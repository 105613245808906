import moment from "moment";

export const FILTER_NAMES = {
  opId: "opId",
  fromOpId: "fromOpId",
  opGroupId: "opGroupId",
  excludeOpId: "excludeOpId",
  field: "field",
  period: "period",
  onlyOpen: "onlyOpen",
  locationId: "locationId",
};

const dateFrom = new Date().getTime() - 1000 * 60 * 60 * 24 * 31; // На 31 день меньше, чем текущая дата

const REPORT_PAGE_MODEL = {
  filter: {
    [FILTER_NAMES.opId]: {},
    [FILTER_NAMES.fromOpId]: {},
    [FILTER_NAMES.opGroupId]: {},
    [FILTER_NAMES.excludeOpId]: {},
    [FILTER_NAMES.field]: {},
    [FILTER_NAMES.period]: [moment(dateFrom), moment()],
    [FILTER_NAMES.onlyOpen]: false,
    [FILTER_NAMES.locationId]: {},
  },
  operators: [],
  groups: [],
  pageLoading: false,
  reportFetching: false,
  validation: {
    resolution: false,
  },
  report: {
    url: undefined,
    file: undefined,
    filename: "",
    filter: undefined,
  },
};

export default REPORT_PAGE_MODEL;
