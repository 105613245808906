import React, { useState, useEffect, useRef, useCallback } from "react";
import { Input, Tooltip } from "antd";
import { getOperatorData } from "../../api/operators";
import { EditOutlined } from "@ant-design/icons";
import { NOTIFICATIONS_TYPES } from "../../services/notifications/types";

import normalizeDate from "../../utils/normalizeDate";
import Spinner from "../Spinner";
import useTextArea from "../../hooks/texarea.hook";

import "./style.scss";

const Comment = ({ props, funcEdit, currentUserID, editingAllowed }) => {
  const { author, datetime, content } = props;
  const currentUser = currentUserID === props.operator_id;
  const className = currentUser ? "comment_current-user" : "comment";

  return (
    <div className={className}>
      <p className="comment__author">
        {author}
        <span className="comment__created-at">{normalizeDate(datetime)}</span>
      </p>
      <p className="comment__message">{content}</p>
      {editingAllowed && currentUser && (
        <div className="buttons-wrapper">
          <Tooltip title="Edit">
            <EditOutlined
              style={{ paddingRight: "5px" }}
              onClick={() => funcEdit(props)}
            />
          </Tooltip>
        </div>
      )}
    </div>
  );
};

const CommentForm = (props) => {
  const {
    appealID,
    getComments,
    newNotifications,
    disabled,
    postingCommentAllowed = false,
    editingOwnCommentAllowed = false,
    currentUserID,
  } = props;

  // State
  const [comments, setComments] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [value, setValue] = useState("");
  const [curEditID, setCurEditID] = useState("");
  const [loading, setLoading] = useState(false);

  // Ref
  const messagesEnd = useRef(null);

  const { getSelection, setSelection, putNewLine } = useTextArea();

  async function onPutNewLine(input) {
    const selection = getSelection(input);

    const newValue = putNewLine(input.value, selection);
    const newSelection = {
      start: selection.start + 1,
      end: selection.start + 1,
    };

    await setValue(newValue);
    setSelection(input, newSelection);
  }

  function onInputPressEnter(event) {
    if (event.ctrlKey) {
      onPutNewLine(event.target);
    } else if (!event.ctrlKey) {
      event.preventDefault();
      handleSubmit(value, curEditID);
    }
  }

  const scrollToBottom = useCallback(() => {
    if (messagesEnd?.current) {
      messagesEnd.current.scrollIntoView({ block: "end" });
    }
  }, [messagesEnd]);

  const checkNewNotification = useCallback((notifications, appealId) => {
    const lastNotification = notifications[notifications.length - 1];

    if (!lastNotification) return false;

    if (
      lastNotification.type === NOTIFICATIONS_TYPES.ISSUE_COMMENT_POSTED &&
      lastNotification.issue_id === appealId
    ) {
      return true;
    }
    if (
      lastNotification.type === NOTIFICATIONS_TYPES.TASK_COMMENT_POSTED &&
      lastNotification.task_id === appealId
    ) {
      return true;
    }
    if (
      lastNotification.type ===
        NOTIFICATIONS_TYPES.MASS_PROBLEM_COMMENT_POSTED &&
      lastNotification.mass_problem_id === appealId
    ) {
      return true;
    }

    return false;
  }, []);

  const getCommentData = useCallback(
    async (comment) => {
      const formattedComment = {
        ...comment,
        content: comment.comment,
        datetime: comment.created_at,
      };

      if (!comment.author) {
        await getOperatorData(comment.operator_id)
          .then((resp) => {
            formattedComment.author = `${resp.data.last_name} ${resp.data.first_name}`;
          })
          .catch(() => {
            formattedComment.author = "Пользователь не найден";
          });
      }

      return formattedComment;
    },
    [getOperatorData]
  );

  const _getComments = useCallback(async () => {
    if (typeof getComments !== "function") return;

    await setLoading(true);

    await getComments()
      .then((resp) => {
        const promises = resp.data.map((comment) => getCommentData(comment));

        Promise.all(promises).then((responses) => setComments(responses));
      })
      .catch((err) => alert(err));

    await setLoading(false);
  }, [getComments, getCommentData]);

  async function handleSubmit(value, curEditID) {
    if (/^(|\s)+$/.test(value)) return;

    await setSubmitting(true);

    if (curEditID !== "") {
      await props.editComment(curEditID, value).then((resp) => {
        if (resp.status === 200) {
          _getComments();
        }
      });

      setValue("");
      setCurEditID("");
      setSubmitting(false);
    } else {
      await props.postComment(value).then((result) => {
        if (result.status === 201) {
          _getComments();
        } else {
          alert(result.data);
        }

        setValue("");
        setSubmitting(false);
      });
    }
  }

  function editComment(comment) {
    setValue(comment.content);
    setCurEditID(comment.id);
  }

  useEffect(() => {
    if (appealID) {
      (async () => {
        await _getComments();
        await scrollToBottom();
      })();
    }
  }, [appealID, _getComments, scrollToBottom]);

  useEffect(() => {
    if (checkNewNotification(newNotifications, appealID)) {
      (async () => {
        await _getComments();
        await scrollToBottom();
      })();
    }
  }, [
    newNotifications,
    appealID,
    _getComments,
    scrollToBottom,
    checkNewNotification,
  ]);

  return (
    <div className="comments-form">
      <div className="comments-form__body">
        <div className="comments-form__list">
          {loading && <Spinner />}
          {!loading && comments.length === 0 && <div>Нет комментариев...</div>}
          {comments
            .sort((a, b) => {
              if (a.datetime > b.datetime) return 1;
              if (a.datetime < b.datetime) return -1;
              return 0;
            })
            .map((comment) => (
              <Comment
                key={comment.id}
                currentUserID={currentUserID}
                props={comment}
                editingAllowed={editingOwnCommentAllowed}
                funcEdit={editComment}
              />
            ))}
          <span ref={messagesEnd} />
        </div>

        {postingCommentAllowed && (
          <div className="comments__input-wrapper">
            <Input.TextArea
              className="input-wrapper__input"
              placeholder="Напишите что-нибудь и нажмите ENTER, чтобы отправить..."
              autoSize={{ minRows: 1, maxRows: 4 }}
              disabled={disabled || submitting}
              value={value}
              onChange={(event) => setValue(event.target.value)}
              // onPressEnter={() => handleSubmit(value, curEditID)}
              onPressEnter={onInputPressEnter}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default CommentForm;
