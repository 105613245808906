import TASK_ACTION_TYPES, {
  CREATE_NEW_TASK,
  GET_TASK_BY_ID,
  EDIT_TASK_DESCRIPTION,
  CLOSE_TASK,
  CHANGE_OPERATOR,
  CHANGE_OPERATORS_GROUP,
  GET_TASK_CHANGES,
} from "./actionTypes";

import {
  TASK_CREATION_ERROR,
  TASK_GETTING_ERROR,
  TASK_DESCRIPTION_EDITING_ERROR,
  TASK_PERFORMER_CHANGING_ERROR,
  TASK_CLOSING_ERROR,
} from "../errors/actionTypes";

import * as api from "../../api/tasks";
import errorHandling from "../../services/errors/errorHandling";
import { TYPES } from "../../components/Search_new/consts";
import { setTabComponent } from "../tabs/tabs";
import { COMPONENTS } from "../../services/tabs";

export function createNewTask(data, type) {
  return async (dispatch) => {
    await api
      .createTask(data, type)
      .then(async (resp) => {
        await dispatch(getTaskByID(resp.data.id));
        await dispatch({ type: CREATE_NEW_TASK });
        await dispatch(setTabComponent(COMPONENTS.TASK_CARD, { taskId: resp.data.id }));
      })
      .catch((err) => dispatch(errorHandling(err, TASK_CREATION_ERROR)));
  };
}

export function getTaskByID(id) {
  return async (dispatch) =>
    await api
      .getTaskByID(id)
      .then((resp) => {
        dispatch({
          type: GET_TASK_BY_ID,
          payload: resp.data,
        });
      })
      .catch((err) => dispatch(errorHandling(err, TASK_GETTING_ERROR)));
}

export function editTaskDescription(id, description) {
  return async (dispatch) => {
    await api
      .editTaskDescription(id, description)
      .then(() => dispatch({ type: EDIT_TASK_DESCRIPTION }))
      .catch((err) => dispatch(errorHandling(err, TASK_DESCRIPTION_EDITING_ERROR)));
  };
}

export function closeTask(id, payload) {
  return async (dispatch) => {
    await api
      .closeTask(id, payload)
      .then((resp) => {
        return resp.status === 200
          ? dispatch({ type: CLOSE_TASK })
          : dispatch(errorHandling(resp, TASK_CLOSING_ERROR));
      })
      .catch((err) => dispatch(errorHandling(err, TASK_CLOSING_ERROR)));
  };
}

export function changeOperator(taskID, newOpID) {
  return async (dispatch) => {
    await api
      .changeOperator(taskID, newOpID)
      .then((resp) => {
        return resp.status === 200
          ? dispatch({ type: CHANGE_OPERATOR })
          : dispatch(errorHandling(resp, TASK_PERFORMER_CHANGING_ERROR));
      })
      .catch((err) => dispatch(errorHandling(err, TASK_PERFORMER_CHANGING_ERROR)));
  };
}

export function changeOperatorsGroup(taskID, newGroupID) {
  return async (dispatch) => {
    await api
      .changeOperatorsGroup(taskID, newGroupID)
      .then((resp) => {
        return resp.status === 200
          ? dispatch({ type: CHANGE_OPERATORS_GROUP })
          : dispatch(errorHandling(resp, TASK_PERFORMER_CHANGING_ERROR));
      })
      .catch((err) => dispatch(errorHandling(err, TASK_PERFORMER_CHANGING_ERROR)));
  };
}

export function getTaskChanges(taskID) {
  return async (dispatch) =>
    await api
      .getTaskChanges(taskID)
      .then((resp) => {
        return resp.status === 200
          ? dispatch({ type: GET_TASK_CHANGES, payload: resp.data })
          : dispatch(errorHandling(resp, ""));
      })
      .catch((err) => dispatch(errorHandling(err, "")));
}

function getTasks(params) {
  const { id, onlyOpen } = params;

  if (!id) throw Error("Не передан параметр 'id");

  if (onlyOpen) return getOpenTasks(params);
  if (!onlyOpen) return getAllTasks(params);
}

function getRequest(isOpen, type) {
  if (isOpen) {
    switch (type) {
      case TYPES.operator:
        return api.getOperatorTasks;
      case TYPES.group:
        return api.getOperatorsGroupTasks;
      default:
        throw Error("Передан неизвестный тип");
    }
  } else {
    switch (type) {
      case TYPES.operator:
        return api.getOperatorClosedTasks;
      case TYPES.group:
        return api.getOperatorsGroupClosedTasks;
      default:
        throw Error("Передан неизвестный тип");
    }
  }
}

async function getAllTasks(params) {
  const { id, type, limit, offset } = params;

  if (offset === 0) {
    const getOpenTasks = getRequest(true, type);
    const getClosedTasks = getRequest(false, type);

    return await Promise.all([getOpenTasks(id), getClosedTasks(id, offset, limit)]);
  } else {
    const getClosedTasks = getRequest(false, type);

    return await getClosedTasks(id, offset, limit);
  }
}

async function getOpenTasks(params) {
  const { id, type } = params;
  const getOpenTasks = getRequest(true, type);

  return await getOpenTasks(id);
}

function clearTask() {
  return (dispatch) => dispatch({ type: TASK_ACTION_TYPES.CLEAR_TASK });
}

const TASK_ACTIONS = {
  createNewTask,
  getTaskByID,
  editTaskDescription,
  closeTask,
  changeOperator,
  changeOperatorsGroup,
  getTaskChanges,
  getTasks,
  clearTask,
};

export default TASK_ACTIONS;
