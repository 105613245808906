export const SET_LOADING_STATUS = "SET_LOADING_STATUS";
export const SET_START_PATH = "SET_START_PATH";
export const SET_REFRESH_TIMEOUT_ID = "SET_REFRESH_TIMEOUT_ID";
export const SET_FAVICON_BLICK_INTERVAL_ID = "SET_FAVICON_BLICK_INTERVAL_ID";

const APP_ACTION_TYPES = {
  SET_LOADING_STATUS,
  SET_START_PATH,
  SET_REFRESH_TIMEOUT_ID,
  SET_FAVICON_BLICK_INTERVAL_ID,
};

export default APP_ACTION_TYPES;
