import React from "react";
import { Select } from "antd";

const ExistenceSelect = ({
  options = [],
  value = undefined,
  onChange = () => {},
}) => {
  return (
    <Select
      bordered={false}
      allowClear
      placeholder="Наличие поля"
      size="small"
      className="component_existence-select"
      value={value}
      options={options}
      onChange={onChange}
      onClear={() => onChange(undefined)}
    />
  );
};

export default ExistenceSelect;
