import { history, store } from "../..";

import getTabModel from "../../components/Tab/tab.model";
import TAB_ACTION_TYPES from "./actionTypes";
import {
  getComponentUrl,
  getComponentByUrl,
  getComponent,
  getTabTitle,
} from "../../services/tabs";

/**
 * @param {object} tab
 * @param {string} tab.uuid
 * @param {number} tab.index
 * @param {string} tab.url
 */
export function setActiveTab(tab) {
  const { url, uuid, index } = tab;

  history.push(url);

  return (dispatch) =>
    dispatch({
      type: TAB_ACTION_TYPES.SET_ACTIVE_TAB,
      payload: { uuid, index },
    });
}

/**
 * @param {object} removed
 * @param {string} removed.uuid
 * @param {number} removed.index
 */
export function removeTab(removed) {
  const { tabs } = store.getState();

  const list = tabs.list.filter((tab, index) => removed.index !== index);
  const active = (() => {
    // Если удаляется последняя вкладка
    if (tabs.list.length === 1) {
      history.push("/");

      return { index: null, uuid: null };
    }

    // Если удаляется активная вкладка
    if (removed.uuid === tabs.active.uuid) {
      // Если активная вкладка последняя в списке, то делаем активной предыдущую
      if (tabs.list.length - 1 === removed.index) {
        const prevTabIndex = list.length - 1;
        const prevTabUrl = list[prevTabIndex].url;

        history.push(prevTabUrl);

        return { index: prevTabIndex, uuid: list[prevTabIndex].uuid };
      } else {
        const nextTabIndex = removed.index;
        const nextTabUrl = list[nextTabIndex].url;

        history.push(nextTabUrl);

        return { index: nextTabIndex, uuid: list[nextTabIndex].uuid };
      }
    } else {
      return {
        ...tabs.active,
        index:
          removed.index > tabs.active.index
            ? tabs.active.index
            : tabs.active.index - 1,
      };
    }
  })();

  return (dispatch) =>
    dispatch({ type: TAB_ACTION_TYPES.REMOVE_TAB, payload: { list, active } });
}

/**
 * @param {string} component
 * @param {object} params
 * @param {object} locationState
 */
export function addTab(component, params, locationState = null) {
  const { tabs } = store.getState();

  // TODO: добавить проверку прав

  const url = getComponentUrl(component, params);
  const newTab = getTabModel({ componentName: component, url });
  const list = [...tabs.list, newTab];
  const active = {
    index: list.length - 1,
    uuid: newTab.uuid,
  };

  history.push(url, locationState);

  return (dispatch) =>
    dispatch({
      type: TAB_ACTION_TYPES.ADD_TAB,
      payload: {
        list,
        active,
      },
    });
}

/**
 * @param {string} component
 * @param {object} params
 * @param {object} locationState
 */
export function setTabComponent(component, params, locationState = null) {
  const { tabs } = store.getState();

  // TODO: добавить проверку прав

  if (tabs.list.length === 0) {
    return (dispatch) => dispatch(addTab(component, params, locationState));
  }

  const list = tabs.list.map((tab, index) => {
    if (index === tabs.active.index) {
      if (tab.component.name === component) return tab;

      const url = getComponentUrl(component, params);
      const tabComponent = getComponent(component);

      history.push(url, locationState);

      return {
        ...tab,
        url,
        title: getTabTitle(component),
        component: { name: component, props: null, component: tabComponent },
      };
    } else {
      return tab;
    }
  });

  return (dispatch) =>
    dispatch({
      type: TAB_ACTION_TYPES.SET_TAB_COMPONENT,
      payload: { list },
    });
}

function getTab(path) {
  const componentName = getComponentByUrl(path);
  return getTabModel({ componentName, url: path });
}

export function tabsInitialization(startPath) {
  let list = []; // список ранее открытых вкладок
  let active = {}; // активная вкладка

  // TODO: добавить проверку прав

  const path = startPath || window.location.pathname;
  const tabs = JSON.parse(localStorage.getItem("tabs")); // ранее открытые вкладки

  if (tabs === null) {
    // Если нет ранее открытых вкладок
    if (path !== "/") {
      // Если открывается не корень сайта, то открываем новую вкладку и делаем её активной
      const newTab = getTab(path);

      list = [...list, newTab];
      active = { uuid: newTab.uuid, index: list.length - 1 };

      history.push(newTab.url);
    }
    // Иначе ничего не делаем
  } else {
    // Если есть ранее открытые вкладки

    switch (path) {
      case "/": {
        list = tabs.list.map((url, index) => {
          const newTab = getTab(url);

          // Делаем первую по счету вкладку активной
          if (index === 0) {
            active = { uuid: newTab.uuid, index };
            history.push(url);
          }

          return newTab;
        });
        break;
      }
      case tabs.active.url: {
        list = tabs.list.map((url, index) => {
          const newTab = getTab(url);

          // Делаем активной вкладку из localStorage
          if (tabs.active.url === url && tabs.active.index === index) {
            active = { uuid: newTab.uuid, index };
          }

          return newTab;
        });

        history.push(tabs.active.url);

        break;
      }
      default: {
        list = tabs.list.map((url, index) => {
          const newTab = getTab(url);

          // Пытаемся найти location.pathname в ранее открытых вкладках
          if (newTab.url === path) {
            active = {
              index,
              uuid: newTab.uuid,
            };
          }

          return newTab;
        });

        // Если location.pathname не найден, то открываем новую вкладку и делаем ее активной
        if (Object.keys(active).length === 0) {
          const activeTab = getTab(path);

          list.push(activeTab);
          active = {
            index: list.length - 1,
            uuid: activeTab.uuid,
          };
        }

        history.push(path);
      }
    }
  }

  return (dispatch) =>
    dispatch({
      type: TAB_ACTION_TYPES.OPEN_PREVIOUSLY_OPENED_TABS,
      payload: { list, active },
    });
}

/**
 *
 * @param {object} tab
 * @param {number} tab.index
 * @param {string} tab.uuid
 * @param {object} props
 */
export function setTabComponentProps(tab, props) {
  const { tabs } = store.getState();

  const list = tabs.list.map((t, index) => {
    if (index === tab.index && t.uuid === tab.uuid) {
      const newProps =
        props === null ? props : { ...t.component.props, ...props };

      return {
        ...t,
        title: getTabTitle(t.component.name, newProps),
        component: {
          ...t.component,
          props: newProps,
        },
      };
    } else {
      return t;
    }
  });

  return (dispatch) =>
    dispatch({
      type: TAB_ACTION_TYPES.SET_TAB_COMPONENT_PROPS,
      payload: { list },
    });
}

export function findTab(tabUrl, tabs = []) {
  const tabIndex = tabs.findIndex((tab) => tab.url === tabUrl);

  return tabIndex === -1
    ? undefined
    : { index: tabIndex, uuid: tabs[tabIndex].uuid, url: tabs[tabIndex].url };
}

/**
 * @param {object} tab
 * @param {number} tab.index
 * @param {string} tab.uuid
 * @param {object} error
 */
function setTabError(tab, error) {
  const { tabs } = store.getState();

  const list = tabs.list.map((t, index) => {
    if (index === tab.index && t.uuid === tab.uuid) {
      return {
        ...t,
        error,
      };
    } else {
      return t;
    }
  });

  return (dispatch) =>
    dispatch({
      type: TAB_ACTION_TYPES.SET_TAB_ERROR,
      payload: { list },
    });
}

const TAB_ACTIONS = {
  addTab,
  findTab,
  removeTab,
  setTabError,
  setActiveTab,
  setTabComponent,
  tabsInitialization,
  setTabComponentProps,
};

export default TAB_ACTIONS;
