import React from "react";
import { Button } from "antd";

import Table, { TABLE_STYLE } from "../../components/Table";

import { useTabs, COMPONENTS } from "../../services/tabs";
import useRBAC, { PERMISSIONS, UI_PERMISSIONS } from "../../services/rbac";

const GroupsList = (props) => {
  const { loading, groups, onRowClick, onDelete } = props;

  // Hooks
  const { clickHandler } = useTabs();
  const { checkAccess, checkPermissionToRenderUI } = useRBAC();

  const COLUMNS = [
    {
      title: "Название",
      dataIndex: "title",
      customFilter: true,
    },
  ];

  if (checkPermissionToRenderUI(UI_PERMISSIONS["ui:management:operators-groups:list:delete-group"])) {
    COLUMNS.push(
      {
        width: 110,
        render: (text, row) => (
          <Button
            danger
            className=""
            children="Удалить"
            onClick={(e) => {
              e.stopPropagation();
              onDelete(row.id);
            }}
          />
        ),
      },  
    )
  }

  return (
    <div className="body__groups">
      <Table
        loading={loading}
        scroll={{ y: "calc(100vh - 268px)" }}
        columns={COLUMNS}
        data={groups}
        onRowClick={
          checkAccess(PERMISSIONS["operator_groups:get:one"]) ? onRowClick : () => {}
        }
        tableStyle={TABLE_STYLE.zebra}
        title={() =>
          checkPermissionToRenderUI(UI_PERMISSIONS["ui:management:operators-groups:list:create"]) && (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                type="primary"
                htmlType="button"
                children="Создать"
                onClick={() =>
                  clickHandler({ component: COMPONENTS.GROUP_CREATION_FORM })
                }
              />
            </div>
          )
        }
      />
    </div>
  );
};

export default GroupsList;
