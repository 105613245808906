import React from "react";
import { Button } from "antd";
import { CloseOutlined, FileOutlined } from "@ant-design/icons";

import {
  IMAGE_MIME_TYPES,
  VIDEO_MIME_TYPES,
} from "../../components/Message/consts";

const DeleteButton = ({ removeFile }) => (
  <Button
    size="small"
    shape="circle"
    className="attached-file__button_delete"
    icon={<CloseOutlined />}
    onClick={removeFile}
  />
);
const ImagePreview = ({ file, removeFile }) => (
  <div className="attached-file__preview attached-file__preview_image">
    <img src={URL.createObjectURL(file)} alt={file.name} />
    <DeleteButton removeFile={removeFile} />
  </div>
);
const VideoPreview = ({ file, removeFile }) => (
  <div className="attached-file__preview attached-file__preview_video">
    <video src={URL.createObjectURL(file)} alt={file.name} />
    <DeleteButton removeFile={removeFile} />
  </div>
);
const OtherPreview = ({ file, removeFile }) => (
  <div className="attached-file__preview attached-file__preview_other">
    <FileOutlined className="preview__icon_file" />
    <span className="preview__filename">{file.name}</span>
    <DeleteButton removeFile={removeFile} />
  </div>
);

const AttachedFilePreview = (props) => {
  const { file, removeFile } = props;

  function drawPreview(file) {
    if (IMAGE_MIME_TYPES.includes(file.type)) {
      return <ImagePreview file={file} removeFile={removeFile} />;
    }
    if (VIDEO_MIME_TYPES.includes(file.type)) {
      return <VideoPreview file={file} removeFile={removeFile} />;
    }

    return <OtherPreview file={file} removeFile={removeFile} />;
  }

  return drawPreview(file);
};

export default AttachedFilePreview;
