import api from "./api";
import { SERVICE_ACCOUNT_API_URLS } from "../consts";

const createServiceAccount = (serviceAccount) => {
  const url = SERVICE_ACCOUNT_API_URLS.createServiceAccount();
  return api.protectedPost(url, serviceAccount);
};

const getServiceAccounts = () => {
  const url = SERVICE_ACCOUNT_API_URLS.getServiceAccounts();
  return api.protectedGet(url);
};

const getServiceAccount = (serviceAccountId) => {
  const url = SERVICE_ACCOUNT_API_URLS.getServiceAccount(serviceAccountId);
  return api.protectedGet(url);
};

const updateServiceAccount = (serviceAccountId, serviceAccount) => {
  const url = SERVICE_ACCOUNT_API_URLS.updateServiceAccount(serviceAccountId);
  return api.protectedPatch(url, serviceAccount);
};

const updateSecretServiceAccount = (serviceAccountId, serviceAccount) => {
  const url =
    SERVICE_ACCOUNT_API_URLS.updateSecretServiceAccount(serviceAccountId);
  return api.protectedPost(url, serviceAccount);
};

const SERVICE_ACCOUNT_API = {
  createServiceAccount,
  getServiceAccount,
  getServiceAccounts,
  updateServiceAccount,
  updateSecretServiceAccount,
};

export default SERVICE_ACCOUNT_API;
