import React, { Component } from 'react';
import { Modal } from 'antd';

class ImageViewer extends Component {
    constructor(props) {
        super(props);

        this.hideViewer = this.hideViewer.bind(this);
    }

    hideViewer() {
        this.props.toggleVisibility(false);
    }

    render() {
        const { visible } = this.props;

        return (
            <Modal
                centered
                width="fit-content"
                style={{ maxWidth: '80%', backgroundColor: 'transparent' }}
                bodyStyle={{ padding: 0 }}
                closable={false}
                footer={false}
                visible={Boolean(visible)}
                onCancel={this.hideViewer}
            >
                {visible && <img src={visible.src} alt="message-file" style={{ maxHeight: '85vh', objectFit: 'contain' }} />}
            </Modal>
        );
    }
}

export default ImageViewer;