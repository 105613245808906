import React from "react";
import { Button } from "antd";
import { CheckOutlined } from "@ant-design/icons";

import useRBAC, { UI_PERMISSIONS } from "../../services/rbac";

const Header = (props) => {
  const { loading, hasChanges, closed, onSave, onDone, onSaveAndClose } = props;

  const { checkPermissionToRenderUI } = useRBAC();

  function drawActionButtons() {
    if (closed) return null;

    return (
      <>
        {checkPermissionToRenderUI(
          UI_PERMISSIONS["ui:mass-problems:card:save"]
        ) && (
          <Button
            className="header__button header__button_save"
            size="small"
            type="default"
            children="Сохранить"
            disabled={loading || !hasChanges}
            onClick={onSave}
          />
        )}
        {checkPermissionToRenderUI(
          UI_PERMISSIONS["ui:mass-problems:card:save-and-close"]
        ) && (
          <Button
            size="small"
            type="primary"
            children="Сохранить и закрыть"
            disabled={loading || !hasChanges}
            onClick={onSaveAndClose}
          />
        )}
        {checkPermissionToRenderUI(
          UI_PERMISSIONS["ui:mass-problems:card:done"]
        ) && (
          <Button
            className="header__button header__button_done"
            size="small"
            children="Выполнено"
            disabled={loading}
            onClick={onDone}
            icon={<CheckOutlined />}
          />
        )}
      </>
    );
  }
  return (
    <>
      {/* <h2 className="header__title">{`Массовая проблема #${massProblem.human_readable_id}`}</h2> */}
      <h2 className="header__title">Массовая проблема</h2>
      {drawActionButtons()}
    </>
  );
};

export default Header;
