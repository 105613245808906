export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const SET_AUTH_STATUS = "SET_AUTH_STATUS";

export default {
  LOGIN,
  LOGOUT,
  REFRESH_TOKEN,
  SET_AUTH_STATUS,
};
