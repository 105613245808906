import api from "./api";
import { RBAC_API_URLS } from "../consts";

export async function getRolesList() {
  const url = RBAC_API_URLS.getRolesList();
  return await api.protectedGet(url);
}

export async function getPermissionsList() {
  const url = RBAC_API_URLS.getPermissionsList();
  return await api.protectedGet(url);
}

/**
 * Получение графа ролей с разрешениями в формате svg
 * Если передан параметр roles, то возвращает граф
 * только по указанным ролям
 * @param {string[]} roles - Список ролей
 * @param {boolean} withPerms - Показывать разрешения или нет
 * @returns {Promise}
 */
export async function getSvgRolesTree(roles = [], withPerms = false) {
  const url = RBAC_API_URLS.getSvgRolesTree(roles, withPerms);
  return await api.protectedGet(url, "blob");
}

/**
 * Создание новой роли
 * @param {Object} payload
 * @param {string} payload.id Название роли
 * @param {string[]} payload.parents Названия родительских ролей
 * @param {string[]} payload.permissions Разрешения
 *
 * @returns {Promise}
 */
export async function createRole(payload) {
  const url = RBAC_API_URLS.createRole();
  return await api.protectedPost(url, payload);
}

/**
 * Добавляет разрешения к роли
 * @param {string} roleName Идентификатор роли
 * @param {string[]} permissions Массив разрешений
 * @returns
 */
async function postRolePermissions(roleName, permissions) {
  const url = RBAC_API_URLS.postRolePermissions(roleName);
  return await api.protectedPost(url, permissions);
}

/**
 * Удаляет разрешения из роли
 * @param {string} roleName Идентификатор роли
 * @param {*} permissions Массив разрешений
 * @returns
 */
async function deleteRolePermissions(roleName, permissions) {
  const url = RBAC_API_URLS.deleteRolePermissions(roleName);
  return await api.protectedDelete(url, permissions);
}

/**
 * Добавляет родительские роли
 * @param {string} roleName Идентификатор роли
 * @param {string[]} parents Массив родительских ролей
 * @returns
 */
async function postRoleParents(roleName, parents) {
  const url = RBAC_API_URLS.postRoleParents(roleName);
  return await api.protectedPost(url, parents);
}

/**
 * Удаляет родительмкие роли
 * @param {string} roleName Идентификатор роли
 * @param {string[]} parents Массив родительских ролей
 * @returns
 */
async function deleteRoleParents(roleName, parents) {
  const url = RBAC_API_URLS.deleteRoleParents(roleName);
  return await api.protectedDelete(url, parents);
}

const RBAC_API = {
  getRolesList,
  getPermissionsList,
  getSvgRolesTree,
  createRole,
  postRolePermissions,
  deleteRolePermissions,
  postRoleParents,
  deleteRoleParents,
};

export default RBAC_API;
