export const CREATE_NEW_TASK = "CREATE_NEW_TASK";
export const GET_TASK_BY_ID = "GET_TASK_BY_ID";
export const GET_OPERATOR_TASKS = "GET_OPERATOR_TASKS";
export const EDIT_TASK_DESCRIPTION = "EDIT_TASK_DESCRIPTION";
export const CLOSE_TASK = "CLOSE_TASK";
export const CHANGE_OPERATOR = "CHANGE_OPERATOR";
export const CHANGE_OPERATORS_GROUP = "CHANGE_OPERATORS_GROUP";
export const CLEAR_TASKS_LIST = "CLEAR_TASKS_LIST";
export const GET_TASK_CHANGES = "GET_TASK_CHANGES";
export const TASK_GET_RELATED_TASKS = "TASK_GET_RELATED_TASKS";

export const RESET_TO_DEFAULT_TASKS_LIST_FILTER =
  "RESET_TO_DEFAULT_TASKS_LIST_FILTER";
export const SET_CLOSED_TASKS_LIST_FILTER = "SET_CLOSED_TASKS_LIST_FILTER";
export const SET_SEARCH_FOR_TASKS_LIST_FILTER =
  "SET_SEARCH_FOR_TASKS_LIST_FILTER";

const SET_TASKS = "SET_TASKS";
const ADD_TASKS = "ADD_TASKS";

const SET_TASK_LIST_ONLY_OPEN_FILTER = "SET_TASK_LIST_ONLY_OPEN_FILTER";
const SET_TASK_LIST_LIMIT = "SET_TASK_LIST_LIMIT";
const SET_TASK_LIST_OFFSET = "SET_TASK_LIST_OFFSET";
const SET_TASK_LIST_LOAD_MORE_FLAG = "SET_TASK_LIST_LOAD_MORE_FLAG";
const CLEAR_TASK = "CLEAR_TASK";

const TASK_ACTION_TYPES = {
  CREATE_NEW_TASK,
  GET_TASK_BY_ID,
  GET_OPERATOR_TASKS,
  EDIT_TASK_DESCRIPTION,
  CLOSE_TASK,
  CHANGE_OPERATOR,
  CHANGE_OPERATORS_GROUP,
  RESET_TO_DEFAULT_TASKS_LIST_FILTER,
  SET_CLOSED_TASKS_LIST_FILTER,
  SET_SEARCH_FOR_TASKS_LIST_FILTER,
  CLEAR_TASKS_LIST,
  GET_TASK_CHANGES,
  TASK_GET_RELATED_TASKS,

  SET_TASKS,
  ADD_TASKS,
  SET_TASK_LIST_ONLY_OPEN_FILTER,
  SET_TASK_LIST_LIMIT,
  SET_TASK_LIST_OFFSET,
  SET_TASK_LIST_LOAD_MORE_FLAG,
  CLEAR_TASK,
};

export default TASK_ACTION_TYPES;
