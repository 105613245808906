import React from "react";

import "./style.scss";

/**
 * @enum
 */
export const ACCESS_DENIED_TYPE = {
  noData: "noData",
  pageNotFound: "pageNotFound",
  noPermissions: "noPermissions",
};

const ACCESS_DENIED_TEXT = {
  [ACCESS_DENIED_TYPE.noData]: "Нет данных",
  [ACCESS_DENIED_TYPE.pageNotFound]: "Страница не найдена",
  [ACCESS_DENIED_TYPE.noPermissions]:
    "В доступе отказано.\nОтсутствуют необходимые разрешения.",
};

const ACCESS_DENIED_DESCR = {
  [ACCESS_DENIED_TYPE.noData]: "",
  [ACCESS_DENIED_TYPE.pageNotFound]: "Возможно, Вы используете неверную ссылку",
  [ACCESS_DENIED_TYPE.noPermissions]:
    "Получить необходимый доступ можно у администратора вашей группы",
};

const AccessDenied = (props) => {
  const { type, children } = props;

  function getHelperText(type) {
    return {
      text: props.text || ACCESS_DENIED_TEXT[type] || "",
      description: props.description || ACCESS_DENIED_DESCR[type] || "",
    };
  }

  function drawContent() {
    if (children) {
      return children;
    } else {
      const { text, description } = getHelperText(type);

      return (
        <div className="access-denied__default-content">
          <h4 className="access-denied__title">{text}</h4>
          <p className="access-denied__description">{description}</p>
        </div>
      );
    }
  }

  return <div className="component_access-denied">{drawContent()}</div>;
};

export default AccessDenied;
