import React, { useCallback } from "react";
import { Tabs as TabsAntd } from "antd";

import CommentForm from "../../components/comments";
import Chat from "../../components/Chat";

import useRBAC, { UI_PERMISSIONS } from "../../services/rbac";
import ISSUES_API from "../../api/appeals";

/**
 @enum Перечисление вкладок
 */
export const TABS = {
  comments: "comments",
  messages: "messages",
};

const TABS_NAME = {
  [TABS.comments]: "Комментарии",
  [TABS.messages]: "Чат",
};

const Tabs = (props) => {
  const {
    userId,
    issueId,
    disabled,
    operatorId,
    newMessages,
    newNotifications,
    defaultActiveTab,
    addFileFromChat,
  } = props;

  const { checkPermissionToRenderUI } = useRBAC();

  const getComments = useCallback(() => ISSUES_API.getAppealComments(issueId), [issueId]);
  const postComment = useCallback(
    (comment) => ISSUES_API.setAppealComment(issueId, comment),
    [issueId]
  );
  const editComment = useCallback(
    (commentId, comment) => ISSUES_API.editComment(commentId, comment),
    []
  );

  function drawTabs() {
    const hasChat = checkPermissionToRenderUI(UI_PERMISSIONS["ui:issues:card:chat:list"]);
    const hasComments = checkPermissionToRenderUI(
      UI_PERMISSIONS["ui:issues:card:comments:list"]
    );

    if (!(hasChat || hasComments)) return null;

    return (
      <TabsAntd
        defaultActiveKey={defaultActiveTab}
        className="page__aside-tabs"
        tabBarStyle={{ height: 38, padding: "0 20px" }}
      >
        {hasChat && (
          <TabsAntd.TabPane key={TABS.messages} tab={TABS_NAME[TABS.messages]}>
            <Chat
              userId={userId}
              operatorId={operatorId}
              disabled={disabled}
              newMessage={newMessages[newMessages.length - 1]}
              addFileToIssue={addFileFromChat}
              sendingMessageAllowed={checkPermissionToRenderUI(
                UI_PERMISSIONS["ui:issues:card:chat:post"]
              )}
            />
          </TabsAntd.TabPane>
        )}
        {hasComments && (
          <TabsAntd.TabPane key={TABS.comments} tab={TABS_NAME[TABS.comments]}>
            <CommentForm
              appealID={issueId}
              currentUserID={operatorId}
              getComments={getComments}
              postComment={postComment}
              editComment={editComment}
              newNotifications={newNotifications}
              postingCommentAllowed={checkPermissionToRenderUI(
                UI_PERMISSIONS["ui:issues:card:comments:post"]
              )}
              editingOwnCommentAllowed={checkPermissionToRenderUI(
                UI_PERMISSIONS["ui:issues:card:comments:edit"]
              )}
            />
          </TabsAntd.TabPane>
        )}
      </TabsAntd>
    );
  }

  return drawTabs();
};

export default Tabs;
