import React from "react";
import {
  Col,
  Row,
  Form,
  Input,
  Select,
  Button,
  Checkbox,
  Typography,
} from "antd";

import useServiceAccount, {
  PERMORMER_FIELD_NAME,
  MAX_NAME_LENGHT,
  SELECT_PROPS,
} from "../../hooks/useServiceAccount";
import { TYPES } from "../../components/Search_new/consts";

const { Option, OptGroup } = Select;
const { Title } = Typography;

const FORM_PROPS = {
  layout: "vertical",
};

const FORM_FIELDS = {
  name: "name",
  description: "description",
  authorizationHeader: "authorization_header",
  basicPassword: "basic_password",
  basicUsername: "basic_username",
  fromOperator: "from_operator_id",
  performer: PERMORMER_FIELD_NAME,
  disabled: "disabled",
};

const Editing = (props) => {
  const {
    loading,
    operators,
    operatorGroups,
    withUpdateSecret,
    onCancel,
    onSaveCommon,
    onSecretUpdate,
    secretUpdating,
  } = props;

  const { formattingFormValues, serviceAccountNameValidation } =
    useServiceAccount();

  const getInitialPerformer = (toOperator, toOperatorsGroup) => {
    if (toOperator) {
      return JSON.stringify({ value: toOperator.id, type: TYPES.operator });
    }
    if (toOperatorsGroup) {
      return JSON.stringify({ value: toOperatorsGroup.id, type: TYPES.group });
    }

    return undefined;
  };

  const getInitialValues = (account) => {
    const {
      disabled,
      name,
      description,
      from_operator,
      to_operator,
      to_operators_group,
      authorization_header,
      basic_username,
      basic_password,
    } = account;

    return {
      [FORM_FIELDS.disabled]: disabled,
      [FORM_FIELDS.name]: name,
      [FORM_FIELDS.description]: description,
      [FORM_FIELDS.fromOperator]: from_operator?.id,
      [FORM_FIELDS.authorizationHeader]: authorization_header,
      [FORM_FIELDS.basicPassword]: basic_password,
      [FORM_FIELDS.basicUsername]: basic_username,
      [FORM_FIELDS.performer]: getInitialPerformer(
        to_operator,
        to_operators_group
      ),
    };
  };

  return (
    <Row className="component_service-account-card" gutter={[0, 36]}>
      {withUpdateSecret && (
        <Col span={24}>
          <Title level={5}>Обновить Secret сервисного аккаунта</Title>
          <Button
            type="primary"
            children="Обновить"
            onClick={onSecretUpdate}
            loading={secretUpdating}
          />
        </Col>
      )}

      <Col span={24}>
        <Title level={5}>Общие данные</Title>

        <Form
          {...FORM_PROPS}
          initialValues={getInitialValues(props)}
          onFinish={(form) => onSaveCommon(formattingFormValues(form))}
        >
          <Form.Item
            name={FORM_FIELDS.name}
            label="Название"
            rules={[
              { required: true, message: "Обязательное поле" },
              {
                max: MAX_NAME_LENGHT,
                message: `Максимальная длина ${MAX_NAME_LENGHT} символа`,
              },
              {
                validator: (_, name) => serviceAccountNameValidation(name),
              },
            ]}
            extra="Например: service_account"
          >
            <Input allowClear disabled={loading} maxLength={MAX_NAME_LENGHT} />
          </Form.Item>

          <Form.Item
            name={FORM_FIELDS.description}
            label="Описание"
            rules={[{ required: true, message: "Обязательное поле" }]}
            extra="Например: Сервисный аккаунт"
          >
            <Input.TextArea allowClear disabled={loading} />
          </Form.Item>

          <Form.Item name={FORM_FIELDS.fromOperator} label="От оператора">
            <Select
              {...SELECT_PROPS}
              options={operators}
              disabled={loading}
              optionFilterProp="label"
              allowClear
            />
          </Form.Item>

          <Form.Item name={FORM_FIELDS.performer} label="Исполнитель">
            <Select {...SELECT_PROPS} allowClear disabled={loading}>
              <OptGroup label="Операторы">
                {operators.map((_o) => (
                  <Option
                    value={JSON.stringify({
                      value: _o.value,
                      type: TYPES.operator,
                    })}
                    children={_o.label}
                  />
                ))}
              </OptGroup>

              <OptGroup label="Группы операторов">
                {operatorGroups.map((_g) => (
                  <Option
                    value={JSON.stringify({
                      value: _g.value,
                      type: TYPES.group,
                    })}
                    children={_g.label}
                  />
                ))}
              </OptGroup>
            </Select>
          </Form.Item>

          <Form.Item
            name={FORM_FIELDS.basicUsername}
            label="Базовое имя пользователя"
          >
            <Input.Password allowClear />
          </Form.Item>
          <Form.Item name={FORM_FIELDS.basicPassword} label="Базовый пароль">
            <Input.Password allowClear />
          </Form.Item>
          <Form.Item name={FORM_FIELDS.authorizationHeader} label="Заголовок">
            <Input.Password allowClear />
          </Form.Item>

          <Form.Item name={FORM_FIELDS.disabled} valuePropName="checked">
            <Checkbox children="Неактивный" />
          </Form.Item>

          <Form.Item noStyle>
            <Button
              children="Сохранить"
              type="primary"
              htmlType="submit"
              loading={loading}
            />
          </Form.Item>

          <Form.Item noStyle>
            <Button
              children="Отмена"
              type="link"
              disabled={loading}
              onClick={onCancel}
            />
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default Editing;
