import React, { Component } from "react";
import { isEqual } from "lodash";

import FileViewerModal from "./modal";
import FilePreview from "../FilePreview";

import { getImagePreview, getFileByID } from "../../api/files";
import { deleteAppealFile } from "../../api/appeals";

import "./style.scss";

const FILE_PROPS = {
  loading: false,
  base64: null,
  url: undefined,
  fail: false,
  retry: null,
  preview: null,
};

class FilesViewer extends Component {
  constructor(props) {
    super(props);
    const { files = [] } = props;

    this.state = {
      files: files,
      filesProps: this.getFilesProps(files),
      openedFileIndex: null,
      openedFileID: null,
      curImg: null,
      loading: false,
      fail: false,
      retry: null,
    };

    this.getFile = this.getFile.bind(this);
    this.openFile = this.openFile.bind(this);
    this.nextFile = this.nextFile.bind(this);
    this.prevFile = this.prevFile.bind(this);
    this.getBase64 = this.getBase64.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.deleteFile = this.deleteFile.bind(this);
    this.getFileData = this.getFileData.bind(this);
    this.setFileProps = this.setFileProps.bind(this);
    this.downloadFile = this.downloadFile.bind(this);
    this.getFilesProps = this.getFilesProps.bind(this);
    this.getImagePreview = this.getImagePreview.bind(this);
    this.drawFilesPreview = this.drawFilesPreview.bind(this);
  }

  getFilesProps(files) {
    const props = {};

    files.forEach((file) => {
      props[file.id] = FILE_PROPS;
    });

    return props;
  }

  deleteFile(fileID) {
    const { appealID } = this.props;

    deleteAppealFile(appealID, fileID)
      .then(() => {
        const { files } = this.state;

        const index = files.findIndex((file) => file.id === fileID);
        const start = files.slice(0, index);
        const end = files.slice(index + 1);

        this.setState({ files: [...start, ...end] });
      })
      .catch();
  }

  async downloadFile(fileID) {
    const { base64 } = this.state.filesProps[fileID];
    const file = this.state.files.find((file) => file.id === fileID);
    const downloadLink = document.createElement("a");

    downloadLink.href = base64;
    downloadLink.download = file.filename || "noname";

    if (!base64) {
      await this.setFileProps(fileID, { loading: true });
      await this.getFile(fileID);
      await this.setFileProps(fileID, { loading: false });

      downloadLink.href = this.state.filesProps[fileID].base64;
    }

    downloadLink.click();
  }

  getFileData(fileID) {
    const { files, filesProps } = this.state;
    const file = files.find((file) => file.id === fileID);

    return file ? { ...file, ...filesProps[fileID] } : null;
  }

  closeModal() {
    this.setState({ openedFileID: null, openedFileIndex: null });
  }

  openFile(fileID) {
    if (!fileID) return;

    const { files, filesProps } = this.state;
    const fileIndex = files.findIndex((file) => file.id === fileID);

    if (!filesProps[fileID].base64) this.getFile(fileID);

    this.setState({ openedFileIndex: fileIndex, openedFileID: fileID });
  }

  setFileProps(fileID, newProps) {
    this.setState(({ filesProps }) => ({
      filesProps: {
        ...filesProps,
        [fileID]: {
          ...filesProps[fileID],
          ...newProps,
        },
      },
    }));
  }

  async componentDidMount() {
    const { files } = this.state;

    await files.forEach(async (file) => {
      if (!file.image_preview) return;

      await this.getImagePreview(file);
    });
  }
  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.files, this.props.files)) {
      this.setState({
        files: this.props.files,
        filesProps: this.getFilesProps(this.props.files),
      });

      this.props.files.forEach(async (file) => {
        if (!file.image_preview) return;

        await this.getImagePreview(file);
      });
    }
  }
  componentWillUnmount() {
    const { files, filesProps } = this.state;

    files.forEach((file) => {
      URL.revokeObjectURL(filesProps[file.id].url);
    });
  }

  getBase64(blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  async getImagePreview(file) {
    await this.setFileProps(file.id, { loading: true });

    await getImagePreview(file.image_preview.id)
      .then(async (preview) => await this.getBase64(preview))
      .then((base64) =>
        this.setFileProps(file.id, {
          preview: base64,
          fail: false,
          retry: null,
        })
      )
      .catch((err) => {
        console.error(err);
        this.setFileProps(file.id, {
          preview: null,
          fail: true,
          retry: () => this.getImagePreview(file),
        });
      });

    await this.setFileProps(file.id, { loading: false });
  }

  async getFile(fileID) {
    await this.setState({ loading: true });

    await getFileByID(fileID)
      .then(async (file) => {
        // await this.getBase64(file)
        return URL.createObjectURL(file);
      })
      .then((url) => {
        this.setState({ fail: false, retry: null });
        this.setFileProps(fileID, { url });
      })
      .catch((err) => {
        console.error(err);
        this.setFileProps(fileID, { base64: null });
        this.setState({ fail: true, retry: () => this.getFile(fileID) });
      });

    await this.setState({ loading: false });
  }

  nextFile() {
    const { files, openedFileIndex } = this.state;

    if (openedFileIndex + 1 > files.length - 1) {
      this.openFile(files[0].id);
    } else {
      const nextFile = files[openedFileIndex + 1];
      this.openFile(nextFile.id);
    }
  }

  prevFile() {
    const { files, openedFileIndex } = this.state;

    if (openedFileIndex - 1 < 0) {
      const lastFile = files[files.length - 1];
      this.openFile(lastFile.id);
    } else {
      const prevFile = files[openedFileIndex - 1];
      this.openFile(prevFile.id);
    }
  }

  drawFilesPreview(files, filesProps) {
    const { deletable, appealID, selection = null } = this.props;

    return files.map((file) => {
      const { operator, created_at } = file;

      return (
        <FilePreview
          id={file.id}
          key={file.id}
          name={file.filename}
          operator={operator ? `${operator?.last_name} ${operator?.first_name}` : ""}
          created_at={created_at}
          selection={selection}
          deletable={deletable && appealID}
          type={file.content_type}
          {...filesProps[file.id]}
          onOpen={this.openFile}
          onDelete={this.deleteFile}
          onDownload={this.downloadFile}
        />
      );
    });
  }

  render() {
    const { files, filesProps, openedFileIndex, openedFileID } = this.state;

    return (
      <div className="files-viewer">
        {this.drawFilesPreview(files, filesProps)}
        <FileViewerModal
          file={this.getFileData(openedFileID)}
          visible={openedFileIndex !== null}
          loading={this.state.loading}
          fail={this.state.fail}
          retry={this.state.retry}
          onNext={this.nextFile}
          onPrev={this.prevFile}
          onClose={this.closeModal}
        />
      </div>
    );
  }
}

export default FilesViewer;
