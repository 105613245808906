export const BUTTON_TYPES = {
  FIRST_PAGE_BUTTON: "FIRST_PAGE_BUTTON",
  PREV_JUMPER: "PREV_JUMPER",
  NEXT_JUMPER: "NEXT_JUMPER",
  LAST_PAGE_BUTTON: "LAST_PAGE_BUTTON",
  PAGE_BUTTON: "PAGE_BUTTON",
};

const TOTAL_PAGE_NUMBERS = 5;
const COUNT_OF_NEIGHBORING = Math.round((TOTAL_PAGE_NUMBERS - 1) / 2);

const usePagination = ({ pageSize, totalCount, currentPage }) => {
  const totalPageCount = Math.ceil(totalCount / pageSize);

  function getRange(start, end) {
    const length = end - start + 1;

    return Array.from({ length }, (_, idx) => idx + start);
  }

  function getPaginationRange() {
    // Если общее количество страниц меньше чем TOTAL_PAGE_NUMBERS
    if (TOTAL_PAGE_NUMBERS >= totalPageCount) {
      return getRange(1, totalPageCount).map((page) => ({
        type: BUTTON_TYPES.PAGE_BUTTON,
        page,
      }));
    }

    const leftNeighborings = Math.max(currentPage - COUNT_OF_NEIGHBORING, 1);
    const rightNeighborings = Math.min(
      currentPage + COUNT_OF_NEIGHBORING,
      totalPageCount
    );

    const shouldShowLeftJumber = leftNeighborings > 2;
    // const shouldShowRightJumper = rightNeighborings < totalPageCount - 2;

    if (!shouldShowLeftJumber) {
      const itemCount = 1 + 2 * COUNT_OF_NEIGHBORING;

      return getRange(1, itemCount).map((page) => ({
        type: BUTTON_TYPES.PAGE_BUTTON,
        page,
      }));
    }

    if (shouldShowLeftJumber) {
      const range = getRange(leftNeighborings, rightNeighborings);

      return [
        { type: BUTTON_TYPES.FIRST_PAGE_BUTTON },
        { type: BUTTON_TYPES.PREV_JUMPER },
        ...range.map((page) => ({ type: BUTTON_TYPES.PAGE_BUTTON, page })),
      ];
    }

    return undefined;
  }

  return {
    totalPageCount,
    getPaginationRange,
  };
};

export default usePagination;
