import React from "react";
import moment from "moment";

import SEARCH_API from "../api/search";
import OPERATORS_API from "../api/operators";
import { getTasks } from "../api/tasks";
import { getIssues } from "../api/appeals";
import { getMassProblems } from "../api/massProblem";

import isEqualMoments from "../utils/isEqualMoments";

export const FILTERS = {
  type: "type",
  urgency: "urgency",
  user_id: "user_id",
  task_id: "task_id",
  done_at: "done_at",
  issue_id: "issue_id",
  created_at: "created_at",
  updated_at: "updated_at",
  location_id: "location_id",
  operator_id: "operator_id",
  template_id: "template_id",
  mass_problem_id: "mass_problem_id",
  from_operator_id: "from_operator_id",
  human_readable_id: "human_readable_id",
  operators_group_id: "operators_group_id",
  service_account_id: "service_account_id",
};

export const SEARCH_RESULT_TYPES = {
  task: "task",
  issue: "issue",
  task_comment: "task_comment",
  mass_problem: "mass_problem",
  issue_comment: "issue_comment",
  mass_problem_comment: "mass_problem_comment",
};

export const DATE_FILTERS = [FILTERS.done_at, FILTERS.created_at, FILTERS.updated_at];

export const PAGE_SIZES = [
  { label: 10, value: 10 },
  { label: 30, value: 30 },
  { label: 50, value: 50 },
];

export const SORT_DIRECTION = {
  asc: "asc", // по возрастанию
  desc: "desc", // по убыванию
};

export const SORT_TYPES = {
  score: "", // по релевантности
  createdAt: "created_at", // по дате создания
  updatedAt: "updated_at", // по дате обновления
  doneAt: "done_at", // по дате закрытия
};

export const COMPARISONS = {
  eq: { value: "eq", label: "=" }, // равно
  neq: { value: "neq", label: <>&#8800;</> }, // не равно
  lt: { value: "lt", label: <>&#60;</> }, // меньше чем
  lte: { value: "lte", label: <>&#8804;</> }, // меньше или равно
  gt: { value: "gt", label: <>&#62;</> }, // больше чем
  gte: { value: "gte", label: <>&#8805;</> }, // больше или равно
};
export const EXISTENCE = {
  ex: { value: "ex", label: "существует" }, // существует
  nex: { value: "nex", label: "не существует" }, // не существует
};

const useSearch = () => {
  // const [searching, setSearching] = useState(false);

  function getFromParam(pageNumber, pageSize) {
    return (pageNumber - 1) * pageSize;
  }

  async function onSearch(params) {
    const stringParams = _searchParamsStringify(params);

    try {
      const response = await SEARCH_API.searchRequest(stringParams);

      return response.data;
    } catch (error) {
      console.error(error);

      return await Promise.reject(error);
    }
  }

  async function getFullDataOfSearchResults(searchResults = []) {
    try {
      const sortedResults = _sortResultsByType(searchResults);
      const fullData = await (await _getFullData(sortedResults)).flat();
      const combinedData = _combineResult(searchResults, fullData);

      return combinedData;
    } catch (error) {
      console.error(error);

      return await Promise.reject(error);
    }
  }

  // Разделение результатов поиска по типу
  function _sortResultsByType(results = []) {
    return results.reduce((sorted, searchResult) => {
      const { type } = searchResult;

      if (type in sorted) {
        sorted[type].push(searchResult);
      } else {
        sorted[type] = [searchResult];
      }

      return sorted;
    }, {});
  }

  // Получение полных данных поисковых результатов
  function _getFullData(sortedResults = {}) {
    const promises = Object.entries(sortedResults).reduce((acc, current) => {
      const [type, values] = current;
      const func = _getRequest(type);

      acc.push(func(values));

      return acc;
    }, []);

    return Promise.all(promises)
      .then((result) => result)
      .catch(() => []);
  }

  // Объединение поисковых результатов и полных данных
  function _combineResult(searchResults = [], fullData = []) {
    return searchResults.map((searchResult) => {
      const data = fullData.find((item) => item.id === searchResult.id);

      return data !== undefined ? { ...searchResult, ...data } : searchResult;
    });
  }

  // Возвращает запрос для получения полных данных в зависимости от типа
  function _getRequest(searchResultType) {
    switch (searchResultType) {
      case SEARCH_RESULT_TYPES.task:
        return (tasks) =>
          getTasks(tasks.map((task) => task.id))
            .then((resp) => resp.data.map((task) => _addFullNameInResults(task)))
            .catch((err) => {
              console.error(err);
              return [];
            });
      case SEARCH_RESULT_TYPES.issue:
        return (issues) =>
          getIssues(issues.map((issue) => issue.id))
            .then((resp) => resp.data.map((issue) => _addFullNameInResults(issue)))
            .catch((err) => {
              console.error(err);
              return [];
            });
      case SEARCH_RESULT_TYPES.mass_problem:
        return (massProblems) =>
          getMassProblems(massProblems.map((mp) => mp.id))
            .then((resp) => resp.data.map((mp) => _addFullNameInResults(mp)))
            .catch((err) => {
              console.error(err);
              return [];
            });
      case SEARCH_RESULT_TYPES.task_comment:
      case SEARCH_RESULT_TYPES.issue_comment:
      case SEARCH_RESULT_TYPES.mass_problem_comment:
        return (comment) => _getCommentFullData(comment);
      default:
        return (f) => f;
    }
  }

  function _getCommentFullData(comments = []) {
    const promises = comments.map(async (comment) => {
      const commentData = { ...comment };

      await OPERATORS_API.getOperatorData(commentData.operator_id).then((resp) => {
        commentData.operator = resp.data;
      });

      return commentData;
    });

    return Promise.all(promises)
      .then((resp) => resp)
      .catch(() => []);
  }

  function _searchParamsStringify(params) {
    const {
      query = "",
      filters = [],
      sort = {},
      from = 0,
      size = 0,
      count_only = false,
    } = params;
    const queryParams = [];

    // Добавляем поиск по строке
    if (query.length > 0) {
      queryParams.push(`query=${query}&`);
    }

    // Добавляем фильтры
    queryParams.push(
      filters.reduce((params, filter) => {
        if (filter.existence && filter.existence.selected) {
          return params + `${filter.value},${filter.existence.selected}&`;
        }
        if (DATE_FILTERS.includes(filter.value)) {
          return params + _dateFilterStringify(filter);
        }

        return (
          params +
          filter.selected.reduce(
            (current, selected) =>
              current + `${filter.value},${selected.comparison}=${selected.value}&`,
            ""
          )
        );
      }, "")
    );

    // Добавляем сортировку
    if (sort.value) {
      queryParams.push(`sort=${sort.value},${sort.direction}&`);
    }

    // Добавляем смещение и количество
    queryParams.push(`from=${from}&size=${size}&`);

    // Добавляем параметр count_only
    queryParams.push(`count_only=${count_only}`);

    // Приводим массив к строке
    const queryString = queryParams.join("");

    // Убираем оканчивающее "&"" если есть
    return queryString.endsWith("&")
      ? queryString.substring(0, queryString.length - 1)
      : queryString;
  }

  function _dateFilterStringify(dateFilter) {
    const { selected, value } = dateFilter;
    const isMoment = selected.every((selected) => moment.isMoment(selected.value));

    if (isMoment) {
      const isEqual = isEqualMoments(selected.map((date) => date.value));

      return isEqual
        ? `${value},${selected[0].comparison}=${_getDateString(selected[0].value)}&`
        : selected.reduce(
            (string, current) =>
              string + `${value},${current.comparison}=${_getDateString(current.value)}&`,
            ""
          );
    } else {
      return "";
    }
  }

  function _getDateString(momentDate) {
    return momentDate.format("YYYY-MM-DD");
  }

  function _addFullNameInResults(appeal) {
    const formatted = { ...appeal };

    if (formatted.user) {
      const { current_pp_name, first_name, last_name } = formatted.user;
      const full_name = `${last_name} ${first_name}`.trim();

      formatted.user = {
        ...formatted.user,
        full_name: current_pp_name ? `${current_pp_name} (${full_name})` : full_name,
      };
    }
    if (formatted.operator) {
      formatted.operator = {
        ...formatted.operator,
        full_name: `${formatted.operator.last_name} ${formatted.operator.first_name}`,
      };
    }
    if (formatted.from_operator) {
      formatted.from_operator = {
        ...formatted.from_operator,
        full_name: `${formatted.from_operator.last_name} ${formatted.from_operator.first_name}`,
      };
    }

    return formatted;
  }

  return { onSearch, getFullDataOfSearchResults, getFromParam };
};

export default useSearch;
