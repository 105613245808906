import api from "./api";
import { HARDWARE_CHANGES, HARDWARE_API_URLS } from "../consts";

/**
 * Добавить новое оборудование
 * @param {string} identifier
 */
const postHardware = async (identifier) => {
  const url = HARDWARE_API_URLS.postHardware();
  return await api.protectedPost(url, { identifier });
};

/**
 * Добавить оборудование к задаче
 * @param {string} taskId
 * @param {string} hardwareId
 */
const putTaskHardware = async (taskId, hardwareId) => {
  const url = HARDWARE_API_URLS.putTaskHardware(taskId);
  return api.protectedPut(url, { hardware_id: hardwareId });
};

/**
 * Удалить оборудование из задачи
 * @param {string} taskId
 * @param {string} hardwareId
 */
const deleteTaskHardware = async (taskId, hardwareId) => {
  const url = HARDWARE_API_URLS.deleteTaskHardware(taskId);
  return api.protectedDelete(url, { hardware_id: hardwareId });
};

/**
 * Добавить изменение оборудования
 * @param {string} hardwareId
 * @param {string} text
 */
const postHardwareChange = async (hardwareId, text) => {
  const url = HARDWARE_API_URLS.postHardwareChange(hardwareId);
  return api.protectedPost(url, { text });
};

/**
 * Редактировать изменение оборудования
 * @param {string} hardwareChangeId
 * @param {string} text
 */
const putHardwareChange = async (hardwareChangeId, text) => {
  const url = HARDWARE_API_URLS.putHardwareChange(hardwareChangeId);
  return api.protectedPut(url, { text });
};

/**
 * Удалить изменение оборудования
 * @param {string} hardwareChangeId
 */
const deleteHardwareChange = async (hardwareChangeId) => {
  const url = HARDWARE_API_URLS.deleteHardwareChange(hardwareChangeId);
  return api.protectedDelete(url);
};

/**
 * Получить список изменений оборудования
 * @param {string} hardwareId
 */
const getHardwareChanges = async (hardwareId) => {
  const url = HARDWARE_API_URLS.getHardwareChanges(hardwareId);
  return api.protectedGet(url);
};

/**
 * Поиск оборудования
 * @param {string} query
 * @param {string} userId
 */
const searchHardware = async (query, userId) => {
  const url = HARDWARE_API_URLS.searchHardware(query, userId);
  return api.protectedGet(url);
};

const putHardwareEdintifier = async (hardwareId, identifier) => {
  const url = HARDWARE_API_URLS.putHardwareEdintifier(hardwareId);
  return api.protectedPut(url, { identifier });
};

export async function editChangeHardware(changeID, text) {
  return await api.protectedPut(`${HARDWARE_CHANGES}/${changeID}/text`, {
    text,
  });
}

export async function deleteChangeHardware(changeID) {
  return await api.protectedDelete(`${HARDWARE_CHANGES}/${changeID}`);
}

const HARDWARE_API = {
  postHardware,
  putTaskHardware,
  deleteTaskHardware,
  postHardwareChange,
  putHardwareChange,
  deleteHardwareChange,
  getHardwareChanges,
  searchHardware,
  putHardwareEdintifier,
};

export default HARDWARE_API;
