import api from "./api";
import { BASE_URL, FILE_API_URLS } from "../consts";
import { store } from "../";

export async function getFileByID(fileID) {
  const { token } = await store.getState().user;
  const url = FILE_API_URLS.getFile(fileID);

  const resp = await fetch(`${BASE_URL}${url}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (resp.status === 200) return resp.blob();

  return Promise.reject();
}

export async function getImagePreview(imagePreviewID) {
  const { token } = await store.getState().user;
  const url = FILE_API_URLS.getFilePreview(imagePreviewID);

  const resp = await fetch(`${BASE_URL}${url}`, {
    method: "GET",
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (resp.status === 200) return resp.blob();

  return Promise.reject();
}

export async function deleteFile(fileID) {
  const url = FILE_API_URLS.deleteFile(fileID);
  return await api.protectedGet(url);
}

export async function uploadFiles(files) {
  const url = FILE_API_URLS.uploadFiles();
  return await api.protectedPostBinary(url, files);
}
