import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  addTab,
  findTab,
  setTabComponent,
  setActiveTab,
} from "../../actions/tabs/tabs";
import { getComponentUrl } from ".";

export function useTabs() {
  const dispatch = useDispatch();
  const delay = 250; // ms, 500 ms default timing in Windows

  // Store
  const tabs = useSelector((store) => store.tabs.list);

  // State
  const [timer, setTimer] = useState(null);

  function clearTimer(timer) {
    setTimer(null);
    clearTimeout(timer);
  }

  function openInNewTab({ component, params, locationState }) {
    const tabUrl = getComponentUrl(component, params);
    const tabDublicate = findTab(tabUrl, tabs);

    if (tabDublicate) {
      dispatch(setActiveTab(tabDublicate));
    } else {
      dispatch(addTab(component, params, locationState));
    }
  }
  function openInCurrentTab({ component, params, locationState }) {
    const tabUrl = getComponentUrl(component, params);
    const tabDublicate = findTab(tabUrl, tabs);

    if (tabDublicate) {
      dispatch(setActiveTab(tabDublicate));
    } else {
      dispatch(setTabComponent(component, params, locationState));
    }
  }

  function clickHandler({ component, params, locationState }) {
    if (typeof timer === "number") {
      clearTimer(timer);
      openInCurrentTab({ component, params, locationState });
    } else {
      setTimer(
        setTimeout(() => {
          clearTimer(timer);
          openInNewTab({ component, params, locationState });
        }, delay)
      );
    }
  }

  return { clickHandler, openInCurrentTab, openInNewTab };
}
