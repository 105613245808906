import api from "./api";
import { ISSUE_API_URLS } from "../consts";

export function createNewAppeal(appeal) {
  const url = ISSUE_API_URLS.create();
  return api.protectedPost(url, appeal);
}

export function createEmptyIssue(emptyIssue) {
  const url = ISSUE_API_URLS.createEmpty();
  return api.protectedPost(url, emptyIssue);
}

export function getAppealData(appealID) {
  const url = ISSUE_API_URLS.getIssue(appealID);
  return api.protectedGet(url);
}

export function setAppealDescription(appealID, description) {
  const url = ISSUE_API_URLS.setIssueDescription(appealID);
  return api.protectedPut(url, { description });
}

export function setAppealOperator(appealID, operator_id) {
  const url = ISSUE_API_URLS.setIssueOperator(appealID);
  return api.protectedPut(url, { operator_id });
}

export async function closeAppeal(appealID) {
  const url = ISSUE_API_URLS.closeIssue(appealID);
  return api.protectedPost(url);
}

export function editComment(comment_id, comment) {
  const url = ISSUE_API_URLS.editIssueComment(comment_id);
  return api.protectedPut(url, { comment });
}

export function setAppealComment(appealID, comment) {
  const url = ISSUE_API_URLS.postIssueComment(appealID);
  return api.protectedPost(url, { comment });
}

export function getAppealComments(appealID) {
  const url = ISSUE_API_URLS.getIssueComments(appealID);
  return api.protectedGet(url);
}

export function getUserAppeals(userID) {
  const url = ISSUE_API_URLS.getUserIssues(userID);
  return api.protectedGet(url);
}

export function getUserAppealsClosed(userID, offset, limit) {
  const url = ISSUE_API_URLS.getUserClosedIssues(userID, offset, limit);
  return api.protectedGet(url);
}

export function getOperatorAppeals(opID, expand = ["user", "operator"]) {
  const url = ISSUE_API_URLS.getOperatorIssues(opID, expand);
  return api.protectedGet(url);
}

export function getOperatorAppealsClosed(opID, offset, limit) {
  const url = ISSUE_API_URLS.getOperatorClosedIssues(opID, offset, limit);
  return api.protectedGet(url);
}

export function getOpGroupAppeals(opID) {
  const url = ISSUE_API_URLS.getOperatorsGroupIssues(opID);
  return api.protectedGet(url);
}

export function getOpGroupAppealsClosed(opID, offset, limit) {
  const url = ISSUE_API_URLS.getOperatorsGroupClosedIssues(opID, offset, limit);
  return api.protectedGet(url);
}

export function uploadAppealFiles(appealID, file_ids) {
  const url = ISSUE_API_URLS.uploadIssueFiles(appealID);
  return api.protectedPost(url, { file_ids });
}

export function deleteAppealFile(appealID, fileID) {
  const url = ISSUE_API_URLS.deleteIssueFile(appealID, fileID);
  return api.protectedDelete(url);
}

export function getRelatedTasks(appealID) {
  const url = ISSUE_API_URLS.getIssueRelatedTasks(appealID);
  return api.protectedGet(url);
}

export function getAppealChanges(appealID) {
  const url = ISSUE_API_URLS.getIssueChanges(appealID);
  return api.protectedGet(url);
}

export function getIssues(ids = []) {
  const url = ISSUE_API_URLS.getIssues(ids);
  return api.protectedGet(url);
}

const ISSUES_API = {
  createNewAppeal,
  createEmptyIssue,
  getAppealData,
  setAppealDescription,
  setAppealOperator,
  closeAppeal,
  editComment,
  setAppealComment,
  getAppealComments,
  getUserAppeals,
  getUserAppealsClosed,
  getOperatorAppeals,
  getOperatorAppealsClosed,
  getOpGroupAppeals,
  getOpGroupAppealsClosed,
  uploadAppealFiles,
  deleteAppealFile,
  getRelatedTasks,
  getAppealChanges,
  getIssues,
};

export default ISSUES_API;
