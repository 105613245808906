import {
  GET_USER_INFO,
  GET_OPERATOR_PERMISSIONS,
  SET_OPERATORS_GROUP_NAME,
} from "../actions/operator/actionTypes";
import * as ls from "../localStorage";

import AUTH_ACTION_TYPES from "../actions/auth/actionTypes";

function getInitialState() {
  const initialState = {
    token: null,
    expireAt: null,
    id: null,
    username: "",
    firstName: "",
    lastName: "",
    lastActiveAt: null,
    operatorsStatusId: null,
    openTasksCount: 0,
    groupName: null,
    permissions: [],
    isAuth: undefined, // true - авторизован, false - не авторизован, undefined - неизвестно авторизован пользователь или нет (используется только в момент инициализации приложения)
    refreshToken: "",
  };

  for (let key in initialState) {
    if (ls.Get(key)) {
      initialState[key] = JSON.parse(ls.Get(key));
    }
  }

  return initialState;
}

export function userReducer(state = getInitialState(), action) {
  switch (action.type) {
    case AUTH_ACTION_TYPES.LOGIN:
      return {
        ...state,
        ...action.payload,
      };
    case AUTH_ACTION_TYPES.LOGOUT:
      return { isAuth: false };
    case AUTH_ACTION_TYPES.SET_AUTH_STATUS:
      return { ...state, isAuth: action.payload };

    case GET_USER_INFO:
      return { ...state, ...action.payload };
    case GET_OPERATOR_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload,
      };
    case SET_OPERATORS_GROUP_NAME:
      return { ...state, groupName: action.payload };
    default: {
      return { ...state };
    }
  }
}
