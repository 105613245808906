import api from "./api";
import { TASK_API_URLS } from "../consts";

/**
 * @enum Получаем
 */
const TASK_EXPANDS = {
  operatorsGroup: "operators_group",
  operator: "operator",
  fromOperator: "from_operator",
  issue: "issue",
  location: "location",
  taskComments: "task_comments",
  files: "files",
  user: "user",
  issueRelatedUser: "issue_related_user",
  template: "template",
  hardware: "hardware",
};

export async function createTask(data, type) {
  const {
    performerID,
    issue_id,
    description,
    location_id,
    file_ids,
    urgency,
    user_id,
    template_id,
    hardware_id,
  } = data;

  const payload = {
    description,
    location_id,
    file_ids,
    urgency,
    template_id,
    hardware_id,
  };
  const operator = { operator: "operator_id", group: "operators_group_id" };

  payload[operator[type]] = performerID;

  if (issue_id) payload.issue_id = issue_id;
  if (user_id) payload.user_id = user_id;

  const url = TASK_API_URLS.createTask();
  return await api.protectedPost(url, payload);
}

export async function getOperatorTasks(opID, expand = "user,operator,operators_group") {
  const url = TASK_API_URLS.getOperatorOpenTasks(opID, expand.split(","));
  return await api.protectedGet(url);
}

export async function getOperatorsGroupTasks(groupID, expand) {
  const url = TASK_API_URLS.getOperatorsGroupOpenTasks(groupID, expand);
  return await api.protectedGet(url);
}

export async function getOperatorClosedTasks(
  opID,
  pageSize,
  curPage,
  expand = ["user", "operator", "operators_group"]
) {
  const offset = pageSize * (curPage - 1);
  const url = TASK_API_URLS.getOperatorLastClosedTasks(opID, offset, pageSize, expand);
  return await api.protectedGet(url);
}

export async function getOperatorsGroupClosedTasks(
  groupID,
  pageSize,
  curPage,
  expand = ["user", "operator", "operators_group"]
) {
  const offset = pageSize * (curPage - 1);
  const url = TASK_API_URLS.getOperatorsGroupLastClosedTasks(
    groupID,
    offset,
    pageSize,
    expand
  );
  return await api.protectedGet(url);
}

export async function getTaskByID(
  id,
  expand = [
    TASK_EXPANDS.issue,
    TASK_EXPANDS.files,
    TASK_EXPANDS.user,
    TASK_EXPANDS.operatorsGroup,
    TASK_EXPANDS.template,
    TASK_EXPANDS.hardware,
  ]
) {
  const url = TASK_API_URLS.getTask(id, expand);
  return await api.protectedGet(url);
}

export async function editTaskDescription(id, description) {
  const url = TASK_API_URLS.setTaskDescription(id);
  return await api.protectedPut(url, { description });
}

export async function changeOperator(taskID, operator_id) {
  const url = TASK_API_URLS.setTaskOperator(taskID);
  return await api.protectedPut(url, { operator_id });
}

export async function changeOperatorsGroup(taskID, operators_group_id) {
  const url = TASK_API_URLS.setTaskOperatorsGroup(taskID);
  return await api.protectedPut(url, { operators_group_id });
}

export async function closeTask(id) {
  const url = TASK_API_URLS.closeTask(id);
  return await api.protectedPost(url);
}

export async function getTaskChanges(taskID) {
  const url = TASK_API_URLS.getTaskChanges(taskID);
  return await api.protectedGet(url);
}

export async function uploadTaskFiles(taskId, file_ids) {
  const url = TASK_API_URLS.uploadTaskFiles(taskId);
  return await api.protectedPost(url, { file_ids });
}

export async function postTaskComment(taskId, comment) {
  const url = TASK_API_URLS.postTaskComment(taskId);
  return await api.protectedPost(url, { comment });
}

export async function getTaskComments(taskId) {
  const url = TASK_API_URLS.getTaskComments(taskId);
  return await api.protectedGet(url);
}

export async function editTaskComment(commentId, comment) {
  const url = TASK_API_URLS.editTaskComment(commentId);
  return await api.protectedPut(url, { comment });
}

export async function getTasks(ids = [], expand = ["operators_group"]) {
  const url = TASK_API_URLS.getTasks(ids, expand);
  return await api.protectedGet(url);
}

export async function setTaskPriority(taskId, urgency = false) {
  const url = TASK_API_URLS.setTaskPriority(taskId);
  return await api.protectedPost(url, { urgency });
}

export function taskReopen(taskId) {
  const url = TASK_API_URLS.reopenTask(taskId);
  return api.protectedPost(url);
}

export function putTaskUser(taskId, userId) {
  const url = TASK_API_URLS.setTaskUser(taskId);
  return api.protectedPut(url, { user_id: userId });
}

export function taskReject(taskId) {
  const url = TASK_API_URLS.taskReject(taskId);
  return api.protectedPost(url);
}

const putTaskTemplate = async (taskId, templateId) => {
  const url = TASK_API_URLS.setTaskTemplate(taskId);
  return await api.protectedPut(url, { template_id: templateId });
};

const deleteTaskTemplate = async (taskId) => {
  const url = TASK_API_URLS.deleteTaskTemplate(taskId);
  return await api.protectedDelete(url);
};

const TASKS_API = {
  createTask,
  getOperatorTasks,
  getOperatorsGroupTasks,
  getOperatorClosedTasks,
  getOperatorsGroupClosedTasks,
  getTaskByID,
  editTaskDescription,
  changeOperator,
  changeOperatorsGroup,
  closeTask,
  getTaskChanges,
  uploadTaskFiles,
  postTaskComment,
  getTaskComments,
  editTaskComment,
  getTasks,
  setTaskPriority,
  taskReopen,
  putTaskUser,
  taskReject,
  putTaskTemplate,
  deleteTaskTemplate,
};

export default TASKS_API;
