export const ADD_TAB = "ADD_TAB";
export const REMOVE_TAB = "REMOVE_TAB";
export const SET_TAB_ERROR = "SET_TAB_ERROR";
export const SET_ACTIVE_TAB = "SET_ACTIVE_TAB";
export const SET_TAB_COMPONENT = "SET_TAB_COMPONENT";
export const SET_TAB_COMPONENT_PROPS = "SET_TAB_COMPONENT_PROPS";
export const OPEN_PREVIOUSLY_OPENED_TABS = "OPEN_PREVIOUSLY_OPENED_TABS";

const TAB_ACTION_TYPES = {
  ADD_TAB,
  REMOVE_TAB,
  SET_TAB_ERROR,
  SET_ACTIVE_TAB,
  SET_TAB_COMPONENT,
  SET_TAB_COMPONENT_PROPS,
  OPEN_PREVIOUSLY_OPENED_TABS,
};

export default TAB_ACTION_TYPES;
