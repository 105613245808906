import checkUUID from "./checkUUID";

function checkRoute(requiredRoute, currentRoute) {
  const requiredArray = requiredRoute.replace("/", "").split("/");
  const currentArray = currentRoute.replace("/", "").split("/");

  for (let i = 0; i <= requiredArray.length; i++) {
    if (requiredArray[i] === currentArray[i]) {
      continue;
    } else if (requiredArray[i].startsWith(":") && checkUUID(currentArray[i])) {
      continue;
    }

    return false;
  }

  return true;
}

export default checkRoute;
