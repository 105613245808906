import { useCallback } from "react";
import moment from "moment";
import { message } from "antd";

import useTextArea from "../../../hooks/texarea.hook";

const useUserMessage = ({ setComponentProps, messages = {} }) => {
  const { getSelection, setSelection, putNewLine } = useTextArea();

  const addUserMessage = useCallback(
    (m) => {
      if ("file" in m) {
        message.warning("Добавление файлов в разработке");
        return;
      }

      setComponentProps({ userMessages: { ...messages, [m.id]: m } });
    },
    [messages, setComponentProps]
  );

  const removeUserMessage = useCallback(
    (messageId) => {
      const { [messageId]: removed, ...rest } = messages;
      setComponentProps({ userMessages: { ...rest } });
    },
    [messages, setComponentProps]
  );

  const editUserMessage = useCallback(
    (messageId, bool) => {
      setComponentProps({
        userMessages: {
          ...messages,
          [messageId]: { ...messages[messageId], isEditing: bool },
        },
      });
    },
    [messages, setComponentProps]
  );

  const сhangeUserMessageText = useCallback(
    (messageId, value) => {
      setComponentProps({
        userMessages: {
          ...messages,
          [messageId]: { ...messages[messageId], message: value },
        },
      });
    },
    [messages, setComponentProps]
  );

  const _putNewLine = useCallback((input) => {
    const selection = getSelection(input);

    const newValue = putNewLine(input.value, selection);
    const newSelection = {
      start: selection.start + 1,
      end: selection.start + 1,
    };

    return { value: newValue, selection: newSelection };
  }, []);

  const onInputPressEnter = useCallback(
    (messageId, event) => {
      if (event.ctrlKey) {
        const { value, selection } = _putNewLine(event.target);

        setSelection(event.target, selection);
        setComponentProps({
          userMessages: {
            ...messages,
            [messageId]: { ...messages[messageId], message: value },
          },
        });
      } else if (!event.ctrlKey) {
        editUserMessage(messageId, false);
      }
    },
    [messages, setComponentProps, setSelection, editUserMessage, _putNewLine]
  );

  const getMessagesArray = useCallback((messages = {}) => {
    return Object.values(messages).sort((a, b) => {
      const aMoment = moment(a.created_at);
      const bMoment = moment(b.created_at);

      if (aMoment.diff(bMoment) > 0) {
        return 1;
      }
      if (aMoment.diff(bMoment) < 0) {
        return -1;
      }

      return 0;
    });
  }, []);

  const formDescription = (description, messages = [], messageSeparator = `\n\n`) => {
    if (messages.length > 0) {
      return `${description}\n${messages.map((m) => m.message).join(messageSeparator)}`;
    } else {
      return description;
    }
  };

  return {
    addUserMessage,
    removeUserMessage,
    editUserMessage,
    сhangeUserMessageText,
    getMessagesArray,
    formDescription,
    onInputPressEnter,
  };
};

export default useUserMessage;
