import React, { useEffect, useState } from "react";
import throttle from "lodash.throttle";
import { Drawer, Button } from "antd";
import { DoubleRightOutlined } from "@ant-design/icons";

import "./style.scss";

const BREAKPOINT = 1280; // px
const BUTTON_STYLE = {
  padding: "0 10px",
  height: "100%",
  width: "60px",
  flex: "0 0 auto",
};
const ICON_STYLE = {
  position: "absolute",
  top: "20px",
  left: "50%",
  transform: "translateX(-50%)",
};

const Filters = (props) => {
  const { children, onReset, onSave, title = "Фильтры" } = props;

  return (
    <div className="component_search-filter">
      <h5 className="search-filter__label">
        <span className="search-filter__filter-label">{title}</span>
        {typeof onSave === "function" && (
          <Button
            className="label__button_save"
            children="Сохранить"
            type="link"
            onClick={onSave}
          />
        )}
        {typeof onReset === "function" && (
          <Button
            className="label__button_reset"
            children="Сбросить"
            type="link"
            onClick={onReset}
          />
        )}
      </h5>
      {children}
    </div>
  );
};

const SearchFilters = (props) => {
  const {
    children = [],
    onSaveFilters,
    onResetFilters,
    drawerVisible = undefined,
    onChangeDrawerVisible = undefined,
  } = props;

  // State
  const [isCollapsed, setIsCollapsed] = useState(checkBreakpoint(BREAKPOINT));
  const [drawerVisibleState, setDrawerVisibleState] = useState(
    drawerVisible !== undefined ? drawerVisible : false
  );

  function checkBreakpoint(breakPoint = 0) {
    return window.innerWidth < breakPoint;
  }

  const throttleResize = throttle(() => {
    setIsCollapsed(checkBreakpoint(BREAKPOINT));
  }, 300);

  function getDrawerProps(visible, onChange) {
    return {
      visible: visible !== undefined ? visible : drawerVisibleState,
      onChangeVisible:
        onChange !== undefined ? onChange : setDrawerVisibleState,
    };
  }

  function drawFilterContainer(inDrawer = false) {
    if (inDrawer) {
      const { visible, onChangeVisible } = getDrawerProps(
        drawerVisible,
        onChangeDrawerVisible
      );

      return (
        <>
          <Button
            type="text"
            size="large"
            style={BUTTON_STYLE}
            icon={<DoubleRightOutlined style={ICON_STYLE} />}
            onClick={() => onChangeVisible(true)}
          />
          <Drawer
            width={300}
            placement="left"
            closable={false}
            getContainer={false}
            visible={visible}
            onClose={() => onChangeVisible(false)}
            bodyStyle={{ padding: "unset" }}
            style={{ position: "absolute" }} // position: absolute для отображения внутри текущего контейнера
          >
            <Filters onReset={onResetFilters} onSave={onSaveFilters}>
              {children}
            </Filters>
          </Drawer>
        </>
      );
    } else {
      return (
        <Filters onReset={onResetFilters} onSave={onSaveFilters}>
          {children}
        </Filters>
      );
    }
  }

  useEffect(() => {
    window.addEventListener("resize", throttleResize);

    return () => {
      window.removeEventListener("resize", throttleResize);
    };
  }, []);

  return drawFilterContainer(isCollapsed);
};

export { FilterContainer } from "./filterContainer";

export default SearchFilters;
