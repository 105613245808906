import React, { useState, useEffect } from "react";

import Table, { getRowIcon } from "../Table";
// import Spinner from "../Spinner";

import normalizeDate, { FULL_DATE } from "../../utils/normalizeDate";
import { useTabs, COMPONENTS } from "../../services/tabs";
import useSearch, {
  FILTERS,
  COMPARISONS,
  SORT_TYPES,
  SORT_DIRECTION,
} from "../../hooks/search.hook";

const PAGE_SIZE = 10;

function drawTable({
  data,
  loading,
  title,
  total,
  currentPage,
  onChangePage = () => {},
  onRowClick = () => {},
}) {
  if (Array.isArray(data) && data.length === 0) return null;

  const columns = [
    {
      dataIndex: "done_at",
      render: (text) => getRowIcon(text),
      width: 40,
      align: "center",
    },
    {
      dataIndex: "human_readable_id",
      title: "Номер",
      width: 80,
      align: "center",
    },
    {
      title: "Создано",
      dataIndex: "created_at",
      render: (text) => normalizeDate(text, FULL_DATE),
      width: 80,
    },
    {
      title: "Описание",
      dataIndex: "description",
      ellipsis: true,
      customFilter: true,
    },
    {
      title: "Исполнитель",
      dataIndex: ["operator", "last_name"],
      render: (text, row) =>
        row.operator
          ? `${row.operator.last_name} ${row.operator.first_name}`
          : row.operators_group.title,
      width: 200,
      ellipsis: true,
    },
  ];

  return (
    <Table
      loading={loading}
      pagination={{
        size: "small",
        position: ["bottomCenter"],
        total: total,
        current: currentPage,
        pageSize: PAGE_SIZE,
        onChange: onChangePage,
      }}
      data={data}
      columns={columns}
      onRowClick={onRowClick}
      title={title}
      rowKey={(row) => row.id}
    />
  );
}

const AllUserAppeals = (props) => {
  const { userId, displayIssues = false, displayTasks = false } = props;

  // State
  const [tasks, setTasks] = useState([]);
  const [issues, setIssues] = useState([]);
  const [tasksPage, setTasksPage] = useState(1);
  const [issuesPage, setIssuesPage] = useState(1);
  const [totalTasks, setTotalTasks] = useState(0);
  const [totalIssues, setTotalIssues] = useState(0);
  const [tasksFetching, setTasksFetching] = useState(false);
  const [issuesFetching, setIssuesFetching] = useState(false);

  const { openInNewTab } = useTabs();
  const { onSearch, getFullDataOfSearchResults, getFromParam } = useSearch();

  function onIssuesPageChange(userId, page) {
    setIssuesPage(page);
    getUserIssues(userId, page);
  }

  function onTasksPageChange(userId, page) {
    setTasksPage(page);
    getUserTasks(userId, page);
  }

  async function getUserIssues(userId, page) {
    const searchParams = {
      filters: [
        {
          value: FILTERS.type,
          selected: [{ value: "issue", comparison: COMPARISONS.eq.value }],
        },
        {
          value: FILTERS.user_id,
          selected: [{ value: userId, comparison: COMPARISONS.eq.value }],
        },
      ],
      sort: { value: SORT_TYPES.createdAt, direction: SORT_DIRECTION.desc },
      from: getFromParam(page, PAGE_SIZE),
      size: PAGE_SIZE,
    };

    await setIssuesFetching(true);

    try {
      const results = await onSearch(searchParams);
      const resultsFullData = await getFullDataOfSearchResults(results.hits);

      setIssues(resultsFullData);
      setTotalIssues(results.total_hits);
    } catch (err) {
      console.error(err);
    }

    await setIssuesFetching(false);
  }

  async function getUserTasks(userId, page) {
    const searchParams = {
      filters: [
        {
          value: FILTERS.type,
          selected: [{ value: "task", comparison: COMPARISONS.eq.value }],
        },
        {
          value: FILTERS.user_id,
          selected: [{ value: userId, comparison: COMPARISONS.eq.value }],
        },
      ],
      sort: { value: SORT_TYPES.createdAt, direction: SORT_DIRECTION.desc },
      from: getFromParam(page, PAGE_SIZE),
      size: PAGE_SIZE,
    };

    await setTasksFetching(true);

    try {
      const results = await onSearch(searchParams);
      const resultsFullData = await getFullDataOfSearchResults(results.hits);

      setTasks(resultsFullData);
      setTotalTasks(results.total_hits);
    } catch (err) {
      console.error(err);
    }

    await setTasksFetching(false);
  }

  function openIssueCard(e, issueId) {
    e.preventDefault();
    e.stopPropagation();

    openInNewTab({
      component: COMPONENTS.ISSUE_CARD,
      params: { issueId },
      locationState: { issueId },
    });
  }

  function openTaskCard(e, taskId) {
    e.preventDefault();
    e.stopPropagation();

    openInNewTab({
      component: COMPONENTS.TASK_CARD,
      params: { taskId },
      locationState: { taskId },
    });
  }

  useEffect(() => {
    if (userId) {
      if (displayTasks) {
        getUserTasks(userId, 1);
      }
      if (displayIssues) {
        getUserIssues(userId, 1);
      }
    } else {
      setIssues([]);
      setTasks([]);
      setTasksFetching(false);
      setIssuesFetching(false);
    }
  }, [userId]);

  if (!userId) return null;
  if (!(displayIssues || displayTasks)) return null;

  return (
    <div className="component_all-user-appeals">
      {displayIssues &&
        drawTable({
          data: issues,
          total: totalIssues,
          loading: issuesFetching,
          currentPage: issuesPage,
          title: () => <h4>Обращения</h4>,
          onRowClick: (e, issue) => openIssueCard(e, issue.id),
          onChangePage: (page) => onIssuesPageChange(userId, page),
        })}

      {displayTasks &&
        drawTable({
          data: tasks,
          total: totalTasks,
          loading: tasksFetching,
          currentPage: tasksPage,
          title: () => <h4>Задачи</h4>,
          onRowClick: (e, task) => openTaskCard(e, task.id),
          onChangePage: (page) => onTasksPageChange(userId, page),
        })}
    </div>
  );
};

export default AllUserAppeals;
