import React from "react";

import MainContainer from "../../components/MainContainer";
import Search from "../../components/Search_new";

import { TYPES } from "../../components/Search_new/consts";
import { useTabs, COMPONENTS } from "../../services/tabs";

import "./style.scss";

const { employee, user } = TYPES;

export default function UserList() {
  // Hooks
  const { clickHandler } = useTabs();

  function updateParent(selected) {
    if (selected === null) {
      return;
    } else {
      clickHandler({
        component: COMPONENTS.USER_CARD,
        params: { userId: selected.id },
        locationState: { userId: selected.id },
      });
    }
  }

  return (
    <MainContainer title="Поиск пользователей">
      <div className="user-list__body">
        <Search
          placeholder="Поиск пользователей..."
          types={[user, employee]}
          updateParent={updateParent}
        />
      </div>
    </MainContainer>
  );
}
