export const GET_USER_INFO = "GET_USER_INFO";
export const GET_OPERATOR_PERMISSIONS = "GET_OPERATOR_PERMISSIONS";
export const SET_OPERATORS_GROUP_NAME = "SET_OPERATORS_GROUP_NAME";

const exportedObject = {
  GET_USER_INFO,
  GET_OPERATOR_PERMISSIONS,
  SET_OPERATORS_GROUP_NAME,
};

export default exportedObject;
