import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import Spinner from "../Spinner";
import ErrorMessage from "../ErrorMessage";

import { RESET_ERROR } from "../../actions/errors/actionTypes";

import "./style.scss";

export const COL_BREAKPOINTS = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
  xl: 8,
  xxl: 8,
};

export const ROW_GUTTER = [10, 10];

const DefaultCardHeader = (props) => {
  const { title } = props;

  return <h2 className="header__title">{title}</h2>;
};

/**
 * @param {Object} props;
 * @param {string} props.className
 * @param {string} props.bodyClassName
 * @param {React.Component} props.header
 * @param {React.Component} props.children
 * @param {string|number} props.maxWidth
 * @param {boolean} props.loading Флаг, который указывает на загрузку содержимого контейнера. Также активирует показ спиннера
 * @param {null|Object} props.error Если при отображении компонента не возникло никаких ошибок,то error = null,
 * иначе передать объект типа { message: 'Текст сообщение об ошибке', title: 'Некий заголовок', status: 'http-статус запроса', onRetry: function someRetryFunc() {'Функция "попробовать снова"'} }
 */
const MainContainer = (props) => {
  const {
    className = "",
    bodyClassName = "",
    bodyId,
    headerClassName = "",
    children,
    maxWidth,
    header,
    title = "",
    loading = false,
    error = null,
  } = props;

  const dispatch = useDispatch();

  function getCardHeader() {
    if (React.isValidElement(header)) {
      return header;
    }

    return <DefaultCardHeader title={title} />;
  }

  function drawSpinner() {
    return (
      <div className="main-card-container__spinner-container">
        <Spinner />
      </div>
    );
  }

  function drawError() {
    return <ErrorMessage {...error} />;
  }

  function drawBodyContent() {
    if (loading) return drawSpinner();
    if (error) return drawError();

    return children;
  }

  function removeError() {
    dispatch({ type: RESET_ERROR });
  }

  // componentWillUnmount
  useEffect(() => () => removeError(), []);

  return (
    <div
      className={`component_main-card-container main-content__card ${className}`}
      style={{ maxWidth: maxWidth }}
    >
      <div
        className={`main-card-container__header card__header ${headerClassName}`}
      >
        {getCardHeader()}
      </div>
      <div
        className={`main-card-container__body card__body ${bodyClassName}`}
        id={bodyId}
      >
        {drawBodyContent()}
      </div>
    </div>
  );
};

export default MainContainer;
