import React, { useState } from "react";
import { Row, Col, Descriptions } from "antd";

import Image from "../Image";
import UserName from "../UserName";
import EditableCardField, { EDITABLE_FIELD_TYPES } from "../EditableCardField";

import getPhoneLinks from "../../utils/getPhoneLinks";
import getEmailLinks from "../../utils/getEmalLinks";

const EDITABLE_FIELD = {
  workstationNumber: "workstaionNumber",
  workplaceLocation: "workplaceLocation",
};

const DEFAULT_SWITCHES = {
  [EDITABLE_FIELD.workplaceLocation]: false,
  [EDITABLE_FIELD.workstationNumber]: false,
};

const UserData = ({
  user = {},
  permissions = {},
  gutter = [12, 12],
  onCopyName,
  onClickName,
  onChangeWorkplaceLocation = () => {},
  onChangeWorkstationNumber = () => {},
}) => {
  const {
    full_name = "",
    photo,
    emails,
    phones,
    internal_phones,
    last_login_data,
    workplace_location,
    department,
    pp_partner_pick_up_points,
    current_pp_name,
  } = user;
  const {
    changingWorkplaceLocationAllowed = false,
    changingWorkstationNumberAllowed = false,
  } = permissions;

  const [switches, setSwitches] = useState(DEFAULT_SWITCHES);

  function toggleEditableField(fieldName) {
    setSwitches({ ...switches, [fieldName]: !switches[fieldName] });
  }

  return (
    <Row wrap gutter={gutter} xs={24}>
      <Col flex="180px">
        <Image src={photo} alt="Фото пользователя" />
      </Col>
      <Col flex={"1 1 0"} style={{ minWidth: 320 }}>
        <Descriptions bordered size="small">
          <Descriptions.Item span={3} label="Пользователь">
            {current_pp_name ? (
              <UserName.WithPickupPoint
                name={full_name}
                onClick={onClickName}
                onCopy={onCopyName}
                pickupPoint={current_pp_name}
              />
            ) : (
              <UserName.Link name={full_name} onClick={onClickName} onCopy={onCopyName} />
            )}
          </Descriptions.Item>
          {department && (
            <Descriptions.Item span={3} label="Департамент">
              {department}
            </Descriptions.Item>
          )}
          {pp_partner_pick_up_points?.length > 0 && (
            <Descriptions.Item span={3} label="Пункт самовывоза">
              {pp_partner_pick_up_points.join(" ")}
            </Descriptions.Item>
          )}
          <Descriptions.Item span={3} label="Телефон">
            {getPhoneLinks(phones)}
          </Descriptions.Item>
          <Descriptions.Item span={3} label="Внутренний телефон">
            {getPhoneLinks(internal_phones)}
          </Descriptions.Item>
          <Descriptions.Item span={3} label="Почта">
            {getEmailLinks(emails)}
          </Descriptions.Item>
          <Descriptions.Item span={3} label="Номер компьютера">
            <EditableCardField
              disabled={!changingWorkstationNumberAllowed}
              name={EDITABLE_FIELD.workstationNumber}
              isEdit={switches[EDITABLE_FIELD.workstationNumber]}
              value={last_login_data}
              type={EDITABLE_FIELD_TYPES.input}
              editField={toggleEditableField}
              saveChanges={onChangeWorkstationNumber}
            />
          </Descriptions.Item>
          <Descriptions.Item span={3} label="Местоположение">
            <EditableCardField
              disabled={!changingWorkplaceLocationAllowed}
              name={EDITABLE_FIELD.workplaceLocation}
              isEdit={switches[EDITABLE_FIELD.workplaceLocation]}
              value={workplace_location}
              type={EDITABLE_FIELD_TYPES.input}
              editField={toggleEditableField}
              saveChanges={onChangeWorkplaceLocation}
            />
          </Descriptions.Item>
        </Descriptions>
      </Col>
    </Row>
  );
};

export default UserData;
