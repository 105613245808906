import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { notification } from "antd";
import { closeNotification } from "../../actions/notification/notification";
import { useTabs } from "../tabs";
import NOTIFICATIONS, { getParamsToOpenTab } from "./types";

const Notification = (props, onClose) => {
  const { key, message, description, btn } = props;

  const config = {
    className: "notifications-service__notice",
    placement: "bottomRight",
    key,
    message,
    description,
    btn,
    onClose: () => onClose(key),
  };

  notification.open(config);
};

const NotificationsService = () => {
  const dispatch = useDispatch();

  const newNotifications = useSelector(
    (store) => store.notifications.newNotifications
  );

  const { clickHandler } = useTabs();

  function onClose(key) {
    dispatch(closeNotification(key));
  }

  async function getNotification(notice) {
    if (notice.type in NOTIFICATIONS) {
      try {
        const _notificationData = await NOTIFICATIONS[notice.type]({
          ...notice,
          onOpenTab: () => {
            clickHandler(getParamsToOpenTab(notice));
            notification.close(notice.key);
          },
        });

        Notification({ ..._notificationData, key: notice.key }, () =>
          onClose(notice.key)
        );
      } catch (err) {
        console.error(err);
      }
    }
  }

  useEffect(() => {
    const newNotification = newNotifications[newNotifications.length - 1];

    if (newNotification) {
      (async () => {
        await getNotification(newNotification);
      })();
    }
  }, [newNotifications]);

  return null;
};

export default NotificationsService;
