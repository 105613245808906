import React from "react";

import AllUserAppeals from "../AllUserAppeals";

const UserAppealsSection = (props) => {
  const { userId, permissions } = props;
  const { displayIssuesHistory, displayTasksHistory } = permissions;

  function drawSection() {
    return (
      <>
        <h3 className="section__header">История заявок пользователя</h3>
        <AllUserAppeals
          userId={userId}
          displayIssues={displayIssuesHistory}
          displayTasks={displayTasksHistory}
        />
      </>
    );
  }

  if (!userId) return null;
  if (!(displayIssuesHistory || displayTasksHistory)) return null;

  return (
    <section className="card__section card__section_user-appeals">
      {drawSection()}
    </section>
  );
};

export default UserAppealsSection;
