import snakeCaseToCamelCase from "./snakeCaseToCamelCase";

/**
 * Функция форматирует ключи объекта из snake_case в camelCase
 * @param {Object} object
 */
function formatResponseKeysToCamelCase(object) {
  const formattedObject = {};

  for (let key in object) {
    const camelCaseKey = snakeCaseToCamelCase(key);

    formattedObject[camelCaseKey] = object[key];
  }

  return formattedObject;
}

export default formatResponseKeysToCamelCase;
