import React, { useCallback } from "react";
import { Tabs as TabsAntd } from "antd";

import CommentForm from "../../components/comments";

import useRBAC, { UI_PERMISSIONS } from "../../services/rbac";
import TASKS_API from "../../api/tasks";

/**
    @enum Перечисление вкладок
 */
const TABS = {
  comments: "comments",
};

/**
 @enum Названия вкладок
 */
const TABS_NAME = {
  [TABS.comments]: "Комментарии",
};

const Tabs = (props) => {
  const { taskId, operatorId, newNotifications } = props;

  const { checkPermissionToRenderUI } = useRBAC();

  const getComments = useCallback(
    () => TASKS_API.getTaskComments(taskId),
    [taskId]
  );
  const postComment = useCallback(
    (comment) => TASKS_API.postTaskComment(taskId, comment),
    [taskId]
  );
  const editComment = useCallback(
    (commentId, comment) => TASKS_API.editTaskComment(commentId, comment),
    []
  );

  if (!checkPermissionToRenderUI(UI_PERMISSIONS["ui:tasks:card:comments:list"]))
    return null;

  return (
    <TabsAntd defaultActiveKey={TABS.comments} className="page__aside-tabs">
      <TabsAntd.TabPane key={TABS.comments} tab={TABS_NAME[TABS.comments]}>
        <CommentForm
          appealID={taskId}
          currentUserID={operatorId}
          getComments={getComments}
          postComment={postComment}
          editComment={editComment}
          newNotifications={newNotifications}
          postingCommentAllowed={checkPermissionToRenderUI(
            UI_PERMISSIONS["ui:tasks:card:comments:post"]
          )}
          editingOwnCommentAllowed={checkPermissionToRenderUI(
            UI_PERMISSIONS["ui:tasks:card:comments:edit"]
          )}
        />
      </TabsAntd.TabPane>
    </TabsAntd>
  );
};

export default Tabs;
