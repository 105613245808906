import React from "react";
import { Row, Col, Typography, Button, Input, Form, Select } from "antd";
import { EditOutlined } from "@ant-design/icons";

const { Title, Text } = Typography;

const View = ({
  title,
  text,
  operatorGroups = [],
  onChangeEditing,
  permissions,
}) => {
  return (
    <Row className="component_template-card" gutter={[0, 18]}>
      <Col span={24}>
        <Title level={4} style={{ marginBottom: 0 }}>
          {title}
        </Title>
      </Col>
      <Col span={24}>
        <Text style={{ whiteSpace: "pre-wrap" }}>{text}</Text>
      </Col>
      <Col span={24}>
        <Row>
          <Col span={24}>
            <Text strong>Шаблон для групп операторов</Text>
          </Col>
          <Col span={24}>
            <Text>{operatorGroups.map((_g) => _g.title).join(", ")}</Text>
          </Col>
        </Row>
      </Col>
      <Col>
        {permissions.edit && (
          <Button
            type="primary"
            children="Редактировать"
            icon={<EditOutlined />}
            onClick={onChangeEditing}
          />
        )}
      </Col>
    </Row>
  );
};

const Editing = ({
  title,
  text,
  operatorGroups,
  operatorGroupsOptions,
  onSave,
  onChangeEditing,
}) => {
  return (
    <Row className="component_template-card">
      <Col>
        <Title level={4}>Редактирование шаблона</Title>
      </Col>
      <Col span={24}>
        <Form
          layout="vertical"
          initialValues={{
            title,
            text,
            operator_group_ids: operatorGroups.map((_g) => _g.id),
          }}
          onFinish={onSave}
        >
          <Form.Item
            name="title"
            label="Название"
            rules={[
              {
                required: true,
                message: "Обязательное поле",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="text"
            label="Текст шаблона"
            rules={[
              {
                required: true,
                message: "Обязательное поле",
              },
            ]}
          >
            <Input.TextArea autoSize={{ minRows: 4, maxRows: 20 }} />
          </Form.Item>

          <Form.Item
            label="Шаблон для групп операторов"
            name="operator_group_ids"
          >
            <Select
              allowClear
              showArrow
              mode="tags"
              options={operatorGroupsOptions}
            />
          </Form.Item>

          <Form.Item noStyle>
            <Button type="primary" children="Сохранить" htmlType="submit" />
            <Button
              type="link"
              children="Отмена"
              htmlType="reset"
              onClick={onChangeEditing}
            />
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

const TemplateCard = (props) => {
  const {
    title,
    text,
    operator_groups,
    isEditing = false,
    onChangeEditing,
    onSave,
    permissions = {},
    operatorsGroups = [],
  } = props;

  return isEditing ? (
    <Editing
      title={title}
      text={text}
      operatorGroups={operator_groups}
      operatorGroupsOptions={operatorsGroups}
      onSave={onSave}
      onChangeEditing={onChangeEditing}
    />
  ) : (
    <View
      title={title}
      text={text}
      operatorGroups={operator_groups}
      onChangeEditing={onChangeEditing}
      permissions={permissions}
    />
  );
};

export default TemplateCard;
