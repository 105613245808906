import { store } from "../../";
import actionTypes from "./actionTypes";
import { getOperatorAppeals } from "../../api/appeals";
import { getOperatorTasks } from "../../api/tasks";
import { getMassProblemsList } from "../../api/massProblem";
import { NOTIFICATIONS_TYPES } from "../../services/notifications/types";
import { NOTIFICATIONS_URL } from "../../consts";
import errorHandling from "../../services/errors/errorHandling";
import {
  CONNECTION_OPENING_ERROR,
  ISSUE_GETTING_ERROR,
  TASK_GETTING_ERROR,
  MASS_PROBLEM_GETTING_ERROR,
} from "../errors/actionTypes";
import { getNewUserMessage } from "../messages/messages";
import createUUID from "../../utils/createUUID";
import { notification } from "antd";

export function openNotificationsWS(accessToken) {
  const socket = new WebSocket(`${NOTIFICATIONS_URL}?token=${accessToken}`);

  socket.onmessage = (message) => store.dispatch(newNotification(message));
  socket.onerror = (err) =>
    store.dispatch(errorHandling(err, CONNECTION_OPENING_ERROR));

  store.dispatch({
    type: actionTypes.OPEN_NOTIFICATIONS_WS,
    payload: socket,
  });
}

export function closeNotificationsWS(socket) {
  if (socket) socket.close();

  return { type: actionTypes.CLOSE_NOTIFICATIONS_WS };
}

export function newNotification(notice) {
  return (dispatch) => {
    const message = JSON.parse(notice.data);
    const key = createUUID();

    // TODO: сделать нормальную фильтрацию оповещений
    const selfNotice =
      message.type === NOTIFICATIONS_TYPES.NEW_OPERATOR_MESSAGE &&
      checkCurrentOperator(message.data.operator_id);
    if (selfNotice) return;

    dispatch({
      type: actionTypes.NEW_NOTIFICATION,
      payload: {
        ...message?.data,
        key,
        type: message?.type,
        new: true,
      },
    });

    dispatch(newNotificationSideAction(message, key));
  };
}

export function closeNotification(noticeKey) {
  notification.close(noticeKey);
  return { type: actionTypes.CLOSE_NOTIFICATION, payload: noticeKey };
}

export function removeReadMessages(userID) {
  const { newMessages } = store.getState().notifications;
  const unreadMessages = newMessages.filter(
    (message) => message.user_id !== userID
  );

  return { type: actionTypes.READ_MESSAGE, payload: unreadMessages };
}

export function getOperatorOpenIssues(opID) {
  return async (dispatch) => {
    await getOperatorAppeals(opID)
      .then((resp) => {
        dispatch({
          type: actionTypes.SET_OPERATOR_OPEN_ISSUES,
          payload: resp.data.map((issue) => issue.id),
        });
      })
      .catch((err) => dispatch(errorHandling(err, ISSUE_GETTING_ERROR)));
  };
}

export function getOperatorOpenTasks(opID) {
  return async (dispatch) => {
    await getOperatorTasks(opID)
      .then((resp) => {
        dispatch({
          type: actionTypes.SET_OPERATOR_OPEN_TASKS,
          payload: resp.data.map((task) => task.id),
        });
      })
      .catch((err) => dispatch(errorHandling(err, TASK_GETTING_ERROR)));
  };
}

export function getOpenMassProblems() {
  return async (dispatch) => {
    await getMassProblemsList()
      .then((resp) => {
        dispatch({
          type: actionTypes.SET_OPEN_MASS_PROBLEMS,
          payload: resp.data.map((mp) => mp.id),
        });
      })
      .catch((err) => dispatch(errorHandling(err, MASS_PROBLEM_GETTING_ERROR)));
  };
}

function checkCurrentOperator(operatorID) {
  const currentOperatorID = store.getState().user.id;

  return currentOperatorID === operatorID;
}

function newNotificationSideAction(message, key) {
  return (dispatch) => {
    switch (message.type) {
      case NOTIFICATIONS_TYPES.NEW_OPERATOR_MESSAGE:
      case NOTIFICATIONS_TYPES.NEW_USER_MESSAGE: {
        dispatch(getNewUserMessage(message.data.message_id));
        return dispatch({
          type: actionTypes.NEW_MESSAGE_NOTIFICATION,
          payload: { ...message.data, key, unread: true },
        });
      }
      case NOTIFICATIONS_TYPES.TASK_CLOSED:
        return dispatch({
          type: actionTypes.TASK_CLOSED,
          payload: message.data,
        });
      case NOTIFICATIONS_TYPES.TASK_ASSIGNED_TO_OPERATOR: {
        if (checkCurrentOperator(message.data.operator_id)) {
          return dispatch({
            type: actionTypes.SET_OPERATOR_OPEN_TASKS,
            payload: [
              ...store.getState().notifications.openTasks,
              message.data.task_id,
            ],
          });
        } else {
          return dispatch({
            type: actionTypes.SET_OPERATOR_OPEN_TASKS,
            payload: [
              ...store
                .getState()
                .notifications.openTasks.filter(
                  (taskId) => taskId !== message.data.task_id
                ),
            ],
          });
        }
      }
      case NOTIFICATIONS_TYPES.TASK_ASSIGNED_TO_OPERATORS_GROUP: {
        return dispatch({
          type: actionTypes.SET_OPERATOR_OPEN_TASKS,
          payload: store
            .getState()
            .notifications.openTasks.filter(
              (taskId) => taskId !== message.data.task_id
            ),
        });
      }
      case NOTIFICATIONS_TYPES.OPERATOR_TASK_CREATED: {
        if (checkCurrentOperator(message.data.operator_id)) {
          return dispatch({
            type: actionTypes.OPERATOR_TASK_CREATED,
            payload: message.data.task_id,
          });
        }
        break;
      }
      case NOTIFICATIONS_TYPES.ISSUE_CREATED: {
        if (checkCurrentOperator(message.data.operator_id)) {
          return dispatch({
            type: actionTypes.ISSUE_CREATED,
            payload: message.data.issue_id,
          });
        }
        break;
      }
      case NOTIFICATIONS_TYPES.ISSUE_TRANSFERRED: {
        if (checkCurrentOperator(message.data.operator_id)) {
          return dispatch({
            type: actionTypes.ISSUE_TRANSFERRED,
            payload: [
              ...store.getState().notifications.openIssues,
              message.data.issue_id,
            ],
          });
        } else {
          return dispatch({
            type: actionTypes.ISSUE_TRANSFERRED,
            payload: [
              ...store
                .getState()
                .notifications.openIssues.filter(
                  (issueId) => issueId !== message.data.issue_id
                ),
            ],
          });
        }
      }
      case NOTIFICATIONS_TYPES.ISSUE_CLOSED:
        return dispatch({
          type: actionTypes.ISSUE_CLOSED,
          payload: message.data,
        });
      case NOTIFICATIONS_TYPES.MASS_PROBLEM_CREATED:
        return dispatch({
          type: actionTypes.SET_OPEN_MASS_PROBLEMS,
          payload: [
            ...store.getState().notifications.openMassProblems,
            message.data.mass_problem_id,
          ],
        });
      case NOTIFICATIONS_TYPES.MASS_PROBLEM_CLOSED:
        return dispatch({
          type: actionTypes.SET_OPEN_MASS_PROBLEMS,
          payload: store
            .getState()
            .notifications.openMassProblems.filter(
              (mpId) => mpId !== message.data.mass_problem_id
            ),
        });

      default:
        return;
    }
  };
}
