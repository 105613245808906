import api from "./api";
import { REPORT_API_URLS } from "../consts";

const getReport = (query, preview = false) => {
  const url = REPORT_API_URLS.getReport(query, preview);
  return api.protectedGet(url, { responseType: preview ? "json" : "blob" });
};

const REPORT_API = {
  getReport,
};

export default REPORT_API;
