import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { message } from "antd";

import HARDWARE_API from "../api/hardware";
import ERROR_ACTIONS from "../actions/errors/errors";

const useHardware = () => {
  const dispatch = useDispatch();

  const [hardwareLoading, setHardwareLoading] = useState(false);
  const [searching, setSearching] = useState(false);
  const [hardwares, setHardwares] = useState([]);
  const [selectedHardware, setSelectedHardware] = useState(undefined);
  const [creationFormVisible, setCreationFormVisible] = useState(false);
  const [hardwareChangesVisible, setHardwareChangesVisible] = useState(false);
  const [hardwareChangeText, setHardwareChangeText] = useState("");
  const [editingHardwareChange, setEditingHardwareChange] = useState(null);
  const [identifierEditing, setIdentifierEditing] = useState(false);

  const searchHardware = useCallback(
    async (query, userId) => {
      await setSearching(true);

      try {
        const response = await HARDWARE_API.searchHardware(query, userId);

        setHardwares(response.data);
      } catch (err) {
        setHardwares([]);

        console.error(err);
        dispatch(
          ERROR_ACTIONS.addError({
            title: "Не удалось получить список оборудования",
            message: err.response.data?.message,
            status: err.response.status,
          })
        );
      }

      await setSearching(false);
    },
    [dispatch]
  );

  const createHardware = useCallback(
    async (identifier) => {
      try {
        await HARDWARE_API.postHardware(identifier);
        message.success("Оборудование добавлено");
        setCreationFormVisible(false);
      } catch (err) {
        console.error(err);

        dispatch(
          ERROR_ACTIONS.addError({
            title: "Не удалось создать оборудование",
            message: err.response.data?.message,
            status: err.response.status,
          })
        );
      }
    },
    [dispatch]
  );

  const getSelectedHardware = useCallback(
    (hardwares, id) => hardwares.find((_hardware) => _hardware.id === id),
    []
  );

  const onPostChange = useCallback(
    async (hardwareId, text) => {
      await setHardwareLoading(true);

      try {
        const response = await HARDWARE_API.postHardwareChange(
          hardwareId,
          text
        );

        setHardwareChangeText("");
        setSelectedHardware((_selectedHardware) => ({
          ..._selectedHardware,
          hardwareChanges: [
            ..._selectedHardware.hardwareChanges,
            response.data,
          ],
        }));
      } catch (err) {
        console.error(err);
        dispatch(
          ERROR_ACTIONS.addError({
            title: "Не удалось добавить изменение оборудования",
            message: err.response.data?.message,
            status: err.response.status,
          })
        );
      }

      await setHardwareLoading(false);
    },
    [dispatch]
  );

  const onRemoveHardwareChange = useCallback(
    async (hardwareChangeId) => {
      await setHardwareLoading(true);

      try {
        await HARDWARE_API.deleteHardwareChange(hardwareChangeId);

        setSelectedHardware((_selectedHardware) => ({
          ..._selectedHardware,
          hardwareChanges: _selectedHardware.hardwareChanges.filter(
            (_item) => _item.id !== hardwareChangeId
          ),
        }));
      } catch (err) {
        console.error(err);
        dispatch(
          ERROR_ACTIONS.addError({
            title: "Не удалось удалить изменение",
            message: err.response.data?.message,
            status: err.response.status,
          })
        );
      }

      await setHardwareLoading(false);
    },
    [dispatch]
  );

  const toggleEditingHardwareChange = useCallback(
    (hardwareChangeId, text = "") => {
      if (hardwareChangeId) {
        setHardwareChangeText(text);
        setEditingHardwareChange(hardwareChangeId);
      } else {
        setHardwareChangeText("");
        setEditingHardwareChange(null);
      }
    },
    []
  );

  const onEditChange = useCallback(
    async (hardwareChangeId, text) => {
      await setHardwareLoading(true);

      try {
        const response = await HARDWARE_API.putHardwareChange(
          hardwareChangeId,
          text
        );

        toggleEditingHardwareChange();
        setSelectedHardware((_selectedHardware) => ({
          ..._selectedHardware,
          hardwareChanges: _selectedHardware.hardwareChanges.map((_item) =>
            _item.id === hardwareChangeId ? { ...response.data } : _item
          ),
        }));
      } catch (err) {
        console.error(err);
        dispatch(
          ERROR_ACTIONS.addError({
            title: "Не удалось отредактировать изменение оборудования",
            message: err.response.data?.message,
            status: err.response.status,
          })
        );
      }

      await setHardwareLoading(false);
    },
    [dispatch, toggleEditingHardwareChange]
  );

  const getHardwareChanges = useCallback(
    async (hardwareId) => {
      try {
        const response = await HARDWARE_API.getHardwareChanges(hardwareId);
        return response.data;
      } catch (err) {
        console.error(err);
        dispatch(
          ERROR_ACTIONS.addError({
            title: "Не удалось получить изменения оборудования",
            message: err.response.data?.message,
            status: err.response.status,
          })
        );

        return [];
      }
    },
    [dispatch]
  );

  const onIdentifierEdit = async (hardwareId, identifier) => {
    try {
      await HARDWARE_API.putHardwareEdintifier(hardwareId, identifier);

      setIdentifierEditing(false);
      setHardwares((hardwares) =>
        hardwares.map((_h) =>
          _h.id === hardwareId ? { ..._h, identifier } : _h
        )
      );
      setSelectedHardware((hardware) => ({ ...hardware, identifier }));
      message.success("Идентификатор изменен");
    } catch (err) {
      console.error(err);
      dispatch(
        ERROR_ACTIONS.addError({
          title: "Не удалось изменить идентификатор оборудования",
          message: err.response.data?.message,
          status: err.response.status,
        })
      );
    }
  };

  return {
    hardwareLoading,
    searching,
    hardwares,
    selectedHardware,
    creationFormVisible,
    hardwareChangesVisible,
    hardwareChangeText,
    editingHardwareChange,
    identifierEditing,
    setHardwares,
    searchHardware,
    createHardware,
    getSelectedHardware,
    onPostChange,
    onEditChange,
    onRemoveHardwareChange,
    toggleEditingHardwareChange,
    setHardwareChangesVisible,
    getHardwareChanges,
    setHardwareLoading,
    setSelectedHardware,
    setCreationFormVisible,
    setHardwareChangeText,
    setIdentifierEditing,
    onIdentifierEdit,
  };
};

export default useHardware;
