import React, { useState, useEffect, useCallback } from "react";
import { Select } from "antd";
import moment from "moment";

import MainContainter from "../../components/MainContainer";
import SearchResults from "../../components/SearchResults";
import SearchComponent, { SORT_DIRECTION } from "../../components/Search";
import SearchFilter, {
  EXISTENCE,
  COMPARISONS,
  FILTER_COMPONENTS,
  isEqualMoments,
} from "../../components/SearchPreciseParams";
import Pagination from "../../components/Pagination";

import SEARCH_API from "../../api/search";
import OPERATORS_API from "../../api/operators";
import LOCATIONS_API from "../../api/locations";
import { getTasks } from "../../api/tasks";
import { getIssues } from "../../api/appeals";
import { getMassProblems } from "../../api/massProblem";
import useRBAC, { UI_PERMISSIONS } from "../../services/rbac";
import useTemplate from "../../hooks/useTemplate.hook";
import usePagination from "../../hooks/pagination.hook";
import useServiceAccount from "../../hooks/useServiceAccount";
import { FILTERS as FILTERS_TYPES } from "../../hooks/search.hook";

import "./style.scss";

export const SEARCH_RESULT_TYPES = {
  task: "task",
  issue: "issue",
  task_comment: "task_comment",
  mass_problem: "mass_problem",
  issue_comment: "issue_comment",
  mass_problem_comment: "mass_problem_comment",
};

const DATE_FILTERS = [
  FILTERS_TYPES.done_at,
  FILTERS_TYPES.created_at,
  FILTERS_TYPES.updated_at,
];

const PAGE_SIZES = [
  { label: 10, value: 10 },
  { label: 30, value: 30 },
  { label: 50, value: 50 },
];

const sort = {
  type: [
    { value: "", label: "По релевантности" },
    { value: "created_at", label: "Создано" },
    { value: "updated_at", label: "Обновлено" },
    { value: "done_at", label: "Выполнено" },
  ],
  direction: Object.values(SORT_DIRECTION),
};

const TYPE_FILTER_OPTIONS = {
  issue: "issue",
  issue_comment: "issue_comment",
  task: "task",
  task_comment: "task_comment",
  mass_problem: "mass_problem",
  mass_problem_comment: "mass_problem_comment",
};

const FILTERS = {
  [FILTERS_TYPES.type]: {
    label: "Тип",
    type: FILTER_COMPONENTS.checkbox,
    selected: [],
    options: [
      { value: TYPE_FILTER_OPTIONS.issue, label: "Обращение" },
      {
        value: TYPE_FILTER_OPTIONS.issue_comment,
        label: "Комментарий к обращению",
      },
      { value: TYPE_FILTER_OPTIONS.task, label: "Задача" },
      {
        value: TYPE_FILTER_OPTIONS.task_comment,
        label: "Комментарий к задаче",
      },
      { value: TYPE_FILTER_OPTIONS.mass_problem, label: "Массовая проблема" },
      {
        value: TYPE_FILTER_OPTIONS.mass_problem_comment,
        label: "Комментарий к массовой проблеме",
      },
    ],
    comparisons: [COMPARISONS.eq, COMPARISONS.neq],
    multiple: true,
  },
  [FILTERS_TYPES.operator_id]: {
    label: "Исполнитель",
    type: FILTER_COMPONENTS.search,
    selected: [],
    options: [],
    comparisons: [COMPARISONS.eq, COMPARISONS.neq],
    existence: {
      selected: undefined,
      options: [EXISTENCE.ex, EXISTENCE.nex],
    },
    multiple: true,
    params: {
      filterOption: (inputValue, option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase()),
    },
  },
  [FILTERS_TYPES.from_operator_id]: {
    label: "От",
    type: FILTER_COMPONENTS.search,
    selected: [],
    options: [],
    comparisons: [COMPARISONS.eq, COMPARISONS.neq],
    multiple: true,
    params: {
      filterOption: (inputValue, option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase()),
    },
  },
  [FILTERS_TYPES.user_id]: {
    label: "Пользователь",
    type: FILTER_COMPONENTS.userSearch,
    selected: [],
    options: [],
    comparisons: [COMPARISONS.eq, COMPARISONS.neq],
    existence: {
      selected: undefined,
      options: [EXISTENCE.ex, EXISTENCE.nex],
    },
    multiple: true,
  },
  [FILTERS_TYPES.operators_group_id]: {
    label: "Группа операторов",
    type: FILTER_COMPONENTS.search,
    selected: [],
    options: [],
    comparisons: [COMPARISONS.eq, COMPARISONS.neq],
    multiple: true,
    params: {
      filterOption: (inputValue, option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase()),
    },
  },
  [FILTERS_TYPES.created_at]: {
    label: "Создано",
    type: FILTER_COMPONENTS.datepicker,
    selected: [
      {
        comparison: COMPARISONS.gte.value,
        value: null,
        label: "",
      },
      {
        comparison: COMPARISONS.lte.value,
        value: null,
        label: "",
      },
    ],
    comparisons: [
      COMPARISONS.eq,
      COMPARISONS.neq,
      COMPARISONS.lt,
      COMPARISONS.lte,
      COMPARISONS.gt,
      COMPARISONS.gte,
    ],
    multiple: false,
  },
  [FILTERS_TYPES.updated_at]: {
    label: "Обновлено",
    type: FILTER_COMPONENTS.datepicker,
    selected: [
      {
        comparison: COMPARISONS.gte.value,
        value: null,
        label: "",
      },
      {
        comparison: COMPARISONS.lte.value,
        value: null,
        label: "",
      },
    ],
    comparisons: [
      COMPARISONS.eq,
      COMPARISONS.neq,
      COMPARISONS.lt,
      COMPARISONS.lte,
      COMPARISONS.gt,
      COMPARISONS.gte,
    ],
    multiple: false,
  },
  [FILTERS_TYPES.done_at]: {
    label: "Выполнено",
    type: FILTER_COMPONENTS.datepicker,
    selected: [
      {
        comparison: COMPARISONS.gte.value,
        value: null,
        label: "",
      },
      {
        comparison: COMPARISONS.lte.value,
        value: null,
        label: "",
      },
    ],
    comparisons: [
      COMPARISONS.eq,
      COMPARISONS.neq,
      COMPARISONS.lt,
      COMPARISONS.lte,
      COMPARISONS.gt,
      COMPARISONS.gte,
    ],
    existence: {
      selected: undefined,
      options: [EXISTENCE.ex, EXISTENCE.nex],
    },
    multiple: false,
  },
  [FILTERS_TYPES.human_readable_id]: {
    label: "Номер",
    type: FILTER_COMPONENTS.number,
    selected: [],
    comparisons: [COMPARISONS.eq, COMPARISONS.neq],
    multiple: true,
  },
  [FILTERS_TYPES.template_id]: {
    label: "Шаблон",
    type: FILTER_COMPONENTS.search,
    selected: [],
    options: [],
    comparisons: [COMPARISONS.eq, COMPARISONS.neq],
    existence: {
      selected: undefined,
      options: [EXISTENCE.ex, EXISTENCE.nex],
    },
    multiple: true,
    params: {
      filterOption: (inputValue, option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase()),
    },
  },
  [FILTERS_TYPES.service_account_id]: {
    label: "Сервисные аккаунты",
    type: FILTER_COMPONENTS.search,
    selected: [],
    options: [],
    comparisons: [COMPARISONS.eq, COMPARISONS.neq],
    existence: {
      selected: undefined,
      options: [EXISTENCE.ex, EXISTENCE.nex],
    },
    multiple: true,
    params: {
      filterOption: (inputValue, option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase()),
    },
  },
  [FILTERS_TYPES.location_id]: {
    label: "Локации",
    type: FILTER_COMPONENTS.search,
    selected: [],
    options: [],
    comparisons: [COMPARISONS.eq, COMPARISONS.neq],
    existence: {
      selected: undefined,
      options: [EXISTENCE.ex, EXISTENCE.nex],
    },
    multiple: true,
    params: {
      filterOption: (inputValue, option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase()),
    },
  },
};

function getCommentFullData(comments = []) {
  const promises = comments.map(async (comment) => {
    const commentData = { ...comment };

    await OPERATORS_API.getOperatorData(commentData.operator_id).then((resp) => {
      commentData.operator = resp.data;
    });

    return commentData;
  });

  return Promise.all(promises)
    .then((resp) => resp)
    .catch(() => []);
}

const Search = (props) => {
  const { componentProps, setComponentProps } = props;

  // Hooks
  const { checkPermissionToRenderUI } = useRBAC();
  const { getTemplates } = useTemplate();
  const { getServiceAccounts } = useServiceAccount();

  // State
  const [searching, setSearching] = useState(false);
  const [page, setPage] = useState(
    componentProps && componentProps.page ? componentProps.page : 1
  );
  const [query, setQuery] = useState(
    componentProps && componentProps.query ? componentProps.query : ""
  );
  const [totalResults, setTotalResults] = useState(
    componentProps && componentProps.totalResults ? componentProps.totalResults : 0
  );
  const [searchResults, setSearchResults] = useState(
    componentProps && componentProps.searchResults
      ? componentProps.searchResults
      : undefined
  );
  const [filters, setFilters] = useState(
    componentProps && componentProps.filters
      ? [...componentProps.filters]
      : initFilters(FILTERS)
  );
  const [selectedSort, setSelectedSort] = useState(
    componentProps && componentProps.selectedSort
      ? componentProps.selectedSort
      : sort.type[0].value
  );
  const [sortDirection, setSortDirection] = useState(
    componentProps && componentProps.sortDirection
      ? componentProps.sortDirection
      : SORT_DIRECTION.asc
  );
  const [pageSize, setPageSize] = useState(
    componentProps && componentProps.pageSize
      ? componentProps.pageSize
      : PAGE_SIZES[0].value
  );

  // Hooks
  const { getPaginationRange, totalPageCount } = usePagination({
    currentPage: page,
    pageSize,
    totalCount: totalResults,
  });

  function initFilters(filters) {
    function getInitTypeFilter(filter) {
      const _filter = { ...filter };

      const FILTER_OPTION_PERMISSION = {
        [TYPE_FILTER_OPTIONS.issue]: UI_PERMISSIONS["ui:search:filter:type:issue"],
        [TYPE_FILTER_OPTIONS.issue_comment]:
          UI_PERMISSIONS["ui:search:filter:type:issue-comment"],
        [TYPE_FILTER_OPTIONS.task]: UI_PERMISSIONS["ui:search:filter:type:task"],
        [TYPE_FILTER_OPTIONS.task_comment]:
          UI_PERMISSIONS["ui:search:filter:type:task-comment"],
        [TYPE_FILTER_OPTIONS.mass_problem]:
          UI_PERMISSIONS["ui:search:filter:type:mass-problem"],
        [TYPE_FILTER_OPTIONS.mass_problem_comment]:
          UI_PERMISSIONS["ui:search:filter:type:mass-problem-comment"],
      };

      _filter.options.forEach((option) => {
        if (option.value in FILTER_OPTION_PERMISSION) {
          const optionPermission = FILTER_OPTION_PERMISSION[option.value];
          const allowed = checkPermissionToRenderUI(optionPermission);

          if (!allowed) {
            _filter.selected = [
              ..._filter.selected,
              { ...option, comparison: COMPARISONS.neq.value },
            ];
            _filter.options = _filter.options.filter(
              (_option) => _option.value !== option.value
            );
          }
        }
      });

      return _filter;
    }

    return Object.keys(filters).reduce((filtersArr, currentFilter) => {
      if (currentFilter === FILTERS_TYPES.type) {
        return [
          ...filtersArr,
          {
            value: currentFilter,
            ...getInitTypeFilter(filters[currentFilter]),
          },
        ];
      }

      return [...filtersArr, { value: currentFilter, ...filters[currentFilter] }];
    }, []);
  }

  function onChangeSort(selected) {
    const searchParams = {
      ...getSearchParams(),
      from: 0,
      sort: {
        value: selected,
        direction: sortDirection,
      },
    };

    setPage(1);
    setSelectedSort(selected);
    onSearch(searchParams);
  }

  function onChangeSortDirection() {
    const direction = (() => {
      if (sortDirection === SORT_DIRECTION.asc) return SORT_DIRECTION.desc;
      if (sortDirection === SORT_DIRECTION.desc) return SORT_DIRECTION.asc;
      return SORT_DIRECTION.asc;
    })();

    const searchParams = {
      ...getSearchParams(),
      from: 0,
      sort: {
        value: selectedSort,
        direction: direction,
      },
    };

    setPage(1);
    setSortDirection(direction);
    onSearch(searchParams);
  }

  function onChangeQuery(event) {
    setQuery(event.target.value);
  }

  function parseSearchParamsToString(
    query = "",
    filters = [],
    sort = {},
    from = 0,
    size = 0
  ) {
    let queryParams = "";

    if (query.length > 0) {
      queryParams = queryParams + `query=${query}&`;
    }

    // парсим фильтры
    queryParams = filters.reduce((params, filter) => {
      if (filter.existence && filter.existence.selected) {
        return params + `${filter.value},${filter.existence.selected}&`;
      }
      if (DATE_FILTERS.includes(filter.value)) {
        return params + parseDateFilter(filter);
      }

      return (
        params +
        filter.selected.reduce(
          (current, selected) =>
            current + `${filter.value},${selected.comparison}=${selected.value}&`,
          ""
        )
      );
    }, queryParams);

    // добавляем сортировку
    if (sort.value) {
      queryParams = queryParams + `sort=${sort.value},${sort.direction}&`;
    }
    // добавляем смещение и количество
    queryParams = queryParams + `from=${from}&size=${size}&`;

    // убираем оканчивающее "&"" если есть
    return queryParams.endsWith("&")
      ? queryParams.substring(0, queryParams.length - 1)
      : queryParams;
  }

  function getDateString(momentDate) {
    return momentDate.format("YYYY-MM-DD");
  }

  function parseDateFilter(dateFilter) {
    const { selected, value } = dateFilter;
    const isMoment = selected.every((selected) => moment.isMoment(selected.value));

    if (isMoment) {
      const isEqual = isEqualMoments(selected.map((date) => date.value));

      return isEqual
        ? `${value},${selected[0].comparison}=${getDateString(selected[0].value)}&`
        : selected.reduce(
            (string, current) =>
              string + `${value},${current.comparison}=${getDateString(current.value)}&`,
            ""
          );
    } else {
      return "";
    }
  }

  async function onSearch(searchParams = {}) {
    const { query, filters, sort, from, size } = searchParams;

    const queryParams = parseSearchParamsToString(query, filters, sort, from, size);

    await setSearching(true);

    try {
      const response = await SEARCH_API.searchRequest(queryParams);
      const { hits, total_hits } = response.data;

      const sortedResults = sortResults(hits);
      const fullDataResults = await (await getFullData(sortedResults)).flat();
      const combined = combineResult(hits, fullDataResults);

      setTotalResults(total_hits);
      setSearchResults(combined);
    } catch (err) {
      console.error(err);
    }

    await setSearching(false);
  }

  function checkResults(results) {
    return Array.isArray(results) && results.length > 0;
  }

  function onChangePageSize(size) {
    const searchParams = {
      ...getSearchParams(),
      size,
      from: 0,
    };

    setPage(1);
    setPageSize(size);
    onSearch(searchParams);
  }

  function getSearchParams() {
    return {
      query,
      filters,
      from: (page - 1) * pageSize,
      size: pageSize,
      sort: {
        value: selectedSort,
        direction: sortDirection,
      },
    };
  }

  function onChangePage(pageNumber) {
    const searchParams = {
      ...getSearchParams(),
      from: (pageNumber - 1) * pageSize,
    };

    setPage(pageNumber);
    onSearch(searchParams);
  }

  function sortResults(results = []) {
    return results.reduce((sorted, searchResult) => {
      const { type } = searchResult;

      if (type in sorted) {
        sorted[type].push(searchResult);
      } else {
        sorted[type] = [searchResult];
      }

      return sorted;
    }, {});
  }

  function getFullData(sortedResults = {}) {
    const promises = Object.entries(sortedResults).reduce((acc, current) => {
      const [type, values] = current;
      const func = getRequest(type);

      acc.push(func(values));

      return acc;
    }, []);

    return Promise.all(promises)
      .then((result) => result)
      .catch(() => []);
  }

  function combineResult(searchResults = [], fullData = []) {
    return searchResults.map((searchResult) => {
      const data = fullData.find((item) => item.id === searchResult.id);

      return data !== undefined ? { ...searchResult, ...data } : searchResult;
    });
  }

  function getRequest(searchResultType) {
    switch (searchResultType) {
      case SEARCH_RESULT_TYPES.task:
        return (tasks) =>
          getTasks(tasks.map((task) => task.id))
            .then((resp) => resp.data)
            .catch((err) => {
              console.error(err);
              return [];
            });
      case SEARCH_RESULT_TYPES.issue:
        return (issues) =>
          getIssues(issues.map((issue) => issue.id))
            .then((resp) => resp.data)
            .catch((err) => {
              console.error(err);
              return [];
            });
      case SEARCH_RESULT_TYPES.mass_problem:
        return (massProblems) =>
          getMassProblems(massProblems.map((mp) => mp.id))
            .then((resp) => resp.data)
            .catch((err) => {
              console.error(err);
              return [];
            });
      case SEARCH_RESULT_TYPES.task_comment:
      case SEARCH_RESULT_TYPES.issue_comment:
      case SEARCH_RESULT_TYPES.mass_problem_comment:
        return (comment) => getCommentFullData(comment);
      default:
        return (f) => f;
    }
  }

  function getOperators() {
    OPERATORS_API.getOperatorsList()
      .then((resp) => {
        setFilters((filters) => {
          const indexOperator = filters.findIndex(
            (filter) => filter.value === FILTERS_TYPES.operator_id
          );
          const indexFromOperator = filters.findIndex(
            (filter) => filter.value === FILTERS_TYPES.from_operator_id
          );
          const newFilters = [...filters];

          if (indexOperator !== -1) {
            newFilters[indexOperator] = {
              ...newFilters[indexOperator],
              options: resp.data.map((operator) => ({
                label: `${operator.last_name} ${operator.first_name}`,
                value: operator.id,
              })),
            };
          }
          if (indexFromOperator !== -1) {
            newFilters[indexFromOperator] = {
              ...newFilters[indexFromOperator],
              options: resp.data.map((operator) => ({
                label: `${operator.last_name} ${operator.first_name}`,
                value: operator.id,
              })),
            };
          }
          return newFilters;
        });
      })
      .catch((err) => console.error(err));
  }

  function getOperatorsGroups() {
    OPERATORS_API.getOperatorsGroupsList()
      .then((resp) => {
        setFilters((filters) => {
          const index = filters.findIndex(
            (filter) => filter.value === FILTERS_TYPES.operators_group_id
          );

          if (index !== -1) {
            const newFilters = [...filters];
            newFilters[index] = {
              ...newFilters[index],
              options: resp.data.map((group) => ({
                label: group.title,
                value: group.id,
              })),
            };

            return newFilters;
          } else {
            return filters;
          }
        });
      })
      .catch((err) => console.error(err));
  }

  async function getTemplatesOptions() {
    const templates = await getTemplates();
    setFilters((filters) => {
      const index = filters.findIndex((_f) => _f.value === FILTERS_TYPES.template_id);

      if (index !== -1) {
        const newFilters = [...filters];
        newFilters[index] = {
          ...newFilters[index],
          options: templates.map((_t) => ({
            label: _t.title,
            value: _t.id,
          })),
        };

        return newFilters;
      }
    });
  }

  async function getServiceAccountOptions() {
    const accounts = await getServiceAccounts();

    setFilters((filters) => {
      const index = filters.findIndex(
        (_f) => _f.value === FILTERS_TYPES.service_account_id
      );

      if (index !== -1) {
        const newFilters = [...filters];
        newFilters[index] = {
          ...newFilters[index],
          options: accounts.map((_a) => ({
            label: _a.name,
            value: _a.id,
          })),
        };

        return newFilters;
      }
    });
  }

  async function getLocations() {
    await LOCATIONS_API.getLocations()
      .then((resp) => {
        const { data } = resp;

        setFilters((filters) => {
          const index = filters.findIndex((_f) => _f.value === FILTERS_TYPES.location_id);

          if (index !== -1) {
            const newFilters = [...filters];
            newFilters[index] = {
              ...newFilters[index],
              options: data.map((_l) => ({
                label: _l.title,
                value: _l.id,
              })),
            };

            return newFilters;
          }
        });
      })
      .catch((err) => {
        console.error(err);
      });
  }

  function onResetFilter(defaultFilters) {
    setFilters(defaultFilters);
    getOperators();
    getLocations();
    getOperatorsGroups();
    getTemplatesOptions();
    getServiceAccountOptions();
  }

  const onPressEnter = useCallback(
    (event) => {
      if (event.repeat) return;
      if (event.target.nodeName !== "BODY") return; // чтобы функция не вызывалась, когда Enter нажат на другом элементе

      onChangePage(1);
    },
    [filters, query]
  );

  function keyDownHandler(event) {
    switch (event.code) {
      case "Enter":
        return onPressEnter(event);
      default:
        return;
    }
  }

  useEffect(() => {
    document.addEventListener("keydown", keyDownHandler);
    return () => document.removeEventListener("keydown", keyDownHandler);
  }, [filters, query]);

  // componentDidMount
  useEffect(() => {
    getOperators();
    getLocations();
    getOperatorsGroups();
    getTemplatesOptions();
    getServiceAccountOptions();
  }, []);

  // componentWillUnmount
  // Сохранение состояния поиска
  useEffect(
    () => () => {
      setComponentProps({
        page,
        query,
        filters,
        pageSize,
        totalResults,
        selectedSort,
        sortDirection,
        searchResults,
      });
    },
    [query, filters, sortDirection, pageSize, page, totalResults, searchResults]
  );

  return (
    <MainContainter className="search_page">
      <SearchFilter
        filters={filters}
        onChange={setFilters}
        onReset={() => onResetFilter(initFilters(FILTERS))}
      />
      <div className="search__wrapper">
        <SearchComponent
          query={{
            value: query,
            onChange: onChangeQuery,
          }}
          sort={{
            options: sort.type,
            selected: selectedSort,
            direction: sortDirection,
            directionDisabled: selectedSort === sort.type[0].value,
            onChangeSort,
            onChangeSortDirection,
          }}
          onSearch={() => onChangePage(1)}
        />
        <div className="search__search-results-wrapper">
          <div className="search-results-wrapper__info">
            {checkResults(searchResults) && (
              <p className="info__results">
                Показано {(page - 1) * pageSize + 1} -{" "}
                {(page - 1) * pageSize + searchResults.length} из {totalResults}
              </p>
            )}
            <div className="info__results-quantity-select">
              <label className="results-quantity-select__label">Показывать по:</label>
              <Select
                size="small"
                options={PAGE_SIZES}
                value={pageSize}
                onSelect={onChangePageSize}
              />
            </div>
          </div>
          <SearchResults results={searchResults} loading={searching} />
          {checkResults(searchResults) && (
            <Pagination
              currentPage={page}
              pageSize={pageSize}
              totalPageCount={totalPageCount}
              range={getPaginationRange()}
              onPageChange={(page) => onChangePage(page)}
              pageSizeChangerVisible={false}
            />
          )}
        </div>
      </div>
    </MainContainter>
  );
};

export default Search;
