import actionTypes from "../actions/notification/actionTypes";

const initialState = {
  notificationsWS: null,
  allNotifications: JSON.parse(localStorage.getItem("notifications")) || [],
  newNotifications: [],
  newMessages: [],
  openIssues: [], // Array of ids
  openTasks: [], // Array of ids
  openMassProblems: [], // Array of ids
  notificationList: [], //
  notificationListOffset: 0,
  notificationListVisible: false, //
  notificationListLoadMore: true,
};

export function notificationsReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.NEW_NOTIFICATION: {
      return {
        ...state,
        newNotifications: [...state.newNotifications, action.payload],
        allNotifications: [...state.allNotifications, action.payload],
      };
    }
    case actionTypes.CLOSE_NOTIFICATION: {
      const newNotifications = state.newNotifications.filter(
        (notice) => notice.key !== action.payload
      );
      const index = state.allNotifications.findIndex(
        (notice) => notice.key === action.payload
      );
      const allNotifications = state.allNotifications;
      allNotifications[index].new = false;

      return { ...state, newNotifications, allNotifications };
    }
    case actionTypes.OPEN_NOTIFICATIONS_WS:
      return { ...state, notificationsWS: action.payload };
    case actionTypes.CLOSE_NOTIFICATIONS_WS:
      return { ...state, notificationsWS: null };
    case actionTypes.NEW_MESSAGE_NOTIFICATION:
      return { ...state, newMessages: [...state.newMessages, action.payload] };
    case actionTypes.READ_MESSAGE:
      return { ...state, newMessages: action.payload };
    case actionTypes.ISSUE_CREATED:
      return { ...state, openIssues: [...state.openIssues, action.payload] };
    case actionTypes.ISSUE_CLOSED:
      return {
        ...state,
        openIssues: state.openIssues.filter(
          (issueId) => issueId !== action.payload.issue_id
        ),
      };
    case actionTypes.ISSUE_TRANSFERRED:
      return { ...state, openIssues: action.payload };
    case actionTypes.OPERATOR_TASK_CREATED:
      return { ...state, openTasks: [...state.openTasks, action.payload] };
    case actionTypes.TASK_CLOSED:
      return {
        ...state,
        openTasks: state.openTasks.filter(
          (taskId) => taskId !== action.payload.task_id
        ),
      };
    case actionTypes.SET_OPERATOR_OPEN_ISSUES:
      return { ...state, openIssues: action.payload };
    case actionTypes.SET_OPERATOR_OPEN_TASKS:
      return { ...state, openTasks: action.payload };
    case actionTypes.SET_OPEN_MASS_PROBLEMS:
      return { ...state, openMassProblems: action.payload };
    case actionTypes.SET_NOTIFICATION_LIST:
      return {
        ...state,
        notificationList: action.payload,
      };
    case actionTypes.SET_NOTIFICATION_LIST_VISIBLE:
      return { ...state, notificationListVisible: action.payload };
    case actionTypes.SET_NOTIFICATION_LIST_OFFSET:
      return { ...state, notificationListOffset: action.payload };
    case actionTypes.SET_NOTIFICATION_LIST_LOADMORE:
      return { ...state, notificationListLoadMore: action.payload };
    default:
      return { ...state };
  }
}
