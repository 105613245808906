import createUUID from "../../utils/createUUID";

import { ADD_ERROR, REMOVE_ERROR } from "./actionTypes";

/**
 * Метод добавляет ошибку в стор
 * @param {Object} error
 * @param {number} error.status - Статус выполнения запроса
 * @param {string} error.message - Текстовое описание ошибки
 * @param {string} error.title - Краткое описание ошибки
 */
function addError(error) {
  return (dispatch) => {
    const uuid = createUUID();

    dispatch({
      type: ADD_ERROR,
      payload: {
        uuid,
        removeError: () => dispatch(removeError(uuid)),
        ...error,
      },
    });
  };
}

/**
 * Метод удаляет ошибку из стора
 * @param {string} uuid - уникальный идентификатор ошибки
 */
function removeError(uuid) {
  return (dispatch) => dispatch({ type: REMOVE_ERROR, payload: uuid });
}

const ERROR_ACTIONS = {
  addError,
  removeError,
};

export default ERROR_ACTIONS;
