import React, { useState, useEffect } from "react";

import Search from "../Search_new";
import Spinner from "../Spinner";
import IssueListItem from "../IssueListItem";

import ISSUE_ACTIONS from "../../actions/appeal/appeal";
import ISSUES_API from "../../api/appeals";
import { TYPES } from "../Search_new/consts";
import { useTabs, COMPONENTS } from "../../services/tabs";

import "./style.scss";

const IssueSelector = (props) => {
  const {
    defaultSelectedIssues = [],
    defaultFilter = null,
    onSelect = () => {},
    excludedIssues = [],
  } = props;

  // State
  const [filter, setFilter] = useState(defaultFilter);
  const [issues, setIssues] = useState([]);
  const [selectedIssues, setSelectedIssues] = useState([]);
  const [issuesFetching, setIssuesFetching] = useState(false);

  const { openInNewTab } = useTabs();

  function setDefaultSelectedIssues(selectedIssueIds = []) {
    const promises = selectedIssueIds.map((id) => ISSUES_API.getAppealData(id));

    Promise.all(promises)
      .then((issues) => setSelectedIssues(issues.map((issue) => issue.data)))
      .catch();
  }

  function getIssues(filter) {
    if (filter === null) return;

    const params = {
      id: filter.id,
      type: filter.type,
      onlyOpen: true,
    };

    setIssuesFetching(true);

    ISSUE_ACTIONS.getIssues(params)
      .then((resp) => {
        Array.isArray(resp)
          ? setIssues(resp.map((item) => item.data).flat())
          : setIssues(resp.data);
      })
      .catch((err) => {
        console.error(err);
      });

    setIssuesFetching(false);
  }

  function onFilterChange(filter) {
    if (filter === null) return;

    setFilter(filter);
    getIssues(filter);
  }

  function openIssueCard(e, issue) {
    openInNewTab({
      component: COMPONENTS.ISSUE_CARD,
      params: { issueId: issue.id },
      locationState: { issueId: issue.id },
    });
  }

  function addIssue(issueId) {
    const updatedIssues = [...selectedIssues, issueId];

    onSelect(updatedIssues.map((issue) => issue.id));
    setSelectedIssues(updatedIssues);
  }

  function removeIssue(issueId) {
    const updatedIssues = selectedIssues.filter((id) => issueId !== id);

    onSelect(updatedIssues.map((issue) => issue.id));
    setSelectedIssues(updatedIssues);
  }

  function drawAllIssues(issues, selectedIssues) {
    const selectedIssuesId = selectedIssues.map((issue) => issue.id);
    const filteredIssues = issues.filter(
      (issue) =>
        !selectedIssuesId.includes(issue.id) &&
        !excludedIssues.includes(issue.id)
    );

    if (issues.length <= 0) {
      return (
        <p className="issue-selector__no-data-text">Обращений не найдено</p>
      );
    }
    if (filteredIssues <= 0) {
      return (
        <p className="issue-selector__no-data-text">
          Нет доступных для выбора обращений
        </p>
      );
    }

    return filteredIssues.map((issue) => (
      <IssueListItem
        key={issue.id}
        issue={issue}
        checked={false}
        onClick={openIssueCard}
        onSelect={addIssue}
      />
    ));
  }

  function drawSelectedIssues(selectedIssues = []) {
    if (selectedIssues.length <= 0) {
      return <p className="issue-selector__no-data-text">Выберите обращение</p>;
    }

    return selectedIssues.map((issue) => (
      <IssueListItem
        key={issue.id}
        issue={issue}
        checked={true}
        onClick={openIssueCard}
        onSelect={removeIssue}
      />
    ));
  }

  // componentDidMount
  useEffect(() => {
    getIssues(filter);
    setDefaultSelectedIssues(defaultSelectedIssues);
  }, []);

  return (
    <div className="component_issue-selector">
      <div className="add-issues-container__col col__left">
        <p className="col__title">Обращения</p>
        <Search
          types={[TYPES.user, TYPES.operator, TYPES.group]}
          sought={filter}
          updateParent={onFilterChange}
          placeholder="Поиск..."
        />
        {issuesFetching ? <Spinner /> : drawAllIssues(issues, selectedIssues)}
      </div>
      <div className="add-issues-container__col col__right">
        <p className="col__title title_selected-issues">Выбранные обращения</p>
        {drawSelectedIssues(selectedIssues)}
      </div>
    </div>
  );
};

export default IssueSelector;
