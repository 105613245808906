import React, { Component } from "react";
import { Button, Input, message } from "antd";
import { LoadingOutlined, CloseCircleFilled } from "@ant-design/icons";
import debounce from "lodash.debounce";

import {
  TYPES,
  TYPES_ARR,
  OPT_GROUPS,
  SEARCH_REQUESTS,
  SEARCH_BY_ID,
} from "./consts";
import { createFromEmployee, getUserData } from "../../api/users";

import "./style.scss";

const error = (text) => {
  message.error(text);
};

class Search extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: null,
      query: "",
      selected: null,
      loading: false,
      optListVisible: false,
      listFocusPosition: -1,
    };

    this.componentRef = React.createRef(null);
    this.inputRef = React.createRef(null);
    this.listRef = React.createRef(null);

    this.types = props.types || TYPES_ARR;
    this.sought = props.sought || null;

    this.onClear = this.onClear.bind(this);
    this.onSelect = this.onSelect.bind(this);
    this.searchByID = this.searchByID.bind(this);
    this.onInputClick = this.onInputClick.bind(this);
    this.onChangeInput = this.onChangeInput.bind(this);
    this.filterOptions = this.filterOptions.bind(this);
    this.searchRequest = this.searchRequest.bind(this);
    this.drawSearchList = this.drawSearchList.bind(this);
    this.debounceRequest = this.debounceRequest.bind(this);
    this.showOptionsList = this.showOptionsList.bind(this);
    this.hideOptionsList = this.hideOptionsList.bind(this);
    this.createUserFromEmployee = this.createUserFromEmployee.bind(this);
    this.keyDownHandler = this.keyDownHandler.bind(this);
    this.onEnterPress = this.onEnterPress.bind(this);
    this.onArrowUpPress = this.onArrowUpPress.bind(this);
    this.onArrowDownPress = this.onArrowDownPress.bind(this);
    this.setFocusedElement = this.setFocusedElement.bind(this);
    this.getOptionsArrayByQuerySelector =
      this.getOptionsArrayByQuerySelector.bind(this);
    this.onInputEnterPress = this.onInputEnterPress.bind(this);
    this.checkSingleOption = this.checkSingleOption.bind(this);
  }

  /**
   * Проверяем результаты поиска на единственно соответствие
   * @param {object} data - Результаты поиска
   * @returns {boolean} Возвращает true, если найдено единственное соответствие, во всех остальных случаях возвращает false
   */
  checkSingleOption(data) {
    if (data === null) return false;

    const searchResults = Object.values(data).flat();

    return searchResults.length === 1;
  }

  onInputEnterPress() {
    const { loading, data } = this.state;

    if (loading) return;

    // Выбираем опцию, если в поиск вернул единственное соответствие
    if (this.checkSingleOption(data)) {
      // Получаем кнопку
      const _optList = this.getOptionsArrayByQuerySelector();
      // Вызываем onClick метод
      _optList[0].click();
    }
  }

  getOptionsArrayByQuerySelector() {
    return this.listRef.current.querySelectorAll("button.optgroup__option");
  }

  /**
   * Задаем фокус элементу.
   * Позиция -1 соответсвует полю ввода
   * @param {number} position
   */
  setFocusedElement(position) {
    const setInputFocus = () => {
      this.inputRef.current.input.focus();
      this.setState({ listFocusPosition: -1 });
    };

    if (position === -1) {
      return setInputFocus();
    }

    const _optList = this.getOptionsArrayByQuerySelector();
    const _lastOptIndex = _optList.length - 1;

    if (_optList.length === 0) {
      return setInputFocus();
    }

    if (position < -1) {
      _optList[_lastOptIndex].focus();
      this.setState({ listFocusPosition: _lastOptIndex });
    } else if (position > _lastOptIndex) {
      setInputFocus();
    } else {
      this.setState({ listFocusPosition: position });
      _optList[position].focus();
    }
  }

  onEnterPress(event) {
    if (event.target === this.inputRef.current?.input) {
      this.setFocusedElement(-1);
    }
  }

  onArrowUpPress(event, state) {
    event.preventDefault(); // Отменяем скролл

    if (!state.loading) {
      this.setFocusedElement(state.listFocusPosition - 1);
    }
  }

  onArrowDownPress(event, state) {
    event.preventDefault(); // Отменяем скролл

    if (!state.loading) {
      this.setFocusedElement(state.listFocusPosition + 1);
    }
  }

  keyDownHandler(event) {
    switch (event.key) {
      case "Enter":
        return this.onEnterPress(event);
      case "ArrowUp":
        return this.onArrowUpPress(event, this.state);
      case "ArrowDown":
        return this.onArrowDownPress(event, this.state);
      default:
        return;
    }
  }

  componentDidMount() {
    document.addEventListener("click", this.onInputClick);
    this.componentRef.current.addEventListener("keydown", this.keyDownHandler);

    if (this.sought) {
      this.searchByID(this.sought);
    }
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.onInputClick);
    this.componentRef.current.removeEventListener(
      "keydown",
      this.keyDownHandler
    );
  }

  showOptionsList() {
    this.setState({ optListVisible: true });
  }

  hideOptionsList() {
    this.setState({ optListVisible: false });
  }

  onClear() {
    this.setState({ selected: null, query: "" });
    this.hideOptionsList();

    if (this.props.updateParent) {
      this.props.updateParent(null);
    }
  }

  onSelect(title, value) {
    const selected = JSON.parse(value);

    this.setState({ selected, query: title });
    this.hideOptionsList();

    if (this.props.updateParent) {
      this.props.updateParent(selected);
    }
  }

  onChangeInput(e, optListVisible) {
    const { value } = e.target;

    e.preventDefault();
    this.setState({ query: value });

    if (optListVisible) {
      this.debounceRequest(value);
    }
  }

  filterOptions(data, type) {
    const { query } = this.state;
    const lowerCaseQuery = query.toLowerCase().replace(/\s/g, "").trim();

    if (type === TYPES.group) {
      return data.filter((group) =>
        group.title
          .toLowerCase()
          .replace(/\s/g, "")
          .trim()
          .includes(lowerCaseQuery)
      );
    }
    if (type === TYPES.operator) {
      return data.filter((item) => {
        const fullName = item.last_name + item.first_name;
        return fullName.toLowerCase().includes(lowerCaseQuery);
      });
    }

    return data;
  }

  drawSearchList() {
    const { data, loading, query } = this.state;

    if (loading) return <div>Поиск...</div>;
    if (!data && query.length > 0) return <div>Ничего не найдено</div>;
    if (!data) return null;

    const options = this.types.map((type) => {
      const drawOptGroup = OPT_GROUPS[type];

      if (type === TYPES.employee) {
        return drawOptGroup(data[type], this.createUserFromEmployee, type);
      }
      return drawOptGroup(data[type], this.onSelect, type);
    });

    return options;
  }

  async searchRequest(query) {
    const requests = () =>
      this.types.map((type) => {
        /*
          Сие чудная конструкция нужна для того, чтобы не делать запрос к апи
          в случае когда идет поиск по пользователям или сотрудникам и нет введенного текста
        */
        if (type === TYPES.group || type === TYPES.operator) {
          return SEARCH_REQUESTS[type](query)
            .then((resp) => {
              this.setState(({ data }) => ({
                data: { ...data, [type]: this.filterOptions(resp.data, type) },
              }));
            })
            .catch((err) => console.error(err));
        } else if (
          (type === TYPES.user || type === TYPES.employee) &&
          query.length > 2
        ) {
          return SEARCH_REQUESTS[type](query)
            .then((resp) => {
              this.setState(({ data }) => ({
                data: { ...data, [type]: this.filterOptions(resp.data, type) },
              }));
            })
            .catch((err) => console.error(err));
        } else {
          return () => {};
        }
      });

    this.setState({ loading: true });
    await Promise.all(requests());
    this.setState({ loading: false });
  }

  debounceRequest = debounce((query) => this.searchRequest(query), 800);

  async createUserFromEmployee(_, value) {
    this.setState({ loading: true });

    const selected = JSON.parse(value);

    await createFromEmployee(selected.id)
      .then(async (resp) => {
        if (resp.status === 201) {
          return await getUserData(resp.data.id);
        }
        return Promise.reject("При создании пользователя произошла ошибка");
      })
      .then(({ data }) => {
        this.setState({
          selected: data,
          query: `${data.last_name} ${data.first_name}`,
        });
        return data;
      })
      .then((data) => {
        if (this.props.updateParent) {
          this.props.updateParent(data);
        }
      })
      .catch((err) => error(err));

    this.hideOptionsList();
    this.setState({ loading: false });
  }

  async searchByID(sought) {
    this.setState({ loading: true });
    const { id, type } = sought;

    await SEARCH_BY_ID[type](id)
      .then((resp) => {
        this.setState({
          selected: resp.data,
          query: `${resp.data.last_name} ${resp.data.first_name}`,
        });

        if (type === TYPES.group) {
          this.setState({ query: resp.data.title });
        }
      })
      .catch((err) => {
        this.setState({ query: "Ничего не найдено..." });
        console.error(err);
      });

    this.setState({ loading: false });
  }

  onInputClick(e) {
    const { query } = this.state;

    if (e.target === this.inputRef.current?.input) {
      this.searchRequest(query);
      this.setFocusedElement(-1);
      return this.showOptionsList();
    }

    return this.hideOptionsList();
  }

  render() {
    const { query, loading, optListVisible } = this.state;
    const { placeholder, disabled, size = "middle" } = this.props;

    return (
      <div className="search-component" ref={this.componentRef}>
        <div className="search-component__input-wrapper">
          {loading && (
            <div className="input-wrapper__loading-spinner">
              <LoadingOutlined />
            </div>
          )}
          <Input
            ref={this.inputRef}
            size={size}
            autoComplete="off"
            placeholder={placeholder}
            disabled={disabled}
            value={query}
            onChange={(e) => this.onChangeInput(e, optListVisible)}
            onPressEnter={() => this.onInputEnterPress()}
            className={loading && "input-wrapper__input_loading"}
          />
          {query.length > 0 && (
            <Button
              disabled={disabled}
              icon={<CloseCircleFilled />}
              onClick={this.onClear}
              type="text"
              size="small"
              className="input-wrapper__button_clear"
            />
          )}
        </div>
        <div
          ref={this.listRef}
          className={
            optListVisible
              ? "search-component__options-list"
              : "search-component__options-list_hide"
          }
        >
          {this.drawSearchList()}
        </div>
      </div>
    );
  }
}

export default Search;
