import React, { useState, useCallback } from "react";

import View from "./view";
import Editing from "./editing";

import useRBAC, { UI_PERMISSIONS } from "../../services/rbac";

import "./style.scss";

const SECRET_KEYS = {
  authorizationHeader: "authorizationHeader",
  secret: "secret",
  basicUsername: "basicUsername",
  basicPassword: "basicPassword",
};

const getInitialSecretVisible = () => {
  const initialValues = {};

  for (const key in SECRET_KEYS) {
    initialValues[key] = false;
  }

  return initialValues;
};

const ServiceAccountCard = (props) => {
  const {
    editing,
    secretUpdating,
    onSaveCommon,
    onSecretUpdate,
    operators,
    operatorGroups,
    id,
  } = props;

  // State
  const [isEditing, setIsEditing] = useState(false);
  const [secretVisible, setSecretVisible] = useState(getInitialSecretVisible());

  // Hooks
  const { checkPermissionToRenderUI } = useRBAC();

  const onChangeSecretVisible = useCallback(
    (key, bool) =>
      setSecretVisible((prev) => ({
        ...prev,
        [key]: bool,
      })),
    []
  );

  if (isEditing) {
    return (
      <Editing
        key={`service-account-card__${id}_editing`}
        {...props}
        withUpdateSecret={checkPermissionToRenderUI(
          UI_PERMISSIONS["ui:management:service-account:card:secret:update"]
        )}
        secretUpdating={secretUpdating}
        loading={editing}
        onCancel={() => setIsEditing(false)}
        operators={operators}
        operatorGroups={operatorGroups}
        onSaveCommon={onSaveCommon}
        onSecretUpdate={onSecretUpdate}
      />
    );
  } else {
    return (
      <View
        key={`service-account-card__${id}_view`}
        {...props}
        onEditing={() => setIsEditing(true)}
        secretVisible={secretVisible}
        onChangeSecretVisible={onChangeSecretVisible}
        secretKeys={SECRET_KEYS}
        secretDisabled={
          !checkPermissionToRenderUI(
            UI_PERMISSIONS["ui:management:service-account:card:secret"]
          )
        }
        editingAllow={checkPermissionToRenderUI(
          UI_PERMISSIONS["ui:management:service-account:card:edit"]
        )}
      />
    );
  }
};

export default ServiceAccountCard;
