import React, { Component } from "react";
import { Upload, Image } from "antd";
import { PlusOutlined } from "@ant-design/icons";

import { uploadFiles } from "../../api/files";

import fileReader from "../../utils/fileReader";

import "./style.scss";

class FileUploader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      previewOpen: false,
      previewImage: "",
      previewTitle: "",
    };

    this.onChange = this.onChange.bind(this);
    this.onRemove = this.onRemove.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.handlePreview = this.handlePreview.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  handleCancel = () => this.setState({ previewOpen: false });

  handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await fileReader(file.originFileObj);
    }

    this.setState({
      previewOpen: true,
      previewTitle: file.name || "",
      previewImage: file.url || file.preview,
    });
  };

  onChange(files) {
    const { onChange = () => {} } = this.props;

    onChange(files);
  }

  onRemove(file) {
    const { files } = this.props;

    const fileIndex = files.findIndex((f) => f.uid === file.uid);
    const start = files.slice(0, fileIndex);
    const end = files.slice(fileIndex + 1);

    this.onChange([...start, ...end]);
  }

  async uploadFiles({ onSuccess, onError, file }) {
    const formData = new FormData();
    await formData.append("files[]", file);

    return await uploadFiles(formData)
      .then((resp) => {
        if (resp.status === 201) {
          const files = [
            ...this.props.files,
            ...resp.data.map((f) => {
              if (f.filename === file.name) {
                return { ...f, uid: file.uid };
              } else {
                return f;
              }
            }),
          ];

          this.onChange(files);

          return onSuccess();
        }
        return onError();
      })
      .catch((err) => {
        console.error(err);
        return onError();
      });
  }

  render() {
    const { disabled = false, listType = "picture-card" } = this.props;
    const { previewImage, previewTitle, previewOpen } = this.state;

    return (
      <>
        <Upload
          multiple
          listType={listType}
          disabled={disabled}
          onRemove={this.onRemove}
          customRequest={this.uploadFiles}
          onPreview={this.handlePreview}
        >
          <button type="button" className="uploader">
            <PlusOutlined />
            <p>Прикрепить файлы</p>
          </button>
        </Upload>

        <Image
          width={200}
          style={{ display: "none" }}
          src=""
          preview={{
            visible: previewOpen,
            src: previewImage,
            onVisibleChange: (value) => {
              this.setState({ previewOpen: value });
            },
          }}
        />

        {/* <Modal
          visible={previewOpen}
          title={previewTitle}
          footer={null}
          onCancel={this.handleCancel}
        >
          <img alt={previewTitle} style={{ width: "100%" }} src={previewImage} />
        </Modal> */}
      </>
    );
  }
}

export default FileUploader;
