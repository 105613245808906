import fetchService from "./api";
import { SEARCH_API_URLS } from "../consts";

export function searchRequest(queryParams = "") {
  const url = SEARCH_API_URLS.search(queryParams);
  return fetchService.protectedGet(url);
}

const SEARCH_API = {
  searchRequest,
};

export default SEARCH_API;
