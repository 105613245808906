import { unprotectedFetch } from "./api";
import { AUTH_API_URLS } from "../consts";

/**
 * Requests a token by username and password.
 * @async
 * @function requestToken
 * @param {string} username - Name of user.
 * @param {string} password - Password of user.
 * @returns {Promise<AxiosResponse<T>>}
 */
export function authorization(username, password) {
  const url = AUTH_API_URLS.authorization();

  return unprotectedFetch({
    url: url,
    method: "POST",
    data: { username, password },
  })
    .then((resp) => (resp.status === 201 ? Promise.resolve(resp) : Promise.reject(resp)))
    .catch((err) => Promise.reject(err));
}

export async function refresh(refresh_token) {
  const url = AUTH_API_URLS.refresh();

  return unprotectedFetch({
    url: url,
    method: "POST",
    data: { refresh_token },
  })
    .then((resp) => (resp.status === 200 ? Promise.resolve(resp) : Promise.reject(resp)))
    .catch((err) => Promise.reject(err));
}

const AUTH_API = {
  authorization,
  refresh,
};

export default AUTH_API;
