import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Form, Input, Button } from "antd";
import { useLocation } from "react-router";

import Search from "../../components/Search_new";
import FilesUploader from "../../components/FilesUploader";
import MainContainer from "../../components/MainContainer";
import AllUserAppeals from "../../components/AllUserAppeals";

import { createAppeal } from "../../actions/appeal/appeal";
import { TYPES } from "../../components/Search_new/consts";
import { useTabs, COMPONENTS } from "../../services/tabs";
import useRBAC, { UI_PERMISSIONS } from "../../services/rbac";

import "./style.scss";

const Header = (props) => {
  const { loading } = props;

  return (
    <>
      <h2 className="header__title">Создание обращения</h2>
      <Button
        className="header__button_create"
        type="primary"
        htmlType="submit"
        children="Создать"
        form="appeal-creation-form"
        loading={loading}
      />
    </>
  );
};

function AppealCreationForm(props) {
  const { componentProps, setComponentProps, tabUuid } = props;

  const location = useLocation();
  const dispatch = useDispatch();

  // Store
  // const operatorId = useSelector((store) => store.user.id);

  // State
  const [loading, setLoading] = useState(false);
  const [files, setFiles] = useState([]);

  // Hooks
  const { openInNewTab } = useTabs();
  const { checkPermissionToRenderUI } = useRBAC();

  function onChangeDescription(e) {
    e.preventDefault();
    setComponentProps({ description: e.target.value });
  }

  function onChangeUser(newUser) {
    setComponentProps({ user: newUser });
  }

  function onChangeOperator(newOperator) {
    setComponentProps({ operator: newOperator });
  }

  async function onSubmit(form) {
    setLoading(true);

    const issue = {
      user_id: form.user.id,
      operator_id: form.operator.id,
      description: form.description,
      file_ids: form.files.map((file) => file.id),
    };

    await dispatch(createAppeal(issue));
    setLoading(false);
  }

  function openOperatorCard(operatorId) {
    openInNewTab({
      component: COMPONENTS.OPERATOR_CARD,
      params: { operatorId },
      locationState: { operatorId },
    });
  }

  function openUserCard(userId) {
    openInNewTab({
      component: COMPONENTS.USER_CARD,
      params: { userId },
      locationState: { userId },
    });
  }

  useEffect(() => {
    const initialState = {
      user: location.state ? { id: location.state.userID, type: TYPES.user } : null,
      operator:
        location.state && location.state.operatorID
          ? {
              id: location.state.operatorID,
              type: TYPES.operator,
            }
          : null,
      files: [],
      description: "",
    };

    if (componentProps === null) {
      setComponentProps(initialState);
    }
  }, [tabUuid]);

  if (componentProps === null) return null;

  const fields = [
    {
      name: "user",
      value: componentProps.user,
    },
    {
      name: "operator",
      value: componentProps.operator,
    },
    {
      name: "files",
      value: files,
    },
    {
      name: "description",
      value: componentProps.description,
    },
  ];

  return (
    <MainContainer
      headerClassName="appeal-creation-form__header"
      bodyClassName="issue-creation-form__body"
      header={<Header loading={loading} />}
    >
      <Form
        id="appeal-creation-form"
        onFinish={onSubmit}
        encType="multipart/form-data"
        className="form"
        fields={fields}
        layout="vertical"
      >
        <Form.Item
          name="operator"
          label="Выберите исполнителя:"
          className="title"
          rules={[{ required: true, message: "Поле должно быть заполнено" }]}
        >
          <Search
            sought={componentProps.operator}
            updateParent={onChangeOperator}
            placeholder="Выбрите исполнителя..."
            types={[TYPES.operator]}
          />
          {componentProps.operator && (
            <Button
              type="link"
              children="Открыть карточку"
              onClick={() => openOperatorCard(componentProps.operator.id)}
            />
          )}
        </Form.Item>

        <Form.Item
          name="user"
          label="Выберите пользователя:"
          className="title"
          rules={[{ required: true, message: "Поле должно быть заполнено" }]}
        >
          <Search
            sought={componentProps.user}
            updateParent={onChangeUser}
            placeholder="Поиск пользователей..."
            types={[TYPES.user, TYPES.employee]}
          />
          {componentProps.user && (
            <Button
              type="link"
              children="Открыть карточку"
              onClick={() => openUserCard(componentProps.user.id)}
            />
          )}
        </Form.Item>

        <Form.Item
          label="Добавьте описание:"
          className="title"
          name="description"
          rules={[{ required: true, message: "Поле должно быть заполнено" }]}
        >
          <Input.TextArea
            className="text-area"
            placeholder="Описание..."
            value={componentProps.description}
            onChange={onChangeDescription}
          />
        </Form.Item>

        <Form.Item name="files" label="Прикрепите файлы:" className="title">
          <FilesUploader onChange={setFiles} files={files} />
        </Form.Item>
      </Form>
      <AllUserAppeals
        userId={componentProps?.user?.id}
        displayIssues={checkPermissionToRenderUI(
          UI_PERMISSIONS["ui:issues:creation-form:user-issues"]
        )}
        displayTasks={checkPermissionToRenderUI(
          UI_PERMISSIONS["ui:issues:creation-form:user-tasks"]
        )}
      />
    </MainContainer>
  );
}

export default AppealCreationForm;
