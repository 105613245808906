import React from "react";

import IssueCard from "../../containers/AppealCard";
import IssueCreationForm from "../../containers/AppealCreationForm";
import IssuesList from "../../containers/AppealsList";
import GroupCard from "../../containers/GroupCard";
import GroupCreationForm from "../../containers/GroupCreationForm";
import LdapPropCard from "../../containers/LdapPropCard";
import LdapPropsCreationForm from "../../containers/LdapPropsCreationForm";
import LocationCard from "../../containers/LocationCard";
import LocationCreationForm from "../../containers/LocationCreationForm";
import MassProblemCard from "../../containers/MassProblemCard";
import MassProblemCreationForm from "../../containers/MassProblemCreationForm";
import MassProblemList from "../../containers/MassProblemList";
import Messanger from "../../containers/Messanger";
import OperatorCard from "../../containers/OperatorCard";
import Management from "../../containers/ManagementPage";
import Profile from "../../containers/Profile";
import RoleCard from "../../containers/RoleCard";
import RoleCreationForm from "../../containers/RoleCreationForm";
import TaskCard from "../../containers/TaskCard";
import TaskCreationForm from "../../containers/TaskCreationForm";
import TaskList from "../../containers/TaskList";
import UserCard from "../../containers/UserCard";
import UserList from "../../containers/UserList";
import Search from "../../containers/Search";
import ReportPage from "../../containers/ReportPage";

import { APP_NAVIGATION_MAP, COMPONENT_URL, COMPONENTS } from "../../consts";
import AccessDenied, {
  ACCESS_DENIED_TYPE,
} from "../../components/AccessDenied";

/**
 * @enum Словарь компонентов
 */
export const COMPONENT_DICTIONARY = {
  [COMPONENTS.ISSUE_CARD]: IssueCard,
  [COMPONENTS.ISSUE_CREATION_FORM]: IssueCreationForm,
  [COMPONENTS.ISSUE_LIST]: IssuesList,
  [COMPONENTS.GROUP_CARD]: GroupCard,
  [COMPONENTS.GROUP_CREATION_FORM]: GroupCreationForm,
  [COMPONENTS.LDAP_PROP_CARD]: LdapPropCard,
  [COMPONENTS.LDAP_PROPS_CREATION_FORM]: LdapPropsCreationForm,
  [COMPONENTS.LOCATION_CARD]: LocationCard,
  [COMPONENTS.LOCATION_CREATION_FORM]: LocationCreationForm,
  [COMPONENTS.MASS_PROBLEM_CARD]: MassProblemCard,
  [COMPONENTS.MASS_PROBLEM_CREATION_FORM]: MassProblemCreationForm,
  [COMPONENTS.MASS_PROBLEM_LIST]: MassProblemList,
  [COMPONENTS.MESSANGER]: Messanger,
  [COMPONENTS.OPERATOR_CARD]: OperatorCard,
  [COMPONENTS.MANAGEMENT]: Management,
  [COMPONENTS.PROFILE]: Profile,
  [COMPONENTS.ROLE_CARD]: RoleCard,
  [COMPONENTS.ROLE_CREATION_FORM]: RoleCreationForm,
  [COMPONENTS.TASK_CARD]: TaskCard,
  [COMPONENTS.TASK_CREATION_FORM]: TaskCreationForm,
  [COMPONENTS.TASK_LIST]: TaskList,
  [COMPONENTS.USER_CARD]: UserCard,
  [COMPONENTS.USER_LIST]: UserList,
  [COMPONENTS.SEARCH]: Search,
  [COMPONENTS.REPORT]: ReportPage,
};

/**
 * @enum Словарь, содержащий названия вкладок в зависимости от компонента
 */
export const TAB_TITLE_DICT = {
  [COMPONENTS.ISSUE_CARD]: (props) =>
    props?.user
      ? `Обращение ${props.user.last_name} ${props.user.first_name[0]}.`
      : "Обращение",
  [COMPONENTS.ISSUE_CREATION_FORM]: "Создание обращения",
  [COMPONENTS.ISSUE_LIST]: "Обращения",
  [COMPONENTS.GROUP_CARD]: () => `Группа`,
  [COMPONENTS.GROUP_CREATION_FORM]: "Создание группы",
  [COMPONENTS.LDAP_PROP_CARD]: () => "Информация о LDAP-свойстве",
  [COMPONENTS.LDAP_PROPS_CREATION_FORM]: "Создание LDAP-свойства",
  [COMPONENTS.LOCATION_CARD]: "Информация о локации",
  [COMPONENTS.LOCATION_CREATION_FORM]: "Создание локации",
  [COMPONENTS.MASS_PROBLEM_CARD]: (props) =>
    props?.human_readable_id
      ? `Масс. проблема ${props.human_readable_id}`
      : "Масс. проблема",
  [COMPONENTS.MASS_PROBLEM_CREATION_FORM]: "Создание масс. проблемы",
  [COMPONENTS.MASS_PROBLEM_LIST]: "Масс. проблемы",
  [COMPONENTS.MESSANGER]: () => `Сообщения`,
  [COMPONENTS.OPERATOR_CARD]: () => `Информация об операторе`,
  [COMPONENTS.MANAGEMENT]: "Управление",
  [COMPONENTS.PROFILE]: "Профиль",
  [COMPONENTS.ROLE_CARD]: () => `Информация о роли`,
  [COMPONENTS.ROLE_CREATION_FORM]: "Создание роли",
  [COMPONENTS.TASK_CARD]: (props) =>
    props?.user
      ? `Задача ${props.user.last_name} ${props.user.first_name[0]}`
      : "Задача",
  [COMPONENTS.TASK_CREATION_FORM]: "Создание задачи",
  [COMPONENTS.TASK_LIST]: "Задачи",
  [COMPONENTS.USER_CARD]: () => `Информация о пользователе`,
  [COMPONENTS.USER_LIST]: "Поиск пользователей",
  [COMPONENTS.SEARCH]: "Поиск",
  [COMPONENTS.REPORT]: "Отчёт",
};

export function getComponentUrl(component, params = {}) {
  if (component in COMPONENT_URL) {
    const url = COMPONENT_URL[component];

    if (Object.keys(params).length > 0) {
      return (() => {
        return url
          .split("/")
          .map((path) => {
            if (path.startsWith(":")) {
              const key = path.substring(1);
              return params[key];
            } else {
              return path;
            }
          })
          .join("/");
      })();
    } else {
      return url;
    }
  } else {
    return "";
  }
}

export function getTabTitle(component, props = {}) {
  if (component in TAB_TITLE_DICT) {
    const title = TAB_TITLE_DICT[component];

    return typeof title === "function" ? title(props) : title;
  } else {
    return "";
  }
}

export function getComponent(component) {
  if (component in COMPONENT_DICTIONARY) {
    return COMPONENT_DICTIONARY[component];
  } else {
    return () => <AccessDenied type={ACCESS_DENIED_TYPE.pageNotFound} />;
  }
}

export function getComponentByUrl(url) {
  function getNestedMap(path, map) {
    if (path in map) return map[path];

    const param = Object.keys(map).find((key) => key.startsWith(":"));
    if (param !== undefined) {
      return map[param];
    }

    return undefined;
  }

  let map = APP_NAVIGATION_MAP;
  const splittedUrl = url.split("/").filter((path) => path !== "");

  for (let i = 0; i < splittedUrl.length; i++) {
    const path = splittedUrl[i];
    const nestedMap = getNestedMap(path, map);

    if (nestedMap === undefined) {
      return undefined;
    }
    if (i === splittedUrl.length - 1) {
      return nestedMap.component || "";
    } else {
      map = nestedMap;
    }
  }

  return undefined;
}

export default COMPONENT_DICTIONARY;
