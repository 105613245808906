import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "antd";
import moment from "moment";

import MainContainer from "../../components/MainContainer";
import Table from "../../components/Table";

import MASS_PROBLEM_API from "../../api/massProblem";
import normalizeDate, { DATE_TIME } from "../../utils/normalizeDate";
import errorHandling from "../../services/errors/errorHandling";
import { MASS_PROBLEM_LIST_GETTING_ERROR } from "../../actions/errors/actionTypes";
import { useTabs, COMPONENTS } from "../../services/tabs";
import useRBAC, { UI_PERMISSIONS } from "../../services/rbac";

const OPERATOR_NOT_FOUND = "Оператор не найден";

const COLUMNS = [
  {
    width: 140,
    title: "Создано",
    dataIndex: "created_at",
    render: (text) => normalizeDate(text, DATE_TIME),
    sorter: {
      compare: (a, b) =>
        moment(a.created_at).unix() - moment(b.created_at).unix(),
    },
  },
  {
    width: 140,
    title: "Обновлено",
    dataIndex: "updated_at",
    render: (text) => normalizeDate(text, DATE_TIME),
    sorter: {
      compare: (a, b) =>
        moment(a.updated_at).unix() - moment(b.updated_at).unix(),
    },
  },
  {
    title: "Описание",
    dataIndex: "description",
    customFilter: true,
    ellipsis: true,
  },
  {
    width: 280,
    title: "Исполнитель",
    render: (text, row) =>
      row.operator
        ? `${row.operator.last_name} ${row.operator.first_name}`
        : OPERATOR_NOT_FOUND,
  },
];

const MassProblemList = () => {
  const dispatch = useDispatch();

  // Store
  const error = useSelector((store) => store.error);

  // State
  const [loading, setLoading] = useState(false);
  // const [operators, setOperators] = useState([]);
  const [massProblems, setMassProblems] = useState([]);

  // Hooks
  const { clickHandler } = useTabs();
  const { checkPermissionToRenderUI } = useRBAC();

  async function getMassProblems() {
    await setLoading(true);

    await MASS_PROBLEM_API.getMassProblemsList()
      .finally(() => setLoading(false))
      .then((resp) => {
        setMassProblems(resp.data);
      })
      .catch((err) =>
        dispatch(errorHandling(err, MASS_PROBLEM_LIST_GETTING_ERROR, false))
      );
  }

  function openMassProblemCard(e, massProblem) {
    e.preventDefault();
    e.stopPropagation();

    clickHandler({
      component: COMPONENTS.MASS_PROBLEM_CARD,
      params: { massProblemId: massProblem.id },
      locationState: { massProblemId: massProblem.id },
    });
  }

  async function getMassProblemsListData() {
    await getMassProblems();
  }

  function checkMassProblems(massProblems) {
    if (Array.isArray(massProblems)) {
      return massProblems;
    } else {
      if (error.type === null) {
        dispatch(
          errorHandling(
            {
              message:
                "Невозможно отобразить список массовых проблем. Получен неверный формат данных",
            },
            MASS_PROBLEM_LIST_GETTING_ERROR,
            false
          )
        );
      }

      return [];
    }
  }

  function getError(error) {
    return error.type === MASS_PROBLEM_LIST_GETTING_ERROR ? error : null;
  }

  // componentDidMount
  useEffect(() => {
    getMassProblemsListData();
  }, []);

  return (
    <MainContainer
      className="page_mass-problems"
      title="Массовые проблемы"
      error={getError(error)}
      loading={loading}
    >
      <Table
        data={checkMassProblems(massProblems)}
        columns={COLUMNS}
        onRowClick={openMassProblemCard}
        title={() =>
          checkPermissionToRenderUI(UI_PERMISSIONS['ui:mass-problems:list:create-mass-problem']) && (
            <div style={{ display: "flex" }}>
              <Button
                style={{ marginLeft: "auto" }}
                type="primary"
                children="Создать массовую проблему"
                onClick={() =>
                  clickHandler({
                    component: COMPONENTS.MASS_PROBLEM_CREATION_FORM,
                  })
                }
              />
            </div>
          )
        }
      />
    </MainContainer>
  );
};

export default MassProblemList;
