import { MS_IN_HOUR, MS_IN_MINUTE, MS_IN_SECOND } from "../constants/math";

const getTimeDifference = (n) => {
  let r = n;

  const hours = Math.floor(r / MS_IN_HOUR);
  r = r - hours * MS_IN_HOUR;
  const minutes = Math.floor(r / MS_IN_MINUTE);
  r = r - minutes * MS_IN_MINUTE;
  const seconds = Math.round(r / MS_IN_SECOND);

  return {
    hours,
    minutes,
    seconds,
  };
};

export default getTimeDifference;
