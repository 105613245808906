// dialog actions
export const GET_ACTIVE_DIALOGS = "GET_ACTIVE_DIALOGS";
export const SELECT_DIALOG = "SELECT_DIALOG";
export const UNSELECT_DIALOG = "UNSELECT_DIALOG";
export const SET_UNREAD_MARK = "SET_UNREAD_MARK";
export const REMOVE_UNREAD_MARK = "REMOVE_UNREAD_MARK";

// message action
export const GET_MESSAGES = "GET_MESSAGES";
export const SEND_MESSAGE = "SEND_MESSAGE";
export const CLEAR_MESSAGES = "CLEAR_MESSAGES";
export const NEW_MESSAGE = "NEW_MESSAGE";

export default {
  GET_ACTIVE_DIALOGS,
  SELECT_DIALOG,
  UNSELECT_DIALOG,
  SET_UNREAD_MARK,
  REMOVE_UNREAD_MARK,
  GET_MESSAGES,
  SEND_MESSAGE,
  CLEAR_MESSAGES,
  NEW_MESSAGE,
};
