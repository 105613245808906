import React, { Component } from "react";
import { connect } from "react-redux";
import { Modal } from "antd";

import errorTypes from "../../actions/errors/actionTypes";

export const ErrorService = (props) => {
  const { errors } = props;

  return errors.map((err, idx) => (
    <Modal
      mask={idx === 0}
      key={`error${idx}_${err.uuid}`}
      visible={true}
      onCancel={err.removeError}
      title={err.title}
      footer={false}
      className="error-message"
    >
      {err.status && (
        <p className="error-message__code">Код ошибки: {err.status}</p>
      )}
      {err.message && <p className="error-message__text">{err.message}</p>}
    </Modal>
  ));
};

class ErrorMessage extends Component {
  render() {
    const { status, message, title, visible, resetError } = this.props;

    return (
      <Modal
        visible={visible}
        onCancel={resetError}
        title={title}
        footer={false}
        className="error-message"
      >
        {status && <p className="error-message__code">Код ошибки: {status}</p>}
        {message && <p className="error-message__text">{message}</p>}
      </Modal>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    status: store.error.status,
    message: store.error.message,
    title: store.error.title,
    visible: store.error.visible,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    resetError: () => dispatch({ type: errorTypes.RESET_ERROR }),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ErrorMessage);
