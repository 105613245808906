import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { message } from "antd";

import Card, { CARD_SECTIONS, OBSERVABLE_KEYS } from "../../components/Card";
import Header from "./header";
import Tabs from "./tabs";

import TASKS_API from "../../api/tasks";
import ISSUES_API from "../../api/appeals";
import OPERATORS_API from "../../api/operators";
import errorHandling from "../../services/errors/errorHandling";
import {
  RESET_ERROR,
  TASK_GETTING_ERROR,
  TASK_CLOSING_ERROR,
  TASK_UPLOAD_FILES_ERROR,
  TASK_PERFORMER_CHANGING_ERROR,
  TASK_DESCRIPTION_EDITING_ERROR,
} from "../../actions/errors/actionTypes";
import { COMPONENT_URL } from "../../consts";
import { COMPONENTS } from "../../services/tabs";
import checkRoute from "../../utils/checkRoute";
import useRBAC, { UI_PERMISSIONS } from "../../services/rbac";
import useTemplate from "../../hooks/useTemplate.hook";
import useTask from "../../hooks/useTask.hook";

import "./style.scss";

const TaskCard = (props) => {
  const { componentProps, setComponentProps, tabUuid, removeTab } = props;

  const dispatch = useDispatch();
  const location = useLocation();

  const taskId = location.state ? location.state.taskId : getTaskIdFromUrl();

  // Store
  const userId = useSelector((store) => store.user.id);
  const error = useSelector((store) => store.error);
  const { newNotifications } = useSelector((store) => store.notifications);

  // State
  const [changes, setChanges] = useState(null);
  const [loading, setLoading] = useState(false);
  const [operators, setOperators] = useState([]);
  const [groups, setGroups] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [templates, setTemplates] = useState([]);

  // Hooks
  const { checkPermissionToRenderUI } = useRBAC();
  const { getTemplates } = useTemplate();
  const { changeTaskTemplate, changeTaskHardware, removeTaskHardware } =
    useTask();

  async function reopenTask(taskId) {
    try {
      const response = await TASKS_API.taskReopen(taskId);

      if (response.status === 200) {
        await getTask();
        await setLoading(false);
        message.success("Задача открыта повторно");
      } else {
        message.error("Не удалось повторно открыть задачу");
      }
    } catch (err) {
      console.error(err);

      // TODO: добавить нормальную обработку ошибок
      message.error("Не удалось повторно открыть задачу");
    }
  }

  async function setTaskPriority(taskId, urgency) {
    try {
      const response = await TASKS_API.setTaskPriority(taskId, urgency);

      if (response.status === 200) {
        message.success("Успешно");
        setComponentProps({ urgency });
      } else {
        message.error("Ошибка! Приоритет не изменен");
      }
    } catch (err) {
      message.error("Ошибка! Приоритет не изменен");
    }
  }

  function getTaskIdFromUrl() {
    const id = window.location.pathname.replace("/", "").split("/")[1];

    return checkRoute(
      COMPONENT_URL[COMPONENTS.TASK_CARD],
      window.location.pathname
    )
      ? id
      : undefined;
  }

  function setDisabledState(task) {
    setDisabled(task ? Boolean(task.done_at || task.deleted_at) : true);
  }

  async function getTask() {
    setComponentProps(null);

    await TASKS_API.getTaskByID(taskId)
      .then((resp) => {
        setDisabledState(resp.data);
        setComponentProps({ ...resp.data });
      })
      .catch((err) => {
        if (err.response) {
          err.response.data.message = `При получении данных задачи произошла ошибка\n${err.response.data.message}`;
        }
        setDisabledState({});
        dispatch(errorHandling(err, TASK_GETTING_ERROR, false));
      });
  }

  async function getOperators() {
    await setLoading(true);

    await OPERATORS_API.getOperatorsList()
      .then((resp) => {
        const operators = resp.data.map((operator) => ({
          label: `${operator.last_name} ${operator.first_name}`,
          value: operator.id,
        }));
        setOperators(operators);
      })
      .catch((err) => {
        if (err.response) {
          err.response.data.message = `При получении списка операторов произошла ошибка\n${err.response.data.message}`;
        }
        dispatch(errorHandling(err, TASK_GETTING_ERROR, false));
      });

    await setLoading(false);
  }

  async function getGroups() {
    await setLoading(true);

    await OPERATORS_API.getOperatorsGroupsList()
      .then((resp) => {
        const groups = resp.data.map((group) => ({
          label: group.title,
          value: group.id,
        }));
        setGroups(groups);
      })
      .catch((err) => {
        if (err.response) {
          err.response.data.message = `При получении списка групп операторов произошла ошибка\n${err.response.data.message}`;
        }
        dispatch(errorHandling(err, TASK_GETTING_ERROR, false));
      });

    await setLoading(false);
  }

  async function getIssueRelatedTasks() {
    if (!(componentProps && componentProps.issue_id)) return;

    await setLoading(true);

    await ISSUES_API.getRelatedTasks(componentProps.issue_id)
      .then((resp) => {
        setComponentProps({
          relatedTasks: resp.data.filter((task) => task.id !== taskId),
        });
      })
      .catch((err) => {
        if (err.response) {
          err.response.data.message = `При получении связанных задач произошла ошибка\n${err.response.data.message}`;
        }
        dispatch(errorHandling(err, TASK_GETTING_ERROR, false));
      });

    await setLoading(false);
  }

  async function getTaskChanges() {
    await TASKS_API.getTaskChanges(taskId)
      .then((resp) => {
        setComponentProps({ changes: resp.data });
      })
      .catch((err) => {
        if (err.response) {
          err.response.data.message = `При получении истории изменений задачи произошла ошибка\n${err.response.data.message}`;
        }
        dispatch(errorHandling(err, TASK_GETTING_ERROR, false));
      });
  }

  async function getTaskCardData() {
    await setLoading(true);

    try {
      await getTask();
      await getGroups();
      await getOperators();
      await getIssueRelatedTasks();

      const templates = await getTemplates().then((templates) =>
        templates.map((_t) => ({ value: _t.id, label: _t.title }))
      );

      setTemplates(templates);
    } catch {
      setLoading(false);
    }
  }

  async function changeTaskPerformer(taskId, performerId) {
    let request;
    const isOperator = operators.some((o) => o.value === performerId);
    const isGroup = groups.some((g) => g.value === performerId);

    if (isOperator) request = TASKS_API.changeOperator;
    if (isGroup) request = TASKS_API.changeOperatorsGroup;

    await setLoading(true);
    return await request(taskId, performerId)
      .finally(() => setLoading(false))
      .catch((err) => {
        dispatch(errorHandling(err, TASK_PERFORMER_CHANGING_ERROR));

        return Promise.reject(err);
      });
  }

  async function uploadTaskFiles(taskId, fileIds) {
    await setLoading(true);

    return await TASKS_API.uploadTaskFiles(taskId, fileIds)
      .finally(() => setLoading(false))
      .catch((err) => {
        dispatch(errorHandling(err, TASK_UPLOAD_FILES_ERROR));

        return Promise.reject(err);
      });
  }

  async function editTaskDescription(taskId, description) {
    await setLoading(true);

    return await TASKS_API.editTaskDescription(taskId, description)
      .finally(() => setLoading(false))
      .catch((err) => {
        dispatch(errorHandling(err, TASK_DESCRIPTION_EDITING_ERROR));

        return Promise.reject(err);
      });
  }

  const onChangeTemplate = async (taskId, templateId) => {
    await setLoading(true);

    return await changeTaskTemplate(taskId, templateId).finally((resp) => {
      setLoading(false);
      return resp;
    });
  };

  const onChangeHardware = async (taskId, hardwareId) => {
    await setLoading(true);

    return await changeTaskHardware(taskId, hardwareId).finally((resp) => {
      setLoading(false);
      return resp;
    });
  };

  const onRemoveHardware = async (taskId, hardwareId) => {
    await setLoading(true);

    return await removeTaskHardware(taskId, hardwareId).finally((resp) => {
      setLoading(false);

      return resp;
    });
  };

  async function handleSaveAndCloseClick(taskId, changes) {
    try {
      await handleSaveClick(taskId, changes);
      removeTab();
    } catch (err) {
      console.error(err);
      message.error("Не удалось сохранить изменения");
    }
  }

  async function handleSaveClick(taskId, changes) {
    try {
      for (let key in changes) {
        if (key === OBSERVABLE_KEYS.performer)
          await changeTaskPerformer(taskId, changes[key]);
        if (key === OBSERVABLE_KEYS.files) {
          const fileIds = changes[key].map((f) => f.id);
          await uploadTaskFiles(taskId, fileIds);
        }
        if (key === OBSERVABLE_KEYS.description)
          await editTaskDescription(taskId, changes[key]);
        if (key === OBSERVABLE_KEYS.templateId) {
          const templateId = changes[key];

          if (templateId) {
            await onChangeTemplate(taskId, templateId);
          } else {
            // удаляем шаблон
          }
        }
        if (key === OBSERVABLE_KEYS.hardware) {
          const hardwareId = changes[key]?.id;

          if (hardwareId) {
            await onChangeHardware(taskId, hardwareId);
          } else {
            await onRemoveHardware(taskId);
          }
        }
      }

      message.success("Сохранено");
      await getTaskCardData();
    } catch {
      setLoading(false);
    }
  }

  async function handleSaveDone() {
    await TASKS_API.closeTask(taskId)
      .then(() => getTaskCardData())
      .catch((err) => dispatch(errorHandling(err, TASK_CLOSING_ERROR)));
  }

  function getError(error) {
    return error.type === TASK_GETTING_ERROR ? error : null;
  }

  function getCardHeader(error) {
    if (getError(error) !== null) return null;

    return (
      <Header
        task={componentProps || {}}
        loading={loading}
        closed={disabled}
        hasChanges={changes !== null}
        setPriority={setTaskPriority}
        onSave={() => handleSaveClick(componentProps.id, changes)}
        onDone={handleSaveDone}
        onSaveAndClose={() =>
          handleSaveAndCloseClick(componentProps.id, changes)
        }
        reopenTask={() => reopenTask(componentProps.id)}
        rejectTask={
          componentProps?.callback &&
          componentProps.callback.reject_url &&
          componentProps.callback.reject_method
            ? () => rejectTask(componentProps.id)
            : undefined
        }
      />
    );
  }

  function checkClosed(task) {
    if (!task) {
      return undefined;
    } else {
      return Boolean(task.done_at || task.deleted_at);
    }
  }

  function resetError() {
    dispatch({ type: RESET_ERROR });
  }

  async function putTaskUser(taskId, userId) {
    try {
      await TASKS_API.putTaskUser(taskId, userId);
      await getTask(taskId);

      message.success("Пользователь добавлен");
    } catch (err) {
      console.error(err);
      message.error("Не удалось добавить пользователя");
    }

    await setLoading(false);
  }

  async function rejectTask(taskId) {
    try {
      await TASKS_API.taskReject(taskId);
      await getTask(taskId);

      message.success("Задача отклонена");
    } catch (err) {
      console.error(err);
      message.error("Не удалось отклонить задачу");
    }

    await setLoading(false);
  }

  useEffect(() => {
    resetError();
    getTaskCardData();
  }, [tabUuid]);

  return (
    <div className="page__task-card">
      <Card
        sections={[
          CARD_SECTIONS.user,
          CARD_SECTIONS.data,
          CARD_SECTIONS.description,
          CARD_SECTIONS.files,
          CARD_SECTIONS.hardware,
          CARD_SECTIONS.relatedTasks,
          CARD_SECTIONS.userAppeals,
        ]}
        data={{
          ...componentProps,
          disabled,
          loading,
          error: getError(error),
          operators,
          groups,
          closedLabel: {
            closed: checkClosed(componentProps),
            text: "Задача закрыта",
          },
          template: {
            id: componentProps?.template_id,
            options: templates,
          },
          hardware: componentProps?.hardware,
          permissions: {
            changePerformerAllowed: checkPermissionToRenderUI(
              UI_PERMISSIONS["ui:tasks:card:performer:edit"]
            ),
            changingWorkstationNumberAllowed: checkPermissionToRenderUI(
              UI_PERMISSIONS["ui:tasks:card:user:workstation-number:edit"]
            ),
            changingWorkplaceLocationAllowed: checkPermissionToRenderUI(
              UI_PERMISSIONS["ui:tasks:card:user:workplace-location:edit"]
            ),
            displayIssuesHistory: checkPermissionToRenderUI(
              UI_PERMISSIONS["ui:tasks:card:user-issues"]
            ),
            displayTasksHistory: checkPermissionToRenderUI(
              UI_PERMISSIONS["ui:tasks:card:user-tasks"]
            ),
            descriptionEditingAllowed: checkPermissionToRenderUI(
              UI_PERMISSIONS["ui:tasks:card:description:edit"]
            ),
            changeTemplateAllowed: checkPermissionToRenderUI(
              UI_PERMISSIONS["ui:tasks:card:template:change"]
            ),
            hardware: {
              change: checkPermissionToRenderUI(
                UI_PERMISSIONS["ui:tasks:card:hardware:change"]
              ),
              remove: checkPermissionToRenderUI(
                UI_PERMISSIONS["ui:tasks:card:hardware:remove"]
              ),
              changesList: checkPermissionToRenderUI(
                UI_PERMISSIONS["ui:tasks:card:hardware:changes:list"]
              ),
              changeCreate: checkPermissionToRenderUI(
                UI_PERMISSIONS["ui:tasks:card:hardware:changes:create"]
              ),
              changeEdit: checkPermissionToRenderUI(
                UI_PERMISSIONS["ui:tasks:card:hardware:changes:edit"]
              ),
              changeRemove: checkPermissionToRenderUI(
                UI_PERMISSIONS["ui:tasks:card:hardware:changes:remove"]
              ),
            },
          },
        }}
        className={`page__card_task ${
          checkClosed(componentProps) ? "task_closed" : ""
        }`}
        header={getCardHeader(error)}
        onUpdate={getTask}
        onChange={setChanges}
        getHistory={getTaskChanges}
        addUser={
          !checkClosed(componentProps)
            ? (userId) => putTaskUser(taskId, userId)
            : undefined
        }
      />
      <Tabs
        taskId={taskId}
        operatorId={userId}
        newNotifications={newNotifications}
      />
    </div>
  );
};

export default TaskCard;
