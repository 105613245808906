import TAB_ACTION_TYPES from "../actions/tabs/actionTypes";

const initialState = {
  list: [],
  active: {
    index: null,
    uuid: null,
  },
};

export default function tabsReducer(state = initialState, action) {
  switch (action.type) {
    case TAB_ACTION_TYPES.SET_ACTIVE_TAB:
      return { ...state, active: action.payload };
    case TAB_ACTION_TYPES.ADD_TAB:
    case TAB_ACTION_TYPES.REMOVE_TAB:
    case TAB_ACTION_TYPES.SET_TAB_ERROR:
    case TAB_ACTION_TYPES.SET_TAB_COMPONENT:
    case TAB_ACTION_TYPES.SET_TAB_COMPONENT_PROPS:
    case TAB_ACTION_TYPES.OPEN_PREVIOUSLY_OPENED_TABS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
