import React from "react";
import { Button } from "antd";

const GoTo = ({ onClick }) => {
  return (
    <Button type="primary" onClick={onClick}>
      Перейти к МП
    </Button>
  );
};

export default ({ onOpenTab }) => ({
  message: "Новый комментарий к МП",
  btn: <GoTo onClick={onOpenTab} />,
});
