import React, { useEffect, useRef } from "react";
import { Input, Select, Button } from "antd";
import {
  // CloseOutlined,
  SearchOutlined,
  SortAscendingOutlined,
  SortDescendingOutlined,
} from "@ant-design/icons";

import "./style.scss";

export const SORT_DIRECTION = {
  asc: "asc",
  desc: "desc",
};

const DEFAULT_SORT = {
  options: [],
  selected: undefined,
  direction: SORT_DIRECTION.asc,
  directionDisabled: false,
  onChangeSort: () => {},
  onChangeSortDirection: () => {},
};
const DEFAULT_QUERY = {
  value: "",
  onChange: () => {},
};

// const DataItem = ({ label = "", onClick }) => {
//   return (
//     <div className="data-list__data-item" onClick={onClick}>
//       {label[0].toUpperCase() + label.substring(1)}
//     </div>
//   );
// };

// const DataList = ({ visible = false, data = [], onClick = () => {} }) => {
//   if (!visible) return null;

//   return (
//     <div className="search-and-filter__data-list">
//       {data.map((item, index) => (
//         <DataItem
//           label={item.label}
//           key={`${item.key}_${index}`}
//           onClick={() => onClick(item.key)}
//         />
//       ))}
//     </div>
//   );
// };

const Search = (props) => {
  const {
    onSearch = () => {},
    query = DEFAULT_QUERY,
    sort = DEFAULT_SORT,
  } = props;

  // State
  // const [isInputFocused, setIsInputFocused] = useState(false);

  // Refs
  const inputRef = useRef();
  const inputWrapperRef = useRef();

  function getSortDirectionIcon(type) {
    switch (type) {
      case "asc":
        return <SortAscendingOutlined />;
      case "desc":
        return <SortDescendingOutlined />;
      default:
        return <></>;
    }
  }

  function onSearchInputClick() {
    // setIsInputFocused(true);
    setInputFocus(inputRef);
  }

  function setInputFocus(ref) {
    if (ref && ref.current) {
      ref.current.focus();
    }
  }

  function onClickOutside(event) {
    if (!event.composedPath().includes(inputWrapperRef.current)) {
      // setIsInputFocused(false);
    }
  }

  // componentDidMount, componentWillUnmount
  useEffect(() => {
    document.addEventListener("click", onClickOutside);

    return () => document.removeEventListener("click", onClickOutside);
  }, []);

  return (
    <div className="component_search">
      <div
        className="search__input-wrapper"
        ref={inputWrapperRef}
        onClick={onSearchInputClick}
      >
        <Input.Search
          allowClear
          placeholder="Поиск"
          ref={inputRef}
          className="input-wrapper__input"
          size="large"
          bordered={false}
          value={query.value}
          onChange={query.onChange}
          onSearch={onSearch}
          enterButton={
            <Button
              className="search-and-filter__button_search"
              size="large"
              type="primary"
              icon={<SearchOutlined />}
              onClick={onSearch}
            />
          }
        />
        {/* <DataList visible={isInputFocused} data={[]} onClick={() => {}} /> */}
      </div>
      <div className="search__sorting-wrapper">
        <Select
          size="large"
          placeholder="Сортировать"
          value={sort.selected}
          options={sort.options}
          onChange={sort.onChangeSort}
        />
        <Button
          size="large"
          icon={getSortDirectionIcon(sort.direction)}
          onClick={sort.onChangeSortDirection}
          disabled={sort.directionDisabled}
        />
      </div>
    </div>
  );
};

export default Search;
