import React, { useState, useEffect, memo } from "react";
import throttle from "lodash.throttle";
import { Layout, Menu, Badge } from "antd";
import {
  UserOutlined,
  FileTextOutlined,
  NotificationOutlined,
  CommentOutlined,
  LogoutOutlined,
  WarningOutlined,
  SearchOutlined,
  BellOutlined,
  PieChartOutlined,
} from "@ant-design/icons";

import Logo from "../Logo";

import { APP_VERSION } from "../../constants/app";
import PATH from "../../paths";
import { history } from "../../";
import { useTabs, COMPONENTS } from "../../services/tabs";
import useRBAC, { UI_PERMISSIONS } from "../../services/rbac";

import "./style.scss";

const { Sider } = Layout;

function SideMenu(props) {
  //Props
  const {
    userName,
    logout,
    newMessagesCounter = [],
    openIssueCounter = [],
    openTaskCounter = [],
    openMassProblems = [],
    showNotificationsList,
  } = props;

  // State
  const [collapsed, setCollapsed] = useState(window.innerWidth < 1200);
  const [collapsible, setCollapsible] = useState(!(window.innerWidth < 1200));
  const [currentPath, setCurrentPath] = useState(getCurrentMenuItem(window.location));

  // Hooks
  const { clickHandler } = useTabs();
  const { checkPermissionToRenderUI } = useRBAC();

  function onCollapsed(collapsed) {
    setCollapsed(collapsed);
  }

  const blockResize = throttle(() => {
    const isCollapse = window.innerWidth < 1200;

    setCollapsed(isCollapse);
    setCollapsible(!isCollapse);
  }, 300);

  function getCurrentMenuItem({ pathname }) {
    const path = pathname.split("/");
    return path !== null ? `/${path[1]}` : pathname;
  }

  // function drawTestLink() {
  //   return (
  //     <Menu.Item
  //       key={"/test"}
  //       className="menu-wrapper__menu-item"
  //       onClick={() => clickHandler({})}
  //     >
  //       <UserOutlined />
  //       <span>Вкладки</span>
  //     </Menu.Item>
  //   );
  // }

  // componentDidMount, componentWillUnmount
  useEffect(() => {
    window.addEventListener("resize", blockResize);
    history.listen((e) => setCurrentPath(getCurrentMenuItem(e)));

    return () => {
      window.removeEventListener("resize", blockResize);
    };
  }, []);

  return (
    <Sider
      className="left-side-menu"
      collapsible={collapsible}
      collapsed={collapsed}
      onCollapse={onCollapsed}
    >
      {collapsed ? (
        <Logo.Short key="app-logo-short" appVersion={APP_VERSION} />
      ) : (
        <Logo.Full key="app-logo-full" appVersion={APP_VERSION} />
      )}

      <Menu className="menu-wrapper" theme="dark" selectedKeys={[currentPath]}>
        {checkPermissionToRenderUI(UI_PERMISSIONS["ui:side-menu:issues"]) && (
          <Menu.Item
            key={PATH.ISSUES}
            className="menu-wrapper__menu-item"
            onClick={() => clickHandler({ component: COMPONENTS.ISSUE_LIST })}
          >
            <FileTextOutlined />
            <span>Обращения</span>
            <Badge
              count={openIssueCounter.length}
              dot={collapsed}
              className="notifications-counter"
            />
          </Menu.Item>
        )}

        {checkPermissionToRenderUI(UI_PERMISSIONS["ui:side-menu:tasks"]) && (
          <Menu.Item
            key={PATH.TASKS}
            className="menu-wrapper__menu-item"
            onClick={() => clickHandler({ component: COMPONENTS.TASK_LIST })}
          >
            <NotificationOutlined />
            <span>Задачи</span>
            <Badge
              count={openTaskCounter.length}
              dot={collapsed}
              className="notifications-counter"
            />
          </Menu.Item>
        )}

        {checkPermissionToRenderUI(UI_PERMISSIONS["ui:side-menu:mass-problems"]) && (
          <Menu.Item
            className="menu-wrapper__menu-item"
            key={PATH.MASS_PROBLEMS}
            onClick={() =>
              clickHandler({
                component: COMPONENTS.MASS_PROBLEM_LIST,
              })
            }
          >
            <WarningOutlined />
            {openMassProblems.length === 0 ? (
              <span>Массовые проблемы</span>
            ) : (
              <span>Масс. проблемы</span>
            )}
            <Badge
              count={openMassProblems.length}
              dot={collapsed}
              className="notifications-counter"
            />
          </Menu.Item>
        )}

        {checkPermissionToRenderUI(UI_PERMISSIONS["ui:side-menu:messanger"]) && (
          <Menu.Item
            key={PATH.MESSAGES}
            className="menu-wrapper__menu-item"
            onClick={() => clickHandler({ component: COMPONENTS.MESSANGER })}
          >
            <CommentOutlined />
            <span>Сообщения</span>
            <Badge
              count={newMessagesCounter.length}
              dot={collapsed}
              className="notifications-counter"
            />
          </Menu.Item>
        )}

        {checkPermissionToRenderUI(UI_PERMISSIONS["ui:side-menu:users"]) && (
          <Menu.Item
            key={PATH.USERS}
            className="menu-wrapper__menu-item"
            onClick={() => clickHandler({ component: COMPONENTS.USER_LIST })}
          >
            <UserOutlined />
            <span>Пользователи</span>
          </Menu.Item>
        )}

        {checkPermissionToRenderUI(UI_PERMISSIONS["ui:side-menu:search"]) && (
          <Menu.Item
            key={PATH.SEARCH}
            className="menu-wrapper__menu-item"
            onClick={() => clickHandler({ component: COMPONENTS.SEARCH })}
          >
            <SearchOutlined />
            <span>Поиск</span>
          </Menu.Item>
        )}

        {checkPermissionToRenderUI(UI_PERMISSIONS["ui:side-menu:management"]) && (
          <Menu.Item
            key={PATH.MANAGEMENT}
            className="menu-wrapper__menu-item"
            onClick={() => clickHandler({ component: COMPONENTS.MANAGEMENT })}
          >
            <UserOutlined />
            <span>Управление</span>
          </Menu.Item>
        )}

        {checkPermissionToRenderUI(UI_PERMISSIONS["ui:side-menu:report"]) && (
          <Menu.Item
            key={PATH.REPORT}
            className="menu-wrapper__menu-item"
            onClick={() => clickHandler({ component: COMPONENTS.REPORT })}
          >
            <PieChartOutlined />
            <span>Отчёт</span>
          </Menu.Item>
        )}

        {/* {drawTestLink()} */}

        <Menu theme="dark" style={{ marginTop: "auto" }} selectedKeys={[currentPath]}>
          {checkPermissionToRenderUI(UI_PERMISSIONS["ui:side-menu:profile"]) && (
            <Menu.Item
              key={PATH.PROFILE}
              className="user-info"
              onClick={() => clickHandler({ component: COMPONENTS.PROFILE })}
            >
              <UserOutlined />
              <span>{userName}</span>
            </Menu.Item>
          )}

          <Menu.Item key="notifications" onClick={showNotificationsList}>
            <BellOutlined />
            <span>Уведомления</span>
          </Menu.Item>

          <Menu.Item className="button_logout" onClick={logout}>
            <LogoutOutlined />
            <span>Выйти</span>
          </Menu.Item>
        </Menu>
      </Menu>
    </Sider>
  );
}

export default memo((props) => SideMenu(props));
