import React from "react";
import { Button } from "antd";

import { getOperatorData } from "../../../api/operators";

const GoTo = ({ onClick }) => {
  return (
    <Button type="primary" onClick={onClick}>
      Перейти к МП
    </Button>
  );
};

export default async (data) => {
  const { operator_id, onOpenTab } = data;
  let operatorName;

  await getOperatorData(operator_id)
    .then((resp) => {
      operatorName = `${resp.data.last_name} ${resp.data.first_name}`;
    })
    .catch(() => {
      operatorName = "Оператор не найден";
    });

  return {
    message: "Новая массовая проблема",
    description: `Новая массовая проблема назначена на ${operatorName}`,
    btn: <GoTo onClick={onOpenTab} />,
  };
};
