import React from "react";
import { DatePicker } from "antd";
import ru_RU from "antd/lib/date-picker/locale/ru_RU";

import ComparisonsSelect from "./comparisonsSelect";
import ExistenceSelect from "./existenceSelect";
import { COMPARISONS, isEqualMoments } from ".";

const { RangePicker } = DatePicker;

const DATE_FORMAT = "DD.MM.YYYY";

const DateFilter = ({
  label = "",
  selected = [],
  comparisons = [],
  existence = undefined,
  onChange = () => {},
  onChangeComparison = () => {},
  onChangeExistence = () => {},
}) => {
  const [startDate, endDate] = selected;

  function onChangeDates(prevDates = [], currentDates = []) {
    if (!currentDates) {
      onChange(0, {
        value: null,
        label: "",
        comparison: COMPARISONS.gte.value,
      });
      onChange(1, {
        value: null,
        label: "",
        comparison: COMPARISONS.lte.value,
      });

      return;
    }

    const [startDate, endDate] = currentDates;

    const prevIsEqual = isEqualMoments(prevDates.map((date) => date.value));
    const currentIsEqual = isEqualMoments(currentDates);

    if (prevIsEqual && currentIsEqual) {
      return currentDates.forEach((date, index) =>
        onChange(index, { value: date, label: date.format(DATE_FORMAT) })
      );
    }
    if (prevIsEqual && !currentIsEqual) {
      onChange(0, {
        value: startDate,
        label: startDate.format(DATE_FORMAT),
        comparison: COMPARISONS.gte.value,
      });
      onChange(1, {
        value: endDate,
        label: endDate.format(DATE_FORMAT),
      });

      return;
    }
    if (!prevIsEqual && currentIsEqual) {
      onChange(0, {
        value: startDate,
        label: startDate.format(DATE_FORMAT),
        comparison: COMPARISONS.eq.value,
      });
      onChange(1, {
        value: endDate,
        label: endDate.format(DATE_FORMAT),
      });

      return;
    }
    if (!prevIsEqual && !currentIsEqual) {
      onChange(0, {
        value: startDate,
        label: startDate.format(DATE_FORMAT),
        comparison: COMPARISONS.gte.value,
      });
      onChange(1, {
        value: endDate,
        label: endDate.format(DATE_FORMAT),
      });

      return;
    }

    console.error("change date filter error");
    return;
  }

  return (
    <div className="search-filter__filter search-filter__filter_date">
      <div className="filter__label">
        {label}
        {existence !== undefined && (
          <ExistenceSelect
            value={existence.selected}
            options={existence.options}
            onChange={onChangeExistence}
          />
        )}
      </div>
      {!(existence && existence.selected) && (
        <>
          <div className="filter__picker-wrapper">
            {isEqualMoments(selected.map((date) => date.value)) && (
              <ComparisonsSelect
                value={startDate.comparison}
                options={comparisons}
                onChange={(value) => onChangeComparison(0, value)}
              />
            )}
            <RangePicker
              style={{ width: "100%" }}
              size="small"
              format={DATE_FORMAT}
              locale={ru_RU}
              value={[startDate.value, endDate.value]}
              onChange={(dates) => onChangeDates(selected, dates)}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default DateFilter;
