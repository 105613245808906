import React from "react";

import UserMessage from "./UserMessage";
import EditingUserMessage from "./EditingUserMessage";

const AddUserMessages = ({
  messages,
  onEdit,
  onDelete,
  onChangeMessageText,
  onPressEnter,
}) => {
  if (messages.length < 1) return null;

  return (
    <div className="task-creation-form__add-user-messages">
      {messages.map((m) =>
        m.isEditing ? (
          <EditingUserMessage
            key={m.id}
            message={m}
            onBlur={() => onEdit(m.id, false)}
            onPressEnter={(event) => onPressEnter(m.id, event)}
            onChange={(value) => onChangeMessageText(m.id, value)}
          />
        ) : (
          <UserMessage
            key={m.id}
            message={m}
            onDelete={() => onDelete(m.id)}
            onEdit={() => onEdit(m.id, true)}
          />
        )
      )}
    </div>
  );
};

export default AddUserMessages;
