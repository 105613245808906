import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Input, Button, Upload } from "antd";
import { PaperClipOutlined } from "@ant-design/icons";

import DialogsList from "./dialogs";
import MessagesList from "./messages";
import SendingError from "./sendingError";
import AttachedFilePreview from "./attachedFilePreview";
import { useTabs, COMPONENTS } from "../../services/tabs";
import ISSUE_ACTIONS from "../../actions/appeal/appeal";
import { removeReadMessages } from "../../actions/notification/notification";
import useMessages from "../../hooks/messages.hook";
import useTextArea from "../../hooks/texarea.hook";
import { NOTIFICATIONS_TYPES } from "../../services/notifications/types";
import useRBAC, { UI_PERMISSIONS } from "../../services/rbac";
import UserName from "../../components/UserName";
// import PATHS from "../../paths";

import "./style.scss";

function Messanger(props) {
  const { componentProps, setComponentProps, setTabError } = props;

  const dispatch = useDispatch();

  // State
  const [dialoguesFetching, setDialoguesFetching] = useState(false);

  // Store
  const newMessages = useSelector((store) => store.notifications.newMessages);
  const newNotifications = useSelector((store) => store.notifications.newNotifications);
  const operatorID = useSelector((store) => store.user.id);

  // Hooks
  const { clickHandler } = useTabs();
  const { checkPermissionToRenderUI } = useRBAC();

  const {
    selectedDialogue,
    dialogues,
    getDialogues,
    setDialogues,
    setSelectedDialogue,
    setDialogueIrrelevant,
    getDialoguesArray,
    sortDialoguesArray,
    getMessages,
    incomingMessageHandling,
    sendMessage,
    inputRef,
    messagesListRef,
    scrollToBottomMessagesList,
    setDialogueLoadingMessages,
    setDialogueSending,
    resetDialogueError,
    setDialogueError,
    onChangeInputText,
    addFile,
    removeFile,
    setDialogueRead,
    setDialogueInWork,
  } = useMessages({
    defaultDialogues: componentProps?.dialogues,
    defaultSelectedDialogue: componentProps?.selectedDialogue,
  });

  const { getInput, setFocus, getSelection, setSelection, putNewLine } = useTextArea();

  const userNameClickHandler = (userId) => {
    clickHandler({
      component: COMPONENTS.USER_CARD,
      params: { userId },
      locationState: {
        userId,
      },
    });
  };

  const renderUserName = (user) => {
    const { current_pp_name, first_name, last_name } = user;
    const name = `${last_name} ${first_name}`.trim();
    const onClick = () => userNameClickHandler(user.id);

    return current_pp_name ? (
      <UserName.WithPickupPoint
        pickupPoint={current_pp_name}
        name={name}
        onClick={onClick}
      />
    ) : (
      <UserName.Link name={name} onClick={onClick} />
    );
  };

  /**
   * Форматируем структуру данных диалогов и сортируем их по актуальности
   * @param {object} dialogues - хэш-таблица диалогов
   */
  function getDialoguesArrayForRender(dialogues) {
    const dialoguesArray = getDialoguesArray(dialogues);
    const { relevant, irrelevant } = getDialoguesByRelevance(dialoguesArray);

    return [...sortDialoguesArray(relevant), ...sortDialoguesArray(irrelevant)];
  }

  /**
   * Возвращает актуальные и неактуальные диалоги
   * @param {Array} dialoguesArray - массив диалогов
   */
  function getDialoguesByRelevance(dialoguesArray) {
    return {
      relevant: dialoguesArray.filter((dialogue) => !dialogue.irrelevant),
      irrelevant: dialoguesArray.filter((dialogue) => dialogue.irrelevant),
    };
  }

  async function onSelectDialogue(dialogue) {
    const { id, limit } = dialogue;
    const input = getInput(inputRef);

    setSelectedDialogue(id);
    // historyPush(`${PATHS.MESSAGES}/${id}`);

    if (input) {
      setFocus(input);
    }

    // Получаем сообщения, если их меньше лимита
    if (dialogue.messages.length < limit) {
      await onGetMessages(dialogue);
    }

    setDialogueRead(id);
    scrollToBottomMessagesList(messagesListRef, { behavior: "auto" });
    dispatch(removeReadMessages(id));
  }

  /**
   * Получаем сообщения
   * @param {object} dialogue - целевой диалог, для которого получаем сообщения
   */
  async function onGetMessages(dialogue) {
    const { id, limit, offset } = dialogue;

    await setDialogueLoadingMessages(id, true);

    try {
      const messages = await getMessages(id, limit, offset);

      setDialogues((dialogues) => ({
        ...dialogues,
        [id]: {
          ...dialogue,
          messages: [...messages, ...dialogue.messages],
          offset: messages.length + offset,
          loadMore: messages.length === limit,
        },
      }));
    } catch (err) {
      setDialogueError(id, err);
    }

    await setDialogueLoadingMessages(id, false);
  }

  async function onSendMessage(dialogue) {
    const { id } = dialogue;
    const input = getInput(inputRef);

    await setDialogueSending(id, true);

    try {
      const response = await sendMessage(dialogue);

      await setDialogues((dialogues) => ({
        ...dialogues,
        [id]: {
          ...dialogue,
          ...response,
        },
      }));

      await scrollToBottomMessagesList(messagesListRef, { behavior: "smooth" });
    } catch (err) {
      setDialogues((dialogues) => ({
        ...dialogues,
        [id]: { ...err },
      }));
    }

    await setDialogueSending(id, false);
    if (input) setFocus(input);
  }

  async function onPutNewLine(dialogueId, input) {
    const selection = getSelection(input);

    const newValue = putNewLine(input.value, selection);
    const newSelection = {
      start: selection.start + 1,
      end: selection.start + 1,
    };

    await onChangeInputText(dialogueId, newValue);
    setSelection(input, newSelection);
  }

  function onInputPressEnter(dialogue, event) {
    if (event.ctrlKey) {
      onPutNewLine(dialogue.id, event.target);
    } else if (!event.ctrlKey) {
      event.preventDefault();
      onSendMessage(dialogue);
    }
  }

  // function getInitialValues(componentProps, locationState) {
  //   if (componentProps !== null) {
  //     return componentProps
  //   } else if (locationState) {
  //     return {
  //       ...INITIAL_VALUES,
  //       userId: locationState.userId,
  //     }
  //   } else {
  //     return INITIAL_VALUES
  //   }
  // }

  function isNewNotificationAboutNewIssue(notification) {
    return (
      notification &&
      [
        NOTIFICATIONS_TYPES.EMPTY_ISSUE_CREATED,
        NOTIFICATIONS_TYPES.ISSUE_CREATED,
      ].includes(notification.type)
    );
  }

  function isNewIssueFromCurrentOperator(notification, currentOperatorId) {
    return notification.operator_id === currentOperatorId;
  }

  const selectedDialogueData = selectedDialogue && dialogues[selectedDialogue];

  async function lazyLoading(dialogue) {
    if (dialogue.loadMore) {
      await onGetMessages(dialogue);
    }
  }

  function createEmptyIssue(operator_id, user_id) {
    dispatch(ISSUE_ACTIONS.createEmptyIssue({ operator_id, user_id }));
  }

  function onDropFiles(dialogueId, fileList) {
    if (fileList.length > 0) {
      let i = 0;

      while (i < fileList.length) {
        addFile(dialogueId, fileList[i]);
        i = i + 1;
      }
    }
  }

  function onPaste(dialogueId, event) {
    const { files } = event.clipboardData;

    onDropFiles(dialogueId, files);
  }

  function drawTakeInWorkButton() {
    return checkPermissionToRenderUI(UI_PERMISSIONS["ui:messanger:take-to-work"]) ? (
      <Button
        className="messages__button_take-to-work"
        children="Взять в работу"
        size="large"
        type="primary"
        htmlType="button"
        disabled={selectedDialogueData.irrelevant}
        onClick={() => {
          setDialogueInWork(selectedDialogue, true);
          createEmptyIssue(operatorID, selectedDialogue);
        }}
      />
    ) : null;
  }

  function drawInput() {
    return (
      <div className="messages__input-wrapper">
        <div
          className={`attached-files ${
            selectedDialogueData.attachedFiles.length > 0 && "shown"
          }`}
        >
          {selectedDialogueData.attachedFiles.map((file) => (
            <AttachedFilePreview
              key={file.id}
              file={file.file}
              removeFile={() => removeFile(selectedDialogue, file.id)}
            />
          ))}
        </div>

        <SendingError
          message={selectedDialogueData.error}
          resetError={() => resetDialogueError(selectedDialogue)}
        />

        <div style={{ position: "relative" }}>
          <Upload
            multiple
            fileList={selectedDialogueData.attachedFiles}
            showUploadList={false}
            beforeUpload={(file) => addFile(selectedDialogue, file)}
            customRequest={() => {}}
            className="paper-clip"
          >
            <Button
              htmlType="button"
              type="text"
              size="large"
              icon={<PaperClipOutlined />}
            />
          </Upload>

          <Input.TextArea
            className="messages__input"
            placeholder="Напишите что-нибудь и нажмите ENTER, чтобы отправить..."
            autoFocus
            autoSize={{ minRows: 1, maxRows: 4 }}
            ref={inputRef}
            disabled={selectedDialogueData.sending}
            value={selectedDialogueData.inputtedText}
            onChange={(e) => onChangeInputText(selectedDialogue, e.target.value)}
            onPaste={(event) => onPaste(selectedDialogue, event)}
            onPressEnter={(event) =>
              onInputPressEnter({ ...selectedDialogueData, id: selectedDialogue }, event)
            }
          />
        </div>
      </div>
    );
  }

  // componentDidMount
  useEffect(() => {
    (async () => {
      await setDialoguesFetching(true);

      try {
        const dialogues = await getDialogues(
          operatorID,
          componentProps?.selectedDialogue,
          componentProps?.dialogues
        );

        setDialogues(dialogues);
        scrollToBottomMessagesList(messagesListRef, { behavior: "auto" });
      } catch (err) {
        setTabError(err);
      }

      await setDialoguesFetching(false);
    })();
  }, []);
  // componentWillUnmount
  useEffect(
    () => () => setComponentProps({ dialogues, selectedDialogue }),
    [dialogues, selectedDialogue]
  );
  // componentDidUpdate
  useEffect(() => {
    (async () => {
      const newMessage = newMessages[newMessages.length - 1];

      if (newMessage) {
        await incomingMessageHandling(newMessage, dialogues, selectedDialogue);
      }
    })();
  }, [newMessages]);

  // Эффект, когда получаем новое уведомление
  useEffect(() => {
    const newNotification = newNotifications[newNotifications.length - 1];

    if (
      isNewNotificationAboutNewIssue(newNotification) &&
      !isNewIssueFromCurrentOperator(newNotification, operatorID)
    ) {
      setDialogueIrrelevant(newNotification.user_id);
    }
  }, [newNotifications]);

  return (
    <div className="page_messages">
      <DialogsList
        dialogs={getDialoguesArrayForRender(dialogues)}
        selectDialogue={selectedDialogue}
        loading={dialoguesFetching}
        dialoguesFetching={dialoguesFetching}
        onSelect={onSelectDialogue}
      />
      {!selectedDialogueData && (
        <div className="information_select-dialog">Выберите чат чтобы начать диалог</div>
      )}

      {selectedDialogueData && (
        <div className="messages-wrapper">
          <div className="messages__user-info">
            {renderUserName(selectedDialogueData.user)}
          </div>

          <MessagesList
            messages={selectedDialogueData.messages}
            loading={selectedDialogueData.fetching}
            currentOperatorId={operatorID}
            messagesListRef={messagesListRef}
            scrollToBottom={() =>
              scrollToBottomMessagesList(messagesListRef, {
                behavior: "smooth",
              })
            }
            onDropFiles={
              selectedDialogueData.inWork
                ? (fileList) => onDropFiles(selectedDialogue, fileList)
                : () => {}
            }
            onFetch={() => lazyLoading({ ...selectedDialogueData, id: selectedDialogue })}
          />

          {
            /*
              Если у пользователя есть открытое обращение,
              то рисуем текстовый инпут,
              иначе рисуем кнопку "Взять в работу"
            */
            selectedDialogueData.inWork ? drawInput() : drawTakeInWorkButton()
          }
        </div>
      )}
    </div>
  );
}

export default Messanger;
