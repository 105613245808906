import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import appReducer from "./app";
import { userReducer } from "./user";
// import { taskReducer } from "./task";
import { errorReducer } from "./errors";
import { notificationsReducer } from "./notifications";
import { messagesReducer } from "./messages";
import tabsReducer from "./tabs";

export const rootReducer = (history) => {
  return combineReducers({
    router: connectRouter(history),
    app: appReducer,
    user: userReducer,
    // tasks: taskReducer,
    error: errorReducer,
    notifications: notificationsReducer,
    messages: messagesReducer,
    tabs: tabsReducer,
  });
};
