import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Tabs, message } from "antd";

import RolesList from "./roles";
import GroupsList from "./groups";
import Locations from "./locations";
import OperatorsList from "./operators";
import Ldap from "./ldap";
import Hardware from "./hardware";
import Templates from "./templates";
import ServiceAccounts from "./serviceAccounts";
import MainContainer from "../../components/MainContainer";

import RBAC_API from "../../api/rbac";
import OPERATORS_API from "../../api/operators";
import LOCATIONS_API from "../../api/locations";
import { useTabs, COMPONENTS } from "../../services/tabs";
import useRBAC, { UI_PERMISSIONS } from "../../services/rbac";
import errorHandling, {
  ERRORS_DICT,
} from "../../services/errors/errorHandling";

import "./style.scss";

/**
 * @enum Вкладки
 */
export const TABS = {
  roles: "roles",
  groups: "groups",
  operators: "operators",
  locations: "locations",
  ldap: "ldap",
  hardware: "hardware",
  templates: "templates",
  serviceAccounts: "serviceAccounts",
};

/**
 * @enum Названия вкладок
 */
const TAB_NAMES = {
  [TABS.roles]: "Роли",
  [TABS.groups]: "Группы",
  [TABS.locations]: "Локации",
  [TABS.operators]: "Операторы",
  [TABS.ldap]: "LDAP",
  [TABS.hardware]: "Оборудование",
  [TABS.templates]: "Шаблоны",
  [TABS.serviceAccounts]: "Сервисные аккаунты",
};

function OperatorManagmentPage(props) {
  const { componentProps, setComponentProps, tabUuid } = props;

  const dispatch = useDispatch();

  // State
  const [operators, setOperators] = useState([]);
  const [groups, setGroups] = useState([]);
  const [roles, setRoles] = useState([]);
  // const [rolesTree, setRolesTree] = useState(null);
  const [locations, setLocations] = useState([]);
  const [ldapProps, setLdapProps] = useState([]);
  const [loading, setLoading] = useState(false);

  // Hooks
  const { clickHandler } = useTabs();
  const { checkPermissionToRenderUI } = useRBAC();

  function setActiveTab(activeTab) {
    setComponentProps({ activeTab });
  }

  // Группы
  function openGroupCard(e, group) {
    e.preventDefault();
    e.stopPropagation();

    clickHandler({
      component: COMPONENTS.GROUP_CARD,
      params: { groupId: group.id },
      locationState: { groupId: group.id },
    });
  }

  async function deleteGroup(groupId) {
    await OPERATORS_API.deleteOperatorsGroup(groupId)
      .then(() => {
        message.success("Группа удалена");
        getGroupsList();
      })
      .catch((err) => {
        console.error(err);
        if (err.response) {
          err.response.data.message = `При удалении группы произошла ошибка\n${err.response.data.message}`;
        }
        dispatch(errorHandling(err, ERRORS_DICT.GROUP_DELETING_ERROR));
      });
  }

  async function getOperatorsList() {
    await setLoading(true);

    await OPERATORS_API.getOperatorsList()
      .then((resp) => {
        if (resp.status === 200) {
          setOperators(
            resp.data.map((operator) => ({
              ...operator,
              full_name: `${operator.last_name} ${operator.first_name}`,
            }))
          );
        }
      })
      .catch((err) => {
        console.error(err);
      });

    await setLoading(false);
  }

  /**
   * @param {object} operator
   */
  function openOperatorCard(e, operator) {
    e.preventDefault();
    e.stopPropagation();

    clickHandler({
      component: COMPONENTS.OPERATOR_CARD,
      params: { operatorId: operator.id },
      locationState: { operatorId: operator.id },
    });
  }

  async function getGroupsList() {
    await setLoading(true);

    await OPERATORS_API.getOperatorsGroupsList()
      .then((resp) => {
        setGroups(resp.data);
      })
      .catch((err) => console.error());

    await setLoading(false);
  }

  // Роли
  async function getRoles() {
    await setLoading(true);

    await RBAC_API.getRolesList().then((resp) => setRoles(resp.data));

    await setLoading(false);
  }

  function openRoleCard(e, role) {
    e.preventDefault();
    e.stopPropagation();

    clickHandler({
      component: COMPONENTS.ROLE_CARD,
      params: { roleId: role.id },
      locationState: { roleId: role.id },
    });
  }

  // Локации
  async function getLocations() {
    await setLoading(true);

    await LOCATIONS_API.getLocations().then((resp) => {
      const locations = resp.data.map((location) => {
        if (location.parent_id) {
          const parent = resp.data.find((l) => l.id === location.parent_id);

          return { ...location, parentTitle: parent && parent.title };
        } else {
          return location;
        }
      });

      setLocations(locations);
    });

    await setLoading(false);
  }

  function openLocationCard(e, location) {
    e.preventDefault();
    e.stopPropagation();

    clickHandler({
      component: COMPONENTS.LOCATION_CARD,
      params: { locationId: location.id },
      locationState: { locationId: location.id },
    });
  }

  async function deleteLocation(locationId) {
    await LOCATIONS_API.deleteLocation(locationId)
      .then(() => {
        getLocations();
        message.success("Локация удалена");
      })
      .catch((err) => {
        console.error(err);
        if (err.response) {
          err.response.data.message = `При удалении локации произошла ошибка\n${err.response.data.message}`;
        }
        dispatch(errorHandling(err, ERRORS_DICT.LOCATION_DELETE_ERROR));
      });
  }

  // LDAP
  async function getOperatorLdapProps() {
    await setLoading(true);

    await OPERATORS_API.getOperatorLdapProps().then((resp) =>
      setLdapProps(resp.data)
    );

    await setLoading(false);
  }

  function openLdapPropsCard(e, ldapProp) {
    e.preventDefault();
    e.stopPropagation();

    clickHandler({
      component: COMPONENTS.LDAP_PROP_CARD,
      params: { ldapPropId: ldapProp.ldap_group },
      locationState: { ldapProp },
    });
  }

  function onTabsChange(tab) {
    setActiveTab(tab);
    getTabData(tab);
  }

  async function getTabData(tab) {
    switch (tab) {
      case TABS.roles:
        return await getRoles();
      case TABS.groups:
      case TABS.operators: {
        await getGroupsList();
        await getOperatorsList();
        return;
      }
      case TABS.locations:
        return await getLocations();
      case TABS.ldap:
        return await getOperatorLdapProps();
      default:
        break;
    }
  }

  useEffect(() => {
    const initialState = {
      activeTab: TABS.operators,
    };

    if (componentProps === null) {
      setComponentProps({ ...initialState });
      getTabData(initialState.activeTab);
    } else {
      getTabData(componentProps.activeTab);
    }
  }, [tabUuid]);

  if (componentProps === null) return null;
  return (
    <MainContainer
      title="Управление операторами, группами и ролями"
      className="operator-management-page"
      bodyClassName="operator-management-page__body"
    >
      <Tabs activeKey={componentProps.activeTab} onChange={onTabsChange}>
        {checkPermissionToRenderUI(
          UI_PERMISSIONS["ui:management:operators:list"]
        ) && (
          <Tabs.TabPane tab={TAB_NAMES[TABS.operators]} key={TABS.operators}>
            <OperatorsList
              loading={loading}
              groups={groups}
              operators={operators}
              onRowClick={openOperatorCard}
            />
          </Tabs.TabPane>
        )}
        {checkPermissionToRenderUI(
          UI_PERMISSIONS["ui:management:operators-groups:list"]
        ) && (
          <Tabs.TabPane tab={TAB_NAMES[TABS.groups]} key={TABS.groups}>
            <GroupsList
              loading={loading}
              groups={groups}
              onRowClick={openGroupCard}
              onDelete={deleteGroup}
            />
          </Tabs.TabPane>
        )}
        {checkPermissionToRenderUI(
          UI_PERMISSIONS["ui:management:roles:list"]
        ) && (
          <Tabs.TabPane tab={TAB_NAMES[TABS.roles]} key={TABS.roles}>
            <RolesList
              loading={loading}
              roles={roles}
              // rolesTree={rolesTree}
              rolesTree={null}
              onRowClick={openRoleCard}
            />
          </Tabs.TabPane>
        )}
        {checkPermissionToRenderUI(
          UI_PERMISSIONS["ui:management:locations:list"]
        ) && (
          <Tabs.TabPane tab={TAB_NAMES[TABS.locations]} key={TABS.locations}>
            <Locations
              loading={loading}
              locations={locations}
              onRowClick={openLocationCard}
              onLocationDelete={deleteLocation}
            />
          </Tabs.TabPane>
        )}
        {checkPermissionToRenderUI(
          UI_PERMISSIONS["ui:management:ldap-props:list"]
        ) && (
          <Tabs.TabPane tab={TAB_NAMES[TABS.ldap]} key={TABS.ldap}>
            <Ldap
              loading={loading}
              ldapProps={ldapProps}
              onRowClick={openLdapPropsCard}
            />
          </Tabs.TabPane>
        )}
        {checkPermissionToRenderUI(
          UI_PERMISSIONS["ui:management:hardware"]
        ) && (
          <Tabs.TabPane tab={TAB_NAMES[TABS.hardware]} key={TABS.hardware}>
            <Hardware />
          </Tabs.TabPane>
        )}

        {checkPermissionToRenderUI(
          UI_PERMISSIONS["ui:management:templates"]
        ) && (
          <Tabs.TabPane tab={TAB_NAMES[TABS.templates]} key={TABS.templates}>
            <Templates />
          </Tabs.TabPane>
        )}

        {checkPermissionToRenderUI(
          UI_PERMISSIONS["ui:management:service-account"]
        ) && (
          <Tabs.TabPane
            tab={TAB_NAMES[TABS.serviceAccounts]}
            key={TABS.serviceAccounts}
          >
            <ServiceAccounts />
          </Tabs.TabPane>
        )}
      </Tabs>
    </MainContainer>
  );
}

export default OperatorManagmentPage;
