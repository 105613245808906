import React from "react";
import { Button, Checkbox, Radio } from "antd";
import { FileOutlined, LoadingOutlined, CloseOutlined } from "@ant-design/icons";

import normalizeDate from "../../utils/normalizeDate";

import "./style.scss";

const IMAGE_MIME_TYPES = [
  "image/gif",
  "image/jpeg",
  "image/png",
  "image/svg+xml",
  "image/webp",
];
const VIDEO_MIME_TYPES = ["video/mp4", "video/ogg", "video/webm"];
const PDF_MIME_TYPE = "application/pdf";
const SELECTION_TYPES = {
  radio: "radio",
  checkbox: "checkbox",
};

const FileSelection = (props) => {
  const {
    id,
    type = SELECTION_TYPES.checkbox,
    selected = [],
    onChange = () => {},
  } = props;

  const isSelected = selected.includes(id);

  function onClick() {
    switch (type) {
      case SELECTION_TYPES.radio: {
        onChange([id]);
        break;
      }
      case SELECTION_TYPES.checkbox: {
        if (isSelected) {
          onChange(selected.filter((fileId) => fileId !== id));
        } else {
          onChange([...selected, id]);
        }
        break;
      }
      default:
        return () => {};
    }
  }

  switch (type) {
    case SELECTION_TYPES.radio:
      return (
        <Radio
          checked={isSelected}
          onChange={onClick}
          className="file-preview__file-selection"
        />
      );
    case SELECTION_TYPES.checkbox:
      return (
        <Checkbox
          checked={isSelected}
          onChange={onClick}
          className="file-preview__file-selection"
        />
      );
    default:
      return null;
  }
};

const LoadingError = ({ retry }) => {
  return (
    <div className="file-preview_loading-error">
      <FileOutlined />
      <div className="error__text">
        <span>Ошибка</span>
        {retry && <Button type="link" children="Повторить" onClick={retry} />}
      </div>
    </div>
  );
};

const PreviewLoading = () => {
  return (
    <div className="file-preview_loading">
      <LoadingOutlined />
    </div>
  );
};

const Image = ({
  img,
  id,
  name,
  operator,
  created_at,
  selection,
  deletable,
  onOpen,
  onDelete,
}) => {
  return (
    <div className="file-preview file-preview__image">
      <img src={img} alt={name} onClick={() => onOpen(id)} />
      {deletable && (
        <Button
          className="file-preview__button_delete"
          icon={<CloseOutlined />}
          onClick={() => onDelete(id)}
          title="Удалить"
        />
      )}
      {selection !== null && <FileSelection {...selection} id={id} />}
      <div className="file-preview__veil" onClick={() => onOpen(id)}>
        <div className="veil__operator">{operator}</div>
        <div className="veil__created-at">{normalizeDate(created_at)}</div>
      </div>
    </div>
  );
};

const Video = ({
  id,
  type,
  operator,
  created_at,
  deletable,
  selection,
  onOpen,
  onDelete,
}) => {
  return (
    <div className="file-preview file-preview__video">
      <FileOutlined onClick={() => onOpen(id)} />
      {deletable && (
        <Button
          className="file-preview__button_delete"
          icon={<CloseOutlined />}
          onClick={() => onDelete(id)}
          title="Удалить"
        />
      )}
      <span className="file-preview file-preview__type">{type}</span>

      {selection !== null && <FileSelection {...selection} id={id} />}

      <div className="file-preview__veil" onClick={() => onOpen(id)}>
        <div className="veil__operator">{operator}</div>
        <div className="veil__created-at">{normalizeDate(created_at)}</div>
      </div>
    </div>
  );
};

const PDF = ({
  id,
  operator,
  created_at,
  deletable,
  selection,
  onDownload,
  onDelete,
}) => {
  return (
    <div className="file-preview file-preview__pdf">
      <FileOutlined onClick={() => onDownload(id)} />
      {deletable && (
        <Button
          className="file-preview__button_delete"
          icon={<CloseOutlined />}
          onClick={() => onDelete(id)}
          title="Удалить"
        />
      )}
      <span className="file-preview__type">pdf</span>
      {selection !== null && <FileSelection {...selection} id={id} />}

      <div className="file-preview__veil">
        <div className="veil__operator">{operator}</div>
        <div className="veil__created-at">{normalizeDate(created_at)}</div>
      </div>
    </div>
  );
};

const OtherFile = ({
  type,
  id,
  operator,
  created_at,
  deletable,
  selection,
  onDownload,
  onDelete,
}) => {
  return (
    <div className="file-preview file-preview__other">
      <FileOutlined onClick={() => onDownload(id)} />
      {deletable && (
        <Button
          className="file-preview__button_delete"
          icon={<CloseOutlined />}
          onClick={() => onDelete(id)}
          title="Удалить"
        />
      )}
      <span className="file-preview__type">{type}</span>
      {selection !== null && <FileSelection {...selection} id={id} />}

      <div className="file-preview__veil">
        <div className="veil__operator">{operator}</div>
        <div className="veil__created-at">{normalizeDate(created_at)}</div>
      </div>
    </div>
  );
};

const NoFile = () => {
  return <div className="file-preview file-preview__no-file">Нет файла</div>;
};

const FilePreview = (props) => {
  const {
    selection = null,
    loading = false,
    deletable,
    id,
    preview,
    name,
    type,
    style = {},
    fail,
    retry,
    operator,
    created_at,
    onOpen,
    onDelete,
    onDownload,
  } = props;

  function getFilenameExtension(filename) {
    return /[.]/.exec(filename) ? /[^.]+$/.exec(filename) : "file";
  }

  function getPreveiwLayoutByType() {
    if (!type) return <NoFile />;
    if (IMAGE_MIME_TYPES.includes(type)) {
      return (
        <Image
          id={id}
          name={name}
          img={preview}
          operator={operator}
          created_at={created_at}
          selection={selection}
          deletable={deletable}
          type={getFilenameExtension(name)}
          onOpen={onOpen}
          onDelete={onDelete}
        />
      );
    }
    if (VIDEO_MIME_TYPES.includes(type)) {
      return (
        <Video
          id={id}
          name={name}
          operator={operator}
          created_at={created_at}
          selection={selection}
          deletable={deletable}
          type={getFilenameExtension(name)}
          onOpen={onOpen}
          onDelete={onDelete}
        />
      );
    }
    if (PDF_MIME_TYPE === type)
      return (
        <PDF
          id={id}
          operator={operator}
          selection={selection}
          created_at={created_at}
          deletable={deletable}
          onDownload={onDownload}
          onDelete={onDelete}
        />
      );

    return (
      <OtherFile
        id={id}
        name={name}
        operator={operator}
        created_at={created_at}
        selection={selection}
        deletable={deletable}
        type={getFilenameExtension(name)}
        onDownload={onDownload}
        onDelete={onDelete}
      />
    );
  }

  function drawFilePreview() {
    if (loading) return <PreviewLoading />;
    if (fail) return <LoadingError retry={retry} />;

    return getPreveiwLayoutByType();
  }

  return (
    <div className="component_file-preview" style={style}>
      {drawFilePreview()}
    </div>
  );
};

export default FilePreview;
