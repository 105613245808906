import React from "react";

import FilesViewer from "../FilesViewer";
import FileUploader from "../FilesUploader";

const FilesSection = (props) => {
  const { files, upload, uploadingFiles, onChange } = props;

  function drawFiles() {
    if (!files || files.length === 0) return null;

    return (
      <>
        <h3 className="section__header">Приложенные файлы</h3>
        <FilesViewer files={files} />
      </>
    );
  }

  function drawUploadingButton() {
    if (!upload) return null;

    return (
      <>
        <h3 className="section__header">Добавить файлы</h3>
        <FileUploader files={uploadingFiles} onChange={onChange} />
      </>
    );
  }

  function drawSection() {
    if ((!files || files.length === 0) && !upload) return null;

    return (
      <section className="card__section card__section_files">
        {drawFiles()}
        {drawUploadingButton()}
      </section>
    );
  }

  return drawSection();
};

export default FilesSection;
