import React from "react";
import { Button } from "antd";
import { CheckOutlined } from "@ant-design/icons";

import { useTabs, COMPONENTS } from "../../services/tabs";
import useRBAC, { UI_PERMISSIONS } from "../../services/rbac";

const Header = (props) => {
  const {
    issue,
    loading,
    submitting,
    closed,
    hasChanges,
    onSave,
    onDone,
    onSaveAndClose,
    setAddToMassProblemVisible,
  } = props;

  const { clickHandler } = useTabs();
  const { checkPermissionToRenderUI } = useRBAC();

  function drawActionButtons() {
    if (closed) return null;

    return (
      <>
        {checkPermissionToRenderUI(UI_PERMISSIONS["ui:issues:card:create-task"]) && (
          <Button
            className="header__button header__button_create-task"
            type="link"
            size="small"
            children="Создать задачу"
            disabled={loading || submitting}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              clickHandler({
                component: COMPONENTS.TASK_CREATION_FORM,
                params: {
                  userId: issue.user_id,
                },
                locationState: {
                  userID: issue.user_id,
                  issueID: issue.id,
                  operatorID: null,
                  description: issue.description,
                },
              });
            }}
          />
        )}

        {checkPermissionToRenderUI(
          UI_PERMISSIONS["ui:issues:card:add-to-mass-problem"]
        ) && (
          <Button
            type="link"
            size="small"
            className="header__button"
            title="Добавить обращение к массовой проблеме"
            children="Добавить к МП"
            onClick={() => setAddToMassProblemVisible(true)}
          />
        )}

        {checkPermissionToRenderUI(UI_PERMISSIONS["ui:issues:card:save"]) && (
          <Button
            className="header__button header__button_save"
            size="small"
            type="default"
            children="Сохранить"
            loading={submitting}
            disabled={loading || !hasChanges}
            onClick={onSave}
          />
        )}

        {checkPermissionToRenderUI(UI_PERMISSIONS["ui:issues:card:save-and-close"]) && (
          <Button
            size="small"
            type="primary"
            children="Сохранить и закрыть"
            loading={submitting}
            disabled={loading || !hasChanges}
            onClick={onSaveAndClose}
          />
        )}

        {checkPermissionToRenderUI(UI_PERMISSIONS["ui:issues:card:done"]) && (
          <Button
            className="header__button header__button_done"
            size="small"
            children="Выполнено"
            disabled={loading || submitting}
            onClick={onDone}
            icon={<CheckOutlined />}
          />
        )}
      </>
    );
  }

  return (
    <>
      <h2 className="header__title">{`Обращение #${issue.human_readable_id}`}</h2>
      {drawActionButtons()}
    </>
  );
};

export default Header;
