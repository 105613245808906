import React from "react";
import { Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import "./style.scss";

const Tab = (props) => {
  const { title = "", isActive = false, onSetActive, onClose } = props;

  function onCloseTab(e) {
    e.stopPropagation();
    onClose();
  }

  function getClassNames(isActive) {
    return `component_tab ${isActive ? "tab_active" : "tab_inactive"}`;
  }

  return (
    <div
      className={getClassNames(isActive)}
      title={title}
      onClick={onSetActive}
    >
      <span className="tab__title">{title}</span>
      <Button
        className="tab__button tab__button_close"
        type="text"
        size="small"
        icon={<CloseOutlined />}
        onClick={onCloseTab}
      />
    </div>
  );
};

export default Tab;
