import { useCallback } from "react";
import { message } from "antd";
import isEqual from "lodash.isequal";

import OPERATORS_API from "../api/operators";

const useOperatorCustomFilters = () => {
  const getCustomFilters = useCallback(async (realm) => {
    try {
      const response = await OPERATORS_API.getCustomFilters(realm);

      return response.data.map((filter) => ({
        label: filter.name,
        value: filter.id,
        filter: filter.filter,
      }));
    } catch (err) {
      console.error(err);
      message.error("Не удалось пролучить пользовательские фильтры");
      return undefined;
    }
  }, []);

  /**
   * Создание нового кастомного фильтра
   * @param {string} realm - Область применения фильтра
   * @param {string} name - Название фильтра
   * @param {string} filter - Значения фильтра
   */
  const createFilter = async (realm, name, filter) => {
    try {
      const response = await OPERATORS_API.postCustomFilter(
        realm,
        name,
        filter
      );
      message.success("Сохранено");

      return {
        label: response.data.name,
        value: response.data.id,
        filter: response.data.filter,
      };
    } catch (err) {
      console.error(err);
      message.error("Не удалось сохранить фильтр");

      return undefined;
    }
  };

  /**
   * Изменение кастомного фильтра
   * @param {string} filterId - id фильтра
   * @param {string} name - Название фильтра
   * @param {string} filter - Значения фильтра
   * @returns
   */
  const editFilter = async (filterId, name, filter) => {
    try {
      const response = await OPERATORS_API.putCustomFilter(
        filterId,
        name,
        filter
      );

      message.success("Сохранено");

      return {
        label: response.data.name,
        value: response.data.id,
        filter: response.data.filter,
      };
    } catch (err) {
      console.error(err);
      message.error("Не удалось сохранить изменения");

      return undefined;
    }
  };

  /**
   * Удаление кастомного фильтра
   * @param {string} filterId - id фильтра
   */
  const deleteFilter = async (filterId) => {
    try {
      await OPERATORS_API.deleteCustomFilter(filterId);
      message.success("Фильтр удален");
    } catch (err) {
      console.error(err);
      message.error("Не удалось удалить фильтр");
    }
  };

  /**
   * Проверяем был ли изменен кастомный фильтр
   * @param {object} selectedFilter - Значения выбранного фильтра
   * @param {object} currentFilterValues - Текущие значения фильтра
   */
  const filterHasChanges = useCallback(
    (selectedFilterValues, currentFilterValues) => {
      return !isEqual(selectedFilterValues, currentFilterValues);
    },
    []
  );

  return {
    getCustomFilters,
    createFilter,
    editFilter,
    deleteFilter,
    filterHasChanges,
  };
};

export default useOperatorCustomFilters;
