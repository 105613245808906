import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Tree, message, Button } from "antd";
import { useLocation } from "react-router";
import { isEqual } from "lodash";
import { BsArrowsCollapse, BsArrowsExpand } from "react-icons/bs";

import MainContainer from "../../components/MainContainer";

import RBAC_API from "../../api/rbac";
import errorHandling from "../../services/errors/errorHandling";
import { RBAC_ROLE_GRAPH_GETTING_ERROR } from "../../actions/errors/actionTypes";
import useRBAC, { UI_PERMISSIONS } from "../../services/rbac";

import "./style.scss";

const Header = (props) => {
  const { hasChanges, onSave } = props;

  return (
    <>
      <h2 className="header__title">Информация о роли</h2>
      <Button
        type="primary"
        children="Сохранить"
        className="header__button header__button_save"
        disabled={!hasChanges}
        onClick={onSave}
      />
    </>
  );
};

const RoleCard = (props) => {
  const { tabUuid, error, setTabError } = props;
  const dispatch = useDispatch();
  const location = useLocation();

  const roleName = location.state
    ? location.state.roleId
    : getRoleNameFromUrl();

  // State
  const [role, setRole] = useState({});
  const [loading, setLoading] = useState(false);
  const [rolesList, setRoles] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [defaultRoleParents, setDefaultRoleParents] = useState([]);
  const [defaultRolePermissions, setDefaultRolePermissions] = useState([]);
  const [hasChanges, setHasChanges] = useState(false);

  const [selectedRoleParents, selectParents] = useState([]);
  const [expandedRoles, setExpandedRoles] = useState([]);
  const [selectedRolePermissions, selectPermissions] = useState([]);
  const [expandedPermissions, setExpandedPermissions] = useState([]);

  // Hooks
  const { checkPermissionToRenderUI } = useRBAC();

  function sortArray(arr = [], filterKey = "") {
    return arr.sort((a, b) => {
      if (a[filterKey] < b[filterKey]) return -1;
      if (a[filterKey] > b[filterKey]) return 1;
      return 0;
    });
  }

  function getRoleNameFromUrl() {
    return window.location.pathname.split("/")[3];
  }

  function treePermissionFormatting(data = []) {
    return data.map((permission, nodeNum) => ({
      title: permission.title,
      key: nodeNum,
      children: sortArray(
        permission.permissions.map((permission) => ({
          title: (
            <p style={{ margin: "unset" }}>
              <span>{permission.description}</span>
              <span
                style={{
                  color: "#8E8E8E",
                  display: "inline-block",
                  marginLeft: "12px",
                }}
              >
                {permission.id}
              </span>
            </p>
          ),
          key: permission.id,
        })),
        "key"
      ),
    }));
  }

  function treeRoleFormatting(data = []) {
    return data.map((role, nodeNum) => ({
      title: role.id,
      value: role.id,
      key: nodeNum,
      parents: role.parents,
      children: role.permissions.map((permission, leafNum) => ({
        title: permission,
        value: permission,
        key: `${nodeNum}.${leafNum}`,
        checkable: false,
      })),
    }));
  }

  async function getPermissions() {
    await setLoading(true);

    await RBAC_API.getPermissionsList()
      .then((resp) => {
        setPermissions(treePermissionFormatting(resp.data));
        setTabError(null);
      })
      .catch((err) => {
        console.error(err);

        setTabError({
          title: "Ошибка получения разрешений",
          message: err.response.data?.message,
          status: err.response.status,
          onRetry: getPermissions,
        });
      });

    await setLoading(false);
  }

  async function getRoles() {
    await setLoading(true);

    await RBAC_API.getRolesList()
      .then((resp) => {
        setRoles(treeRoleFormatting(resp.data));
        setTabError(null);
      })
      .catch((err) => {
        console.error(err);

        setTabError({
          title: "Ошибка получения ролей",
          message: err.response.data?.message,
          status: err.response.status,
          onRetry: getRoles,
        });
      });

    await setLoading(false);
  }

  function findRole(roles, roleName) {
    const role = roles.find((role) => role.value === roleName);

    if (role !== undefined) {
      // Получаем массив ключей родительских ролей
      const roleParents = rolesList
        .filter((r) => role.parents.includes(r.value))
        .map((r) => r.key);
      // Получаем массив ключей разрешений
      const rolePermissions = role.children.map((p) => p.value);

      setRole(role);
      setTabError(null);
      selectParents(roleParents);
      selectPermissions(rolePermissions);
      setDefaultRoleParents(roleParents);
      setDefaultRolePermissions(rolePermissions);
    } else {
      setTabError({
        title: "Роль не найдена",
        message: `Не удалось найти роль ${roleName}`,
      });
    }
  }

  function getRolesNames(rolesKeys) {
    return rolesKeys.map(
      (key) => rolesList.find((role) => role.key === key).value
    );
  }

  function getPermissionsNames(permissionsKeys) {
    return (
      permissionsKeys
        // Убираем "узловые" разрешения
        .filter((key) => !/^\d+$/gm.test(key))
    );
  }

  async function changeRoleParents() {
    const add = selectedRoleParents.filter(
      (roleKey) => !defaultRoleParents.includes(roleKey)
    );
    const remove = defaultRoleParents.filter(
      (role) => !selectedRoleParents.includes(role)
    );

    if (add.length > 0)
      await RBAC_API.postRoleParents(roleName, getRolesNames(add));
    if (remove.length > 0)
      await RBAC_API.deleteRoleParents(roleName, getRolesNames(remove));
  }

  async function changeRolePermissions() {
    const add = selectedRolePermissions.filter(
      (permission) => !defaultRolePermissions.includes(permission)
    );
    const remove = defaultRolePermissions.filter(
      (permission) => !selectedRolePermissions.includes(permission)
    );

    if (add.length > 0)
      await RBAC_API.postRolePermissions(roleName, getPermissionsNames(add));
    if (remove.length > 0)
      await RBAC_API.deleteRolePermissions(
        roleName,
        getPermissionsNames(remove)
      );
  }

  async function getRoleCardData() {
    try {
      await getRoles();
      await getPermissions();
    } catch (e) {
      console.error(e);
    }
  }

  async function saveChanges() {
    try {
      await changeRoleParents();
      await changeRolePermissions();

      await getRoleCardData();
      message.success("Сохранено");
    } catch (e) {
      console.error(e);
    }
  }

  function checkChanges() {
    const parentsEqual = isEqual(defaultRoleParents, selectedRoleParents);
    const permissionsEqual = isEqual(
      defaultRolePermissions,
      selectedRolePermissions
    );

    setHasChanges(!(parentsEqual && permissionsEqual));
  }

  async function getRoleSvgGraph(role, withPerms = false) {
    await RBAC_API.getSvgRolesTree([role], withPerms)
      .then((resp) => {
        const blob = new Blob([resp.data], { type: "image/svg+xml" });
        const url = URL.createObjectURL(blob);

        window.open(url);
      })
      .catch((err) =>
        dispatch(errorHandling(err, RBAC_ROLE_GRAPH_GETTING_ERROR))
      );
  }

  function expandAllRoles(roles) {
    setExpandedRoles(getNodesArray(roles));
  }

  function collapseAllRoles() {
    setExpandedRoles([]);
  }

  function expandAllPermissions(permissions) {
    setExpandedPermissions(getNodesArray(permissions));
  }

  function collapseAllPermissions() {
    setExpandedPermissions([]);
  }

  function getNodesArray(tree) {
    const keys = [];

    for (let key in tree) {
      const node = tree[key];

      keys.push(node.key);
    }

    return keys;
  }

  // componentDidMount
  useEffect(() => {
    getRoleCardData();
  }, [tabUuid]);

  // componentDidUpdate
  useEffect(() => {
    if (rolesList.length > 0 && permissions.length > 0) {
      findRole(rolesList, roleName);
    }
  }, [rolesList, permissions]);
  useEffect(() => {
    checkChanges();
  }, [selectedRoleParents, selectedRolePermissions]);

  return (
    <MainContainer
      className="page_role-card"
      headerClassName="page__header_role-card"
      bodyClassName="page__body_role-card"
      error={error}
      loading={loading}
      header={<Header onSave={saveChanges} hasChanges={hasChanges} />}
    >
      <h4 className="role-card__role-name">
        <span className="role-name__title">Название роли:</span>
        {role.title}
      </h4>

      {checkPermissionToRenderUI(
        UI_PERMISSIONS["ui:management:roles:card:role-graph"]
      ) && (
        <div className="role-card__graph-wrapper">
          <h5>Граф роли</h5>
          <Button
            htmlType="button"
            children="Показать только роли"
            onClick={() => getRoleSvgGraph(roleName)}
          />
          <Button
            htmlType="button"
            children="Показать роли с разрешениями"
            onClick={() => getRoleSvgGraph(roleName, true)}
          />
        </div>
      )}

      <div className="role-card__wrapper">
        <div className="role-card__role-parents">
          <h5>Родительские роли:</h5>
          <div className="role-card__button-wrapper">
            <Button
              type="default"
              onClick={collapseAllRoles}
              icon={<BsArrowsCollapse />}
              title="Свернуть всё"
              className="role-card__button"
            />
            <Button
              type="default"
              onClick={() => expandAllRoles(rolesList)}
              icon={<BsArrowsExpand />}
              title="Развернуть всё"
              className="role-card__button"
            />
          </div>
          <Tree
            checkable
            treeData={rolesList.filter((role) => role.value !== roleName)}
            checkedKeys={selectedRoleParents}
            expandedKeys={expandedRoles}
            disabled={
              !checkPermissionToRenderUI(
                UI_PERMISSIONS["ui:management:roles:card:parents:edit"]
              )
            }
            onCheck={selectParents}
            onExpand={setExpandedRoles}
          />
        </div>
        <div className="role-card__role-permissions">
          <h5>Разрешения:</h5>
          <div className="role-card__button-wrapper">
            <Button
              type="default"
              onClick={collapseAllPermissions}
              icon={<BsArrowsCollapse />}
              title="Свернуть всё"
              className="role-card__button"
            />
            <Button
              type="default"
              onClick={() => expandAllPermissions(permissions)}
              icon={<BsArrowsExpand />}
              title="Развернуть всё"
              className="role-card__button"
            />
          </div>
          <Tree
            checkable
            treeData={permissions}
            checkedKeys={selectedRolePermissions}
            expandedKeys={expandedPermissions}
            disabled={
              !checkPermissionToRenderUI(
                UI_PERMISSIONS["ui:management:roles:card:permissions:edit"]
              )
            }
            onCheck={selectPermissions}
            onExpand={setExpandedPermissions}
          />
        </div>
      </div>
    </MainContainer>
  );
};

export default RoleCard;
