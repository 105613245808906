import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Row, Col, Button, message, Space } from "antd";

import Search from "../Search_new";
import UserData from "../UserData";

import errorHandling from "../../services/errors/errorHandling";
import USER_API from "../../api/users";
import { useTabs, COMPONENTS } from "../../services/tabs";
import { TYPES } from "../Search_new/consts";
import useCopy from "../../hooks/useCopy";

const GUTTER = [20, 20];

const WithoutUser = (props) => {
  const { user, onChangeUser, addUser } = props;

  return (
    <>
      <h3 className="section__header">Без пользователя</h3>
      {typeof addUser === "function" && (
        <Row gutter={GUTTER} align="middle">
          <Col className="section__field" span={24}>
            <Space>
              <p className="field__title">Добавить пользователя:</p>
              <Search
                sought={user}
                updateParent={onChangeUser}
                placeholder="Выберите пользователя"
                types={[TYPES.user]}
              />
              {user && (
                <Button
                  type="primary"
                  children="Добавить"
                  onClick={() => addUser(user.id)}
                />
              )}
            </Space>
          </Col>
        </Row>
      )}{" "}
    </>
  );
};

const UserSection = (props) => {
  const dispatch = useDispatch();
  const {
    id,
    last_name,
    first_name,
    patronymic_name = "",
    internal_phones,
    workplace_location,
    department = "",
    phones,
    last_login_data,
    emails,
    pp_partner_pick_up_points,
    addUser,
    onUpdate = () => {},
    permissions,
    current_pp_name,
  } = props;
  const full_name = `${last_name} ${first_name} ${patronymic_name}`.trim();

  // State
  const [userPhoto, setUserPhoto] = useState(null);
  const [user, setUser] = useState(null); // Когда нет пользователя

  // Hooks
  const { copy } = useCopy();
  const { clickHandler } = useTabs();

  const onClickName = (e) => {
    e.preventDefault();
    e.stopPropagation();

    clickHandler({
      component: COMPONENTS.USER_CARD,
      params: { userId: id },
      locationState: { userId: id },
    });
  };

  const onCopyName = (value) => {
    copy(value);
    message.success("Скопировано");
  };

  function changeUserWorkplaceLocation(id, location) {
    USER_API.changeUserWorkplaceLocation(id, location)
      .then(() => {
        message.success("Обновлено");
        onUpdate();
      })
      .catch((err) => dispatch(errorHandling(err, "type")));
  }

  function changeUserWorkstationNumber(id, number) {
    USER_API.changeUserWorkstationNumber(id, number)
      .then(() => {
        message.success("Обновлено");
        onUpdate();
      })
      .catch((err) => dispatch(errorHandling(err, "type")));
  }

  // Получаем фото пользователя
  useEffect(() => {
    if (id) {
      (async () => {
        try {
          const response = await USER_API.getUserPhoto(id);
          const url = await URL.createObjectURL(response.data);

          setUserPhoto(url);
        } catch (err) {
          console.error(err);
        }
      })();
    }
  }, [id]);

  if (!id) {
    return <WithoutUser user={user} onChangeUser={setUser} addUser={addUser} />;
  } else {
    return (
      <section className="card__section card__section_user-info">
        <h3 className="section__header">Информация о пользователе</h3>

        <UserData
          user={{
            photo: userPhoto,
            emails,
            phones,
            internal_phones,
            current_pp_name,
            pp_partner_pick_up_points,
            workplace_location,
            last_login_data,
            department,
            full_name,
          }}
          permissions={permissions}
          onCopyName={() => onCopyName(full_name)}
          onClickName={onClickName}
          onChangeWorkplaceLocation={(location) =>
            changeUserWorkplaceLocation(id, location)
          }
          onChangeWorkstationNumber={(number) => changeUserWorkstationNumber(id, number)}
        />
      </section>
    );
  }
};

export default UserSection;
