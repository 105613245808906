import React, { useState, useEffect } from "react";
import moment from "moment";
import { throttle } from "lodash";
import { Button, Drawer } from "antd";
import { DoubleRightOutlined } from "@ant-design/icons";

import DateFilter from "./datepicker";
import CheckboxFilter from "./checkbox";
import SearchFilter from "./search";
import NumberFilter from "./inputNumber";
import UserFilter from "./userSearch";

import "./style.scss";

export const FILTER_COMPONENTS = {
  number: "number",
  search: "search",
  checkbox: "checkbox",
  datepicker: "datepicker",
  userSearch: "userSearch",
};
export const COMPARISONS = {
  eq: { value: "eq", label: "=" }, // равно
  neq: { value: "neq", label: <>&#8800;</> }, // не равно
  lt: { value: "lt", label: <>&#60;</> }, // меньше чем
  lte: { value: "lte", label: <>&#8804;</> }, // меньше или равно
  gt: { value: "gt", label: <>&#62;</> }, // больше чем
  gte: { value: "gte", label: <>&#8805;</> }, // больше или равно
};
export const EXISTENCE = {
  ex: { value: "ex", label: "существует" }, // существует
  nex: { value: "nex", label: "не существует" }, // не существует
};

export function isEqualMoments(momentDates = []) {
  if (!Array.isArray(momentDates)) {
    return false;
  }
  if (!momentDates.every((date) => moment.isMoment(date))) {
    return false;
  }

  return momentDates.every((date) => date.unix() === momentDates[0].unix());
}

const BREAKPOINT = 1280; // px

const ICON_STYLE = {
  position: "absolute",
  top: "20px",
  left: "50%",
  transform: "translateX(-50%)",
};

const BUTTON_STYLE = { padding: "0 10px", height: "100%", width: "60px" };

const Filters = (props) => {
  const { filters = [], onChange = () => {}, onReset } = props;

  function getFilterComponent(type) {
    switch (type) {
      case FILTER_COMPONENTS.checkbox:
        return CheckboxFilter;
      case FILTER_COMPONENTS.datepicker:
        return DateFilter;
      case FILTER_COMPONENTS.search:
        return SearchFilter;
      case FILTER_COMPONENTS.number:
        return NumberFilter;
      case FILTER_COMPONENTS.userSearch:
        return UserFilter;
      default:
        return null;
    }
  }

  function removeSelectedFilter(filterIndex, selectedIndex) {
    onChange((filters) => {
      if (filters[filterIndex]) {
        const newFilters = [...filters];
        const filter = newFilters[filterIndex];

        newFilters[filterIndex] = {
          ...filter,
          selected: filter.selected.filter(
            (s, index) => index !== selectedIndex
          ),
        };

        return newFilters;
      } else {
        return filters;
      }
    });
  }

  function addSelectedFilter(filterIndex, newSelected) {
    onChange((filters) => {
      if (filters[filterIndex]) {
        const newFilters = [...filters];
        const filter = newFilters[filterIndex];

        newFilters[filterIndex] = {
          ...filter,
          selected: [
            ...filter.selected,
            { ...newSelected, comparison: filter.comparisons[0].value },
          ],
        };

        return newFilters;
      } else {
        return filters;
      }
    });
  }

  function onChangeSelectedValue(filterIndex, selectedIndex, newSelected) {
    onChange((filters) => {
      if (filters[filterIndex]) {
        const newFilters = [...filters];
        const filter = newFilters[filterIndex];

        newFilters[filterIndex] = {
          ...filter,
          selected: filter.selected.map((selected, index) =>
            index === selectedIndex ? { ...selected, ...newSelected } : selected
          ),
        };

        return newFilters;
      } else {
        return filters;
      }
    });
  }

  function onChangeComparison(filterIndex, selectedIndex, newComparison) {
    onChange((filters) => {
      if (filters[filterIndex]) {
        const newFilters = [...filters];
        const filter = newFilters[filterIndex];

        newFilters[filterIndex] = {
          ...filter,
          selected: filter.selected.map((selected, index) =>
            index === selectedIndex
              ? { ...selected, comparison: newComparison }
              : selected
          ),
        };

        return newFilters;
      } else {
        return filters;
      }
    });
  }

  function onChangeExistenceSelect(filterIndex, existence) {
    onChange((filters) => {
      if (filters[filterIndex]) {
        const newFilters = [...filters];
        const filter = newFilters[filterIndex];

        newFilters[filterIndex] = {
          ...filter,
          existence: {
            ...filter.existence,
            selected: existence,
          },
        };

        return newFilters;
      } else {
        return filters;
      }
    });
  }

  return (
    <div className="component_search-filter">
      <h5 className="search-filter__label">
        Фильтры
        <Button
          className="label__button_reset"
          children="Сбросить"
          type="link"
          onClick={onReset}
        />
      </h5>
      {filters.map((f, idx) => {
        const Filter = getFilterComponent(f.type);

        if (Filter !== null) {
          return (
            <Filter
              key={`${f.value}_${idx}`}
              {...f}
              onSelect={(newSelected) => addSelectedFilter(idx, newSelected)}
              onRemove={(selectedIndex) =>
                removeSelectedFilter(idx, selectedIndex)
              }
              onChange={(selectedIndex, newSelected) =>
                onChangeSelectedValue(idx, selectedIndex, newSelected)
              }
              onChangeComparison={(selectedIndex, newComparison) =>
                onChangeComparison(idx, selectedIndex, newComparison)
              }
              onChangeExistence={(existence) =>
                onChangeExistenceSelect(idx, existence)
              }
            />
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};

const SearchPreciseParams = (props) => {
  const {
    filters = [],
    onChange = () => {},
    onReset = () => {},
    drawerVisible = undefined,
    onChangeDrawerVisible = undefined,
  } = props;

  // State
  const [isCollapsed, setIsCollapsed] = useState(checkBreakpoint(BREAKPOINT));
  const [drawerVisibleState, setDrawerVisibleState] = useState(
    drawerVisible !== undefined ? drawerVisible : false
  );

  const throttleResize = throttle(() => {
    const isCollapsed = checkBreakpoint(BREAKPOINT);

    setIsCollapsed(isCollapsed);
  }, 300);

  function checkBreakpoint(breakPoint = 0) {
    return window.innerWidth < breakPoint;
  }

  function getDrawerProps(visible, onChange) {
    return {
      visible: visible !== undefined ? visible : drawerVisibleState,
      onChangeVisible:
        onChange !== undefined ? onChange : setDrawerVisibleState,
    };
  }

  function drawFilterContainer(inDrawer = false) {
    if (inDrawer) {
      const { visible, onChangeVisible } = getDrawerProps(
        drawerVisible,
        onChangeDrawerVisible
      );

      return (
        <>
          <Button
            type="text"
            size="large"
            style={BUTTON_STYLE}
            icon={<DoubleRightOutlined style={ICON_STYLE} />}
            onClick={() => onChangeVisible(true)}
          />
          <Drawer
            width={300}
            placement="left"
            closable={false}
            getContainer={false}
            visible={visible}
            onClose={() => onChangeVisible(false)}
            bodyStyle={{ padding: "unset" }}
            style={{ position: "absolute" }} // position: absolute для отображения внутри текущего контейнера
          >
            <Filters filters={filters} onChange={onChange} onReset={onReset} />
          </Drawer>
        </>
      );
    } else {
      return (
        <Filters filters={filters} onChange={onChange} onReset={onReset} />
      );
    }
  }

  // componentDidMount
  useEffect(() => {
    window.addEventListener("resize", throttleResize);
  }, []);

  // componentWillUnmount
  useEffect(
    () => () => {
      window.removeEventListener("resize", throttleResize);
    },
    []
  );

  return drawFilterContainer(isCollapsed);
};

export default SearchPreciseParams;
