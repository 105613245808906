/**
 * @enum
 */
export const FILTER_NAMES = {
  onlyOpen: "onlyOpen",
  performers: "performers",
  fromOperator: "fromOperator",
  onlyUrgency: "onlyUrgency",
  templateId: "templateId",
  locationId: "locationId",
  onlyWithoutOperator: "onlyWithoutOperator",
};

const TASK_LIST_MODEL = {
  // Фильтр для получения списка задач
  filter: {
    [FILTER_NAMES.onlyOpen]: false, // Показывать только открытые задачи
    [FILTER_NAMES.performers]: {}, // Фильтр задач по исполнителю
    [FILTER_NAMES.fromOperator]: null, // Фильтр задач от оператора
    [FILTER_NAMES.onlyUrgency]: false, // Показывать только важные задачи
    [FILTER_NAMES.templateId]: {}, // Прикрепленные шаблоны
    [FILTER_NAMES.locationId]: {}, // Локации
    [FILTER_NAMES.onlyWithoutOperator]: false, // Только задачи без оператора
  },
  pagination: {
    page: 0, // Номер страницы
    pageSize: 0, // Количество задач на странице
  },
  totalResults: undefined, // Всего задач найдено
  tasks: [], // Список задач
  searching: false, // Отображать спиннер загрузки
  operators: [], // Список операторов
  groups: [], // Список групп
  customFilters: {
    selected: undefined, // Выбранный пользовательский фильтр
    options: [], // Пользовательские фильтры
  },
  templates: [], // Список шаблонов
  locations: [], // Список локаций
};

export default TASK_LIST_MODEL;
