import moment from "moment";

function isEqualMoments(momentDates = []) {
  if (!Array.isArray(momentDates)) {
    return false;
  }
  if (!momentDates.every((date) => moment.isMoment(date))) {
    return false;
  }

  return momentDates.every((date) => date.unix() === momentDates[0].unix());
}

export default isEqualMoments;
