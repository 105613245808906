import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { message } from "antd";

import Layout from "./layout";

import { history } from "../..";

import {
  getOperatorData,
  changeOperatorGroup,
  getOperatorsGroupsList,
  setOperatorRoles,
  deleteOperatorRoles,
} from "../../api/operators";
import { getRolesList } from "../../api/rbac";
import useRBAC, { UI_PERMISSIONS } from "../../services/rbac";

import "./style.scss";

const successMessage = (text) => {
  message.success(text);
};

const errorMessage = (text) => {
  message.error(text);
};

const OperatorCard = (props) => {
  const { error, tabUuid } = props;

  // Store
  const currentUserId = useSelector((store) => store.user.id);

  // State
  const [loading] = useState(false);
  const [operator, setOperator] = useState({});
  const [groups, setGroups] = useState([]);
  const [roles, setRoles] = useState([]);
  const [switches, setSwitches] = useState({ group: false, roles: false });

  // Hooks
  const { checkPermissionToRenderUI } = useRBAC();

  const uiPermissions = {
    group: checkPermissionToRenderUI(UI_PERMISSIONS['ui:management:operators:card:group:edit']),
    roles: checkPermissionToRenderUI(UI_PERMISSIONS['ui:management:operators:card:roles:edit']),
  }

  function onClick(e) {
    if (
      !e
        .composedPath()
        .find((elem) => elem.className === "component_editable-card-field")
    ) {
      setSwitches((switches) => Object.keys(switches).reduce((acc, key) => {
        acc[key] = false;
        return acc;
      }, {}));
    }
  }

  async function changeGroup(operatorId, groupId) {
    await changeOperatorGroup(operatorId, groupId)
      .then(async (resp) => {
        if (resp.status === 200) {
          await getOperatorDataById();

          return successMessage("Группа успешно изменена");
        }

        return errorMessage("При изменении группы произошла ошибка");
      })
      .catch(() => errorMessage("При изменении группы произошла ошибка"));
  }

  async function changeOperatorRoles(operatorId, newRoles) {
    const rolesForDelete = roles
      .filter((role) => !newRoles.includes(role.value))
      .map((role) => role.value);

    try {
      await setOperatorRoles(operatorId, newRoles);
      await deleteOperatorRoles(operatorId, rolesForDelete);
      successMessage("Сохранено");
    } catch {
      errorMessage('При изменении ролей оператора произошла ошибка');
    }

    getOperatorDataById();
  }

  function switchEditableField(fieldName) {
    setSwitches((switches) => ({
      ...switches,
      [fieldName]: !switches[fieldName]
    }))
  }


  async function getRoles() {
    await getRolesList()
      .then((resp) => setRoles(resp.data.map(role => ({ value: role.id, label: role.id }))))
      .catch(() => errorMessage("Ошибка получения ролей"));
  }

  async function getOperatorDataById() {
    const splitPath = history.location.pathname.split("/");
    const id = splitPath[3];

    await getOperatorData(id)
      .then((resp) => {
        if (resp.status === 200) {
          setOperator(resp.data);
        } else {
          return Promise.reject(resp);
        }
      });
  }

  async function getGroupsList() {
    await getOperatorsGroupsList()
      .then((resp) => {
        if (resp.status === 200) {
          setGroups(resp.data.map((group) => ({
            value: group.id,
            label: group.title,
          })));
        } else {
          errorMessage('Ошибка получения групп');
        }
      })
      .catch(() => errorMessage('Ошибка получения групп'));
  }

  async function getOperatorCardData() {
    try {
      await getRoles();
      await getOperatorDataById();
      await getGroupsList();
    } catch (err) {
      console.error(err);

      setTabError({
        title: "Ошибка получения оператора",
        status: err.response.status,
        message: err.response.data?.message,
      });
    }
  }

  function setTabError(error) {
    if (typeof props.setTabError === "function") {
      props.setTabError(error);
    }
  }

  useEffect(() => {
    getOperatorCardData();
    document.addEventListener("click", onClick);

    return () => {document.removeEventListener("click", onClick)}
  }, []);

  useEffect(() => {
    getOperatorCardData();
  }, [tabUuid])

  return (
    <Layout
      uiPermissions={uiPermissions}
      error={error}
      loading={loading}
      roles={roles}
      groups={groups}
      operator={operator}
      readOnly={false}
      switches={switches}
      isCurrentUser={currentUserId === operator.id}
      changeGroup={changeGroup}
      setOperatorRoles={changeOperatorRoles}
      switchEditableField={switchEditableField}
    />
  );
}

export default OperatorCard;
