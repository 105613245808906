export const ENV = process.env.NODE_ENV;
export const DEV_ENV = "development";
export const PROD_ENV = "production";

export const { PROTOCOL, HOSTNAME, VERSION } = (() => {
  switch (ENV) {
    case DEV_ENV:
      return {
        PROTOCOL: "https:",
        HOSTNAME: "helpdesk.sima-land.ru/api",
        VERSION: "/v1",
      };
    case PROD_ENV:
      return {
        PROTOCOL: "https:",
        HOSTNAME: "helpdesk.sima-land.ru/api",
        VERSION: "/v1",
      };
    default:
      return { PROTOCOL: "", HOSTNAME: "", VERSION: "" };
  }
})();

export const BASE_URL = window.API_BASE_URL
  ? `${window.API_BASE_URL}/api`
  : `${PROTOCOL}//${HOSTNAME}`;

export const ADMIN_LDAP_GROUP = "r_helpdesk_admin";
export const CHECK_PERMISSIONS = true; // Выполнять ли условную отрисовку интерфейса в зависимости от разрешений пользователя

// ENVIRONMENT
export const FRONT_ENV = {
  development: "development",
  production: "production",
};

// Notifications
export const NOTIFICATIONS_URL = window.WS_BASE_URL
  ? `${window.WS_BASE_URL}/api/ws/events`
  : "wss://helpdesk.sima-land.ru/api/ws/events";

export const GROUP_LIST = "/v1/groups";

// OPERATORS
export const OPERATORS_LIST = "/v1/operators";

// ISSUES
export const ISSUE = "/v1/issue";

// TASKS
export const TASK = "/v1/task";

// USERS
export const USER = "/v1/user";
export const USER_CREATE_FROM_EMPLOYEE = USER + "/create-from-employee";
export const USER_SEARCH = "/users/search";

// CHATS
export const GET_MESSAGES = "/v1/message";

// FILES
export const DOWNLOAD_FILES_URL = "/v1/download/file";
export const DOWNLOAD_IMAGE_PREVIEW_URL = "/v1/download/image-preview";

const getExpand = (expand = []) => {
  return expand.join(",");
};

/** API */
export const STATUSES = `${VERSION}/statuses`;

export const OPERATOR_LDAP_PROPS = `${VERSION}/operator-ldap-props`;

const SECURITY_PREFIX = "/security";
export const AUTH_API_URLS = {
  authorization: () => `${SECURITY_PREFIX}/token`,
  refresh: () => `${SECURITY_PREFIX}/refresh-token`,
};

const ISSUE_PREFIX = `${VERSION}/issue`;
// prettier-ignore
export const ISSUE_API_URLS = {
  create: () => ISSUE_PREFIX,
  createEmpty: () => `${ISSUE_PREFIX}/empty-issue`,
  closeIssue: (issueId) => `${ISSUE_PREFIX}/${issueId}/close`,
  setIssueDescription: (issueId) => `${ISSUE_PREFIX}/${issueId}/description`,
  setIssueOperator: (issueId) => `${ISSUE_PREFIX}/${issueId}/operator`,
  editIssueComment: (commentId) => `${ISSUE_PREFIX}/comment/${commentId}/comment`,
  postIssueComment: (issueId) => `${ISSUE_PREFIX}/${issueId}/comment`,
  getIssueComments: (issueId) => `${ISSUE_PREFIX}/${issueId}/comments`,
  uploadIssueFiles: (issueId) => `${ISSUE_PREFIX}/${issueId}/files`,
  deleteIssueFile: (issueId, fileId) => `${ISSUE_PREFIX}/${issueId}/file/${fileId}`,
  getIssueChanges: (issueId) => `${ISSUE_PREFIX}/${issueId}/changes`,
  getIssue: (issueId, expand = ["user", "from_operator", "files"]) => {
    return expand.length > 0
      ? `${ISSUE_PREFIX}/${issueId}?expand=${getExpand(expand)}`
      : `${ISSUE_PREFIX}/${issueId}`;
  },
  getUserIssues: (userId, expand = ["user", "operator"]) => {
    return expand.length > 0
      ? `${VERSION}/user/${userId}/open-issues?expand=${getExpand(expand)}`
      : `${VERSION}/user/${userId}/open-issues`;
  },
  getUserClosedIssues: (
    userId,
    offset,
    limit,
    expand = ["user", "operator"]
  ) => {
    return expand.length > 0
      ? `${VERSION}/user/${userId}/last-closed-issues/${offset}/${limit}?expand=${getExpand(expand)}`
      : `${VERSION}/user/${userId}/last-closed-issues/${offset}/${limit}`;
  },
  getOperatorIssues: (operatorId, expand = ["user", "operator"]) => {
    return expand.length > 0
      ? `${VERSION}/operator/${operatorId}/open-issues?expand=${getExpand(expand)}`
      : `${VERSION}/operator/${operatorId}/open-issues`;
  },
  getOperatorClosedIssues: (
    operatorId,
    offset,
    limit,
    expand = ["user", "operator"]
  ) => {
    return expand.length > 0
      ? `/operator/${operatorId}/last-closed-issues/${offset}/${limit}?expand=${getExpand(expand)}`
      : `/operator/${operatorId}/last-closed-issues/${offset}/${limit}`;
  },
  getOperatorsGroupIssues: (
    operatorsGroupId,
    expand = ["user", "operator"]
  ) => {
    return expand.length > 0
      ? `/operators-group/${operatorsGroupId}/open-issues?expand=${getExpand(expand)}`
      : `/operators-group/${operatorsGroupId}/open-issues`;
  },
  getOperatorsGroupClosedIssues: (
    operatorsGroupId,
    offset,
    limit,
    expand = ["user", "operator"]
  ) => {
    return expand.length > 0
      ? `operators-group/${operatorsGroupId}/last-closed-issues/${offset}/${limit}?expand=${getExpand(expand)}`
      : `operators-group/${operatorsGroupId}/last-closed-issues/${offset}/${limit}`;
  },
  getIssueRelatedTasks: (issueId, expand = ["operator", "operators_group"]) => {
    return expand.length > 0
      ? `${ISSUE_PREFIX}/${issueId}/tasks?expand=${getExpand(expand)}`
      : `${ISSUE_PREFIX}/${issueId}/tasks`;
  },
  getIssues: (ids = []) => {
    const query = ids
      .reduce((string, id) => string + `&id=${id}`, "")
      .substring(1);
    return `${ISSUE_PREFIX}s?${query}`;
  },
};

const TASK_PREFIX = `${VERSION}/task`;

export const TASK_API_URLS = {
  createTask: () => `${TASK_PREFIX}`,
  getTasks: (taskIds = [], expand = []) => {
    const query = taskIds.reduce((string, id) => string + `&id=${id}`, "").substring(1);
    return expand.length > 0
      ? `${TASK_PREFIX}s?${query}&expand=${getExpand(expand)}`
      : `${TASK_PREFIX}s?${query}`;
  },
  getOperatorsGroupOpenTasks: (
    groupId,
    expand = ["user", "operator", "operators_group"]
  ) =>
    expand.length > 0
      ? `${VERSION}/operators-group/${groupId}/open-tasks?expand=${getExpand(expand)}`
      : `${VERSION}/operators-group/${groupId}/open-tasks`,
  getOperatorsGroupLastClosedTasks: (groupId, offset, limit, expand = []) =>
    expand.length > 0
      ? `${VERSION}/operators-group/${groupId}/last-closed-tasks/${offset}/${limit}?expand=${getExpand(
          expand
        )}`
      : `${VERSION}/operators-group/${groupId}/last-closed-tasks/${offset}/${limit}`,
  getOperatorOpenTasks: (operatorId, expand = ["user", "operator", "operators_group"]) =>
    expand.length > 0
      ? `${VERSION}/operator/${operatorId}/open-tasks?expand=${getExpand(expand)}`
      : `${VERSION}/operator/${operatorId}/open-tasks`,
  getOperatorLastClosedTasks: (operatorId, offset, limit, expand = []) =>
    expand.length > 0
      ? `${VERSION}/operator/${operatorId}/last-closed-tasks/${offset}/${limit}?expand=${getExpand(
          expand
        )}`
      : `${VERSION}/operator/${operatorId}/last-closed-tasks/${offset}/${limit}`,
  getTaskUserPhoto: (taskId) => `${TASK_PREFIX}/${taskId}/user-photo`,
  getIssueRelatedTaskUserPhoto: (taskId) =>
    `${TASK_PREFIX}/${taskId}/issue-related-user-photo`,
  getTask: (taskId, expand = []) =>
    expand.length > 0
      ? `${TASK_PREFIX}/${taskId}?expand=${getExpand(expand)}`
      : `${TASK_PREFIX}/${taskId}`,
  setTaskDescription: (taskId) => `${TASK_PREFIX}/${taskId}/description`,
  setTaskOperator: (taskId) => `${TASK_PREFIX}/${taskId}/operator`,
  setTaskOperatorsGroup: (taskId) => `${TASK_PREFIX}/${taskId}/operators-group`,
  closeTask: (taskId) => `${TASK_PREFIX}/${taskId}/close`,
  getTaskChanges: (taskId) => `${TASK_PREFIX}/${taskId}/changes`,
  uploadTaskFiles: (taskId) => `${TASK_PREFIX}/${taskId}/files`,
  postTaskComment: (taskId) => `${TASK_PREFIX}/${taskId}/comment`,
  getTaskComments: (taskId) => `${TASK_PREFIX}/${taskId}/comments`,
  editTaskComment: (commentId) => `${TASK_PREFIX}/comments/${commentId}/comment`,
  setTaskPriority: (taskId) => `${TASK_PREFIX}/${taskId}/priority`,
  reopenTask: (taskId) => `${TASK_PREFIX}/${taskId}/reopen`,
  setTaskUser: (taskId) => `${TASK_PREFIX}/${taskId}/user`,
  taskReject: (taskId) => `${TASK_PREFIX}/${taskId}/reject`,
  setTaskTemplate: (taskId) => `${TASK_PREFIX}/${taskId}/template`,
  deleteTaskTemplate: (taskId) => `${TASK_PREFIX}/${taskId}/template`,
};

const MASS_PROBLEM_PREFIX = `${VERSION}/mass-problem`;

export const MASS_PROBLEM = {
  create: MASS_PROBLEM_PREFIX,
  getOpenProblemsList: (expand = "operator") =>
    expand ? `${MASS_PROBLEM_PREFIX}s?expand=${expand}` : `${MASS_PROBLEM_PREFIX}s`,
  getById: (massProblemId) =>
    `${MASS_PROBLEM_PREFIX}/${massProblemId}?expand=operator,files`,
  addFiles: (massProblemId) => `${MASS_PROBLEM_PREFIX}/${massProblemId}/files`,
  addIssues: (massProblemId) => `${MASS_PROBLEM_PREFIX}/${massProblemId}/issues`,
  closeProblem: (massProblemId) => `${MASS_PROBLEM_PREFIX}/${massProblemId}/close`,
  editComment: (commentId) => `${MASS_PROBLEM_PREFIX}/comment/${commentId}/comment`,
  postComment: (massProblemId) => `${MASS_PROBLEM_PREFIX}/${massProblemId}/comment`,
  commentsList: (massProblemId) => `${MASS_PROBLEM_PREFIX}/${massProblemId}/comments`,
  putDescription: (massProblemId) =>
    `${MASS_PROBLEM_PREFIX}/${massProblemId}/description`,
  deleteIssue: (massProblemId, issueId) =>
    `${MASS_PROBLEM_PREFIX}/${massProblemId}/${issueId}`,
  getOpenIssuesList: (massProblemId) =>
    `${MASS_PROBLEM_PREFIX}/${massProblemId}/open-issues?expand=user,operator`,
  deleteFile: (massProblemId, fileId) =>
    `${MASS_PROBLEM_PREFIX}/${massProblemId}/file/${fileId}`,
  getChanges: (massProblemId) => `${MASS_PROBLEM_PREFIX}/${massProblemId}/changes`,
  getMassProblemsById: (ids = []) =>
    `${MASS_PROBLEM_PREFIX}s-by-ids?${ids
      .reduce((string, id) => string + `&id=${id}`, "")
      .substring(1)}`,
};

const OPERATOR_PREFIX = `${VERSION}/operator`;
const CUSTOM_FILTERS_PREFIX = `${VERSION}/custom-filter`;
const OPERATOR_NOTIFICATIONS_PREFIX = `${VERSION}/notifications`;
// prettier-ignore
export const OPERATOR_API_URLS = {
  getOperator: (operatorId) => `${OPERATOR_PREFIX}/${operatorId}`,
  getOperators: () => `${OPERATOR_PREFIX}s`,
  setOperatorGroup: (operatorId) => `${OPERATOR_PREFIX}/${operatorId}/group`,
  setOperatorRoles: (operatorId) => `${OPERATOR_PREFIX}/${operatorId}/roles`,
  deleteOperatorRoles: (operatorId) => `${OPERATOR_PREFIX}/${operatorId}/roles`,
  getOperatorPermissions: (operatorId) => `${OPERATOR_PREFIX}/${operatorId}/inherited-permissions`,
  getCustomFilters: (realm) => `${CUSTOM_FILTERS_PREFIX}s/${realm}`,
  postCustomFilter: () => `${CUSTOM_FILTERS_PREFIX}`,
  putCustomFilter: (filterId) => `${CUSTOM_FILTERS_PREFIX}/${filterId}`,
  deleteCustomFilter: (filterId) => `${CUSTOM_FILTERS_PREFIX}/${filterId}`,
  getNotifications: (limit, offset) => `${OPERATOR_NOTIFICATIONS_PREFIX}/${offset}/${limit}`,
  readNotifications: () => `${OPERATOR_NOTIFICATIONS_PREFIX}/read`,
  clearAllNotifications: () => `${OPERATOR_NOTIFICATIONS_PREFIX}/clear-all`,
};

const OPERATORS_GROUP_PREFIX = `${VERSION}/operators-group`;

export const OPERATORS_GROUP_API_URLS = {
  createOperatorsGroup: () => `${OPERATORS_GROUP_PREFIX}`,
  putOperatorsGroup: (groupId) => `${OPERATORS_GROUP_PREFIX}/${groupId}`,
  deleteOperatorsGroup: (groupId) => `${OPERATORS_GROUP_PREFIX}/${groupId}`,
  getOperatorsGroup: (groupId) => `${OPERATORS_GROUP_PREFIX}/${groupId}/operators`,
  getGroups: () => `${VERSION}/groups`,
};

// prettier-ignore
export const CHAT_API_URLS = {
  getMessages: (userId, offset, limit) => `${VERSION}/user/${userId}/messages/${offset}/${limit}`,
  getDialogues: () => `${VERSION}/user-ids-whose-chat-require-attention`,
  getMessage: (messageId) => `${VERSION}/message/${messageId}`,
  postFile: (userId) => `${VERSION}/user/${userId}/file`,
  postMessage: (userId) => `${VERSION}/user/${userId}/message`,
};

// prettier-ignore
export const FILE_API_URLS = {
  getFile: (fileId) => `${VERSION}/download/file/${fileId}`,
  getFilePreview: (filePreviewId) => `${VERSION}/download/image-preview/${filePreviewId}`,
  deleteFile: (fileId) => `${VERSION}/file/${fileId}`,
  uploadFiles: () => `${VERSION}/upload/files`,
}

const LOCATION_PREFIX = `${VERSION}/location`;
// prettier-ignore
export const LOCATION_API_URLS = {
  getLocations: (expand = ["mac_address_masks"]) => {
    return expand.length > 0
      ? `${LOCATION_PREFIX}s?expand=${expand.join(",")}`
      : `${LOCATION_PREFIX}s`
  },
  getLocation: (locationId, expand = ["mac_address_masks"]) => {
    return expand.length > 0
      ? `${LOCATION_PREFIX}/${locationId}?expand=${expand.join(",")}`
      : `${LOCATION_PREFIX}/${locationId}`
  },
  createLocation: () => `${LOCATION_PREFIX}`,
  getLocationTasks: (locationId) => `${LOCATION_PREFIX}/${locationId}/tasks`,
  setLocationMacAddresses: (locationId) => `${LOCATION_PREFIX}/${locationId}/mac-address-masks`,
  changeParentLocation: (locationId) => `${LOCATION_PREFIX}/${locationId}/parent`,
  changeLocationTitle: (locationId) => `${LOCATION_PREFIX}/${locationId}/title`,
  deleteLocation: (locationId) => `${LOCATION_PREFIX}/${locationId}`,
}

export const HARDWARE_CHANGES = `${VERSION}/hardware-changes`;
export const HARDWARE_API_URLS = {
  postHardware: () => `${VERSION}/hardware`,
  putTaskHardware: (taskId) => `${VERSION}/task/${taskId}/hardware`,
  deleteTaskHardware: (taskId) => `${VERSION}/task/${taskId}/hardware`,
  postHardwareChange: (hardwareId) => `${VERSION}/hardware/${hardwareId}/change`,
  putHardwareChange: (hardwareChangeId) =>
    `${VERSION}/hardware-change/${hardwareChangeId}/text`,
  deleteHardwareChange: (hardwareChangeId) =>
    `${VERSION}/hardware-change/${hardwareChangeId}`,
  getHardwareChanges: (hardwareId) => `${VERSION}/hardware/${hardwareId}/changes`,
  searchHardware: (query, userId) =>
    userId
      ? `${VERSION}/hardware/search?query=${query}&user_id=${userId}`
      : `${VERSION}/hardware/search?query=${query}`,
  putHardwareEdintifier: (hardwareId) => `${VERSION}/hardware/${hardwareId}/identifier`,
};

const TEMPLATE_PREFIX = `${VERSION}/template`;
// prettier-ignore
export const TEMPLATES_API_URLS = {
  getTemplateList: (operatorsGroupId) => operatorsGroupId
    ? `${TEMPLATE_PREFIX}s?operator_group_id=${operatorsGroupId}`
    : `${TEMPLATE_PREFIX}s`,
  getTemplate: (templateId, ) => `${TEMPLATE_PREFIX}/${templateId}`,
  updateTemplate: (templateId) => `${TEMPLATE_PREFIX}/${templateId}`,
  deleteTemplate: (templateId) => `${TEMPLATE_PREFIX}/${templateId}`,
  createTemplate: () => `${TEMPLATE_PREFIX}`,
}

const INTEGRATION_PREFIX = `${VERSION}/integration`;

export const SERVICE_ACCOUNT_API_URLS = {
  createServiceAccount: () => `${INTEGRATION_PREFIX}/service-account`,
  getServiceAccounts: () => `${INTEGRATION_PREFIX}/service-accounts`,
  getServiceAccount: (serviceAccountId) =>
    `${INTEGRATION_PREFIX}/service-account/${serviceAccountId}`,
  updateServiceAccount: (serviceAccountId) =>
    `${INTEGRATION_PREFIX}/service-account/${serviceAccountId}`,
  updateSecretServiceAccount: (serviceAccountId) =>
    `${INTEGRATION_PREFIX}/service-account/${serviceAccountId}/secret`,
};

export const REPORT_API_URLS = {
  getReport: (query, preview) =>
    `${VERSION}/performing-report?${query}&preview=${preview}`,
};

const RBAC_PREFIX = `${VERSION}/rbac`;

export const RBAC_API_URLS = {
  getRolesList: () => `${RBAC_PREFIX}/roles`,
  getPermissionsList: () => `${VERSION}/list-available-permissions`,
  getSvgRolesTree: (roles = [], withPerms = false) =>
    `${RBAC_PREFIX}/graph?${roles.map((r) => `role=${r}&`)}with_perms=${withPerms}`,
  createRole: () => `${RBAC_PREFIX}/role`,
  postRolePermissions: (roleName) => `${RBAC_PREFIX}/role/${roleName}/permissions`,
  deleteRolePermissions: (roleName) => `${RBAC_PREFIX}/role/${roleName}/permission`,
  postRoleParents: (roleName) => `${RBAC_PREFIX}/role/${roleName}/parents`,
  deleteRoleParents: (roleName) => `${RBAC_PREFIX}/role/${roleName}/parents`,
};

export const SEARCH_API_URLS = {
  search: (queryParams) => `${VERSION}/search?${queryParams}`,
};

const USER_PREFIX = `${VERSION}/user`;
export const USER_API_URLS = {
  createFromEmployee: () => `${USER_PREFIX}/create-from-employee`,
  getUser: (userId) => `${USER_PREFIX}/${userId}`,
  setUserLocation: (userId) => `${USER_PREFIX}/${userId}/workplace-location`,
  setUserWorkstationNumber: (userId) =>
    `${USER_PREFIX}/${userId}/update-workstation-number-manually`,
  searchUser: (query, usersOnly = false) =>
    `${USER_PREFIX}s/search?query=${query}&users_only=${usersOnly}`,
  getUserPhoto: (userId) => `${USER_PREFIX}/${userId}/photo`,
  updateUserInfo: (userId) => `${USER_PREFIX}/${userId}/update-user-employee-info`,
};

/**
 * @enum Области применения кастомных фильтров
 */
export const REALMS_OF_CUSTOM_FILTERS = {
  issues: "issues",
  tasks: "tasks",
};

/**
 * @enum Перечисление всех компонентов, которые можно открыть во вкладке
 */
export const COMPONENTS = {
  ISSUE_CARD: "ISSUE_CARD",
  ISSUE_CREATION_FORM: "ISSUE_CREATION_FORM",
  ISSUE_LIST: "ISSUE_LIST",
  GROUP_CARD: "GROUP_CARD",
  GROUP_CREATION_FORM: "GROUP_CREATION_FORM",
  LDAP_PROP_CARD: "LDAP_PROP_CARD",
  LDAP_PROPS_CREATION_FORM: "LDAP_PROPS_CREATION_FORM",
  LOCATION_CARD: "LOCATION_CARD",
  LOCATION_CREATION_FORM: "LOCATION_CREATION_FORM",
  MASS_PROBLEM_CARD: "MASS_PROBLEM_CARD",
  MASS_PROBLEM_CREATION_FORM: "MASS_PROBLEM_CREATION_FORM",
  MASS_PROBLEM_LIST: "MASS_PROBLEM_LIST",
  MESSANGER: "MESSANGER",
  OPERATOR_CARD: "OPERATOR_CARD",
  MANAGEMENT: "MANAGEMENT",
  PROFILE: "PROFILE",
  ROLE_CARD: "ROLE_CARD",
  ROLE_CREATION_FORM: "ROLE_CREATION_FORM",
  TASK_CARD: "TASK_CARD",
  TASK_CREATION_FORM: "TASK_CREATION_FORM",
  TASK_LIST: "TASK_LIST",
  USER_CARD: "USER_CARD",
  USER_LIST: "USER_LIST",
  SEARCH: "SEARCH",
  REPORT: "REPORT",
};

export const APP_NAVIGATION_MAP = {
  issues: {
    component: COMPONENTS.ISSUE_LIST,
    new: {
      component: COMPONENTS.ISSUE_CREATION_FORM,
    },
    ":issueId": {
      component: COMPONENTS.ISSUE_CARD,
    },
  },
  tasks: {
    component: COMPONENTS.TASK_LIST,
    new: {
      component: COMPONENTS.TASK_CREATION_FORM,
    },
    ":taskId": {
      component: COMPONENTS.TASK_CARD,
    },
  },
  "mass-problems": {
    component: COMPONENTS.MASS_PROBLEM_LIST,
    new: {
      component: COMPONENTS.MASS_PROBLEM_CREATION_FORM,
    },
    ":massProblemId": {
      component: COMPONENTS.MASS_PROBLEM_CARD,
    },
  },
  messages: {
    component: COMPONENTS.MESSANGER,
    // ":userId": {
    //   component: COMPONENTS.MESSANGER,
    // },
  },
  users: {
    component: COMPONENTS.USER_LIST,
    ":userId": {
      component: COMPONENTS.USER_CARD,
    },
  },
  management: {
    component: COMPONENTS.MANAGEMENT,
    location: {
      // component: () => {},
      new: {
        component: COMPONENTS.LOCATION_CREATION_FORM,
      },
      ":locationId": {
        component: COMPONENTS.LOCATION_CARD,
      },
    },
    operator: {
      // component: () => {},
      ":operatorId": {
        component: COMPONENTS.OPERATOR_CARD,
      },
    },
    groups: {
      // component: () => {},
      new: {
        component: COMPONENTS.GROUP_CREATION_FORM,
      },
      ":groupId": {
        component: COMPONENTS.GROUP_CARD,
      },
    },
    roles: {
      // component: () => {},
      new: {
        component: COMPONENTS.ROLE_CREATION_FORM,
      },
      ":roleId": {
        component: COMPONENTS.ROLE_CARD,
      },
    },
    ldap: {
      // component: () => {},
      new: {
        component: COMPONENTS.LDAP_PROPS_CREATION_FORM,
      },
      ":ldapPropId": {
        component: COMPONENTS.LDAP_PROP_CARD,
      },
    },
  },
  profile: {
    component: COMPONENTS.PROFILE,
  },
  search: {
    component: COMPONENTS.SEARCH,
  },
  report: {
    component: COMPONENTS.REPORT,
  },
};

function recursiveTransformNavigationMap(map, previosPath = [""]) {
  const componentUrl = {}; // Переменная для перечисления вида { [Название_компонента]: '/путь_к_компоненту' }

  for (let key in map) {
    if (key === "component") {
      /*
        Если есть компонент, то добавляем в componentUrl
        массив пути форматируем в строку, вставляя между частями "/"
      */
      componentUrl[map[key]] = previosPath.join("/");
    } else {
      // Иначе рекурсивно обходим вложенные объекты
      const nestedMap = recursiveTransformNavigationMap(map[key], [...previosPath, key]);
      const keyValueArrays = Object.entries(nestedMap); // Извлекаем пары ключ-значение из объекта

      // Добавляем их в componentUrl
      keyValueArrays.forEach((prop) => {
        const [key, value] = prop;
        componentUrl[key] = value;
      });
    }
  }

  return componentUrl;
}

export const COMPONENT_URL = recursiveTransformNavigationMap(APP_NAVIGATION_MAP);

// export default backendURL;
