import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, message } from "antd";
import isEqual from "lodash.isequal";
import {
  ReloadOutlined,
  // ClockCircleOutlined
} from "@ant-design/icons";

import AddToMassProblem from "../../components/AddToMassProblem";
import MainContainer from "../../components/MainContainer";
import Table from "../../components/Table";
import Pagination from "../../components/Pagination";
import Filters from "./filters";

import ISSUE_LIST_MODEL, { FILTER_NAMES } from "./model";
import { TYPES } from "../../components/Search_new/consts";
import { REALMS_OF_CUSTOM_FILTERS } from "../../consts";
import { useTabs, COMPONENTS } from "../../services/tabs";
import useRBAC, { UI_PERMISSIONS } from "../../services/rbac";
import useSearch, {
  FILTERS,
  EXISTENCE,
  PAGE_SIZES,
  COMPARISONS,
  SORT_TYPES,
  SORT_DIRECTION,
} from "../../hooks/search.hook";
import OPERATORS_API from "../../api/operators";
import usePagination from "../../hooks/pagination.hook";
import useOperatorCustomFilters from "../../hooks/operatorCustomFilters";

import "./style.scss";

const IssuesList = (props) => {
  const { componentProps, setComponentProps, error, setTabError } = props;
  const {
    filter,
    issues,
    totalResults,
    searching,
    operators,
    groups,
    addToMassProblemVisible,
    customFilters,
    pagination,
  } = componentProps;
  const { onlyOpen, performers, fromOperator } = filter;
  const { page, pageSize } = pagination;

  // Store
  const {
    id: currentOperatorId,
    lastName,
    firstName,
  } = useSelector((store) => store.user);

  // Hooks
  const { clickHandler } = useTabs();
  const { checkPermissionToRenderUI } = useRBAC();
  const { onSearch, getFullDataOfSearchResults } = useSearch();
  const { getCustomFilters, createFilter, editFilter } = useOperatorCustomFilters();
  const { getPaginationRange, totalPageCount } = usePagination({
    currentPage: page,
    pageSize,
    totalCount: totalResults,
  });

  const DEFAULT_COMPONENT_PROPS = {
    ...ISSUE_LIST_MODEL,
    filter: {
      ...ISSUE_LIST_MODEL.filter,
      onlyOpen: true,
      fromOperator: JSON.stringify({
        id: currentOperatorId,
        type: TYPES.operator,
      }),
    },
    pagination: {
      page: 1,
      pageSize: PAGE_SIZES[0].value,
    },
  };

  async function getOperators() {
    await OPERATORS_API.getOperatorsList()
      .then((resp) =>
        setComponentProps({
          operators: resp.data
            .map((operator) => ({
              id: operator.id,
              value: JSON.stringify({ id: operator.id, type: TYPES.operator }),
              title: `${operator.last_name} ${operator.first_name}`,
            }))
            .sort((a, b) => sortFunc(a.title, b.title)),
        })
      )
      .catch((err) => {
        console.error(err);
      });
  }

  async function getGroups() {
    await OPERATORS_API.getOperatorsGroupsList()
      .then((resp) =>
        setComponentProps({
          groups: resp.data
            .map((group) => ({
              id: group.id,
              value: JSON.stringify({ id: group.id, type: TYPES.group }),
              title: group.title,
            }))
            .sort((a, b) => sortFunc(a.title, b.title)),
        })
      )
      .catch((err) => {
        console.error(err);
      });
  }

  function sortFunc(a, b) {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  }

  function getFromParam(page, size) {
    return (page - 1) * size;
  }

  function getTotal(searchResults = [], page = 1, pageSize = 0, totalResults = 0) {
    if (totalResults === 0) return `Показано ${totalResults}`;

    return `Показано ${(page - 1) * pageSize + 1} - ${
      (page - 1) * pageSize + searchResults.length
    } из ${totalResults}`;
  }

  function getPerformers(performers) {
    function getFilterType(performerType) {
      switch (performerType) {
        case TYPES.operator:
          return FILTERS.operator_id;
        case TYPES.group:
          return FILTERS.operators_group_id;
        default:
          return;
      }
    }

    const filtersObj = Object.keys(performers).reduce((acc, key) => {
      try {
        const performer = JSON.parse(performers[key].value);
        const filterType = getFilterType(performer.type);

        if (!filterType) throw Error("TaskList: performer type not found");

        if (filterType in acc) {
          const _acc = { ...acc };

          _acc[filterType].selected.push({
            value: performer.id,
            comparison: COMPARISONS.eq.value,
          });

          return _acc;
        } else {
          return {
            ...acc,
            [filterType]: {
              selected: [{ value: performer.id, comparison: COMPARISONS.eq.value }],
            },
          };
        }
      } catch (err) {
        console.error(err);
        return acc;
      }
    }, {});

    return Object.keys(filtersObj).reduce(
      (acc, key) => [...acc, { ...filtersObj[key], value: key }],
      []
    );
  }

  async function getIssues(filters, page, pageSize) {
    const { onlyOpen, performers = {}, fromOperator } = filters;

    const searchParams = {
      filters: [
        {
          value: FILTERS.type,
          selected: [{ value: "issue", comparison: COMPARISONS.eq.value }],
        },
      ],
      sort: { value: SORT_TYPES.createdAt, direction: SORT_DIRECTION.desc },
      from: getFromParam(page, pageSize),
      size: pageSize,
    };

    if (fromOperator) {
      searchParams.filters.push({
        value: FILTERS.from_operator_id,
        selected: [
          {
            value: JSON.parse(fromOperator).id,
            comparison: COMPARISONS.eq.value,
          },
        ],
      });
    }
    if (Object.keys(performers).length > 0) {
      searchParams.filters.push(...getPerformers(performers));
    }
    if (onlyOpen) {
      searchParams.filters.push({
        value: FILTERS.done_at,
        existence: { selected: EXISTENCE.nex.value },
      });
    }

    await setComponentProps({ searching: true });

    try {
      const searchResults = await onSearch(searchParams);
      const { total_hits, hits } = searchResults;
      const searchResultsFullData = await getFullDataOfSearchResults(hits);

      setComponentProps({
        issues: searchResultsFullData,
        totalResults: total_hits,
      });
    } catch (err) {
      setTabError({
        title: "Ошибка получения задач",
        message: err.response?.data?.message,
      });
    }

    await setComponentProps({ searching: false });
  }

  function openIssueCard(e, issue) {
    e.preventDefault();
    e.stopPropagation();

    clickHandler({
      component: COMPONENTS.ISSUE_CARD,
      params: { issueId: issue.id },
      locationState: { issueId: issue.id },
    });
  }

  function checkIssues(issues) {
    if (Array.isArray(issues)) {
      return issues;
    } else {
      if (error === null) {
        setTabError({
          message:
            "Невозможно отобразить список обращений. Получен неверный формат данных",
        });
      }
      return [];
    }
  }

  function getTableTitle(issues) {
    if (!Array.isArray(issues)) return null;

    // const openIssuesCount = issues.filter((issue) => !issue.done_at).length;

    return (
      <div className="issues-list__table-title">
        <span>{getTotal(issues, page, pageSize, totalResults)}</span>
        <Button
          title="Обновить"
          className="table-title__button table-title__button_refresh"
          type="text"
          icon={<ReloadOutlined />}
          onClick={() => setComponentProps({ filter: { ...filter, page: 1 } })}
        />
        {/* <ClockCircleOutlined className="title__icon title__icon_clock" />
        Открытых: */}
        <span className="title__open-issues-count">{/* {openIssuesCount} */}</span>
        {checkPermissionToRenderUI(
          UI_PERMISSIONS["ui:issues:list:add-to-mass-problem"]
        ) && (
          <Button
            className="button_add-to-mass-problem"
            type="link"
            children="Добавить обращения к МП"
            onClick={() => setComponentProps({ addToMassProblemVisible: true })}
          />
        )}
        {checkPermissionToRenderUI(UI_PERMISSIONS["ui:issues:list:create-issue"]) && (
          <Button
            className="button_create"
            type="primary"
            children="Создать обращение"
            onClick={() => clickHandler({ component: COMPONENTS.ISSUE_CREATION_FORM })}
          />
        )}
      </div>
    );
  }

  function onResetFilters() {
    setComponentProps({
      filter: DEFAULT_COMPONENT_PROPS.filter,
      customFilters: {
        ...customFilters,
        selected: DEFAULT_COMPONENT_PROPS.customFilters.selected,
      },
    });
  }

  function getOperatorFilter() {
    const value = JSON.stringify({
      id: currentOperatorId,
      type: TYPES.operator,
    });

    return {
      performers: {
        [currentOperatorId]: {
          title: `${lastName} ${firstName}`,
          value,
        },
      },
      fromOperator: ISSUE_LIST_MODEL.filter.fromOperator,
      onlyOpen: false,
    };
  }

  async function onSaveFilter(name, filters, filterId) {
    try {
      const filtersString = JSON.stringify(filters);

      if (filterId) {
        const _updateFilter = await editFilter(filterId, name, filtersString);

        setComponentProps({
          customFilters: {
            ...customFilters,
            options: customFilters.options.map((filter) =>
              filter.value === filterId ? _updateFilter : filter
            ),
          },
        });
      } else {
        const _newFilter = await createFilter(
          REALMS_OF_CUSTOM_FILTERS.issues,
          name,
          filtersString
        );

        setComponentProps({
          customFilters: {
            ...customFilters,
            options: [...customFilters.options, _newFilter],
          },
        });
      }
    } catch (err) {
      console.error(err);
    }
  }

  // Выбрать пользовательский фильтр
  function onCustomFilterSelect(id, filter) {
    if (!id && !filter) {
      return setComponentProps({
        filter: { ...DEFAULT_COMPONENT_PROPS.filter },
        customFilters: { ...customFilters, selected: undefined },
      });
    }

    try {
      const parsedFilter = JSON.parse(filter);

      setComponentProps({
        filter: {
          ...DEFAULT_COMPONENT_PROPS.filter,
          ...parsedFilter,
        },
        customFilters: { ...customFilters, selected: id },
      });
    } catch (err) {
      console.error(err);
      message.error("Не удалось применить фильтр");
    }
  }

  // initial state
  useEffect(() => {
    (async () => {
      if (isEqual(componentProps, ISSUE_LIST_MODEL)) {
        setComponentProps(DEFAULT_COMPONENT_PROPS);
      }

      getOperators();
      getGroups();

      const customFiltersOptions = await getCustomFilters(
        REALMS_OF_CUSTOM_FILTERS.issues
      );

      setComponentProps({
        customFilters: { ...customFilters, options: customFiltersOptions },
      });
    })();
  }, [getCustomFilters]);

  // Получаем обращения при изменении фильтров или пагинации
  useEffect(() => {
    if (page !== ISSUE_LIST_MODEL.pagination.page) {
      getIssues(filter, page, pageSize);
    }
  }, [filter, page, pageSize]);

  useEffect(() => {
    if (
      page !== ISSUE_LIST_MODEL.pagination.page &&
      pageSize !== ISSUE_LIST_MODEL.pagination.pageSize
    ) {
      setComponentProps({
        pagination: {
          ...pagination,
          page: DEFAULT_COMPONENT_PROPS.pagination.page,
        },
      });
    }
  }, [filter]);

  return (
    <MainContainer
      title="Обращения"
      className="page_issues-list"
      bodyClassName="issues-list__body"
      error={error}
    >
      <Filters
        defaultFilters={DEFAULT_COMPONENT_PROPS.filter}
        operatorFilters={getOperatorFilter()}
        customFilters={{
          ...customFilters,
          onChange: onCustomFilterSelect,
        }}
        filters={{
          [FILTER_NAMES.onlyOpen]: { value: onlyOpen },
          [FILTER_NAMES.fromOperator]: {
            options: operators,
            value: fromOperator,
          },
          [FILTER_NAMES.performers]: {
            options: { operators, groups },
            value: performers,
          },
        }}
        onChangeFilters={(filterName, value) =>
          setComponentProps({ filter: { ...filter, [filterName]: value } })
        }
        onResetFilters={onResetFilters}
        onSaveFilters={(name) =>
          onSaveFilter(
            name,
            {
              [FILTER_NAMES.onlyOpen]: onlyOpen,
              [FILTER_NAMES.fromOperator]: fromOperator,
              [FILTER_NAMES.performers]: performers,
            },
            customFilters.selected
          )
        }
      />

      <div className="table-wrapper">
        <Table
          scroll={{ y: "calc(100vh - 316px)" }}
          loading={searching}
          data={checkIssues(issues)}
          onRowClick={openIssueCard}
          title={() => getTableTitle(issues)}
          showSorterTooltip={false}
        />
        <Pagination
          className="issues-list__pagination"
          size="small"
          range={getPaginationRange()}
          currentPage={page}
          pageSize={pageSize}
          totalPageCount={totalPageCount}
          onPageChange={(page) =>
            setComponentProps({ pagination: { ...pagination, page } })
          }
          onPageSizeChange={(pageSize) =>
            setComponentProps({ pagination: { ...pagination, pageSize } })
          }
        />
      </div>
      <AddToMassProblem
        filter={{ id: currentOperatorId, type: TYPES.operator }}
        visible={addToMassProblemVisible}
        onClose={() => setComponentProps({ addToMassProblemVisible: false })}
      />
    </MainContainer>
  );
};

export default IssuesList;
