/**
 * @enum
 */
export const FILTER_NAMES = {
  onlyOpen: "onlyOpen",
  performers: "performers",
  fromOperator: "fromOperator",
};

const ISSUE_LIST_MODEL = {
  // Фильтры для получения списка обращений
  filter: {
    [FILTER_NAMES.onlyOpen]: false, // Показывать только открытые обращения
    [FILTER_NAMES.performers]: {}, // Фильтр обращений по исполнителям
    [FILTER_NAMES.fromOperator]: null, // Фильтр обращений от оператора
  },
  pagination: {
    page: 0, // Номер страницы
    pageSize: 0, // Количество обращений на странице
  },
  totalResults: undefined, // Всего обращений найдено
  issues: [], // Список обращений
  searching: false, // Отображать спиннер загрузки
  operators: [], // Список операторов
  groups: [], // Список групп
  addToMassProblemVisible: false, // Отображение модального окна для добавления обращения к мп
  customFilters: {
    selected: undefined, // Выбранный пользовательский фильтр
    options: [], // Пользовательские фильтры
  },
};

export default ISSUE_LIST_MODEL;
