import ISSUES_API from "../../api/appeals";
import APPEAL_TYPES from "./actionType";
import {
  ISSUE_CREATION_ERROR,
  ISSUE_CLOSING_ERROR,
  ISSUE_GETTING_ERROR,
  ISSUE_OPERATOR_CHANGING_ERROR,
  ISSUE_DESCRIPTION_EDITING_ERROR,
  GETTING_RELATED_TASKS_ERROR,
  FILE_UPLOADING_ERROR,
} from "../errors/actionTypes";
import errorHandling from "../../services/errors/errorHandling";
import { TYPES } from "../../components/Search_new/consts";
import { addTab, setTabComponent } from "../../actions/tabs/tabs";
import { COMPONENTS } from "../../services/tabs";
import { TABS as ISSUE_TABS } from "../../containers/AppealCard";

/**
 *
 * @param {object} params
 * @param {string} params.id
 * @param {string} params.type
 * @param {number} params.limit Лимит
 * @param {number} params.offset Смещение
 * @param {boolean} params.onlyOpen Получить только открытые обращения, иначе все
 */
function getIssues(params) {
  const { id, onlyOpen } = params;

  if (!id) throw Error("Не передан параметр 'id'");

  if (onlyOpen) return getOpenIssues(params);
  if (!onlyOpen) return getAllIssues(params);
}

/**
 * Получаем все обращения
 * @param {object} params
 * @returns
 */
async function getAllIssues(params) {
  const { id, type, limit, offset } = params;

  if (offset === 0) {
    // В этом блоке получаем все обращения
    const getOpenIssues = getRequest(true, type);
    const getClosedIssues = getRequest(false, type);

    return await Promise.all([
      getOpenIssues(id),
      getClosedIssues(id, offset, limit),
    ]);
  } else {
    // Если же offset !== 0, то получаем только закрытые обращения с учетом смещения
    const getClosedIssues = getRequest(false, type);

    return await getClosedIssues(id, offset, limit);
  }
}

/**
 * Получаем все открытые обращения
 * @param {object} params
 */
async function getOpenIssues(params) {
  const { id, type } = params;
  const getOpenIssues = getRequest(true, type);

  return await getOpenIssues(id);
}

/**
 * Возвращает запрос для получения обращений по переданным параметрам
 * @param {boolean} isOpen Запрос на получение открытых или закрытых обращений
 * @param {TYPES} type Тип
 * @returns {Function}
 */
function getRequest(isOpen, type) {
  if (isOpen) {
    switch (type) {
      case TYPES.operator:
        return ISSUES_API.getOperatorAppeals;
      case TYPES.group:
        return ISSUES_API.getOpGroupAppeals;
      case TYPES.user:
        return ISSUES_API.getUserAppeals;
      default:
        throw Error("Передан неизвестный тип");
    }
  } else {
    switch (type) {
      case TYPES.operator:
        return ISSUES_API.getOperatorAppealsClosed;
      case TYPES.group:
        return ISSUES_API.getOpGroupAppealsClosed;
      case TYPES.user:
        return ISSUES_API.getUserAppealsClosed;
      default:
        throw Error("Передан неизвестный тип");
    }
  }
}

export function getAppealList(userID, config) {
  return async (dispatch) => {
    let request;
    const {
      type = "operator",
      onlyOpen = false,
      offset = 0,
      limit = 0,
    } = config;

    if (onlyOpen) {
      switch (type) {
        case "user":
          request = ISSUES_API.getUserAppealsClosed(userID, offset, limit);
          break;
        case "operator":
          request = ISSUES_API.getOperatorAppealsClosed(userID, offset, limit);
          break;
        case "group":
          request = ISSUES_API.getOpGroupAppealsClosed(userID, offset, limit);
          break;
        default:
          return dispatch(
            errorHandling(
              {
                message:
                  "Передан неизвестный тип пользователя при получении обращений",
              },
              ISSUE_GETTING_ERROR
            )
          );
      }
    } else {
      switch (type) {
        case "user":
          request = ISSUES_API.getUserAppeals(userID);
          break;
        case "operator":
          request = ISSUES_API.getOperatorAppeals(userID);
          break;
        case "group":
          request = ISSUES_API.getOpGroupAppeals(userID);
          break;
        default:
          return dispatch(
            errorHandling(
              {
                message:
                  "Передан неизвестный тип пользователя при получении обращений",
              },
              ISSUE_GETTING_ERROR
            )
          );
      }
    }

    request
      .then((resp) =>
        dispatch({
          type: APPEAL_TYPES.GET_APPEALS_LIST,
          payload: resp.data,
        })
      )
      .catch((err) => dispatch(errorHandling(err, ISSUE_GETTING_ERROR)));
  };
}

export function closeAppeal(id) {
  return async (dispatch) =>
    await ISSUES_API.closeAppeal(id)
      .then(() => dispatch({ type: APPEAL_TYPES.APPEAL_CLOSE }))
      .catch((err) => dispatch(errorHandling(err, ISSUE_CLOSING_ERROR)));
}

export function createAppeal(appeal) {
  return async (dispatch) =>
    await ISSUES_API.createNewAppeal(appeal)
      .then(async (resp) => {
        await dispatch(
          setTabComponent(COMPONENTS.ISSUE_CARD, { issueId: resp.data.id })
        );
      })
      .catch((err) => dispatch(errorHandling(err, ISSUE_CREATION_ERROR)));
}

export function createEmptyIssue(emptyIssue) {
  return async (dispatch) =>
    await ISSUES_API.createEmptyIssue(emptyIssue)
      .then((resp) =>
        dispatch(
          addTab(
            COMPONENTS.ISSUE_CARD,
            { issueId: resp.data.id },
            { defaultActiveTab: ISSUE_TABS.messages, issueId: resp.data.id }
          )
        )
      )
      .catch((err) => dispatch(errorHandling(err, ISSUE_CREATION_ERROR)));
}

export function getAppealByID(ussueId) {
  return async (dispatch) =>
    await ISSUES_API.getAppealData(ussueId)
      .then((resp) => {
        dispatch({
          type: APPEAL_TYPES.GET_APPEAL_DATA,
          payload: resp.data,
        });
      })
      .catch((err) => {
        dispatch(errorHandling(err, ISSUE_GETTING_ERROR));
      });
}

export function changeAppealOperator(ussueId, operatorID) {
  return async (dispatch) =>
    await ISSUES_API.setAppealOperator(ussueId, operatorID)
      .then(() => dispatch({ type: APPEAL_TYPES.APPEAL_CHANGE_OPERATOR }))
      .catch((err) =>
        dispatch(errorHandling(err, ISSUE_OPERATOR_CHANGING_ERROR))
      );
}

export function editAppealDescription(ussueId, desc) {
  return async (dispatch) =>
    await ISSUES_API.setAppealDescription(ussueId, desc)
      .then(() => dispatch({ type: APPEAL_TYPES.APPEAL_EDIT_DESCRIPTION }))
      .catch((err) =>
        dispatch(errorHandling(err, ISSUE_DESCRIPTION_EDITING_ERROR))
      );
}

export function getAppealRelatedTasks(ussueId) {
  return async (dispatch) =>
    await ISSUES_API.getRelatedTasks(ussueId)
      .then((resp) =>
        dispatch({
          type: APPEAL_TYPES.GET_RELATED_TASKS,
          payload: resp.data,
        })
      )
      .catch((err) =>
        dispatch(errorHandling(err, GETTING_RELATED_TASKS_ERROR))
      );
}

export function uploadAppealFiles(ussueId, filesIDs) {
  return async (dispatch) =>
    await ISSUES_API.uploadAppealFiles(ussueId, filesIDs)
      .then(() => dispatch({ type: APPEAL_TYPES.APPEAL_UPLOADING_FILES }))
      .catch((err) => dispatch(errorHandling(err, FILE_UPLOADING_ERROR)));
}

export function getAppealChanges(ussueId) {
  return async (dispatch) =>
    await ISSUES_API.getAppealChanges(ussueId)
      .then((resp) =>
        dispatch({
          type: APPEAL_TYPES.GET_APPEAL_CHANGES,
          payload: resp.data,
        })
      )
      .catch((err) => dispatch(errorHandling(err, "")));
}

const ISSUE_ACTIONS = {
  getIssues,
  createEmptyIssue,
};

export default ISSUE_ACTIONS;
