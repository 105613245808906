import React from 'react';

import EmployeesOptGroup from './employees';
import UsersOptGroup from './users';
import OperatorsOptGroup from './operators';
import GroupsOptGroup from './groups';

import { getUsersByQuery, getUserData } from '../../api/users';
import {
    getOperatorsList,
    getOperatorsGroupsList,
    getOperatorData,
    getOperatorsGroupData,
} from '../../api/operators';

// Типы искомых данных
const employee = 'employee';
const user = 'user';
const operator = 'operator';
const group = 'group';

export const TYPES = {
    employee,
    user,
    operator,
    group
};

export const TYPES_ARR = [
    employee,
    user,
    operator,
    group,
];

export const SEARCH_REQUESTS = {
    employee: (query) => getUsersByQuery(query).then(resp => ({ data: resp.data.employees })),
    user: (query) => getUsersByQuery(query).then(resp => ({ data: resp.data.users })),
    operator: getOperatorsList,
    group: getOperatorsGroupsList,
};

export const OPT_GROUPS = {
    employee: (employees, onClick, key) => <EmployeesOptGroup employees={employees} onClick={onClick} key={key} />,
    user: (users, onClick, key) => <UsersOptGroup users={users} onClick={onClick} key={key} />,
    operator: (operators, onClick, key) => <OperatorsOptGroup operators={operators} onClick={onClick} key={key} />,
    group: (groups, onClick, key) => <GroupsOptGroup groups={groups} onClick={onClick} key={key} />
}

export const SEARCH_BY_ID = {
    user: getUserData,
    operator: getOperatorData,
    group: getOperatorsGroupData,
}