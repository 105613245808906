import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";

import AccessDenied, {
  ACCESS_DENIED_TYPE,
} from "../../components/AccessDenied";
import Tab from "../../components/Tab";

import TAB_ACTIONS from "../../actions/tabs/tabs";
import useRBAC from "../rbac";

import "./style.scss";

function ComponentNotFound() {
  return (
    <AccessDenied>
      <h4>Компонент не найден</h4>
    </AccessDenied>
  );
}

const Tabs = () => {
  const dispatch = useDispatch();

  // Store
  const { list, active } = useSelector((store) => store.tabs);
  const { startPath } = useSelector((store) => store.app);

  // Hooks
  const { checkAccessToTab } = useRBAC();

  const setTabError = useCallback(
    (tab, error) => {
      dispatch(TAB_ACTIONS.setTabError(tab, error));
    },
    [dispatch]
  );

  // componentDidMount
  useEffect(() => {
    dispatch(TAB_ACTIONS.tabsInitialization(startPath));
  }, [dispatch, startPath]);

  function drawComponent(tab, tabIndex) {
    const { component, uuid, error } = tab;
    const { component: Component, props, name } = component;
    const active = { uuid, index: tabIndex };

    // Проверка прав пользователя
    if (!checkAccessToTab(name))
      return <AccessDenied type={ACCESS_DENIED_TYPE.noPermissions} />;

    if (React.isValidElement(<Component />)) {
      return (
        <Component
          error={error}
          tabUuid={active.uuid}
          componentProps={props}
          removeTab={() => dispatch(TAB_ACTIONS.removeTab(active))}
          setComponentProps={(props) =>
            dispatch(TAB_ACTIONS.setTabComponentProps(active, props))
          }
          setTabError={(error) => setTabError(active, error)}
        />
      );
    } else {
      return <ComponentNotFound />;
    }
  }

  if (!Array.isArray(list)) return null;
  if (list.length <= 0)
    return (
      <AccessDenied>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <h4>Навигация по приложению:</h4>
          <ul>
            <li>Открыть ссылку в новой вкладке - щелчок левой кнопкой мыши</li>
            <li>
              Открыть ссылку внутри текущей вкладки - двойной щелчок левой
              кнопкой мыши
            </li>
          </ul>
        </div>
      </AccessDenied>
    );

  const activeTab = list[active.index];

  return (
    <div className="tabs-container">
      <div className="tabs-container__tabs-list">
        {list.map((tab, index) => {
          const isActive = tab.uuid === active.uuid;
          const onSetActive = isActive
            ? () => {}
            : () =>
                dispatch(
                  TAB_ACTIONS.setActiveTab({
                    url: tab.url,
                    uuid: tab.uuid,
                    index,
                  })
                );

          return (
            <Tab
              key={tab.uuid}
              isActive={isActive}
              title={tab.title}
              onClose={() =>
                dispatch(TAB_ACTIONS.removeTab({ uuid: tab.uuid, index }))
              }
              onSetActive={onSetActive}
            />
          );
        })}
      </div>
      <div className="tabs-container__acive-tab-content">
        {drawComponent(activeTab, active.index)}
      </div>
    </div>
  );
};

export {
  // COMPONENTS,
  TAB_TITLE_DICT,
  COMPONENT_DICTIONARY,
  getComponentByUrl,
  getComponentUrl,
  getComponent,
  getTabTitle,
} from "./componentDict";
export { COMPONENTS } from "../../consts";
export { useTabs } from "./tabs.hook";

export default Tabs;
