import { createStore, applyMiddleware } from "redux";
import logger from "redux-logger";
import thunk from "redux-thunk";

import { rootReducer } from "../reducers";
import { FRONT_ENV } from "../consts";

//export const store = createStore(rootReducer, applyMiddleware(thunk, logger) )

export function configureStore(history) {
  if (process.env.NODE_ENV === FRONT_ENV.production) {
    return createStore(rootReducer(history), applyMiddleware(thunk));
  } else {
    return createStore(rootReducer(history), applyMiddleware(thunk, logger));
  }
}
