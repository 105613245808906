import React from "react";
import { CameraOutlined } from "@ant-design/icons";

import "./style.scss";

const ImageFallback = () => {
  return (
    <div className="component_image-fallback">
      <CameraOutlined className="image-fallback__icon" />
      <span className="image-fallback__text">{`Изображение\nотсутствует`}</span>
    </div>
  );
};

export default ImageFallback;
