import { useCallback } from "react";
import { useDispatch } from "react-redux";

import TEMPLATES_API from "../api/templates";
import ERROR_ACTIONS from "../actions/errors/errors";

const useTemplate = () => {
  const dispatch = useDispatch();

  const getTemplates = useCallback(async () => {
    try {
      const response = await TEMPLATES_API.getTemplateList();
      return response.data;
    } catch (err) {
      console.error(err);
      dispatch(
        ERROR_ACTIONS.addError({
          title: "Не удалось получить список шаблонов",
          message: err.response.data?.message,
          status: err.response.status,
        })
      );
      return [];
    }
  }, [dispatch]);

  return {
    getTemplates,
  };
};

export default useTemplate;
