import React, { useEffect, useCallback } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { Layout, Drawer } from "antd";
import { useDispatch, useSelector } from "react-redux";

// import AccessDenied, { ACCESS_DENIED_TYPE } from "../AccessDenied";
import LoginPage from "../../containers/LoginPage";
import NotificationsService from "../../services/notifications";
import SideMenu from "../../components/SideMenu";
import Tabs from "../../services/tabs";
import Notifications from "../Notifications";
import Spinner from "../Spinner";

import AUTH_ACTIONS from "../../actions/auth/auth";
import APP_ACTIONS from "../../actions/app/app";
import {
  SET_NOTIFICATION_LIST_VISIBLE,
  SET_NOTIFICATION_LIST,
  SET_NOTIFICATION_LIST_OFFSET,
  SET_NOTIFICATION_LIST_LOADMORE,
} from "../../actions/notification/actionTypes";
import NOTIFICATIONS, { getParamsToOpenTab } from "../../services/notifications/types";
import useOperatorNotifications from "../../hooks/operatorNotifications";
import { useTabs } from "../../services/tabs";
import useFaviconBlick from "../../hooks/useFaviconBlick";

import PATHS from "../../paths";
import NewMessageFavicon from "../../assets/favicons/new-message/new-message.png";

import "./style.scss";

const NOTIFICATIONS_LIMIT = 20;

const Protected = () => {
  const dispatch = useDispatch();

  // Store
  const {
    newMessages,
    openTasks,
    openIssues,
    openMassProblems,
    notificationList,
    notificationListOffset,
    notificationListVisible,
  } = useSelector((store) => store.notifications);
  const userName = useSelector(
    (store) => `${store.user.lastName} ${store.user.firstName}`
  );
  const { faviconBlickIntervalId } = useSelector((store) => store.app);

  // Hooks
  const { clickHandler } = useTabs();
  const { getNotifications, readNotificationsRequest, clearAllNotificationsRequest } =
    useOperatorNotifications();
  const { setDefaultFavicon, setFaviconBlickInterval } = useFaviconBlick();

  const getNotificationData = async (notification) => {
    const { type } = notification;

    try {
      const _getNotificationData = NOTIFICATIONS[type];
      const _notificationData = await _getNotificationData({
        ...notification,
        onOpenTab: () => {
          clickHandler(getParamsToOpenTab(notification));
          dispatch({ type: SET_NOTIFICATION_LIST_VISIBLE, payload: false });
        },
      });

      return _notificationData;
    } catch {
      return undefined;
    }
  };

  const getNotificationsList = async (offset, notifications = []) => {
    try {
      const response = await getNotifications(NOTIFICATIONS_LIMIT, offset);

      if (response) {
        const promises = response.notifications.map(async (notification) => {
          const { data, ...rest } = notification;
          const notificationData = await getNotificationData({
            ...rest,
            ...data,
          });

          return notificationData ? { ...notificationData, ...data, ...rest } : null;
        });

        const notificationsWithData = await Promise.all(promises)
          .then((notifications) => notifications)
          .catch(() => []);

        dispatch({
          type: SET_NOTIFICATION_LIST_OFFSET,
          payload: response.offset,
        });
        dispatch({
          type: SET_NOTIFICATION_LIST,
          payload: [...notifications, ...notificationsWithData],
        });
        dispatch({
          type: SET_NOTIFICATION_LIST_LOADMORE,
          payload: response.loadMore,
        });
      }
    } catch {}
  };

  const showNotificationsList = async (notifications, offset) => {
    if (!notifications || notifications.length === 0) {
      await getNotificationsList(offset, notifications);
    }

    await dispatch({ type: SET_NOTIFICATION_LIST_VISIBLE, payload: true });
  };

  const hideNotificationsList = useCallback(
    async (notifications) => {
      const notificationIds = notifications.map((_n) => _n.id);

      await readNotificationsRequest(notificationIds);

      await dispatch({ type: SET_NOTIFICATION_LIST, payload: [] });
      await dispatch({ type: SET_NOTIFICATION_LIST_OFFSET, payload: 0 });
    },
    [readNotificationsRequest, dispatch]
  );

  const clearNotificationList = async () => {
    try {
      await clearAllNotificationsRequest();

      await dispatch({ type: SET_NOTIFICATION_LIST, payload: [] });
      await dispatch({ type: SET_NOTIFICATION_LIST_OFFSET, payload: 0 });
      await dispatch({ type: SET_NOTIFICATION_LIST_VISIBLE, payload: false });
    } catch {}
  };

  /** Управляем favicon. Включаем мигание, если есть непрочитанные сообщения */
  useEffect(() => {
    if (Array.isArray(newMessages) && newMessages.length > 0) {
      if (faviconBlickIntervalId === null) {
        const intervalId = setFaviconBlickInterval(NewMessageFavicon);
        dispatch(APP_ACTIONS.setFaviconBlickIntervalId(intervalId));
      }
    } else {
      setDefaultFavicon();
      clearInterval(faviconBlickIntervalId);
      dispatch(APP_ACTIONS.setFaviconBlickIntervalId(null));
    }
  }, [
    newMessages,
    faviconBlickIntervalId,
    setDefaultFavicon,
    setFaviconBlickInterval,
    dispatch,
  ]);

  useEffect(() => {
    if (!notificationListVisible) {
      hideNotificationsList(notificationList);
    }
  }, [notificationListVisible, hideNotificationsList]);

  return (
    <Layout>
      <SideMenu
        userName={userName}
        logout={() => dispatch(AUTH_ACTIONS.logout())}
        newMessagesCounter={newMessages}
        openIssueCounter={openIssues}
        openTaskCounter={openTasks}
        openMassProblems={openMassProblems}
        showNotificationsList={() =>
          showNotificationsList(notificationList, notificationListOffset)
        }
      />

      <Layout style={{ position: "relative" }}>
        <Tabs />
        <NotificationsService />
      </Layout>

      <Drawer
        className="notifications-drawer"
        title="Уведомления"
        placement="left"
        bodyStyle={{ paddingTop: 0 }}
        visible={notificationListVisible}
        onClose={() => dispatch({ type: SET_NOTIFICATION_LIST_VISIBLE, payload: false })}
        children={
          <Notifications
            notifications={notificationList}
            getMoreNotifications={() =>
              getNotificationsList(notificationListOffset, notificationList)
            }
            onClearAll={() => clearNotificationList()}
          />
        }
      />
    </Layout>
  );
};

const Unprotected = () => {
  return (
    <Layout style={{ height: "100vh" }}>
      <Switch>
        <Route exact path={PATHS.ROOT} component={LoginPage} />
        <Route
          exact
          path="*"
          component={() => (
            <Redirect to={PATHS.ROOT} />
            // <AccessDenied type={ACCESS_DENIED_TYPE.pageNotFound} />
          )}
        />
      </Switch>
    </Layout>
  );
};

const Suspense = () => {
  return (
    <Layout className="component_app-layout app-layout_suspense">
      <Spinner />
    </Layout>
  );
};

const AppLayout = {
  Protected,
  Unprotected,
  Suspense,
};

export default AppLayout;
