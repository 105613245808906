import React from "react";
import { Typography } from "antd";

import AppVersion from "../AppVersion";

import "./style.scss";

const props = {
  level: 1,
  style: {
    fontSize: 24,
    fontWeight: "bold",
    color: "#fff",
    textAlign: "center",
    margin: 0,
  },
};

const Full = ({ appVersion }) => {
  return (
    <div className="component_logo logo_full">
      <Typography.Title {...props}>HelpDesk</Typography.Title>
      <AppVersion version={appVersion} />
    </div>
  );
};

const Short = ({ appVersion }) => {
  return (
    <div className="component_logo logo_short">
      <Typography.Title {...props}>HD</Typography.Title>
      <AppVersion version={appVersion} />
    </div>
  );
};

const Logo = {
  Full,
  Short,
};

export default Logo;
