import React from "react";
import { Button } from "antd";

const GoTo = ({ onClick }) => {
  return (
    <Button type="primary" onClick={onClick}>
      Перейти к обращению
    </Button>
  );
};

export default ({ onOpenTab }) => ({
  message: "Все задачи по обращению закрыты",
  btn: <GoTo onClick={onOpenTab} />,
});
