import { useCallback } from "react";

const useCopy = () => {
  const copy = useCallback((value) => {
    navigator.clipboard.writeText(value);
  }, []);

  return { copy };
};

export default useCopy;
