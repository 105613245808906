import React, { useState, useEffect, useCallback } from "react";
import { Space, Checkbox, Button, Select } from "antd";

import SearchFilters, {
  FilterContainer,
} from "../../components/SearchPreciseParams/index2";
import OperatorCustomFilters, {
  CustomFilterSaveModal,
} from "../../components/OperatorCustomFilters";

import useOperatorCustomFilters from "../../hooks/operatorCustomFilters";

import ISSUE_LIST_MODEL, { FILTER_NAMES } from "./model";

const { Option, OptGroup } = Select;

const SELECT_PROPS = {
  value: [],
  allowClear: true,
  showSearch: true,
  style: { width: 260 },
  size: "small",
  placeholder: "Поиск",
  optionFilterProp: "children",
};

const Filters = (props) => {
  const {
    onResetFilters,
    onSaveFilters,
    customFilters,
    filters,
    defaultFilters,
    operatorFilters,
    onChangeFilters,
  } = props;

  const { onlyOpen, performers, fromOperator } = filters;

  // State
  const [modalVisible, setModalVisible] = useState(false);
  const [saveButtonVisible, setSaveButtonVisible] = useState(false);

  // Hooks
  const { filterHasChanges } = useOperatorCustomFilters();

  const findFilter = useCallback((filters, selected) => {
    return filters.find((filter) => filter.value === selected);
  }, []);

  const onSave = async (name) => {
    try {
      await onSaveFilters(name);
      await setModalVisible(false);
    } catch {}
  };

  // Проверяем изменения пользовательского фильтра
  useEffect(() => {
    const selectedFilterValues = {
      [FILTER_NAMES.onlyOpen]: onlyOpen.value,
      [FILTER_NAMES.performers]: performers.value,
      [FILTER_NAMES.fromOperator]: fromOperator.value,
    };

    if (customFilters.selected) {
      const _selectedFilter = findFilter(
        customFilters.options,
        customFilters.selected
      );

      setSaveButtonVisible(
        filterHasChanges(selectedFilterValues, _selectedFilter?.filter)
      );
    } else {
      const isDefaultFilter = !filterHasChanges(
        selectedFilterValues,
        defaultFilters
      );
      const isOperatorFilter = !filterHasChanges(
        selectedFilterValues,
        operatorFilters
      );

      setSaveButtonVisible(!(isDefaultFilter || isOperatorFilter));
    }
  }, [
    defaultFilters,
    operatorFilters,
    fromOperator.value,
    onlyOpen.value,
    performers.value,
    customFilters.options,
    customFilters.selected,
    filterHasChanges,
    findFilter,
  ]);

  return (
    <>
      <SearchFilters
        onResetFilters={onResetFilters}
        onSaveFilters={
          saveButtonVisible ? () => setModalVisible(true) : undefined
        }
      >
        <FilterContainer label="Пользовательские фильтры">
          <Space direction="vertical">
            <Button
              size="small"
              type="primary"
              children="Мои обращения"
              onClick={() =>
                customFilters.onChange(
                  undefined,
                  JSON.stringify(operatorFilters)
                )
              }
            />
            <OperatorCustomFilters
              selectProps={{
                ...SELECT_PROPS,
                allowClear: true,
                optionFilterProp: "label",
              }}
              selectedFilter={customFilters.selected}
              customFilters={customFilters.options}
              onSelectFilter={(id, option) =>
                customFilters.onChange(id, option?.filter)
              }
            />
          </Space>
        </FilterContainer>
        <FilterContainer
          label="Исполнитель"
          selected={performers.value}
          onRemoveSelected={(key) => {
            const { [key]: _, ...rest } = performers.value;

            onChangeFilters(FILTER_NAMES.performers, rest);
          }}
        >
          <Select
            {...SELECT_PROPS}
            onSelect={(value, option) =>
              onChangeFilters(FILTER_NAMES.performers, {
                ...performers.value,
                [option.key]: { value, title: option.children },
              })
            }
          >
            <OptGroup label="Операторы">
              {performers.options.operators.map((operator) => (
                <Option key={operator.id} value={operator.value}>
                  {operator.title}
                </Option>
              ))}
            </OptGroup>
            <OptGroup label="Группы операторов">
              {performers.options.groups.map((group) => (
                <Option key={group.id} value={group.value}>
                  {group.title}
                </Option>
              ))}
            </OptGroup>
          </Select>
        </FilterContainer>
        <FilterContainer label="От оператора">
          <Select
            {...SELECT_PROPS}
            value={fromOperator.value}
            onSelect={(fromOperator) =>
              onChangeFilters(FILTER_NAMES.fromOperator, fromOperator)
            }
            onClear={() =>
              onChangeFilters(
                FILTER_NAMES.fromOperator,
                ISSUE_LIST_MODEL.filter.fromOperator
              )
            }
          >
            <OptGroup label="Операторы">
              {fromOperator.options.map((operator) => (
                <Option key={operator.id} value={operator.value}>
                  {operator.title}
                </Option>
              ))}
            </OptGroup>
          </Select>
        </FilterContainer>
        <FilterContainer>
          <Checkbox
            checked={onlyOpen.value}
            onChange={(event) =>
              onChangeFilters(FILTER_NAMES.onlyOpen, event.target.checked)
            }
            children="Только открытые"
          />
        </FilterContainer>
      </SearchFilters>
      <CustomFilterSaveModal
        visible={modalVisible}
        defaultFilterName={
          findFilter(customFilters.options, customFilters.selected)?.label
        }
        onOk={onSave}
        onCancel={() => setModalVisible(false)}
      />
    </>
  );
};

export default Filters;
