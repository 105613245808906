import React from "react";

import { TYPES } from "./consts";

const groupsOptGroup = (props) => {
  const { groups, onClick } = props;

  if (!Array.isArray(groups) || groups.length === 0) {
    return (
      <div className="options-list__optgroup">
        Группы операторов
        <button disabled className="optgroup__option_undefined">
          Группы не найдены
        </button>
      </div>
    );
  }

  groups.forEach((group) => (group.type = TYPES.group));

  return (
    <div className="options-list__optgroup">
      Группы операторов
      {groups.map((group) => (
        <button
          type="button"
          onClick={() => onClick(group.title, JSON.stringify(group))}
          key={group.id}
          className="optgroup__option"
        >
          {group.title}
        </button>
      ))}
    </div>
  );
};

export default groupsOptGroup;
