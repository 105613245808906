import { store } from "../../";
import actionTypes from "./actionTypes";
import errorHandling from "../../services/errors/errorHandling";
import {
  DIALOGS_GETTING_ERROR,
  USER_MESSAGES_GETTING_ERROR,
} from "../../actions/errors/actionTypes";

import { getActualDialogues, getUserMessages, getMessage } from "../../api/chatHTTP";
import { getFileByID } from "../../api/files";
import { getOperatorAppeals } from "../../api/appeals";
import { getUserData, getUserPhoto } from "../../api/users";
import { getOperatorData } from "../../api/operators";
import fileReader from "../../utils/fileReader";
import { messageFrom } from "../../components/Message/consts";

export function getDialogues(operatorID) {
  return async (dispatch) => {
    let usersIDs = [];

    await getActualDialogues()
      .then((resp) => {
        if (resp.status === 200) {
          usersIDs = resp.data;
        }
      })
      .catch((err) => dispatch(errorHandling(err, "")));

    await getOperatorAppeals(operatorID)
      .then((resp) => {
        if (resp.status === 200) {
          const users = resp.data.map((dialog) => dialog.user_id);
          const uniqueIDs = new Set(users);
          usersIDs = [...usersIDs, ...uniqueIDs];
        }
      })
      .catch();

    const dialogs = usersIDs.map(async (id) => await getDialogData(id));

    return Promise.all(dialogs)
      .then((dialogs) =>
        dispatch({ type: actionTypes.GET_ACTIVE_DIALOGS, payload: dialogs })
      )
      .catch((err) => errorHandling(err, DIALOGS_GETTING_ERROR));
  };
}

export function selectDialog(userID) {
  const { dialogs } = store.getState().messages;
  const dialogsArray = [...dialogs];

  if (dialogsArray.length === 0) {
    return {
      type: actionTypes.SELECT_DIALOG,
      payload: { selectedDialog: { userID } },
    };
  }

  dialogsArray.forEach((dialog) => (dialog.selected = false));
  const selectedDialog = dialogsArray.find((dialog) => dialog.userID === userID);
  selectedDialog.selected = true;

  return {
    type: actionTypes.SELECT_DIALOG,
    payload: { dialogs: dialogsArray, selectedDialog },
  };
}

export function getMessages(userID, offset, limit) {
  return async (dispatch) =>
    await getUserMessages(userID, offset, limit)
      .then((resp) => {
        return resp.status === 200 ? resp.data : Promise.reject();
      })
      .then((messages) => messages.map((message) => getMessageData(message)))
      .then((result) => Promise.all(result))
      .then((messages) => dispatch({ type: actionTypes.GET_MESSAGES, payload: messages }))
      .catch(() => dispatch(errorHandling("", USER_MESSAGES_GETTING_ERROR)));
}

export function getNewUserMessage(messageID) {
  return async (dispatch) => {
    await getMessage(messageID)
      .then((resp) => {
        return resp.status === 200 ? Promise.resolve(resp.data) : Promise.reject();
      })
      .then(async (message) => await getMessageData(message))
      .then((message) => dispatch({ type: actionTypes.NEW_MESSAGE, payload: message }))
      .catch(() => dispatch(errorHandling("", USER_MESSAGES_GETTING_ERROR)));
  };
}

export function sendMessage(data) {
  return {
    type: actionTypes.SEND_MESSAGE,
    payload: { ...data },
  };
}

async function getMessageData(message) {
  const { id: curOpID } = store.getState().user;
  const { user_id, operator_id, created_at, file } = message;
  const messageData = {
    userID: user_id,
    authorID: null,
    authorName: null,
    messageID: message.id,
    createdAt: created_at,
    text: message.message,
  };

  if (operator_id) {
    await getOperatorData(operator_id).then(({ data }) => {
      messageData.authorName = `${data.last_name} ${data.first_name}`;
      messageData.authorID = operator_id;
      messageData.messageFrom =
        operator_id === curOpID ? messageFrom.currentOperator : messageFrom.others;
    });
  } else {
    await getUserData(user_id).then(({ data }) => {
      messageData.authorName = `${data.last_name} ${data.first_name}`;
      messageData.authorID = user_id;
      messageData.messageFrom = messageFrom.user;
    });
  }

  if (file) {
    await getFileByID(file.id)
      .then((blob) => {
        messageData.file = { size: blob.size, type: blob.type };
        return fileReader(blob);
      })
      .then((file) => {
        messageData.file.base64 = file;
      });
  }

  return messageData;
}

async function getMessageDataForMessangerTab(message) {
  const { id: curOpID, lastName, firstName } = store.getState().user;

  const messageData = {
    ...message,
    messageFrom: undefined,
  };

  if ("operator_id" in message) {
    const { operator_id } = message;

    if (operator_id === curOpID) {
      messageData.messageFrom = {
        id: curOpID,
        last_name: lastName,
        first_name: firstName,
      };
    } else {
      await getOperatorData(operator_id).then(({ data }) => {
        const { id, last_name, first_name } = data;

        messageData.messageFrom = {
          id,
          last_name,
          first_name,
        };
      });
    }
  } else {
    const { user_id } = message;

    await getUserData(user_id).then(({ data }) => {
      const { id, last_name, first_name } = data;

      messageData.messageFrom = {
        id,
        last_name,
        first_name,
      };
    });
  }

  if ("file" in message) {
    const { file } = message;

    await getFileByID(file.id)
      .then((blob) => {
        messageData.file = { size: blob.size, type: blob.type, id: file.id };
        return fileReader(blob);
      })
      .then((file) => {
        messageData.file.base64 = file;
      });
  }

  return messageData;
}

const defaultDialogueData = {
  id: "",
  user: {
    id: "",
    last_name: "",
    first_name: "",
    avatar: undefined,
  },
  messages: [],
  offset: 0,
  limit: 20,
  unread: false,
  error: undefined,
  loadMore: false,
  sending: false,
  fetching: false,
  inputtedText: "",
  attachedFiles: [],
  disabled: false,
  irrelevant: false,
  inWork: false,
};

const checkUnreadMessages = (dialogueId, unreadMessages = []) =>
  unreadMessages.findIndex((message) => message.user_id === dialogueId) !== -1;

async function getDialogData(userId, isSelected, params = {}) {
  const unreadMessages = store.getState().notifications.newMessages;

  const dialogData = {
    ...defaultDialogueData,
    ...params,
    id: userId,
    offset: 0,
    unread: checkUnreadMessages(userId, unreadMessages),
  };
  const messagesLimit = isSelected ? dialogData.limit : 1;

  await getUserData(userId).then(({ data }) => {
    const { first_name, last_name, current_pp_name } = data;
    dialogData.user = {
      ...dialogData.user,
      first_name,
      last_name,
      id: userId,
      current_pp_name,
    };
  });
  await getUserPhoto(userId)
    .then((resp) => {
      dialogData.user.avatar = URL.createObjectURL(resp.data);
    })
    .catch(() => {
      dialogData.user.avatar = undefined;
    });
  await getUserMessages(userId, dialogData.offset, messagesLimit).then(
    async ({ data }) => {
      if (data.length > 0) {
        dialogData.offset = data.length;

        await Promise.all(
          data.map(async (message) => await getMessageDataForMessangerTab(message))
        ).then((messages) => {
          dialogData.messages = messages;
          dialogData.loadMore = messagesLimit === messages.length;
        });
      }
    }
  );

  return dialogData;
}

const MESSAGES_ACTIONS = {
  getMessages,
  sendMessage,
  selectDialog,
  getDialogues,
  getDialogData,
  getMessageData,
  getNewUserMessage,
  getMessageDataForMessangerTab,
};

export default MESSAGES_ACTIONS;
