import React, { useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, message } from "antd";
import isEqual from "lodash.isequal";
import {
  ReloadOutlined,
  // ClockCircleOutlined
} from "@ant-design/icons";

import MainContainer from "../../components/MainContainer";
import Table from "../../components/Table";
import Filters from "./filters";
import Pagination from "../../components/Pagination";

import TASK_LIST_MODEL, { FILTER_NAMES } from "./model";
import ERROR_ACTIONS from "../../actions/errors/errors";
import { TYPES } from "../../components/Search_new/consts";
import { REALMS_OF_CUSTOM_FILTERS } from "../../consts";
import { useTabs, COMPONENTS } from "../../services/tabs";
import useRBAC, { UI_PERMISSIONS } from "../../services/rbac";
import useSearch, {
  FILTERS,
  EXISTENCE,
  PAGE_SIZES,
  COMPARISONS,
  SORT_TYPES,
  SORT_DIRECTION,
} from "../../hooks/search.hook";
import OPERATORS_API from "../../api/operators";
import LOCATIONS_API from "../../api/locations";
import useTemplate from "../../hooks/useTemplate.hook";
import usePagination from "../../hooks/pagination.hook";
import useOperatorCustomFilters from "../../hooks/operatorCustomFilters";

import "./style.scss";

const TaskList = (props) => {
  const { componentProps, setComponentProps, error, setTabError } = props;
  const {
    filter,
    tasks,
    totalResults,
    searching,
    operators,
    groups,
    pagination,
    customFilters,
    templates,
    locations,
  } = componentProps;
  const {
    onlyOpen,
    performers,
    fromOperator,
    onlyUrgency,
    templateId,
    locationId,
    onlyWithoutOperator,
  } = filter;
  const { page, pageSize } = pagination;

  const dispatch = useDispatch();

  // Store
  const {
    id: currentOperatorId,
    firstName,
    lastName,
  } = useSelector((store) => store.user);

  // Hooks
  const { clickHandler } = useTabs();
  const { checkPermissionToRenderUI } = useRBAC();
  const { onSearch, getFullDataOfSearchResults } = useSearch();
  const { getCustomFilters, createFilter, editFilter } = useOperatorCustomFilters();
  const { getPaginationRange, totalPageCount } = usePagination({
    currentPage: page,
    pageSize,
    totalCount: totalResults,
  });
  const { getTemplates } = useTemplate();

  const DEFAULT_COMPONENT_PROPS = {
    ...TASK_LIST_MODEL,
    filter: {
      ...TASK_LIST_MODEL.filter,
      onlyOpen: true,
      fromOperator: JSON.stringify({
        id: currentOperatorId,
        type: TYPES.operator,
      }),
      onlyUrgency: false,
    },
    pagination: {
      page: 1,
      pageSize: PAGE_SIZES[0].value,
    },
  };

  /**
   *
   * Обратная совместимость для шаблонов пользовательских фильтров
   *
   * @param {string|object} customFilter - Пользовательский фильтр
   *
   */

  const templateCustomFilterBackwardCompatibility = (customFilter) => {
    // Старый вариант с возможностью выбора только одного шаблона
    if (typeof customFilter === "string") {
      const _template = templates.find((_t) => _t.value === customFilter);

      if (_template) {
        return { [customFilter]: { value: customFilter, title: _template.label } };
      } else {
        return {
          [customFilter]: { value: customFilter, title: "Название фильтра не найдено" },
        };
      }
    }

    // Новый вариант с выбором нескольких
    if (typeof customFilter === "object" && customFilter !== null) {
      return customFilter;
    }

    return TASK_LIST_MODEL.filter.templateId;
  };

  async function getOperators() {
    await OPERATORS_API.getOperatorsList()
      .then((resp) =>
        setComponentProps({
          operators: resp.data
            .map((operator) => ({
              id: operator.id,
              value: JSON.stringify({ id: operator.id, type: TYPES.operator }),
              title: `${operator.last_name} ${operator.first_name}`,
            }))
            .sort((a, b) => sortFunc(a.title, b.title)),
        })
      )
      .catch((err) => {
        console.error(err);
      });
  }

  async function getGroups() {
    await OPERATORS_API.getOperatorsGroupsList()
      .then((resp) =>
        setComponentProps({
          groups: resp.data
            .map((group) => ({
              id: group.id,
              value: JSON.stringify({ id: group.id, type: TYPES.group }),
              title: group.title,
            }))
            .sort((a, b) => sortFunc(a.title, b.title)),
        })
      )
      .catch((err) => {
        console.error(err);
      });
  }

  const getLocations = useCallback(async () => {
    try {
      const response = await LOCATIONS_API.getLocations();
      return response.data;
    } catch (err) {
      console.error(err);
      dispatch(
        ERROR_ACTIONS.addError({
          message: "Не удалось получить список локаций",
          message: err.response.data?.message,
          status: err.response.status,
        })
      );
      return [];
    }
  }, [dispatch]);

  function sortFunc(a, b) {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  }

  function getFromParam(page, size) {
    return (page - 1) * size;
  }

  function getTotal(searchResults = [], page = 1, pageSize = 0, totalResults = 0) {
    if (totalResults === 0) return `Показано ${totalResults}`;

    return `Показано ${(page - 1) * pageSize + 1} - ${
      (page - 1) * pageSize + searchResults.length
    } из ${totalResults}`;
  }

  function getPerformers(performers, onlyWithoutOperator = false) {
    function getFilterType(performerType) {
      switch (performerType) {
        case TYPES.operator:
          return FILTERS.operator_id;
        case TYPES.group:
          return FILTERS.operators_group_id;
        default:
          return;
      }
    }

    const filtersObj = Object.keys(performers).reduce((acc, key) => {
      try {
        const performer = JSON.parse(performers[key].value);
        const filterType = getFilterType(performer.type);

        if (!filterType) throw Error("TaskList: performer type not found");

        // Не добавяем операторов, если выбран чекбокс "Только свободные"
        if (filterType === FILTERS.operator_id && onlyWithoutOperator) {
          return acc;
        }

        if (filterType in acc) {
          const _acc = { ...acc };

          _acc[filterType].selected.push({
            value: performer.id,
            comparison: COMPARISONS.eq.value,
          });

          return _acc;
        } else {
          return {
            ...acc,
            [filterType]: {
              selected: [{ value: performer.id, comparison: COMPARISONS.eq.value }],
            },
          };
        }
      } catch (err) {
        console.error(err);
        return acc;
      }
    }, {});

    return Object.keys(filtersObj).reduce(
      (acc, key) => [...acc, { ...filtersObj[key], value: key }],
      []
    );
  }

  async function getTasks(filters, page, pageSize) {
    const {
      onlyOpen,
      performers = {},
      fromOperator,
      onlyUrgency,
      templateId,
      locationId,
      onlyWithoutOperator,
    } = filters;

    const searchParams = {
      filters: [
        {
          value: FILTERS.type,
          selected: [{ value: "task", comparison: COMPARISONS.eq.value }],
        },
      ],
      sort: { value: SORT_TYPES.createdAt, direction: SORT_DIRECTION.desc },
      from: getFromParam(page, pageSize),
      size: pageSize,
    };

    if (fromOperator) {
      searchParams.filters.push({
        value: FILTERS.from_operator_id,
        selected: [
          {
            value: JSON.parse(fromOperator).id,
            comparison: COMPARISONS.eq.value,
          },
        ],
      });
    }
    if (Object.keys(performers).length > 0) {
      searchParams.filters.push(...getPerformers(performers, onlyWithoutOperator));
    }
    if (onlyOpen) {
      searchParams.filters.push({
        value: FILTERS.done_at,
        existence: { selected: EXISTENCE.nex.value },
      });
    }
    if (onlyUrgency) {
      searchParams.filters.push({
        value: FILTERS.urgency,
        selected: [{ value: true, comparison: COMPARISONS.eq.value }],
      });
    }
    if (Object.keys(templateId).length > 0) {
      searchParams.filters.push({
        value: FILTERS.template_id,
        selected: Object.keys(templateId).reduce(
          (acc, cur) => [...acc, { value: cur, comparison: COMPARISONS.eq.value }],
          []
        ),
      });
    }
    if (Object.keys(locationId).length > 0) {
      searchParams.filters.push({
        value: FILTERS.location_id,
        selected: Object.keys(locationId).reduce(
          (acc, cur) => [...acc, { value: cur, comparison: COMPARISONS.eq.value }],
          []
        ),
      });
    }
    if (onlyWithoutOperator) {
      searchParams.filters.push({
        value: FILTERS.operator_id,
        existence: {
          selected: EXISTENCE.nex.value,
        },
      });
    }

    await setComponentProps({ searching: true });

    try {
      const searchResults = await onSearch(searchParams);
      const { total_hits, hits } = searchResults;
      const searchResultsFullData = await getFullDataOfSearchResults(hits);

      setComponentProps({
        tasks: searchResultsFullData,
        totalResults: total_hits,
      });
    } catch (err) {
      setTabError({
        title: "Ошибка получения задач",
        message: err.response?.data?.message,
      });
    }

    await setComponentProps({ searching: false });
  }

  function openTaskCard(e, task) {
    e.preventDefault();
    e.stopPropagation();

    clickHandler({
      component: COMPONENTS.TASK_CARD,
      params: { taskId: task.id },
      locationState: { taskId: task.id },
    });
  }

  function checkTasks(tasks) {
    if (Array.isArray(tasks)) {
      return tasks;
    } else {
      if (error === null) {
        setTabError({
          message: "Невозможно отобразить список задач. Получен неверный формат данных",
        });
      }

      return [];
    }
  }

  function getTableTitle(tasks) {
    if (!Array.isArray(tasks)) return null;

    // const openTasksCount = tasks.filter((task) => !task.done_at).length;

    return (
      <div className="task-list__table-title">
        <span>{getTotal(tasks, page, pageSize, totalResults)}</span>
        <Button
          title="Обновить"
          className="table-title__button table-title__button_refresh"
          type="text"
          icon={<ReloadOutlined />}
          onClick={() => setComponentProps({ filter: { ...filter, page: 1 } })}
        />
        {/* <ClockCircleOutlined className="title__icon title__icon_clock" />
        Открытых: */}
        <span className="title__open-task-count">{/* {openTasksCount} */}</span>
        {checkPermissionToRenderUI(UI_PERMISSIONS["ui:tasks:list:create-task"]) && (
          <Button
            className="button_create"
            type="primary"
            children="Создать задачу"
            onClick={() => clickHandler({ component: COMPONENTS.TASK_CREATION_FORM })}
          />
        )}
      </div>
    );
  }

  function onResetFilters() {
    setComponentProps({
      filter: DEFAULT_COMPONENT_PROPS.filter,
      customFilters: {
        ...customFilters,
        selected: DEFAULT_COMPONENT_PROPS.customFilters.selected,
      },
    });
  }

  function getOperatorFilter() {
    const value = JSON.stringify({
      id: currentOperatorId,
      type: TYPES.operator,
    });

    return {
      performers: {
        [currentOperatorId]: {
          title: `${lastName} ${firstName}`,
          value,
        },
      },
      fromOperator: TASK_LIST_MODEL.filter.fromOperator,
      onlyOpen: false,
      onlyUrgency: false,
      templateId: TASK_LIST_MODEL.filter.templateId,
      locationId: TASK_LIST_MODEL.filter.locationId,
    };
  }

  async function onSaveFilter(name, filters, filterId) {
    try {
      const filtersString = JSON.stringify(filters);

      if (filterId) {
        const _updateFilter = await editFilter(filterId, name, filtersString);

        setComponentProps({
          customFilters: {
            ...customFilters,
            options: customFilters.options.map((filter) =>
              filter.value === filterId ? _updateFilter : filter
            ),
          },
        });
      } else {
        const _newFilter = await createFilter(
          REALMS_OF_CUSTOM_FILTERS.tasks,
          name,
          filtersString
        );

        setComponentProps({
          customFilters: {
            ...customFilters,
            options: [...customFilters.options, _newFilter],
          },
        });
      }
    } catch (err) {
      console.error(err);
    }
  }

  // Выбрать пользовательский фильтр
  function onCustomFilterSelect(id, filter) {
    if (!id && !filter) {
      return setComponentProps({
        filter: { ...DEFAULT_COMPONENT_PROPS.filter },
        customFilters: { ...customFilters, selected: undefined },
      });
    }

    try {
      const parsedFilter = JSON.parse(filter);

      if (FILTER_NAMES.templateId in parsedFilter) {
        parsedFilter[FILTER_NAMES.templateId] = templateCustomFilterBackwardCompatibility(
          parsedFilter[FILTER_NAMES.templateId]
        );
      }

      setComponentProps({
        filter: {
          ...DEFAULT_COMPONENT_PROPS.filter,
          ...parsedFilter,
        },
        customFilters: { ...customFilters, selected: id },
      });
    } catch (err) {
      console.error(err);
      message.error("Не удалось применить фильтр");
    }
  }

  // initial state
  useEffect(() => {
    (async () => {
      if (isEqual(componentProps, TASK_LIST_MODEL)) {
        setComponentProps(DEFAULT_COMPONENT_PROPS);
      }

      getOperators();
      getGroups();

      const customFiltersOptions = await getCustomFilters(REALMS_OF_CUSTOM_FILTERS.tasks);
      const templates = await getTemplates();
      const locations = await getLocations();

      setComponentProps({
        customFilters: { ...customFilters, options: customFiltersOptions },
        templates: templates.map((_t) => ({ value: _t.id, label: _t.title })),
        locations: locations.map((_l) => ({ value: _l.id, label: _l.title })),
      });
    })();
  }, [getCustomFilters, getTemplates, getLocations]);

  // Получаем обращения при изменении фильтров или пагинации
  useEffect(() => {
    if (page !== TASK_LIST_MODEL.pagination.page) {
      getTasks(filter, page, pageSize);
    }
  }, [filter, page, pageSize]);

  useEffect(() => {
    if (
      page !== TASK_LIST_MODEL.pagination.page &&
      pageSize !== TASK_LIST_MODEL.pagination.pageSize
    ) {
      setComponentProps({
        pagination: {
          ...pagination,
          page: DEFAULT_COMPONENT_PROPS.pagination.page,
        },
      });
    }
  }, [filter]);

  return (
    <MainContainer
      className="page_task-list"
      bodyClassName="task-list__body"
      title="Задачи"
      error={error}
    >
      <Filters
        defaultFilters={DEFAULT_COMPONENT_PROPS.filter}
        operatorFilters={getOperatorFilter()}
        customFilters={{
          ...customFilters,
          onChange: onCustomFilterSelect,
        }}
        filters={{
          [FILTER_NAMES.onlyUrgency]: { value: onlyUrgency },
          [FILTER_NAMES.onlyOpen]: { value: onlyOpen },
          [FILTER_NAMES.fromOperator]: {
            options: operators,
            value: fromOperator,
          },
          [FILTER_NAMES.performers]: {
            options: { operators, groups },
            value: performers,
          },
          [FILTER_NAMES.templateId]: {
            options: templates,
            value: templateId,
          },
          [FILTER_NAMES.locationId]: {
            options: locations,
            value: locationId,
          },
          [FILTER_NAMES.onlyWithoutOperator]: {
            value: onlyWithoutOperator,
          },
        }}
        onChangeFilters={(filterName, value) =>
          setComponentProps({ filter: { ...filter, [filterName]: value } })
        }
        onResetFilters={onResetFilters}
        onSaveFilters={(name) =>
          onSaveFilter(
            name,
            {
              [FILTER_NAMES.onlyUrgency]: onlyUrgency,
              [FILTER_NAMES.onlyOpen]: onlyOpen,
              [FILTER_NAMES.fromOperator]: fromOperator,
              [FILTER_NAMES.performers]: performers,
              [FILTER_NAMES.templateId]: templateId,
              [FILTER_NAMES.locationId]: locationId,
              [FILTER_NAMES.onlyWithoutOperator]: onlyWithoutOperator,
            },
            customFilters.selected
          )
        }
      />

      <div className="table-wrapper">
        <Table
          scroll={{ y: "calc(100vh - 316px)" }}
          loading={searching}
          data={checkTasks(tasks)}
          onRowClick={openTaskCard}
          title={() => getTableTitle(tasks)}
          showSorterTooltip={false}
        />
        <Pagination
          className="task-list__pagination"
          size="small"
          range={getPaginationRange()}
          currentPage={page}
          pageSize={pageSize}
          totalPageCount={totalPageCount}
          onPageChange={(page) =>
            setComponentProps({ pagination: { ...pagination, page } })
          }
          onPageSizeChange={(pageSize) =>
            setComponentProps({ pagination: { ...pagination, pageSize } })
          }
        />
      </div>
    </MainContainer>
  );
};

export default TaskList;
