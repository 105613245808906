import React, { Component, useEffect, useRef } from "react";
import { Input, Button, Table } from "antd";
import {
  SearchOutlined,
  CheckCircleOutlined,
  ClockCircleOutlined,
  ExclamationOutlined,
} from "@ant-design/icons";
// import { InfinityTable as Table } from "antd-table-infinity";
import { Resizable } from "react-resizable";
import moment from "moment";

import Spinner from "../Spinner";
import normalizeDate, { DATE_TIME } from "../../utils/normalizeDate";

import "./style.scss";

/**
 @enum Стили таблицы
 */
export const TABLE_STYLE = {
  zebra: "zebra",
  status: "status",
};

const ResizableTitle = (props) => {
  const { onResize, width, ...restProps } = props;

  if (!width) {
    return <th {...restProps} />;
  }

  return (
    <Resizable
      width={width}
      height={0}
      onResize={onResize}
      draggableOpts={{ enableUserSelectHack: false }}
      handle={
        <span className="table__resize-column" onClick={(e) => e.stopPropagation()} />
      }
    >
      <th {...restProps} />
    </Resizable>
  );
};

const SearchDropdown = (props) => {
  const { onSearch, onChange, onReset } = props;
  const searchInput = useRef(null);

  useEffect(() => searchInput.current.focus());

  return (
    <div className="table__dropdown-filter">
      <Input
        placeholder="Поиск..."
        onChange={(e) => onChange(e.target.value ? [e.target.value] : [])}
        onPressEnter={onSearch}
        ref={searchInput}
      />
      <Button
        onClick={onSearch}
        icon={<SearchOutlined />}
        type="primary"
        size="small"
        children="Найти"
      />
      <Button onClick={onReset} size="small" type="text" children="Сбросить" />
    </div>
  );
};

function drawSpinner() {
  return (
    <div className="table__spinner-wrapper">
      <Spinner />
    </div>
  );
}

export function getRowIcon(doneAt, urgency = false) {
  if (doneAt) {
    return <CheckCircleOutlined className="row__check-icon" />;
  } else {
    if (urgency) {
      return <ExclamationOutlined className="row__exclamation-icon" />;
    } else {
      return <ClockCircleOutlined className="row__clock-icon" />;
    }
  }
}

const COLUMNS = [
  {
    dataIndex: "done_at",
    render: (text, row) => getRowIcon(text, row.urgency),
    width: 40,
    align: "center",
  },
  {
    title: "Дата",
    dataIndex: "created_at",
    width: 150,
    render: (date) => normalizeDate(date, DATE_TIME),
    sorter: {
      compare: (a, b) => moment(a.created_at).unix() - moment(b.created_at).unix(),
    },
  },
  {
    title: "Номер",
    dataIndex: "human_readable_id",
    width: 120,
    sorter: { compare: (a, b) => a.human_readable_id - b.human_readable_id },
    customFilter: true,
  },
  {
    title: "Пользователь",
    dataIndex: ["user", "full_name"],
    // render: (text, row) => (row.user ? text : "-"),
    width: 250,
    customFilter: true,
    ellipsis: true,
  },
  {
    title: "Описание",
    dataIndex: "description",
    ellipsis: true,
    customFilter: true,
  },
  {
    title: "Исполнитель",
    // dataIndex: ["operator", "full_name"],
    render: (text, row) =>
      row.operator
        ? `${row.operator.last_name} ${row.operator.first_name}`
        : row.operators_group.title,
    width: 250,
    // customFilter: true,
    ellipsis: true,
  },
];

const COMPONENTS = {
  header: {
    cell: ResizableTitle,
  },
};

class MainTable extends Component {
  constructor(props) {
    super(props);

    const columns = props.columns ? props.columns : COLUMNS;

    this.state = {
      loading: false,
      searchText: "",
      searchedColumn: "",
      columns: this.getColumnsProps(columns),
    };

    this.getColumnsProps = this.getColumnsProps.bind(this);
    this.getColumnResizeProps = this.getColumnResizeProps.bind(this);
    this.getColumnSearchProps = this.getColumnSearchProps.bind(this);
    this.handleResize = this.handleResize.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleReset = this.handleReset.bind(this);
    this.lazyLoading = this.lazyLoading.bind(this);
    this.getTableTitle = this.getTableTitle.bind(this);
    this.getRowClassName = this.getRowClassName.bind(this);
    this.getZebraTableStyle = this.getZebraTableStyle.bind(this);
    this.getStatusTableStyle = this.getStatusTableStyle.bind(this);
    this.searchInNestedObjects = this.searchInNestedObjects.bind(this);
    this.checkSubstring = this.checkSubstring.bind(this);
  }

  getTableTitle(title) {
    if (typeof title === "function") {
      return title;
    }
  }

  async lazyLoading() {
    const { onFetch } = this.props;

    if (!onFetch) return;

    this.setState({ loading: true });
    await onFetch();
    this.setState({ loading: false });
  }

  getColumnsProps(columns) {
    return columns.map((col, index) => {
      let filter;

      if (col.customFilter) {
        filter = this.getColumnSearchProps(col.dataIndex);
      }

      return {
        ...col,
        ...filter,
        ...this.getColumnResizeProps(index),
      };
    });
  }

  // Методы ресайза

  getColumnResizeProps(index) {
    return {
      onHeaderCell: (column) => ({
        width: column.width,
        onResize: this.handleResize(index),
      }),
    };
  }

  handleResize(index) {
    return (e, { size }) => {
      this.setState(({ columns }) => {
        const nextColumns = [...columns];
        nextColumns[index] = {
          ...nextColumns[index],
          width: size.width,
        };

        return { columns: nextColumns };
      });
    };
  }

  // Методы фильтра

  getColumnSearchProps(dataIndex) {
    return {
      filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
        <SearchDropdown
          onChange={setSelectedKeys}
          onSearch={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          onReset={() => this.handleReset(clearFilters)}
        />
      ),
      filterIcon: (filtered) => (
        <SearchOutlined className={`filter-icon ${filtered ? "filtered" : ""}`} />
      ),
      onFilter: (value, record) => {
        if (Array.isArray(dataIndex)) {
          const nestedValue = this.searchInNestedObjects(record, dataIndex);

          return nestedValue ? this.checkSubstring(nestedValue.toString(), value) : "";
        } else {
          return record[dataIndex]
            ? this.checkSubstring(record[dataIndex].toString(), value)
            : "";
        }
      },
    };
  }

  handleSearch(selectedKeys, confirm, dataIndex) {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  }

  handleReset(clearFilters) {
    clearFilters();
    this.setState({ searchText: "" });
  }

  searchInNestedObjects(record, dataIndex = []) {
    let nestedData = { ...record };

    for (let index in dataIndex) {
      const key = dataIndex[index];

      if (key in nestedData) {
        nestedData = nestedData[key];
      } else {
        return undefined;
      }
    }

    return nestedData;
  }

  checkSubstring(string, substring) {
    return string.toLowerCase().includes(substring.toLowerCase());
  }

  // Стили

  getStatusTableStyle(row) {
    if (row.done_at) {
      return "table__row_green";
    } else {
      if (row.urgency) {
        return "table__row_red";
      } else {
        return "table__row_yellow";
      }
    }
  }

  getZebraTableStyle(index) {
    return index % 2 === 0 ? "table__row_light" : "table__row_dark";
  }

  getRowClassName(row, index) {
    const { tableStyle = TABLE_STYLE.status, rowClassName } = this.props;

    switch (tableStyle) {
      case TABLE_STYLE.zebra:
        return this.getZebraTableStyle(index);
      case TABLE_STYLE.status:
        return this.getStatusTableStyle(row);
      default:
        break;
    }

    if (rowClassName) {
      if (typeof rowClassName === "string") return rowClassName;
      if (typeof rowClassName === "function") return rowClassName(row, index);
    }

    return;
  }

  render() {
    const { columns } = this.state;
    const {
      data,
      onRowClick = () => {},
      title = null,
      rowSelection,
      expandable,
      rowKey = (row) => row.id,
      loading = false,
      scroll = { y: "calc(100vh - 248px)" },
      pagination = false,
      showSorterTooltip = true,
    } = this.props;

    return (
      <Table
        scroll={scroll}
        dataSource={data}
        columns={columns}
        title={this.getTableTitle(title)}
        components={COMPONENTS}
        rowKey={rowKey}
        loading={loading}
        loadingIndicator={() => drawSpinner()}
        onFetch={this.lazyLoading}
        className="table"
        size="small"
        bordered
        rowClassName={this.getRowClassName}
        onRow={(row) => ({ onClick: (e) => onRowClick(e, row) })}
        rowSelection={rowSelection}
        expandable={expandable}
        pagination={pagination}
        showSorterTooltip={showSorterTooltip}
      />
    );
  }
}

export default MainTable;
