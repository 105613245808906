import React from "react";
import { Typography, Button } from "antd";
import { CopyOutlined } from "@ant-design/icons";

import "./style.scss";

const { Text, Link: LinkAntd } = Typography;

const Link = ({ name, onCopy, onClick }) => {
  return (
    <Text className="component_user-name user-name_link">
      {typeof onClick === "function" ? (
        <LinkAntd className="user-name__link" onClick={onClick}>
          {name}
        </LinkAntd>
      ) : (
        <Text className="user-name__text">{name}</Text>
      )}
      {typeof onCopy === "function" && (
        <Button type="text" size="small" onClick={onCopy} icon={<CopyOutlined />} />
      )}
    </Text>
  );
};

const WithPickupPoint = ({ pickupPoint, name, onCopy, onClick }) => {
  return (
    <Text className="component_user-name user-name_with-pickup-point">
      {pickupPoint}{" "}
      <Text style={{ color: "inherit" }}>
        {`(`}
        <Link name={name} onCopy={onCopy} onClick={onClick} />
        {`)`}
      </Text>
    </Text>
  );
};

const UserName = {
  Link,
  WithPickupPoint,
};

export default UserName;
