import React, { useState, useEffect } from "react";
import { Drawer, Button, Row, Col, Input, Typography } from "antd";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";

import HardwareChanges from "../HardwareChanges";
import MainTable, { TABLE_STYLE } from "../Table";
import Search from "../Search_new";
import { TYPES } from "../Search_new/consts";

import useHardware from "../../hooks/useHardware.hook";
import normalizeDate, { DATE_TIME } from "../../utils/normalizeDate";
import ERROR_ACTIONS from "../../actions/errors/errors";

const { Text } = Typography;

const HARDWARE_COLUMNS = [
  {
    title: "Идентификатор",
    dataIndex: "identifier",
  },
  {
    width: 120,
    title: "Создано",
    dataIndex: "created_at",
    render: (value) => normalizeDate(value, DATE_TIME),
  },
  {
    width: 120,
    title: "Обновлено",
    dataIndex: "updated_at",
    render: (value) => normalizeDate(value, DATE_TIME),
  },
];

const HardwareTableTitle = ({
  query,
  user,
  setQuery,
  setUser,
  searchHardware,
}) => {
  return (
    <Row gutter={[8, 8]}>
      <Col flex={"220px"}>
        <Search
          placeholder="Пользователь"
          sought={user}
          updateParent={setUser}
          types={[TYPES.user]}
        />
      </Col>
      <Col flex={"220px"}>
        <Input
          autoFocus
          allowClear
          placeholder="Идентификатор оборудования"
          value={query}
          onChange={(event) => setQuery(event.target.value)}
          onPressEnter={() => {
            if (user || query.length > 2) {
              searchHardware(query, user?.id);
            }
          }}
        />
      </Col>
      <Col>
        <Button
          icon={<SearchOutlined />}
          disabled={Boolean(!(user || query.length > 2))}
          onClick={() => searchHardware(query, user?.id)}
        />
      </Col>
    </Row>
  );
};

const View = ({
  hardware,
  setIsEditing,
  onDeleteHardware,
  onShowHardware,
  permissions = {},
}) => {
  const _columns = [...HARDWARE_COLUMNS];

  if (permissions.remove) {
    _columns.push({
      width: 106,
      title: "",
      dataIndex: "",
      render: () => (
        <Button
          danger
          children="Удалить"
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            onDeleteHardware();
          }}
        />
      ),
    });
  }

  if (hardware?.id) {
    return (
      <MainTable
        data={[hardware]}
        columns={_columns}
        tableStyle={TABLE_STYLE.zebra}
        onRowClick={(_, row) => onShowHardware(row.id)}
        title={() =>
          permissions.change ? (
            <Row>
              <Col>
                <Button children="Изменить" onClick={setIsEditing} />
              </Col>
            </Row>
          ) : null
        }
      />
    );
  } else {
    if (permissions.change) {
      return (
        <Button
          icon={<PlusOutlined />}
          children="Добавить оборудование"
          onClick={setIsEditing}
        />
      );
    } else {
      return <Text>Нет оборудования</Text>;
    }
  }
};

const Editing = ({
  hardwares,
  searching,
  query,
  user,
  setQuery,
  setUser,
  searchHardware,
  onShowHardware,
  onAddHardware,
}) => {
  const _columns = [
    ...HARDWARE_COLUMNS,
    {
      width: 116,
      title: "",
      dataIndex: "",
      render: (_, hardware) => (
        <Button
          children="Добавить"
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            onAddHardware(hardware);
          }}
        />
      ),
    },
  ];

  return (
    <MainTable
      data={hardwares}
      columns={_columns}
      loading={searching}
      tableStyle={TABLE_STYLE.zebra}
      onRowClick={(_, row) => onShowHardware(row.id)}
      title={() => (
        <HardwareTableTitle
          query={query}
          user={user}
          setQuery={setQuery}
          setUser={setUser}
          searchHardware={searchHardware}
        />
      )}
    />
  );
};

const HardwareSection = (props) => {
  const { hardware, onChangeHardware, permissions = {} } = props;
  const {
    change,
    remove,
    changesList,
    changeEdit,
    changeRemove,
    changeCreate,
  } = permissions;

  const dispatch = useDispatch();

  // State
  const [isEditing, setIsEditing] = useState(false);
  const [query, setQuery] = useState("");
  const [user, setUser] = useState(null);

  const {
    hardwareLoading,
    searching,
    hardwares,
    selectedHardware,
    hardwareChangesVisible,
    hardwareChangeText,
    editingHardwareChange,
    setHardwares,
    searchHardware,
    getSelectedHardware,
    onPostChange,
    onEditChange,
    onRemoveHardwareChange,
    toggleEditingHardwareChange,
    setHardwareChangesVisible,
    getHardwareChanges,
    setHardwareLoading,
    setSelectedHardware,
    setHardwareChangeText,
  } = useHardware();

  const onAddHardware = (hardware) => {
    onChangeHardware(hardware);
    setIsEditing(false);
  };

  const rowClickHandler = async (hardwares, hardwareId) => {
    await setHardwareChangesVisible(true);
    await setHardwareLoading(true);

    const _selectedHardware = getSelectedHardware(hardwares, hardwareId);

    if (_selectedHardware) {
      _selectedHardware.hardwareChanges = await getHardwareChanges(hardwareId);

      setSelectedHardware(_selectedHardware);
    } else {
      setSelectedHardware(undefined);
      dispatch(
        ERROR_ACTIONS.addError({
          title: "Не удалось получить историю изменений оборудования",
        })
      );
    }

    await setHardwareLoading(false);
  };

  const drawContent = () => {
    if (isEditing) {
      return (
        <Editing
          hardwares={hardwares}
          searching={searching}
          query={query}
          user={user}
          setQuery={setQuery}
          setUser={setUser}
          searchHardware={searchHardware}
          onAddHardware={onAddHardware}
          onShowHardware={
            changesList
              ? (hardwareId) => rowClickHandler(hardwares, hardwareId)
              : () => {}
          }
        />
      );
    } else {
      return (
        <View
          permissions={{
            change,
            remove,
          }}
          hardware={hardware}
          setIsEditing={() => setIsEditing(true)}
          onDeleteHardware={() => onChangeHardware(undefined)}
          onShowHardware={
            changesList
              ? (hardwareId) => rowClickHandler([hardware], hardwareId)
              : () => {}
          }
        />
      );
    }
  };

  // Сбрасываем фильры и оборудование, если поменялся статус секции
  useEffect(() => {
    if (!isEditing) {
      setQuery("");
      setUser(null);
      setHardwares([]);
    }
  }, [isEditing]);

  return (
    <section className="card__section card__section_hardware">
      <h3 className="section__header">Оборудование</h3>
      {drawContent()}

      <Drawer
        width={"auto"}
        bodyStyle={{ overflowY: "hidden" }}
        title={`История изменений ${selectedHardware?.identifier}`}
        visible={hardwareChangesVisible}
        onClose={() => setHardwareChangesVisible(false)}
      >
        <HardwareChanges
          isEditing={Boolean(editingHardwareChange)}
          text={hardwareChangeText}
          loading={hardwareLoading}
          hardware={selectedHardware}
          onOk={
            editingHardwareChange
              ? () => onEditChange(editingHardwareChange, hardwareChangeText)
              : () => onPostChange(selectedHardware?.id, hardwareChangeText)
          }
          onChangeText={setHardwareChangeText}
          onRemoveChange={onRemoveHardwareChange}
          toggleEditing={toggleEditingHardwareChange}
          permissions={
            !isEditing
              ? {
                  hardwareChangeCreate: changeCreate,
                  hardwareChangeRemove: changeRemove,
                  hardwareChangeEdit: changeEdit,
                }
              : {
                  hardwareChangeCreate: false,
                  hardwareChangeEdit: false,
                  hardwareChangeRemove: false,
                }
          }
        />
      </Drawer>
    </section>
  );
};

export default HardwareSection;
