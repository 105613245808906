export const CREATE_APPEAL = "CREATE_APPEAL";

export const GET_APPEALS_LIST = "GET_APPEALS_LIST";
export const CLEAR_ISSUES_LIST = "CLEAR_ISSUES_LIST";
export const USER_OPEN_APPEALS = "USER_OPEN_APPEALS";
export const USER_CLOSED_APPEALS = "USER_CLOSED_APPEALS";
export const OPERATOR_OPEN_APPEALS = "OPERATOR_OPEN_APPEALS";
export const OPERATOR_CLOSED_APPEALS = "OPERATOR_CLOSED_APPEALS";
export const GROUP_OPEN_APPEALS = "GROUP_OPEN_APPEALS";
export const GROUP_CLOSED_APPEALS = "GROUP_CLOSED_APPEALS";

export const APPEAL_CHANGE_OPERATOR = "APPEL_CHANGE_OPERATOR";
export const APPEAL_EDIT_DESCRIPTION = "APPEAL_EDIT_DESCRIPTION";
export const APPEAL_UPLOADING_FILES = "APPEAL_UPLOADING_FILES";

export const APPEAL_CLOSE = "APPEAL_CLOSE";

export const GET_APPEAL_BY_ID = "GET_APPEAL_BY_ID";
export const GET_APPEAL_DATA = "GET_APPEAL_DATA";

export const GET_RELATED_TASKS = "GET_RELATED_TASKS";
export const GET_APPEAL_CHANGES = "GET_APPEAL_CHANGES";

export const RESET_APPEAL_LIST_FILTER_TO_DEFAULT =
  "RESET_APPEAL_LIST_FILTER_TO_DEFAULT";
export const SET_CLOSED_APPEAL_LIST_FILTER = "SET_CLOSED_APPEAL_LIST_FILTER";
export const SET_SEARCH_FOR_APPEAL_LIST_FILTER =
  "SET_SEARCH_FOR_APPEAL_LIST_FILTER";

export const SET_ISSUES = "SET_ISSUES";
export const ADD_ISSUES = "ADD_ISSUES";

export const SET_ISSUES_LIST_OPEN_ONLY_FILTER =
  "SET_ISSUES_LIST_OPEN_ONLY_FILTER";
export const SET_ISSUES_LIST_LIMIT = "SET_ISSUES_LIST_LIMIT";
export const SET_ISSUES_LIST_OFFSET = "SET_ISSUES_LIST_OFFSET";
export const SET_ISSUES_LIST_LOAD_MORE_FLAG = "SET_ISSUES_LIST_LOAD_MORE_FLAG";
const CLEAR_ISSUE = "CLEAR_ISSUE";

export default {
  APPEAL_CHANGE_OPERATOR,
  APPEAL_EDIT_DESCRIPTION,
  APPEAL_CLOSE,
  GET_APPEAL_BY_ID,
  GET_APPEAL_DATA,
  CREATE_APPEAL,
  USER_OPEN_APPEALS,
  USER_CLOSED_APPEALS,
  OPERATOR_OPEN_APPEALS,
  OPERATOR_CLOSED_APPEALS,
  GROUP_OPEN_APPEALS,
  GROUP_CLOSED_APPEALS,
  GET_RELATED_TASKS,
  RESET_APPEAL_LIST_FILTER_TO_DEFAULT,
  SET_CLOSED_APPEAL_LIST_FILTER,
  SET_SEARCH_FOR_APPEAL_LIST_FILTER,
  GET_APPEALS_LIST,
  APPEAL_UPLOADING_FILES,
  CLEAR_ISSUES_LIST,
  GET_APPEAL_CHANGES,

  SET_ISSUES,
  ADD_ISSUES,

  SET_ISSUES_LIST_OPEN_ONLY_FILTER,
  SET_ISSUES_LIST_LIMIT,
  SET_ISSUES_LIST_OFFSET,
  SET_ISSUES_LIST_LOAD_MORE_FLAG,
  CLEAR_ISSUE,
};
