/**
 * Обработка ошибок, вызывает появление всплывающего сообщения
 * @param {Object} err
 * @param {string} type Тип ошибки
 * @returns {Object}
 */
function errorHandling(err, type, visible = true) {
  console.error(err);

  if (err.response) {
    return {
      type,
      payload: {
        visible,
        status: err.response.status,
        message: err.response.data.message,
      },
    };
  }

  return {
    type,
    payload: { ...err, visible },
  };
}

export { ERRORS_DICT } from "../../actions/errors/actionTypes";

export default errorHandling;
