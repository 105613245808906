import React from 'react';

import './style.scss';

function getDateFormat(date) {
    const months = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];
    const createdAt = new Date(date);
    const day = createdAt.getDate();
    const monthNumber = createdAt.getMonth();
    const year = createdAt.getFullYear();

    return `${day} ${months[monthNumber]} ${year} г.`;
}

const MessageSeparator = (props) => {
    const date = getDateFormat(props.date);

    return (
        <div className="message-separator">{date}</div>
    );
}

export default MessageSeparator;