import React from "react";
import { Button } from "antd";

import Spinner from "../Spinner";
import Table, { TABLE_STYLE } from "../../components/Table";

import { useTabs, COMPONENTS } from "../../services/tabs";
import { SEARCH_RESULT_TYPES } from "../../containers/Search";
import normalizeDate, { DATE_TIME } from "../../utils/normalizeDate";
import addLeadingZeros from "../../utils/addLeadingZeros";

import "./style.scss";

const NUMBER_LENGTH = 6;

const SearchResults = (props) => {
  const { results = undefined, loading = false } = props;

  const COLUMNS = [
    {
      title: "Тип",
      dataIndex: "type",
      width: 160,
      render: (text, row) => getResultTypeLabel(row),
    },
    {
      title: "Описание",
      dataIndex: "description",
      ellipsis: true,
    },
    {
      title: "Пользователь",
      dataIndex: "user_id",
      render: (user_id, row) =>
        !row.user ? (
          "-"
        ) : (
          <Button
            type="link"
            className="table__button"
            onClick={(e) => openUserCard(e, user_id)}
          >
            {row.user.last_name} {row.user.first_name}
          </Button>
        ),
    },
    {
      title: "Исполнитель",
      dataIndex: "operator_id",
      render: (operator_id, row) => {
        if (row.operator) {
          return (
            <Button
              type="link"
              className="table__button"
              onClick={(e) => openOperatorCard(e, operator_id)}
            >
              {row.operator.last_name} {row.operator.first_name}
            </Button>
          );
        }
        if (row.operators_group) {
          return row.operators_group.title;
        }

        return "-";
      },
    },
    {
      title: "От",
      dataIndex: "from_operator_id",
      render: (operator_id, row) =>
        !row.from_operator ? (
          "-"
        ) : (
          <Button
            type="link"
            className="table__button"
            onClick={(e) => openOperatorCard(e, operator_id)}
          >
            {row.from_operator.last_name} {row.from_operator.first_name}
          </Button>
        ),
    },
    {
      title: "Создано",
      dataIndex: "created_at",
      width: 130,
      render: (date) => (date ? normalizeDate(date, DATE_TIME) : "-"),
    },
    {
      title: "Выполнено",
      dataIndex: "done_at",
      width: 130,
      render: (date) => (date ? normalizeDate(date, DATE_TIME) : "-"),
    },
  ];

  // Hooks
  const { clickHandler } = useTabs();

  function getResultTypeLabel(result) {
    switch (result.type) {
      case SEARCH_RESULT_TYPES.issue:
        return `ОБ ${addLeadingZeros(
          String(result.human_readable_id),
          NUMBER_LENGTH
        )}`;
      case SEARCH_RESULT_TYPES.task:
        return `ЗД ${addLeadingZeros(
          String(result.human_readable_id),
          NUMBER_LENGTH
        )}`;
      case SEARCH_RESULT_TYPES.mass_problem:
        return `МП ${addLeadingZeros(
          String(result.human_readable_id),
          NUMBER_LENGTH
        )}`;
      case SEARCH_RESULT_TYPES.issue_comment:
        return "Комментарий к ОБ";
      case SEARCH_RESULT_TYPES.task_comment:
        return "Комментарий к ЗД";
      case SEARCH_RESULT_TYPES.mass_problem_comment:
        return "Комментарий к МП";
      default:
        return "";
    }
  }

  function onRowClickHandler(row) {
    switch (row.type) {
      case SEARCH_RESULT_TYPES.task:
        return clickHandler({
          component: COMPONENTS.TASK_CARD,
          params: { taskId: row.id },
          locationState: { taskId: row.id },
        });
      case SEARCH_RESULT_TYPES.task_comment:
        return clickHandler({
          component: COMPONENTS.TASK_CARD,
          params: { taskId: row.task_id },
          locationState: { taskId: row.task_id },
        });
      case SEARCH_RESULT_TYPES.issue:
        return clickHandler({
          component: COMPONENTS.ISSUE_CARD,
          params: { issueId: row.id },
          locationState: { issueId: row.id },
        });
      case SEARCH_RESULT_TYPES.issue_comment:
        return clickHandler({
          component: COMPONENTS.ISSUE_CARD,
          params: { issueId: row.issue_id },
          locationState: { issueId: row.issue_id },
        });
      case SEARCH_RESULT_TYPES.mass_problem:
        return clickHandler({
          component: COMPONENTS.MASS_PROBLEM_CARD,
          params: { massProblemId: row.id },
          locationState: { massProblemId: row.id },
        });
      case SEARCH_RESULT_TYPES.mass_problem_comment:
        return clickHandler({
          component: COMPONENTS.MASS_PROBLEM_CARD,
          params: { massProblemId: row.mass_problem_id },
          locationState: { massProblemId: row.mass_problem_id },
        });
      default:
        return null;
    }
  }

  function openUserCard(event, userId) {
    event.preventDefault();
    event.stopPropagation();

    clickHandler({
      component: COMPONENTS.USER_CARD,
      params: { userId },
      locationState: { userId },
    });
  }

  function openOperatorCard(event, operatorId) {
    event.preventDefault();
    event.stopPropagation();

    clickHandler({
      component: COMPONENTS.OPERATOR_CARD,
      params: { operatorId },
      locationState: { operatorId },
    });
  }

  function drawSearchResults(results) {
    if (loading)
      return (
        <div className="search-results__spinner-wrapper">
          <Spinner text="Поиск..." />
        </div>
      );
    if (results === undefined)
      return (
        <div className="search-results__info-tip">
          <p>Для поиска введите запрос и/или установите точные фильтры</p>
          <ul>
            <li>
              Нажмите <code>Enter</code> чтобы выполнить поисковый запрос;
            </li>
            <li>
              Параметр <code>"Наличие поля"</code> проверяет наличие или
              отсутсвие (в зависимости от выбранного значения) поля в
              результатах поиска. Если параметр установлен, то остальные
              выбранные значения этого фильтра игнорируются;
            </li>
          </ul>
        </div>
      );
    if (Array.isArray(results) && results.length === 0)
      return (
        <div className="search-results__no-results">
          Поиск не дал результатов...
        </div>
      );
    if (Array.isArray(results)) {
      return (
        <Table
          scroll={{ y: "calc(100vh - 268px)" }}
          title={false}
          data={results}
          columns={COLUMNS}
          rowKey={(row) => row.id}
          tableStyle={TABLE_STYLE.zebra}
          onRowClick={(event, row) => onRowClickHandler(row)}
        />
      );
    }

    return null;
  }

  return (
    <div className="component_search-results">{drawSearchResults(results)}</div>
  );
};

export default SearchResults;
