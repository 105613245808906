import jwtDecode from "jwt-decode";
import { history, store } from "../../";

import { ROOT } from "../../paths";
import { LOGIN_ERROR, REFRESH_ERROR } from "../errors/actionTypes";
import { PERMISSIONS } from "../../services/rbac";
import { getOperatorAppeals } from "../../api/appeals";
import { getOperatorTasks } from "../../api/tasks";
import { getMassProblemsList } from "../../api/massProblem";
import errorHandling from "../../services/errors/errorHandling";
import {
  openNotificationsWS,
  closeNotificationsWS,
} from "../notification/notification";
import AUTH_API from "../../api/auth";
import APP_ACTIONS from "../app/app";
import APP_ACTION_TYPES from "../app/actionTypes";
import OPERATORS_API from "../../api/operators";
import AUTH_ACTION_TYPES from "./actionTypes";
import OPERATOR_ACTION_TYPES from "../operator/actionTypes";
import NOTIFICATION_ACTION_TYPES from "../notification/actionTypes";
import formatResponseKeysToCamelCase from "../../utils/formatResponseKeysToCamelCase";

const MIN_TOKEN_TTL = 30_000; // ms
const REFRESH_TIMEOUT_REDUCTION = 25_000; // ms

/**
 * Перегоняем данные в JSON и кэшируем в localStorage
 * @param {Object} data
 */
function cacheData(data) {
  for (let key in data) {
    localStorage.setItem(key, JSON.stringify(data[key]));
  }
}

function parseToken(token) {
  if (typeof token !== "string") return null;

  try {
    return {
      payload: jwtDecode(token, { payload: true }),
    };
  } catch (error) {
    console.error(new Error("Token parse error"));
    console.error(error);

    return null;
  }
}

/**
 * Получаем все необходимые данные и устанавливаем настройки по умолчанию
 * @param {Object} authData
 */
function setDefaultAppSettings(authData) {
  return async (dispatch) => {
    const parsedToken = parseToken(authData.token);
    const expireAt = parsedToken && parsedToken.payload.exp * 1000;

    await cacheData({ ...authData, expireAt });
    await openNotificationsWS(authData.token);

    await dispatch({
      type: OPERATOR_ACTION_TYPES.GET_USER_INFO,
      payload: { ...authData, expireAt },
    });
  };
}

/**
 * Авторизация пользователя
 * @param {string} username
 * @param {string} password`
 */
function login(username, password) {
  return async (dispatch) => {
    try {
      const { data: auth } = await AUTH_API.authorization(username, password);
      const { token, refresh_token, operator } = auth;
      const parsedToken = parseToken(token);
      const expiresAt = parsedToken && parsedToken.payload.exp * 1000;

      const formattedUserData = formatResponseKeysToCamelCase(operator);

      await dispatch(
        setDefaultAppSettings({
          token,
          refresh_token,
          ...formattedUserData,
        })
      );

      // Устанавливаем рефреш таймаут
      const timeout = getTimout(expiresAt);
      dispatch(setRefreshTimeout(refresh_token, timeout));

      const { data: permissions } = await OPERATORS_API.getOperatorPermissions(
        operator.id
      );
      const counters = await getUserCounters(operator.id, permissions);

      if (counters.issues) {
        dispatch({
          type: NOTIFICATION_ACTION_TYPES.SET_OPERATOR_OPEN_ISSUES,
          payload: counters.issues,
        });
      }
      if (counters.tasks) {
        dispatch({
          type: NOTIFICATION_ACTION_TYPES.SET_OPERATOR_OPEN_TASKS,
          payload: counters.tasks,
        });
      }
      if (counters.massProblems) {
        dispatch({
          type: NOTIFICATION_ACTION_TYPES.SET_OPEN_MASS_PROBLEMS,
          payload: counters.massProblems,
        });
      }

      dispatch({
        type: OPERATOR_ACTION_TYPES.GET_OPERATOR_PERMISSIONS,
        payload: permissions,
      });
      dispatch({
        type: AUTH_ACTION_TYPES.SET_AUTH_STATUS,
        payload: true,
      });
    } catch (err) {
      dispatch(errorHandling(err, LOGIN_ERROR));
    }
  };
}

/**
 * Выходим из приложения и чистим все данные пользователя
 */
function logout() {
  return async (dispatch) => {
    localStorage.clear();

    const socket = store.getState().notifications.notificationsWS;
    const refreshTimeoutId = store.getState().app.refreshTimeoutId;

    removeTimeoutRefresh(refreshTimeoutId);
    await dispatch(closeNotificationsWS(socket));
    await dispatch({ type: AUTH_ACTION_TYPES.LOGOUT });

    history.push(ROOT);
  };
}

/**
 * Сравниваем текущее время и время жизни токена
 * @param {string|number} expireAt
 * @returns {boolean}
 */
function isTokenExpired(expireAt) {
  return new Date().getTime() >= new Date(expireAt).getTime();
}

function removeTimeoutRefresh(refreshTimeoutId) {
  clearTimeout(refreshTimeoutId);
  APP_ACTIONS.setTimeoutRefresh(null);
}

/**
 * Проверка авторизации при маунте приложения
 * @param {object} params
 * @param {string | null} params.id - id пользователя
 * @param {string | null} params.token
 * @param {string | null} params.refreshToken
 * @param {string} params.startPath - первоначально открытый путь
 */
function checkAuthorization(params) {
  return async (dispatch) => {
    const { id, token, refreshToken, startPath } = params;

    if (id && token) {
      const parsedToken = parseToken(token);
      const expiresAt = parsedToken && parsedToken.payload.exp * 1000;

      try {
        let _token = token;
        let _expiresAt = expiresAt;
        let _refreshToken = refreshToken;

        // Если токен истек, пытаемся обновить его
        if (_refreshToken && _expiresAt && isTokenExpired(_expiresAt)) {
          const { data } = await AUTH_API.refresh(refreshToken);
          const { token, refresh_token } = data;
          const parsedToken = parseToken(token);
          const expiresAt =
            parsedToken && parsedToken.payload.exp * 1000 - 30_000;

          // Пишем новый токен и рефреш в store
          dispatch({
            type: OPERATOR_ACTION_TYPES.GET_USER_INFO,
            payload: { token, refreshToken: refresh_token },
          });

          _token = token;
          _expiresAt = expiresAt;
          _refreshToken = refresh_token;
        }

        // Получаем данные пользователя
        const { data: user } = await OPERATORS_API.getOperatorData(id);
        const { data: permissions } =
          await OPERATORS_API.getOperatorPermissions(id);
        const counters = await getUserCounters(id, permissions);

        // Устанавливаем обновление авторизации
        const timeout = getTimout(_expiresAt);
        dispatch(setRefreshTimeout(_refreshToken, timeout));

        const formattedUserData = formatResponseKeysToCamelCase(user);

        // Добавляем данные пользователя в localstorage и открываем ws
        await dispatch(
          setDefaultAppSettings({
            token: _token,
            refresh_token: _refreshToken,
            ...formattedUserData,
          })
        );

        if (counters.issues) {
          dispatch({
            type: NOTIFICATION_ACTION_TYPES.SET_OPERATOR_OPEN_ISSUES,
            payload: counters.issues,
          });
        }
        if (counters.tasks) {
          dispatch({
            type: NOTIFICATION_ACTION_TYPES.SET_OPERATOR_OPEN_TASKS,
            payload: counters.tasks,
          });
        }
        if (counters.massProblems) {
          dispatch({
            type: NOTIFICATION_ACTION_TYPES.SET_OPEN_MASS_PROBLEMS,
            payload: counters.massProblems,
          });
        }

        dispatch({
          type: OPERATOR_ACTION_TYPES.GET_OPERATOR_PERMISSIONS,
          payload: permissions,
        });
        dispatch({
          type: APP_ACTION_TYPES.SET_START_PATH,
          payload: startPath,
        });
        dispatch({
          type: AUTH_ACTION_TYPES.SET_AUTH_STATUS,
          payload: true,
        });

        return;
      } catch (err) {
        dispatch(errorHandling(err, ""));
      }
    }

    await dispatch(logout());
  };
}

async function getUserCounters(userId, permissions) {
  const counters = {
    issues: null,
    tasks: null,
    massProblems: null,
  };

  if (
    permissions.includes("operators") ||
    permissions.includes(PERMISSIONS["operators:issues:open:get:list"])
  ) {
    const { data: issues } = await getOperatorAppeals(userId, "");
    counters.issues = issues.map((issue) => issue.id);
  }

  if (
    permissions.includes("operators") ||
    permissions.includes(PERMISSIONS["operators:tasks:open:get:list"])
  ) {
    const { data: tasks } = await getOperatorTasks(userId, "");
    counters.tasks = tasks.map((task) => task.id);
  }

  if (
    permissions.includes("mass_problems") ||
    permissions.includes(PERMISSIONS["mass_problems:get:list"])
  ) {
    const { data: massProblems } = await getMassProblemsList("");
    counters.massProblems = massProblems.map((mp) => mp.id);
  }

  return counters;
}

// Убрать dispatch из этой схемы
function setRefreshTimeout(refreshToken, timeout) {
  return async (dispatch) => {
    if (timeout > MIN_TOKEN_TTL) {
      const refreshTimeoutId = setTimeout(async () => {
        try {
          const { data } = await AUTH_API.refresh(refreshToken);
          const { token, refresh_token } = data;
          const parsedToken = parseToken(token);
          const expiresAt =
            parsedToken && parsedToken.payload.exp * 1000 - 30_000;
          const timeout = getTimout(expiresAt);

          // Пишем новый токен и рефреш в ls и store
          cacheData({ token, refresh_token });
          dispatch({
            type: OPERATOR_ACTION_TYPES.GET_USER_INFO,
            payload: { token, refreshToken: refresh_token },
          });
          dispatch(setRefreshTimeout(refresh_token, timeout));
        } catch (err) {
          dispatch(errorHandling(err, REFRESH_ERROR));
          dispatch(logout());
        }
      }, timeout);

      dispatch({
        type: APP_ACTION_TYPES.SET_REFRESH_TIMEOUT_ID,
        payload: refreshTimeoutId,
      });

      return;
    }

    dispatch(errorHandling({}, REFRESH_ERROR));
    dispatch(logout());
  };
}

function getTimout(expiresAt) {
  return (
    new Date(expiresAt).getTime() -
    new Date().getTime() -
    REFRESH_TIMEOUT_REDUCTION
  );
}

export default {
  login,
  logout,
  isTokenExpired,
  checkAuthorization,
};
