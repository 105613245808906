import React from "react";
import { Select } from "antd";

const OperatorCustomFilters = (props) => {
  const {
    customFilters = [],
    selectedFilter,
    selectProps = {},
    onSelectFilter,
  } = props;

  return (
    <Select
      {...selectProps}
      options={customFilters}
      value={selectedFilter}
      onChange={onSelectFilter}
    />
  );
};

export { default as CustomFilterSaveModal } from "./CustomFilterSaveModal";

export default OperatorCustomFilters;
