import React from "react";
import { Button } from "antd";

import { getOperatorsGroupData } from "../../../api/operators";

const GoTo = ({ onClick }) => {
  return (
    <Button type="primary" onClick={onClick}>
      Перейти к задаче
    </Button>
  );
};

export default async (data) => {
  const { operators_group_id, onOpenTab } = data;
  let groupName;

  await getOperatorsGroupData(operators_group_id)
    .then(({ data }) => (groupName = data.title))
    .catch(() => (groupName = "Группа не найдена"));

  return {
    message: "Задача переназначена",
    description: `Задача назначена на группу ${groupName}`,
    btn: <GoTo onClick={onOpenTab} />,
  };
};
