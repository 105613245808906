import { useCallback } from "react";
import { message } from "antd";

import OPERATORS_API from "../api/operators";

const useOperatorNotifications = () => {
  const getNotificationsRequest = useCallback(async (limit, offset) => {
    try {
      const response = await OPERATORS_API.getNotifications(limit, offset);

      return response.data;
    } catch (err) {
      console.error(err);
      message.error("Не удалось получить уведомления");
      return undefined;
    }
  }, []);

  const getNotifications = useCallback(
    async (limit, offset) => {
      try {
        const notifications = await getNotificationsRequest(limit, offset);

        return {
          notifications,
          offset: offset + notifications.length,
          loadMore: Boolean(notifications.length === limit),
        };
      } catch {
        return undefined;
      }
    },
    [getNotificationsRequest]
  );

  const readNotificationsRequest = useCallback(async (notificationIds) => {
    try {
      await OPERATORS_API.readNotifications(notificationIds);
    } catch (err) {
      console.error(err);
    }
  }, []);

  const clearAllNotificationsRequest = async () => {
    try {
      await OPERATORS_API.clearAllNotifications();
    } catch (err) {
      console.error(err);
      message.error("Не удалось очистить уведомления");
    }
  };

  return {
    getNotifications,
    readNotificationsRequest,
    clearAllNotificationsRequest,
  };
};

export default useOperatorNotifications;
