import React from "react";
import { Typography, Button, Row, Col } from "antd";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";

import "./style.scss";

const { Text } = Typography;
const componentClassName = "component_secret-data";
const placeholderClassName = "secret-data__placeholder";
const BUTTON_TYPE = "text";
const BUTTON_SIZE = "small";
const ROW_GUTTER = [12, 0];

const SecretData = ({ visible, onChangeVisible, value, disabled }) => {
  if (disabled)
    return (
      <Row className={componentClassName}>
        <Col span={24}>
          <div className={placeholderClassName} />
        </Col>
      </Row>
    );

  if (visible) {
    return (
      <Row className={componentClassName} gutter={ROW_GUTTER}>
        <Col>
          <Button
            type={BUTTON_TYPE}
            size={BUTTON_SIZE}
            icon={<EyeInvisibleOutlined />}
            onClick={() => onChangeVisible(false)}
          />
        </Col>
        <Col>
          <Text>{value}</Text>
        </Col>
      </Row>
    );
  } else {
    return (
      <Row className={componentClassName} align="middle" gutter={ROW_GUTTER}>
        <Col>
          <Button
            type={BUTTON_TYPE}
            size={BUTTON_SIZE}
            icon={<EyeOutlined />}
            onClick={() => onChangeVisible(true)}
          />
        </Col>

        <Col flex={"auto"}>
          <div className={placeholderClassName} />
        </Col>
      </Row>
    );
  }
};

export default SecretData;
