import React, { Component } from "react";
import { Form, Input, Button, Card } from "antd";
import { connect } from "react-redux";

import AUTH_ACTIONS from "../../actions/auth/auth";

import "antd/dist/antd.css";

const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};

class Login extends Component {
  constructor() {
    super();

    this.state = {
      loading: false,
    };

    this.onFinish = this.onFinish.bind(this);
  }

  async onFinish({ username, password }) {
    this.setState({ loading: true });
    await this.props.login(username, password);
    this.setState({ loading: false });
  }

  render() {
    const { loading } = this.state;

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          height: "100%",
          alignItems: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Card>
          <Form {...layout} name="basic" onFinish={this.onFinish}>
            <Form.Item
              labelAlign="left"
              label="Логин:"
              name="username"
              rules={[
                {
                  required: true,
                  message: "Введите логин",
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              labelAlign="left"
              label="Пароль:"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Введите пароль",
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>
                Войти
              </Button>
            </Form.Item>
          </Form>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = (dispatch) => ({
  login: (username, password) =>
    dispatch(AUTH_ACTIONS.login(username, password)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
