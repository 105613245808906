import React from "react";
import { Typography } from "antd";

const { Link } = Typography;

function getEmailLinks(emails) {
  if (Array.isArray(emails)) {
    return emails.map((email, index) => (
      <Link key={`${email}_${index}`} href={`mailto:${email}`}>
        {email}
      </Link>
    ));
  } else {
    return [];
  }
}

export default getEmailLinks;
