import React, { Component } from "react";
import { store } from "../..";
import { Badge, Input } from "antd";
import { SearchOutlined, UserOutlined } from "@ant-design/icons";

import Spinner from "../../components/Spinner";
import UserName from "../../components/UserName";

import normalizeDate, { ONLY_TIME, FULL_DATE } from "../../utils/normalizeDate";

function getDateTimeFormat(createdAt) {
  const dayOfWeek = ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"];
  const today = new Date();
  const day = today.getDate();
  const month = today.getMonth();
  const year = today.getFullYear();
  const weekAgo = new Date();
  weekAgo.setDate(today.getDate() - 6);

  const created = new Date(createdAt);
  const createdDay = created.getDate();
  const createdMonth = created.getMonth();
  const createdYear = created.getFullYear();

  const thisMonthAndYear = month === createdMonth && year === createdYear;
  const messageCreatedToday = thisMonthAndYear && day === createdDay;
  const messageCreatedYesterday = thisMonthAndYear && day - 1 === createdDay;
  const messageCreatedThisWeek = created >= weekAgo && created < today;

  if (messageCreatedToday) {
    return normalizeDate(createdAt, ONLY_TIME);
  }

  if (messageCreatedYesterday) {
    return "Вчера";
  }

  if (messageCreatedThisWeek) {
    return dayOfWeek[created.getDay()];
  }

  return normalizeDate(createdAt, FULL_DATE);
}

function getLastMessageAuthorName(message) {
  const currentOperatorId = store.getState().user.id;
  const { id, last_name, first_name } = message.messageFrom;

  if (id === currentOperatorId) {
    return "Вы: ";
  }
  if (message.user_id === id) {
    return "";
  }

  return `${last_name} ${first_name[0]}.: `;
}

function getLastMessageText(message) {
  if (message.file) {
    return "Вложенный файл";
  } else {
    return message.message;
  }
}

function getLastMessage(messages) {
  const lastMessage = Array.isArray(messages) && messages[messages.length - 1];

  if (lastMessage) {
    return {
      created_at: getDateTimeFormat(lastMessage.created_at),
      message: getLastMessageText(lastMessage),
      messageFrom: getLastMessageAuthorName(lastMessage),
    };
  } else {
    return {
      created_at: "",
      message: "Нет сообщений",
      messageFrom: "",
    };
  }
}

function getDialogueClassName(dialogue) {
  const className = ["dialog-item"];

  dialogue.selected && className.push("dialog-item_selected");
  dialogue.disabled && className.push("dialog-item_disabled");
  dialogue.irrelevant && className.push("dialog-item_irrelevant");

  return className.join(" ");
}

const DialogItem = (props) => {
  const { user, unread, onSelect, disabled, messages } = props;
  const { current_pp_name, first_name, last_name } = user;
  const name = `${last_name} ${first_name}`.trim();

  const className = getDialogueClassName(props);
  const lastMessage = getLastMessage(messages);

  return (
    <button className={className} onClick={onSelect} disabled={disabled}>
      {user.avatar ? (
        <img className="dialog-item__avatar" src={user.avatar} alt="Фото пользователя" />
      ) : (
        <div className="dialog-item__avatar dialog-item__avatar_mock">
          <UserOutlined />
        </div>
      )}
      <div className="dialog-item__wrapper">
        <h4 className="dialog-item__author-and-date">
          {current_pp_name ? (
            <UserName.WithPickupPoint pickupPoint={current_pp_name} name={name} />
          ) : (
            <UserName.Link name={name} />
          )}
          <span className="dialog-item__created-at">{lastMessage.created_at}</span>
        </h4>
        <p className="dialog-item__message">
          {lastMessage.messageFrom && (
            <span className="dialog-item__message-from">{lastMessage.messageFrom}</span>
          )}
          <span className="dialog-item__last-message">{lastMessage.message}</span>
          <Badge dot={unread} className="dialog-item__notification" />
        </p>
      </div>
    </button>
  );
};

const NoDialogs = () => {
  return (
    <div className="dialogs-list">
      <div className="information">Нет активных диалогов...</div>
    </div>
  );
};

const DialogsLoading = () => {
  return (
    <div className="dialogs-list">
      <Spinner />
    </div>
  );
};
class DialogsList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      query: "",
    };

    this.onChangeInput = this.onChangeInput.bind(this);
    this.drawDialogs = this.drawDialogs.bind(this);
  }

  onChangeInput(e) {
    const { value } = e.target;

    e.preventDefault();
    this.setState({
      query: value,
    });
  }

  drawDialogs() {
    const { query } = this.state;
    const {
      dialogs,
      selectDialogue,
      onSelect = () => {},
      dialoguesFetching,
    } = this.props;

    if (query.length > 0) {
      const filtered = dialogs.filter(
        (dialog) =>
          dialog.user.last_name.toLowerCase().includes(query.toLowerCase()) ||
          dialog.user.first_name.toLowerCase().includes(query.toLowerCase())
      );

      return filtered.length === 0 ? (
        <div className="information">Ничего не найдено :(</div>
      ) : (
        filtered.map((item) => (
          <DialogItem
            {...item}
            selected={selectDialogue === item.id}
            onSelect={selectDialogue === item.id ? () => {} : () => onSelect(item)}
            disabled={dialoguesFetching || item.disabled}
            key={item.id}
          />
        ))
      );
    }

    return dialogs.map((item) => (
      <DialogItem
        {...item}
        selected={selectDialogue === item.id}
        onSelect={selectDialogue === item.id ? () => {} : () => onSelect(item)}
        disabled={dialoguesFetching || item.disabled}
        key={item.id}
      />
    ));
  }

  render() {
    const { dialogs, loading } = this.props;
    const { query } = this.state;

    if (loading) return <DialogsLoading />;
    if (dialogs.length === 0) return <NoDialogs />;

    return (
      <div className="dialogs-list">
        <div className="dialog-search">
          <SearchOutlined className="search-icon" />
          <Input
            value={query}
            onChange={this.onChangeInput}
            placeholder="Поиск..."
            bordered={false}
            allowClear
          />
        </div>
        {this.drawDialogs()}
      </div>
    );
  }
}

export default DialogsList;
