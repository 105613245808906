import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { message } from "antd";

import Layout from "./layout";

import useRBAC, { UI_PERMISSIONS } from "../../services/rbac";

import {
  getLocations,
  getLocationById,
  changeLocationTitle,
  changeParentLocation,
  setLocationMacAddresses,
} from "../../api/locations";

import "./style.scss";

const CARD_FIELDS = {
  title: "title",
  macAddresses: "macAddresses",
  parentLocation: "parentLocation",
};

const DEFAULT_SWITCHES = {
  [CARD_FIELDS.title]: false,
  [CARD_FIELDS.macAddresses]: false,
  [CARD_FIELDS.parentLocation]: false,
};

const LocationCard = (props) => {
  const { tabUuid, error, setTabError } = props;
  const location = useLocation();

  // State
  const [switches, setSwitch] = useState(DEFAULT_SWITCHES);
  const [locations, setLocations] = useState([]);
  const [currentLocation, setCurrentLocation] = useState({});
  const [loading, setLoading] = useState(false);

  //Hooks
  const { checkPermissionToRenderUI } = useRBAC();

  const uiPermissions = {
    name: checkPermissionToRenderUI(
      UI_PERMISSIONS["ui:management:locations:card:name:edit"]
    ),
    parent: checkPermissionToRenderUI(
      UI_PERMISSIONS["ui:management:locations:card:parent:edit"]
    ),
    macAddress: checkPermissionToRenderUI(
      UI_PERMISSIONS["ui:management:locations:card:mac-address:edit"]
    ),
  };

  async function getLocationCardData() {
    await setLoading(true);

    try {
      await getLocationsList();
      await getCurrentLocation();

      setTabError(null);
    } catch (err) {
      console.error(err);

      setTabError({
        title: "Ошибка получения локации",
        message: err.response.data?.message,
        status: err.response.status,
      });
    }

    await setLoading(false);
  }

  function getMacAddressesArray(macAddressesString) {
    return macAddressesString.split(/[\s,.]/gim).filter((ma) => Boolean(ma));
  }

  function getMacAddressesString(macAddresses) {
    if (!macAddresses || !Array.isArray(macAddresses)) return "";

    return macAddresses.map((ma) => ma.id).join(", ");
  }

  function formatLocations(locations = []) {
    const formattedLocations = locations.map((location) => ({
      ...location,
      key: location.id,
      value: location.id,
      label: location.title,
      macAddresses: getMacAddressesString(location.mac_address_masks),
    }));

    return formattedLocations;
  }

  async function getLocationsList() {
    await getLocations().then((resp) =>
      setLocations(formatLocations(resp.data))
    );
  }

  async function getCurrentLocation() {
    const splitPath = location.pathname.split("/");
    const locationId = splitPath[3];

    await getLocationById(locationId).then((resp) =>
      setCurrentLocation({
        ...resp.data,
        macAddresses: getMacAddressesString(resp.data.mac_address_masks),
      })
    );
  }

  function switchEditableField(fieldName) {
    setSwitch({ ...switches, [fieldName]: !switches[fieldName] });
  }

  function changeCurrentLocationTitle(title) {
    changeLocationTitle(currentLocation.id, title)
      .then(() => {
        getCurrentLocation();
        getLocationsList();

        message.success("Успешно");
      })
      .catch(() => {
        message.error("При изменении названия локации произошла ошибка");
      });
  }

  function changeCurrentLocationParent(parentId) {
    changeParentLocation(currentLocation.id, parentId)
      .then(() => {
        getCurrentLocation();
        getLocationsList();

        message.success("Успешно");
      })
      .catch(() => {
        message.error("При изменении родительской локации произошла ошибка");
      });
  }

  function setCurrentLocationMacAddresses(macAddresses) {
    const macAddressesArray = macAddresses
      ? getMacAddressesArray(macAddresses)
      : [];

    setLocationMacAddresses(currentLocation.id, macAddressesArray)
      .then(() => {
        getCurrentLocation();
        getLocationsList();

        message.success("Успешно");
      })
      .catch((err) => {
        if (err.response) {
          return message.error(err.response.data.message, 3);
        }
        message.error("При изменении родительской локации произошла ошибка");
      });
  }

  function onClick(e) {
    if (
      !e
        .composedPath()
        .find((elem) => elem.className === "component_editable-card-field")
    ) {
      setSwitch(
        Object.keys(switches).reduce((acc, key) => {
          acc[key] = false;
          return acc;
        }, {})
      );
    }
  }

  useEffect(() => {
    document.addEventListener("click", onClick);

    return () => {
      document.removeEventListener("click", onClick);
    };
  }, []);

  useEffect(() => {
    getLocationCardData();
  }, [tabUuid]);

  return (
    <Layout
      uiPermissions={uiPermissions}
      error={error}
      loading={loading}
      currentLocation={currentLocation}
      fields={CARD_FIELDS}
      switches={switches}
      locations={locations}
      switchEditableField={switchEditableField}
      changeLocationTitle={changeCurrentLocationTitle}
      changeParentLocation={changeCurrentLocationParent}
      setLocationMacAddresses={setCurrentLocationMacAddresses}
    />
  );
};

export default LocationCard;
