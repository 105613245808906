import React, { useCallback } from "react";
import {
  Row,
  Col,
  Space,
  Button,
  Tooltip,
  Typography,
  Descriptions,
} from "antd";
import { EditOutlined } from "@ant-design/icons";

import SecretData from "../SecretData";

import { useTabs, COMPONENTS } from "../../services/tabs";
import normalizeDate from "../../utils/normalizeDate";

const { Title, Text } = Typography;

const DESCRIPTION_SIZE = "small";

const View = (props) => {
  const { clickHandler } = useTabs();

  const {
    name,
    description,
    updated_at,
    created_at,
    disabled,
    authorization_header,
    secret,
    basic_password,
    basic_username,
    from_operator,
    operator,
    to_operator,
    to_operators_group,
    onEditing,
    secretVisible,
    secretDisabled = true,
    secretKeys,
    onChangeSecretVisible,
    editingAllow = false,
  } = props;

  const drawRecepient = () => {
    if (to_operator)
      return (
        <Button
          className="service-account-card__link"
          type="link"
          onClick={() =>
            clickHandler({
              component: COMPONENTS.OPERATOR_CARD,
              params: { operatorId: to_operator.id },
              locationState: { operatorId: to_operator.id },
            })
          }
        >{`${to_operator.last_name} ${to_operator.first_name}`}</Button>
      );
    if (to_operators_group)
      return (
        <Button
          className="service-account-card__link"
          type="link"
          onClick={() =>
            clickHandler({
              component: COMPONENTS.GROUP_CARD,
              params: { groupId: to_operators_group.id },
              locationState: { groupId: to_operators_group.id },
            })
          }
        >
          {to_operators_group.title}
        </Button>
      );

    return "";
  };

  const drawAuthData = useCallback(() => {
    const items = [];

    if (authorization_header) {
      items.push(
        <Descriptions.Item label="Заголовок" key="secret-data__auth-header">
          <SecretData
            disabled={secretDisabled}
            visible={secretVisible.authorizationHeader}
            value={authorization_header}
            onChangeVisible={(bool) =>
              onChangeSecretVisible(secretKeys.authorizationHeader, bool)
            }
          />
        </Descriptions.Item>
      );
    }
    if (secret) {
      items.push(
        <Descriptions.Item label="Secret" key="secret-data__secret">
          <SecretData
            disabled={secretDisabled}
            visible={secretVisible.secret}
            value={secret}
            onChangeVisible={(bool) =>
              onChangeSecretVisible(secretKeys.secret, bool)
            }
          />
        </Descriptions.Item>
      );
    }
    if (basic_username) {
      items.push(
        <Descriptions.Item
          label="Базовое имя пользователя"
          key="secret-data__basic-username"
        >
          <SecretData
            disabled={secretDisabled}
            visible={secretVisible.basicUsername}
            value={basic_username}
            onChangeVisible={(bool) =>
              onChangeSecretVisible(secretKeys.basicUsername, bool)
            }
          />
        </Descriptions.Item>
      );
    }
    if (basic_password) {
      items.push(
        <Descriptions.Item
          label="Базовый пароль"
          key="secret-data__basic-password"
        >
          <SecretData
            disabled={secretDisabled}
            visible={secretVisible.basicPassword}
            value={basic_password}
            onChangeVisible={(bool) =>
              onChangeSecretVisible(secretKeys.basicPassword, bool)
            }
          />
        </Descriptions.Item>
      );
    }

    return items;
  }, [
    secretDisabled,
    secretKeys,
    authorization_header,
    secret,
    basic_username,
    basic_password,
    secretVisible,
    onChangeSecretVisible,
  ]);

  return (
    <Row className="component_service-account-card" gutter={[0, 16]}>
      <Col span={24}>
        <Space align="center">
          <Title level={5} style={{ marginBottom: 0 }}>
            {name}
          </Title>

          {editingAllow && (
            <Tooltip title="Редактировать">
              <Button type="text" icon={<EditOutlined />} onClick={onEditing} />
            </Tooltip>
          )}
        </Space>
      </Col>
      <Col span={24}>
        <Text strong style={{ display: "block" }}>
          Описание
        </Text>
        <Text>{description}</Text>
      </Col>
      <Col span={24}>
        <Descriptions bordered column={2} size={DESCRIPTION_SIZE}>
          <Descriptions.Item label="Создан">
            {normalizeDate(created_at)}
          </Descriptions.Item>
          <Descriptions.Item label="Изменен">
            {normalizeDate(updated_at)}
          </Descriptions.Item>
          <Descriptions.Item label="Создал">
            <Button
              className="service-account-card__link"
              type="link"
              onClick={() =>
                clickHandler({
                  component: COMPONENTS.OPERATOR_CARD,
                  params: { operatorId: operator.id },
                  locationState: { operatorId: operator.id },
                })
              }
            >{`${operator.last_name} ${operator.first_name}`}</Button>
          </Descriptions.Item>
          <Descriptions.Item label="Неактивный">
            {disabled ? "Да" : "Нет"}
          </Descriptions.Item>
          <Descriptions.Item label="От оператора">
            <Button
              className="service-account-card__link"
              type="link"
              onClick={() =>
                clickHandler({
                  component: COMPONENTS.OPERATOR_CARD,
                  params: { operatorId: from_operator.id },
                  locationState: { operatorId: from_operator.id },
                })
              }
            >
              {`${from_operator.last_name} ${from_operator.first_name}`}
            </Button>
          </Descriptions.Item>
          <Descriptions.Item label="Исполнитель">
            {drawRecepient()}
          </Descriptions.Item>
        </Descriptions>
      </Col>
      <Col span={24}>
        <Descriptions bordered column={1} size={DESCRIPTION_SIZE}>
          {drawAuthData()}
        </Descriptions>
      </Col>
    </Row>
  );
};

export default View;
