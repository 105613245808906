import React, { useState, useEffect } from "react";
import { Form, Button, Input, Select, message } from "antd";

import MainContainer from "../../components/MainContainer";

import { getLocations, createLocation } from "../../api/locations";

import "./style.scss";

const Header = () => {
  return (
    <>
      <h2 className="header__title">Создание локации</h2>
    </>
  );
};

const LocationCreationForm = () => {
  const [form] = Form.useForm();
  const [locations, setLocations] = useState([]);
  const [locationsFilter, setLocationsFilter] = useState("");

  function formatLocations(locations = []) {
    const formattedLocations = locations.map((location) => ({
      ...location,
      key: location.id,
      value: location.id,
      label: location.title,
    }));

    return formattedLocations;
  }

  function submitForm(formData) {
    createLocation(formData)
      .then(() => {
        form.resetFields();
        message.success("Локация успешно создана");
        getLocationsTree();
      })
      .catch(() => message.error("При создании локации произошла ошибка"));
  }

  function getLocationsTree() {
    getLocations().then((resp) => setLocations(formatLocations(resp.data)));
  }

  function titleValidation(value) {
    if (!value) return Promise.reject("Обязательное поле");
    if (value.length < 3) return Promise.reject("Минимальная длина 3 символа");

    return Promise.resolve();
  }

  // componentDidMount
  useEffect(() => {
    getLocationsTree();
  }, []);

  return (
    <MainContainer
      className="page_location-creation"
      headerClassName="location-creation__header"
      header={<Header />}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={submitForm}
        style={{ maxWidth: 500 }}
        className="location-creation__form"
      >
        <Form.Item
          label="Название локации:"
          name="title"
          rules={[
            {
              required: true,
              validator: (rule, value) => titleValidation(value),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Выбрать родительскую локацию:" name="parent_id">
          <Select
            allowClear
            showSearch
            filterOption
            optionFilterProp="label"
            options={locations}
            searchValue={locationsFilter}
            onSearch={setLocationsFilter}
          />
        </Form.Item>
        <Button type="primary" htmlType="submit" children="Создать" />
      </Form>
    </MainContainer>
  );
};

export default LocationCreationForm;
