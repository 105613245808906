import React from "react";
import { Modal, Button } from "antd";
import {
  ArrowRightOutlined,
  ArrowLeftOutlined,
  CloseOutlined,
  DownloadOutlined,
} from "@ant-design/icons";

import Spinner from "../Spinner";

const IMAGE_MIME_TYPES = [
  "image/gif",
  "image/jpeg",
  "image/png",
  "image/svg+xml",
  "image/webp",
];
const VIDEO_MIME_TYPES = ["video/mp4", "video/ogg", "video/webm"];

const LoadingFail = ({ retry }) => {
  return (
    <div className="file_loading-fail">
      <p>Произошла ошибка</p>
      {retry && (
        <Button type="link" children="Повторить загрузку" onClick={retry} />
      )}
    </div>
  );
};

const NoFile = () => {
  return <div className="no-file">Нет файла</div>;
};

const Image = ({ file }) => {
  return (
    <img
      src={file.url}
      alt={file.filename}
      className="shown-image"
      onKeyUp={(e) => this.onKeyUp(e)}
    />
  );
};

const Video = ({ file }) => {
  return (
    <video className="file-veiwer__video" controls>
      <source
        src={file.base64}
        type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
      ></source>
      <source
        src={file.base64}
        type='video/ogg; codecs="theora, vorbis"'
      ></source>
      <source
        src={file.base64}
        type='video/webm; codecs="vp8, vorbis"'
      ></source>
      Ваш браузер не поддерживает воспроизведение видео
    </video>
  );
};

const OtherFile = ({ base64, name }) => {
  return (
    <div className="other-file">
      Невозможно отобразить файл
      {base64 && (
        <Button type="link">
          <a href={base64} download={name}>
            Скачать
          </a>
        </Button>
      )}
    </div>
  );
};

class FileViewerModal extends React.Component {
  constructor(props) {
    super(props);

    this.onKeyUp = this.onKeyUp.bind(this);
    this.drawFile = this.drawFile.bind(this);
    this.drawModalContent = this.drawModalContent.bind(this);
    this.drawDownloadButton = this.drawDownloadButton.bind(this);
  }

  drawDownloadButton(url, filename = "noname") {
    if (!url) return;

    return (
      <Button className="header__button header__button_download">
        <a href={url} download={filename}>
          <DownloadOutlined style={{ fontSize: "24px" }} />
        </a>
      </Button>
    );
  }

  drawModalContent(file) {
    const { loading, fail, retry } = this.props;

    if (loading) return <Spinner />;
    if (fail) return <LoadingFail retry={retry} />;

    return this.drawFile(file);
  }

  drawFile(file) {
    if (!file) return <NoFile />;
    if (IMAGE_MIME_TYPES.includes(file.content_type))
      return <Image file={file} />;
    if (VIDEO_MIME_TYPES.includes(file.content_type))
      return <Video file={file} />;

    return <OtherFile base64={file.base64} name={file.filename} />;
  }

  onKeyUp(e) {
    switch (e.key) {
      case "ArrowRight":
        return this.props.onNext();
      case "ArrowLeft":
        return this.props.onPrev();
      default:
        break;
    }
  }

  // componentDidMount() {
  //   document.addEventListener("keyup", this.onKeyUp);
  // }

  // componentWillUnmount() {
  //   document.removeEventListener("keyup", this.onKeyUp);
  // }

  render() {
    const { file, visible } = this.props;

    return (
      <Modal
        centered
        width="fit-content"
        style={{ maxWidth: "80%", backgroundColor: "transparent" }}
        bodyStyle={{ padding: 0 }}
        closable={false}
        footer={false}
        visible={visible}
        onCancel={this.props.onClose}
      >
        <header className="file-veiwer__header">
          <span>{file && file.filename}</span>
          <div className="button-wrapper">
            {file && this.drawDownloadButton(file.url, file.filename)}
            <Button
              className="header__button header__button_close"
              onClick={this.props.onClose}
              icon={<CloseOutlined style={{ fontSize: "24px" }} />}
            />
          </div>
        </header>
        <button
          className="button prev"
          onClick={this.props.onPrev}
          children={<ArrowLeftOutlined style={{ color: "#fff" }} />}
        />
        <button
          className="button next"
          onClick={this.props.onNext}
          children={<ArrowRightOutlined style={{ color: "#fff" }} />}
        />
        {this.drawModalContent(file)}
      </Modal>
    );
  }
}

export default FileViewerModal;
