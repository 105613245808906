import { COMPONENTS } from "../../consts";

/**
 * @enum Все возможные разрешения пользователя
 */
export const PERMISSIONS = {
  "operator_ldap_props:create": "operator_ldap_props:create", // Создать ldap свойства операторов
  "operator_ldap_props:update": "operator_ldap_props:update", // Обновить ldap свойства операторов
  "operator_ldap_props:delete": "operator_ldap_props:delete", // Удалить ldap свойства операторов
  "operator_ldap_props:list": "operator_ldap_props:list", // Поулчения текущего перечня ldap свойства операторов
  "rbac:roles:create": "rbac:roles:create", // Добавление ролей
  "rbac:roles:drop": "rbac:roles:drop", // Отзыв ролей
  "rbac:permissions:assign": "rbac:permissions:assign", // Присвоение разрешений
  "rbac:permissions:revoke": "rbac:permissions:revoke", // Отзыв разрешений
  "rbac:roles:inherit": "rbac:roles:inherit", // Наследование ролей
  "rbac:roles:remove_parent": "rbac:roles:remove_parent", // Удаление родительских ролей
  "rbac:graph:roles": "rbac:graph:roles", // Нарисовать граф определённых ролей/рфзрешений
  "rbac:graph:full": "rbac:graph:full", // Нарисовать граф всех ролей/разрешений
  "rbac:roles:get:list": "rbac:roles:get:list", // Получить список доступных ролей
  "operators:get:one:by_id": "operators:get:one:by_id", // Получение оператора по идентификатору
  "operators:open_tasks:count": "operators:open_tasks:count", // Считать открытые задачи операторов
  "operators:list:all": "operators:list:all", // Получение всех операторов
  "operators:list:group_operators": "operators:list:group_operators", // Получение операторов группы
  "operators:names:update": "operators:names:update", // Обновление имени оператора
  "operators:status:update": "operators:status:update", // Обновление статуса оператора
  "operators:group:update": "operators:group:update", // Обновление группы оператора
  "operators:phone_number:update": "operators:phone_number:update", // Обновление номера телефона оператора
  "operators:issues:open:get:list": "operators:issues:open:get:list", // Получение открытых обращений оператора
  "operators:issues:last_closed:get:list": "operators:issues:last_closed:get:list", // Получение последних закрытых обращений оператора
  "operators:tasks:open:get:list": "operators:tasks:open:get:list", // Получение открытых задач оператора
  "operators:tasks:last_closed:get:list": "operators:tasks:last_closed:get:list", // Получение последних закрытых задач оператора
  "operators:inherited_permissions:get:list": "operators:inherited_permissions:get:list", // Получение наследованных разрешений
  "operators:roles:grant": "operators:roles:grant", // Добавить роли оператору
  "operators:roles:revoke": "operators:roles:revoke", // Отозвать роли оператора
  "operators:location_changes:get:list": "operators:location_changes:get:list", // Получить изменения локации оператора
  "operators:registration_tokens:update": "operators:registration_tokens:update", // Обновление регистрационного токена оператора (для отправки пушей)
  "users:photos:get:one": "users:photos:get:one", // Получить фото пользователя
  "users:create:from_employee": "users:create:from_employee", // Создать пользователя из сотрудника (сервис employees)
  "users:create:from_initial_data": "users:create:from_initial_data", // Создать пользователя из введённых данных
  "users:workplace_location:edit": "users:workplace_location:edit", // Редактировать местоположение рабочего места
  "users:workstation_number:start_auto_update":
    "users:workstation_number:start_auto_update", // Запустить автоматическое обновление номера рабочей станции
  "users:workstation_number:edit": "users:workstation_number:edit", // Обновить номер рабочей станции вручную
  "users:search": "users:search", // Поиск пользователей
  "users:get:by_phone": "users:get:by_phone", // Получить пользователя по номеру телефона
  "users:get:by_id": "users:get:by_id", // Получить пользователя по его идентификатору
  "users:employee_info:all_update": "users:employee_info:all_update", // Обновить у всех пользователей информацию из сервиса employees
  "available_permissions:get:list": "available_permissions:get:list", // Список доступных для назначения разрешений
  "locations:operators:open:get:list": "locations:operators:open:get:list", // Получение операторов в локации
  "users:chats_require_attention:get:list": "users:chats_require_attention:get:list", // Получение идентификаторов пользователей, чей чат требует внимания
  "operator_groups:issues:open:get:list": "operator_groups:issues:open:get:list", // Получение открытых обращений группы операторов
  "operator_groups:issues:last_closed:get:list":
    "operator_groups:issues:last_closed:get:list", // Получение последних закрытых обращений группы операторов
  "mass_problems:issues:open:get:list": "mass_problems:issues:open:get:list", // Получение открытых обращений массовой проблемы
  "users:issues:open:get:list": "users:issues:open:get:list", // Получить открытые обращения по пользователю
  "users:issues:last_closed:get:list": "users:issues:last_closed:get:list", // Получить последние закрытые обращения по пользователю
  "users:issues:for_period:get:list": "users:issues:for_period:get:list", // Получить обращения по пользователю за период
  "users:messages:post:text": "users:messages:post:text", // Отправить текстовое сообщение пользователю
  "users:messages:post:file": "users:messages:post:file", // Отправить пользователю сообщение с файлом
  "users:messages:get:list": "users:messages:get:list", // Получить сообщения пользователя
  "users:tasks:open:get:list": "users:tasks:open:get:list", // Получить открытые задачи по атрибуту пользователь
  "users:tasks:last_closed:get:list": "users:tasks:last_closed:get:list", // Получить открытые задачи по атрибуту пользователь
  "users:messages:get:one": "users:messages:get:one", // Получить одно сообщение пользователя
  "operator_statuses:create": "operator_statuses:create", // Создание статуса операторов
  "operator_statuses:edit": "operator_statuses:edit", // Редактирование статуса операторов
  "operator_statuses:delete": "operator_statuses:delete", // Удаление статуса операторов
  "operator_statuses:get:one": "operator_statuses:get:one", // Получить статус оператора
  "operator_statuses:get:list": "operator_statuses:get:list", // Получить список доступных статусов операторов
  "locations:create": "locations:create", // Создание локации
  "locations:title:edit": "locations:title:edit", // Редактирование тайтла локации
  "locations:parent:edit": "locations:parent:edit", // Редактирование родительской локации
  "locations:get:list": "locations:get:list", // Получить список локаций
  "locations:delete": "locations:delete", // Удаление локации
  "locations:get:one": "locations:get:one", // Получить локацию
  "locations:mac_addresses:replace": "locations:mac_addresses:replace", // Заменить мак адреса локации
  "mass_problems:create": "mass_problems:create", // Создать массовую проблему
  "mass_problems:issues:add": "mass_problems:issues:add", // Добавить обращение в массовую проблему
  "mass_problems:issues:delete": "mass_problems:issues:delete", // Удалить образение из массовой проблемы
  "mass_problems:description:edit": "mass_problems:description:edit", // Обновить описание массовой проблемы
  "mass_problems:close": "mass_problems:close", // Закрыть массовую проблему
  "mass_problems:comments:post": "mass_problems:comments:post", // Добавить коментарий к массовой проблеме
  "mass_problems:comments:edit": "mass_problems:comments:edit", // Редактировать коментарий массовой проблемы
  "mass_problems:files:add": "mass_problems:files:add", // Добавить файлы к массовой проблеме
  "mass_problems:files:delete": "mass_problems:files:delete", // Удалить файл из массовой проблемы
  "mass_problems:get:one": "mass_problems:get:one", // Получить массовую проблему
  "mass_problems:get:multiple": "mass_problems:get:multiple", // Получить массовые проблемы по нескольким идентификаторам
  "mass_problems:get:list": "mass_problems:get:list", // Получить список массовых проблем
  "mass_problems:comments:list": "mass_problems:comments:list", // Получение коментариев массовой проблемы
  "mass_problem:from_operator:closed:get:list":
    "mass_problem:from_operator:closed:get:list", // Получение закрытых массовых проблем от оператора
  "mass_problem:from_operator:open:get:list": "mass_problem:from_operator:open:get:list", // Получение открытых массовых проблем от оператора
  "mass_problems:get:changes": "mass_problems:get:changes", // Получение изменений массовой проблемы
  "files:upload": "files:upload", // Загрузка файлов для дальшейшего прикрепления
  "files:get:one": "files:get:one", // Получить файл
  "files:image_previews:get:one": "files:image_previews:get:one", // Получить превью изображения
  "qas:create": "qas:create", // Создать вопрос-ответ
  "qas:edit": "qas:edit", // Редактировать вопрос-ответ
  "operator_groups:create": "operator_groups:create", // Создать группу операторов
  "operator_groups:edit": "operator_groups:edit", // Редактировать группу операторов
  "operator_groups:delete": "operator_groups:delete", // Удалить группу операторов
  "operator_groups:get:one": "operator_groups:get:one", // Получение группы операторов
  "operator_groups:get:list": "operator_groups:get:list", // Получение списка групп операторов
  "operator_groups:operators:get:list": "operator_groups:operators:get:list", // Получение операторов группы
  "operator_groups:tasks:open:get:list": "operator_groups:tasks:open:get:list", // Получение открытых задач группы операторов
  "operator_groups:tasks:last_closed:get:list":
    "operator_groups:tasks:last_closed:get:list", // Получение последних закрытых задач группы операторов
  "locations:tasks:open:get:list": "locations:tasks:open:get:list", // Получить открытые задачи в локации
  "users:hardware_changes:add": "users:hardware_changes:add", // Добавить изменение по железу пользователя
  "users:hardware_changes:edit": "users:hardware_changes:edit", // Редактировать изменение по железу пользователя
  "users:hardware_changes:delete": "users:hardware_changes:delete", // Удалить изменение по железу пользователя
  "users:hardware_changes:get:list": "users:hardware_changes:get:list", // Получить список изменений по железу пользователя
  "tasks:create": "tasks:create", // Создание задачи
  "tasks:description:edit": "tasks:description:edit", // Редактирование описания задачи
  "tasks:assign:to_operator": "tasks:assign:to_operator", // Назначить задачу на оператора
  "tasks:assign:to_operators_group": "tasks:assign:to_operators_group", // Назначить задачу на группу операторов
  "tasks:close": "tasks:close", // Закрыть задачу
  "tasks:priority:edit": "tasks:priority:edit", // Изменение приоритета задачи
  "tasks:comments:post": "tasks:comments:post", // Добавить коментарий к задаче
  "tasks:comments:edit": "tasks:comments:edit", // Редактировать коментарий задачи
  "tasks:location:edit": "tasks:location:edit", // Редактировать локацию задачи
  "tasks:location_description:edit": "tasks:location_description:edit", // Редактировать описание локации задачи
  "tasks:get:task": "tasks:get:task", // Получение задачи
  "tasks:get:multiple": "tasks:get:multiple", // Получение задач по нескольким идентификаторам
  "tasks:get:issue_related_user": "tasks:get:issue_related_user", // Получение пользователя связанного с задачей
  "tasks:get:changes": "tasks:get:changes", // Получение изменений задачи
  "tasks:comments:get:list": "tasks:comments:get:list", // Получение коментариев к задаче
  "tasks:files:append": "tasks:files:append", // Добавление файлов к задаче
  "tasks:from_operator:closed:get:list": "tasks:from_operator:closed:get:list", // Получение закрытых задач от оператора
  "tasks:from_operator:open:get:list": "tasks:from_operator:open:get:list", // Получение открытых задач от оператора
  "tasks:user:delete": "tasks:user:delete", // Открепление пользователя от задачи (пользователь по которому ставится задача)
  "tasks:user:edit": "tasks:user:edit", // Обновление пользователя связанного с задачей
  "tasks:users:get:photo": "tasks:users:get:photo", // Получение фото пользователя по идентификатору задачи
  "tasks:issue_related_users:get:photo": "tasks:issue_related_users:get:photo", // Получение фото пользователя (связанного с задачей через обращение) по идентификатору задачи
  "tasks:files:delete": "tasks:files:delete", // Удаление файлов задачи
  "issues:full:create": "issues:full:create", // Создание обращение
  "issues:empty:create": "issues:empty:create", // Быстрое создание (пустого) обращения
  "issues:description:edit": "issues:description:edit", // Редактирование описания обращения
  "issues:transfer": "issues:transfer", // Передача своего обращения
  "issues:close": "issues:close", // Закрытие обращения
  "issues:close_not_mine": "issues:close_not_mine", // Закрытие обращения обслуживаемого другим оператором
  "issues:comments:post": "issues:comments:post", // Коментировать обращения
  "issues:comments:edit": "issues:comments:edit", // Редактирование комментария обращения
  "issues:files:append": "issues:files:append", // Добавление файла к обращению
  "issues:files:delete": "issues:files:delete", // Удаление файла из обращения
  "issues:tasks:get:list": "issues:tasks:get:list", // Получение задач по обращению
  "issues:get:one": "issues:get:one", // Получение обращения
  "issues:get:multiple": "issues:get:multiple", // Получение обращений оп нескольким идентификаторам
  "issues:get:changes": "issues:get:changes", // Получение изменений обращения
  "issues:from_operator:closed:get:list": "issues:from_operator:closed:get:list", // Получение закрытых задач от оператора
  "issues:from_operator:open:get:list": "issues:from_operator:open:get:list", // Получение открытых задач от оператора
  "issues:users:get:photo": "issues:users:get:photo", // Получение фото пользователя по идентификатору обращения
  "issues:comments:get:list": "issues:comments:get:list", // Получение комментариев обращения
  search: "search", // Поиск обращений/задач/массовых проблем
};

/**
 * @enum Список разрешений для отрисовки элементов пользовательского интерфейса
 */
export const UI_PERMISSIONS = {
  "ui:side-menu:issues": "ui:side-menu:issues", // Кнопка 'Обращения' в боковом меню
  "ui:side-menu:tasks": "ui:side-menu:tasks", // Кнопка 'Задачи' в боковом меню
  "ui:side-menu:mass-problems": "ui:side-menu:mass-problems", // Кнопка 'Массовые проблемы' в боковом меню
  "ui:side-menu:messanger": "ui:side-menu:messanger", // Кнопка 'Сообщения' в боковом меню
  "ui:side-menu:users": "ui:side-menu:users", // Кнопка 'Пользователи' в боковом меню
  "ui:side-menu:search": "ui:side-menu:search", // Кнопка 'Поиск' в боковом меню
  "ui:side-menu:management": "ui:side-menu:management", // Кнопка 'Управление' в боковом меню
  "ui:side-menu:profile": "ui:side-menu:profile", // Кнопка 'Профиль пользователя' в боковом меню
  "ui:side-menu:report": "ui:side-menu:report", // Кнопка 'Отчёт' в боковом меню
  "ui:issues:list:add-to-mass-problem": "ui:issues:list:add-to-mass-problem", // Кнопка 'Добавить обращения к МП' в списке обращений
  "ui:issues:list:create-issue": "ui:issues:list:create-issue", // Кнопка 'Создать обращение' в списке обращений
  "ui:issues:card:create-task": "ui:issues:card:create-task", // Кнопка 'Создать задачу' в карточке обращения
  "ui:issues:card:add-to-mass-problem": "ui:issues:card:add-to-mass-problem", // Кнопка 'Добавить к МП' в карточке обращения
  "ui:issues:card:save": "ui:issues:card:save", // Кнопка 'Сохранить' в карточке обращения
  "ui:issues:card:save-and-close": "ui:issues:card:save-and-close", // Кнопка 'Сохранить и закрыть' в карточке обращения
  "ui:issues:card:done": "ui:issues:card:done", // Кнопка 'Выполнено' в карточке обращения
  "ui:issues:card:chat:list": "ui:issues:card:chat:list", // Отображения списка сообщений в чате в карточке обращения
  "ui:issues:card:chat:post": "ui:issues:card:chat:post", // Отправить сообщение в чате в карточке обращения
  "ui:issues:card:comments:list": "ui:issues:card:comments:list", // Отображения списка комментариев в карточке обращения
  "ui:issues:card:comments:post": "ui:issues:card:comments:post", // Отправить комментарий в карточке обращения
  "ui:issues:card:comments:edit": "ui:issues:card:comments:edit", // Редактирование своего комментария в карточке обращения
  "ui:issues:card:user:workplace-location:edit":
    "ui:issues:card:user:workplace-location:edit", // Редактирование местоположения пользователя в карточке обращения
  "ui:issues:card:user:workstation-number:edit":
    "ui:issues:card:user:workstation-number:edit", // Редактирование номера компьютера пользователя в карточке обращения
  "ui:issues:card:performer:edit": "ui:issues:card:performer:edit", // Редактирование исполнителя в карточке обращения
  "ui:issues:card:description:edit": "ui:issues:card:description:edit", // Редактирование описания в карточке обращения
  "ui:issues:card:user-issues": "ui:issues:card:user-issues", // Показывать историю обращений пользователя в карточке обращения
  "ui:issues:card:user-tasks": "ui:issues:card:user-tasks", // Показывать историю задач пользователя в карточке обращения
  "ui:issues:creation-form:user-issues": "ui:issues:creation-form:user-issues", // История обращений пользователя в форме создания обращения
  "ui:issues:creation-form:user-tasks": "ui:issues:creation-form:user-tasks", // История задач пользователя в форме создания обращения
  "ui:tasks:list:create-task": "ui:tasks:list:create-task", // Кнопка 'Создать задачу' в списке задач
  "ui:tasks:card:edit-priority": "ui:tasks:card:edit-priority", // Чекбокс 'Высокий приоритет' в карточке задачи
  "ui:tasks:card:save": "ui:tasks:card:save", // Кнопка 'Сохранить' в карточке задачи
  "ui:tasks:card:save-and-close": "ui:tasks:card:save-and-close", // Кнопка 'Сохранить и закрыть' в карточке задачи
  "ui:tasks:card:done": "ui:tasks:card:done", // Кнопка 'Выполнено' в карточке задачи
  "ui:tasks:card:reject": "ui:tasks:card:reject", // Кнопка 'Отклонить' в карточке задачи
  "ui:tasks:card:comments:list": "ui:tasks:card:comments:list", // Отобразить список комментариев в карточке задачи
  "ui:tasks:card:comments:post": "ui:tasks:card:comments:post", // Отправить комментарий в карточке задачи
  "ui:tasks:card:comments:edit": "ui:tasks:card:comments:edit", // Редактирование своего комментария в карточке задачи
  "ui:tasks:card:user:workplace-location:edit":
    "ui:tasks:card:user:workplace-location:edit", // Редактирование местоположения пользователя в карточке задачи
  "ui:tasks:card:user:workstation-number:edit":
    "ui:tasks:card:user:workstation-number:edit", // Редактирование номера компьютера пользователя в карточке задачи
  "ui:tasks:card:performer:edit": "ui:tasks:card:performer:edit", // Редактирование исполнителя в карточке задачи
  "ui:tasks:card:description:edit": "ui:tasks:card:description:edit", // Редактирование описания в карточке задачи
  "ui:tasks:card:user-issues": "ui:tasks:card:user-issues", // Показывать историю обращений пользователя в карточке задачи
  "ui:tasks:card:user-tasks": "ui:tasks:card:user-tasks", // Показывать историю задач пользователя в карточке задачи
  "ui:tasks:card:reopen": "ui:tasks:card:reopen", // Отображать кнопку "Открыть повторно" в карточке задачи
  "ui:tasks:card:template:change": "ui:tasks:card:template:change", // Изменить шаблон в карточке задачи
  "ui:tasks:card:hardware:change": "ui:tasks:card:hardware:change", // Изменить оборудование в задаче
  "ui:tasks:card:hardware:remove": "ui:tasks:card:hardware:remove", // Удалить оборудование из задачи
  "ui:tasks:card:hardware:changes:list": "ui:tasks:card:hardware:changes:list", // Просмотреть историю изменений оборудования в карточке задачи
  "ui:tasks:card:hardware:changes:create": "ui:tasks:card:hardware:changes:create", // Добавить новое изменение оборудования в карточке задачи
  "ui:tasks:card:hardware:changes:edit": "ui:tasks:card:hardware:changes:edit", // Редактировать запись в истории изменений оборудования в карточке задачи
  "ui:tasks:card:hardware:changes:remove": "ui:tasks:card:hardware:changes:remove", // Удалить запись в истории изменений оборудования в карточке задачи
  "ui:tasks:creation-form:user-issues": "ui:tasks:creation-form:user-issues", // История обращений пользователя в форме создания задачи
  "ui:tasks:creation-form:user-tasks": "ui:tasks:creation-form:user-tasks", // История задач пользователя в форме создания задачи
  "ui:tasks:creation-form:related-issues": "ui:tasks:creation-form:related-issues", // Отображать список отрытых обращения пользователя, для прикрепления к задаче
  "ui:tasks:creation-form:user-data": "ui:tasks:creation-form:user-data", // Отображать информацию о пользователе в форме создания задачи
  "ui:tasks:creation-form:user-data:workplace-location:edit":
    "ui:tasks:creation-form:user-data:workplace-location:edit", // Редактировать местоположение пользователя в форме создания задачи
  "ui:tasks:creation-form:user-data:workstation-number:edit":
    "ui:tasks:creation-form:user-data:workstation-number:edit", // Редактировать номер компьютера пользователя в форме создания задачи
  "ui:mass-problems:list:create-mass-problem":
    "ui:mass-problems:list:create-mass-problem", // Кнопка 'Создать массовую проблему' в списке массовых проблем
  "ui:mass-problems:card:save": "ui:mass-problems:card:save", // Кнопка 'Сохранить' в карточке массовой проблемы
  "ui:mass-problems:card:save-and-close": "ui:mass-problems:card:save-and-close", // Кнопка 'Сохранить и закрыть' в карточке массовой проблемы
  "ui:mass-problems:card:done": "ui:mass-problems:card:done", // Кнопка 'Выполнено' в карточке массовой проблемы
  "ui:mass-problems:card:delete-issue-from-mass-problem":
    "ui:mass-problems:card:delete-issue-from-mass-problem", // Удаление обращения из массовой проблемы
  "ui:mass-problems:card:add-issue-from-mass-problem":
    "ui:mass-problems:card:add-issue-from-mass-problem", // Добавить обращения к массовой проблеме
  "ui:mass-problems:card:description:edit": "ui:mass-problems:card:description:edit", // Редактирование описания в карточке массовой проблемы
  "ui:mass-problems:card:comments:list": "ui:mass-problems:card:comments:list", // Отобразить список комментариев в карточке массовой проблемы
  "ui:mass-problems:card:comments:post": "ui:mass-problems:card:comments:post", // Отправить комментарий в карточке массовой проблемы
  "ui:mass-problems:card:comments:edit": "ui:mass-problems:card:comments:edit", // Редактирование своего комментария в карточке массовой проблемы
  "ui:messanger:take-to-work": "ui:messanger:take-to-work", // Отображать кнопку 'Взять в работу' в диалоге с пользователем
  "ui:management:operators:list": "ui:management:operators:list", // Список операторов во вкладке 'Управление'
  "ui:management:operators:card:roles:edit": "ui:management:operators:card:roles:edit", // Редактирование ролей в карточке оператора
  "ui:management:operators:card:group:edit": "ui:management:operators:card:group:edit", // Редактирование группы в карточке оператора
  "ui:management:operators-groups:list": "ui:management:operators-groups:list", // Список групп операторов во вкладке 'Управление'
  "ui:management:operators-groups:list:create":
    "ui:management:operators-groups:list:create", // Кнопка 'Создать' в списке групп
  "ui:management:operators-groups:list:delete-group":
    "ui:management:operators-groups:list:delete-group", // Кнопка 'Удалить' в списке групп
  "ui:management:operators-groups:card:edit-group-title":
    "ui:management:operators-groups:card:edit-group-title", // Переименовать название группы в карточке группы
  "ui:management:roles:list": "ui:management:roles:list", // Список ролей во вкладке 'Управление'
  "ui:management:roles:list:roles-full-graph":
    "ui:management:roles:list:roles-full-graph", // Кнопка 'Полный граф ролей' в списке ролей
  "ui:management:roles:list:create": "ui:management:roles:list:create", // Кнопка 'Создать' в списке ролей
  "ui:management:roles:list:delete-role": "ui:management:roles:list:delete-role", // Кнопка 'Удалить' в списке ролей
  "ui:management:roles:card:parents:edit": "ui:management:roles:card:parents:edit", // Наличие разрешения делает активными чекбоксы родительских ролей
  "ui:management:roles:card:permissions:edit":
    "ui:management:roles:card:permissions:edit", // Наличие разрешения делает активными чекбоксы разрешений
  "ui:management:roles:card:role-graph": "ui:management:roles:card:role-graph", // Кнопка 'Показать граф' в карточке роли
  "ui:management:locations:list": "ui:management:locations:list", // Список локаций во вкладке 'Управление'
  "ui:management:locations:list:create": "ui:management:locations:list:create", // Кнопка 'Создать' в списке локаций
  "ui:management:locations:list:delete-location":
    "ui:management:locations:list:delete-location", // Кнопка 'Удалить' в списке локаций
  "ui:management:locations:card:name:edit": "ui:management:locations:card:name:edit", // Редактирование названия локации
  "ui:management:locations:card:parent:edit": "ui:management:locations:card:parent:edit", // Редактирование родительской локации
  "ui:management:locations:card:mac-address:edit":
    "ui:management:locations:card:mac-address:edit", // Редактирование mac-адреса локации
  "ui:management:ldap-props:list": "ui:management:ldap-props:list", // Список LDAP-свойств во вкладке 'Управление'
  "ui:management:ldap-props:list:create": "ui:management:ldap-props:list:create", // Кнопка 'Создать' в списке LDAP-свойств
  "ui:management:ldap-props:card:operators-status:edit":
    "ui:management:ldap-props:card:operators-status:edit", // Редактирование статуса операторов в карточке LDAP-свойства
  "ui:management:ldap-props:card:operators-group:edit":
    "ui:management:ldap-props:card:operators-group:edit", // Редактирование группы операторов в карточке LDAP-свойства
  "ui:management:ldap-props:card:roles:edit": "ui:management:ldap-props:card:roles:edit", // Редактирование ролей в карточке LDAP-свойства
  "ui:management:ldap-props:card:delete": "ui:management:ldap-props:card:delete", // Кнопка 'Удалить' в карточке LDAP-свойства
  "ui:management:hardware": "ui:management:hardware", // Список оборудонвания во вкладке 'Управление'
  "ui:management:hardware:identifier:edit": "ui:management:hardware:identifier:edit", // Редактирование идентификатора оборудования
  "ui:management:hardware:create": "ui:management:hardware:create", // Добавить новое оборудование
  "ui:management:hardware:changes:list": "ui:management:hardware:changes:list", // Просмотреть список изменений оборудования
  "ui:management:hardware:changes:create": "ui:management:hardware:changes:create", // Добавить новое изменение оборудования
  "ui:management:hardware:changes:edit": "ui:management:hardware:changes:edit", // Редактирование изменение оборудования
  "ui:management:hardware:changes:remove": "ui:management:hardware:changes:remove", // Удалить изменение оборудования
  "ui:management:templates": "ui:management:templates", // Список шаблонов во вкладке 'Управление'
  "ui:management:templates:create": "ui:management:templates:create", // Кнопка 'Создать шаблон' в списке шаблонов
  "ui:management:templates:remove": "ui:management:templates:remove", // Кнопка 'Удалить' в списке шаблонов
  "ui:management:templates:card": "ui:management:templates:card", // Открыть карточку шаблона
  "ui:management:templates:card:edit": "ui:management:templates:card:edit", // Кнопка 'Редактировать' в карточке шаблона
  "ui:management:service-account": "ui:management:service-account", // Список сервисных аккунтов во вкладке 'Управление'
  "ui:management:service-account:create": "ui:management:service-account:create", // Создание сервисного аккаунта
  "ui:management:service-account:card": "ui:management:service-account:card", // Просмотр полной информации о сервисном аккаунте
  "ui:management:service-account:card:edit": "ui:management:service-account:card:edit", // Редактирование общих данных сервисного аккаунта
  "ui:management:service-account:card:secret":
    "ui:management:service-account:card:secret", // Просмотр авторизационных данных сервисного аккаунта
  "ui:management:service-account:card:secret:update":
    "ui:management:service-account:card:secret:update", // Обновить Secret сервисного аккаунта

  "ui:users:card:workplace-location:edit": "ui:users:card:workplace-location:edit", // Редактирование местоположения в карточке пользователя
  "ui:users:card:workstation-number:edit": "ui:users:card:workstation-number:edit", // Редактирование номера компьютера в карточке пользователя
  "ui:users:card:create-issue": "ui:users:card:create-issue", // Кнопка 'Создать обращение' в карточке пользователя
  "ui:users:card:create-task": "ui:users:card:create-task", // Кнопка 'Создать задачу' в карточке пользователя
  "ui:users:card:hardware": "ui:users:card:hardware", // Отображать список оборудования пользователя
  "ui:users:card:hardware:changes:list": "ui:users:card:hardware:changes:list", // Просмотреть список изменений оборудования в карточке пользователя
  "ui:users:card:hardware:changes:create": "ui:users:card:hardware:changes:create", // Добавить новое изменение оборудования в карточке пользователя
  "ui:users:card:hardware:changes:edit": "ui:users:card:hardware:changes:edit", // Редактирование изменение оборудования в карточке пользователя
  "ui:users:card:hardware:changes:remove": "ui:users:card:hardware:changes:remove", // Удалить изменение оборудования в карточке пользователя
  "ui:users:card:user-issues": "ui:users:card:user-issues", // История обращений пользователя в карточке пользователя
  "ui:users:card:user-tasks": "ui:users:card:user-tasks", // История задач пользователя в карточке пользователя
  "ui:users:card:messages:list": "ui:users:card:messages:list", // Отображать список сообщений в карточке пользователя
  "ui:users:card:messages:post": "ui:users:card:messages:post", // Отправлять сообщение в карточке пользователя
  "ui:users:card:update-user-info": "ui:users:card:update-user-info", // Обновление пользовательской информации из Employees
  "ui:search:filter:type:issue": "ui:search:filter:type:issue", // Поиск -> Фильтр -> Тип -> Отображать опцию 'Обращение'
  "ui:search:filter:type:issue-comment": "ui:search:filter:type:issue-comment", // Поиск -> Фильтр -> Тип -> Отображать опцию 'Комментарий к обращению'
  "ui:search:filter:type:task": "ui:search:filter:type:task", // Поиск -> Фильтр -> Тип -> Отображать опцию 'Задача'
  "ui:search:filter:type:task-comment": "ui:search:filter:type:task-comment", // Поиск -> Фильтр -> Тип -> Отображать опцию 'Комментарий к задаче'
  "ui:search:filter:type:mass-problem": "ui:search:filter:type:mass-problem", // Поиск -> Фильтр -> Тип -> Отображать опцию 'Массовая проблема'
  "ui:search:filter:type:mass-problem-comment":
    "ui:search:filter:type:mass-problem-comment", // Поиск -> Фильтр -> Тип -> Отображать опцию 'Комментарий к массовой проблеме'
};

/**
 * @enum Карта разрешений для доступа к вкладке
 */
export const TABS_PERMISSIONS_MAP = {
  [COMPONENTS.ISSUE_CARD]: [
    PERMISSIONS["issues:get:multiple"],
    PERMISSIONS["issues:get:one"],
    PERMISSIONS["issues:tasks:get:list"],
    PERMISSIONS["operators:list:all"],
    PERMISSIONS["operators:open_tasks:count"],
    PERMISSIONS["search"],
    PERMISSIONS["tasks:get:multiple"],
    PERMISSIONS["users:get:by_id"],
    // PERMISSIONS['users:photos:get:one'],
  ],
  [COMPONENTS.ISSUE_CREATION_FORM]: [
    PERMISSIONS["files:upload"],
    PERMISSIONS["issues:full:create"],
    PERMISSIONS["issues:get:multiple"],
    PERMISSIONS["issues:get:one"],
    PERMISSIONS["issues:tasks:get:list"],
    PERMISSIONS["operators:get:one:by_id"],
    PERMISSIONS["operators:list:all"],
    PERMISSIONS["operators:open_tasks:count"],
    PERMISSIONS["search"],
    PERMISSIONS["tasks:get:multiple"],
    PERMISSIONS["users:get:by_id"],
    PERMISSIONS["users:search"],
  ],
  [COMPONENTS.ISSUE_LIST]: [
    PERMISSIONS["issues:get:multiple"],
    PERMISSIONS["operators:get:one:by_id"],
    PERMISSIONS["operators:open_tasks:count"],
    PERMISSIONS["search"],
  ],
  [COMPONENTS.GROUP_CARD]: [
    PERMISSIONS["operator_groups:get:one"],
    PERMISSIONS["operator_groups:edit"],
    PERMISSIONS["operators:list:group_operators"],
    PERMISSIONS["operators:open_tasks:count"],
  ],
  [COMPONENTS.GROUP_CREATION_FORM]: [PERMISSIONS["operator_groups:create"]],
  [COMPONENTS.LDAP_PROP_CARD]: [
    PERMISSIONS["operator_groups:get:list"],
    PERMISSIONS["operator_statuses:get:list"],
    PERMISSIONS["rbac:roles:get:list"],
  ],
  [COMPONENTS.LDAP_PROPS_CREATION_FORM]: [
    PERMISSIONS["operator_ldap_props:create"],
    PERMISSIONS["operator_groups:get:list"],
    PERMISSIONS["operator_statuses:get:list"],
    PERMISSIONS["rbac:roles:get:list"],
  ],
  [COMPONENTS.LOCATION_CARD]: [
    PERMISSIONS["locations:title:edit"],
    PERMISSIONS["locations:parent:edit"],
    PERMISSIONS["locations:mac_addresses:replace"],
  ],
  [COMPONENTS.LOCATION_CREATION_FORM]: [
    PERMISSIONS["locations:create"],
    PERMISSIONS["locations:get:list"],
  ],
  [COMPONENTS.MASS_PROBLEM_CARD]: [
    PERMISSIONS["mass_problems:get:one"],
    PERMISSIONS["mass_problems:issues:open:get:list"],
    PERMISSIONS["operator_groups:get:one"],
    PERMISSIONS["operator_groups:issues:open:get:list"],
    PERMISSIONS["operators:list:all"],
    PERMISSIONS["operators:open_tasks:count"],
    PERMISSIONS["users:get:by_id"],
  ],
  [COMPONENTS.MASS_PROBLEM_CREATION_FORM]: [
    PERMISSIONS["operator_groups:get:one"],
    PERMISSIONS["operator_groups:issues:open:get:list"],
    PERMISSIONS["operators:get:one:by_id"],
    PERMISSIONS["operators:open_tasks:count"],
    PERMISSIONS["users:get:by_id"],
  ],
  [COMPONENTS.MASS_PROBLEM_LIST]: [PERMISSIONS["mass_problems:get:list"]],
  [COMPONENTS.MESSANGER]: [
    PERMISSIONS["operators:issues:open:get:list"],
    PERMISSIONS["users:chats_require_attention:get:list"],
    PERMISSIONS["users:get:by_id"],
    PERMISSIONS["users:messages:get:list"],
    PERMISSIONS["users:photos:get:one"],
  ],
  [COMPONENTS.OPERATOR_CARD]: [
    PERMISSIONS["operator_groups:get:list"],
    PERMISSIONS["operators:get:one:by_id"],
    PERMISSIONS["operators:open_tasks:count"],
    PERMISSIONS["rbac:roles:get:list"],
  ],
  [COMPONENTS.MANAGEMENT]: [
    PERMISSIONS["operator_groups:get:list"],
    PERMISSIONS["operators:list:all"],
    PERMISSIONS["operators:open_tasks:count"],
  ],
  [COMPONENTS.PROFILE]: [PERMISSIONS["operator_groups:get:one"]],
  [COMPONENTS.ROLE_CARD]: [
    PERMISSIONS["available_permissions:get:list"],
    PERMISSIONS["rbac:roles:get:list"],
  ],
  [COMPONENTS.ROLE_CREATION_FORM]: [
    PERMISSIONS["available_permissions:get:list"],
    PERMISSIONS["rbac:roles:get:list"],
    PERMISSIONS["rbac:roles:create"],
  ],
  [COMPONENTS.TASK_CARD]: [
    PERMISSIONS["issues:get:multiple"],
    PERMISSIONS["operator_groups:get:list"],
    PERMISSIONS["operators:list:all"],
    PERMISSIONS["operators:open_tasks:count"],
    PERMISSIONS["search"],
    PERMISSIONS["tasks:get:multiple"],
    PERMISSIONS["tasks:get:task"],
    PERMISSIONS["users:get:by_id"],
    // PERMISSIONS['users:photos:get:one'],
  ],
  [COMPONENTS.TASK_CREATION_FORM]: [
    PERMISSIONS["files:upload"],
    PERMISSIONS["issues:get:multiple"],
    PERMISSIONS["issues:get:one"],
    PERMISSIONS["locations:get:list"],
    PERMISSIONS["operator_groups:get:list"],
    PERMISSIONS["operators:get:one:by_id"],
    PERMISSIONS["operators:list:all"],
    PERMISSIONS["operators:open_tasks:count"],
    PERMISSIONS["search"],
    PERMISSIONS["tasks:create"],
    PERMISSIONS["tasks:get:multiple"],
    PERMISSIONS["tasks:get:task"],
    PERMISSIONS["users:get:by_id"],
    PERMISSIONS["users:issues:open:get:list"],
  ],
  [COMPONENTS.TASK_LIST]: [
    PERMISSIONS["tasks:get:multiple"],
    PERMISSIONS["operators:get:one:by_id"],
    PERMISSIONS["operators:open_tasks:count"],
    PERMISSIONS["search"],
  ],
  [COMPONENTS.USER_CARD]: [
    PERMISSIONS["issues:get:multiple"],
    PERMISSIONS["search"],
    PERMISSIONS["tasks:get:multiple"],
    PERMISSIONS["users:get:by_id"],
    PERMISSIONS["users:messages:get:list"],
    // PERMISSIONS['users:photos:get:one'],
  ],
  [COMPONENTS.USER_LIST]: [PERMISSIONS["users:search"]],
  [COMPONENTS.SEARCH]: [
    PERMISSIONS["search"],
    PERMISSIONS["operator_groups:get:list"],
    PERMISSIONS["operators:list:all"],
    PERMISSIONS["operators:open_tasks:count"],
  ],
};

/**
 * @enum Карта разрешений для отображения элементов интерфейса
 */
export const COMPONENTS_PERMISSIONS_MAP = {
  SIDE_MENU: {
    ISSUE_LIST_BUTTON: [PERMISSIONS["issues:get:multiple"]],
    TASK_LIST_BUTTON: [PERMISSIONS["tasks:get:multiple"]],
    MASS_PROBLEM_LIST_BUTTON: [PERMISSIONS["mass_problems:get:list"]],
    MESSANGER: [
      PERMISSIONS["operators:issues:open:get:list"],
      PERMISSIONS["users:chats_require_attention:get:list"],
      PERMISSIONS["users:get:by_id"],
      PERMISSIONS["users:messages:get:list"],
      PERMISSIONS["users:photos:get:one"],
    ],
    USER_LIST: [PERMISSIONS["users:search"]],
    SEARCH: [
      PERMISSIONS["search"],
      PERMISSIONS["operator_groups:get:list"],
      PERMISSIONS["operators:list:all"],
      PERMISSIONS["operators:open_tasks:count"],
    ],
    MANAGEMENT: [
      PERMISSIONS["operator_groups:get:list"],
      PERMISSIONS["operators:list:all"],
      PERMISSIONS["operators:open_tasks:count"],
    ],
    PROFILE: [PERMISSIONS["operator_groups:get:one"]],
  },
  [COMPONENTS.ISSUE_LIST]: {
    ADD_TO_MASS_PROBLEM_BUTTON: [
      PERMISSIONS["mass_problems:get:list"],
      PERMISSIONS["mass_problems:issues:add"],
      PERMISSIONS["operator_groups:get:list"],
      PERMISSIONS["operators:list:all"],
      PERMISSIONS["operators:open_tasks:count"],
      PERMISSIONS["users:get:by_id"],
      PERMISSIONS["users:issues:open:get:list"],
      PERMISSIONS["users:search"],
    ],
    CREATE_BUTTON: [
      PERMISSIONS["files:upload"],
      PERMISSIONS["issues:full:create"],
      PERMISSIONS["issues:get:multiple"],
      PERMISSIONS["issues:get:one"],
      PERMISSIONS["issues:tasks:get:list"],
      PERMISSIONS["operators:get:one:by_id"],
      PERMISSIONS["operators:list:all"],
      PERMISSIONS["operators:open_tasks:count"],
      PERMISSIONS["search"],
      PERMISSIONS["tasks:get:multiple"],
      PERMISSIONS["users:get:by_id"],
      PERMISSIONS["users:search"],
    ],
  },
  [COMPONENTS.ISSUE_CARD]: {
    CREATE_TASK_BUTTON: [
      PERMISSIONS["files:upload"],
      PERMISSIONS["issues:get:multiple"],
      PERMISSIONS["issues:get:one"],
      PERMISSIONS["locations:get:list"],
      PERMISSIONS["operator_groups:get:list"],
      PERMISSIONS["operators:get:one:by_id"],
      PERMISSIONS["operators:list:all"],
      PERMISSIONS["operators:open_tasks:count"],
      PERMISSIONS["search"],
      PERMISSIONS["tasks:comments:get:list"],
      PERMISSIONS["tasks:create"],
      PERMISSIONS["tasks:get:multiple"],
      PERMISSIONS["tasks:get:task"],
      PERMISSIONS["users:get:by_id"],
      PERMISSIONS["users:issues:open:get:list"],
      PERMISSIONS["users:photos:get:one"],
    ],
    ADD_TO_MASS_PROBLEM_BUTTON: [
      PERMISSIONS["mass_problems:get:list"],
      PERMISSIONS["mass_problems:issues:add"],
      PERMISSIONS["operator_groups:get:list"],
      PERMISSIONS["operators:list:all"],
      PERMISSIONS["operators:open_tasks:count"],
      PERMISSIONS["users:get:by_id"],
      PERMISSIONS["users:issues:open:get:list"],
      PERMISSIONS["users:search"],
    ],
    SAVE_BUTTON: [
      PERMISSIONS["issues:description:edit"],
      PERMISSIONS["issues:files:append"],
    ],
    SAVE_AND_CLOSE_BUTTON: [
      PERMISSIONS["issues:description:edit"],
      PERMISSIONS["issues:files:append"],
    ],
    DONE_BUTTON: [PERMISSIONS["issues:close"]],
    CHAT: [
      PERMISSIONS["files:upload"],
      PERMISSIONS["users:messages:get:list"],
      PERMISSIONS["users:messages:post:file"],
      PERMISSIONS["users:messages:post:text"],
      PERMISSIONS["users:photos:get:one"],
    ],
    COMMENTS: [
      PERMISSIONS["issues:comments:edit"],
      PERMISSIONS["issues:comments:get:list"],
      PERMISSIONS["issues:comments:post"],
      PERMISSIONS["operators:get:one:by_id"],
      PERMISSIONS["operators:open_tasks:count"],
    ],
  },
  [COMPONENTS.ISSUE_CREATION_FORM]: [
    PERMISSIONS["files:upload"],
    PERMISSIONS["issues:full:create"],
    PERMISSIONS["issues:get:multiple"],
    PERMISSIONS["issues:get:one"],
    PERMISSIONS["issues:tasks:get:list"],
    PERMISSIONS["operators:get:one:by_id"],
    PERMISSIONS["operators:list:all"],
    PERMISSIONS["operators:open_tasks:count"],
    PERMISSIONS["search"],
    PERMISSIONS["tasks:get:multiple"],
    PERMISSIONS["users:get:by_id"],
    PERMISSIONS["users:search"],
  ],
  [COMPONENTS.TASK_LIST]: {
    CREATE_BUTTON: [
      PERMISSIONS["files:upload"],
      PERMISSIONS["issues:get:multiple"],
      PERMISSIONS["issues:get:one"],
      PERMISSIONS["locations:get:list"],
      PERMISSIONS["operator_groups:get:list"],
      PERMISSIONS["operators:get:one:by_id"],
      PERMISSIONS["operators:list:all"],
      PERMISSIONS["operators:open_tasks:count"],
      PERMISSIONS["search"],
      PERMISSIONS["tasks:create"],
      PERMISSIONS["tasks:get:multiple"],
      PERMISSIONS["tasks:get:task"],
      PERMISSIONS["users:get:by_id"],
      PERMISSIONS["users:issues:open:get:list"],
    ],
  },
  [COMPONENTS.TASK_CARD]: {
    PRIORITY_CHECKBOX: [PERMISSIONS["tasks:priority:edit"]],
    SAVE_BUTTON: [
      PERMISSIONS["files:upload"],
      PERMISSIONS["tasks:assign:to_operators_group"],
      PERMISSIONS["tasks:description:edit"],
      PERMISSIONS["tasks:files:append"],
    ],
    SAVE_AND_CLOSE_BUTTON: [
      PERMISSIONS["files:upload"],
      PERMISSIONS["tasks:assign:to_operators_group"],
      PERMISSIONS["tasks:description:edit"],
      PERMISSIONS["tasks:files:append"],
    ],
    DONE_BUTTON: [PERMISSIONS["tasks:close"]],
    COMMENTS: [
      PERMISSIONS["tasks:comments:edit"],
      PERMISSIONS["tasks:comments:get:list"],
      PERMISSIONS["tasks:comments:post"],
    ],
  },
  [COMPONENTS.TASK_CREATION_FORM]: [
    PERMISSIONS["files:upload"],
    PERMISSIONS["issues:get:multiple"],
    PERMISSIONS["issues:get:one"],
    PERMISSIONS["locations:get:list"],
    PERMISSIONS["operator_groups:get:list"],
    PERMISSIONS["operators:get:one:by_id"],
    PERMISSIONS["operators:list:all"],
    PERMISSIONS["operators:open_tasks:count"],
    PERMISSIONS["search"],
    PERMISSIONS["tasks:create"],
    PERMISSIONS["tasks:get:multiple"],
    PERMISSIONS["tasks:get:task"],
    PERMISSIONS["users:get:by_id"],
    PERMISSIONS["users:issues:open:get:list"],
  ],
  [COMPONENTS.MASS_PROBLEM_LIST]: {
    CREATE_BUTTON: [
      PERMISSIONS["files:upload"],
      PERMISSIONS["mass_problems:create"],
      PERMISSIONS["mass_problems:get:one"],
      PERMISSIONS["mass_problems:issues:open:get:list"],
      PERMISSIONS["operator_groups:get:one"],
      PERMISSIONS["operator_groups:issues:open:get:list"],
      PERMISSIONS["operators:get:one:by_id"],
      PERMISSIONS["operators:list:all"],
      PERMISSIONS["operators:open_tasks:count"],
      PERMISSIONS["users:get:by_id"],
    ],
  },
  [COMPONENTS.MASS_PROBLEM_CARD]: {
    SAVE_BUTTON: [
      PERMISSIONS["files:upload"],
      PERMISSIONS["mass_problems:description:edit"],
      PERMISSIONS["mass_problems:files:add"],
      PERMISSIONS["mass_problems:issues:add"],
      PERMISSIONS["mass_problems:issues:open:get:list"],
    ],
    SAVE_AND_CLOSE_BUTTON: [
      PERMISSIONS["files:upload"],
      PERMISSIONS["mass_problems:description:edit"],
      PERMISSIONS["mass_problems:files:add"],
      PERMISSIONS["mass_problems:issues:add"],
      PERMISSIONS["mass_problems:issues:open:get:list"],
    ],
    DONE_BUTTON: [PERMISSIONS["issues:close"], PERMISSIONS["mass_problems:close"]],
    COMMENTS: [
      PERMISSIONS["mass_problems:comments:edit"],
      PERMISSIONS["mass_problems:comments:list"],
      PERMISSIONS["mass_problems:comments:post"],
      PERMISSIONS["operators:get:one:by_id"],
      PERMISSIONS["operators:open_tasks:count"],
    ],
  },
  [COMPONENTS.MASS_PROBLEM_CREATION_FORM]: [
    PERMISSIONS["files:upload"],
    PERMISSIONS["mass_problems:create"],
    PERMISSIONS["mass_problems:get:one"],
    PERMISSIONS["mass_problems:issues:open:get:list"],
    PERMISSIONS["operator_groups:get:one"],
    PERMISSIONS["operator_groups:issues:open:get:list"],
    PERMISSIONS["operators:get:one:by_id"],
    PERMISSIONS["operators:list:all"],
    PERMISSIONS["operators:open_tasks:count"],
    PERMISSIONS["users:get:by_id"],
  ],
  [COMPONENTS.MESSANGER]: {
    CREATE_EMPTY_ISSUE_BUTTON: [PERMISSIONS["issues:empty:create"]],
    CREATE_ISSUE_BUTTON: [PERMISSIONS["issues:full:create"]],
  },
  [COMPONENTS.USER_CARD]: [
    PERMISSIONS["issues:get:multiple"],
    PERMISSIONS["search"],
    PERMISSIONS["tasks:get:multiple"],
    PERMISSIONS["users:get:by_id"],
    PERMISSIONS["users:messages:get:list"],
    PERMISSIONS["users:photos:get:one"],
  ],
  [COMPONENTS.SEARCH]: [
    PERMISSIONS["issues:get:multiple"],
    PERMISSIONS["mass_problems:get:multiple"],
    PERMISSIONS["operators:get:one:by_id"],
    PERMISSIONS["operators:open_tasks:count"],
    PERMISSIONS["search"],
    PERMISSIONS["tasks:get:multiple"],
  ],
  [COMPONENTS.PROFILE]: [PERMISSIONS["operator_groups:get:one"]],
  [COMPONENTS.MANAGEMENT]: {
    OPERATORS: {
      _permissions: [
        PERMISSIONS["operator_groups:get:list"],
        PERMISSIONS["operators:list:all"],
        PERMISSIONS["operators:open_tasks:count"],
      ],
    },
    GROUPS: {
      _permissions: [
        PERMISSIONS["operator_groups:get:list"],
        PERMISSIONS["operators:list:all"],
        PERMISSIONS["operators:open_tasks:count"],
      ],
      CREATE_BUTTON: [PERMISSIONS["operator_groups:create"]],
      DELETE_OPERATOR_GROUP_BUTTON: [PERMISSIONS["operator_groups:delete"]],
    },
    ROLES: {
      _permissions: [PERMISSIONS["rbac:roles:get:list"]],
      ROLES_GRAPH_BUTTON: [PERMISSIONS["rbac:graph:full"]],
      CREATE_BUTTON: [PERMISSIONS["rbac:roles:get:list"]],
    },
    LOCATIONS: {
      _permissions: [PERMISSIONS["locations:get:list"]],
      CREATE_BUTTON: [PERMISSIONS["locations:create"]],
      DELETE_LOCATION_BUTTON: [PERMISSIONS["locations:delete"]],
    },
    LDAP_PROPS: {
      _permissions: [PERMISSIONS["operator_ldap_props:list"]],
      CREATE_BUTTON: [PERMISSIONS["operator_ldap_props:create"]],
    },
  },
  [COMPONENTS.OPERATOR_CARD]: [
    PERMISSIONS["operator_groups:get:list"],
    PERMISSIONS["operators:get:one:by_id"],
    PERMISSIONS["operators:open_tasks:count"],
    PERMISSIONS["rbac:roles:get:list"],
  ],
  [COMPONENTS.GROUP_CARD]: [
    PERMISSIONS["operator_groups:get:one"],
    PERMISSIONS["operator_groups:edit"],
    PERMISSIONS["operators:list:group_operators"],
    PERMISSIONS["operators:open_tasks:count"],
  ],
  [COMPONENTS.ROLE_CARD]: [
    PERMISSIONS["available_permissions:get:list"],
    PERMISSIONS["rbac:roles:get:list"],
  ],
  [COMPONENTS.LOCATION_CARD]: [
    PERMISSIONS["locations:title:edit"],
    PERMISSIONS["locations:parent:edit"],
    PERMISSIONS["locations:mac_addresses:replace"],
  ],
  [COMPONENTS.LDAP_PROP_CARD]: {
    DELETE_LDAP_PROP_BUTTON: [PERMISSIONS["operator_ldap_props:delete"]],
  },
};
