import api from "./api";
import { TEMPLATES_API_URLS } from "../consts";

export const getTemplateList = async (operatorsGroupId) => {
  const url = TEMPLATES_API_URLS.getTemplateList(operatorsGroupId);
  return await api.protectedGet(url);
};

export const getTemplate = async (templateId) => {
  const url = TEMPLATES_API_URLS.getTemplate(templateId);
  return await api.protectedGet(url);
};

/**
 * @param {string} templateId
 * @param {object} template
 * @param {string} template.title - название шаблона
 * @param {string} template.text - текст шаблона
 */
export const updateTemplate = async (templateId, template) => {
  const url = TEMPLATES_API_URLS.updateTemplate(templateId);
  return await api.protectedPatch(url, template);
};

export const deleteTemplate = async (templateId) => {
  const url = TEMPLATES_API_URLS.deleteTemplate(templateId);
  return await api.protectedDelete(url);
};

/**
 * @param {object} template
 * @param {string} template.title - название шаблона
 * @param {string} template.text - текст шаблона
 */
export const createTemplate = async (template) => {
  const url = TEMPLATES_API_URLS.createTemplate();
  return await api.protectedPost(url, template);
};

const TEMPLATES_API = {
  getTemplate,
  getTemplateList,
  updateTemplate,
  deleteTemplate,
  createTemplate,
};

export default TEMPLATES_API;
