import isEqual from "lodash.isequal";
import { useSelector } from "react-redux";

import { CHECK_PERMISSIONS } from "../../consts";
// import { TABS_PERMISSIONS_MAP } from "./permissions";

export default function useRBAC() {
  const userPermissions = useSelector((store) =>
    store.user.permissions.map((p) => p.split(":"))
  );

  const checkAccess = (requiredPermission = "") => {
    const splitted = requiredPermission.split(":");

    for (let i = 0; i < userPermissions.length; i++) {
      const permission = userPermissions[i];

      if (permission.length === 1) {
        if (requiredPermission.startsWith(permission[0])) {
          return true;
        } else {
          continue;
        }
      } else {
        if (isEqual(permission, splitted)) {
          return true;
        } else {
          continue;
        }
      }
    }

    return false;
  };

  // /**
  //  * Проверка необходимых разрешений для перехода во вкладку
  //  * @param {string} requestedTab - название компонента, который хочет открыть пользователь
  //  * @returns {boolean}
  // */
  // const checkAccessToTab = (requestedTab) => {
  //   if (!(requestedTab in TABS_PERMISSIONS_MAP)) {
  //     throw Error('Tab not found');
  //   }

  //   const tabRequiredPermissions = TABS_PERMISSIONS_MAP[requestedTab];
  //   const hasAccess = tabRequiredPermissions.every((_permission) => checkAccess(_permission));

  //   return hasAccess;
  // }

  /**
   * Проверка разрешений для отображения элемента интерфейса
   * @param {string[]} requiredPermissions
   * @returns {boolean}
   */
  const checkPermissionsToRenderComponent = (requiredPermissions) => {
    return requiredPermissions.every((_permission) => checkAccess(_permission));
  };

  /**
   * Проверка разрешения для отрисовки пользовательского интерфейса
   * @param {string} permission Разрешение на отрисовку элемента пользовательского интерфейса
   * @returns {boolean}
   */
  const checkPermissionToRenderUI = (permission) => {
    return checkAccess(permission);
  };

  return CHECK_PERMISSIONS
    ? {
        checkAccess,
        checkAccessToTab: () => true,
        // checkAccessToTab, // TODO: временно убрана проверка вкладок на время настройки прав пользователей
        checkPermissionToRenderUI,
        checkPermissionsToRenderComponent,
      }
    : {
        checkAccess: () => true,
        checkAccessToTab: () => true,
        checkPermissionToRenderUI: () => true,
        checkPermissionsToRenderComponent: () => true,
      };
}

export {
  PERMISSIONS,
  TABS_PERMISSIONS_MAP,
  COMPONENTS_PERMISSIONS_MAP,
  UI_PERMISSIONS,
} from "./permissions";
