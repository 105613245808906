import React, { useCallback } from "react";
import { Select, DatePicker, Button, Typography, Checkbox } from "antd";
import ru_RU from "antd/lib/date-picker/locale/ru_RU";

import SearchFilters, {
  FilterContainer,
} from "../../components/SearchPreciseParams/index2";
import { FULL_DATE } from "../../utils/normalizeDate";
import { FILTER_NAMES } from "./model";

const { RangePicker } = DatePicker;
const { Text } = Typography;

const SELECT_PROPS = {
  value: [],
  allowClear: true,
  showSearch: true,
  style: { width: 260 },
  size: "small",
  placeholder: "Поиск",
  optionFilterProp: "label",
};

const Filters = (props) => {
  const {
    filters,
    fieldOptions,
    groups,
    operators,
    locations,
    validation,
    fetching,
    onChange,
    onReset,
    onGetReport,
  } = props;
  const { opId, fromOpId, opGroupId, excludeOpId, field, period, onlyOpen, locationId } =
    filters;

  const onRemoveSelected = useCallback(
    (fieldName, selected, removed) => {
      const { [removed]: _, ...rest } = selected;

      onChange(fieldName, rest);
    },
    [onChange]
  );

  const onDatesChanged = (dates) => {
    if (dates === null) {
      onChange(FILTER_NAMES.period, []);
    } else {
      onChange(FILTER_NAMES.period, dates);
    }
  };

  return (
    <SearchFilters title="Параметры" onResetFilters={onReset}>
      <FilterContainer
        label="Оператор"
        selected={opId}
        onRemoveSelected={(removed) => onRemoveSelected(FILTER_NAMES.opId, opId, removed)}
      >
        <Select
          {...SELECT_PROPS}
          options={operators}
          onSelect={(value, option) =>
            onChange(FILTER_NAMES.opId, {
              ...opId,
              [value]: { value, title: option.label },
            })
          }
        />
      </FilterContainer>

      <FilterContainer
        label="Группы оператора"
        selected={opGroupId}
        onRemoveSelected={(removed) =>
          onRemoveSelected(FILTER_NAMES.opGroupId, opGroupId, removed)
        }
      >
        <Select
          {...SELECT_PROPS}
          options={groups}
          onSelect={(value, option) =>
            onChange(FILTER_NAMES.opGroupId, {
              ...opGroupId,
              [value]: { value, title: option.label },
            })
          }
        />
      </FilterContainer>

      <FilterContainer
        label="От оператора"
        selected={fromOpId}
        onRemoveSelected={(removed) =>
          onRemoveSelected(FILTER_NAMES.fromOpId, fromOpId, removed)
        }
      >
        <Select
          {...SELECT_PROPS}
          options={operators}
          onSelect={(value, option) =>
            onChange(FILTER_NAMES.fromOpId, {
              ...fromOpId,
              [value]: { value, title: option.label },
            })
          }
        />
      </FilterContainer>

      <FilterContainer
        label="Исключить операторов"
        selected={excludeOpId}
        onRemoveSelected={(removed) =>
          onRemoveSelected(FILTER_NAMES.excludeOpId, excludeOpId, removed)
        }
      >
        <Select
          {...SELECT_PROPS}
          options={operators}
          onSelect={(value, option) =>
            onChange(FILTER_NAMES.excludeOpId, {
              ...excludeOpId,
              [value]: { value, title: option.label },
            })
          }
        />
      </FilterContainer>

      <FilterContainer
        label="Добавить поля в отчёт"
        selected={field}
        onRemoveSelected={(removed) =>
          onRemoveSelected(FILTER_NAMES.field, field, removed)
        }
      >
        <Select
          {...SELECT_PROPS}
          placeholder=""
          options={fieldOptions}
          onSelect={(value, option) =>
            onChange(FILTER_NAMES.field, {
              ...field,
              [value]: { value, title: option.label },
            })
          }
        />
      </FilterContainer>

      <FilterContainer label="За период">
        <RangePicker
          size="small"
          style={{ width: 260 }}
          locale={ru_RU}
          format={FULL_DATE}
          value={period}
          onChange={onDatesChanged}
        />
      </FilterContainer>

      <FilterContainer>
        <Checkbox
          checked={onlyOpen}
          onChange={(event) => onChange(FILTER_NAMES.onlyOpen, event.target.checked)}
        >
          Только открытые
        </Checkbox>
      </FilterContainer>

      <FilterContainer
        label="Локации"
        selected={locationId}
        onRemoveSelected={(removed) =>
          onRemoveSelected(FILTER_NAMES.locationId, locationId, removed)
        }
      >
        <Select
          {...SELECT_PROPS}
          options={locations}
          onSelect={(value, option) =>
            onChange(FILTER_NAMES.locationId, {
              ...locationId,
              [value]: { value, title: option.label },
            })
          }
        />
      </FilterContainer>

      <FilterContainer>
        <Button
          style={{ margin: "24px 0 4px" }}
          children="Сформировать отчёт"
          disabled={!validation.resolution}
          onClick={onGetReport}
          loading={fetching}
        />
        <Text style={{ display: "block", color: "red" }}>{validation?.message}</Text>
      </FilterContainer>
    </SearchFilters>
  );
};

export default Filters;
