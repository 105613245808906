import React from "react";
import { Button } from "antd";

import { getOperatorData } from "../../../api/operators";
import { getUserData } from "../../../api/users";

const GoTo = ({ onClick }) => {
  return (
    <Button type="primary" onClick={onClick}>
      Перейти
    </Button>
  );
};

export default async (data) => {
  const { operator_id, user_id, onOpenTab } = data;
  let operatorName;
  let userName;

  await getOperatorData(operator_id)
    .then(({ data }) => (operatorName = `${data.last_name} ${data.first_name}`))
    .catch(() => (operatorName = "Оператор не найден"));

  await getUserData(user_id)
    .then(({ data }) => {
      const { current_pp_name, last_name, first_name } = data;
      const full_name = `${last_name} ${first_name}`.trim();

      userName = current_pp_name ? `${current_pp_name} (${full_name})` : full_name;
    })
    .catch(() => (userName = "Пользователь не найден"));

  return {
    message: "Новое обращение",
    description: `${operatorName} создал обращение от ${userName}`,
    btn: <GoTo onClick={onOpenTab} />,
  };
};
