import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { Row, Col, Button, Modal, message } from "antd";

import MainTable, { TABLE_STYLE } from "../../components/Table";
import TemplateCreationForm from "../../components/TemplateCreationForm";
import TemplateCard from "../../components/TemplateCard";

import TEMPLATES_API from "../../api/templates";
import OPERATORS_API from "../../api/operators";
import ERROR_ACTIONS from "../../actions/errors/errors";
import useRBAC, { UI_PERMISSIONS } from "../../services/rbac";

const TableHeader = ({ onClick, permissions }) => {
  return (
    <Row justify="end">
      <Col>
        {permissions.create && (
          <Button type="primary" children="Создать шаблон" onClick={onClick} />
        )}
      </Col>
    </Row>
  );
};

const Templates = () => {
  const dispatch = useDispatch();
  const { checkPermissionToRenderUI } = useRBAC();

  const COLUMNS = [
    {
      dataIndex: "title",
      title: "Название",
      customFilter: true,
      ellipsis: true,
    },
    {
      dataIndex: "text",
      title: "Текст",
      customFilter: true,
      ellipsis: true,
    },
    {
      dataIndex: "operator_groups",
      title: "Группы операторов",
      render: (groups) => groups.map((_g) => _g.title).join(", "),
      ellipsis: true,
    },
  ];

  if (
    checkPermissionToRenderUI(UI_PERMISSIONS["ui:management:templates:remove"])
  ) {
    COLUMNS.push({
      dataIndex: "",
      render: (_, row) => (
        <Button
          danger
          type="primary"
          children="Удалить"
          onClick={(event) => {
            event.preventDefault();
            event.stopPropagation();
            deleteTemplate(row.id);
          }}
        />
      ),
      width: 104,
    });
  }

  // State
  const [loading, setLoading] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [submitting, setSubmitting] = useState(false);
  const [creationFormVisible, setCreationFormVisible] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedTemplateEditing, setSelectedTemplateEditing] = useState(false);
  const [operatorsGroups, setOperatorsGroups] = useState([]);

  const getOperatorsGroups = useCallback(async () => {
    try {
      const response = await OPERATORS_API.getOperatorsGroupsList();
      return response.data.map((group) => ({
        value: group.id,
        label: group.title,
      }));
    } catch (err) {
      console.error(err);
      dispatch(
        ERROR_ACTIONS.addError({
          title: "Не удалось получить список групп операторов",
          message: err.response.data?.message,
          status: err.response.status,
        })
      );
      return [];
    }
  }, [dispatch]);

  const getTemplateList = useCallback(async () => {
    try {
      const response = await TEMPLATES_API.getTemplateList();
      return response.data;
    } catch (err) {
      console.error(err);
      dispatch(
        ERROR_ACTIONS.addError({
          title: "Не удалось получить список шаблонов",
          message: err.response.data?.message,
          status: err.response.status,
        })
      );
      return [];
    }
  }, [dispatch]);

  const createTemplate = async (form) => {
    await setSubmitting(true);

    try {
      const response = await TEMPLATES_API.createTemplate(form);
      setTemplates((templates) => [...templates, response.data]);
      setCreationFormVisible(false);
      message.success("Шаблон создан");
    } catch (err) {
      console.error(err);
      dispatch(
        ERROR_ACTIONS.addError({
          title: "Не удалось создать шаблон",
          message: err.response.data?.message,
          status: err.response.status,
        })
      );
    }

    await setSubmitting(false);
  };

  const getTemplate = useCallback(() => {
    if (!selectedTemplate) return {};

    return templates.find((_t) => _t.id === selectedTemplate);
  }, [templates, selectedTemplate]);

  const updateTemplate = async (templateId, template) => {
    try {
      const response = await TEMPLATES_API.updateTemplate(templateId, template);
      setTemplates((templates) =>
        templates.map((_t) => (_t.id === templateId ? response.data : _t))
      );
      setSelectedTemplateEditing(false);
      message.success("Шаблон изменен");
    } catch (err) {
      console.error(err);
      dispatch(
        ERROR_ACTIONS.addError({
          title: "Не удалось изменить шаблон",
          message: err.response.data?.message,
          status: err.response.status,
        })
      );
    }
  };

  const deleteTemplate = async (templateId) => {
    try {
      await TEMPLATES_API.deleteTemplate(templateId);
      setTemplates((templates) =>
        templates.filter((_t) => _t.id !== templateId)
      );
      message.success("Шаблон удален");
    } catch (err) {
      console.error(err);
      dispatch(
        ERROR_ACTIONS.addError({
          title: "Не удалось удалить шаблон",
          message: err.response.data?.message,
          status: err.response.status,
        })
      );
    }
  };

  useEffect(() => {
    (async () => {
      await setLoading(true);
      const templates = await getTemplateList();
      const groups = await getOperatorsGroups();
      await setLoading(false);

      setTemplates(templates);
      setOperatorsGroups(groups);
    })();
  }, [getTemplateList, getOperatorsGroups]);

  return (
    <>
      <Row>
        <Col>
          <MainTable
            title={() => (
              <TableHeader
                onClick={() => setCreationFormVisible(true)}
                permissions={{
                  create: checkPermissionToRenderUI(
                    UI_PERMISSIONS["ui:management:templates:create"]
                  ),
                }}
              />
            )}
            loading={loading}
            columns={COLUMNS}
            data={templates}
            tableStyle={TABLE_STYLE.zebra}
            onRowClick={
              checkPermissionToRenderUI(
                UI_PERMISSIONS["ui:management:templates:card"]
              )
                ? (_, row) => setSelectedTemplate(row.id)
                : () => {}
            }
          />
        </Col>
      </Row>
      <Modal
        destroyOnClose
        visible={creationFormVisible}
        footer={null}
        onCancel={() => setCreationFormVisible(false)}
      >
        <TemplateCreationForm
          operatorsGroups={operatorsGroups}
          submitting={submitting}
          onFinish={createTemplate}
        />
      </Modal>
      <Modal
        destroyOnClose
        visible={Boolean(selectedTemplate)}
        footer={null}
        onCancel={() => setSelectedTemplate(null)}
      >
        <TemplateCard
          {...getTemplate(selectedTemplate)}
          isEditing={selectedTemplateEditing}
          onChangeEditing={() =>
            setSelectedTemplateEditing((editing) => !editing)
          }
          onSave={(template) => updateTemplate(selectedTemplate, template)}
          operatorsGroups={operatorsGroups}
          permissions={{
            edit: checkPermissionToRenderUI(
              UI_PERMISSIONS["ui:management:templates:card:edit"]
            ),
          }}
        />
      </Modal>
    </>
  );
};

export default Templates;
