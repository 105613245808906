const useTextArea = () => {
  /**
   * Получаем целевой инпут при использовании библиотечного компонента ant design <Input.Textarea />
   * @param {React.RefObject} ref
   */
  function getInput(ref) {
    if (ref && ref.current) {
      const input = ref.current.resizableTextArea?.textArea;

      if (input instanceof HTMLTextAreaElement) {
        return input;
      }
    }

    return null;
  }

  /**
   * Устанавливаем фокус на текстовое поле
   * @param {HTMLInputElement|HTMLTextAreaElement} input - целевой элемент ввода текста
   */
  function setFocus(input) {
    input.focus();
  }

  /**
   * Снимаем фокус с текстового поля
   * @param {HTMLInputElement|HTMLTextAreaElement} input - целевой элемент ввода текста
   */
  function setBlur(input) {
    input.blur();
  }

  /**
   * Получаем начальную и конечную позицию выделения в текстовом поле
   * @param {HTMLInputElement|HTMLTextAreaElement} input - целевой элемент ввода текста
   */
  function getSelection(input) {
    return {
      start: input.selectionStart,
      end: input.selectionEnd,
    };
  }

  /**
   * Установка области выделения
   * Для установки позиции курсора, необходимо передать selection.start === selection.end
   * @param {HTMLInputElement|HTMLTextAreaElement} input - целевой элемент ввода текста
   * @param {object} selection - область выделения строки
   * @param {number} selection.start - начальная позиция выделения
   * @param {number} selection.end - конечная позиция выделения
   */
  function setSelection(input, selection) {
    setFocus(input);
    input.selectionStart = selection.start;
    input.selectionEnd = selection.end;
  }

  /**
   * Вставляем перевод корретки в строку.
   * @param {string} value - целевая строка, в которую будет вставлен перевод корретки
   * @param {object} selection - область выделения строки
   * @param {number} selection.start - начальная позиция выделения
   * @param {number} selection.end - конечная позиция выделения
   */
  function putNewLine(value, selection) {
    const { start, end } = selection;

    const head = value.substring(0, start);
    const tail = value.substring(end);

    return head + `\n` + tail;
  }

  return {
    getInput,
    setFocus,
    setBlur,
    getSelection,
    setSelection,
    putNewLine,
  };
};

export default useTextArea;
