import { useCallback } from "react";
import { useDispatch } from "react-redux";

import TASKS_API from "../api/tasks";
import HARDWARE_API from "../api/hardware";
import ERROR_ACTIONS from "../actions/errors/errors";

const useTask = () => {
  const dispatch = useDispatch();

  const changeTaskTemplate = useCallback(
    async (taskId, templateId) => {
      try {
        const response = await TASKS_API.putTaskTemplate(taskId, templateId);

        return Promise.resolve(response.data);
      } catch (err) {
        console.error(err);
        dispatch(
          ERROR_ACTIONS.addError({
            title: "Не удалось изменить шаблон задачи",
            message: err.response.data?.message,
            status: err.response.status,
          })
        );

        return Promise.reject(err);
      }
    },
    [dispatch]
  );

  const removeTaskTemplate = useCallback(
    async (taskId) => {
      try {
        const response = await TASKS_API.deleteTaskTemplate(taskId);

        return Promise.resolve(response.data);
      } catch (err) {
        console.error(err);
        dispatch(
          ERROR_ACTIONS.addError({
            title: "Не удалось удалить шаблон задачи",
            message: err.response.data?.message,
            status: err.response.status,
          })
        );

        return Promise.reject(err);
      }
    },
    [dispatch]
  );

  const changeTaskHardware = useCallback(
    async (taskId, hardwareId) => {
      try {
        const response = await HARDWARE_API.putTaskHardware(taskId, hardwareId);

        return Promise.resolve(response.data);
      } catch (err) {
        console.error(err);
        dispatch(
          ERROR_ACTIONS.addError({
            title: "Не удалось изменить оборудование задачи",
            message: err.response.data?.message,
            status: err.response.status,
          })
        );

        return Promise.reject(err);
      }
    },
    [dispatch]
  );

  const removeTaskHardware = useCallback(
    async (taskId, hardwareId) => {
      try {
        const response = await HARDWARE_API.deleteTaskHardware(
          taskId,
          hardwareId
        );

        return Promise.resolve(response.data);
      } catch (err) {
        console.error(err);
        dispatch(
          ERROR_ACTIONS.addError({
            title: "Не удалось удалить оборудование задачи",
            message: err.response.data?.message,
            status: err.response.status,
          })
        );

        return Promise.reject(err);
      }
    },
    [dispatch]
  );

  return {
    changeTaskTemplate,
    removeTaskTemplate,
    changeTaskHardware,
    removeTaskHardware,
  };
};

export default useTask;
