import axios from "axios";

import AUTH_ACTIONS from "../actions/auth/auth";
import errorHandling from "../services/errors/errorHandling";
import { BASE_URL } from "../consts";
import { store } from "..";
import ERROR_TYPE from "../actions/errors/actionTypes";

/**
 * Инициализируем аксиос
 */
export const fetchService = axios.create({
  baseURL: BASE_URL,
  timeout: 60000,
});

/**
 * Аксиос для защищенных запросов
 * @param {Object} data Конфигурация запроса
 */
const protectedFetch = (data) => {
  const token = store.getState().user.token;
  const expireAt = store.getState().user.expireAt;

  if (token && expireAt && !AUTH_ACTIONS.isTokenExpired(expireAt)) {
    return fetchService({
      ...data,
      headers: {
        ...data.headers,
        Authorization: "Bearer " + store.getState().user.token,
      },
    });
  } else {
    store.dispatch(AUTH_ACTIONS.logout());
    store.dispatch(errorHandling({}, ERROR_TYPE.TOKEN_EXPIRED));

    return;
  }
};

/**
 * Аксиос для обычных запросов
 * @param {Object} data Конфигурация запроса
 * @returns
 */
export const unprotectedFetch = (data) => {
  return fetchService(data);
};

/**
 * @param {Object} data
 * @param {boolean} isProtected
 * @returns
 */
const fetchWrap = async (data, isProtected = true) => {
  try {
    // Запрос для авторизованного пользователя или нет
    const response = isProtected
      ? await protectedFetch(data)
      : await unprotectedFetch(data);

    // Если запрос выполнился удачно, то возвращаем значение
    if ([200, 201].includes(response.status)) {
      return Promise.resolve(response);
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * @param {string} url
 * @param {string} responseType
 * @returns {Promise}
 */
const get = async (url) => {
  return await fetchWrap(
    {
      url,
      method: "GET",
    },
    false
  )
    .then((resp) => Promise.resolve(resp))
    .catch((err) => Promise.reject(err));
};

/**
 * @param {string} url
 * @param {Object} payload
 * @returns {Promise}
 */
const post = async (url, payload) => {
  return await fetchWrap(
    {
      url,
      method: "POST",
      data: payload,
    },
    false
  )
    .then((resp) => Promise.resolve(resp))
    .catch((err) => Promise.reject(err));
};

/**
 * @param {string} url
 * @param {string} params
 * @returns {Promise}
 */
const protectedGet = async (url, params = {}) => {
  return await fetchWrap({
    ...params,
    url,
    method: "GET",
  })
    .then((resp) => Promise.resolve(resp))
    .catch((err) => Promise.reject(err));
};

/**
 * @param {string} url
 * @param {Object} payload
 * @returns {Promise}
 */
const protectedPost = async (url, payload) => {
  return await fetchWrap({
    url,
    method: "POST",
    data: payload,
  })
    .then((resp) => Promise.resolve(resp))
    .catch((err) => Promise.reject(err));
};

/**
 * @param {string} url
 * @param {Object} payload
 * @returns {Promise}
 */
const protectedPostBinary = async (url, payload) => {
  return await fetchWrap({
    url,
    method: "POST",
    data: payload,
    headers: {
      "Content-Type": 'multipart/form-data; boundary="boundary"',
    },
  })
    .then((resp) => Promise.resolve(resp))
    .catch((err) => Promise.reject(err));
};

/**
 * @param {string} url
 * @param {Object} payload
 * @returns {Promise}
 */
const protectedPut = async (url, payload) => {
  return await fetchWrap({
    url,
    method: "PUT",
    data: payload,
  });
};

/**
 * @param {string} url
 * @param {Object} payload
 * @returns {Promise}
 */
const protectedPatch = async (url, payload) => {
  return await fetchWrap({
    url,
    method: "PATCH",
    data: payload,
  });
};

/**
 * @param {string} url
 * @param {Object} payload
 * @returns {Promise}
 */
const protectedDelete = async (url, payload) => {
  return await fetchWrap({
    url,
    method: "DELETE",
    data: payload,
  })
    .then((resp) => Promise.resolve(resp))
    .catch((err) => Promise.reject(err));
};

const exportedObject = {
  get,
  post,
  protectedGet,
  protectedPut,
  protectedPost,
  protectedPatch,
  protectedDelete,
  protectedPostBinary,
};

export default exportedObject;
