import React, { useCallback } from "react";
import { Button, Select, Row, Col, Space } from "antd";
import {
  LeftOutlined,
  RightOutlined,
  // DoubleLeftOutlined,
  // DoubleRightOutlined,
  EllipsisOutlined,
} from "@ant-design/icons";

import { BUTTON_TYPES } from "../../hooks/pagination.hook";
import { PAGE_SIZES } from "../../hooks/search.hook";

import "./style.scss";

const INITIAL_PROPS = {
  className: "",
  range: [], //
  showFirtsPageButton: true,
  size: "small", // Размер кнопок
  pageSize: 0, // Количество элементов на странице
  currentPage: 1, // Текущая страница
  totalPageCount: 0,
  pageSizeOptions: PAGE_SIZES, // Список возможных размеров страницы
  pageSizeChangerVisible: true,
  // jumpPageCount: 5, // Количество страниц, на которое можно перепрыгнуть
  onPageChange: () => {},
  onPageSizeChange: () => {},
};

const Pagination = (props) => {
  const {
    className = INITIAL_PROPS.className,
    range = INITIAL_PROPS.range,
    // showFirtsPageButton = INITIAL_PROPS.showFirtsPageButton,
    size = INITIAL_PROPS.size,
    pageSize = INITIAL_PROPS.pageSize,
    currentPage = INITIAL_PROPS.currentPage,
    totalPageCount = INITIAL_PROPS.totalPageCount,
    pageSizeOptions = INITIAL_PROPS.pageSizeOptions,
    // jumpPageCount = INITIAL_PROPS.jumpPageCount,
    pageSizeChangerVisible = INITIAL_PROPS.pageSizeChangerVisible,
    onPageChange = INITIAL_PROPS.onPageChange,
    onPageSizeChange = INITIAL_PROPS.onPageSizeChange,
  } = props;

  // State
  // const [prevJumperHovered, setPrevJumperHovered] = useState(false);
  // const [nextJumperHovered, setNextJumperHovered] = useState(false);

  const drawPageSizeOptions = useCallback(() => {
    if (!pageSizeChangerVisible) return null;
    if (totalPageCount <= 0) return null;

    return (
      <Space>
        <Select
          className="pagination__page-size-options"
          size="small"
          value={pageSize}
          options={pageSizeOptions}
          onChange={onPageSizeChange}
        />
        на странице
      </Space>
    );
  }, [pageSize, pageSizeOptions, onPageSizeChange, totalPageCount]);

  function drawPageButtons(range = []) {
    return range.map((button, idx) => {
      switch (button.type) {
        case BUTTON_TYPES.FIRST_PAGE_BUTTON:
          return (
            <li
              key={`first-page_${idx}`}
              className="pagination__first-page pagination__li"
            >
              <Button
                type="text"
                size={size}
                children="1"
                onClick={() => onPageChange(1)}
              />
            </li>
          );
        case BUTTON_TYPES.LAST_PAGE_BUTTON:
        case BUTTON_TYPES.PREV_JUMPER:
          return (
            <li
              key={`prev-jumper_${idx}`}
              className="pagination__prev-jumper pagination__li"
            >
              <EllipsisOutlined />
              {/* <Button
          size={size}
          icon={
            prevJumperHovered ? <DoubleLeftOutlined /> : <EllipsisOutlined />
          }
          onPointerEnter={() => setPrevJumperHovered(true)}
          onPointerLeave={() => setPrevJumperHovered(false)}
          onClick={() => onPageChange(currentPage - jumpPageCount, pageSize)}
        /> */}
            </li>
          );
        case BUTTON_TYPES.NEXT_JUMPER:
          return (
            <li
              key={`next-jumper_${idx}`}
              className="pagination__next-jumper pagination__li"
            >
              <EllipsisOutlined />
              {/* <Button
              size={size}
              icon={
                nextJumperHovered ? <DoubleRightOutlined /> : <EllipsisOutlined />
              }
              onPointerEnter={() => setNextJumperHovered(true)}
              onPointerLeave={() => setNextJumperHovered(false)}
              onClick={() => onPageChange(currentPage + jumpPageCount, pageSize)}
            /> */}
            </li>
          );
        case BUTTON_TYPES.PAGE_BUTTON:
          return (
            <li
              className="pagination__li"
              key={`${button.type}_${button.page}${idx}`}
            >
              <Button
                size={size}
                type={currentPage === button.page ? "primary" : "text"}
                children={button.page}
                onClick={() => onPageChange(button.page)}
              />
            </li>
          );
        default:
          return null;
      }
    });
  }

  return (
    <Row className={`component_pagination ${className}`}>
      {totalPageCount > 1 && (
        <Col>
          <ul className="pagination__pages">
            <li className="pagination__prev">
              <Button
                type="text"
                size={size}
                disabled={currentPage === 1}
                icon={<LeftOutlined />}
                onClick={() => onPageChange(currentPage - 1)}
              />
            </li>

            {drawPageButtons(range)}

            <li className="pagination__next">
              <Button
                type="text"
                size={size}
                disabled={currentPage === totalPageCount}
                icon={<RightOutlined />}
                onClick={() => onPageChange(currentPage + 1)}
              />
            </li>
          </ul>
        </Col>
      )}
      <Col>{drawPageSizeOptions()}</Col>
    </Row>
  );
};

export default Pagination;
