import React from "react";
import { Button } from "antd";

import Table, { TABLE_STYLE } from "../../components/Table";

import { useTabs, COMPONENTS } from "../../services/tabs";
import useRBAC, { PERMISSIONS, UI_PERMISSIONS } from "../../services/rbac";

const COLUMNS = [
  {
    title: "LDAP-группа",
    dataIndex: "ldap_group",
  },
  {
    title: "Группа операторов",
    dataIndex: ["operators_group", "title"],
  },
  {
    title: "Статус операторов",
    dataIndex: ["operators_status", "title"],
  },
  {
    title: "Роли",
    dataIndex: "roles",
    render: (text, row) => row.roles.join(", "),
  },
];

const Ldap = (props) => {
  const { loading, ldapProps, onRowClick } = props;

  // Hooks
  const { clickHandler } = useTabs();
  const { checkAccess, checkPermissionToRenderUI } = useRBAC();

  function handleRowClick() {
    const requiredPermissions = [
      PERMISSIONS["operator_ldap_props:delete"],
      PERMISSIONS["operator_ldap_props:update"],
    ];

    if (requiredPermissions.every((p) => !checkAccess(p))) {
      return () => {};
    } else {
      return onRowClick;
    }
  }

  return (
    <div className="body__ldap">
      <Table
        loading={loading}
        scroll={{ y: "calc(100vh - 268px)" }}
        columns={COLUMNS}
        data={ldapProps}
        tableStyle={TABLE_STYLE.zebra}
        onRowClick={handleRowClick()}
        rowKey={(row) => row.ldap_group}
        title={() =>
          checkPermissionToRenderUI(UI_PERMISSIONS["ui:management:ldap-props:list:create"]) && (
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                type="primary"
                htmlType="button"
                children="Создать"
                onClick={() =>
                  clickHandler({
                    component: COMPONENTS.LDAP_PROPS_CREATION_FORM,
                  })
                }
              />
            </div>
          )
        }
      />
    </div>
  );
};

export default Ldap;
