import React, { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Col, Row, Button, message, Divider, Typography } from "antd";
import { useLocation } from "react-router";

import MainContainer from "../../components/MainContainer";
import Chat from "../../components/Chat";
import AllUserAppeals from "../../components/AllUserAppeals";
import HardwareSection from "./HardwareSection";
import UserData from "../../components/UserData";

import useCopy from "../../hooks/useCopy";

import {
  getUserData,
  changeUserWorkplaceLocation,
  changeUserWorkstationNumber,
  getUserPhoto,
  updateUserInfo,
} from "../../api/users";
import { useTabs, COMPONENTS } from "../../services/tabs";
import useRBAC, { UI_PERMISSIONS } from "../../services/rbac";
import ERROR_ACTIONS from "../../actions/errors/errors";

import "./style.scss";

const { Title } = Typography;

const Header = () => {
  return (
    <>
      <h2 className="header__title">Информация о пользователе</h2>
    </>
  );
};

const ROW_GUTTER = [0, 32];

const MAIN_COL_BREAKPOINTS = {
  sm: 12,
  md: 12,
  lg: 14,
  xl: 14,
  xxl: 16,
};
const MAIN_COL_BREAKPOINTS_WO_CHAT = {
  sm: 24,
  md: 24,
  lg: 24,
  xl: 24,
  xxl: 24,
};
const CHAT_COL_BREAKPOINTS = {
  sm: 12,
  md: 12,
  lg: 10,
  xl: 10,
  xxl: 8,
};
const CHAT_COL_DISPLAY_NONE = {
  sm: 0,
  md: 0,
  lg: 0,
  xl: 0,
  xxl: 0,
};

function UserCard(props) {
  const { tabUuid, error, setTabError } = props;

  const dispatch = useDispatch();
  const location = useLocation();

  // Store
  const operatorID = useSelector((store) => store.user.id);
  const newMessages = useSelector((store) => store.notifications.newMessages);

  // State
  const [data, setData] = useState({});
  const [userPhoto, setUserPhoto] = useState(null);
  const [updating, setUpdating] = useState(false);

  // Hooks
  const { copy } = useCopy();
  const { clickHandler } = useTabs();
  const { checkPermissionToRenderUI } = useRBAC();

  const { mainCol, chatCol } = getBreakpoints(data?.id);

  const onCopy = (value) => {
    copy(value);
    message.success("Скопировано");
  };

  function getBreakpoints(userId) {
    const breakpoints = {
      mainCol: MAIN_COL_BREAKPOINTS,
      chatCol: CHAT_COL_BREAKPOINTS,
    };

    if (!chatAccess(userId)) {
      breakpoints.mainCol = MAIN_COL_BREAKPOINTS_WO_CHAT;
      breakpoints.chatCol = CHAT_COL_DISPLAY_NONE;
    }

    return breakpoints;
  }

  function chatAccess(userId) {
    return Boolean(
      userId && checkPermissionToRenderUI(UI_PERMISSIONS["ui:users:card:messages:list"])
    );
  }

  const getUserByID = useCallback(
    async (id) => {
      await getUserData(id)
        .then(async (resp) => {
          if (resp.status === 200) {
            const { first_name, last_name, patronymic_name } = resp.data;

            await setData({
              ...resp.data,
              full_name: `${last_name} ${first_name} ${patronymic_name}`.trim(),
            });
            setTabError(null);
          } else {
            return Promise.reject(resp);
          }
        })
        .catch((err) => {
          console.error(err);
          setTabError({
            title: "Ошибка получения пользователя",
            status: err.response.status,
            message: err.response.data?.message,
          });
        });
    },
    [setTabError]
  );

  async function onChangeWorkplace(value) {
    const { id } = data;

    await changeUserWorkplaceLocation(id, value)
      .then((resp) => {
        if (resp.status === 200) {
          return message.success("Сохранено");
        }

        return Promise.reject();
      })
      .catch(() => message.error("Ошибка"));

    await getUserByID(id);
  }

  async function onChangeWorkstation(value) {
    const { id } = data;

    await changeUserWorkstationNumber(id, value)
      .then((resp) => {
        if (resp.status === 200) {
          return message.success("Сохранено");
        }

        return Promise.reject();
      })
      .catch(() => message.error("Ошибка"));

    await getUserByID(id);
  }

  async function getPhoto(userId) {
    try {
      const response = await getUserPhoto(userId);
      const photoUrl = await URL.createObjectURL(response.data);

      setUserPhoto(photoUrl);
    } catch (err) {
      console.error(err);
    }
  }

  async function onUpdateUserInfo(userId) {
    await setUpdating(true);

    try {
      await updateUserInfo(userId);
      await getUserByID(userId);

      message.success("Обновлено");
    } catch (err) {
      console.error(err);
      dispatch(
        ERROR_ACTIONS.addError({
          title: "Не удалось обновить информацию пользователя",
          message: err.response.data?.message,
          status: err.response.status,
        })
      );
    }

    await setUpdating(false);
  }

  useEffect(() => {
    const userId = location.state
      ? location.state.userId
      : location.pathname.split("/")[2];

    (async () => {
      await getUserByID(userId);
      await getPhoto(userId);
    })();
  }, [tabUuid]);

  return (
    <MainContainer
      error={error}
      header={<Header />}
      headerClassName="user-card__header"
      bodyClassName="user-card__body"
    >
      <Row
        className="body__main-row"
        style={{
          width: "100%",
          height: "100%",
          position: "relative",
          overflow: "hidden",
        }}
      >
        {/* MAIN COLUMN */}
        <Col className="main-row__main-col" {...mainCol}>
          <Row gutter={ROW_GUTTER} className="main-col__user-info">
            <Col span={24}>
              <UserData
                user={{ ...data, photo: userPhoto }}
                permissions={{
                  changingWorkplaceLocationAllowed: checkPermissionToRenderUI(
                    UI_PERMISSIONS["ui:users:card:workplace-location:edit"]
                  ),
                  changingWorkstationNumberAllowed: checkPermissionToRenderUI(
                    UI_PERMISSIONS["ui:users:card:workstation-number:edit"]
                  ),
                }}
                onChangeWorkplaceLocation={onChangeWorkplace}
                onChangeWorkstationNumber={onChangeWorkstation}
                onCopyName={data?.full_name ? () => onCopy(data.full_name) : undefined}
              />
            </Col>
          </Row>

          <Row gutter={[0, 40]}>
            <Col>
              {checkPermissionToRenderUI(
                UI_PERMISSIONS["ui:users:card:create-issue"]
              ) && (
                <Button
                  type="primary"
                  style={{ margin: 2 }}
                  children="Создать обращение"
                  onClick={() =>
                    clickHandler({
                      component: COMPONENTS.ISSUE_CREATION_FORM,
                      locationState: { userID: data.id, operatorID },
                    })
                  }
                />
              )}

              {checkPermissionToRenderUI(UI_PERMISSIONS["ui:users:card:create-task"]) && (
                <Button
                  type="primary"
                  style={{ margin: 2 }}
                  children="Создать задачу"
                  onClick={() =>
                    clickHandler({
                      component: COMPONENTS.TASK_CREATION_FORM,
                      locationState: { userID: data.id, operatorID: null },
                    })
                  }
                />
              )}
            </Col>
          </Row>
          {checkPermissionToRenderUI(
            UI_PERMISSIONS["ui:users:card:update-user-info"]
          ) && (
            <>
              <Divider />
              <Row className="main-col__functions" gutter={[0, 40]}>
                <Col xs={24} className="functions_update-user-info">
                  <div className="function__description">
                    <Title
                      level={5}
                      className="description__name"
                      children="Обновить пользовательскую информацию"
                    />
                    <Title
                      level={5}
                      className="description__subtitle"
                      children="Актуализировать данные пользователя из сервиса Employees. Работает только для сотрудников, имеющих логин AD."
                    />
                  </div>
                  <Button
                    shape="round"
                    loading={updating}
                    children="Обновить"
                    onClick={() => onUpdateUserInfo(data?.id)}
                  />
                </Col>
              </Row>
            </>
          )}

          {checkPermissionToRenderUI(UI_PERMISSIONS["ui:users:card:hardware"]) && (
            <HardwareSection
              userId={data?.id}
              permissions={{
                hardwareChangeCreate: checkPermissionToRenderUI(
                  UI_PERMISSIONS["ui:users:card:hardware:changes:create"]
                ),
                hardwareChangeRemove: checkPermissionToRenderUI(
                  UI_PERMISSIONS["ui:users:card:hardware:changes:remove"]
                ),
                list: checkPermissionToRenderUI(
                  UI_PERMISSIONS["ui:users:card:hardware:changes:list"]
                ),
                hardwareChangeEdit: checkPermissionToRenderUI(
                  UI_PERMISSIONS["ui:users:card:hardware:changes:edit"]
                ),
              }}
            />
          )}

          <Row>
            <Col flex={"100%"}>
              <AllUserAppeals
                userId={data?.id}
                displayIssues={checkPermissionToRenderUI(
                  UI_PERMISSIONS["ui:users:card:user-issues"]
                )}
                displayTasks={checkPermissionToRenderUI(
                  UI_PERMISSIONS["ui:users:card:user-tasks"]
                )}
              />
            </Col>
          </Row>
        </Col>

        {/* CHAT COLUMN */}
        {chatAccess(data?.id) && (
          <Col
            {...chatCol}
            className="main-row__chat-col user-card__col user-card__col_chat"
          >
            <Chat
              userId={data?.id}
              operatorId={operatorID}
              newMessage={newMessages[newMessages.length - 1]}
              sendingMessageAllowed={checkPermissionToRenderUI(
                UI_PERMISSIONS["ui:users:card:messages:post"]
              )}
            />
          </Col>
        )}
      </Row>
    </MainContainer>
  );
}

export default UserCard;
