import React from "react";
import { Modal, Input, Form, Button, Space } from "antd";

const MAX_LENGTH_OF_FILTER_NAME = 50;

const CustomFilterSaveModal = (props) => {
  const {
    visible,
    defaultFilterName,
    onOk = () => {},
    onCancel = () => {},
  } = props;

  return (
    <Modal
      centered
      visible={visible}
      title="Сохранение фильтра"
      onCancel={onCancel}
      footer={null}
    >
      <Form
        layout="vertical"
        onFinish={(form) => onOk(form.name)}
        initialValues={{ name: defaultFilterName }}
      >
        <Form.Item
          label="Название фильтра:"
          name="name"
          rules={[
            { required: true, message: "Заполните название фильтра" },
            {
              message: `Максимальная длина ${MAX_LENGTH_OF_FILTER_NAME} символов`,
              max: MAX_LENGTH_OF_FILTER_NAME,
            },
          ]}
        >
          <Input autoFocus />
        </Form.Item>
        <Space>
          <Form.Item>
            <Button type="primary" htmlType="submit" children="Сохранить" />
          </Form.Item>
          <Form.Item>
            <Button htmlType="reset" children="Отмена" onClick={onCancel} />
          </Form.Item>
        </Space>
      </Form>
    </Modal>
  );
};

export default CustomFilterSaveModal;
