import React, { useState, useRef, useMemo } from "react";
import { Row, Col, Button, Descriptions, message, Typography } from "antd";
import { CopyOutlined } from "@ant-design/icons";
import moment from "moment";
import "moment/locale/ru";

import Spinner from "../Spinner";
import EditableCardField, { EDITABLE_FIELD_TYPES } from "../EditableCardField";

import normalizeDate, { DATE_TIME } from "../../utils/normalizeDate";
import { GUTTER, BREAKPOINTS } from ".";
import { useTabs, COMPONENTS } from "../../services/tabs";
import useCopy from "../../hooks/useCopy";

const EDITABLE_FIELDS = {
  operator: "operator",
  template: "template",
};

const DEFAULT_SWITCHES = {
  [EDITABLE_FIELDS.operator]: false,
  [EDITABLE_FIELDS.template]: false,
};

const CopyButton = ({ onClick, disabled = false }) => (
  <Button
    type="text"
    size="small"
    disabled={disabled}
    icon={<CopyOutlined />}
    onClick={onClick}
  />
);

const { Text } = Typography;

const ChangeRecord = (props) => {
  moment.locale("ru");

  const { requester, event_time, changes } = props;
  const formattedDate = moment(event_time).format("DD MMMM YYYY в HH:mm");

  return (
    <div className="component_change-record">
      <p className="change-record__label">
        <span className="label__requester">{requester}</span>
        <span className="label__event-time"> {formattedDate}</span>
      </p>
      <p className="change-record__changes-header">внёс изменения:</p>
      <ul className="change-record__changes">
        {changes.map((c, index) => (
          <li key={index}>{c}</li>
        ))}
      </ul>
    </div>
  );
};

function DrawHistory(props) {
  const { loading, visible, changes = [] } = props;

  const visibility = visible
    ? "data__history-of-changes_shown"
    : "data__history-of-changes_hidden";

  function drawContent() {
    if (loading) return <Spinner />;
    if (!Array.isArray(changes) || changes.length === 0)
      return <p>История изменений пуста</p>;

    return changes.map((record, index) => (
      <ChangeRecord key={record.event_time + index} {...record} />
    ));
  }
  return <div className={`data__history-of-changes ${visibility}`}>{drawContent()}</div>;
}

const DataSection = (props) => {
  const {
    human_readable_id,
    from_operator,
    created_at,
    updated_at,
    done_at,
    deleted_at,
    location,
    groups = [],
    operators = [],
    performer,
    template,
    disabled,
    changes = [],
    onChangePerformer = () => {},
    onChangeTemplate = () => {},
    getHistory = () => {},
    permissions,
  } = props;

  const { changePerformerAllowed = false, changeTemplateAllowed = false } = permissions;
  const performerName = useMemo(() => {
    const operator = operators.find((o) => o.value === performer);
    const group = groups.find((g) => g.value === performer);

    if (operator) {
      return operator.label;
    }
    if (group) {
      return group.label;
    }

    return undefined;
  }, [performer, groups, operators]);

  // State
  const [switches, setSwitches] = useState(DEFAULT_SWITCHES);
  const [historyVisible, setHistoryVisible] = useState(false);
  const [historyLoading, setHistoryLoading] = useState(false);

  // Hooks
  const { clickHandler } = useTabs();
  const { copy } = useCopy();

  // Ref
  const dataSectionRed = useRef(null);

  const onCopy = (value) => {
    message.success("Скопировано");
    copy(value);
  };

  function getOptions() {
    const options = [];

    if (Array.isArray(operators) && operators.length > 0) {
      options.push({ label: "Операторы", options: operators });
    }
    if (Array.isArray(groups) && groups.length > 0) {
      options.push({ label: "Группы", options: groups });
    }

    return options;
  }

  function toggleHistoryVisible(visible) {
    if (visible) {
      setHistoryLoading(true);
      getHistory();
      setHistoryLoading(false);
    }

    setHistoryVisible(visible);
    setTimeout(() => dataSectionRed.current.scrollIntoView({ behavior: "smooth" }), 200);
  }

  function toggleEditableField(fieldName) {
    setSwitches({ ...switches, [fieldName]: !switches[fieldName] });
  }

  return (
    <section className="card__section card__section_data" ref={dataSectionRed}>
      <h3 className="section__header">Данные заявки</h3>

      <Descriptions
        bordered
        size="small"
        column={{ xs: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 2 }}
      >
        <Descriptions.Item label="Номер">
          <Row align={"middle"} gutter={[6, 0]}>
            <Col>
              <Text>{human_readable_id}</Text>
            </Col>
            <Col>
              <CopyButton onClick={() => onCopy(human_readable_id)} />
            </Col>
          </Row>
        </Descriptions.Item>
        <Descriptions.Item label="Исполнитель">
          <Row gutter={[6, 0]}>
            <Col>
              <EditableCardField
                name={EDITABLE_FIELDS.operator}
                value={performer}
                placeholder="Выберите исполнителя"
                params={{ options: getOptions(), optionFilterProp: "label" }}
                type={EDITABLE_FIELD_TYPES.select}
                isEdit={switches.operator}
                editField={toggleEditableField}
                saveChanges={onChangePerformer}
                disabled={disabled || !changePerformerAllowed}
              />
            </Col>
            <Col>
              <CopyButton
                onClick={() => onCopy(performerName)}
                disabled={performerName === undefined}
              />
            </Col>
          </Row>
        </Descriptions.Item>
        <Descriptions.Item label="От оператора">
          <Button
            type="link"
            className="button_operator-link"
            children={`${from_operator.last_name} ${from_operator.first_name}`}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              clickHandler({
                component: COMPONENTS.OPERATOR_CARD,
                params: { operatorId: from_operator.id },
                locationState: { operatorId: from_operator.id },
              });
            }}
          />
        </Descriptions.Item>
        <Descriptions.Item label="Создано">
          {normalizeDate(created_at, DATE_TIME)}
        </Descriptions.Item>
        <Descriptions.Item label="Обновлено">
          {normalizeDate(updated_at, DATE_TIME)}
        </Descriptions.Item>
        {done_at && (
          <Descriptions.Item label="Завершено">
            {normalizeDate(done_at, DATE_TIME)}
          </Descriptions.Item>
        )}
        {deleted_at && (
          <Descriptions.Item label="Удалено">
            {normalizeDate(deleted_at, DATE_TIME)}
          </Descriptions.Item>
        )}

        {location && (
          <Descriptions.Item label="Локация">{location.title}</Descriptions.Item>
        )}
        {template && (
          <Descriptions.Item label="Шаблон">
            <EditableCardField
              name={EDITABLE_FIELDS.template}
              value={template.id}
              placeholder="Выберите шаблон"
              params={{ options: template.options }}
              type={EDITABLE_FIELD_TYPES.select}
              isEdit={switches.template}
              editField={toggleEditableField}
              saveChanges={onChangeTemplate}
              disabled={disabled || !changeTemplateAllowed}
            />
          </Descriptions.Item>
        )}
      </Descriptions>

      <Row gutter={GUTTER}>
        <Col {...BREAKPOINTS}>
          <Button
            type="link"
            children={
              historyVisible ? "Скрыть историю изменений" : "Показать историю изменений"
            }
            className="button_history-of-changes"
            onClick={() => toggleHistoryVisible(!historyVisible)}
          />
        </Col>
      </Row>
      <DrawHistory visible={historyVisible} loading={historyLoading} changes={changes} />
    </section>
  );
};

export default DataSection;
