import React from "react";

import UserSearch from "../Search_new";
import { TYPES } from "../Search_new/consts";
import SelectedFilter from "./selectedFilter";
import ExistenceSelect from "./existenceSelect";

const UserFilter = ({
  label = "",
  selected = [],
  comparisons = [],
  existence = undefined,
  onSelect = () => {},
  onRemove = () => {},
  onChangeComparison = () => {},
  onChangeExistence = () => {},
}) => {
  function onSelectUser(selected) {
    if (selected === null) return;

    onSelect({
      value: selected.id,
      label: `${selected.last_name} ${selected.first_name}`,
    });
  }

  return (
    <div className="search-filter__filter search-filter__filter_user-search">
      <div className="filter__label">
        {label}
        {existence !== undefined && (
          <ExistenceSelect
            value={existence.selected}
            options={existence.options}
            onChange={onChangeExistence}
          />
        )}
      </div>
      {!(existence && existence.selected) && (
        <>
          <UserSearch
            size="small"
            placeholder="Поиск"
            types={[TYPES.user]}
            updateParent={onSelectUser}
          />
          <div>
            {selected.map((filter, idx) => (
              <SelectedFilter
                key={`${filter.value}_${idx}`}
                comparisons={{
                  selected: selected.comparison,
                  options: comparisons,
                }}
                filterLabel={filter.label}
                onChange={(value) => onChangeComparison(idx, value)}
                onRemove={() => onRemove(idx)}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default UserFilter;
