import ERROR_TYPES from "../actions/errors/actionTypes";

const initialState = {
  status: null,
  message: null,
  title: null,
  visible: false,
  type: null,
  errors: [],
};

/**
 * @enum Словарь заголовков ошибок
 */
const ERROR_TITLES = {
  [ERROR_TYPES.LOGIN_ERROR]: "Ошибка авторизации",
  [ERROR_TYPES.TOKEN_EXPIRED]: "Время авторизации истекло",
  [ERROR_TYPES.REFRESH_ERROR]: "Ошибка продления авторизации",
  [ERROR_TYPES.ISSUE_LIST_GETTING_ERROR]: "Ошибка получения списка обращений",
  [ERROR_TYPES.ISSUE_CREATION_ERROR]: "Ошибка создания обращения",
  [ERROR_TYPES.ISSUE_GETTING_ERROR]: "Ошибка получения обращения",
  [ERROR_TYPES.ISSUE_DESCRIPTION_EDITING_ERROR]: "Ошибка изменения описания",
  [ERROR_TYPES.ISSUE_OPERATOR_CHANGING_ERROR]: "Ошибка изменения оператора",
  [ERROR_TYPES.ISSUE_CLOSING_ERROR]: "Ошибка закрытия обращения",
  [ERROR_TYPES.ISSUE_GETTING_COMMENTS_ERROR]: "Ошибка получения комментариев",
  [ERROR_TYPES.ISSUE_POSTING_COMMENT_ERROR]: "Ошибка отправки комментария",
  [ERROR_TYPES.ISSUE_EDITING_COMMENT_ERROR]:
    "Ошибка редактирования комментария",
  [ERROR_TYPES.ISSUE_FILE_DELETING_ERROR]: "Ошибка удаления файла",
  [ERROR_TYPES.ISSUE_UPLOAD_FILES_ERROR]: "Ошибка загрузки файлов обращения",
  [ERROR_TYPES.TASK_LIST_GETTING_ERROR]: "Ошибка получения списка задач",
  [ERROR_TYPES.TASK_CREATION_ERROR]: "Ошибка создания задачи",
  [ERROR_TYPES.TASK_GETTING_ERROR]: "Ошибка получения задачи",
  [ERROR_TYPES.TASK_DESCRIPTION_EDITING_ERROR]:
    "Ошибка редактирования описания",
  [ERROR_TYPES.TASK_PERFORMER_CHANGING_ERROR]:
    "Ошибка изменения исполнителя задачи",
  [ERROR_TYPES.TASK_CLOSING_ERROR]: "Ошибка закрытия задачи",
  [ERROR_TYPES.TASK_UPLOAD_FILES_ERROR]: "Ошибка загрузки файлов задачи",
  [ERROR_TYPES.FILE_UPLOADING_ERROR]: "Ошибка отправки файла",
  [ERROR_TYPES.FILE_DOWNLOADING_ERROR]: "Ошибка загрузки файла",
  [ERROR_TYPES.IMAGE_PREVIEW_DOWNLOADING_ERROR]: "Ошибка загрузки превью",
  [ERROR_TYPES.OPERATOR_CREATION_ERROR]: "Ошибка создания оператора",
  [ERROR_TYPES.OPERATOR_GETTING_ERROR]: "Ошибка получения оператора",
  [ERROR_TYPES.OPERATOR_NAME_CHANGING_ERROR]: "Ошибка изменения имени",
  [ERROR_TYPES.OPERATOR_STATUS_CHANGING_ERROR]:
    "Ошибка изменения статуса оператора",
  [ERROR_TYPES.OPERATOR_GROUP_CHANGING_ERROR]:
    "Ошибка изменения группы оператора",
  [ERROR_TYPES.GROUP_CREATION_ERROR]: "Ошибка создания группы операторов",
  [ERROR_TYPES.GROUP_NAME_EDITING_ERROR]:
    "Ошибка редактирования названия группы",
  [ERROR_TYPES.GROUP_GETTING_ERROR]: "Ошибка получения группы",
  [ERROR_TYPES.GROUP_DELETING_ERROR]: "Ошибка удаления группы",
  [ERROR_TYPES.CONNECTION_OPENING_ERROR]: "Ошибка установления соединения",
  [ERROR_TYPES.USER_MESSAGES_GETTING_ERROR]: "Ошибка получения сообщений",
  [ERROR_TYPES.CONNECTION_LOST]: "Отсутствует подключение к сети",
  [ERROR_TYPES.MASS_PROBLEM_LIST_GETTING_ERROR]:
    "Ошибка получения списка массовых проблем",
  [ERROR_TYPES.MASS_PROBLEM_GETTING_ERROR]:
    "Ошибка получения массовой проблемы",
  [ERROR_TYPES.MASS_PROBLEM_DELETE_ISSUE_ERROR]:
    "Ошибка удаления обращения из массовой проблемы",
  [ERROR_TYPES.MASS_PROBLEM_ADD_NEW_ISSUE_ERROR]:
    "Ошибка добавления обращений к массовой проблеме",
  [ERROR_TYPES.MASS_PROBLEM_UPLOAD_FILES_ERROR]:
    "Ошибка загрузки файлов массовой проблемы",
  [ERROR_TYPES.MASS_PROBLEM_DESCRIPTION_EDITING_ERROR]:
    "Ошибка редактирования описания массовой проблемы",
  [ERROR_TYPES.MASS_PROBLEM_CLOSING_ERROR]: "Ошибка закрытия массовой проблемы",
  [ERROR_TYPES.LDAP_PROP_CREATION_ERROR]: "Ошибка создания LDAP-свойства",
  [ERROR_TYPES.LDAP_PROP_DELETION_ERROR]: "Ошибка удаления LDAP-свойства",
  [ERROR_TYPES.RBAC_ROLE_GRAPH_GETTING_ERROR]: "Ошибка получения графа роли",
  [ERROR_TYPES.LOCATION_DELETE_ERROR]: "Ошибка удаления локации",
  [ERROR_TYPES.INCOMING_MESSAGE_HANDLING_ERROR]:
    "Ошибка обработки входящего сообщения",
};

function getErrorTitle(type) {
  return ERROR_TITLES[type] || "Ошибка";
}

function getError(type, data) {
  if (!(type in ERROR_TYPES)) return null;

  return {
    type,
    title: getErrorTitle(type),
    ...data,
  };
}

export function errorReducer(state = initialState, action) {
  if (action.type === ERROR_TYPES.RESET_ERROR) return { ...initialState };

  if (action.type === ERROR_TYPES.ADD_ERROR)
    return { ...state, errors: [...state.errors, action.payload] };

  if (action.type === ERROR_TYPES.REMOVE_ERROR)
    return {
      ...state,
      errors: state.errors.filter((error) => error.uuid !== action.payload),
    };

  const error = getError(action.type, action.payload);

  return error ? { ...state, ...error } : { ...state };
}
