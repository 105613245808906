import React, { useState, useCallback } from "react";
import { Checkbox, Input } from "antd";

import SelectedFilter from "./selectedFilter";
import ExistenceSelect from "./existenceSelect";

const CheckboxFilter = ({
  selected = [],
  label = "",
  options = [],
  comparisons = [],
  existence = undefined,
  withFilter = false,
  onSelect = () => {},
  onRemove = () => {},
  onChangeComparison = () => {},
  onChangeExistence = () => {},
}) => {
  const [filter, setFilter] = useState("");

  const filterOptions = useCallback(
    (options, filter) =>
      options.filter((option) =>
        option.label.toLowerCase().includes(filter.toLowerCase())
      ),
    [filter]
  );

  function onChangeCheckbox(event, option, index) {
    if (event.target.checked) {
      onSelect(option);
    } else {
      onRemove(index);
    }

    event.nativeEvent.target.blur();
  }

  return (
    <div className="search-filter__filter search-filter__filter_checkbox">
      <div className="filter__label">
        {label}
        {existence !== undefined && (
          <ExistenceSelect
            value={existence.selected}
            options={existence.options}
            onChange={onChangeExistence}
          />
        )}
      </div>
      {!(existence && existence.selected) && (
        <>
          {withFilter && <Input
            allowClear
            className="filter__input"
            size="small"
            placeholder="Фильтр"
            value={filter}
            onChange={(e) => setFilter(e.target.value)}
          />}
          {filterOptions(options, filter).map((option, index) => {
            const selectedIndex = selected.findIndex(
              (s) => s.value === option.value
            );
            const selectedItem = selected[selectedIndex];
            const isChecked = selectedIndex !== -1;

            return (
              <div
                key={`${option.value}_${index}`}
                className="filter__checkbox-wrapper"
              >
                <Checkbox
                  value={option.value}
                  checked={isChecked}
                  onChange={(e) => onChangeCheckbox(e, option, selectedIndex)}
                  children={!isChecked && option.label}
                />
                {isChecked && (
                  <SelectedFilter
                    filterLabel={selectedItem.label}
                    comparisons={{
                      selected: selectedItem.comparison,
                      options: comparisons,
                    }}
                    onChange={(value) =>
                      onChangeComparison(selectedIndex, value)
                    }
                  />
                )}
              </div>
            );
          })}
        </>
      )}
    </div>
  );
};

export default CheckboxFilter;
