import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Row,
  Col,
  Divider,
  Typography,
  List,
  Select,
  Button,
  Space,
  Tooltip,
} from "antd";
import { DeleteOutlined } from "@ant-design/icons";

import MainContainer from "../../components/MainContainer";
// import EditableCardField from "../../components/EditableCardField";
import { getOperatorsGroupData } from "../../api/operators";
import { SET_OPERATORS_GROUP_NAME } from "../../actions/operator/actionTypes";
import { REALMS_OF_CUSTOM_FILTERS } from "../../consts";

import useOperatorCustomFilters from "../../hooks/operatorCustomFilters";

import "./style.scss";

const { Title, Text } = Typography;

const breakpoints = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
  xl: 8,
  xxl: 8,
};

const gutter = [10, 10];

const CUSTOM_FILTERS_REALMS = [
  {
    label: "Обращения",
    value: REALMS_OF_CUSTOM_FILTERS.issues,
  },
  {
    label: "Задачи",
    value: REALMS_OF_CUSTOM_FILTERS.tasks,
  },
];

const BODY_ID = "body_profile";

const OperatorCard = (props) => {
  // const { componentProps, setComponentProps, tabUuid } = props;

  const dispatch = useDispatch();

  // Store
  const userName = useSelector(
    (store) => `${store.user.lastName} ${store.user.firstName}`
  );
  const roles = useSelector((store) => store.user.roles);
  const groupName = useSelector((store) => store.user.groupName);
  const groupId = useSelector((store) => store.user.operatorsGroupId);

  // State
  const [loading, setLoading] = useState(false);
  const [bodyHeight, setBodyHeight] = useState(null);
  const [customFilters, setCustomFilters] = useState({});
  const [selectedRealm, setSelectedRealm] = useState(
    REALMS_OF_CUSTOM_FILTERS.issues
  );

  // Hooks
  const { getCustomFilters, deleteFilter } = useOperatorCustomFilters();

  const getGroupName = useCallback(
    async (groupId) => {
      await getOperatorsGroupData(groupId)
        .then((resp) =>
          dispatch({ type: SET_OPERATORS_GROUP_NAME, payload: resp.data.title })
        )
        .catch((err) => console.error(err));
    },
    [dispatch]
  );

  const getUserCustomFilters = useCallback(async () => {
    const customFilters = {};

    for (const realm in REALMS_OF_CUSTOM_FILTERS) {
      customFilters[REALMS_OF_CUSTOM_FILTERS[realm]] = await getCustomFilters(
        REALMS_OF_CUSTOM_FILTERS[realm]
      );
    }

    setCustomFilters(customFilters);
  }, [getCustomFilters]);

  const onDeleteFilter = async (filterId, realm) => {
    try {
      await deleteFilter(filterId);
      await setCustomFilters((customFilters) => ({
        ...customFilters,
        [realm]: customFilters[realm].filter(
          (filter) => filter.value !== filterId
        ),
      }));
    } catch {}
  };

  const getSelectedFilters = (customFilters, selectedRealm) => {
    return customFilters[selectedRealm] || [];
  };

  const getBodyHeight = useCallback(() => {
    const body = document.getElementById(BODY_ID);
    return body ? body.offsetHeight : undefined;
  }, []);

  useEffect(() => {
    (async () => {
      await setLoading(true);

      if (!groupName && groupId) {
        await getGroupName(groupId);
      }

      await getUserCustomFilters();
      await setBodyHeight(getBodyHeight());

      await setLoading(false);
    })();
  }, [groupId, groupName, getUserCustomFilters, getGroupName, getBodyHeight]);

  return (
    <MainContainer title="Профиль" loading={loading} bodyId={BODY_ID}>
      <Row gutter={gutter}>
        <Col {...breakpoints} className="field__title">
          Имя: <span className="field__value">{userName}</span>
        </Col>
        <Col {...breakpoints} className="field__title">
          Группа: <span className="field__value">{groupName}</span>
        </Col>
        <Col {...breakpoints} className="field__title">
          Роли: <span className="field__value">{roles.join(", ")}</span>
        </Col>
      </Row>

      <Divider />

      <Row gutter={gutter}>
        <Col>
          <Title level={5}>Пользовательские фильтры</Title>

          <Space style={{ paddingBottom: 16 }}>
            <Text>Область фильтра:</Text>
            <Select
              style={{ minWidth: 200 }}
              options={CUSTOM_FILTERS_REALMS}
              value={selectedRealm}
              onChange={setSelectedRealm}
            />
          </Space>

          <List
            bordered
            style={{
              maxHeight: bodyHeight ? bodyHeight - 120 : "unset",
              overflow: "auto",
            }}
            dataSource={getSelectedFilters(customFilters, selectedRealm)}
            renderItem={(filter) => (
              <List.Item
                key={filter.value}
                actions={[
                  <Tooltip title="Удалить">
                    <Button
                      danger
                      type="text"
                      title="Удалить"
                      htmlType="button"
                      icon={<DeleteOutlined />}
                      onClick={() =>
                        onDeleteFilter(filter.value, selectedRealm)
                      }
                    />
                  </Tooltip>,
                ]}
                children={
                  <List.Item.Meta key={filter.value} title={filter.label} />
                }
              />
            )}
          />
        </Col>
      </Row>
      <Row gutter={gutter}></Row>
      {/* <div className="notifications">
          <h3>Уведомления:</h3>
          <div className="notifications__list">
            {notifications.length === 0 && (
              <div>Пока нет никаких уведомлений...</div>
            )}
            {notifications.map((notice) => (
              <div className="notice">
                <ExclamationCircleOutlined />
                {notice.message}: {notice.description}
              </div>
            ))}
          </div>
        </div> */}
    </MainContainer>
  );
};

export default OperatorCard;
