import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Form, Button, Input, message } from "antd";

import MainContainer from "../../components/MainContainer";
import FileUploader from "../../components/FilesUploader";
import Table from "../../components/Table";
import Search from "../../components/Search_new";
import { TYPES } from "../../components/Search_new/consts";

import ISSUES_API from "../../api/appeals";
import MASS_PROBLEM_API from "../../api/massProblem";
import normalizeDate, { DATE_TIME } from "../../utils/normalizeDate";
import { useTabs, COMPONENTS } from "../../services/tabs";

import "./style.scss";

const COLUMNS = [
  {
    title: "Номер",
    align: "center",
    dataIndex: "human_readable_id",
    width: 100,
  },
  {
    width: 160,
    title: "Создано",
    dataIndex: "created_at",
    render: (text) => normalizeDate(text, DATE_TIME),
  },
  {
    title: "Описание",
    dataIndex: "description",
    ellipsis: true,
  },
  {
    width: 260,
    title: "Пользователь",
    dataIndex: "user_id",
    render: (text, row) => `${row.user.last_name} ${row.user.first_name}`,
  },
];

const TabelTitle = (props) => {
  const { performer, setPerformer } = props;

  return (
    <Search
      placeholder="Оператор или группа"
      types={[TYPES.operator, TYPES.group]}
      sought={performer}
      updateParent={setPerformer}
    />
  );
};

const Header = (props) => {
  const { loading } = props;

  return (
    <>
      <h2 className="header__title">Создание массовой проблемы</h2>
      <Button
        style={{ marginLeft: "auto" }} // TODO: убрать стили и заменить на класс
        className=""
        type="primary"
        form="mass-problem-creation-form"
        htmlType="submit"
        children="Создать"
        loading={loading}
      />
    </>
  );
};

const MassProblemCreationForm = (props) => {
  const { componentProps, setComponentProps, tabUuid } = props;

  // Store
  const userOperatorGroup = useSelector((store) => store.user.operatorsGroupId);
  const userId = useSelector((store) => store.user.id);

  // State
  // const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [issues, setIssues] = useState([]); // Список обращений, доступных для выбора
  const [files, setFiles] = useState([]); // Список идентификаторов прикрепленных файлов

  // Hooks
  const { openInNewTab, openInCurrentTab } = useTabs();

  function setDescription(e) {
    e.preventDefault();
    setComponentProps({ description: e.target.value });
  }

  function setFilter(filter) {
    setComponentProps({ filter });
    getIssues(filter);
  }

  function setSelectedIssues(selectedIssues) {
    setComponentProps({ selectedIssuesId: selectedIssues });
  }

  function setOperator(operator) {
    setComponentProps({ operator });
  }

  async function getIssues(filter) {
    // await setLoading(true);

    if (filter && filter.type === TYPES.operator) {
      await ISSUES_API.getOperatorAppeals(filter.id)
        .then((resp) => setIssues(resp.data))
        .catch((err) => console.error(err));
    }
    if (filter && filter.type === TYPES.group) {
      await ISSUES_API.getOpGroupAppeals(filter.id)
        .then((resp) => setIssues(resp.data))
        .catch((err) => console.error(err));
    }

    // await setLoading(false);
  }

  async function createMassProblem(form) {
    await setSubmitting(true);

    const payload = {
      description: form.description,
      operator_id: form.operator.id,
      issues_ids: form.issues_ids,
      file_ids: form.files.map((f) => f.id),
    };

    await MASS_PROBLEM_API.createMassProblem(payload)
      .then((resp) => {
        openInCurrentTab({
          component: COMPONENTS.MASS_PROBLEM_CARD,
          params: { massProblemId: resp.data.id },
        });
      })
      .catch((err) => message.error("Ошибка"));

    await setSubmitting(false);
  }

  function openOperatorCard(operatorId) {
    openInNewTab({
      component: COMPONENTS.OPERATOR_CARD,
      params: { operatorId },
      locationState: { operatorId },
    });
  }

  useEffect(() => {
    const initialState = {
      operator: { id: userId, type: TYPES.operator },
      description: "",
      selectedIssuesId: [],
      filter: {
        id: userOperatorGroup,
        type: TYPES.group,
      },
    };

    if (componentProps === null) {
      setComponentProps(initialState);
      getIssues(initialState.filter);
    } else {
      getIssues(componentProps.filter);
    }
  }, [tabUuid]);

  if (componentProps === null) return null;

  const fields = [
    {
      name: "operator",
      value: componentProps.operator,
    },
    {
      name: "description",
      value: componentProps.description,
    },
    {
      name: "issues_ids",
      value: componentProps.selectedIssuesId,
    },
    {
      name: "files",
      value: files,
    },
  ];
  return (
    <MainContainer
      className="page_mass-problem-creation-form"
      header={<Header loading={submitting} />}
    >
      <Form
        id="mass-problem-creation-form"
        layout="vertical"
        fields={fields}
        style={{ width: "80%", minWidth: "320px" }}
        onFinish={createMassProblem}
      >
        <Form.Item
          name="operator"
          label="Оператор:"
          rules={[
            {
              required: true,
              validator: () =>
                componentProps.operator
                  ? Promise.resolve()
                  : Promise.reject("Обязательное поле"),
            },
          ]}
        >
          <Search
            placeholder="Выберите исполнителя"
            sought={componentProps.operator}
            types={[TYPES.operator]}
            updateParent={setOperator}
          />
          {componentProps.operator && (
            <Button
              type="link"
              children="Открыть карточку"
              onClick={() => openOperatorCard(componentProps.operator.id)}
            />
          )}
        </Form.Item>
        <Form.Item
          name="description"
          label="Описание:"
          rules={[{ required: true, message: "Обязательное поле" }]}
        >
          <Input.TextArea
            className="mass-problem-creation-form__textarea"
            placeholder="Введите описание массовой проблемы"
            onChange={setDescription}
          />
        </Form.Item>
        <Form.Item
          name="issues_ids"
          label="Обращения:"
          // rules={[
          //   {
          //     required: true,
          //     validator: () =>
          //       componentProps.selectedIssuesId.length === 0
          //         ? Promise.reject("Обязательно поле")
          //         : Promise.resolve(),
          //   },
          // ]}
        >
          <Table
            size="small"
            columns={COLUMNS}
            data={issues}
            rowSelection={{
              type: "checkbox",
              selectedRowKeys: componentProps.selectedIssuesId,
              onChange: setSelectedIssues,
            }}
            title={() => (
              <TabelTitle
                performer={componentProps.filter}
                setPerformer={setFilter}
              />
            )}
          />
        </Form.Item>
        <Form.Item name="files" label="Файлы:">
          <FileUploader onChange={setFiles} files={files} />
        </Form.Item>
      </Form>
    </MainContainer>
  );
};

export default MassProblemCreationForm;
