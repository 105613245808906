import React from "react";
import { Typography } from "antd";

import "./style.scss";

const AppVersion = ({ version }) => {
  return (
    <Typography.Title level={5} type="secondary" className="component_app-version">
      v{version}
    </Typography.Title>
  );
};

export default AppVersion;
