export const messageFrom = {
  user: "user",
  others: "others",
  currentOperator: "currentOperator",
};

export const classNames = {
  user: "message_from-user",
  others: "",
  currentOperator: "message_from-current-operator",
};

export const IMAGE_MIME_TYPES = [
  "image/gif",
  "image/jpeg",
  "image/png",
  "image/svg+xml",
  "image/webp",
];
export const VIDEO_MIME_TYPES = [
  "video/mp4",
  "video/ogg",
  "video/webm",
  "video/quicktime",
];
