import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Row, Col } from "antd";
import { useLocation } from "react-router";

import Table, { TABLE_STYLE } from "../../components/Table";
import MainContainer from "../../components/MainContainer";
import EditableCardField, {
  EDITABLE_FIELD_TYPES,
} from "../../components/EditableCardField";

import { changeOperatorsGroupTitle } from "../../actions/operator/operator";
import OPERATORS_API from "../../api/operators";
// import { getOperatorsGroupData, getGroupOperators } from "../../api/operators";
import { useTabs, COMPONENTS } from "../../services/tabs";
import useRBAC, { UI_PERMISSIONS } from "../../services/rbac";

import "./style.scss";

const COLUMNS = [
  {
    dataIndex: "full_name",
    title: "Имя",
    customFilter: true,
  },
  {
    title: "Логин",
    dataIndex: "username",
    customFilter: true,
  },
];

const Header = () => {
  return (
    <>
      <h2 className="header__title">Информация о группе</h2>
    </>
  );
};

const SWITCHES = {
  title: false,
};

function GroupCard(props) {
  const { tabUuid, setTabError, error } = props;

  const dispatch = useDispatch();
  const location = useLocation();

  const groupId = location.state
    ? location.state.groupId
    : location.pathname.split("/")[3];

  // State
  const [title, setTitle] = useState("");
  const [operators, setOperators] = useState([]);
  const [switches, setSwitches] = useState(SWITCHES);
  const [loading, setLoading] = useState(false);

  // Hooks
  const { clickHandler } = useTabs();
  const { checkPermissionToRenderUI} = useRBAC();

  async function changeGroupTitle(groupId, title) {
    await dispatch(changeOperatorsGroupTitle(groupId, title));
    await getGroupData(groupId);
  }

  async function getGroup(groupId) {
    await OPERATORS_API.getOperatorsGroupData(groupId).then((resp) => {
      if (resp.status === 200) {
        setTitle(resp.data.title);
      } else {
        return Promise.reject(resp);
      }
    });
  }

  async function getGroupOperators(groupId) {
    await OPERATORS_API.getGroupOperators(groupId).then((resp) => {
      if (resp.status === 200) {
        setOperators(
          resp.data.map((operator) => ({
            ...operator,
            full_name: `${operator.last_name} ${operator.first_name}`,
          }))
        );
      } else {
        return Promise.reject(resp);
      }
    });
  }

  async function getGroupData(groupId) {
    await setLoading(true);

    try {
      await getGroup(groupId);
      await getGroupOperators(groupId);

      setTabError(null);
    } catch (err) {
      console.error(err);

      setTabError({
        title: "Ошибка получения группы операторов",
        status: err.response.status,
        message: err.response.data?.message,
      });
    }

    await setLoading(false);
  }

  useEffect(() => {
    getGroupData(groupId);
  }, [tabUuid]);

  function switchEditableCardField(fieldName) {
    setSwitches((switches) => ({
      ...switches,
      [fieldName]: !switches[fieldName],
    }));
  }

  return (
    <MainContainer
      error={error}
      loading={loading}
      className="group-card"
      headerClassName="group-card__header"
      header={<Header />}
    >
      <Row>
        <Col>
          <div className="group-card__group-title">
            <label className="field-name">Название: </label>
            <EditableCardField
              disabled={!checkPermissionToRenderUI(UI_PERMISSIONS['ui:management:operators-groups:card:edit-group-title'])}
              value={title}
              isEdit={switches.title}
              type={EDITABLE_FIELD_TYPES.input}
              saveChanges={(title) => changeGroupTitle(groupId, title)}
              editField={() => switchEditableCardField("title")}
            />
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <label style={{ fontWeight: "bold" }}>Операторы:</label>
          <Table
            tableStyle={TABLE_STYLE.zebra}
            data={operators}
            columns={COLUMNS}
            onRowClick={(e, operator) => {
              e.preventDefault();
              e.stopPropagation();

              clickHandler({
                component: COMPONENTS.OPERATOR_CARD,
                params: { operatorId: operator.id },
                locationState: { operatorId: operator.id },
              });
            }}
          />
        </Col>
      </Row>
    </MainContainer>
  );
}

export default GroupCard;
