import React from "react";
import moment from "moment";
import { Button } from "antd";

import Table, { TABLE_STYLE } from "../../components/Table";

import normalizeDate, { DATE_TIME } from "../../utils/normalizeDate";
import { useTabs, COMPONENTS } from "../../services/tabs";
import useRBAC, { PERMISSIONS, UI_PERMISSIONS } from "../../services/rbac";

const TableTitle = () => {
  // Hooks
  const { clickHandler } = useTabs();

  return (
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <Button
        type="primary"
        htmlType="button"
        children="Создать"
        onClick={() =>
          clickHandler({ component: COMPONENTS.LOCATION_CREATION_FORM })
        }
      />
    </div>
  );
};

const Locations = (props) => {
  const { loading, locations = [], onRowClick, onLocationDelete } = props;

  // Hooks
  const { checkAccess, checkPermissionToRenderUI } = useRBAC();

  // function getParentLocationName(parentId) {
  //   if (!parentId) return "-";

  //   const parentLocation = locations.find(
  //     (location) => location.id === parentId
  //   );

  //   if (parentLocation === undefined) {
  //     return "-";
  //   } else {
  //     return parentLocation.title;
  //   }
  // }

  function getLocationsMacAddresses(macAddresses) {
    if (!macAddresses || !Array.isArray(macAddresses)) return "-";

    return macAddresses.map((ma) => ma.id).join(", ");
  }

  const COLUMNS = [
    {
      title: "Название",
      dataIndex: "title",
      customFilter: true,
    },
    {
      title: "Родительская локация",
      dataIndex: "parentTitle",
      render: (title) => title || "-",
      // customFilter: true,
    },
    {
      title: "MAC адреса",
      dataIndex: "mac_address_masks",
      // customFilter: true,
      render: (text, row) => getLocationsMacAddresses(row.mac_address_masks),
    },
    {
      title: "Создана",
      dataIndex: "created_at",
      render: (date) => normalizeDate(date, DATE_TIME),
      width: 160,
      sorter: {
        compare: (a, b) =>
          moment(a.created_at).unix() - moment(b.created_at).unix(),
      },
    },
    {
      title: "Изменена",
      dataIndex: "updated_at",
      render: (date) => normalizeDate(date, DATE_TIME),
      width: 160,
      sorter: {
        compare: (a, b) =>
          moment(a.updated_at).unix() - moment(b.updated_at).unix(),
      },
    },
  ];

  // Проверяем разрешение на отображение кнопки удаления локации
  if (
    checkPermissionToRenderUI(
      UI_PERMISSIONS["ui:management:locations:list:delete-location"]
    )
  ) {
    COLUMNS.push({
      width: 110,
      render: (text, row) => (
        <Button
          danger
          children="Удалить"
          onClick={(e) => {
            e.stopPropagation();
            onLocationDelete(row.id);
          }}
        />
      ),
    });
  }

  return (
    <div>
      <Table
        loading={loading}
        scroll={{ y: "calc(100vh - 298px)" }}
        title={
          checkPermissionToRenderUI(
            UI_PERMISSIONS["ui:management:locations:list:create"]
          ) && TableTitle
        }
        data={locations}
        columns={COLUMNS}
        onRowClick={
          checkAccess(PERMISSIONS["locations:get:one"]) ? onRowClick : () => {}
        }
        tableStyle={TABLE_STYLE.zebra}
      />
    </div>
  );
};

export default Locations;
