import React, { useState, useEffect } from "react";
import { Modal, message, Button } from "antd";

import Table from "../Table";
import Spinner from "../Spinner";
import IssueSelector from "../IssueSelector";

import MASS_PROBLEM_API from "../../api/massProblem";
import normalizeDate, { DATE_TIME } from "../../utils/normalizeDate";

import "./style.scss";

const MASS_PROBLEMS_TABLE_COLS = [
  {
    dataIndex: "created_at",
    title: "Создано",
    width: 140,
    render: (createdAt) => normalizeDate(createdAt, DATE_TIME),
  },
  {
    dataIndex: "description",
    title: "Описание",
    ellipsis: true,
  },
  {
    dataIndex: "operator",
    title: "Исполнитель",
    width: 260,
    ellipsis: true,
    render: (text, row) =>
      row.operator && `${row.operator.last_name} ${row.operator.first_name}`,
  },
];

const AddToMassProblem = (props) => {
  const {
    defaultSelectedIssues = [],
    filter = null,
    visible = false,
    onClose = () => {},
  } = props;

  // State
  const [submitting, setSubmitting] = useState(false);
  const [selectedIssues, setSelectedIssues] = useState(
    Array.isArray(defaultSelectedIssues) ? defaultSelectedIssues : []
  );
  const [massProblemsFetching, setMassProblemsFetching] = useState(false);
  const [massProblems, setMassProblems] = useState([]);
  const [selectedMassProblem, setSelectedMassProblem] = useState(null);

  const massProblemSelection = {
    type: "radio",
    selectedRowKeys: [selectedMassProblem],
    onChange: (selectedRowKeys, selectedRow) =>
      setSelectedMassProblem(selectedRow[0].id),
  };

  function getMassProblems() {
    setMassProblemsFetching(true);

    MASS_PROBLEM_API.getMassProblemsList()
      .then((resp) => setMassProblems(resp.data))
      .catch((err) => console.error(err));

    setMassProblemsFetching(false);
  }

  function addIssuesToMassProblem(massProblemId, issueIds = []) {
    setSubmitting(true);

    MASS_PROBLEM_API.addIssuesToMassProblem(massProblemId, issueIds)
      .then(() => {
        message.success(
          issueIds.length > 1 ? "Обращение добавлено" : "Обращения добавлены"
        );
        setTimeout(() => onClose(), 300);
      })
      .catch((err) => {
        console.error(err);
      });

    setSubmitting(false);
  }

  function setDisabledSubmitButton() {
    if (selectedIssues.length < 0) return true;
    if (!selectedMassProblem) return true;

    return false;
  }

  useEffect(() => {
    if (visible) {
      getMassProblems();
    }
  }, [visible]);

  return (
    <Modal
      width="auto"
      style={{ maxWidth: 1200, minWidth: 1024 }}
      wrapClassName="component_add-issues-to-mass-problem"
      visible={visible}
      onCancel={onClose}
      footer={null}
    >
      <div className="add-issues-to-mass-problem__modal">
        <h4 className="add-issues-to-mass-problem__title">
          Добавить обращения к массовой проблеме
        </h4>
        <IssueSelector
          defaultFilter={filter}
          defaultSelectedIssues={selectedIssues}
          onSelect={setSelectedIssues}
        />
        <div>
          <p>Массовые проблемы</p>
          {massProblemsFetching ? (
            <Spinner />
          ) : (
            <Table
              data={massProblems}
              columns={MASS_PROBLEMS_TABLE_COLS}
              rowSelection={massProblemSelection}
              rowKey={(row) => row.id}
              title={null}
            />
          )}
        </div>
        <div className="add-issues-to-mass-problem__submit-button-wrapper">
          <Button
            type="primary"
            children="Добавить"
            loading={submitting}
            disabled={setDisabledSubmitButton()}
            onClick={() =>
              addIssuesToMassProblem(selectedMassProblem, selectedIssues)
            }
          />
        </div>
      </div>
    </Modal>
  );
};

export default AddToMassProblem;
