import React, { useState } from "react";
import { Input, Button } from "antd";
import { EnterOutlined } from "@ant-design/icons";

import SelectedFilter from "./selectedFilter";
import ExistenceSelect from "./existenceSelect";

const NumberFilter = ({
  label = "",
  selected = [],
  comparisons = [],
  existence = undefined,
  onSelect = () => {},
  onRemove = () => {},
  onChangeComparison = () => {},
  onChangeExistence = () => {},
}) => {
  const [query, setQuery] = useState("");

  function replaceNonDigitChars(value = "") {
    return value.replace(/\D/gim, "");
  }

  function onChangeInput(value) {
    setQuery(replaceNonDigitChars(value));
  }

  function onSelectFilter(query) {
    if (!query) return;

    onSelect({ value: query, label: query });
    setQuery("");
  }

  return (
    <div className="search-filter__filter search-filter__filter_number">
      <div className="filter__label">
        {label}
        {existence !== undefined && (
          <ExistenceSelect
            value={existence.selected}
            options={existence.options}
            onChange={onChangeExistence}
          />
        )}
      </div>
      {!(existence && existence.selected) && (
        <>
          <Input
            allowClear
            size="small"
            placeholder="Введите число"
            className="filter__input-number"
            value={query}
            style={{ marginBottom: 10 }}
            onChange={(e) => onChangeInput(e.target.value)}
            onPressEnter={() => onSelectFilter(query)}
            addonAfter={
              <Button
                title="Добавить"
                size="small"
                icon={<EnterOutlined />}
                onClick={() => onSelectFilter(query)}
              />
            }
          />
          <div>
            {selected.map((filter, idx) => (
              <SelectedFilter
                key={`${filter.value}_${idx}`}
                comparisons={{
                  selected: selected.comparison,
                  options: comparisons,
                }}
                filterLabel={filter.label}
                onChange={(value) => onChangeComparison(idx, value)}
                onRemove={() => onRemove(idx)}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default NumberFilter;
