import React from "react";

import { TYPES } from "./consts";

const employeeOptGroup = (props) => {
  const { employees, onClick } = props;

  if (!Array.isArray(employees) || employees.length === 0) {
    return (
      <div className="options-list__optgroup">
        Сотрудники
        <button disabled className="optgroup__option_undefined">
          Сотрудники не найдены
        </button>
      </div>
    );
  }

  employees.forEach((employee) => (employee.type = TYPES.employee));

  return (
    <div className="options-list__optgroup">
      Сотрудники
      {employees.map((employee) => (
        <button
          type="button"
          onClick={() =>
            onClick(
              `${employee.last_name} ${employee.first_name} ${employee.patronymic_name}`,
              JSON.stringify(employee)
            )
          }
          key={employee.id}
          className="optgroup__option"
        >
          <p className="option__title">{`${employee.last_name} ${employee.first_name} ${employee.patronymic_name}`}</p>
          {employee.department ? (
            <p className="option__subtitle">
              Департамент: {employee.department}
            </p>
          ) : null}
        </button>
      ))}
    </div>
  );
};

export default employeeOptGroup;
