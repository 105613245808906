import React from "react";

import Spinner from "../../components/Spinner";

import "./style.scss";

const GlobalSpinner = (props) => {
  const { loading = false } = props;

  if (!loading) {
    return null;
  }

  return (
    <div className="component_global-spinner">
      <div className="global-spinner__spinner-wrapper">
        <Spinner size="large" />
      </div>
    </div>
  );
};

export default GlobalSpinner;
