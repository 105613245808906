import actionTypes from "../actions/messages/actionTypes";

const initialState = {
  dialogs: [],
  selectedDialog: null,
  messages: [],
};

function updateDialogs(dialogs, payload) {
  const updatedDialogs = [...dialogs];
  const dialogIndex = updatedDialogs.findIndex(
    (dialog) => dialog.userID === payload.userID
  );

  if (dialogIndex === -1) {
    return [
      ...updatedDialogs,
      {
        userID: payload.userID,
        userName: payload.authorName,
        unread: true,
        selected: false,
        createdAt: new Date(),
        lastMessage: payload.file ? "Вложенный файл" : payload.text,
        lastMessageAuthorID: payload.authorID,
        lastMessageAuthorName: payload.authorName,
      },
    ];
  }

  updatedDialogs[dialogIndex].unread = !payload.isCurOperator;
  updatedDialogs[dialogIndex].createdAt = payload.createdAt;
  updatedDialogs[dialogIndex].lastMessage = payload.file
    ? "Вложенный файл"
    : payload.text;
  updatedDialogs[dialogIndex].lastMessageAuthorID = payload.authorID;
  updatedDialogs[dialogIndex].lastMessageAuthorName =
    payload.userID !== payload.authorID ? payload.authorName : false;
  updatedDialogs[dialogIndex].isCurOperator = payload.isCurOperator;

  return updatedDialogs;
}

function updateMessages(messages, payload) {
  return [...messages, payload];
}

function getNewMessage(state, payload) {
  const { dialogs, selectedDialog, messages } = state;

  if (selectedDialog && selectedDialog.userID === payload.userID) {
    return {
      ...state,
      dialogs: updateDialogs(dialogs, payload),
      messages: updateMessages(messages, payload),
    };
  }

  return { ...state, dialogs: updateDialogs(dialogs, payload) };
}

export function messagesReducer(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_ACTIVE_DIALOGS:
      return { ...state, dialogs: action.payload };
    case actionTypes.SELECT_DIALOG:
      return { ...state, ...action.payload };
    case actionTypes.GET_MESSAGES:
      return { ...state, messages: [...action.payload, ...state.messages] };
    case actionTypes.CLEAR_MESSAGES:
      return { ...state, messages: [] };
    case actionTypes.UNSELECT_DIALOG:
      return { ...state, selectedDialog: null, dialogs: [] };
    case actionTypes.NEW_MESSAGE:
      return getNewMessage(state, action.payload);
    case actionTypes.SEND_MESSAGE:
      return getNewMessage(state, action.payload);
    default:
      return { ...state };
  }
}
