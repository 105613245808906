import React from "react";

import { TYPES } from "./consts";

const usersOptGroup = (props) => {
  const { users, onClick } = props;

  if (!Array.isArray(users) || users.length === 0) {
    return (
      <div className="options-list__optgroup">
        Пользователи
        <button disabled className="optgroup__option_undefined">
          Пользователи не найдены
        </button>
      </div>
    );
  }

  users.forEach((user) => (user.type = TYPES.user));
  return (
    <div className="options-list__optgroup">
      Пользователи
      {users.map((user) => (
        <button
          type="button"
          onClick={() =>
            onClick(
              `${user.last_name} ${user.first_name} ${user.patronymic_name}`,
              JSON.stringify(user)
            )
          }
          key={user.id}
          className="optgroup__option"
        >
          <p className="option__title">{`${user.last_name} ${user.first_name} ${user.patronymic_name}`}</p>
          {user.department ? (
            <p className="option__subtitle">Департамент: {user.department}</p>
          ) : null}
        </button>
      ))}
    </div>
  );
};

export default usersOptGroup;
