import { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import createUUID from "../utils/createUUID";
import fileReader from "../utils/fileReader";
import ISSUES_API from "../api/appeals";
import CHAT_HTTP_API from "../api/chatHTTP";
import MESSAGES_ACTIONS from "../actions/messages/messages";
import { removeReadMessages } from "../actions/notification/notification";
import errorHandling from "../services/errors/errorHandling";
import { INCOMING_MESSAGE_HANDLING_ERROR } from "../actions/errors/actionTypes";
import normalizeDate, { ONLY_TIME, FULL_DATE } from "../utils/normalizeDate";

// const dialog = {
//   id: "",
//   user: {
//     id: "",
//     last_name: "",
//     first_name: "",
//     current_pp_name: "",
//   },
//   messages: [],
//   unread: false,
//   offset: 0,
//   limit: 20,
//   loadMore: false,
//   error: null,
//   sending: false,
//   fetching: false,
//   inputtedText: "",
//   attachedFiles: [],
//   disabled: false,
//   irrelevant: false,
//   inWork: true,
// };
// const message = {
//   id: "",
//   file: null,
//   unread: false,
//   message: "",
//   user_id: "",
//   created_at: "",
//   updated_at: "",
//   deleted_at: "",
//   operator_id: null,
//   messageFrom: {
//     id: "",
//     last_name: "",
//     first_name: "",
//   },
// };
// const attachedFile = {
//   id: new Date().getTime(),
//   lastModified: 1619519619845,
//   lastModifiedDate: new Date(),
//   name: "784229.jpeg",
//   size: 28930,
//   type: "image/jpeg",
//   webkitRelativePath: "",
// };

function useMessages(props = {}) {
  const dispatch = useDispatch();

  // initial values
  const { defaultDialogues = {}, defaultSelectedDialogue = null } = props;

  // Store
  const { lastName, firstName, id } = useSelector((store) => store.user);

  // State
  const [dialogues, setDialogues] = useState(defaultDialogues);
  const [selectedDialogue, setSelectedDialogue] = useState(defaultSelectedDialogue);

  // Refs
  const inputRef = useRef();
  const messagesListRef = useRef();

  async function getDialogueData(id, isSelected, defaultData) {
    return await MESSAGES_ACTIONS.getDialogData(id, isSelected, defaultData);
  }

  /**
   * Получаем список активных диалогов оператора
   */
  async function getDialogues(operatorId, selectedDialogue, defaultDialogues = {}) {
    const waitingUsersIds = []; // список пользователей без обращения
    const handlingUsersIds = []; // список пользователей с обращением

    function isSelectedDialogue(dialogueId, selectedDialogue) {
      return selectedDialogue && dialogueId === selectedDialogue;
    }

    try {
      // Получаем массив актуальных диалогов для оператора
      await CHAT_HTTP_API.getActualDialogues().then((resp) =>
        waitingUsersIds.push(...resp.data)
      );
      // Получаем массив уникальных пользователей по открытым обращениям оператора
      await ISSUES_API.getOperatorAppeals(operatorId).then((resp) => {
        const users = resp.data.map((dialogue) => dialogue.user_id);
        const uniqueIDs = new Set(users);
        handlingUsersIds.push(...uniqueIDs);
      });
      // Получаем данные ожидающих пользователей
      const waitingDialogues = waitingUsersIds.map(
        async (id) =>
          await getDialogueData(id, isSelectedDialogue(id, selectedDialogue), {
            ...defaultDialogues[id],
            inWork: false,
          })
      );
      // Получаем данные пользователей в обработке
      const handlingDialogues = handlingUsersIds.map(
        async (id) =>
          await getDialogueData(id, isSelectedDialogue(id, selectedDialogue), {
            ...defaultDialogues[id],
            inWork: true,
          })
      );

      // Ожидаем выполнение всех промисов и формируем хэш-таблицу диалогов { [dialogue_id]: [dialogue_data] }
      return await Promise.all([...waitingDialogues, ...handlingDialogues]).then(
        (dialogues) =>
          dialogues.reduce((acc, dialogue) => {
            const { id, ...rest } = dialogue;

            return { ...acc, [id]: rest };
          }, {})
      );
    } catch (err) {
      console.error(err);
      return Promise.reject(err);
    }
  }

  /* 
    Сортировка массива диалогов от более актуального к менее
  */
  function sortDialoguesArray(dialogues) {
    return dialogues.sort((a, b) => {
      const aLastMessage = a.messages[a.messages.length - 1];
      const bLastMessage = b.messages[b.messages.length - 1];

      if (aLastMessage && bLastMessage) {
        if (new Date(aLastMessage.created_at) > new Date(bLastMessage.created_at))
          return -1;
        if (new Date(aLastMessage.created_at) < new Date(bLastMessage.created_at))
          return 1;

        return 0;
      } else {
        return 0;
      }
    });
  }

  /*
    Форматируем хэш-таблицу диалогов в массив и добавляем id
  */
  function getDialoguesArray(dialogues) {
    return Object.keys(dialogues).reduce(
      (acc, dialogueId) => [...acc, { ...dialogues[dialogueId], id: dialogueId }],
      []
    );
  }

  /**
   * Получаем массив сообщений для диалога
   * @param {string} dialogueId - идентификатор целевого диалога
   * @param {number} limit - количество запрашиваемых сообщений
   * @param {number} offset - номер сообщения, с которого начинается отсчет получаемых сообщений
   */
  async function getMessages(dialogueId, limit, offset) {
    try {
      const response = await CHAT_HTTP_API.getUserMessages(dialogueId, offset, limit);
      const messages = await Promise.all(
        response.data.map(
          async (message) => await MESSAGES_ACTIONS.getMessageDataForMessangerTab(message)
        )
      );

      return Promise.resolve(messages);
    } catch (err) {
      console.error(err);

      return Promise.reject(err);
    }
  }

  /**
   * Обработка входящих сообщений
   * @param {object} message - новое сообщение
   * @param {object} dialogues - хэш-таблица диалогов
   * @param {string} selectedDialogue - текущий выбранный диалог
   */
  async function incomingMessageHandling(message, dialogues, selectedDialogue) {
    try {
      const _message = await CHAT_HTTP_API.getMessage(message.message_id);
      // Форматируем данные сообщения
      const _messageData = await MESSAGES_ACTIONS.getMessageDataForMessangerTab(
        _message.data
      );
      const { user_id: _id } = _messageData;

      // Проверяем наличие диалога в таблице
      if (_messageData.user_id in dialogues) {
        if (_messageData.user_id === selectedDialogue) {
          dispatch(removeReadMessages(_messageData.user_id));
          scrollToBottomMessagesList(messagesListRef);
        }

        setDialogues({
          ...dialogues,
          [_id]: {
            ...dialogues[_id],
            offset: dialogues[_id].offset + 1,
            unread: _id !== selectedDialogue,
            messages: [...dialogues[_id].messages, _messageData],
          },
        });
      } else {
        const _newDialogue = await getDialogueData(_id);
        const { id, ...rest } = _newDialogue;

        setDialogues({
          ...dialogues,
          [_id]: { ...rest, unread: true },
        });
      }
    } catch (err) {
      console.error(err);
      dispatch(errorHandling(err, INCOMING_MESSAGE_HANDLING_ERROR));
    }
  }

  /*
   */
  async function sendMessage(dialogue) {
    const { id, inputtedText, attachedFiles } = dialogue;

    try {
      const updatedDialogue = { ...dialogue };

      if (!_isEmptyMessage(inputtedText)) {
        const response = await _sendText(id, inputtedText);

        updatedDialogue.inputtedText = "";
        updatedDialogue.offset = updatedDialogue.offset + 1;
        updatedDialogue.messages = [
          ...updatedDialogue.messages,
          {
            ...response.data,
            messageFrom: {
              id,
              last_name: lastName,
              first_name: firstName,
            },
          },
        ];
      }

      if (attachedFiles.length > 0) {
        const { messages, errors } = await _sendFiles(id, attachedFiles);

        updatedDialogue.offset = updatedDialogue.offset + messages.length;
        updatedDialogue.messages = [...updatedDialogue.messages, ...messages];
        updatedDialogue.attachedFiles = updatedDialogue.attachedFiles.filter((file) =>
          errors.includes(file.id)
        );
        updatedDialogue.error =
          errors.length > 0 ? `Не удалось отправить файлы: ${errors.length}` : null;
      }

      return Promise.resolve(updatedDialogue);
    } catch (err) {
      return Promise.reject({
        ...dialogue,
        error: err.response?.data.message,
      });
    }
  }

  function scrollToBottomMessagesList(ref, options = { behavior: "smooth" }) {
    if (ref.current) {
      // Задержка скролла, чтобы сообщения успели отрисоваться
      setTimeout(() => ref.current?.scrollIntoView(options), 50);
    }
  }

  /*
    Делаем диалог неактуальным и неактивным.
    Используется когда пользователь закрупляется за другим оператором
  */
  function setDialogueIrrelevant(dialogueId) {
    setDialogues((dialogues) => ({
      ...dialogues,
      [dialogueId]: {
        ...dialogues[dialogueId],
        disabled: true,
        irrelevant: true,
      },
    }));
  }

  function setDialogueLoadingMessages(dialogueId, bool) {
    setDialogues((dialogues) => ({
      ...dialogues,
      [dialogueId]: {
        ...dialogues[dialogueId],
        fetching: bool,
      },
    }));
  }

  function setDialogueSending(dialogueId, bool) {
    setDialogues((dialogues) => ({
      ...dialogues,
      [dialogueId]: {
        ...dialogues[dialogueId],
        sending: bool,
      },
    }));
  }

  function resetDialogueError(dialogueId) {
    setDialogueError(dialogueId, null);
  }

  function setDialogueError(dialogueId, error) {
    setDialogues((dialogues) => ({
      ...dialogues,
      [dialogueId]: {
        ...dialogues[dialogueId],
        error,
      },
    }));
  }

  function onChangeInputText(dialogueId, text) {
    setDialogues((dialogues) => ({
      ...dialogues,
      [dialogueId]: {
        ...dialogues[dialogueId],
        inputtedText: text,
      },
    }));
  }

  function addFile(dialogueId, file) {
    setDialogues((dialogues) => ({
      ...dialogues,
      [dialogueId]: {
        ...dialogues[dialogueId],
        attachedFiles: [
          ...dialogues[dialogueId].attachedFiles,
          { file, id: createUUID() },
        ],
      },
    }));
  }

  function removeFile(dialogueId, fileId) {
    setDialogues((dialogues) => ({
      ...dialogues,
      [dialogueId]: {
        ...dialogues[dialogueId],
        attachedFiles: dialogues[dialogueId].attachedFiles.filter(
          (file) => file.id !== fileId
        ),
      },
    }));
  }

  function setDialogueRead(dialogueId) {
    setDialogues((dialogues) => ({
      ...dialogues,
      [dialogueId]: {
        ...dialogues[dialogueId],
        unread: false,
      },
    }));
  }

  function setDialogueInWork(dialogueId, bool) {
    setDialogues((dialogues) => ({
      ...dialogues,
      [dialogueId]: {
        ...dialogues[dialogueId],
        inWork: bool,
      },
    }));
  }

  function getDateTimeFormat(createdAt) {
    const dayOfWeek = ["Вс", "Пн", "Вт", "Ср", "Чт", "Пт", "Сб"];
    const today = new Date();
    const day = today.getDate();
    const month = today.getMonth();
    const year = today.getFullYear();
    const weekAgo = new Date();
    weekAgo.setDate(today.getDate() - 6);

    const created = new Date(createdAt);
    const createdDay = created.getDate();
    const createdMonth = created.getMonth();
    const createdYear = created.getFullYear();

    const thisMonthAndYear = month === createdMonth && year === createdYear;
    const messageCreatedToday = thisMonthAndYear && day === createdDay;
    const messageCreatedYesterday = thisMonthAndYear && day - 1 === createdDay;
    const messageCreatedThisWeek = created >= weekAgo && created < today;

    if (messageCreatedToday) {
      return normalizeDate(createdAt, ONLY_TIME);
    }

    if (messageCreatedYesterday) {
      return "Вчера";
    }

    if (messageCreatedThisWeek) {
      return dayOfWeek[created.getDay()];
    }

    return normalizeDate(createdAt, FULL_DATE);
  }

  async function _sendText(dialogueId, text) {
    return await CHAT_HTTP_API.sendMessage(dialogueId, text);
  }

  async function _sendFiles(dialogueId, files) {
    const messages = [];
    const errors = [];

    for (let i = 0; i < files.length; i++) {
      const { file } = files[i];

      try {
        const formData = new FormData();
        await formData.append("file", file);
        const response = await CHAT_HTTP_API.sendFile(dialogueId, formData);
        const base64 = await fileReader(file);

        messages.push({
          ...response.data,
          file: {
            ...response.data.file,
            size: file.size,
            type: file.type,
            base64,
          },
          messageFrom: {
            id,
            last_name: lastName,
            first_name: firstName,
          },
        });
      } catch (err) {
        errors.push(file.id);
      }
    }

    return { messages, errors };
  }

  function _isEmptyMessage(text) {
    return /^(|\s)+$/.test(text);
  }

  return {
    dialogues,
    getDialogues,
    setDialogues,
    getDialogueData,
    getDialoguesArray,
    sortDialoguesArray,
    setDialogueIrrelevant,
    selectedDialogue,
    setSelectedDialogue,
    getMessages,
    sendMessage,
    incomingMessageHandling,
    messagesListRef,
    scrollToBottomMessagesList,
    setDialogueLoadingMessages,
    setDialogueSending,
    resetDialogueError,
    setDialogueError,
    onChangeInputText,
    addFile,
    removeFile,
    setDialogueRead,
    setDialogueInWork,
    inputRef,
    getDateTimeFormat,
  };
}

export default useMessages;
