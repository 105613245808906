export const ROOT = "/";
export const LOGIN = "/login";

// Обращения
export const ISSUES = "/issues";
export const ISSUE_CREATION_FORM = ISSUES + "/new";
export const ISSUE_CARD = ISSUES + "/:issueId";

// Задачи
export const TASKS = "/tasks";
export const TASK_CREATION_FORM = TASKS + "/new";
export const TASK_CARD = TASKS + "/:taskId";

// Массовые проблемы
export const MASS_PROBLEMS = "/mass-problems";
export const MASS_PROBLEM_CREATION_FORM = MASS_PROBLEMS + "/new";
export const MASS_PROBLEM_CARD = MASS_PROBLEMS + "/:massProblemId";

// Сообщения
export const MESSAGES = "/messages";

// Пользователи
export const USERS = "/users";
export const USER_CARD = `${USERS}/:userID`;

// Управление
export const MANAGEMENT = "/management";

// Профиль
export const PROFILE = "/profile";

// Операторы
export const OPERATORS = "/operators";
export const OPERATOR_CARD = OPERATORS + "/:operatorId";

// Группы
export const GROUPS = "/groups";
export const GROUP_CREATION_FORM = GROUPS + "/new";
export const GROUP_CARD = GROUPS + "/:groupId";

// Роли
export const ROLES = "/roles";
export const ROLE_CREATION_FORM = ROLES + "/new";
export const ROLE_CARD = ROLES + "/:roleName";

// Локации
export const LOCATIONS = "/locations";
export const LOCATION_CREATION_FORM = LOCATIONS + "/new";
export const LOCATION_CARD = LOCATIONS + "/:locationId";

// LDAP
export const LDAP_PROPS_LIST = "/ldap";
export const LDAP_PROPS_CREATION_FORM = LDAP_PROPS_LIST + "/new";
export const LDAP_PROPS_CARD = LDAP_PROPS_LIST + "/:groupName";

// Search
export const SEARCH = "/search";

// Отчеты
export const REPORT = "/report";

const PATHS = {
  ROOT,
  LOGIN,
  ISSUES,
  ISSUE_CREATION_FORM,
  ISSUE_CARD,
  TASKS,
  TASK_CREATION_FORM,
  TASK_CARD,
  MASS_PROBLEMS,
  MASS_PROBLEM_CREATION_FORM,
  MASS_PROBLEM_CARD,
  MESSAGES,
  USERS,
  USER_CARD,
  MANAGEMENT,
  PROFILE,
  OPERATORS,
  OPERATOR_CARD,
  GROUPS,
  GROUP_CREATION_FORM,
  GROUP_CARD,
  ROLES,
  ROLE_CREATION_FORM,
  ROLE_CARD,
  LOCATIONS,
  LOCATION_CREATION_FORM,
  LOCATION_CARD,
  LDAP_PROPS_LIST,
  LDAP_PROPS_CREATION_FORM,
  LDAP_PROPS_CARD,
  SEARCH,
  REPORT,
};

export default PATHS;
