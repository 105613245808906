import React, { useState, useEffect } from "react";
import { Input, Button, Upload } from "antd";
import { SendOutlined, PaperClipOutlined, CloseOutlined } from "@ant-design/icons";

import MessagesList from "../../containers/Messanger/messages";
import SendingError from "../../containers/Messanger/sendingError";

import useTextArea from "../../hooks/texarea.hook";
import useMessages from "../../hooks/messages.hook";

import "./style.scss";
import Spinner from "../Spinner";

const Chat = (props) => {
  const {
    disabled,
    userId,
    operatorId,
    newMessage,
    addFileToIssue,
    sendingMessageAllowed = false,
    onMessageClick,
    clickableMessages,
  } = props;

  // State
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Hooks
  const {
    selectedDialogue,
    dialogues,
    setDialogues,
    getDialogueData,
    setSelectedDialogue,
    getMessages,
    incomingMessageHandling,
    sendMessage,
    inputRef,
    messagesListRef,
    scrollToBottomMessagesList,
    setDialogueLoadingMessages,
    setDialogueSending,
    resetDialogueError,
    onChangeInputText,
    addFile,
  } = useMessages();
  const { getInput, setFocus, getSelection, setSelection, putNewLine } = useTextArea();

  const dialogueData = dialogues[selectedDialogue];

  function onInputPressEnter(dialogue, event) {
    if (event.ctrlKey) {
      onPutNewLine(dialogue.id, event.target);
    } else if (!event.ctrlKey) {
      event.preventDefault();
      onSendMessage(dialogue);
    }
  }

  async function onPutNewLine(dialogueId, input) {
    const selection = getSelection(input);

    const newValue = putNewLine(input.value, selection);
    const newSelection = {
      start: selection.start + 1,
      end: selection.start + 1,
    };

    await onChangeInputText(dialogueId, newValue);
    setSelection(input, newSelection);
  }

  async function onSendMessage(dialogue) {
    const { id } = dialogue;
    const input = getInput(inputRef);

    await setDialogueSending(id, true);

    try {
      const response = await sendMessage(dialogue);

      await setDialogues((dialogues) => ({
        ...dialogues,
        [id]: {
          ...dialogue,
          ...response,
        },
      }));

      await scrollToBottomMessagesList(messagesListRef, { behavior: "smooth" });
    } catch (err) {
      setDialogues((dialogues) => ({
        ...dialogues,
        [id]: { ...err },
      }));
    }

    await setDialogueSending(id, false);
    if (input) setFocus(input);
  }

  function onDropFiles(dialogueId, fileList) {
    if (fileList.length > 0) {
      let i = 0;

      while (i < fileList.length) {
        addFile(dialogueId, fileList[i]);
        i = i + 1;
      }
    }
  }

  async function lazyLoading(dialogue) {
    if (dialogue.loadMore) {
      await setDialogueLoadingMessages(dialogue.id, true);
      const _dialogue = await onGetMessages(dialogue);
      await setDialogueLoadingMessages(dialogue.id, false);

      setDialogues((dialogues) => ({
        ...dialogues,
        [dialogue.id]: _dialogue,
      }));
    }
  }

  /**
   * Получаем сообщения
   * @param {object} dialogue - целевой диалог, для которого получаем сообщения
   */
  async function onGetMessages(dialogue) {
    const { id, limit, offset } = dialogue;

    try {
      const messages = await getMessages(id, limit, offset);

      return {
        ...dialogue,
        messages: [...messages, ...dialogue.messages],
        offset: messages.length + offset,
        loadMore: messages.length === limit,
      };
    } catch (err) {
      return {
        ...dialogue,
        error: err,
      };
    }
  }

  function removeFiles(dialogueId) {
    setDialogues((dialogues) => ({
      ...dialogues,
      [dialogueId]: {
        ...dialogues[dialogueId],
        attachedFiles: [],
      },
    }));
  }

  function onPaste(dialogueId, event) {
    const { files } = event.clipboardData;

    onDropFiles(dialogueId, files);
  }

  useEffect(() => {
    if (userId) {
      (async () => {
        await setLoading(true);

        try {
          const _dialogue = await getDialogueData(userId, true);

          setDialogues({ [userId]: { ..._dialogue } });
          setSelectedDialogue(userId);
          await setLoading(false);
          scrollToBottomMessagesList(messagesListRef, { behavior: "auto" });
        } catch (err) {
          await setLoading(false);
          setError(`Ошибка загрузки чата\n${err.response?.data.message}`);

          console.error(err);
        }
      })();
    }
  }, [userId]);

  useEffect(() => {
    if (newMessage?.user_id === selectedDialogue) {
      (async () => {
        await incomingMessageHandling(newMessage, dialogues, selectedDialogue);
      })();
    }
  }, [newMessage]);

  if (loading)
    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spinner />
      </div>
    );
  if (error) {
    return (
      <div
        style={{
          height: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {error}
      </div>
    );
  }

  if (!dialogueData) return null;
  if (Object.keys(dialogueData).length === 0) return null;

  return (
    <div className="component_chat-form">
      <MessagesList
        onMessageClick={onMessageClick}
        messages={dialogueData.messages}
        loading={dialogueData.fetching}
        currentOperatorId={operatorId}
        messagesListRef={messagesListRef}
        scrollToBottom={() =>
          scrollToBottomMessagesList(messagesListRef, {
            behavior: "smooth",
          })
        }
        onDropFiles={(fileList) => onDropFiles(selectedDialogue, fileList)}
        onFetch={() => lazyLoading({ ...dialogueData, id: selectedDialogue })}
        addFileToIssue={addFileToIssue}
        clickable={clickableMessages}
      />
      {sendingMessageAllowed && (
        <div className="chat-form__input-wrapper">
          <div
            className={`attached-files ${
              dialogueData.attachedFiles.length > 0 && "shown"
            }`}
          >
            Прикреплено {dialogueData.attachedFiles.length} файлов
            <Button
              type="text"
              size="small"
              icon={<CloseOutlined />}
              onClick={() => removeFiles(selectedDialogue)}
              style={{ marginLeft: "auto" }}
            />
          </div>

          <SendingError
            message={dialogueData.error}
            resetError={() => resetDialogueError(selectedDialogue)}
          />

          <div style={{ position: "relative" }}>
            <Upload
              multiple
              fileList={dialogueData.attachedFiles}
              showUploadList={false}
              beforeUpload={(file) => addFile(selectedDialogue, file)}
              customRequest={() => {}}
              className="paper-clip"
            >
              <Button
                htmlType="button"
                type="text"
                size="large"
                icon={<PaperClipOutlined />}
              />
            </Upload>

            <Input.TextArea
              autoFocus
              ref={inputRef}
              value={dialogueData.inputtedText}
              disabled={disabled || dialogueData.sending}
              bordered={false}
              autoSize={{ minRows: 1, maxRows: 4 }}
              className="chat-form__input"
              placeholder="Введите что-нибудь..."
              onChange={(event) =>
                onChangeInputText(selectedDialogue, event.target.value)
              }
              onPaste={(event) => onPaste(selectedDialogue, event)}
              onPressEnter={(event) =>
                onInputPressEnter({ ...dialogueData, id: selectedDialogue }, event)
              }
            />

            <Button
              className="chat-form__button chat-form__button_send"
              type="link"
              loading={dialogueData.sending}
              disabled={disabled}
              icon={<SendOutlined />}
              onClick={() => onSendMessage(dialogueData)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Chat;
