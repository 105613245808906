import React, { useEffect, useState } from "react";
import lodashIsEqual from "lodash.isequal";
import lodashReduce from "lodash.reduce";

import MainContainer from "../MainContainer";
import UserSection from "./UserSection";
import DataSection from "./DataSection";
import FilesSection from "./FilesSection";
import UserAppealsSection from "./UserAppealsSection";
import DesctiptionSection from "./DescriptionSection";
import MassProblemSection from "./MassProblemSection";
import RelatedTasksSection from "./RelatedTasksSection";
import HardwareSection from "./HardwareSection";
import ClosedLabel from "./ClosedLabel";

import "./style.scss";

/**
 @enum Словарь секций
 */
export const CARD_SECTIONS = {
  user: "user",
  data: "data",
  files: "files",
  userAppeals: "userAppeals",
  description: "description",
  massProblem: "massProblem",
  relatedTasks: "relatedTasks",
  hardware: "hardware",
};

/**
 @constant Отступы колонки
 */
export const GUTTER = [10, 10];

/**
 @enum Переносы колонок в зависимости от разрешения экрана
*/
export const BREAKPOINTS = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
  xl: 12,
  xxl: 12,
};

/**
 @enum Ключи наблюдаемых параметров
 */
export const OBSERVABLE_KEYS = {
  description: "description",
  performer: "performer",
  files: "files",
  massProblemNewIssues: "massProblemNewIssues",
  templateId: "templateId",
  hardware: "hardware",
};

const OBSERVABLE = Object.values(OBSERVABLE_KEYS);

/**
 * Компонент-конструктор для карточки обращения/задачи/массовой проблемы
 * @param {Object} props
 * @param {string[]} props.sections Список разделов карточки
 * @param {React.Component} props.header Хедер компонент карточки
 * @param {Object} props.user Данные пользователя, если есть
 * @param {Object} props.data Данные обращения/задачи/массовой проблемы
 * @param {string} props.className Класс карточки
 * @returns
 */
const Card = (props) => {
  const {
    sections = [],
    className = "",
    header,
    data,
    addUser,
    onUpdate,
    onChange = () => {},
    getHistory = () => {},
  } = props;

  const { closedLabel = {} } = data;

  // State
  const [files, setFiles] = useState([]); // Сюда будем складывать айдишники дозагруженных файлов
  const [performer, setPerformer] = useState(""); // Сюда исходного исполнителя
  const [description, setDescription] = useState(""); // Сюда складываем исходное описание
  const [massProblemNewIssues, setMassProblemNewIssues] = useState([]); // Только для массовых проблем. Список идентификаторов обращений, добавляемых в массовую пробдему
  const [templateId, setTemplateId] = useState(""); // Только для задач
  const [hardware, setHardware] = useState(undefined); // Только для задач
  const [initialValues, setInitialValues] = useState({}); // Значения из стора считаем как исходные

  function getInitialValues(data, observable) {
    const initial = {};

    for (let i = 0; i < observable.length; i++) {
      const key = observable[i];

      switch (key) {
        case OBSERVABLE_KEYS.description: {
          initial[key] = data.description;
          setDescription(data.description);
          break;
        }
        case OBSERVABLE_KEYS.performer: {
          const performerId = data.operator_id
            ? data.operator_id
            : data.operators_group_id;
          initial[key] = performerId;
          setPerformer(performerId);
          break;
        }
        case OBSERVABLE_KEYS.files: {
          initial[key] = [];
          setFiles([]);
          break;
        }
        case OBSERVABLE_KEYS.massProblemNewIssues: {
          initial[key] = [];
          setMassProblemNewIssues([]);
          break;
        }
        case OBSERVABLE_KEYS.templateId: {
          initial[key] = data.template?.id;
          setTemplateId(data.template?.id);
          break;
        }
        case OBSERVABLE_KEYS.hardware: {
          initial[key] = data.hardware;
          setHardware(data.hardware);
          break;
        }
        default: {
          initial[key] = data[key];
          break;
        }
      }
    }

    return initial;
  }

  function getCardHeader() {
    if (React.isValidElement(header)) return header;
  }

  function getCardSection(section) {
    switch (section) {
      case CARD_SECTIONS.user: {
        return (
          <UserSection
            key={CARD_SECTIONS.user}
            {...data.user}
            addUser={addUser}
            onUpdate={onUpdate}
            permissions={data.permissions}
          />
        );
      }
      case CARD_SECTIONS.data: {
        return data.operator_id || data.operators_group_id ? (
          <DataSection
            key={CARD_SECTIONS.data}
            {...data}
            template={{
              ...data.template,
              id: templateId,
            }}
            performer={performer}
            onChangePerformer={setPerformer}
            onChangeTemplate={setTemplateId}
            onUpdate={onUpdate}
            getHistory={getHistory}
          />
        ) : null;
      }
      case CARD_SECTIONS.files:
        return (
          <FilesSection
            key={CARD_SECTIONS.files}
            upload={!data.disabled}
            files={data.files}
            uploadingFiles={files}
            onUpdate={onUpdate}
            onChange={setFiles}
          />
        );
      case CARD_SECTIONS.description: {
        return (
          <DesctiptionSection
            key={CARD_SECTIONS.description}
            description={description}
            disabled={data.disabled}
            onChange={setDescription}
            permissions={data.permissions}
          />
        );
      }
      case CARD_SECTIONS.massProblem:
        return (
          <MassProblemSection
            key={CARD_SECTIONS.massProblem}
            operator={data.operator}
            massProblemIssues={data.openIssues}
            newIssues={massProblemNewIssues}
            onUpdate={onUpdate}
            addNewIssue={setMassProblemNewIssues}
            permissions={data.permissions}
          />
        );
      case CARD_SECTIONS.relatedTasks: {
        return data.tasks && data.tasks.length > 0 ? (
          <RelatedTasksSection
            key={CARD_SECTIONS.relatedTasks}
            tasks={data.tasks}
            onUpdate={onUpdate}
          />
        ) : null;
      }
      case CARD_SECTIONS.userAppeals: {
        return (
          data.user && (
            <UserAppealsSection
              key={CARD_SECTIONS.userAppeals}
              userId={data.user.id}
              permissions={data.permissions}
            />
          )
        );
      }
      case CARD_SECTIONS.hardware:
        return (
          <HardwareSection
            key={CARD_SECTIONS.hardware}
            hardware={hardware}
            onChangeHardware={setHardware}
            permissions={data.permissions?.hardware}
          />
        );
      default:
        return null;
    }
  }

  function drawCardSections(sections) {
    if (!Array.isArray(sections))
      throw new Error("Неверно заданы отображаемые секции");

    return sections.map((section) => getCardSection(section));
  }

  function getChanges(initial, current) {
    if (!initial || !current) return;

    const changes = lodashReduce(
      current,
      (result, value, key) =>
        lodashIsEqual(value, initial[key])
          ? result
          : { ...result, [key]: value },
      {}
    );

    Object.keys(changes).length > 0 ? onChange(changes) : onChange(null);
  }

  useEffect(() => {
    setInitialValues(getInitialValues(data, OBSERVABLE));
  }, [data.updated_at]);

  useEffect(() => {
    const currentValues = {
      description,
      performer,
      files,
      massProblemNewIssues,
      templateId,
      hardware,
    };

    getChanges(initialValues, currentValues);
  }, [
    description,
    performer,
    files,
    massProblemNewIssues,
    templateId,
    hardware,
  ]);

  return (
    <MainContainer
      header={getCardHeader()}
      className={`component_card ${className}`}
      headerClassName={`card__header ${className}`}
      bodyClassName={`card__body ${className}`}
      loading={data.loading}
      error={data.error}
    >
      {closedLabel.closed && <ClosedLabel text={closedLabel.text} />}
      {drawCardSections(sections)}
    </MainContainer>
  );
};

export default Card;
