import React from "react";

import { TYPES } from "./consts";

const operatorsOptGroup = (props) => {
  const { operators, onClick } = props;

  if (!Array.isArray(operators) || operators.length === 0) {
    return (
      <div className="options-list__optgroup">
        Операторы
        <button disabled className="optgroup__option_undefined">
          Операторы не найдены
        </button>
      </div>
    );
  }

  operators.forEach((operator) => (operator.type = TYPES.operator));

  return (
    <div className="options-list__optgroup">
      Операторы
      {operators.map((operator) => (
        <button
          type="button"
          onClick={() =>
            onClick(
              `${operator.last_name} ${operator.first_name}`,
              JSON.stringify(operator)
            )
          }
          key={operator.id}
          className="optgroup__option"
        >
          <p className="option__title">{`${operator.last_name} ${operator.first_name}`}</p>
          {operator.operators_group && operator.operators_group.title ? (
            <p className="option__subtitle">
              Группа: {operator.operators_group.title}
            </p>
          ) : null}
        </button>
      ))}
    </div>
  );
};

export default operatorsOptGroup;
