import React from "react";
import { Row, Col, Button } from "antd";
import moment from "moment";
import "moment/locale/ru";

import MainContainer from "../../components/MainContainer";
import EditableCardField, {
  EDITABLE_FIELD_TYPES,
} from "../../components/EditableCardField";
import { ADMIN_LDAP_GROUP } from "../../consts";

const GUTTER = [10, 10];
const MOMENT_FORMAT = "DD MMMM YYYY, HH:mm";
const BREAKPOINTS = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
  xl: 8,
  xxl: 8,
};

const Header = () => (
  <>
    <h2 className="header__title">LDAP-свойство</h2>
  </>
);

const Layout = (props) => {
  const {
    uiPermissions,
    ldapProp,
    error,
    loading,
    switches,
    roles,
    groups,
    statuses,
    switchEditableField,
    changeOperatorsStatus,
    changeOperatorsGroup,
    changeOperatorsRoles,
    deleteLdapProp,
  } = props;

  moment.locale("ru");

  return (
    <MainContainer
      error={error}
      loading={loading}
      header={<Header />}
      className="page_ldap-prop-card"
      headerClassName="ldap-prop-card__header"
      bodyClassName="ldap-prop-card__body"
    >
      <Row gutter={GUTTER}>
        <Col {...BREAKPOINTS}>
          <div className="ldap-prop-card__card-field">
            <label className="card-field__title">Создано:</label>
            {moment(ldapProp.created_at).format(MOMENT_FORMAT)}
          </div>
        </Col>
        <Col {...BREAKPOINTS}>
          <div className="ldap-prop-card__card-field">
            <label className="card-field__title">Обновлено:</label>
            {moment(ldapProp.updated_at).format(MOMENT_FORMAT)}
          </div>
        </Col>
        <Col {...BREAKPOINTS}>
          {ldapProp.deleted_at && (
            <div className="ldap-prop-card__card-field">
              <label className="card-field__title">Удалено:</label>
              {moment(ldapProp.deleted_at).format(MOMENT_FORMAT)}
            </div>
          )}
        </Col>
      </Row>
      <Row gutter={GUTTER}>
        <Col {...BREAKPOINTS}>
          <div className="ldap-prop-card__card-field">
            <label className="card-field__title">LDAP-группа:</label>
            {ldapProp.ldap_group}
          </div>
        </Col>
        <Col {...BREAKPOINTS}>
          <div className="ldap-prop-card__card-field">
            <label className="card-field__title">Статус операторов:</label>
            <EditableCardField
              name="operatorStatus"
              type={EDITABLE_FIELD_TYPES.select}
              value={ldapProp.operators_status_id}
              disabled={!uiPermissions.status}
              params={{ options: statuses }}
              isEdit={switches.operatorStatus}
              editField={switchEditableField}
              saveChanges={(statusId) =>
                changeOperatorsStatus(ldapProp.ldap_group, statusId)
              }
            />
          </div>
        </Col>
        <Col {...BREAKPOINTS}>
          <div className="ldap-prop-card__card-field">
            <label className="card-field__title">Группа операторов:</label>
            <EditableCardField
              name="operatorsGroup"
              type={EDITABLE_FIELD_TYPES.select}
              value={ldapProp.operators_group_id}
              disabled={!uiPermissions.group}
              params={{ options: groups }}
              isEdit={switches.operatorsGroup}
              editField={switchEditableField}
              saveChanges={(groupId) =>
                changeOperatorsGroup(ldapProp.ldap_group, groupId)
              }
            />
          </div>
        </Col>
      </Row>
      <Row gutter={GUTTER}>
        <Col {...BREAKPOINTS}>
          <div className="ldap-prop-card__card-field">
            <label className="card-field__title">Роли:</label>
            <EditableCardField
              name="roles"
              type={EDITABLE_FIELD_TYPES.checkbox}
              value={ldapProp.roles}
              disabled={!uiPermissions.roles}
              params={{ options: roles }}
              isEdit={switches.roles}
              editField={switchEditableField}
              saveChanges={(roles) =>
                changeOperatorsRoles(ldapProp.ldap_group, roles)
              }
            />
          </div>
        </Col>
      </Row>
      {(ldapProp.ldap_group !== ADMIN_LDAP_GROUP) && !ldapProp.deleted_at && uiPermissions.delete && (
        <Button
          danger
          type="primary"
          children="Удалить свойство"
          className="card-field__button_delete"
          onClick={() => deleteLdapProp(ldapProp.ldap_group)}
        />
      )}
    </MainContainer>
  );
};

export default Layout;
