import React from "react";
import { Row, Col, Typography, Form, Button, Input, Select } from "antd";

const { Title } = Typography;

const TemplateCreationForm = ({ onFinish, submitting, operatorsGroups }) => {
  return (
    <Row className="component_template-creation-form">
      <Col span={24}>
        <Title level={5}>Создание шаблона</Title>
      </Col>
      <Col span={24}>
        <Form layout="vertical" onFinish={onFinish}>
          <Form.Item
            label="Название"
            name="title"
            rules={[
              {
                required: true,
                message: "Обязательное поле",
              },
            ]}
          >
            <Input autoFocus />
          </Form.Item>

          <Form.Item
            label="Текст шаблона"
            name="text"
            rules={[
              {
                required: true,
                message: "Обязательное поле",
              },
            ]}
          >
            <Input.TextArea autoSize={{ minRows: 4, maxRows: 20 }} />
          </Form.Item>

          <Form.Item
            label="Шаблон для групп операторов"
            name="operator_group_ids"
          >
            <Select
              allowClear
              showArrow
              mode="tags"
              options={operatorsGroups}
            />
          </Form.Item>

          <Form.Item>
            <Button
              loading={submitting}
              type="primary"
              htmlType="submit"
              children="Создать"
            />
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
};

export default TemplateCreationForm;
