import React from "react";
import { Image as ImageAntd } from "antd";

import ImageFallback from "../ImageFallback";
import Spinner from "../Spinner";

import "./style.scss";

const Image = (props) => {
  const { loading, src, alt, preview = true } = props;

  function drawContent() {
    if (loading) return <Spinner />;
    if (!src) return <ImageFallback />;

    return (
      <ImageAntd className="image__ant-image" preview={preview} src={src} alt={alt} />
    );
  }

  return <div className="component_image">{drawContent()}</div>;
};

export default Image;
