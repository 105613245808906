import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import { useLocation } from "react-router";

import Card, { CARD_SECTIONS, OBSERVABLE_KEYS } from "../../components/Card";
import Header from "./header";
import Tabs, { TABS } from "./tabs";
import AddToMassProblem from "../../components/AddToMassProblem";

import ISSUES_API from "../../api/appeals";
import OPERATORS_API from "../../api/operators";
import ISSUE_ACTION_TYPES from "../../actions/appeal/actionType";
import errorHandling from "../../services/errors/errorHandling";
import {
  RESET_ERROR,
  ISSUE_GETTING_ERROR,
  ISSUE_DESCRIPTION_EDITING_ERROR,
  ISSUE_OPERATOR_CHANGING_ERROR,
  ISSUE_UPLOAD_FILES_ERROR,
  ISSUE_CLOSING_ERROR,
} from "../../actions/errors/actionTypes";
import { COMPONENTS } from "../../services/tabs";
import { TYPES } from "../../components/Search_new/consts";
import { COMPONENT_URL } from "../../consts";
import checkRoute from "../../utils/checkRoute";
import useRBAC, { UI_PERMISSIONS } from "../../services/rbac";

import "./style.scss";

const IssueCard = (props) => {
  const { componentProps, setComponentProps, tabUuid, removeTab } = props;

  const dispatch = useDispatch();
  const location = useLocation();

  const issueId = location.state ? location.state.issueId : getIssueIdFromUrl();
  const defaultActiveTab =
    location.state && location.state.defaultActiveTab
      ? location.state.defaultActiveTab
      : TABS.messages;

  // Store
  const userId = useSelector((store) => store.user.id);
  const error = useSelector((store) => store.error);
  const { newMessages, newNotifications } = useSelector((store) => store.notifications);

  // State
  const [changes, setChanges] = useState(null);
  const [loading, setLoading] = useState(false);
  const [operators, setOperators] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [addToMassProblemVisible, setAddToMassProblemVisible] = useState(false);

  const { checkPermissionToRenderUI } = useRBAC();

  function setDisabledState(issue) {
    setDisabled(issue ? Boolean(issue.done_at || issue.deleted_at) : true);
  }

  function getIssueIdFromUrl() {
    const id = window.location.pathname.replace("/", "").split("/")[1];

    return checkRoute(COMPONENT_URL[COMPONENTS.ISSUE_CARD], window.location.pathname)
      ? id
      : undefined;
  }

  async function getIssue() {
    await setLoading(true);

    await ISSUES_API.getAppealData(issueId)
      .then((resp) => {
        setDisabledState(resp.data);
        setComponentProps({ ...resp.data });
      })
      .catch((err) => {
        if (err.response) {
          err.response.data.message = `При получении данных обращения произошла ошибка\n${err.response.data.message}`;
        }
        setDisabledState({});
        dispatch(errorHandling(err, ISSUE_GETTING_ERROR, false));
      });

    await setLoading(false);
  }

  async function getOperators() {
    await setLoading(true);

    await OPERATORS_API.getOperatorsList()
      .then((resp) => {
        const operators = resp.data.map((operator) => ({
          label: `${operator.last_name} ${operator.first_name}`,
          value: operator.id,
        }));
        setOperators(operators);
      })
      .catch((err) => {
        if (err.response) {
          err.response.data.message = `При получении списка операторов произошла ошибка\n${err.response.data.message}`;
        }
        dispatch(errorHandling(err, ISSUE_GETTING_ERROR, false));
      });

    await setLoading(false);
  }

  async function getIssueRelatedTasks() {
    await setLoading(true);

    await ISSUES_API.getRelatedTasks(issueId)
      .then((resp) =>
        setComponentProps({
          relatedTasks: resp.data,
        })
      )
      .catch((err) => {
        if (err.response) {
          err.response.data.message = `При получении связанных задач произошла ошибка\n${err.response.data.message}`;
        }
        dispatch(errorHandling(err, ISSUE_GETTING_ERROR, false));
      });

    await setLoading(false);
  }

  async function getIssueChanges() {
    await ISSUES_API.getAppealChanges(issueId)
      .then((resp) => setComponentProps({ changes: resp.data }))
      .catch((err) => {
        if (err.response) {
          err.response.data.message = `При получении истории изменений обращения произошла ошибка\n${err.response.data.message}`;
        }
        dispatch(errorHandling(err, ISSUE_GETTING_ERROR, false));
      });
  }

  async function editIssueDescription(issueId, description) {
    await setLoading(true);

    return await ISSUES_API.setAppealDescription(issueId, description)
      .finally(() => setLoading(false))
      .catch((err) => {
        dispatch(errorHandling(err, ISSUE_DESCRIPTION_EDITING_ERROR));

        return Promise.reject(err);
      });
  }

  async function changeIssuePerformer(issueId, operatorId) {
    await setLoading(true);

    return await ISSUES_API.setAppealOperator(issueId, operatorId)
      .finally(() => setLoading(false))
      .catch((err) => {
        dispatch(errorHandling(err, ISSUE_OPERATOR_CHANGING_ERROR));

        return Promise.reject(err);
      });
  }

  async function uploadIssueFiles(issueId, filesId) {
    await setLoading(true);

    return await ISSUES_API.uploadAppealFiles(issueId, filesId)
      .finally(() => setLoading(false))
      .catch((err) => {
        dispatch(errorHandling(err, ISSUE_UPLOAD_FILES_ERROR));

        return Promise.reject(false);
      });
  }

  async function handleSaveAndCloseClick(issueId, changes) {
    try {
      await handleSaveClick(issueId, changes);
      removeTab();
    } catch (err) {
      console.error(err);
      message.error("Не удалось сохранить изменения");
    }
  }

  async function handleSaveClick(issueId, changes) {
    try {
      for (let key in changes) {
        if (key === OBSERVABLE_KEYS.description)
          await editIssueDescription(issueId, changes[key]);
        if (key === OBSERVABLE_KEYS.performer)
          await changeIssuePerformer(issueId, changes[key]);
        if (key === OBSERVABLE_KEYS.files) {
          const filesId = changes[key].map((file) => file.id);

          await uploadIssueFiles(issueId, filesId);
        }
      }

      await getIssueCardData();
      message.success("Сохранено");
    } catch {
      setLoading(false);
    }
  }

  async function handleDoneClick() {
    await ISSUES_API.closeAppeal(issueId)
      .then(() => {
        dispatch({ type: ISSUE_ACTION_TYPES.APPEAL_CLOSE });
        getIssueCardData();
      })
      .catch((err) => dispatch(errorHandling(err, ISSUE_CLOSING_ERROR)));
  }

  async function getIssueCardData() {
    try {
      await getIssue();
      await getOperators();
      // await getIssueChanges();
      await getIssueRelatedTasks();
    } catch {
      setLoading(false);
    }
  }

  function getError(error) {
    return error.type === ISSUE_GETTING_ERROR ? error : null;
  }

  function getIssueHeader(error) {
    if (getError(error) !== null) return null;

    return (
      <Header
        issue={componentProps || {}}
        loading={loading}
        closed={disabled}
        hasChanges={changes !== null}
        onSave={() => handleSaveClick(componentProps.id, changes)}
        onDone={handleDoneClick}
        onSaveAndClose={() =>
          handleSaveAndCloseClick(componentProps.id, changes, tabUuid)
        }
        setAddToMassProblemVisible={setAddToMassProblemVisible}
      />
    );
  }

  function checkClosed(issue) {
    if (!issue) {
      return undefined;
    } else {
      return Boolean(issue.done_at || issue.deleted_at);
    }
  }

  function resetError() {
    dispatch({ type: RESET_ERROR });
  }

  async function addFileFromChat(issueId, fileId) {
    try {
      await uploadIssueFiles(issueId, [fileId]);
      await getIssueCardData();
      message.success("Файл добавлен");
    } catch (err) {
      message.error("Не удалось добавить файл");
    }
  }

  useEffect(() => {
    resetError();
    getIssueCardData();
  }, [tabUuid]);

  return (
    <div className="page_issue-card">
      <Card
        sections={[
          CARD_SECTIONS.user,
          CARD_SECTIONS.data,
          CARD_SECTIONS.description,
          CARD_SECTIONS.files,
          CARD_SECTIONS.relatedTasks,
          CARD_SECTIONS.userAppeals,
        ]}
        data={{
          ...componentProps,
          disabled,
          loading,
          error: getError(error),
          operators,
          closedLabel: {
            closed: checkClosed(componentProps),
            text: "Обращение закрыто",
          },
          permissions: {
            changePerformerAllowed: checkPermissionToRenderUI(
              UI_PERMISSIONS["ui:issues:card:performer:edit"]
            ),
            changingWorkstationNumberAllowed: checkPermissionToRenderUI(
              UI_PERMISSIONS["ui:issues:card:user:workstation-number:edit"]
            ),
            changingWorkplaceLocationAllowed: checkPermissionToRenderUI(
              UI_PERMISSIONS["ui:issues:card:user:workplace-location:edit"]
            ),
            displayIssuesHistory: checkPermissionToRenderUI(
              UI_PERMISSIONS["ui:issues:card:user-issues"]
            ),
            displayTasksHistory: checkPermissionToRenderUI(
              UI_PERMISSIONS["ui:issues:card:user-tasks"]
            ),
            descriptionEditingAllowed: checkPermissionToRenderUI(
              UI_PERMISSIONS["ui:issues:card:description:edit"]
            ),
          },
        }}
        className={`page__card_issue ${
          checkClosed(componentProps) ? "issue_closed" : ""
        }`}
        header={getIssueHeader(error)}
        onUpdate={getIssue}
        onChange={setChanges}
        getHistory={getIssueChanges}
      />
      <Tabs
        userId={componentProps?.user ? componentProps.user.id : null}
        operatorId={userId}
        defaultActiveTab={defaultActiveTab}
        disabled={disabled}
        issueId={issueId}
        newMessages={newMessages}
        newNotifications={newNotifications}
        addFileFromChat={
          disabled ? undefined : (fileId) => addFileFromChat(issueId, fileId)
        }
      />
      <AddToMassProblem
        filter={
          componentProps?.user ? { id: componentProps.user.id, type: TYPES.user } : null
        }
        defaultSelectedIssues={[issueId]}
        visible={addToMassProblemVisible}
        onClose={() => setAddToMassProblemVisible(false)}
      />
    </div>
  );
};

export { TABS } from "./tabs";
export default IssueCard;
