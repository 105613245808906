import { useCallback } from "react";
import { useDispatch } from "react-redux";
import { message } from "antd";

import SERVICE_ACCOUNT_API from "../api/serviceAccount";
import ERROR_ACTIONS from "../actions/errors/errors";
import { TYPES } from "../components/Search_new/consts";

export const PERMORMER_FIELD_NAME = "performer";
export const MAX_NAME_LENGHT = 64;
export const SELECT_PROPS = {
  value: [],
  allowClear: true,
  showSearch: true,
  placeholder: "Поиск",
  optionFilterProp: "children",
};

const useServiceAccount = () => {
  const dispatch = useDispatch();

  const getServiceAccounts = useCallback(async () => {
    try {
      const response = await SERVICE_ACCOUNT_API.getServiceAccounts();
      return response.data;
    } catch (err) {
      console.error(err);
      dispatch(
        ERROR_ACTIONS.addError({
          title: "Не удалось получить список сервисных аккаунтов",
          message: err.response.data?.message,
          status: err.response.status,
        })
      );

      return [];
    }
  }, [dispatch]);

  const getServiceAccount = useCallback(
    async (accountId) => {
      try {
        const response = await SERVICE_ACCOUNT_API.getServiceAccount(accountId);
        return response.data;
      } catch (err) {
        console.error(err);
        dispatch(
          ERROR_ACTIONS.addError({
            title: "Не удалось получить сервисный аккаунт",
            message: err.response.data?.message,
            status: err.response.status,
          })
        );
        return undefined;
      }
    },
    [dispatch]
  );

  const createServiceAccount = useCallback(
    async (serviceAccount) => {
      try {
        const response = await SERVICE_ACCOUNT_API.createServiceAccount(
          serviceAccount
        );
        message.success("Сервисный аккаунт создан");

        return Promise.resolve(response.data);
      } catch (err) {
        console.error(err);
        dispatch(
          ERROR_ACTIONS.addError({
            title: "Не удалось создать сервисный аккаунт",
            message: err.response.data?.message,
            status: err.response.status,
          })
        );

        return Promise.reject(err);
      }
    },
    [dispatch]
  );

  const updateServiceAccount = useCallback(
    async (accountId, serviceAccount) => {
      try {
        const response = await SERVICE_ACCOUNT_API.updateServiceAccount(
          accountId,
          serviceAccount
        );
        message.success("Аккаунт изменен");

        return Promise.resolve(response);
      } catch (err) {
        console.error(err);
        dispatch(
          ERROR_ACTIONS.addError({
            title: "Не удалось изменить сервисный аккаунт",
            message: err.response.data?.message,
            status: err.response.status,
          })
        );

        return Promise.reject({});
      }
    },
    [dispatch]
  );

  const updateSecretServiceAccount = useCallback(
    async (accountId, serviceAccount) => {
      try {
        const response = await SERVICE_ACCOUNT_API.updateServiceAccount(
          accountId,
          serviceAccount
        );
        message.success("Обновлено");

        return Promise.resolve(response);
      } catch (err) {
        console.error(err);
        dispatch(
          ERROR_ACTIONS.addError({
            title: "Не удалось обновить Secret сервисного аккаунта",
            message: err.response.data?.message,
            status: err.response.status,
          })
        );

        return Promise.reject({});
      }
    },
    [dispatch]
  );

  const formattingFormValues = useCallback((form) => {
    try {
      const { [PERMORMER_FIELD_NAME]: taskPerformer, ...rest } = form;

      const formatted = { ...rest };

      const _performer = JSON.parse(form[PERMORMER_FIELD_NAME]);

      // Подставляем нужный ключ в зависимости от того, какой был выбран оператор
      if (_performer?.type === TYPES.operator) {
        formatted.to_operator_id = _performer.value;
      } else if (_performer?.type === TYPES.group) {
        formatted.to_operators_group_id = _performer.value;
      }

      return formatted;
    } catch (err) {
      console.error(err);
      return form;
    }
  }, []);

  const serviceAccountNameValidation = useCallback((name = "") => {
    const latinCharRegex = /[a-zA-Z]/;

    if (!latinCharRegex.test(name[0])) {
      return Promise.reject(
        "Может начинаться только с буквы латинского алфавита"
      );
    }
    if (!latinCharRegex.test(name[name.length - 1])) {
      return Promise.reject(
        "Может заканчиваться только на букву латинского алфавита"
      );
    }

    if (!/^[a-z0-9_-]*$/gim.test(name)) {
      return Promise.reject(
        "Допустимо использование только латинских букв, цифр, тире и нижнего подчеркивания"
      );
    }

    return Promise.resolve("");
  }, []);

  return {
    getServiceAccount,
    getServiceAccounts,
    formattingFormValues,
    createServiceAccount,
    updateServiceAccount,
    updateSecretServiceAccount,
    serviceAccountNameValidation,
  };
};

export default useServiceAccount;
