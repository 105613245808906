import React from "react";
import { Button } from "antd";
import { WarningOutlined, CloseOutlined } from "@ant-design/icons";

export default function SendingError(props) {
  const { message = null, resetError } = props;
  const shown = message !== null ? "shown" : "";

  if (message && typeof message !== "string") {
    console.error(message);
    return null;
  }

  function drawResetButton() {
    if (typeof resetError === "function") {
      return (
        <Button
          className="sending-error-message__button sending-error-message__button_reset-error"
          type="text"
          shape="circle"
          size="small"
          onClick={resetError}
          icon={<CloseOutlined />}
        />
      );
    } else {
      return null;
    }
  }

  return (
    <div className={`input-wrapper__sending-error-message ${shown}`}>
      <WarningOutlined className="sending-error-message__icon" />
      При отправке сообщения произошла ошибка. {message}
      {drawResetButton()}
    </div>
  );
}
