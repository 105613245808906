/**
 * Форматирует
 * @param {string} string Строка в формате snake case
 * @returns {string} Строка в формате camel case
 */
function snakeCaseToCamelCase(string) {
  const wordsArray = string.split("_");

  if (wordsArray.length === 1) {
    return wordsArray.join("");
  }

  return wordsArray
    .map((word, index) =>
      index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
    )
    .join("");
}

export default snakeCaseToCamelCase;
