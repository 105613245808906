import React, { useCallback } from "react";
import { List, Row, Col, Input, Button, Comment } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";

import normalizeDate, { DATE_TIME } from "../../utils/normalizeDate";

const BUTTON_SIZE = "small";

const ListHeader = ({
  isEditing = false,
  loading = false,
  value,
  onChangeValue,
  onOk = () => {},
  toggleEditing = () => {},
}) => {
  return (
    <Row>
      <Col flex={"auto"}>
        <Input.Search
          disabled={loading}
          value={value}
          onChange={(event) => onChangeValue(event.target.value)}
          onPressEnter={onOk}
          onSearch={onOk}
          style={{ minWidth: 320 }}
          placeholder="Внести изменение"
          enterButton={<Button type="primary" children="Добавить" />}
        />
      </Col>
      <Col>
        {isEditing && (
          <Button
            type="link"
            children="Отмена"
            onClick={() => toggleEditing()}
          />
        )}
      </Col>
    </Row>
  );
};

const ListItem = (props) => {
  const {
    id,
    text,
    operator,
    created_at,
    updated_at,
    toggleEditing = () => {},
    onRemoveChange = () => {},
    permissions = {},
  } = props;
  const content =
    created_at && updated_at && created_at === updated_at
      ? text
      : `${text} (ред)`;
  const actionButtons = [];

  if (permissions.edit) {
    actionButtons.push(
      <Button
        type="text"
        size={BUTTON_SIZE}
        icon={<EditOutlined />}
        onClick={() => toggleEditing(id, text)}
      />
    );
  }
  if (permissions.remove) {
    actionButtons.push(
      <Button
        danger
        type="text"
        size={BUTTON_SIZE}
        icon={<DeleteOutlined />}
        onClick={() => onRemoveChange(id)}
      />
    );
  }

  return (
    <Comment
      key={id}
      author={`${operator.last_name} ${operator.first_name}`}
      content={content}
      datetime={normalizeDate(created_at, DATE_TIME)}
      actions={actionButtons}
    />
  );
};

const HardwareChanges = ({
  text = "",
  isEditing = false,
  onChangeText = () => {},
  toggleEditing = () => {},
  hardware = {},
  loading = false,
  onOk = () => {},
  onRemoveChange = () => {},
  isIdentifierEditing = false,
  toggleIdentifierEditing = () => {},
  onIdentifierEdit = () => {},
  permissions = {},
}) => {
  const { hardwareChanges = [] } = hardware;
  const {
    hardwareChangeCreate = false,
    hardwareChangeEdit = false,
    hardwareChangeRemove = false,
    identifierEdit = false,
  } = permissions;

  const drawListHeader = useCallback(() => {
    const header = (
      <ListHeader
        isEditing={isEditing}
        disabled={loading}
        value={text}
        onChangeValue={onChangeText}
        onOk={onOk}
        toggleEditing={toggleEditing}
      />
    );

    if (hardwareChangeCreate) return header;
    if (hardwareChangeEdit && isEditing) return header;

    return null;
  }, [
    hardwareChangeCreate,
    hardwareChangeEdit,
    isEditing,
    loading,
    text,
    onChangeText,
    onOk,
    toggleEditing,
  ]);

  const drawIdentifierEditing = (identifier) => {
    if (!identifierEdit) return null;

    if (isIdentifierEditing) {
      return (
        <Row gutter={[0, 20]}>
          <Col>
            <Input.Search
              placeholder="Введите идентификатор"
              defaultValue={identifier}
              onSearch={onIdentifierEdit}
              enterButton={<Button type="primary" children="Сохранить" />}
            />
          </Col>
          <Col>
            <Button
              type="link"
              children="Отмена"
              onClick={() => toggleIdentifierEditing(false)}
            />
          </Col>
        </Row>
      );
    } else {
      return (
        <Row gutter={[20, 20]}>
          <Col>
            <Button
              children="Редактровать идентификатор"
              icon={<EditOutlined />}
              onClick={() => toggleIdentifierEditing(true)}
            />
          </Col>
        </Row>
      );
    }
  };

  return (
    <>
      {drawIdentifierEditing(hardware.identifier)}
      {drawListHeader()}
      <List
        style={{ height: "100%", overflowY: "auto" }}
        loading={loading}
        dataSource={hardwareChanges}
        renderItem={(_item) => (
          <ListItem
            {..._item}
            onRemoveChange={onRemoveChange}
            toggleEditing={toggleEditing}
            permissions={{
              remove: hardwareChangeRemove,
              edit: hardwareChangeEdit,
            }}
          />
        )}
      />
    </>
  );
};

export default HardwareChanges;
