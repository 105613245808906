import api from "./api";
import {
  OPERATOR_LDAP_PROPS,
  STATUSES,
  OPERATOR_API_URLS,
  OPERATORS_GROUP_API_URLS,
} from "../consts";

export async function getOperatorData(opID) {
  const url = OPERATOR_API_URLS.getOperator(opID);
  return await api.protectedGet(url);
}

export async function getOperatorsList() {
  const url = OPERATOR_API_URLS.getOperators();
  return await api.protectedGet(url);
}

export async function getOperatorsGroupData(groupID) {
  const url = OPERATORS_GROUP_API_URLS.getOperatorsGroup(groupID);
  return await api.protectedGet(url);
}

export async function getGroupOperators(groupID) {
  const url = OPERATORS_GROUP_API_URLS.getOperatorsGroup(groupID);
  return await api.protectedGet(url);
}

export async function putOperatorsGroupTitle(groupId, title) {
  const url = OPERATORS_GROUP_API_URLS.putOperatorsGroup(groupId);
  return await api.protectedPut(url, { title });
}

export async function getOperatorsGroupsList() {
  const url = OPERATORS_GROUP_API_URLS.getGroups();
  return await api.protectedGet(url);
}

export async function deleteOperatorsGroup(groupId) {
  const url = OPERATORS_GROUP_API_URLS.deleteOperatorsGroup(groupId);
  return await api.protectedDelete(url);
}

export async function changeOperatorGroup(opID, operators_group_id) {
  const url = OPERATOR_API_URLS.setOperatorGroup(opID);
  return await api.protectedPut(url, { operators_group_id });
}

export async function createOperatorsGroup(title) {
  const url = OPERATORS_GROUP_API_URLS.createOperatorsGroup();
  return await api.protectedPost(url, { title });
}

export async function setOperatorRoles(operatorId, roles) {
  const url = OPERATOR_API_URLS.setOperatorRoles(operatorId);
  return await api.protectedPost(url, roles);
}

export async function deleteOperatorRoles(operatorId, roles) {
  const url = OPERATOR_API_URLS.deleteOperatorRoles(operatorId);
  return await api.protectedDelete(url, roles);
}

export async function getOperatorPermissions(operatorId) {
  const url = OPERATOR_API_URLS.getOperatorPermissions(operatorId);
  return await api.protectedGet(url);
}

export async function getOperatorLdapProps() {
  return await api.protectedGet(
    `${OPERATOR_LDAP_PROPS}?expand=operators_group,operators_status`
  );
}

export async function postOperatorLdapProps(ldapProp) {
  return await api.protectedPost(OPERATOR_LDAP_PROPS, ldapProp);
}

export async function patchOperatorLdapProps(ldapGroup, payload) {
  return await api.protectedPatch(`${OPERATOR_LDAP_PROPS}/${ldapGroup}`, payload);
}

export async function deleteOperatorLdapProps(ldapGroup) {
  return await api.protectedDelete(`${OPERATOR_LDAP_PROPS}/${ldapGroup}`);
}

export async function getOperatorStatuses() {
  return await api.protectedGet(STATUSES);
}

/**
 * Получить сохраненные кастомные фильтры оператора
 * @param {string} realm - Область применения фильтров
 */
export const getCustomFilters = async (realm = "") => {
  const url = OPERATOR_API_URLS.getCustomFilters(realm);
  return await api.protectedGet(url);
};

/**
 * Создать новый кастомный фильтр
 * @param {string} realm - Область применения фильтра
 * @param {string} name - Название фильтра
 * @param {string} filter - Параметры фильтра
 */
export const postCustomFilter = async (realm, name, filter) => {
  const url = OPERATOR_API_URLS.postCustomFilter();
  return await api.protectedPost(url, { realm, name, filter });
};

/**
 * Редактировать кастомный фильтр
 * @param {string} filterId - id фильтра
 * @param {string} name - Название фильтра
 * @param {string} filter - Параметры фильтра
 */
export const putCustomFilter = async (filterId, name, filter) => {
  const url = OPERATOR_API_URLS.putCustomFilter(filterId);
  return await api.protectedPut(url, { name, filter });
};

/**
 * Удалить кастомный фильтр
 * @param {string} filterId - id фильтра
 */
export const deleteCustomFilter = async (filterId) => {
  const url = OPERATOR_API_URLS.deleteCustomFilter(filterId);
  return await api.protectedDelete(url);
};

export const getNotifications = async (limit, offset) => {
  const url = OPERATOR_API_URLS.getNotifications(limit, offset);
  return await api.protectedGet(url);
};

export const readNotifications = async (notificationIds) => {
  const url = OPERATOR_API_URLS.readNotifications();
  return await api.protectedPost(url, { notification_ids: notificationIds });
};

export const clearAllNotifications = async () => {
  const url = OPERATOR_API_URLS.clearAllNotifications();
  return await api.protectedPost(url);
};

const OPERATORS_API = {
  getOperatorData,
  getOperatorsList,
  getOperatorsGroupData,
  putOperatorsGroupTitle,
  getGroupOperators,
  getOperatorsGroupsList,
  deleteOperatorsGroup,
  changeOperatorGroup,
  createOperatorsGroup,
  setOperatorRoles,
  deleteOperatorRoles,
  getOperatorPermissions,
  getOperatorLdapProps,
  postOperatorLdapProps,
  patchOperatorLdapProps,
  deleteOperatorLdapProps,
  getOperatorStatuses,
  getCustomFilters,
  postCustomFilter,
  putCustomFilter,
  deleteCustomFilter,
  getNotifications,
  readNotifications,
  clearAllNotifications,
};

export default OPERATORS_API;
