import React from "react";
import { Space, Button } from "antd";
import { PaperClipOutlined } from "@ant-design/icons";

import { getUserData } from "../../../api/users";
import { getMessage } from "../../../api/chatHTTP";

export default async (data) => {
  const { user_id, message_id, onOpenTab } = data;
  let userName;
  let message;

  await getUserData(user_id)
    .then(({ data }) => {
      const { current_pp_name, last_name, first_name } = data;
      const full_name = `${last_name} ${first_name}`.trim();

      userName = current_pp_name ? `${current_pp_name} (${full_name})` : full_name;
    })
    .catch(() => (userName = "Пользователь не найден"));

  await getMessage(message_id)
    .then(({ data }) => {
      if (data.message) {
        message = data.message;
        return;
      }
      if (data.file) {
        message = (
          <Space size="small">
            <PaperClipOutlined />
            Вложенный файл
          </Space>
        );
        return;
      }
    })
    .catch(() => (message = "Сообщение не найдено"));

  return {
    message: userName,
    description: message,
    btn: (
      <Button type="primary" onClick={onOpenTab}>
        Перейти к сообщениям
      </Button>
    ),
  };
};
