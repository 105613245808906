import React from "react";
import { Button, Row, Col } from "antd";
import { EditOutlined, DeleteOutlined } from "@ant-design/icons";

import Message from "../../../components/Message";

import "./style.scss";

const BUTTON_PROPS = {
  type: "text",
  // size: "large",
};

const UserMessage = ({ message = {}, onDelete, onEdit }) => {
  const { messageFrom, created_at } = message;

  return (
    <Row gutter={[8, 8]} className="add-user-message__user-message" align={"middle"}>
      <Col flex="1">
        <Message
          text={message.message}
          authorName={`${messageFrom.last_name} ${messageFrom.first_name}`}
          createdAt={created_at}
          messageFrom={"operator_id" in message ? undefined : "user"}
        />
      </Col>
      <Col flex="40px">
        <Button {...BUTTON_PROPS} icon={<EditOutlined />} onClick={onEdit} />
      </Col>
      <Col flex="40px">
        <Button {...BUTTON_PROPS} icon={<DeleteOutlined />} onClick={onDelete} danger />
      </Col>
    </Row>
  );
};

export default UserMessage;
