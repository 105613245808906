import React from "react";
import Table from "../Table";
import { CheckCircleOutlined, ClockCircleOutlined } from "@ant-design/icons";

import normalizeDate, { DATE_TIME } from "../../utils/normalizeDate";
import { useTabs, COMPONENTS } from "../../services/tabs";

const RelatedTasksSection = (props) => {
  const { tasks } = props;

  // Hooks
  const { clickHandler } = useTabs();

  function getRowIcon(doneAt) {
    return doneAt ? (
      <CheckCircleOutlined className="row__check-icon" />
    ) : (
      <ClockCircleOutlined className="row__clock-icon" />
    );
  }

  const COLUMNS = [
    {
      dataIndex: "done_at",
      render: (text) => getRowIcon(text),
      width: 40,
      align: "center",
    },
    {
      title: "Номер",
      dataIndex: "human_readable_id",
      width: 80,
      align: "center",
    },
    {
      title: "Создано",
      dataIndex: "created_at",
      render: (text) => normalizeDate(text, DATE_TIME),
      width: 120,
    },
    {
      title: "Описание",
      dataIndex: "description",
      ellipsis: true,
    },
    {
      title: "Исполнитель",
      // dataIndex: ["operator", "last_name"],
      render: (text, row) => {
        return row.operator
          ? `${row.operator.last_name} ${row.operator.first_name}`
          : row.operators_group.title;
      },
    },
  ];

  function getRowClassName(row, index) {
    return row.done_at ? "table__row_green" : "table__row_yellow";
  }

  function getTableTitle(tasks) {
    const all = tasks.length;
    const done = tasks.filter((t) => t.done_at).length;
    const inProcess = all - done;

    return (
      <p className="table__title">
        <span>Всего: {all}</span>
        <span>
          <CheckCircleOutlined />
          Выполнено: {done}
        </span>
        <span>
          <ClockCircleOutlined />В процессе: {inProcess}
        </span>
      </p>
    );
  }

  return (
    <section className="card__section card__section_related-tasks">
      <h3 className="section__header">Связанные задачи</h3>
      <Table
        data={tasks}
        columns={COLUMNS}
        onRowClick={(e, task) => {
          e.preventDefault();
          e.stopPropagation();

          clickHandler({
            component: COMPONENTS.TASK_CARD,
            params: { taskId: task.id },
            locationState: { taskId: task.id },
          });
        }}
        rowClassName={getRowClassName}
        title={() => getTableTitle(tasks)}
      />
    </section>
  );
};

export default RelatedTasksSection;
