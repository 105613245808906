import React, { useCallback } from "react";
import { Tabs as TabsAntd } from "antd";

import CommentForm from "../../components/comments";

import useRBAC, { UI_PERMISSIONS } from "../../services/rbac";
import MASS_PROBLEM_API from "../../api/massProblem";

/**
 @enum Перечисление вкладок
 */
const TABS = {
  comments: "comments",
};

/**
   @enum Названия вкладок
   */
const TABS_NAME = {
  [TABS.comments]: "Комментарии",
};

const Tabs = (props) => {
  const { massProblemId, operatorId, newNotifications } = props;

  // Hooks
  const { checkPermissionToRenderUI } = useRBAC();

  const getComments = useCallback(
    () => MASS_PROBLEM_API.getMassProblemComments(massProblemId),
    [massProblemId]
  );
  const postComment = useCallback(
    (comment) =>
      MASS_PROBLEM_API.postMassProblemComment(massProblemId, comment),

    [massProblemId]
  );
  const editComment = useCallback(
    (commentId, comment) =>
      MASS_PROBLEM_API.editMassProblemComment(commentId, comment),

    []
  );

  if (
    !checkPermissionToRenderUI(
      UI_PERMISSIONS["ui:mass-problems:card:comments:list"]
    )
  )
    return null;

  return (
    <TabsAntd className="page__aside-tabs" defaultActiveKey={TABS.comments}>
      <TabsAntd.TabPane key={TABS.comments} tab={TABS_NAME[TABS.comments]}>
        <CommentForm
          appealID={massProblemId}
          currentUserID={operatorId}
          getComments={getComments}
          postComment={postComment}
          editComment={editComment}
          newNotifications={newNotifications}
          postingCommentAllowed={checkPermissionToRenderUI(
            UI_PERMISSIONS["ui:mass-problems:card:comments:post"]
          )}
          editingOwnCommentAllowed={checkPermissionToRenderUI(
            UI_PERMISSIONS["ui:mass-problems:card:comments:edit"]
          )}
        />
      </TabsAntd.TabPane>
    </TabsAntd>
  );
};

export default Tabs;
