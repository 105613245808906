import fetchService from "./api";
import { LOCATION_API_URLS } from "../consts";

export function getLocations() {
  const url = LOCATION_API_URLS.getLocations();
  return fetchService.protectedGet(url);
}

export function getLocationById(locationId) {
  const url = LOCATION_API_URLS.getLocation(locationId);
  return fetchService.protectedGet(url);
}

export function createLocation(location) {
  const url = LOCATION_API_URLS.createLocation();
  return fetchService.protectedPost(url, location);
}

export function getLocationTasks(locationId) {
  const url = LOCATION_API_URLS.getLocationTasks(locationId);
  return fetchService.protectedGet(url);
}

export function setLocationMacAddresses(locationId, macAddresses) {
  const url = LOCATION_API_URLS.setLocationMacAddresses(locationId);
  return fetchService.protectedPost(url, macAddresses);
}

export function changeParentLocation(locationId, parentId) {
  const url = LOCATION_API_URLS.changeParentLocation(locationId);
  return fetchService.protectedPut(url, { parent_id: parentId });
}

export function changeLocationTitle(locationId, title) {
  const url = LOCATION_API_URLS.changeLocationTitle(locationId);
  return fetchService.protectedPut(url, { title });
}

export function deleteLocation(locationId) {
  const url = LOCATION_API_URLS.deleteLocation(locationId);
  return fetchService.protectedDelete(url);
}

const LOCATIONS_API = {
  getLocations,
  createLocation,
  deleteLocation,
  getLocationById,
  getLocationTasks,
  changeLocationTitle,
  changeParentLocation,
  setLocationMacAddresses,
};

export default LOCATIONS_API;
