import React, { Component } from "react";
import throttle from "lodash.throttle";
import { Button } from "antd";
import { ArrowDownOutlined } from "@ant-design/icons";

import ImageViewer from "../../components/ImageViewer";
import Spinner from "../../components/Spinner";
import Message from "../../components/Message";
import MessageSeparator from "../../components/MessageSeparator";
class MessagesList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      viewerVisible: false,
      fetching: false,
      data: [],
      dragging: false,
      scrollToBottomVisible: false,
    };

    this.lazyLoading = this.lazyLoading.bind(this);
    this.toggleViewer = this.toggleViewer.bind(this);
    this.drawMessages = this.drawMessages.bind(this);
    this.checkMessageFrom = this.checkMessageFrom.bind(this);
    this.onDropHandler = this.onDropHandler.bind(this);
    this.onDragOverHandler = this.onDragOverHandler.bind(this);
    this.onDragEnterHangler = this.onDragEnterHangler.bind(this);
    this.onDragLeaveHandler = this.onDragLeaveHandler.bind(this);
    this.onScrollHandler = this.onScrollHandler.bind(this);
    this.throttleScrollHandler = throttle(this.onScrollHandler, 500);
    this.checkScrollToBottomVisible = this.checkScrollToBottomVisible.bind(this);
    this.drawScrollToBottomButton = this.drawScrollToBottomButton.bind(this);
    this.getMessageClassName = this.getMessageClassName.bind(this);
  }

  getMessageClassName(params) {
    const { onClick, isClickable } = params;
    const classNames = [];

    if (typeof onClick === "function" && isClickable) {
      classNames.push("message_clickable");
    }

    return classNames.join(" ");
  }

  drawScrollToBottomButton(visible = false, onClick = () => {}) {
    const className = visible ? "button_visible" : "button_hidden";

    return (
      <Button
        className={`messages-list__button button_scroll-to-bottom ${className}`}
        shape="circle"
        disabled={!visible}
        icon={<ArrowDownOutlined />}
        onClick={onClick}
      />
    );
  }

  checkScrollToBottomVisible(scrollTop, scrollHeight, offsetHeight) {
    // Если сообщения прокручены вверх больше чем на половину высоты элемента
    if (scrollHeight - scrollTop > 1.5 * offsetHeight) {
      this.setState({ scrollToBottomVisible: true });
    } else {
      this.setState({ scrollToBottomVisible: false });
    }
  }

  onScrollHandler(event) {
    const { target } = event;
    const { scrollTop, scrollHeight, offsetHeight } = target;

    // Подгружаем сообщения, если список прокручен до верха
    if (scrollTop === 0) {
      this.lazyLoading();
    }

    this.checkScrollToBottomVisible(scrollTop, scrollHeight, offsetHeight);
  }

  onDragEnterHangler(event) {
    event.preventDefault();
    event.stopPropagation();

    if (event.dataTransfer.items && event.dataTransfer.items.length > 0) {
      this.setState({ dragging: true });
    }
  }

  onDragLeaveHandler(event) {
    event.preventDefault();
    event.stopPropagation();

    this.setState({ dragging: false });
  }

  onDragOverHandler(event) {
    event.preventDefault();
    event.stopPropagation();
  }

  onDropHandler(event) {
    const { onDropFiles = () => {} } = this.props;

    event.preventDefault();
    event.stopPropagation();

    if (event.dataTransfer.files && event.dataTransfer.files.length > 0) {
      onDropFiles(event.dataTransfer.files);
      event.dataTransfer.clearData();
    }

    this.setState({ dragging: false });
  }

  checkMessageFrom(message) {
    const { currentOperatorId } = this.props;

    if ("operator_id" in message) {
      return currentOperatorId === message.operator_id ? "currentOperator" : "other";
    } else {
      return "user";
    }
  }

  toggleViewer(visible) {
    this.setState({ viewerVisible: visible });
  }

  async lazyLoading() {
    const { onFetch = () => {} } = this.props;

    await onFetch();
  }

  drawMessages(messages) {
    const { addFileToIssue, onMessageClick, clickable = [] } = this.props;

    let prevDay;
    let arr = [];

    for (let i = 0; i < messages.length; i++) {
      const { created_at, id } = messages[i];
      const day = new Date(created_at).getDate();
      const messageFrom = this.checkMessageFrom(messages[i]);
      const isClickable = clickable.includes(messageFrom);
      const onClick = isClickable ? () => onMessageClick(messages[i]) : undefined;

      if (prevDay !== day) {
        arr[arr.length] = <MessageSeparator date={created_at} key={created_at} />;
      }

      arr[arr.length] = (
        <Message
          {...messages[i]}
          key={id}
          text={messages[i].message}
          createdAt={messages[i].created_at}
          messageFrom={messageFrom}
          authorName={`${messages[i].messageFrom.last_name} ${messages[i].messageFrom.first_name}`}
          onImageClick={this.toggleViewer}
          addFileToIssue={addFileToIssue}
          onClick={onClick}
          className={this.getMessageClassName({
            onClick,
            isClickable,
          })}
        />
      );
      prevDay = day;
    }

    return arr;
  }

  render() {
    const { viewerVisible, dragging, scrollToBottomVisible } = this.state;
    const { loading, messagesListRef, scrollToBottom } = this.props;

    return (
      <>
        <div
          className={`messages-list  ${dragging ? "files-dnd_dragging" : ""}`}
          onScroll={(event) => {
            event.persist();
            this.throttleScrollHandler(event);
          }}
          onDragEnter={this.onDragEnterHangler}
          onDragLeave={this.onDragLeaveHandler}
          onDragOver={this.onDragOverHandler}
          onDrop={this.onDropHandler}
        >
          {loading && <Spinner />}
          {!loading && this.props.messages.length === 0 && (
            <div className="information">
              Здесь пока нет ни одного сообщения. Напишите что-нибудь...
            </div>
          )}
          {this.drawMessages(this.props.messages)}
          <div ref={messagesListRef}>
            {/* Этот элемент в конце списка, к которому будет происходить прокручивание */}
          </div>
          {this.drawScrollToBottomButton(scrollToBottomVisible, scrollToBottom)}
        </div>
        <ImageViewer visible={viewerVisible} toggleVisibility={this.toggleViewer} />
      </>
    );
  }
}

export default MessagesList;
