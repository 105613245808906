import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";
import { message } from "antd";

import Layout from "./layout";

import RBAC_API from "../../api/rbac";
import OPERATORS_API from "../../api/operators";
import errorHandling from "../../services/errors/errorHandling";
import {
  LDAP_PROP_DELETION_ERROR,
  LDAP_PROP_PATCH_ERROR,
} from "../../actions/errors/actionTypes";
import { useTabs, COMPONENTS } from "../../services/tabs";
import useRBAC, { UI_PERMISSIONS } from "../../services/rbac";

import "./style.scss";

const LDAP_PROPS_SWITCHES = {
  operatorStatus: false,
  operatorsGroup: false,
  roles: false,
};

const LdapPropCard = (props) => {
  const { tabUuid, error, setTabError } = props;

  const dispatch = useDispatch();
  const location = useLocation();

  // Hooks
  const { openInCurrentTab } = useTabs();
  const { checkPermissionToRenderUI } = useRBAC();

  const initialLdapProp = location.state ? location.state.ldapProp : {};
  const uiPermissions = {
    roles: checkPermissionToRenderUI(UI_PERMISSIONS['ui:management:ldap-props:card:roles:edit']),
    delete: checkPermissionToRenderUI(UI_PERMISSIONS['ui:management:ldap-props:card:delete']),
    group: checkPermissionToRenderUI(UI_PERMISSIONS["ui:management:ldap-props:card:operators-group:edit"]),
    status: checkPermissionToRenderUI(UI_PERMISSIONS["ui:management:ldap-props:card:operators-status:edit"]),

  }
  
  // State
  const [ldapProp, setLdapProp] = useState(initialLdapProp);
  const [loading, setLoading] = useState(false);
  const [switches, setSwitches] = useState(LDAP_PROPS_SWITCHES);
  const [roles, setRoles] = useState([]);
  const [groups, setGroups] = useState([]);
  const [statuses, setStatuses] = useState([]);

  function getLdapPropName(locationState) {
    return locationState && locationState.ldapProp
      ? locationState.ldapProp
      : window.location.pathname.split("/")[3];
  }

  async function getLdapProp() {
    const ldapGroupName = getLdapPropName(location.state);

    await OPERATORS_API.getOperatorLdapProps()
      .then((resp) => {
        const ldapProp = resp.data.find(
          (ldapProp) => ldapProp.ldap_group === ldapGroupName
        );

        if (ldapProp !== undefined) {
          setLdapProp(ldapProp);
        } else {
          setTabError({
            title: "LDAP-свойство не найдено",
            message:
              "Возможно вы пытаетесь получить несуществующее LDAP-свойство",
          });
        }
      })
      .catch((err) => {
        console.error(err);

        setTabError({
          title: "LDAP-свойство не найдено",
          message:
            "Возможно вы пытаетесь получить несуществующее LDAP-свойство",
        });
      });
  }

  async function getRoles() {
    await RBAC_API.getRolesList()
      .then((resp) =>
        setRoles(resp.data.map((role) => ({ value: role.id, label: role.id })))
      )
      .catch((err) => {
        console.error(err);

        setTabError(err);
      });
  }

  async function getGroups() {
    await OPERATORS_API.getOperatorsGroupsList()
      .then((resp) =>
        setGroups(
          resp.data.map((group) => ({ value: group.id, label: group.title }))
        )
      )
      .catch((err) => {
        console.error(err);

        setTabError(err);
      });
  }

  async function getStatuses() {
    await OPERATORS_API.getOperatorStatuses()
      .then((resp) =>
        setStatuses(
          resp.data.map((status) => ({ value: status.id, label: status.title }))
        )
      )
      .catch((err) => {
        console.error(err);

        setTabError(err);
      });
  }

  async function getCardData() {
    await setLoading(true);

    try {
      await getRoles();
      await getGroups();
      await getStatuses();
    } catch (e) {
      console.error(e);
    }

    await setLoading(false);
  }

  async function deleteLdapProp(ldapGroup) {
    await OPERATORS_API.deleteOperatorLdapProps(ldapGroup)
      .then(async () => {
        openInCurrentTab({ component: COMPONENTS.MANAGEMENT });
        message.success("Свойство удалено");
      })
      .catch((err) => dispatch(errorHandling(err, LDAP_PROP_DELETION_ERROR)));
  }

  async function changeOperatorsStatus(ldapGroup, statusId) {
    await OPERATORS_API.patchOperatorLdapProps(ldapGroup, {
      operators_status_id: statusId,
    })
      .then(async () => {
        await getLdapProp();
        message.success("Сохранено");
      })
      .catch((err) => dispatch(errorHandling(err, LDAP_PROP_PATCH_ERROR)));
  }

  async function changeOperatorsGroup(ldapGroup, groupId) {
    await OPERATORS_API.patchOperatorLdapProps(ldapGroup, {
      operators_group_id: groupId,
    })
      .then(async () => {
        await getLdapProp();
        message.success("Сохранено");
      })
      .catch((err) => dispatch(errorHandling(err, LDAP_PROP_PATCH_ERROR)));
  }

  async function changeOperatorsRoles(ldapGroup, roles) {
    await OPERATORS_API.patchOperatorLdapProps(ldapGroup, { roles })
      .then(async () => {
        await getLdapProp();
        message.success("Сохранено");
      })
      .catch((err) => dispatch(errorHandling(err, LDAP_PROP_PATCH_ERROR)));
  }

  function switchEditableField(fieldName) {
    setSwitches({ ...switches, [fieldName]: !switches[fieldName] });
  }

  function onPageClick(e) {
    if (
      !e
        .composedPath()
        .find((elem) => elem.className === "component_editable-card-field")
    ) {
      setSwitches(
        Object.keys(switches).reduce((acc, key) => {
          acc[key] = false;
          return acc;
        }, {})
      );
    }
  }

  useEffect(() => {
    document.addEventListener("click", onPageClick);

    return () => document.removeEventListener("click", onPageClick);
  }, []);
  useEffect(() => {
    if (Object.keys(initialLdapProp).length === 0) {
      getLdapProp();
    }

    getCardData();
  }, [tabUuid]);

  return (
    <Layout
      uiPermissions={uiPermissions}
      ldapProp={ldapProp}
      error={error}
      loading={loading}
      switches={switches}
      roles={roles}
      groups={groups}
      statuses={statuses}
      deleteLdapProp={deleteLdapProp}
      switchEditableField={switchEditableField}
      changeOperatorsGroup={changeOperatorsGroup}
      changeOperatorsRoles={changeOperatorsRoles}
      changeOperatorsStatus={changeOperatorsStatus}
    />
  );
};

export default LdapPropCard;
