import React from "react";
import { Input } from "antd";

const DescriptionSection = (props) => {
  const { description, disabled, onChange, permissions } = props;
  const { descriptionEditingAllowed = false } = permissions;

  return (
    <section className="card__section card__section_description">
      <h3 className="section__header">Описание</h3>
      <Input.TextArea
        className="section_description__textarea"
        autoSize={{ minRows: 4, maxRows: 20 }}
        disabled={disabled || !descriptionEditingAllowed}
        value={description}
        onChange={(e) => onChange(e.target.value)}
        placeholder="Опишите проблему"
      />
    </section>
  );
};

export default DescriptionSection;
