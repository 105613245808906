import React from "react";
import { Row, Col } from "antd";

import MainContainer from "../../components/MainContainer";
import EditableCardField, {
  EDITABLE_FIELD_TYPES,
} from "../../components/EditableCardField";

const gutter = [10, 10];

const breakpoints = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
  xl: 8,
  xxl: 8,
};

const Header = () => {
  return (
    <>
      <h2 className="header__title">Информация о локации</h2>
    </>
  );
};

const Layout = (props) => {
  const {
    uiPermissions,
    error,
    loading,
    currentLocation,
    fields,
    switches,
    locations,
    switchEditableField,
    changeLocationTitle,
    changeParentLocation,
    setLocationMacAddresses,
  } = props;

  return (
    <MainContainer
      error={error}
      loading={loading}
      className="card_location-card"
      headerClassName="location-card__header"
      header={<Header />}
    >
      <Row gutter={gutter}>
        <Col {...breakpoints}>
          <div className="location-card__card-field">
            <label className="card-field__title">Локация:</label>
            <EditableCardField
              name={fields.title}
              type={EDITABLE_FIELD_TYPES.input}
              disabled={!uiPermissions.name}
              value={currentLocation.title}
              isEdit={switches.title}
              params={{ allowClear: true }}
              editField={switchEditableField}
              saveChanges={changeLocationTitle}
            />
          </div>
        </Col>
        <Col {...breakpoints}>
          <div className="location-card__card-field">
            <label className="card-field__title">Родительская локация:</label>
            <EditableCardField
              name={fields.parentLocation}
              type={EDITABLE_FIELD_TYPES.select}
              disabled={!uiPermissions.parent}
              value={currentLocation.parent_id}
              isEdit={switches.parentLocation}
              params={{ options: locations }}
              editField={switchEditableField}
              saveChanges={changeParentLocation}
            />
          </div>
        </Col>
        <Col {...breakpoints}>
          <div className="location-card__card-field">
            <label className="card-field__title">MAC адреса:</label>
            <EditableCardField
              name={fields.macAddresses}
              type={EDITABLE_FIELD_TYPES.textarea}
              disabled={!uiPermissions.macAddress}
              value={currentLocation.macAddresses}
              isEdit={switches.macAddresses}
              // params={{ allowClear: true }}
              editField={switchEditableField}
              saveChanges={setLocationMacAddresses}
            />
          </div>
        </Col>
      </Row>
    </MainContainer>
  );
};

export default Layout;
