import { useCallback } from "react";

import DefaultFavicon from "../assets/favicons/favicon.png";

import { FAVICON_TAG_QUERY_SELECTOR } from "../constants/app";

const useFaviconBlick = () => {
  const _getFaviconLinks = useCallback((querySelector = FAVICON_TAG_QUERY_SELECTOR) => {
    return document.querySelectorAll(querySelector);
  }, []);

  const _setFavicon = useCallback(
    (favicon, { querySelector = undefined } = {}) => {
      const links = _getFaviconLinks(querySelector);

      if (links.length > 0) {
        for (const l of links) {
          l.href = favicon;
        }
      }
    },
    [_getFaviconLinks]
  );

  const setDefaultFavicon = useCallback(() => {
    _setFavicon(DefaultFavicon);
  }, [_setFavicon]);

  const setFaviconBlickInterval = useCallback(
    (favicon, interval = 500) => {
      let _isDefaultFavicon = true;

      return setInterval(() => {
        if (_isDefaultFavicon) {
          _setFavicon(favicon);
          _isDefaultFavicon = false;
        } else {
          setDefaultFavicon();
          _isDefaultFavicon = true;
        }
      }, interval);
    },
    [_setFavicon, setDefaultFavicon]
  );

  return {
    setDefaultFavicon,
    setFaviconBlickInterval,
  };
};

export default useFaviconBlick;
