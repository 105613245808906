import React from "react";
import { Typography } from "antd";

const { Link } = Typography;

function getPhoneLinks(phones) {
  if (Array.isArray(phones)) {
    return phones.map((phone, index) => (
      <Link key={`${phone}_${index}`} href={`tel:${phone}`}>
        {phone}
      </Link>
    ));
  } else {
    return [];
  }
}

export default getPhoneLinks;
