import React from "react";
import { Button } from "antd";

import "./style.scss";

const ErrorMessage = (props) => {
  const { title, status, message, onRetry } = props;

  function drawRetryButton() {
    if (!onRetry) return null;

    return (
      <Button
        type="primary"
        className="error-message__retry-button"
        children="Повторить"
        onClick={onRetry}
      />
    );
  }

  function drawStatus() {
    if (!status) return null;

    return (
      <span className="error-message__error-status">Статус: {status} </span>
    );
  }

  return (
    <div className="component_error-message">
      <h3 className="error-message__error-title">{title}</h3>
      <p className="error-message__error-description">
        {drawStatus()}
        {message}
      </p>
      {drawRetryButton()}
    </div>
  );
};

export default ErrorMessage;
