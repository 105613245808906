import React, { useState, useEffect } from "react";

import Table, { TABLE_STYLE } from "../../components/Table";
import useRBAC, { PERMISSIONS } from "../../services/rbac";

const OperatorsList = (props) => {
  const { loading, operators, groups, onRowClick } = props;

  // State
  const [tableData, setTableData] = useState(
    combineOperatorsAndGroups(operators, groups)
  );

  // Hooks
  const { checkAccess } = useRBAC();

  const OPERATOR_LIST_COLUMNS = [
    {
      title: "Имя",
      dataIndex: "full_name",
      customFilter: true,
    },
    {
      title: "Логин",
      dataIndex: "username",
      customFilter: true,
      width: 240,
    },
    {
      title: 'Роли',
      dataIndex: 'roles',
      render: (roles) => roles.join(', '),
      customFilter: true,
    },
    {
      title: "Группа",
      className: "table__col_group",
      dataIndex: "group",
      customFilter: true,
    },
  ];

  function getGroupName(groupId, groups = []) {
    const group = groups.find((group) => group.id === groupId);

    return group !== undefined ? group.title : "Группа не найдена";
  }

  function combineOperatorsAndGroups(operators = [], groups = []) {
    return operators.map((operator) => ({
      ...operator,
      group: getGroupName(operator.operators_group_id, groups),
    }));
  }

  useEffect(() => {
    setTableData(combineOperatorsAndGroups(operators, groups));
  }, [groups, operators]);

  return (
    <div className="body__operators">
      <Table
        loading={loading}
        columns={OPERATOR_LIST_COLUMNS}
        data={tableData}
        onRowClick={
          checkAccess(PERMISSIONS["operators:get:one:by_id"]) ? onRowClick : () => {}
        }
        tableStyle={TABLE_STYLE.zebra}
      />
    </div>
  );
};

export default OperatorsList;
