import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Row, Col, Button, Drawer, Form, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";

import HardwareChanges from "../../components/HardwareChanges";
import Table, { TABLE_STYLE } from "../../components/Table";
import Search from "../../components/Search_new";
import { TYPES } from "../../components/Search_new/consts";

import useHardware from "../../hooks/useHardware.hook";
import useRBAC, { UI_PERMISSIONS } from "../../services/rbac";
import normalizeDate, { DATE_TIME } from "../../utils/normalizeDate";
import ERROR_ACTIONS from "../../actions/errors/errors";

const HardwareCreationForm = (props) => {
  const { onFinish = () => {} } = props;

  return (
    <Form layout="vertical" onFinish={onFinish}>
      <Form.Item
        label="Идентификатор"
        name="identifier"
        rules={[{ required: true, message: "Обязательное поле" }]}
      >
        <Input allowClear />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" children="Добавить" />
      </Form.Item>
    </Form>
  );
};

const Hardware = () => {
  // State
  const [user, setUser] = useState(null);
  const [query, setQuery] = useState("");

  const dispatch = useDispatch();

  // Hooks
  const { checkPermissionToRenderUI } = useRBAC();
  const {
    hardwareLoading,
    searching,
    hardwares,
    selectedHardware,
    creationFormVisible,
    hardwareChangesVisible,
    hardwareChangeText,
    editingHardwareChange,
    identifierEditing,
    searchHardware,
    createHardware,
    getSelectedHardware,
    onPostChange,
    onEditChange,
    onRemoveHardwareChange,
    toggleEditingHardwareChange,
    setHardwareChangesVisible,
    getHardwareChanges,
    setHardwareLoading,
    setSelectedHardware,
    setCreationFormVisible,
    setHardwareChangeText,
    setIdentifierEditing,
    onIdentifierEdit,
  } = useHardware();

  const COLUMNS = [
    {
      title: "Идентификатор",
      dataIndex: "identifier",
    },
    {
      width: 160,
      title: "Создано",
      dataIndex: "created_at",
      render: (value) => normalizeDate(value, DATE_TIME),
    },
    {
      width: 160,
      title: "Обновлено",
      dataIndex: "updated_at",
      render: (value) => normalizeDate(value, DATE_TIME),
    },
  ];

  const rowClickHandler = async (hardwares, hardwareId) => {
    await setHardwareChangesVisible(true);
    await setHardwareLoading(true);

    const _selectedHardware = getSelectedHardware(hardwares, hardwareId);

    if (_selectedHardware) {
      _selectedHardware.hardwareChanges = await getHardwareChanges(hardwareId);

      setSelectedHardware(_selectedHardware);
    } else {
      setSelectedHardware(undefined);
      dispatch(
        ERROR_ACTIONS.addError({
          title: "Не удалось получить историю изменений оборудования",
        })
      );
    }
    await setHardwareLoading(false);
  };

  return (
    <div style={{ minHeight: "480px" }}>
      <Table
        data={hardwares}
        columns={COLUMNS}
        loading={searching}
        scroll={{ y: "calc(100vh - 298px)" }}
        tableStyle={TABLE_STYLE.zebra}
        onRowClick={
          checkPermissionToRenderUI(
            UI_PERMISSIONS["ui:management:hardware:changes:list"]
          )
            ? (_, row) => rowClickHandler(hardwares, row.id)
            : () => {}
        }
        title={() => (
          <Row gutter={[16, 0]} align={"middle"}>
            <Col flex="auto">
              <Row gutter={[8, 0]}>
                <Col flex={"320px"}>
                  <Search
                    placeholder="Пользователь"
                    sought={user}
                    updateParent={setUser}
                    types={[TYPES.user]}
                  />
                </Col>
                <Col flex={"320px"}>
                  <Input
                    autoFocus
                    allowClear
                    placeholder="Идентификатор оборудования"
                    value={query}
                    onChange={(event) => setQuery(event.target.value)}
                    onPressEnter={() => {
                      if (user || query.length > 2) {
                        searchHardware(query, user?.id);
                      }
                    }}
                  />
                </Col>
                <Col>
                  <Button
                    icon={<SearchOutlined />}
                    disabled={Boolean(!(user || query.length > 2))}
                    onClick={() => searchHardware(query, user?.id)}
                  />
                </Col>
              </Row>
            </Col>
            {checkPermissionToRenderUI(
              UI_PERMISSIONS["ui:management:hardware:create"]
            ) && (
              <Col>
                <Button
                  type="primary"
                  children="Добавить оборудование"
                  onClick={() => setCreationFormVisible(true)}
                />
              </Col>
            )}
          </Row>
        )}
      />

      <Drawer
        width={320}
        title="Добавить оборудование"
        visible={creationFormVisible}
        onClose={() => setCreationFormVisible(false)}
      >
        <HardwareCreationForm
          onFinish={(form) => createHardware(form.identifier)}
        />
      </Drawer>

      <Drawer
        width={"auto"}
        bodyStyle={{ overflowY: "hidden" }}
        title={`История изменений ${selectedHardware?.identifier}`}
        visible={hardwareChangesVisible}
        onClose={() => setHardwareChangesVisible(false)}
      >
        <HardwareChanges
          isEditing={Boolean(editingHardwareChange)}
          isIdentifierEditing={identifierEditing}
          text={hardwareChangeText}
          loading={hardwareLoading}
          hardware={selectedHardware}
          onOk={
            editingHardwareChange
              ? () => onEditChange(editingHardwareChange, hardwareChangeText)
              : () => onPostChange(selectedHardware?.id, hardwareChangeText)
          }
          onChangeText={setHardwareChangeText}
          onRemoveChange={onRemoveHardwareChange}
          onIdentifierEdit={(identifier) =>
            onIdentifierEdit(selectedHardware?.id, identifier)
          }
          toggleEditing={toggleEditingHardwareChange}
          toggleIdentifierEditing={setIdentifierEditing}
          permissions={{
            hardwareChangeCreate: checkPermissionToRenderUI(
              UI_PERMISSIONS["ui:management:hardware:changes:create"]
            ),
            hardwareChangeRemove: checkPermissionToRenderUI(
              UI_PERMISSIONS["ui:management:hardware:changes:remove"]
            ),
            hardwareChangeEdit: checkPermissionToRenderUI(
              UI_PERMISSIONS["ui:management:hardware:changes:edit"]
            ),
            identifierEdit: checkPermissionToRenderUI(
              UI_PERMISSIONS["ui:management:hardware:identifier:edit"]
            ),
          }}
        />
      </Drawer>
    </div>
  );
};

export default Hardware;
