import React from "react";
import { List, Button, Typography, Badge } from "antd";
import { ClearOutlined } from "@ant-design/icons";

import useMessages from "../../hooks/messages.hook";

const { Text } = Typography;

const Notifications = (props) => {
  const { notifications = [], getMoreNotifications, onClearAll } = props;

  const { getDateTimeFormat } = useMessages();

  const getTitle = (notification) => {
    const { is_read, message, created_at } = notification;
    return (
      <div style={{ display: "flex" }}>
        {!is_read && <Badge dot status="error" />}
        <Text style={{ marginRight: "auto" }} children={message} />
        <Text
          style={{ fontSize: 12 }}
          type="secondary"
          children={getDateTimeFormat(created_at)}
        />
      </div>
    );
  };

  return (
    <>
      <List
        header={
          <Button
            type="link"
            children="Очистить все"
            icon={<ClearOutlined />}
            onClick={onClearAll}
            disabled={notifications.length === 0}
          />
        }
        itemLayout="vertical"
        loadMore={
          notifications.length > 0 && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "24px 0 8px",
              }}
            >
              <Button children="Загрузить ещё" onClick={getMoreNotifications} />
            </div>
          )
        }
        dataSource={notifications}
        renderItem={(notification) => {
          const { btn } = notification;
          const actions = [];

          if (btn) {
            actions.push(btn);
          }

          return (
            <List.Item key={notification.id} actions={actions}>
              <List.Item.Meta
                key={notification.id}
                title={getTitle(notification)}
                description={notification.description}
              />
            </List.Item>
          );
        }}
      />
    </>
  );
};

export default Notifications;
