import React from "react";
import { Button } from "antd";
import { FileOutlined, PlusOutlined } from "@ant-design/icons";

import normalizeDate, { ONLY_TIME } from "../../utils/normalizeDate";
import { classNames, IMAGE_MIME_TYPES, VIDEO_MIME_TYPES } from "./consts";

import "./style.scss";

const MessageFile = (props) => {
  const {
    file,
    text,
    authorName,
    createdAt,
    className,
    onImageClick = () => {},
    onClick = () => {},
    addFileToIssue,
  } = props;

  function drawAddToIssueButton(addToIssue, fileId) {
    if (typeof addToIssue === "function") {
      return (
        <Button
          type="link"
          children="Добавить к обращению"
          className="message__add-to-issue"
          icon={<PlusOutlined />}
          onClick={() => addToIssue(fileId)}
        />
      );
    } else {
      return null;
    }
  }

  if (IMAGE_MIME_TYPES.includes(file.type)) {
    return (
      <div className={`message message_with-file ${className}`} onClick={onClick}>
        <h4 className="message__author">{authorName}</h4>
        <p className="message__content">{text}</p>
        <img
          className="message__file"
          src={file.base64}
          alt={file.name}
          onClick={(e) => onImageClick(e.target)}
        />
        <div>
          <a className="message__download-link" href={file.base64} download>
            Скачать
          </a>
          {drawAddToIssueButton(addFileToIssue, file.id)}
        </div>
        <span className="message__created-at">{normalizeDate(createdAt, ONLY_TIME)}</span>
      </div>
    );
  }

  if (VIDEO_MIME_TYPES.includes(file.type)) {
    return (
      <div className={`message message_with-file ${className}`} onClick={onClick}>
        <h4 className="message__author">{authorName}</h4>
        <p className="message__content">{text}</p>
        <video className="message__video" controls>
          <source
            src={file.base64}
            type='video/mp4; codecs="avc1.42E01E, mp4a.40.2"'
          ></source>
          <source src={file.base64} type='video/ogg; codecs="theora, vorbis"'></source>
          <source src={file.base64} type='video/webm; codecs="vp8, vorbis"'></source>
          Ваш браузер не поддерживает воспроизведение видео
        </video>
        <div>
          <a className="message__download-link" href={file.base64} download>
            Скачать
          </a>
          {drawAddToIssueButton(addFileToIssue, file.id)}
        </div>
        <span className="message__created-at">{normalizeDate(createdAt, ONLY_TIME)}</span>
      </div>
    );
  }

  return (
    <div className={`message message_with-file ${className || ""}`} onClick={onClick}>
      <h4 className="message__author">{authorName}</h4>
      <p className="message__content">{text}</p>
      <div className="message__file-preview">
        <FileOutlined style={{ fontSize: 80 }} />
        {/* <span style={{ position: 'absolute',  }}>{file.type.split("/")[1]}</span> */}
      </div>
      <div>
        <a className="message__download-link" href={file.base64} download>
          Скачать
        </a>
        {drawAddToIssueButton(addFileToIssue, file.id)}
      </div>
      <span className="message__created-at">{normalizeDate(createdAt, ONLY_TIME)}</span>
    </div>
  );
};

const Message = (props) => {
  const { authorName, text, createdAt, className, onClick = () => {} } = props;

  return (
    <div className={`message ${className || ""}`} onClick={onClick}>
      <h4 className="message__author">{authorName}</h4>
      <p className="message__content">{text}</p>
      <span className="message__created-at">{normalizeDate(createdAt, ONLY_TIME)}</span>
    </div>
  );
};

const RenderMessage = (props) => {
  let { className } = props;
  const { file, messageFrom, onImageClick, addFileToIssue } = props;

  className =
    className !== undefined
      ? `${className} ${classNames[messageFrom]}`
      : classNames[messageFrom];

  if (file)
    return (
      <MessageFile
        {...props}
        className={className}
        onImageClick={onImageClick}
        addFileToIssue={addFileToIssue}
      />
    );

  return <Message {...props} className={className} />;
};

export default RenderMessage;
