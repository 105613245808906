import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Input,
  Button,
  Form,
  Select,
  Checkbox,
  Layout,
  Row,
  Col,
  Space,
  Drawer,
  message,
  Divider,
} from "antd";
import { useLocation } from "react-router";
import { SearchOutlined } from "@ant-design/icons";

import Table from "../../components/Table";
import Search from "../../components/Search_new";
import MainContainer from "../../components/MainContainer";
import AllUserAppeals from "../../components/AllUserAppeals";
import FilesViewer from "../../components/FilesViewer";
import FileUploader from "../../components/FilesUploader";
import UserData from "../../components/UserData";
import HardwareChanges from "../../components/HardwareChanges";
import Chat from "../../components/Chat";
import AddUserMessages from "./AddUserMessages";
import Property from "../../components/Property";

import normalizeDate, { DATE_TIME } from "../../utils/normalizeDate";
import { TYPES } from "../../components/Search_new/consts";
import LOCATIONS_API from "../../api/locations";
import ISSUES_API from "../../api/appeals";
import USER_API from "../../api/users";
import TASK_ACTIONS from "../../actions/task/task";
import ERROR_ACTIONS from "../../actions/errors/errors";
import { useTabs, COMPONENTS } from "../../services/tabs";
import useRBAC, { UI_PERMISSIONS } from "../../services/rbac";
import useTemplate from "../../hooks/useTemplate.hook";
import useHardware from "../../hooks/useHardware.hook";
import useUserMessage from "./AddUserMessages/userMessage.hook";

import "./style.scss";

const { TextArea } = Input;
const { Content } = Layout;

const INITIAL_VALUES = {
  performer: null,
  user: null,
  issueId: "",
  location: undefined,
  description: "",
  urgency: false,
  templateId: undefined,
  hardwareId: undefined,
  hardwareQuery: "",
  userMessages: {},
};

const columns = [
  {
    title: "Дата",
    dataIndex: "created_at",
    key: "created_at",
    width: 150,
    render: (date) => normalizeDate(date, DATE_TIME),
  },
  {
    title: "Номер",
    dataIndex: "human_readable_id",
    width: 100,
  },
  {
    title: "Описание",
    dataIndex: "description",
    key: "description",
    ellipsis: true,
  },
  {
    title: "Исполнитель",
    dataIndex: "opName",
    key: "opName",
  },
];

const HARDWARE_COLUMNS = [
  {
    title: "Идентификатор",
    dataIndex: "identifier",
  },
];

const Header = ({ loading }) => {
  return (
    <>
      <h2 className="header__title">Создание задачи</h2>
      <Button
        loading={loading}
        form="task-creation-form"
        className="header__button_create"
        type="primary"
        htmlType="submit"
        children="Создать"
      />
    </>
  );
};
function TaskCreationForm(props) {
  const { componentProps, setComponentProps, tabUuid } = props;

  const dispatch = useDispatch();
  const location = useLocation();

  // Store
  const currentOperatorId = useSelector((store) => store.user.id);

  // State
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [locationsFilter, setLocationsFilter] = useState(undefined);
  const [issues, setIssues] = useState([]);
  const [locations, setLocations] = useState([]);
  const [issueFiles, setIssueFiles] = useState([]);
  const [selectedIssueFiles, setSelectedIssueFiles] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [userPhoto, setUserPhoto] = useState("");
  const [userChatOpen, setUserChatOpen] = useState(false);

  // Hooks
  const { openInNewTab } = useTabs();
  const { checkPermissionToRenderUI } = useRBAC();
  const { getTemplates } = useTemplate();
  const {
    hardwares,
    hardwareLoading,
    selectedHardware,
    hardwareChangesVisible,
    searchHardware,
    setSelectedHardware,
    setHardwareLoading,
    setHardwareChangesVisible,
    getSelectedHardware,
    getHardwareChanges,
  } = useHardware();

  const {
    addUserMessage,
    removeUserMessage,
    editUserMessage,
    сhangeUserMessageText,
    getMessagesArray,
    formDescription,
    onInputPressEnter,
  } = useUserMessage({
    messages: componentProps?.userMessages,
    setComponentProps,
  });

  const onChangeUserWorkplaceLocation = async (userId, location) => {
    try {
      await USER_API.changeUserWorkplaceLocation(userId, location);

      const response = await USER_API.getUserData(userId);
      setComponentProps({ user: response.data });

      message.success("Обновлено");
    } catch (err) {
      dispatch(
        ERROR_ACTIONS.addError({
          title: "Не удалось изменить местоположение пользователя",
          message: err.response.data?.message,
          status: err.response.status,
        })
      );
    }
  };

  const onChangeUserWorkstationNumber = async (userId, number) => {
    try {
      await USER_API.changeUserWorkstationNumber(userId, number);

      const response = await USER_API.getUserData(userId);
      setComponentProps({ user: response.data });

      message.success("Обновлено");
    } catch (err) {
      dispatch(
        ERROR_ACTIONS.addError({
          title: "Не удалось изменить номер компьютера пользователя",
          message: err.response.data?.message,
          status: err.response.status,
        })
      );
    }
  };

  const getUserPhoto = async (userId) => {
    try {
      const response = await USER_API.getUserPhoto(userId);
      const url = await URL.createObjectURL(response.data);

      setUserPhoto(url);
    } catch (err) {
      setUserPhoto("");
    }
  };

  function getInitialValues(componentProps, locationState) {
    if (componentProps !== null) {
      return componentProps;
    } else if (locationState) {
      return {
        ...INITIAL_VALUES,
        performer:
          locationState.operatorID === null
            ? locationState.operatorID
            : { id: locationState.operatorID, type: TYPES.operator },
        user: { id: locationState.userID, type: TYPES.user },
        issueId: locationState.issueID,
        description: locationState.description,
      };
    } else {
      return INITIAL_VALUES;
    }
  }

  const filesSelection = {
    type: "checkbox",
    selected: selectedIssueFiles,
    onChange: setSelectedIssueFiles,
  };

  function getIssueData(issueId) {
    ISSUES_API.getAppealData(issueId)
      .then((resp) => {
        if ("files" in resp.data) {
          setIssueFiles(resp.data.files);
        } else {
          setIssueFiles([]);
        }
      })
      .catch((err) => {
        setIssueFiles([]);
        console.error(err);
      });
  }

  function formatLocations(locations = []) {
    return locations.map((location) => ({
      ...location,
      key: location.id,
      value: location.id,
      label: location.title,
    }));
  }

  async function getLocations() {
    await LOCATIONS_API.getLocations().then((resp) =>
      setLocations(formatLocations(resp.data))
    );
  }

  async function onChangeUser(newUser) {
    if (newUser !== null) {
      await getUserIssues(newUser.id);
      await getUserPhoto(newUser.id);
    } else {
      setUserPhoto("");
    }

    setComponentProps({
      user: newUser,
      issueId: "",
      userMessages: INITIAL_VALUES.userMessages,
    });
  }

  function onChangePerformer(currentPerformer, newPerformer) {
    const needToResetTemplate = (currentPerformer, newPerformer, currentTemplateId) => {
      const _template = findTemplate(currentTemplateId);
      let _currentPerformerGroupId = undefined;
      let _newPerformerGroupId = undefined;

      if (currentPerformer) {
        if (currentPerformer.type === TYPES.group)
          _currentPerformerGroupId = currentPerformer.id;
        if (currentPerformer.type === TYPES.operator)
          _currentPerformerGroupId = currentPerformer.operators_group_id;
      }
      if (newPerformer) {
        if (newPerformer.type === TYPES.group) _newPerformerGroupId = newPerformer.id;
        if (newPerformer.type === TYPES.operator)
          _newPerformerGroupId = newPerformer.operators_group_id;
      }

      // Если есть оба исполнителя и их группы не совпадают, то сбрасываем шаблон
      if (
        _currentPerformerGroupId &&
        _newPerformerGroupId &&
        _currentPerformerGroupId !== _newPerformerGroupId
      )
        return true;

      // Если нет текущего исполнителя, выбран шаблон и этот шаблон не соответствует новому исполнителю
      if (_template) {
        const _templateGroupIds = _template.operator_groups.map((_g) => _g.id);

        if (
          !_currentPerformerGroupId &&
          _newPerformerGroupId &&
          !_templateGroupIds.includes(_newPerformerGroupId)
        )
          return true;
      }

      return false;
    };

    if (needToResetTemplate(currentPerformer, newPerformer, componentProps?.templateId)) {
      onChangeTemplate(
        componentProps?.templateId,
        undefined,
        componentProps?.description
      );
    }

    setComponentProps({ performer: newPerformer });
  }

  function onChangeLocation(location) {
    setComponentProps({ location });
  }

  function onChangeDescription(description) {
    setComponentProps({ description });
  }

  function onChangeIssueId(issueId) {
    setComponentProps({ issueId });

    if (issueId) {
      getIssueData(issueId);
    }

    setSelectedIssueFiles([]);
  }

  function onChangeUrgency(bool) {
    setComponentProps({ urgency: bool });
  }

  async function onFinish(form) {
    setLoading(true);

    const messages = getMessagesArray(form.userMessages);

    const task = {
      issue_id: form.issue_id,
      performerID: form.operator.id,
      description: formDescription(form.description, messages),
      location_id: form.location_id,
      urgency: form.urgency,
      template_id: form.template_id,
      file_ids: [...form.files.map((file) => file.id), ...selectedIssueFiles],
      hardware_id: form.hardware_id,
    };

    if (form.user) {
      task.user_id = form.user.id;
    }

    dispatch(TASK_ACTIONS.createNewTask(task, form.operator.type));
    setLoading(false);
  }

  async function getUserIssues(userID) {
    await ISSUES_API.getUserAppeals(userID)
      .then((resp) => setIssues(resp.data))
      .catch((e) => console.error(e));
  }

  function openOperatorCard(e, operatorId) {
    e.preventDefault();
    e.stopPropagation();

    openInNewTab({
      component: COMPONENTS.OPERATOR_CARD,
      params: { operatorId },
      locationState: { operatorId },
    });
  }

  function openUserCard(e, userId) {
    e.preventDefault();
    e.stopPropagation();

    openInNewTab({
      component: COMPONENTS.USER_CARD,
      params: { userId },
      locationState: { userId },
    });
  }

  const findTemplate = useCallback(
    (templateId) => {
      if (!templateId) return undefined;

      return templates.find((_t) => _t.id === templateId);
    },
    [templates]
  );

  const getTemplateOptions = (templates, performer) => {
    const formatOptions = (templates) =>
      templates.map((_t) => ({
        label: _t.title,
        value: _t.id,
      }));

    if (performer?.type === TYPES.operator) {
      const _templates = templates.filter(
        (_t) =>
          _t.operator_groups.findIndex((_g) => _g.id === performer.operators_group_id) !==
          -1
      );

      return formatOptions(_templates);
    }
    if (performer?.type === TYPES.group) {
      const _templates = templates.filter(
        (_t) => _t.operator_groups.findIndex((_g) => _g.id === performer.id) !== -1
      );

      return formatOptions(_templates);
    }

    return formatOptions(templates);
  };

  const onChangeTemplate = (currentTemplateId, nextTemplateId, description = "") => {
    const lineBreak = `\n\n`;
    const _nextTemplate = findTemplate(nextTemplateId); // Ищем новый шаблон
    const _currentTemplate = findTemplate(currentTemplateId); // Ищем текущий шаблон
    // Пытаемся удалить предыдущий шаблон из описания
    const _replacedTemplateFromDescription = description.replace(
      `${_currentTemplate?.text}${lineBreak}`,
      ""
    );

    if (_nextTemplate) {
      setComponentProps({
        templateId: nextTemplateId,
        description: `${_nextTemplate.text}${lineBreak}${_replacedTemplateFromDescription}`,
      });
    } else {
      setComponentProps({
        templateId: nextTemplateId,
        description: _replacedTemplateFromDescription,
      });
    }
  };

  const showHardwareChanges = async (hardwares, hardwareId) => {
    await setHardwareChangesVisible(true);
    await setHardwareLoading(true);

    const _selectedHardware = getSelectedHardware(hardwares, hardwareId);

    if (_selectedHardware) {
      _selectedHardware.hardwareChanges = await getHardwareChanges(hardwareId);

      setSelectedHardware(_selectedHardware);
    } else {
      setSelectedHardware(undefined);
      dispatch(
        ERROR_ACTIONS.addError({
          title: "Не удалось получить историю изменений оборудования",
        })
      );
    }
    await setHardwareLoading(false);
  };

  useEffect(() => {
    (async () => {
      const initialState = getInitialValues(componentProps, location.state);

      if (componentProps === null) {
        setComponentProps(initialState);
      }
      if (initialState.user) {
        getUserIssues(initialState.user.id);
        getUserPhoto(initialState.user.id);
      }
      if (initialState.issueId) {
        getIssueData(initialState.issueId);
      }
      if (componentProps?.hardwareQuery) {
        await searchHardware(componentProps.hardwareQuery);
      }

      getLocations();
      const templates = await getTemplates();

      setTemplates(templates);
    })();
  }, [tabUuid, getTemplates]);

  useEffect(() => {
    return () => {
      URL.revokeObjectURL(userPhoto);
    };
  }, [userPhoto]);

  if (componentProps === null) return null;

  const rowSelection = {
    type: "radio",
    selectedRowKeys: [componentProps.issueId],
    onChange: async (selectedRowKeys, selectedRow) => {
      await onChangeIssueId(selectedRow[0].id);
    },
  };

  const fields = [
    {
      name: "operator",
      value: componentProps.performer,
    },
    {
      name: "issue_id",
      value: componentProps.issueId,
    },
    {
      name: "user",
      value: componentProps.user,
    },
    {
      name: "description",
      value: componentProps.description,
    },
    {
      name: "location_id",
      value: componentProps.location,
    },
    {
      name: "urgency",
      value: componentProps.urgency,
    },
    {
      name: "files",
      value: files,
    },
    {
      name: "template_id",
      value: componentProps.templateId,
    },
    {
      name: "hardware_id",
      value: componentProps.hardwareId,
    },
    {
      name: "userMessages",
      value: componentProps.userMessages,
    },
  ];

  return (
    <MainContainer
      header={<Header loading={loading} />}
      headerClassName="task-creation-form__header"
      bodyClassName="task-creation-form__body"
    >
      <Layout style={{ backgroundColor: "white" }}>
        <Content>
          <Row gutter={[24, 24]}>
            <Col span={componentProps?.user ? 12 : 24}>
              <Form
                id="task-creation-form"
                onFinish={onFinish}
                fields={fields}
                layout="vertical"
              >
                <Form.Item
                  className="title"
                  label="Выберите исполнителя:"
                  name="operator"
                  rules={[{ required: true, message: "Поле должно быть заполнено" }]}
                >
                  <Search
                    placeholder="Выберите исполнителя..."
                    sought={componentProps.performer}
                    updateParent={(newPerformer) =>
                      onChangePerformer(componentProps.performer, newPerformer)
                    }
                    types={[TYPES.operator, TYPES.group]}
                  />
                  {componentProps.performer && (
                    <Button
                      type="link"
                      children="Открыть карточку"
                      onClick={(e) => openOperatorCard(e, componentProps.performer.id)}
                    />
                  )}
                </Form.Item>

                <Form.Item name="user" label="Выберите пользователя:" className="title">
                  <Search
                    placeholder="Введите имя пользователя..."
                    updateParent={onChangeUser}
                    sought={componentProps.user}
                    types={[TYPES.user, TYPES.employee]}
                  />
                  {componentProps.user && (
                    <Button
                      type="link"
                      children="Открыть карточку"
                      onClick={(e) => openUserCard(e, componentProps.user.id)}
                    />
                  )}
                </Form.Item>

                {checkPermissionToRenderUI(
                  UI_PERMISSIONS["ui:tasks:creation-form:related-issues"]
                ) &&
                  componentProps.user && (
                    <Form.Item
                      label="Выберите обращение, на основе которого создать задачу:"
                      className="title"
                      name="issue_id"
                    >
                      <Table
                        rowKey={(row) => row.id}
                        data={issues}
                        columns={columns}
                        rowSelection={rowSelection}
                        size="small"
                      />
                    </Form.Item>
                  )}

                <Form.Item label="Выберите локацию:" name="location_id" className="title">
                  <Select
                    allowClear
                    showSearch
                    filterOption
                    placeholder="Выберите локацию..."
                    optionFilterProp="label"
                    style={{ maxWidth: 400 }}
                    options={locations}
                    searchValue={locationsFilter}
                    onSearch={setLocationsFilter}
                    onChange={onChangeLocation}
                  />
                </Form.Item>

                <Form.Item name="template_id" label="Шаблон" className="title">
                  <Select
                    allowClear
                    placeholder="Выберите шаблон"
                    style={{ maxWidth: 400 }}
                    options={getTemplateOptions(templates, componentProps.performer)}
                    onChange={(templateId) =>
                      onChangeTemplate(
                        componentProps?.templateId,
                        templateId,
                        componentProps?.description
                      )
                    }
                  />
                </Form.Item>

                <Form.Item
                  name="description"
                  label="Добавьте описание:"
                  className="title"
                  rules={[{ required: true, message: "Поле должно быть заполнено" }]}
                >
                  <TextArea
                    className="text-area"
                    placeholder="Описание..."
                    onChange={(e) => onChangeDescription(e.target.value)}
                  />
                </Form.Item>

                {componentProps?.user && (
                  <>
                    <Divider />
                    <Form.Item name="userMessages" noStyle>
                      <Property
                        className="add-user-message"
                        title="Добавить сообщения пользователя"
                        description="Выберите сообщения пользователя. Текстовые сообщения будут добавлены в описание задачи"
                        buttonProps={{
                          children: "Добавить",
                          onClick: () => setUserChatOpen(true),
                        }}
                      />
                      <AddUserMessages
                        messages={getMessagesArray(componentProps.userMessages)}
                        onChangeMessageText={(id, value) =>
                          сhangeUserMessageText(id, value)
                        }
                        onPressEnter={onInputPressEnter}
                        onEdit={(id, bool) => editUserMessage(id, bool)}
                        onDelete={(id) => removeUserMessage(id)}
                      />
                    </Form.Item>
                    <Divider />
                  </>
                )}

                <Form.Item
                  name="urgency"
                  valuePropName="checked"
                  label="Приоритет:"
                  className="title"
                >
                  <Checkbox
                    style={{ fontWeight: "normal" }}
                    onChange={(e) => onChangeUrgency(e.target.checked)}
                  >
                    Высокий
                  </Checkbox>
                </Form.Item>

                {componentProps.issueId && issueFiles.length > 0 && (
                  <Form.Item
                    name="issue-files"
                    className="title"
                    label="Перенесите файлы из обращения:"
                  >
                    <FilesViewer files={issueFiles} selection={filesSelection} />
                  </Form.Item>
                )}

                <Form.Item name="files" label="Прикрепите файлы:" className="title">
                  <FileUploader files={files} onChange={setFiles} />
                </Form.Item>

                <Form.Item
                  name="hardware_id"
                  label="Оборудование"
                  className="title title_hardware-id"
                >
                  <Table
                    data={hardwares}
                    columns={HARDWARE_COLUMNS}
                    onRowClick={(event, row) => {
                      showHardwareChanges(hardwares, row.id);
                    }}
                    rowSelection={{
                      type: "radio",
                      selectedRowKeys: [componentProps?.hardwareId],
                      onChange: async (selectedRowKeys, selectedRow) => {
                        await setComponentProps({
                          hardwareId: selectedRow[0].id,
                        });
                      },
                    }}
                    title={() => (
                      <Space>
                        <Input
                          allowClear
                          style={{ width: 260 }}
                          placeholder="Идентификатор"
                          value={componentProps?.hardwareQuery}
                          onChange={(event) => {
                            if (event.target.value) {
                              setComponentProps({
                                hardwareQuery: event.target.value,
                              });
                            } else {
                              setComponentProps({
                                hardwareQuery: event.target.value,
                                hardwareId: INITIAL_VALUES.hardwareId,
                              });
                            }
                          }}
                          onPressEnter={(event) => {
                            event.preventDefault();
                            searchHardware(componentProps?.hardwareQuery);
                          }}
                        />
                        <Button
                          onClick={() => searchHardware(componentProps?.hardwareQuery)}
                          icon={<SearchOutlined />}
                        />
                      </Space>
                    )}
                  />
                </Form.Item>
              </Form>
            </Col>

            {componentProps?.user && (
              <Col span={12}>
                {checkPermissionToRenderUI(
                  UI_PERMISSIONS["ui:tasks:creation-form:user-data"]
                ) && (
                  <UserData
                    user={{
                      ...componentProps.user,
                      photo: userPhoto,
                      full_name: `${componentProps.user?.last_name} ${componentProps.user?.first_name} ${componentProps.user?.patronymic_name}`,
                    }}
                    permissions={{
                      changingWorkplaceLocationAllowed: checkPermissionToRenderUI(
                        UI_PERMISSIONS[
                          "ui:tasks:creation-form:user-data:workplace-location:edit"
                        ]
                      ),
                      changingWorkstationNumberAllowed: checkPermissionToRenderUI(
                        UI_PERMISSIONS[
                          "ui:tasks:creation-form:user-data:workstation-number:edit"
                        ]
                      ),
                    }}
                    onChangeWorkplaceLocation={(location) =>
                      onChangeUserWorkplaceLocation(componentProps.user.id, location)
                    }
                    onChangeWorkstationNumber={(number) =>
                      onChangeUserWorkstationNumber(componentProps.user.id, number)
                    }
                  />
                )}

                <AllUserAppeals
                  userId={componentProps?.user?.id}
                  displayIssues={checkPermissionToRenderUI(
                    UI_PERMISSIONS["ui:tasks:creation-form:user-issues"]
                  )}
                  displayTasks={checkPermissionToRenderUI(
                    UI_PERMISSIONS["ui:tasks:creation-form:user-tasks"]
                  )}
                />
              </Col>
            )}
          </Row>
        </Content>
      </Layout>

      <Drawer
        width={"auto"}
        bodyStyle={{ overflowY: "hidden" }}
        title={`История изменений ${selectedHardware?.identifier}`}
        visible={hardwareChangesVisible}
        onClose={() => setHardwareChangesVisible(false)}
      >
        <HardwareChanges
          loading={hardwareLoading}
          hardware={selectedHardware}
          permissions={{
            hardwareChangeCreate: false,
            hardwareChangeRemove: false,
            hardwareChangeEdit: false,
            identifierEdit: false,
          }}
        />
      </Drawer>

      <Drawer
        width={480}
        headerStyle={{
          display: "none",
        }}
        bodyStyle={{
          padding: 12,
        }}
        open={userChatOpen && componentProps?.user}
        onClose={() => setUserChatOpen(false)}
      >
        <Chat
          userId={componentProps?.user?.id}
          operatorId={currentOperatorId}
          onMessageClick={(m) => addUserMessage(m)}
          clickableMessages={["user"]}
        />
      </Drawer>
    </MainContainer>
  );
}

export default TaskCreationForm;
