import React, { useCallback, useEffect, useState } from "react";
import { Row, Col, Button, Modal } from "antd";
import { useDispatch } from "react-redux";

import MainTable, { TABLE_STYLE } from "../../components/Table";
import ServiceAccountCreationForm from "../../components/ServiceAccountCreationForm";
import ServiceAccountCard from "../../components/ServiceAccountCard";
import { TYPES } from "../../components/Search_new/consts";

import OPERATORS_API from "../../api/operators";
import ERROR_ACTIONS from "../../actions/errors/errors";
import useServiceAccount from "../../hooks/useServiceAccount";
import useRBAC, { UI_PERMISSIONS } from "../../services/rbac";

const COLUMNS = [
  {
    dataIndex: "name",
    title: "Название",
    customFilter: true,
    ellipsis: true,
  },
  {
    dataIndex: "description",
    title: "Описание",
    ellipsis: true,
    customFilter: true,
  },
  {
    dataIndex: ["from_operator", "fullname"],
    title: "От оператора",
    customFilter: true,
  },
  {
    dataIndex: ["performer", "name"],
    title: "Исполнитель",
    customFilter: true,
  },
  {
    dataIndex: "disabled",
    title: "Неактивный",
    width: 108,
    render: (value) => (value ? "Да" : "Нет"),
  },
];

const TableTitle = ({ onShowCreationFormVisible }) => {
  return (
    <Row align="end">
      <Col>
        <Button
          children="Создать"
          type="primary"
          onClick={onShowCreationFormVisible}
        />
      </Col>
    </Row>
  );
};

const ServiceAccounts = () => {
  const dispatch = useDispatch();

  // State
  const [loading, setLoading] = useState(false);
  const [creationFormVisible, setCreationFormVisible] = useState(false);
  const [serviceAccounts, setServiceAccounts] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(undefined);
  const [operators, setOperators] = useState([]);
  const [operatorGroups, setOperatorGroups] = useState([]);
  const [creating, setCreating] = useState(false);
  const [secretUpdating, setSecretUpdating] = useState(false);
  const [editing, setEditing] = useState(false);

  // Hooks
  const {
    getServiceAccounts,
    createServiceAccount,
    updateServiceAccount,
    updateSecretServiceAccount,
  } = useServiceAccount();
  const { checkPermissionToRenderUI } = useRBAC();

  const getOperators = useCallback(async () => {
    try {
      const response = await OPERATORS_API.getOperatorsList();
      return response.data;
    } catch (err) {
      console.error(err);
      dispatch(
        ERROR_ACTIONS.addError({
          title: "Не удалось получить список операторов",
          message: err.response.data?.message,
          status: err.response.status,
        })
      );

      return [];
    }
  }, [dispatch]);

  const getOperatorGroups = useCallback(async () => {
    try {
      const response = await OPERATORS_API.getOperatorsGroupsList();
      return response.data;
    } catch (err) {
      console.error(err);
      dispatch(
        ERROR_ACTIONS.addError({
          title: "Не удалось получить список групп операторов",
          message: err.response.data?.message,
          status: err.response.status,
        })
      );

      return [];
    }
  }, [dispatch]);

  const getServiceAccountPerformer = useCallback((account) => {
    if (account.to_operator) {
      return {
        id: account.to_operator.id,
        name: `${account.to_operator.last_name} ${account.to_operator.first_name}`,
        type: TYPES.operator,
      };
    }
    if (account.to_operators_group) {
      return {
        id: account.to_operators_group.id,
        name: account.to_operators_group.title,
        type: TYPES.group,
      };
    }

    return undefined;
  }, []);

  const formatServiceAcc = useCallback(
    (account) => {
      return {
        ...account,
        from_operator: {
          ...account.from_operator,
          fullname: `${account.from_operator.last_name} ${account.from_operator.first_name}`,
        },
        performer: getServiceAccountPerformer(account),
      };
    },
    [getServiceAccountPerformer]
  );

  const onCreateServiceAccount = async (account) => {
    await setCreating(true);

    try {
      const newAccount = await createServiceAccount(account);
      setServiceAccounts((accounts) => [...accounts, newAccount]);
      setCreationFormVisible(false);
    } catch {}

    await setCreating(false);
  };

  const onSaveCommon = useCallback(
    async (accountId, commonData) => {
      await setEditing(true);

      try {
        const response = await updateServiceAccount(accountId, commonData);
        const updatedAcc = formatServiceAcc(response.data);

        setServiceAccounts((prev) => {
          const accounts = [...prev];
          const index = prev.findIndex((_account) => _account.id === accountId);

          if (index !== -1) {
            accounts[index] = updatedAcc;
          } else {
            accounts.push(updatedAcc);
          }

          return accounts;
        });
        setSelectedAccount(updatedAcc);
      } catch {}

      await setEditing(false);
    },
    [updateServiceAccount, formatServiceAcc]
  );

  const onSecretUpdate = useCallback(
    async (accountId) => {
      await setSecretUpdating(true);

      try {
        const response = await updateSecretServiceAccount(accountId);
        const updatedAcc = formatServiceAcc(response.data);

        setServiceAccounts((prev) => {
          const accounts = [...prev];
          const index = prev.findIndex((_account) => _account.id === accountId);

          if (index !== -1) {
            accounts[index] = updatedAcc;
          } else {
            accounts.push(updatedAcc);
          }

          return accounts;
        });
        setSelectedAccount(updatedAcc);
      } catch {}

      await setSecretUpdating(false);
    },
    [updateSecretServiceAccount, formatServiceAcc]
  );

  // initial
  useEffect(() => {
    (async () => {
      await setLoading(true);

      const operators = await getOperators().then((operators) =>
        operators.map((_operator) => ({
          value: _operator.id,
          label: `${_operator.last_name} ${_operator.first_name}`,
        }))
      );
      const groups = await getOperatorGroups().then((groups) =>
        groups.map((_group) => ({
          value: _group.id,
          label: _group.title,
        }))
      );
      const serviceAccounts = await getServiceAccounts().then((accounts) =>
        accounts.map((_a) => formatServiceAcc(_a))
      );

      setOperators(operators);
      setOperatorGroups(groups);
      setServiceAccounts(serviceAccounts);

      await setLoading(false);
    })();
  }, [formatServiceAcc]);

  return (
    <>
      <Row>
        <Col span={24}>
          <MainTable
            loading={loading}
            onRowClick={
              checkPermissionToRenderUI(
                UI_PERMISSIONS["ui:management:service-account:card"]
              )
                ? (event, account) => setSelectedAccount(account)
                : () => {}
            }
            data={serviceAccounts}
            columns={COLUMNS}
            tableStyle={TABLE_STYLE.zebra}
            title={() => (
              <TableTitle
                onShowCreationFormVisible={() => setCreationFormVisible(true)}
              />
            )}
          />
        </Col>
      </Row>
      <Modal
        destroyOnClose
        footer={null}
        visible={creationFormVisible}
        onCancel={() => setCreationFormVisible(false)}
        title="Создание сервисного аккаунта"
        style={{ top: 16 }}
      >
        <ServiceAccountCreationForm
          loading={creating}
          onFinish={onCreateServiceAccount}
          operators={operators}
          operatorGroups={operatorGroups}
        />
      </Modal>
      <Modal
        destroyOnClose
        footer={null}
        visible={Boolean(selectedAccount)}
        onCancel={() => setSelectedAccount(undefined)}
        width={680}
      >
        <ServiceAccountCard
          {...selectedAccount}
          operators={operators}
          operatorGroups={operatorGroups}
          editing={editing}
          secretUpdating={secretUpdating}
          onSaveCommon={(account) => onSaveCommon(selectedAccount?.id, account)}
          onSecretUpdate={() => onSecretUpdate(selectedAccount?.id)}
        />
      </Modal>
    </>
  );
};

export default ServiceAccounts;
