import api from "./api";
import { CHAT_API_URLS } from "../consts";

//post
export async function sendMessage(userID, message) {
  const url = CHAT_API_URLS.postMessage(userID);
  return await api.protectedPost(url, { message });
}

export async function sendFile(userID, formData) {
  const url = CHAT_API_URLS.postFile(userID);
  return await api.protectedPostBinary(url, formData);
}

//get
export async function getUserMessages(userID, offset = 0, limit = 20) {
  const url = CHAT_API_URLS.getMessages(userID, offset, limit);
  return await api.protectedGet(url);
}

export async function getMessage(messageID) {
  const url = CHAT_API_URLS.getMessage(messageID);
  return await api.protectedGet(url);
}

export async function getActualDialogues() {
  const url = CHAT_API_URLS.getDialogues();
  return await api.protectedGet(url);
}

const CHAT_HTTP_API = {
  sendFile,
  sendMessage,
  getUserMessages,
  getMessage,
  getActualDialogues,
};

export default CHAT_HTTP_API;
