import React from "react";
import { Form, Input, Select, Button, Divider, Typography } from "antd";

import useServiceAccount, {
  PERMORMER_FIELD_NAME,
  MAX_NAME_LENGHT,
  SELECT_PROPS,
} from "../../hooks/useServiceAccount";
import { TYPES } from "../Search_new/consts";

const { Option, OptGroup } = Select;
const { Title } = Typography;

const ServiceAccountCreationForm = ({
  loading = false,
  onFinish,
  operators = [],
  operatorGroups = [],
  initialValues = {},
}) => {
  const { formattingFormValues, serviceAccountNameValidation } = useServiceAccount();

  return (
    <Form
      onFinish={(form) => onFinish(formattingFormValues(form))}
      layout="vertical"
      initialValues={initialValues}
    >
      <Form.Item
        name="name"
        label="Название"
        rules={[
          { required: true, message: "Обязательное поле" },
          {
            max: MAX_NAME_LENGHT,
            message: `Максимальная длина ${MAX_NAME_LENGHT} символа`,
          },
          {
            validator: (_, name) => serviceAccountNameValidation(name),
          },
        ]}
        extra="Например: service_account"
      >
        <Input allowClear maxLength={MAX_NAME_LENGHT} />
      </Form.Item>

      <Form.Item
        name="description"
        label="Описание"
        rules={[{ required: true, message: "Обязательное поле" }]}
        extra="Например: Сервисный аккаунт"
      >
        <Input.TextArea allowClear />
      </Form.Item>

      <Form.Item name="from_operator_id" label="От оператора">
        <Select
          {...SELECT_PROPS}
          options={operators}
          optionFilterProp="label"
          allowClear
        />
      </Form.Item>

      <Form.Item
        name={PERMORMER_FIELD_NAME}
        label="Исполнитель"
        extra="На кого будет назначена задача. Либо на оператора, либо на группу"
      >
        <Select {...SELECT_PROPS} allowClear>
          <OptGroup label="Операторы">
            {operators.map((_operator) => (
              <Option
                value={JSON.stringify({
                  value: _operator.value,
                  type: TYPES.operator,
                })}
                children={_operator.label}
              />
            ))}
          </OptGroup>

          <OptGroup label="Группы операторов">
            {operatorGroups.map((_group) => (
              <Option
                value={JSON.stringify({
                  value: _group.value,
                  type: TYPES.group,
                })}
                children={_group.label}
              />
            ))}
          </OptGroup>
        </Select>
      </Form.Item>

      <Form.Item
        style={{
          padding: "12px 24px",
          borderRadius: 8,
          backgroundColor: "#d6e4ff",
        }}
      >
        <Title level={5}>Callback authorization</Title>
        <Form.Item name="authorization_header" label="Authorization header">
          <Input.Password allowClear />
        </Form.Item>

        <Divider>или</Divider>

        <Form.Item name="basic_username" label="Username">
          <Input.Password allowClear />
        </Form.Item>

        <Form.Item name="basic_password" label="Passwort">
          <Input.Password allowClear />
        </Form.Item>
      </Form.Item>

      <Form.Item noStyle>
        <Button children="Создать" type="primary" htmlType="submit" loading={loading} />
      </Form.Item>
    </Form>
  );
};

export default ServiceAccountCreationForm;
