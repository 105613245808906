import React from 'react';

import ExistenceSelect from './existenceSelect';
import SelectedFilter from './selectedFilter';

export const FilterContainer = (props) => {
    const {
        label,
        children = [],
        existence = undefined,
        onChangeExistence = () => {},
        selected = {},
        onRemoveSelected = () => {},
        onChangeComparison = () => {}
    } = props;

    function drawSelected(selected) {
        const selectedArray = Object.keys(selected).reduce((acc, key) => ([
            ...acc,
            { ...selected[key], key }
        ]), []);

        return selectedArray.map((selected) => (
            <SelectedFilter
                key={selected.key}
                filterLabel={selected.title}
                comparisons={selected.comparisons}
                onRemove={() => onRemoveSelected(selected.key)}
                onChange={onChangeComparison}
            />
        ));
    }

    return (
        <div className='component_filter-container'>
            <div className='filter-container__head'>
                <span className='head__label'>{label}</span>
                {existence !== undefined && (
                    <ExistenceSelect
                        value={existence.selected}
                        options={existence.options}
                        onChange={onChangeExistence}
                    />
                )}
            </div>
            {!existence?.selected && (
                <div className='filter-container__filter'>
                    {children}
                </div>            
            )}
            <div className='filter-container__selected'>
                {drawSelected(selected)}
            </div>
        </div>
    )
}