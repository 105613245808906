import React, { useEffect, useCallback, useMemo } from "react";
import { Row, Col, Typography, Button, Table } from "antd";
import { useDispatch } from "react-redux";
import moment from "moment";
import { DownloadOutlined } from "@ant-design/icons";

import Filters from "./filters";
import MainContainer from "../../components/MainContainer";

import OPERATORS_API from "../../api/operators";
import ERROR_ACTIONS from "../../actions/errors/errors";
import REPORT_PAGE_MODEL from "./model";
import REPORT_API from "../../api/report";
import LOCATIONS_API from "../../api/locations";
import { useTabs, COMPONENTS } from "../../services/tabs";
import normalizeDate from "../../utils/normalizeDate";
import getTimeDifference from "../../utils/getTimeDifference";

import "./style.scss";

const { Link } = Typography;

const FIELD_OPTIONS = [
  { value: "from_operator", label: "От оператора" },
  { value: "task_comments", label: "Комментарии к задаче" },
  { value: "issue", label: "Обращение" },
  { value: "files", label: "Файлы" },
];

const TableTitle = ({ total, count, disabled, onDownload }) => {
  return (
    <Row justify="start" gutter={[12, 0]} align="middle">
      <Col>
        <Button
          disabled={disabled}
          type="primary"
          children="Скачать отчёт"
          onClick={onDownload}
          icon={<DownloadOutlined />}
        />
      </Col>
      {typeof total === "number" && <Col>Всего: {total}</Col>}
      {typeof count === "number" && <Col>Показано: {count}</Col>}
    </Row>
  );
};

const ReportPage = (props) => {
  const dispatch = useDispatch();

  const { componentProps, setComponentProps, error } = props;
  const {
    groups,
    operators,
    filter,
    validation,
    pageLoading,
    reportFetching,
    report,
    locations,
  } = componentProps;

  const { clickHandler } = useTabs();

  const openTaskCard = useCallback(
    (taskId) => {
      clickHandler({
        component: COMPONENTS.TASK_CARD,
        params: { taskId },
        locationState: { taskId },
      });
    },
    [clickHandler]
  );

  const openUserCard = useCallback(
    (userId) => {
      clickHandler({
        component: COMPONENTS.USER_CARD,
        params: { userId },
        locationState: { userId },
      });
    },
    [clickHandler]
  );

  const openOperatorCard = useCallback(
    (operatorId) => {
      clickHandler({
        component: COMPONENTS.OPERATOR_CARD,
        params: { operatorId },
        locationState: { operatorId },
      });
    },
    [clickHandler]
  );

  const openIssueCard = useCallback(
    (issueId) => {
      clickHandler({
        component: COMPONENTS.ISSUE_CARD,
        params: { issueId },
        locationState: { issueId },
      });
    },
    [clickHandler]
  );

  const openGroupCard = useCallback(
    (groupId) => {
      clickHandler({
        component: COMPONENTS.GROUP_CARD,
        params: { groupId },
        locationState: { groupId },
      });
    },
    [clickHandler]
  );

  const memoizedCompareFilters = useMemo(() => {
    const jsonReportFilter = JSON.stringify(report.filter);
    const jsonSelectedFilter = JSON.stringify(filter);

    return jsonReportFilter === jsonSelectedFilter;
  }, [report.filter, filter]);

  const memoizedColumns = useMemo(() => {
    const columns = [
      {
        title: "Задача",
        dataIndex: "human_readable_id",
        width: "96px",
        render: (humanReadableId, task) => (
          <Link onClick={() => openTaskCard(task.id)}>{humanReadableId}</Link>
        ),
      },
      {
        title: "Пользователь",
        dataIndex: "user",
        width: "220px",
        render: (user) =>
          user ? (
            <Link onClick={() => openUserCard(user.id)}>
              {user.last_name} {user.first_name}
            </Link>
          ) : null,
      },
      {
        title: "Описание",
        dataIndex: "description",
        width: "480px",
      },
      {
        title: "Начало",
        dataIndex: "created_at",
        width: "160px",
        render: (createdAt) => normalizeDate(createdAt),
      },
      {
        title: "Выполнено",
        dataIndex: "done_at",
        width: "160px",
        render: (doneAt) => normalizeDate(doneAt),
      },
      {
        title: "Длительность",
        dataIndex: "duration",
        width: "120px",
        render: (_, task) => {
          const { hours, minutes, seconds } = getTimeDifference(
            moment(task.done_at) - moment(task.created_at)
          );
          return `${hours}:${minutes}:${seconds}`;
        },
      },
      {
        title: "Исполнитель",
        render: (task) => {
          if ("operator" in task) {
            return (
              <Link onClick={() => openOperatorCard(task.operator.id)}>
                {task.operator.last_name} {task.operator.first_name}
              </Link>
            );
          }
          if ("operators_group" in task) {
            return (
              <Link onClick={() => openGroupCard(task.operators_group.id)}>
                {task.operators_group.title}
              </Link>
            );
          }

          return "";
        },
        width: "220px",
      },
      {
        title: "Группа исполнителя",
        dataIndex: ["operators_group", "title"],
        width: "220px",
      },
      {
        title: "От оператора",
        dataIndex: "from_operator",
        width: "220px",
        render: (fromOperator) => (
          <Link onClick={() => openOperatorCard(fromOperator.id)}>
            {fromOperator.last_name} {fromOperator.first_name}
          </Link>
        ),
      },
      {
        title: "Номер ПК",
        dataIndex: ["user", "last_login_data"],
        width: "200px",
      },
      {
        title: "Местоположение",
        dataIndex: ["user", "workplace_location"],
        width: "200px",
      },
    ];

    if (report.filter?.field && "issue" in report.filter.field) {
      columns.push({
        title: "Обращение",
        dataIndex: "issue",
        width: "120px",
        render: (issue) =>
          issue ? (
            <Link onClick={() => openIssueCard(issue.id)}>{issue.human_readable_id}</Link>
          ) : null,
      });
    }

    return columns;
  }, [report.filter, openTaskCard, openUserCard, openOperatorCard, openIssueCard]);

  const getLocations = useCallback(async () => {
    try {
      const response = await LOCATIONS_API.getLocations();
      return response.data;
    } catch (err) {
      console.error(err);
      dispatch(
        ERROR_ACTIONS.addError({
          title: "Не удалось получить список локаций",
          message: err.response.data?.message,
          status: err.response.status,
        })
      );
      return [];
    }
  }, [dispatch]);

  const getGroups = useCallback(async () => {
    try {
      const response = await OPERATORS_API.getOperatorsGroupsList();
      return response.data;
    } catch (err) {
      console.error(err);
      dispatch(
        ERROR_ACTIONS.addError({
          title: "Не удалось получить список групп операторов",
          message: err.response.data?.message,
          status: err.response.status,
        })
      );
      return [];
    }
  }, [dispatch]);

  const getOperators = useCallback(async () => {
    try {
      const response = await OPERATORS_API.getOperatorsList();
      return response.data;
    } catch (err) {
      console.error(err);
      dispatch(
        ERROR_ACTIONS.addError({
          title: "Не удалось получить список операторов",
          message: err.response.data?.message,
          status: err.response.status,
        })
      );
      return [];
    }
  }, [dispatch]);

  const paramsValidation = useCallback((filters) => {
    if (
      Object.values(filters.opId).length === 0 &&
      Object.values(filters.fromOpId).length === 0 &&
      Object.values(filters.opGroupId).length === 0
    ) {
      return {
        resolution: false,
        message: "Выберите один из параметров: Оператор, Группа или От оператора",
      };
    }

    return { resolution: true, message: "" };
  }, []);

  const getReportParams = (filters) => {
    const formattingSelectedParams = (selected, key) => {
      return Object.values(selected)
        .map((_v) => `&${key}=${_v.value}`)
        .join("");
    };

    const query = [];
    const dateFormat = "YYYY-MM-DD";

    if (Object.values(filters.opId).length > 0) {
      query.push(formattingSelectedParams(filter.opId, "op_id"));
    }
    if (Object.values(filters.opGroupId).length > 0) {
      query.push(formattingSelectedParams(filters.opGroupId, "op_group_id"));
    }
    if (Object.values(filters.fromOpId).length > 0) {
      query.push(formattingSelectedParams(filters.fromOpId, "from_op_id"));
    }
    if (Object.values(filters.excludeOpId).length > 0) {
      query.push(formattingSelectedParams(filters.excludeOpId, "exclude_op_id"));
    }
    if (Object.values(filters.field).length > 0) {
      query.push(formattingSelectedParams(filters.field, "field"));
    }
    if (moment.isMoment(filters.period[0])) {
      query.push(`&date_from=${moment(filters.period[0]).format(dateFormat)}`);
    }
    if (moment.isMoment(filters.period[1])) {
      query.push(`&date_to=${moment(filters.period[1]).format(dateFormat)}`);
    }
    if (filters.onlyOpen) {
      query.push(`&open_only=${filters.onlyOpen}`);
    }
    if (Object.values(filters.locationId).length > 0) {
      query.push(formattingSelectedParams(filters.locationId, "location_id"));
    }

    return query.join("").replace("&", "");
  };

  const onGetReportPreview = async (filters) => {
    await setComponentProps({ reportFetching: true });

    try {
      const params = getReportParams(filters);
      const response = await REPORT_API.getReport(params, true);

      setComponentProps({
        report: {
          filter,
          preview: response.data.tasks,
          total: response.data.total,
        },
      });
    } catch (err) {
      console.error(err);
      dispatch(
        ERROR_ACTIONS.addError({
          title: "При формировании отчёта произошла ошибка",
          message: err.response.data?.message,
          status: err.response.status,
        })
      );
    }

    await setComponentProps({ reportFetching: false });
  };

  const onResetFilter = () => {
    setComponentProps({ filter: REPORT_PAGE_MODEL.filter });
  };

  const onChangeFilter = (filterName, value) => {
    setComponentProps({ filter: { ...filter, [filterName]: value } });
  };

  const dowloadReportFile = async (file, filename) => {
    const url = URL.createObjectURL(file);
    const link = document.createElement("a");

    link.href = url;
    link.download = filename;

    await link.click();
    link.remove();
    URL.revokeObjectURL(url);
  };

  const getReportFile = async (filters, report) => {
    await setComponentProps({ reportFetching: true });

    try {
      const params = getReportParams(filters);
      const response = await REPORT_API.getReport(params, false);
      const filename = "helpdesk_report.xlsx";

      dowloadReportFile(response.data, filename);

      setComponentProps({
        report: {
          ...report,
          file: response.data,
          filename,
          filter,
        },
      });
    } catch (err) {
      console.error(err);
      dispatch(
        ERROR_ACTIONS.addError({
          title: "При формировании отчёта произошла ошибка",
          message: err.response.data?.message,
          status: err.response.status,
        })
      );
    }

    await setComponentProps({ reportFetching: false });
  };

  useEffect(() => {
    (async () => {
      await setComponentProps({ pageLoading: true });

      const groups = await getGroups().then((groups) =>
        groups.map((_g) => ({ label: _g.title, value: _g.id }))
      );
      const operators = await getOperators().then((operators) =>
        operators.map((_op) => ({
          label: `${_op.last_name} ${_op.first_name}`,
          value: _op.id,
        }))
      );
      const locations = await getLocations().then((locations) =>
        locations.map((_l) => ({
          label: _l.title,
          value: _l.id,
        }))
      );

      await setComponentProps({ operators, groups, locations, pageLoading: false });
    })();
  }, [getGroups, getOperators, getLocations]);

  useEffect(() => {
    setComponentProps({ validation: paramsValidation(filter) });
  }, [filter]);

  return (
    <MainContainer
      className="page_report"
      bodyClassName="report__body"
      error={error}
      loading={pageLoading}
    >
      <Filters
        onChange={onChangeFilter}
        onReset={onResetFilter}
        onGetReport={() => onGetReportPreview(filter)}
        fieldOptions={FIELD_OPTIONS}
        operators={operators}
        groups={groups}
        locations={locations}
        filters={filter}
        validation={validation}
        fetching={reportFetching}
      />
      <Row className="body__main-content" justify="space-between" align="top">
        <Col span={24}>
          <Table
            title={() => (
              <TableTitle
                count={report.preview?.length}
                total={report.total}
                disabled={!(report.preview && memoizedCompareFilters)}
                onDownload={
                  report.file
                    ? () => dowloadReportFile(report.file, report.filename)
                    : () => getReportFile(filter, report)
                }
              />
            )}
            bordered
            size="small"
            dataSource={report.preview}
            columns={memoizedColumns}
            scroll={{ x: 800, y: "calc(100vh - 248px)" }}
            pagination={false}
            rowKey={(row) => row.id}
          />
        </Col>
      </Row>
    </MainContainer>
  );
};

export default ReportPage;
