import React from "react";
import { useDispatch } from "react-redux";
import { Button } from "antd";

import Table, { TABLE_STYLE } from "../../components/Table";

import { useTabs, COMPONENTS } from "../../services/tabs";
import useRBAC, { PERMISSIONS, UI_PERMISSIONS } from "../../services/rbac";
import RBAC_API from "../../api/rbac";
import errorHandling from "../../services/errors/errorHandling";
import { RBAC_ROLE_GRAPH_GETTING_ERROR } from "../../actions/errors/actionTypes";

function drawRest(strings = []) {
  if (strings.length > 3) {
    const [first, second, third, ...rest] = strings;
    return (
      <>
        {`${[first, second, third].join(", ")}`}
        {rest.length > 0 && <b> и ещё {rest.length}</b>}
      </>
    );
  } else {
    return strings.join(", ");
  }
}

const RolesList = (props) => {
  const { loading, roles, onRowClick } = props;

  const dispatch = useDispatch();

  // Hooks
  const { clickHandler } = useTabs();
  const { checkAccess, checkPermissionToRenderUI } = useRBAC();

  const COLUMNS = [
    {
      title: "Роль",
      dataIndex: "id",
      width: 280,
    },
    {
      title: "Разрешения",
      dataIndex: "permissions",
      render: drawRest,
    },
    {
      title: "Родительские роли",
      dataIndex: "parents",
      render: (text) => text.join(", "),
      width: 400,
    },
  ];

  async function getRoleSvgGraph(withPerms = false) {
    await RBAC_API.getSvgRolesTree([], withPerms)
      .then((resp) => {
        const blob = new Blob([resp.data], { type: "image/svg+xml" });
        const url = URL.createObjectURL(blob);

        window.open(url);
      })
      .catch((err) =>
        dispatch(errorHandling(err, RBAC_ROLE_GRAPH_GETTING_ERROR))
      );
  }

  function handleRowClick() {
    const requiredPermissions = [
      PERMISSIONS["rbac:roles:remove_parent"],
      PERMISSIONS["rbac:roles:inherit"],
    ];

    if (requiredPermissions.every((p) => !checkAccess(p))) {
      return () => {};
    } else {
      return onRowClick;
    }
  }

  if (
    checkPermissionToRenderUI(
      UI_PERMISSIONS["ui:management:roles:list:delete-role"]
    )
  ) {
    COLUMNS.push({
      width: 110,
      render: () => (
        <Button disabled danger htmlType="button" children="Удалить" />
      ),
    });
  }

  return (
    <div className="body__roles">
      <Table
        loading={loading}
        scroll={{ y: "calc(100vh - 284px)" }}
        data={roles}
        columns={COLUMNS}
        onRowClick={handleRowClick()}
        tableStyle={TABLE_STYLE.zebra}
        title={() => (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {checkPermissionToRenderUI(
              UI_PERMISSIONS["ui:management:roles:list:roles-full-graph"]
            ) && (
              <div style={{ marginLeft: -11 }}>
                <h5 style={{ marginBottom: "unset", textIndent: "15px" }}>
                  Полный граф ролей
                </h5>
                <Button
                  type="link"
                  htmlType="button"
                  children="Показать только роли"
                  onClick={() => getRoleSvgGraph()}
                />
                <Button
                  type="link"
                  size="small"
                  htmlType="buttom"
                  children="Показать роли с разрешениями"
                  onClick={() => getRoleSvgGraph(true)}
                />
              </div>
            )}
            {checkPermissionToRenderUI(
              UI_PERMISSIONS["ui:management:roles:list:create"]
            ) && (
              <Button
                type="primary"
                htmlType="button"
                children="Создать"
                onClick={() =>
                  clickHandler({ component: COMPONENTS.ROLE_CREATION_FORM })
                }
              />
            )}
          </div>
        )}
      />
    </div>
  );
};

export default RolesList;
