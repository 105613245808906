import React, { Component } from "react";
import { Form, Input, Button, message } from "antd";

import MainContainer from "../../components/MainContainer";
import { createOperatorsGroup } from "../../api/operators";

import "./style.scss";

const success = () => {
  message.success("Группа успешно создана");
};

const error = () => {
  message.error("При создании группы произошла ошибка");
};

const Header = () => {
  return (
    <>
      <h2 className="header__title">Создание группы операторов</h2>
    </>
  );
};

class GroupCreationForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sending: false,
    };
    this.formRef = React.createRef();
    this.createGroup = this.createGroup.bind(this);
  }

  async createGroup(form) {
    this.setState({ sending: true });

    await createOperatorsGroup(form.title)
      .then(({ status }) => {
        if (status === 201) return success();

        return error();
      })
      .catch(() => error());

    this.setState({ sending: false });
    this.formRef.current.resetFields();
  }

  render() {
    const { sending } = this.state;

    return (
      <MainContainer
        className="card_group-creation"
        headerClassName="group-creation__header"
        header={<Header />}
      >
        <Form
          style={{ maxWidth: 400 }}
          ref={this.formRef}
          onFinish={this.createGroup}
          layout="vertical"
        >
          <Form.Item
            label="Название группы:"
            name="title"
            rules={[
              { required: true, message: "Поле обязательно для заполнения" },
            ]}
          >
            <Input placeholder="Введите название группы..." />
          </Form.Item>

          <Button htmlType="submit" type="primary" loading={sending}>
            Создать
          </Button>
        </Form>
      </MainContainer>
    );
  }
}

export default GroupCreationForm;
