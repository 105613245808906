import { COMPONENTS } from "../tabs";

import taskAssignedToOperator from "./notifications/taskAssignedToOperator";
import taskAssignedToOperatorsGroup from "./notifications/taskAssignedToOperatorsGroup";
import issueClosed from "./notifications/issueClosed";
import taskClosed from "./notifications/taskClosed";
import allIssueTasksClosed from "./notifications/allIssueTasksClosed";
import issueCreated from "./notifications/issueCreated";
import operatorTaskCreated from "./notifications/operatorTaskCreated";
import operatorsGroupTaskCreated from "./notifications/operatorsGroupTaskCreated";
import newOperatorMessage from "./notifications/newOperatorMessage";
import newUserMessage from "./notifications/newUserMessage";
import issueCommentPosted from "./notifications/issueCommentPosted";
import issueTransferred from "./notifications/issueTransferred";
import massProblemCreated from "./notifications/massProblemCreated";
import massProblemClosed from "./notifications/massProblemClosed";
import taskCommentPosted from "./notifications/taskCommentPosted";
import massProblemCommentPosted from "./notifications/massProblemCommentPosted";
import taskReopened from "./notifications/taskReopened";

export const NOTIFICATIONS_TYPES = {
  TASK_ASSIGNED_TO_OPERATOR: "task_assigned_to_operator",
  TASK_ASSIGNED_TO_OPERATORS_GROUP: "task_assigned_to_operators_group",
  ISSUE_CLOSED: "issue_closed",
  TASK_CLOSED: "task_closed",
  TASK_REOPENED: "task_reopened",
  ALL_ISSUE_TASKS_CLOSED: "all_issue_tasks_closed",
  ISSUE_CREATED: "issue_created",
  EMPTY_ISSUE_CREATED: "empty_issue_created",
  OPERATOR_TASK_CREATED: "operator_task_created",
  OPERATORS_GROUP_TASK_CREATED: "operators_group_task_created",
  NEW_OPERATOR_MESSAGE: "new_operator_message",
  NEW_USER_MESSAGE: "new_user_message",
  ISSUE_COMMENT_POSTED: "issue_comment_posted",
  ISSUE_TRANSFERRED: "issue_transferred",
  TASK_COMMENT_POSTED: "task_comment_posted",
  MASS_PROBLEM_CREATED: "mass_problem_created",
  MASS_PROBLEM_COMMENT_POSTED: "mass_problem_comment_posted",
  MASS_PROBLEM_CLOSED: "mass_problem_closed",
};

const NOTIFICATIONS = {
  [NOTIFICATIONS_TYPES.TASK_ASSIGNED_TO_OPERATOR]: (data) =>
    taskAssignedToOperator(data),
  [NOTIFICATIONS_TYPES.TASK_ASSIGNED_TO_OPERATORS_GROUP]: (data) =>
    taskAssignedToOperatorsGroup(data),
  [NOTIFICATIONS_TYPES.ISSUE_CLOSED]: (data) => issueClosed(data),
  [NOTIFICATIONS_TYPES.TASK_CLOSED]: (data) => taskClosed(data),
  [NOTIFICATIONS_TYPES.TASK_REOPENED]: (data) => taskReopened(data),
  [NOTIFICATIONS_TYPES.ALL_ISSUE_TASKS_CLOSED]: (data) =>
    allIssueTasksClosed(data),
  [NOTIFICATIONS_TYPES.ISSUE_CREATED]: (data) => issueCreated(data),
  [NOTIFICATIONS_TYPES.EMPTY_ISSUE_CREATED]: (data) => issueCreated(data),
  [NOTIFICATIONS_TYPES.OPERATOR_TASK_CREATED]: (data) =>
    operatorTaskCreated(data),
  [NOTIFICATIONS_TYPES.OPERATORS_GROUP_TASK_CREATED]: (data) =>
    operatorsGroupTaskCreated(data),
  [NOTIFICATIONS_TYPES.NEW_OPERATOR_MESSAGE]: (data) =>
    newOperatorMessage(data),
  [NOTIFICATIONS_TYPES.NEW_USER_MESSAGE]: (data) => newUserMessage(data),
  [NOTIFICATIONS_TYPES.ISSUE_COMMENT_POSTED]: (data) =>
    issueCommentPosted(data),
  [NOTIFICATIONS_TYPES.ISSUE_TRANSFERRED]: (data) => issueTransferred(data),
  [NOTIFICATIONS_TYPES.TASK_COMMENT_POSTED]: (data) => taskCommentPosted(data),
  [NOTIFICATIONS_TYPES.MASS_PROBLEM_CREATED]: (data) =>
    massProblemCreated(data),
  [NOTIFICATIONS_TYPES.MASS_PROBLEM_COMMENT_POSTED]: (data) =>
    massProblemCommentPosted(data),
  [NOTIFICATIONS_TYPES.MASS_PROBLEM_CLOSED]: (data) => massProblemClosed(data),
};

/**
 * Получаем параметры для открытия вкладки при клике по уведомлению
 * @param {object} notification
 */
export function getParamsToOpenTab(notification) {
  switch (notification.type) {
    case NOTIFICATIONS_TYPES.ALL_ISSUE_TASKS_CLOSED:
    case NOTIFICATIONS_TYPES.ISSUE_CLOSED:
    case NOTIFICATIONS_TYPES.ISSUE_COMMENT_POSTED:
    case NOTIFICATIONS_TYPES.ISSUE_CREATED:
    case NOTIFICATIONS_TYPES.EMPTY_ISSUE_CREATED:
      return {
        component: COMPONENTS.ISSUE_CARD,
        params: { issueId: notification?.issue_id },
        locationState: { issueId: notification?.issue_id },
      };
    case NOTIFICATIONS_TYPES.MASS_PROBLEM_CLOSED:
    case NOTIFICATIONS_TYPES.MASS_PROBLEM_COMMENT_POSTED:
    case NOTIFICATIONS_TYPES.MASS_PROBLEM_CREATED:
      return {
        component: COMPONENTS.MASS_PROBLEM_CARD,
        params: { massProblemId: notification?.mass_problem_id },
        locationState: { massProblemId: notification?.mass_problem_id },
      };
    case NOTIFICATIONS_TYPES.NEW_OPERATOR_MESSAGE:
    case NOTIFICATIONS_TYPES.NEW_USER_MESSAGE:
      return {
        component: COMPONENTS.MESSANGER,
        locationState: {
          selectedDialogue: notification?.user_id,
        },
        params: {
          selectedDialogue: notification?.user_id,
        },
      };
    case NOTIFICATIONS_TYPES.OPERATORS_GROUP_TASK_CREATED:
    case NOTIFICATIONS_TYPES.OPERATOR_TASK_CREATED:
    case NOTIFICATIONS_TYPES.TASK_ASSIGNED_TO_OPERATOR:
    case NOTIFICATIONS_TYPES.TASK_ASSIGNED_TO_OPERATORS_GROUP:
    case NOTIFICATIONS_TYPES.TASK_CLOSED:
    case NOTIFICATIONS_TYPES.TASK_COMMENT_POSTED:
    case NOTIFICATIONS_TYPES.TASK_REOPENED:
      return {
        component: COMPONENTS.TASK_CARD,
        params: { taskId: notification?.task_id },
        locationState: { taskId: notification?.task_id },
      };
    default:
      return {};
  }
}

export default NOTIFICATIONS;
