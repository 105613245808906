import React, { useState, useEffect } from "react";
import { Input, Button, Radio, Checkbox, Select } from "antd";
import { EditOutlined } from "@ant-design/icons";

import "./style.scss";

/**
 * @enum Типы полей
 */
export const EDITABLE_FIELD_TYPES = {
  input: "input",
  textarea: "textarea",
  radio: "radio",
  checkbox: "checkbox",
  select: "select",
  search: "search",
};

/**
 * @param {object} props
 * @param {string} props.name
 * @param {string} props.placeholder
 * @param {boolean} props.isEdit Булев флаг, который показывает редактируется поле или нет
 * @param {string|Array} props.value Значение поля
 * @param {Function} props.saveChanges Функция вызывающаяся при сохранении изменений
 * @param {Function} props.editField Функция, которая переключает режимы (редактирование/просмотр)
 * @param {boolean} props.disabled
 * @param {object} props.params Свойства, которые будут переданы компоненту отвечающему за редактирование
 * @param {EDITABLE_FIELD_TYPES} props.type Тип поля, которое будет отображено в режиме редактирования
 */
const EditableCardField = (props) => {
  const {
    name,
    isEdit,
    disabled,
    placeholder = "",
    saveChanges = () => {},
    editField,
    type,
    params = {},
  } = props;

  const [value, onChangeValue] = useState(props.value);

  function saveAndHideInput(newValue = value) {
    onChangeValue(newValue);
    saveChanges(newValue);
    editField(name);
  }

  function hideInput() {
    onChangeValue(props.value);
    editField(name);
  }

  function drawFieldValue() {
    if (disabled) {
      return (
        <span className="editable-card-field_disabled">
          {getFieldValue(props.value)}
        </span>
      );
    }

    if (
      String(props.value) &&
      props.value !== null &&
      props.value !== undefined
    ) {
      return (
        <span className="editable-card-field" onClick={() => editField(name)}>
          {getFieldValue(props.value)}
        </span>
      );
    } else {
      return (
        <Button
          type="text"
          size="small"
          icon={<EditOutlined />}
          onClick={() => editField(name)}
        />
      );
    }
  }

  function getFieldValue(value) {
    if (!value) return placeholder;

    switch (type) {
      case EDITABLE_FIELD_TYPES.checkbox:
        return getLabelForCheckbox(params.options, value);
      case EDITABLE_FIELD_TYPES.select: {
        if (!Array.isArray(params.options)) return "Значение не найдено";

        return getLabelForSelect(params.options, value);
      }
      case EDITABLE_FIELD_TYPES.radio: {
        if (!Array.isArray(params.options)) {
          return "Значение не найдено";
        }

        return getValueFromOptions(params.options, value);
      }
      case EDITABLE_FIELD_TYPES.input:
      case EDITABLE_FIELD_TYPES.textarea:
      default:
        return String(value);
    }
  }

  function getLabelForSelect(options, value) {
    function deepFind(options, value) {
      for (let i = 0; i < options.length; i++) {
        if ("value" in options[i] && options[i].value === value) {
          return options[i];
        }
        if ("options" in options[i]) {
          const selected = deepFind(options[i].options, value);

          if (selected !== undefined) return selected;
        }
      }

      return undefined;
    }

    const selected = deepFind(options, value);

    return selected !== undefined ? selected.label : "Значение не найдено";
  }

  function getLabelForCheckbox(options, values) {
    if (!values) return "Значение не найдено";
    if (!Array.isArray(values)) return "Значение не найдено";

    const checked = options.filter((option) => values.includes(option.value));

    return checked.length > 0
      ? checked.map((option) => option.label).join(", ")
      : "Значение не найдено";
  }

  function getValueFromOptions(options = [], value) {
    if (Array.isArray(value)) {
      const selected = options.filter((option) => value.includes(option.value));

      return selected.length > 0
        ? selected.map((option) => option.label).join(", ")
        : "Значение не найдено";
    } else {
      const selected = options.find((option) => option.value === value);

      return selected !== undefined
        ? String(selected.label)
        : "Значение не найдено";
    }
  }

  // TODO: подумать над названием функции. Возвращает компонент, который редактирует значение
  function getEditorComponent() {
    switch (type) {
      case EDITABLE_FIELD_TYPES.checkbox:
        return (
          <Checkbox.Group
            name={name}
            className="editable-card-field__checkbox"
            value={value}
            onChange={onChangeValue}
            {...params}
          />
        );
      case EDITABLE_FIELD_TYPES.radio:
        return (
          <Radio.Group
            name={name}
            className="editable-card-field__radio"
            value={value}
            onChange={(e) => onChangeValue(e.target.value)}
            {...params}
          />
        );
      case EDITABLE_FIELD_TYPES.select:
        return (
          <Select
            autoFocus
            showSearch
            style={{ minWidth: 180 }}
            placeholder="Не выбрано"
            name={name}
            className="editable-card-field__select"
            value={value}
            onChange={saveAndHideInput}
            {...params}
          />
        );
      case EDITABLE_FIELD_TYPES.search:
        return <Select />;
      case EDITABLE_FIELD_TYPES.textarea:
        return (
          <Input.TextArea
            name={name}
            autoFocus
            className="editable-card-field__textarea"
            value={value}
            onChange={(e) => onChangeValue(e.target.value)}
            {...params}
          />
        );
      case EDITABLE_FIELD_TYPES.input:
      default:
        return (
          <Input
            name={name}
            autoFocus
            className="editable-card-field__input"
            value={value}
            onChange={(e) => onChangeValue(e.target.value)}
            onPressEnter={() => saveAndHideInput()}
            {...params}
          />
        );
    }
  }

  function drawEdit() {
    return (
      <div className="editable-card-field_edit-state">
        {getEditorComponent()}
        <div className="editable-card-field__action-buttons">
          <Button
            size="small"
            type="primary"
            htmlType="button"
            children="Сохранить"
            onClick={() => saveAndHideInput()}
          />
          <Button
            danger
            size="small"
            htmlType="button"
            type="link"
            children="Отмена"
            onClick={hideInput}
          />
        </div>
      </div>
    );
  }

  // componentDidUpdate
  useEffect(() => {
    onChangeValue(props.value);
  }, [props.value]);

  return (
    <div className="component_editable-card-field">
      {isEdit ? drawEdit() : drawFieldValue()}
    </div>
  );
};

export default EditableCardField;
