import React from "react";
import { Button } from "antd";

const GoTo = ({ onClick }) => {
  return (
    <Button type="primary" onClick={onClick}>
      Перейти
    </Button>
  );
};

export default ({ onOpenTab }) => {
  return {
    message: "Новый комментарий к обращению",
    btn: <GoTo onClick={onOpenTab} />,
  };
};
