import createUUID from "../../utils/createUUID";
import { getComponent, getTabTitle, COMPONENTS } from "../../services/tabs";

import TASK_LIST_MODEL from "../../containers/TaskList/model";
import ISSUE_LIST_MODEL from "../../containers/AppealsList/model";
import REPORT_PAGE_MODEL from "../../containers/ReportPage/model";

// TODO: добавить модели для всех компонентов, которые открываются внутри вкладки
function getComponentPropsModel(component) {
  switch (component) {
    case COMPONENTS.ISSUE_LIST:
      return ISSUE_LIST_MODEL;
    case COMPONENTS.TASK_LIST:
      return TASK_LIST_MODEL;
    case COMPONENTS.REPORT:
      return REPORT_PAGE_MODEL;
    default:
      return null;
  }
}

function getTabModel(tab) {
  const { componentName, url } = tab;
  const tabModel = {
    url,
    uuid: createUUID(),
    title: getTabTitle(componentName),
    component: {
      props: getComponentPropsModel(componentName),
      name: componentName,
      component: getComponent(componentName),
    },
    history: {
      previous: [],
      next: [],
    },
    error: null,
  };

  return tabModel;
}

export default getTabModel;
