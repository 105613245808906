import React, { useEffect } from "react";
// import { useDispatch } from "react-redux";
import { Row, Col, Typography, Divider, Drawer } from "antd";

import MainTable, { TABLE_STYLE } from "../../components/Table";
import HardwareChanges from "../../components/HardwareChanges";

import useHardware from "../../hooks/useHardware.hook";
import normalizeDate, { DATE_TIME } from "../../utils/normalizeDate";
// import ERROR_ACTIONS from "../../actions/errors/errors";

const { Title } = Typography;

const COLUMNS = [
  {
    title: "Идентификатор",
    dataIndex: "identifier",
  },
  {
    width: 160,
    title: "Создано",
    dataIndex: "created_at",
    render: (value) => normalizeDate(value, DATE_TIME),
  },
  {
    width: 160,
    title: "Обновлено",
    dataIndex: "updated_at",
    render: (value) => normalizeDate(value, DATE_TIME),
  },
];

const HardwareSection = (props) => {
  const { userId, permissions } = props;
  const { list } = permissions;

  // const dispatch = useDispatch();

  const {
    hardwareLoading,
    hardwares,
    selectedHardware,
    hardwareChangesVisible,
    hardwareChangeText,
    editingHardwareChange,
    searchHardware,
    getSelectedHardware,
    onPostChange,
    onEditChange,
    onRemoveHardwareChange,
    toggleEditingHardwareChange,
    setHardwareChangesVisible,
    getHardwareChanges,
    setHardwareLoading,
    setSelectedHardware,
    setHardwareChangeText,
  } = useHardware();

  const rowClickHandler = async (hardwares, hardwareId) => {
    await setHardwareChangesVisible(true);
    await setHardwareLoading(true);

    const _selectedHardware = getSelectedHardware(hardwares, hardwareId);

    if (_selectedHardware) {
      _selectedHardware.hardwareChanges = await getHardwareChanges(hardwareId);

      setSelectedHardware(_selectedHardware);
    } else {
      setSelectedHardware(undefined);
    }
    await setHardwareLoading(false);
  };

  useEffect(() => {
    (async () => {
      await searchHardware("", userId);
    })();
  }, [userId, searchHardware]);

  return (
    <>
      <Divider />
      <Row>
        <Col>
          <Title level={5} style={{ textIndent: 20 }}>
            Оборудование пользователя
          </Title>
        </Col>
        <Col>
          <MainTable
            data={hardwares}
            columns={COLUMNS}
            loading={hardwareLoading}
            tableStyle={TABLE_STYLE.zebra}
            onRowClick={
              list ? (_, row) => rowClickHandler(hardwares, row.id) : () => {}
            }
          />
        </Col>
      </Row>
      <Divider />

      <Drawer
        width={"auto"}
        bodyStyle={{ overflowY: "hidden" }}
        title={`История изменений ${selectedHardware?.identifier}`}
        visible={hardwareChangesVisible}
        onClose={() => setHardwareChangesVisible(false)}
      >
        <HardwareChanges
          isEditing={Boolean(editingHardwareChange)}
          text={hardwareChangeText}
          loading={hardwareLoading}
          hardware={selectedHardware}
          onOk={
            editingHardwareChange
              ? () => onEditChange(editingHardwareChange, hardwareChangeText)
              : () => onPostChange(selectedHardware?.id, hardwareChangeText)
          }
          onChangeText={setHardwareChangeText}
          onRemoveChange={onRemoveHardwareChange}
          toggleEditing={toggleEditingHardwareChange}
          permissions={permissions}
        />
      </Drawer>
    </>
  );
};

export default HardwareSection;
