// Сброс ошибки
export const RESET_ERROR = "RESET_ERROR";

// Ошибки приложения
export const CONNECTION_LOST = "CONNECTION_LOST";

// Ошибки авторизации
export const LOGIN_ERROR = "LOGIN_ERROR";
export const REFRESH_ERROR = "REFRESH_ERROR";
export const TOKEN_EXPIRED = "TOKEN_EXPIRED";

// Ошибки обращений
export const ISSUE_LIST_GETTING_ERROR = "ISSUE_LIST_GETTING_ERROR";
export const ISSUE_CREATION_ERROR = "ISSUE_CREATION_ERROR";
export const ISSUE_GETTING_ERROR = "ISSUE_GETTING_ERROR";
export const ISSUE_DESCRIPTION_EDITING_ERROR =
  "ISSUE_DESCRIPTION_EDITING_ERROR";
export const ISSUE_OPERATOR_CHANGING_ERROR = "ISSUE_OPERATOR_CHANGING_ERROR";
export const ISSUE_CLOSING_ERROR = "ISSUE_CLOSING_ERROR";
export const ISSUE_GETTING_COMMENTS_ERROR = "ISSUE_GETTING_COMMENTS_ERROR";
export const ISSUE_POSTING_COMMENT_ERROR = "ISSUE_POSTING_COMMENT_ERROR";
export const ISSUE_EDITING_COMMENT_ERROR = "ISSUE_EDITING_COMMENT_ERROR";
export const ISSUE_FILE_DELETING_ERROR = "ISSUE_FILE_DELETING_ERROR";
export const GETTING_RELATED_TASKS_ERROR = "GETTING_RELATED_TASKS_ERROR";
export const ISSUE_UPLOAD_FILES_ERROR = "ISSUE_UPLOAD_FILES_ERROR";

// Ошибки задач
export const TASK_LIST_GETTING_ERROR = "TASK_LIST_GETTING_ERROR";
export const TASK_CREATION_ERROR = "TASK_CREATION_ERROR";
export const TASK_GETTING_ERROR = "TASK_GETTING_ERROR";
export const TASK_DESCRIPTION_EDITING_ERROR = "TASK_DESCRIPTION_EDITING_ERROR";
export const TASK_PERFORMER_CHANGING_ERROR = "TASK_PERFORMER_CHANGING_ERROR";
export const TASK_CLOSING_ERROR = "TASK_CLOSING_ERROR";
export const TASK_UPLOAD_FILES_ERROR = "TASK_UPLOAD_FILES_ERROR";

// Ошибки файлов
export const FILE_UPLOADING_ERROR = "FILE_UPLOADING_ERROR";
export const FILE_DOWNLOADING_ERROR = "FILE_DOWNLOADING_ERROR";
export const IMAGE_PREVIEW_DOWNLOADING_ERROR =
  "IMAGE_PREVIEW_DOWNLOADING_ERROR";

// Ошибки операторов
export const OPERATOR_CREATION_ERROR = "OPERATOR_CREATION_ERROR";
export const OPERATOR_GETTING_ERROR = "OPERATOR_GETTING_ERROR";
export const OPERATOR_NAME_CHANGING_ERROR = "OPERATOR_NAME_CHANGING_ERROR";
export const OPERATOR_ADMIN_SIGN_CHANGING_ERROR =
  "OPERATOR_ADMIN_SIGN_CHANGING_ERROR";
export const OPERATOR_FIRSTLINE_SIGN_CHANGING_ERROR =
  "OPERATOR_FIRSTLINE_SIGN_CHANGING_ERROR";
export const OPERATOR_STATUS_CHANGING_ERROR = "OPERATOR_STATUS_CHANGING_ERROR";
export const OPERATOR_GROUP_CHANGING_ERROR = "OPERATOR_GROUP_CHANGING_ERROR";

// Ошибки групп операторов
export const GROUP_CREATION_ERROR = "GROUP_CREATION_ERROR";
export const GROUP_NAME_EDITING_ERROR = "GROUP_NAME_EDITING_ERROR";
export const GROUP_GETTING_ERROR = "GROUP_GETTING_ERROR";
export const GROUP_DELETING_ERROR = "GROUP_DELETING_ERROR";

// Ошибки пользователей
export const USER_CREATION_ERROR = "USER_CREATION_ERROR";
export const USER_GETTING_ERROR = "USER_GETTING_ERROR";
export const USER_SEARCH_ERROR = "USER_SEARCH_ERROR";
export const USER_WORKPLACE_CHANGING_ERROR = "USER_WORKPLACE_CHANGING_ERROR";
export const USER_WORKSTATION_NUMBER_CHANGING_ERROR =
  "USER_WORKSTATION_NUMBER_CHANGING_ERROR";

// Ошибки сообщений
export const USER_MESSAGES_GETTING_ERROR = "USER_MESSAGES_GETTING_ERROR";
export const DIALOGS_GETTING_ERROR = "DIALOGS_GETTING_ERROR";
export const INCOMING_MESSAGE_HANDLING_ERROR =
  "INCOMING_MESSAGE_HANDLING_ERROR";

// Ошибки уведомлений
export const CONNECTION_OPENING_ERROR = "CONNECTION_OPENING_ERROR";

// Ошибки массовых проблем
export const MASS_PROBLEM_LIST_GETTING_ERROR =
  "MASS_PROBLEM_LIST_GETTING_ERROR";
export const MASS_PROBLEM_GETTING_ERROR = "MASS_PROBLEM_GETTING_ERROR";
export const MASS_PROBLEM_DELETE_ISSUE_ERROR =
  "MASS_PROBLEM_DELETE_ISSUE_ERROR";
export const MASS_PROBLEM_ADD_NEW_ISSUE_ERROR =
  "MASS_PROBLEM_ADD_NEW_ISSUE_ERROR";
export const MASS_PROBLEM_UPLOAD_FILES_ERROR =
  "MASS_PROBLEM_UPLOAD_FILES_ERROR";
export const MASS_PROBLEM_DESCRIPTION_EDITING_ERROR =
  "MASS_PROBLEM_DESCRIPTION_EDITING_ERROR";
export const MASS_PROBLEM_CLOSING_ERROR = "MASS_PROBLEM_CLOSING_ERROR";

// Ошибки LDAP свойств
export const LDAP_PROP_CREATION_ERROR = "LDAP_PROP_CREATION_ERROR";
export const LDAP_PROP_DELETION_ERROR = "LDAP_PROP_DELETION_ERROR";
export const LDAP_PROP_PATCH_ERROR = "LDAP_PROP_PATCH_ERROR";

// Ошибки RBAC
export const RBAC_ROLE_GRAPH_GETTING_ERROR = "RBAC_ROLE_GRAPH_GETTING_ERROR";

// Ошибки локаций
export const LOCATION_DELETE_ERROR = "LOCATION_DELETE_ERROR";

// Новый сервис ошибок
export const ADD_ERROR = "ADD_ERROR";
export const REMOVE_ERROR = "REMOVE_ERROR";

export const ERRORS_DICT = {
  RESET_ERROR,

  LOGIN_ERROR,
  REFRESH_ERROR,
  TOKEN_EXPIRED,

  ISSUE_LIST_GETTING_ERROR,
  ISSUE_CREATION_ERROR,
  ISSUE_GETTING_ERROR,
  ISSUE_DESCRIPTION_EDITING_ERROR,
  ISSUE_OPERATOR_CHANGING_ERROR,
  ISSUE_CLOSING_ERROR,
  ISSUE_GETTING_COMMENTS_ERROR,
  ISSUE_POSTING_COMMENT_ERROR,
  ISSUE_EDITING_COMMENT_ERROR,
  ISSUE_FILE_DELETING_ERROR,
  GETTING_RELATED_TASKS_ERROR,
  ISSUE_UPLOAD_FILES_ERROR,

  TASK_LIST_GETTING_ERROR,
  TASK_CREATION_ERROR,
  TASK_GETTING_ERROR,
  TASK_DESCRIPTION_EDITING_ERROR,
  TASK_PERFORMER_CHANGING_ERROR,
  TASK_CLOSING_ERROR,
  TASK_UPLOAD_FILES_ERROR,

  FILE_UPLOADING_ERROR,
  FILE_DOWNLOADING_ERROR,
  IMAGE_PREVIEW_DOWNLOADING_ERROR,

  OPERATOR_CREATION_ERROR,
  OPERATOR_GETTING_ERROR,
  OPERATOR_NAME_CHANGING_ERROR,
  OPERATOR_ADMIN_SIGN_CHANGING_ERROR,
  OPERATOR_FIRSTLINE_SIGN_CHANGING_ERROR,
  OPERATOR_STATUS_CHANGING_ERROR,
  OPERATOR_GROUP_CHANGING_ERROR,

  GROUP_CREATION_ERROR,
  GROUP_GETTING_ERROR,
  GROUP_NAME_EDITING_ERROR,
  GROUP_DELETING_ERROR,

  USER_CREATION_ERROR,
  USER_GETTING_ERROR,
  USER_SEARCH_ERROR,
  USER_WORKPLACE_CHANGING_ERROR,
  USER_WORKSTATION_NUMBER_CHANGING_ERROR,

  USER_MESSAGES_GETTING_ERROR,
  DIALOGS_GETTING_ERROR,
  INCOMING_MESSAGE_HANDLING_ERROR,

  CONNECTION_OPENING_ERROR,
  CONNECTION_LOST,

  MASS_PROBLEM_LIST_GETTING_ERROR,
  MASS_PROBLEM_GETTING_ERROR,
  MASS_PROBLEM_CLOSING_ERROR,
  MASS_PROBLEM_UPLOAD_FILES_ERROR,
  MASS_PROBLEM_DELETE_ISSUE_ERROR,
  MASS_PROBLEM_ADD_NEW_ISSUE_ERROR,
  MASS_PROBLEM_DESCRIPTION_EDITING_ERROR,

  LDAP_PROP_CREATION_ERROR,
  LDAP_PROP_DELETION_ERROR,
  LDAP_PROP_PATCH_ERROR,

  RBAC_ROLE_GRAPH_GETTING_ERROR,

  LOCATION_DELETE_ERROR,
  ADD_ERROR,
  REMOVE_ERROR,
};

export default ERRORS_DICT;
