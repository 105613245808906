import React from "react";
import { Row, Col } from "antd";

import MainContainer from "../../components/MainContainer";
import EditableCardField, {
  EDITABLE_FIELD_TYPES,
} from "../../components/EditableCardField";

const breakpoints = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
  xl: 8,
  xxl: 8,
};

const Header = () => {
  return (
    <>
      <h2 className="header__title">Информация об операторе</h2>
    </>
  );
};

const Layout = (props) => {
  const {
    uiPermissions,
    error,
    loading,
    roles,
    operator,
    switches,
    groups,
    changeGroup,
    setOperatorRoles,
    switchEditableField,
  } = props;

  return (
    <MainContainer
      error={error}
      loading={loading}
      className="page_operator-card"
      headerClassName="operator-card__header"
      header={<Header />}
    >
      <Row gutter={[10, 10]}>
        <Col {...breakpoints}>
          <div className="operator-card__card-field">
            <label className="card-field__title">Фамилия:</label>
            <EditableCardField
              disabled
              name="lastName"
              type={EDITABLE_FIELD_TYPES.input}
              value={operator.last_name}
              isEdit={switches.lastName}
              params={{ allowClear: true }}
              editField={switchEditableField}
              saveChanges={(lastName) => lastName}
            />
          </div>
        </Col>
        <Col {...breakpoints}>
          <div className="operator-card__card-field">
            <label className="card-field__title">Имя:</label>
            <EditableCardField
              disabled
              name="firstName"
              type={EDITABLE_FIELD_TYPES.input}
              value={operator.first_name}
              isEdit={switches.firstName}
              params={{ allowClear: true }}
              editField={switchEditableField}
              saveChanges={(firstName) => firstName}
            />
          </div>
        </Col>
        <Col {...breakpoints}>
          <div className="operator-card__card-field">
            <label className="card-field__title">Роли:</label>
            <EditableCardField
              name="roles"
              type={EDITABLE_FIELD_TYPES.checkbox}
              disabled={!uiPermissions.roles}
              value={operator.roles}
              isEdit={switches.roles}
              params={{ options: roles }}
              editField={switchEditableField}
              saveChanges={(roles) => setOperatorRoles(operator.id, roles)}
            />
          </div>
        </Col>
        <Col {...breakpoints}>
          <div className="operator-card__card-field">
            <label className="card-field__title">Группа:</label>
            <EditableCardField
              name="group"
              type={EDITABLE_FIELD_TYPES.select}
              disabled={!uiPermissions.group}
              value={operator.operators_group_id}
              isEdit={switches.group}
              params={{ options: groups }}
              editField={switchEditableField}
              saveChanges={(groupId) => changeGroup(operator.id, groupId)}
            />
          </div>
        </Col>
      </Row>
    </MainContainer>
  );
};

export default Layout;
