import React from "react";
import { Button } from "antd";
import { CloseOutlined } from "@ant-design/icons";

import ComparisonsSelect from "./comparisonsSelect";

const SelectedFilter = ({
  filterLabel = "",
  comparisons = {},
  onChange = () => {},
  onRemove = undefined,
}) => {
  return (
    <div className="component_selected-filter">
      {Object.keys(comparisons).length > 0 && (
        <ComparisonsSelect
          options={comparisons.options}
          value={comparisons.selected}
          onChange={onChange}
        />
      )}
      <p title={filterLabel} className="selected-filter__label">
        <span className="label__text">{filterLabel}</span>
        {typeof onRemove === "function" && (
          <Button
            className="label__button_close"
            type="text"
            size="small"
            icon={<CloseOutlined />}
            onClick={onRemove}
          />
        )}
      </p>
    </div>
  );
};

export default SelectedFilter;
