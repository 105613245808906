import React from "react";
import { Button } from "antd";

import { getOperatorData } from "../../../api/operators";

const GoTo = ({ onClick }) => {
  return (
    <Button type="primary" onClick={onClick}>
      Перейти к задаче
    </Button>
  );
};

export default async (data) => {
  const { operator_id, onOpenTab } = data;
  let operatorName;

  await getOperatorData(operator_id)
    .then(({ data }) => (operatorName = `${data.last_name} ${data.first_name}`))
    .catch(() => (operatorName = "Оператор не найден"));

  return {
    message: "Задача переназначена",
    description: `Задача назначена на ${operatorName}`,
    btn: <GoTo onClick={onOpenTab} />,
  };
};
