import React from "react";
import { Button, Checkbox } from "antd";
import { CheckOutlined } from "@ant-design/icons";

import useRBAC, { UI_PERMISSIONS } from "../../services/rbac";

const BUTTON_SIZE = "small";

const ClosedTaskHeader = (props) => {
  const {
    hasPermission = false, // Разрешение на отображение кнопки "Открыть повторно"
    reopenTask,
  } = props;

  return (
    <>
      {hasPermission && (
        <Button
          size={BUTTON_SIZE}
          children="Открыть повторно"
          onClick={reopenTask}
        />
      )}
    </>
  );
};

const Header = (props) => {
  const {
    task,
    closed,
    loading,
    submitting,
    hasChanges,
    setPriority,
    onSave,
    onDone,
    onSaveAndClose,
    reopenTask,
    rejectTask,
  } = props;

  const { checkPermissionToRenderUI } = useRBAC();

  function drawActionButtons() {
    if (closed)
      return (
        <ClosedTaskHeader
          hasPermission={checkPermissionToRenderUI(
            UI_PERMISSIONS["ui:tasks:card:reopen"]
          )}
          reopenTask={reopenTask}
        />
      );

    return (
      <>
        {checkPermissionToRenderUI(
          UI_PERMISSIONS["ui:tasks:card:edit-priority"]
        ) && (
          <Checkbox
            checked={task.urgency}
            onChange={(event) => setPriority(task.id, event.target.checked)}
            disabled={loading}
            children="Высокий приоритет"
          />
        )}

        {checkPermissionToRenderUI(UI_PERMISSIONS["ui:tasks:card:save"]) && (
          <Button
            size={BUTTON_SIZE}
            type="default"
            loading={submitting}
            disabled={loading || !hasChanges}
            className="header__button header__button_save"
            children="Сохранить"
            onClick={onSave}
          />
        )}

        {checkPermissionToRenderUI(
          UI_PERMISSIONS["ui:tasks:card:save-and-close"]
        ) && (
          <Button
            size={BUTTON_SIZE}
            type="primary"
            children="Сохранить и закрыть"
            loading={submitting}
            disabled={loading || !hasChanges}
            onClick={onSaveAndClose}
          />
        )}

        {checkPermissionToRenderUI(UI_PERMISSIONS["ui:tasks:card:done"]) && (
          <Button
            size={BUTTON_SIZE}
            disabled={loading || submitting}
            className="header__button header__button_done"
            children="Выполнено"
            onClick={onDone}
            icon={<CheckOutlined />}
          />
        )}

        {checkPermissionToRenderUI(UI_PERMISSIONS["ui:tasks:card:reject"]) &&
          typeof rejectTask === "function" && (
            <Button
              size={BUTTON_SIZE}
              type="link"
              children="Отклонить"
              onClick={rejectTask}
            />
          )}
      </>
    );
  }

  return (
    <>
      <h2 className="header__title">{`Задача #${task.human_readable_id}`}</h2>
      {drawActionButtons()}
    </>
  );
};

export default Header;
