import React, { useState, useEffect, useCallback } from "react";
import { Form, Input, Checkbox, Select, Button, message } from "antd";
import { useDispatch } from "react-redux";

import MainContainer from "../../components/MainContainer";

import RBAC_API from "../../api/rbac";
import OPERATORS_API from "../../api/operators";
import errorHandling from "../../services/errors/errorHandling";
import { LDAP_PROP_CREATION_ERROR } from "../../actions/errors/actionTypes";

import "./style.scss";

const LDAP_GROUP_NAME_MAX_LEN = 50;

const Header = () => (
  <>
    <h2 className="header__title">Создание нового LDAP-свойства</h2>
  </>
);

const LdapPropsCreationForm = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [roles, setRoles] = useState([]);
  const [groups, setGroups] = useState([]);
  const [statuses, setStatuses] = useState([]);

  function ldapGroupNameValidation(name = "") {
    const regex = /^[a-z_\d]*$/gim;

    if (name.length === 0) return Promise.reject("Обязательное поле");
    if (!regex.test(name))
      return Promise.reject(
        "Название может содержать только символы латинского алфавита, цифры и нижнее подчеркивание. Например: examle_prop_001"
      );

    return Promise.resolve();
  }

  function filterSelectOptions(input, option) {
    return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  }

  const getRoles = useCallback(async () => {
    await RBAC_API.getRolesList()
      .then((resp) =>
        setRoles(resp.data.map((role) => ({ value: role.id, label: role.id })))
      )
      .catch((err) => setError(err));
  }, []);

  const getGroups = useCallback(async () => {
    await OPERATORS_API.getOperatorsGroupsList()
      .then((resp) =>
        setGroups(
          resp.data.map((group) => ({ value: group.id, label: group.title }))
        )
      )
      .catch((err) => setError(err));
  }, []);

  const getStatuses = useCallback(async () => {
    await OPERATORS_API.getOperatorStatuses()
      .then((resp) =>
        setStatuses(
          resp.data.map((status) => ({ value: status.id, label: status.title }))
        )
      )
      .catch((err) => setError(err));
  }, []);

  const getFormData = useCallback(async () => {
    await setLoading(true);

    try {
      await getRoles();
      await getGroups();
      await getStatuses();
    } catch (e) {
      console.error(e);
    }

    await setLoading(false);
  }, [getRoles, getGroups, getStatuses]);

  async function submitForm(formFields) {
    await setSubmitting(true);

    await OPERATORS_API.postOperatorLdapProps(formFields)
      .then(() => {
        form.resetFields();
        getFormData();
        message.success("LDAP-свойство успешно создано");
      })
      .catch((err) => dispatch(errorHandling(err, LDAP_PROP_CREATION_ERROR)));

    await setSubmitting(false);
  }

  useEffect(() => {
    getFormData();
  }, [getFormData]);

  return (
    <MainContainer
      error={error}
      loading={loading}
      header={<Header />}
      className="page_ldap-props-creation-form"
      headerClassName="ldap-props-creation-form__header"
    >
      <Form
        form={form}
        onFinish={submitForm}
        layout="vertical"
        className="ldap-props-creation-form__form"
      >
        <Form.Item
          label="Название LDAP группы:"
          name="ldap_group"
          rules={[
            {
              required: true,
              validator: (_, value) => ldapGroupNameValidation(value),
            },
            {
              min: 3,
              message: "Строка слишком короткая",
            },
            {
              max: LDAP_GROUP_NAME_MAX_LEN,
              message: `Максимальная длина: ${LDAP_GROUP_NAME_MAX_LEN} символов`,
            },
          ]}
        >
          <Input placeholder="Введите название..." />
        </Form.Item>
        <Form.Item
          label="Роли:"
          name="roles"
          rules={[
            { required: true, message: "Необходимо выбрать хотя бы одну роль" },
          ]}
          style={{ padding: "10px 0" }}
        >
          <Checkbox.Group options={roles} style={{ paddingLeft: "30px" }} />
        </Form.Item>
        <Form.Item
          label="Группа операторов:"
          name="operators_group_id"
          rules={[{ required: true, message: "Обязательное поле" }]}
        >
          <Select
            showSearch
            placeholder="Выберите группу..."
            options={groups}
            filterOption={filterSelectOptions}
          />
        </Form.Item>
        <Form.Item
          label="Статус операторов:"
          name="operators_status_id"
          rules={[{ required: true, message: "Обязательное поле" }]}
        >
          <Select
            showSearch
            placeholder="Выберите статус..."
            options={statuses}
            filterOption={filterSelectOptions}
          />
        </Form.Item>
        <Button
          loading={submitting}
          type="primary"
          htmlType="submit"
          children="Создать"
          style={{ marginTop: "20px" }}
        />
      </Form>
    </MainContainer>
  );
};

export default LdapPropsCreationForm;
