import React from "react";
import { Button, Layout, Col, Row, Typography } from "antd";

import "./style.scss";

const { Content } = Layout;
const { Title, Text } = Typography;

const DEFAULT_PROPS = {
  shape: "round",
  htmlType: "button",
};

const Property = ({ title, description, buttonProps, className, children = null }) => {
  return (
    <Content className={`component_property ${className}`}>
      <Row wrap={false}>
        <Col flex="auto">
          <Row>
            <Col xs={24}>
              <Title level={5} className="property__title">
                {title}
              </Title>
            </Col>
            <Col xs={24}>
              <Text className="property__description">{description}</Text>
            </Col>
          </Row>
        </Col>
        <Col flex={"40px"} />
        <Col>
          <Button {...DEFAULT_PROPS} {...buttonProps} />
        </Col>
      </Row>
      {children}
    </Content>
  );
};

export default Property;
