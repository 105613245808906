import api from "./api";
import { USER_API_URLS, HARDWARE_API_URLS } from "../consts";

export async function createFromEmployee(employee_id) {
  const url = USER_API_URLS.createFromEmployee();
  return await api.protectedPost(url, { employee_id });
}

export async function getUserData(userID) {
  const url = USER_API_URLS.getUser(userID);
  return await api.protectedGet(url);
}

export async function changeUserWorkplaceLocation(userID, workplace_location) {
  const url = USER_API_URLS.setUserLocation(userID);
  return await api.protectedPut(url, { workplace_location });
}

export async function changeUserWorkstationNumber(userID, work_station_number) {
  const url = USER_API_URLS.setUserWorkstationNumber(userID);
  return await api.protectedPost(url, {
    work_station_number,
  });
}

export async function getUsersByQuery(query, usersOnly = false) {
  const url = USER_API_URLS.searchUser(query, usersOnly);
  return await api.protectedGet(url);
}

export async function editUserHardwareChange(recordID, text) {
  const url = HARDWARE_API_URLS.putHardwareChange(recordID);
  return await api.protectedPut(url, { text });
}

export async function deleteUserHardwareChange(recordID) {
  const url = HARDWARE_API_URLS.deleteHardwareChange(recordID);
  return await api.protectedDelete(url);
}

export async function getUserPhoto(userId) {
  const url = USER_API_URLS.getUserPhoto(userId);
  return await api.protectedGet(url, { responseType: "blob" });
}

/*
  Обновление информации пользователя из базы Employees
*/
export async function updateUserInfo(userId) {
  const url = USER_API_URLS.updateUserInfo(userId);
  return await api.protectedPost(url);
}

const USER_API = {
  getUserData,
  getUserPhoto,
  updateUserInfo,
  getUsersByQuery,
  createFromEmployee,
  editUserHardwareChange,
  deleteUserHardwareChange,
  changeUserWorkplaceLocation,
  changeUserWorkstationNumber,
};

export default USER_API;
