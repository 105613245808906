import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { message } from "antd";

import Card, { CARD_SECTIONS, OBSERVABLE_KEYS } from "../../components/Card";
import Header from "./header";
import Tabs from "./tabs";

import OPERATORS_API from "../../api/operators";
import MASS_PROBLEM_API from "../../api/massProblem";
import errorHandling from "../../services/errors/errorHandling";
import {
  RESET_ERROR,
  MASS_PROBLEM_GETTING_ERROR,
  MASS_PROBLEM_UPLOAD_FILES_ERROR,
  MASS_PROBLEM_DELETE_ISSUE_ERROR,
  MASS_PROBLEM_ADD_NEW_ISSUE_ERROR,
  MASS_PROBLEM_DESCRIPTION_EDITING_ERROR,
  MASS_PROBLEM_CLOSING_ERROR,
} from "../../actions/errors/actionTypes";
import { COMPONENTS } from "../../services/tabs";
import { COMPONENT_URL } from "../../consts";
import checkRoute from "../../utils/checkRoute";
import useRBAC, { UI_PERMISSIONS } from "../../services/rbac";

import "./style.scss";

const MassProblemCard = (props) => {
  const { componentProps, setComponentProps, tabUuid, removeTab } = props;

  const dispatch = useDispatch();
  const location = useLocation();

  const massProblemId = location.state
    ? location.state.massProblemId
    : getMassProblemIdFromUrl();

  // Store
  const userId = useSelector((store) => store.user.id);
  const error = useSelector((store) => store.error);
  const { newNotifications } = useSelector((store) => store.notifications);

  // State
  const [changes, setChanges] = useState(null);
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [operators, setOperators] = useState([]);

  // Hooks
  const { checkPermissionToRenderUI } = useRBAC();

  function getMassProblemIdFromUrl() {
    const id = window.location.pathname.replace("/", "").split("/")[1];

    return checkRoute(
      COMPONENT_URL[COMPONENTS.MASS_PROBLEM_CARD],
      window.location.pathname
    )
      ? id
      : undefined;
  }

  function setDisabledState(massProblem) {
    setDisabled(
      massProblem
        ? Boolean(massProblem.done_at || massProblem.deleted_at)
        : true
    );
  }

  async function getMassProblem() {
    await setLoading(true);

    return await MASS_PROBLEM_API.getMassProblemById(massProblemId)
      .finally(() => setLoading(false))
      .then((resp) => {
        setDisabledState(resp.data);
        setComponentProps({ ...resp.data, performer: resp.data.operator });
      })
      .catch((err) => {
        if (err.response) {
          err.response.data.message = `При получении данных массовой проблемы произошла ошибка\n${err.response.data.message}`;
        }
        setDisabledState({});
        dispatch(errorHandling(err, MASS_PROBLEM_GETTING_ERROR, false));
      });
  }

  async function getOperators() {
    await setLoading(true);

    await OPERATORS_API.getOperatorsList()
      .then((resp) => {
        const operators = resp.data.map((operator) => ({
          label: `${operator.last_name} ${operator.first_name}`,
          value: operator.id,
        }));
        setOperators(operators);
      })
      .catch((err) => {
        if (err.response) {
          err.response.data.message = `При получении списка операторов произошла ошибка\n${err.response.data.message}`;
        }
        dispatch(errorHandling(err, "", false));
      });

    await setLoading(false);
  }

  async function getMassProblemOpenIssues() {
    await setLoading(true);

    await MASS_PROBLEM_API.getOpenIssuesOfMassProblem(massProblemId)
      .finally(() => setLoading(false))
      .then((resp) => {
        setComponentProps({ openIssues: resp.data });
      })
      .catch((err) => {
        if (err.response) {
          err.response.data.message = `При получении списка обращений массовой проблемы произошла ошибка\n${err.response.data.message}`;
        }
        dispatch(errorHandling(err, MASS_PROBLEM_GETTING_ERROR, false));
      });
  }

  async function getMassProblemChanges() {
    await MASS_PROBLEM_API.getMassProblemChanges(massProblemId)
      .then((resp) => setComponentProps({ changes: resp.data }))
      .catch((err) => {
        if (err.response) {
          err.response.data.message = `При получении списка операторов произошла ошибка\n${err.response.data.message}`;
        }
        dispatch(errorHandling(err, MASS_PROBLEM_GETTING_ERROR, false));
      });
  }

  async function getMassProblemCardData() {
    try {
      await getMassProblem();
      await getOperators();
      await getMassProblemOpenIssues();
    } catch (e) {
      setLoading(false);
    }
  }

  async function deleteIssueFromMassProblem(issueId) {
    await setLoading(true);

    return await MASS_PROBLEM_API.deleteIssueFromMassProblem(
      massProblemId,
      issueId
    )
      .finally(() => setLoading(false))
      .then(() => getMassProblemOpenIssues())
      .catch((err) => {
        dispatch(errorHandling(err, MASS_PROBLEM_DELETE_ISSUE_ERROR));

        return Promise.reject(err);
      });
  }

  async function addNewIssues(massProblemId, issuesId) {
    await setLoading(true);

    return await MASS_PROBLEM_API.addIssuesToMassProblem(
      massProblemId,
      issuesId
    )
      .finally(() => setLoading(false))
      .catch((err) => {
        dispatch(errorHandling(err, MASS_PROBLEM_ADD_NEW_ISSUE_ERROR));

        return Promise.reject(err);
      });
  }

  async function uploadMassProblemFiles(massProblemId, filesId) {
    await setLoading(true);

    return await MASS_PROBLEM_API.postMassProblemFiles(
      massProblemId.substring(),
      filesId
    )
      .finally(() => setLoading(false))
      .catch((err) => {
        dispatch(errorHandling(err, MASS_PROBLEM_UPLOAD_FILES_ERROR));
        return Promise.reject(err);
      });
  }

  async function editMassProblemDescription(massProblemId, description) {
    await setLoading(true);
    return await MASS_PROBLEM_API.putMassProblemDescription(
      massProblemId,
      description
    )
      .finally(() => setLoading(false))
      .catch((err) => {
        dispatch(errorHandling(err, MASS_PROBLEM_DESCRIPTION_EDITING_ERROR));

        return Promise.reject(err);
      });
  }

  // async function changeMassProblemPerformer(massProblemId, performerId) {
  //   await setLoading(true);
  //   await setLoading(false);
  // }

  async function handleSaveAndCloseClick(massProblemId, changes) {
    try {
      await handleSaveClick(massProblemId, changes);
      removeTab();
    } catch (err) {
      console.error(err);
      message.error("Не удалось сохранить изменения");
    }
  }

  async function handleSaveClick(massProblemId, changes) {
    try {
      for (let key in changes) {
        if (key === OBSERVABLE_KEYS.massProblemNewIssues)
          await addNewIssues(massProblemId, changes[key]);
        if (key === OBSERVABLE_KEYS.description)
          await editMassProblemDescription(massProblemId, changes[key]);
        if (key === OBSERVABLE_KEYS.files) {
          const filesId = changes[key].map((file) => file.id);

          await uploadMassProblemFiles(massProblemId, filesId);
        }
      }

      message.success("Сохранено");
      await getMassProblemCardData();
    } catch {
      setLoading(false);
    }
  }

  async function handleDoneClick() {
    await setLoading(true);

    await MASS_PROBLEM_API.closeMassProblem(massProblemId)
      .finally(() => setLoading(false))
      .then(() => getMassProblemCardData())
      .catch((err) => dispatch(errorHandling(err, MASS_PROBLEM_CLOSING_ERROR)));
  }

  function getError(error) {
    return error.type === MASS_PROBLEM_GETTING_ERROR ? error : null;
  }

  function getCardHeader(error) {
    if (getError(error) !== null) return null;

    return (
      <Header
        massProblem={componentProps || {}}
        loading={loading}
        closed={disabled}
        hasChanges={changes !== null}
        onSave={() => handleSaveClick(massProblemId, changes)}
        onDone={handleDoneClick}
        onSaveAndClose={() => handleSaveAndCloseClick(massProblemId, changes)}
      />
    );
  }

  function checkClosed(mass_problem) {
    if (!mass_problem) {
      return undefined;
    } else {
      return Boolean(mass_problem.done_at || mass_problem.deleted_at);
    }
  }

  function resetError() {
    dispatch({ type: RESET_ERROR });
  }

  useEffect(() => {
    resetError();
    getMassProblemCardData();
  }, [tabUuid]);

  return (
    <div className="page_mass-problem-card">
      <Card
        className={`page__card_mass-problem ${
          checkClosed(componentProps) ? "mass-problem_closed" : ""
        }`}
        sections={[
          CARD_SECTIONS.massProblem,
          CARD_SECTIONS.data,
          CARD_SECTIONS.description,
          CARD_SECTIONS.files,
        ]}
        data={{
          ...componentProps,
          error: getError(error),
          loading,
          disabled,
          operators,
          deleteIssueFromMassProblem,
          closedLabel: {
            closed: checkClosed(componentProps),
            text: "Массовая проблема закрыта",
          },
          permissions: {
            deletingFromMassProblemAllowed: checkPermissionToRenderUI(
              UI_PERMISSIONS[
                "ui:mass-problems:card:delete-issue-from-mass-problem"
              ]
            ),
            addToMassProblemAllowed: checkPermissionToRenderUI(
              UI_PERMISSIONS[
                "ui:mass-problems:card:add-issue-from-mass-problem"
              ]
            ),
            descriptionEditingAllowed: checkPermissionToRenderUI(
              UI_PERMISSIONS["ui:mass-problems:card:description:edit"]
            ),
          },
        }}
        header={getCardHeader(error)}
        onUpdate={getMassProblem}
        onChange={setChanges}
        getHistory={getMassProblemChanges}
      />
      <Tabs
        massProblemId={massProblemId}
        operatorId={userId}
        newNotifications={newNotifications}
      />
    </div>
  );
};

export default MassProblemCard;
