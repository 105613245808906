export const NEW_NOTIFICATION = "NEW_NOTIFICATION";
export const CLOSE_NOTIFICATION = "CLOSE_NOTIFICATION";
export const OPEN_NOTIFICATIONS_WS = "OPEN_NOTIFICATIONS_WS";
export const CLOSE_NOTIFICATIONS_WS = "CLOSE_NOTIFICATIONS_WS";
export const NEW_MESSAGE_NOTIFICATION = "NEW_MESSAGE_NOTIFICATION";
export const READ_MESSAGE = "READ_MESSAGE";
export const ISSUE_CREATED = "ISSUE_CREATED";
export const ISSUE_CLOSED = "ISSUE_CLOSED";
export const OPERATOR_TASK_CREATED = "OPERATOR_TASK_CREATED";
export const TASK_ASSIGNED_TO_OPERATOR = "TASK_ASSIGNED_TO_OPERATOR";
export const TASK_CLOSED = "TASK_CLOSED";
export const SET_OPERATOR_OPEN_ISSUES = "SET_OPERATOR_OPEN_ISSUES";
export const SET_OPERATOR_OPEN_TASKS = "SET_OPERATOR_OPEN_TASKS";
export const SET_OPEN_MASS_PROBLEMS = "SET_OPEN_MASS_PROBLEMS";
export const GET_OPERATOR_NEW_MESSAGE = "GET_OPERATOR_NEW_MESSAGE";
export const ISSUE_TRANSFERRED = "ISSUE_TRANSFERRED";
export const SET_NOTIFICATION_LIST = "SET_NOTIFICATION_LIST";
export const SET_NOTIFICATION_LIST_OFFSET = "SET_NOTIFICATION_LIST_OFFSET";
export const SET_NOTIFICATION_LIST_VISIBLE = "SET_NOTIFICATION_LIST_VISIBLE";
export const SET_NOTIFICATION_LIST_LOADMORE = "SET_NOTIFICATION_LIST_LOADMORE";

export default {
  NEW_NOTIFICATION,
  CLOSE_NOTIFICATION,
  OPEN_NOTIFICATIONS_WS,
  CLOSE_NOTIFICATIONS_WS,
  NEW_MESSAGE_NOTIFICATION,
  READ_MESSAGE,
  TASK_CLOSED,
  ISSUE_CLOSED,
  ISSUE_CREATED,
  TASK_ASSIGNED_TO_OPERATOR,
  OPERATOR_TASK_CREATED,
  SET_OPERATOR_OPEN_ISSUES,
  SET_OPERATOR_OPEN_TASKS,
  SET_OPEN_MASS_PROBLEMS,
  GET_OPERATOR_NEW_MESSAGE,
  ISSUE_TRANSFERRED,
  SET_NOTIFICATION_LIST,
  SET_NOTIFICATION_LIST_OFFSET,
  SET_NOTIFICATION_LIST_VISIBLE,
  SET_NOTIFICATION_LIST_LOADMORE,
};
