import { store } from "../../";

import APP_ACTION_TYPES from "./actionTypes";

/**
 * @param {boolean} bool
 */
function setAppLoading(bool) {
  store.dispatch({ type: APP_ACTION_TYPES.SET_LOADING_STATUS, payload: bool });
}

function setStartPath(path) {
  store.dispatch({ type: APP_ACTION_TYPES.SET_START_PATH, payload: path });
}

function setTimeoutRefresh(refreshTimeoutId) {
  store.dispatch({
    type: APP_ACTION_TYPES.SET_REFRESH_TIMEOUT_ID,
    payload: refreshTimeoutId,
  });
}

function setFaviconBlickIntervalId(intervalId) {
  return (dispatch) =>
    dispatch({
      type: APP_ACTION_TYPES.SET_FAVICON_BLICK_INTERVAL_ID,
      payload: intervalId,
    });
}

const APP_ACTIONS = {
  setStartPath,
  setAppLoading,
  setTimeoutRefresh,
  setFaviconBlickIntervalId,
};

export default APP_ACTIONS;
