function addLeadingZeros(string = "", size = 0) {
  let leadingZerosString = string;

  while (leadingZerosString.length < size) {
    leadingZerosString = "0" + leadingZerosString;
  }

  return leadingZerosString;
}

export default addLeadingZeros;
