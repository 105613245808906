import React, { useState, useRef } from "react";
import { Button } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";

import Table from "../Table";
import IssueSelector from "../IssueSelector";

import { TYPES } from "../Search_new/consts";
import { useTabs, COMPONENTS } from "../../services/tabs";

function MassProblemSection(props) {
  const {
    massProblemIssues = [],
    addNewIssue,
    deleteIssueFromMassProblem,
    permissions,
  } = props;
  const {
    deletingFromMassProblemAllowed = false,
    addToMassProblemAllowed = false,
  } = permissions;

  // Store
  const userOperatorGroup = useSelector((store) => store.user.operatorsGroupId);

  // Hooks
  const { clickHandler } = useTabs();

  const addIssuesRef = useRef(null);

  const defaultFilter = {
    id: userOperatorGroup,
    type: TYPES.group,
  };

  // State
  const [addIssuesVisible, setAddIssuesVisible] = useState(false);

  function getColumns() {
    const COLUMNS = [
      {
        width: 100,
        align: "center",
        title: "Номер",
        dataIndex: "human_readable_id",
      },
      {
        title: "Описание",
        dataIndex: "description",
      },
    ];

    if (deletingFromMassProblemAllowed) {
      COLUMNS.push({
        width: 100,
        align: "center",
        dataIndex: "id",
        render: (text) => (
          <Button
            danger
            size="small"
            type="primary"
            children="Удалить"
            disabled={
              Array.isArray(massProblemIssues) && massProblemIssues.length < 2
            }
            onClick={(e) => {
              e.stopPropagation();
              deleteIssueFromMassProblem(text);
            }}
          />
        ),
      });
    }

    return COLUMNS;
  }

  // Редирект на страницу карточки обращения
  function openIssueCard(e, issue) {
    e.preventDefault();
    e.stopPropagation();

    clickHandler({
      component: COMPONENTS.ISSUE_CARD,
      params: { issueId: issue.id },
      locationState: { issueId: issue.id },
    });
  }

  // Рисуем список обращений массовой проблемы
  function drawIssues() {
    return (
      <Table
        data={massProblemIssues}
        columns={getColumns()}
        onRowClick={openIssueCard}
      />
    );
  }

  // Рисуем блок добавления обращений в массовую проблему
  function drawAddIssues(massProblemIssues) {
    if (!addToMassProblemAllowed) return null;

    function onHeaderClick() {
      setAddIssuesVisible(!addIssuesVisible);
      setTimeout(
        () => addIssuesRef.current.scrollIntoView({ behavior: "smooth" }),
        200
      );
    }

    const arrowClassName = `header__arrow ${
      addIssuesVisible ? "header__arrow_up" : "header__arrow_down"
    }`;
    const addIssuesContainerClassName = `section__add-issues-container ${
      addIssuesVisible
        ? "section__add-issues-container_shown"
        : "section__add-issues-container_hidden"
    }`;

    return (
      <>
        <h3
          ref={addIssuesRef}
          className="section__header section__header_add-issues"
          onClick={onHeaderClick}
        >
          Добавить обращения
          <div className="header__hl" />
          <DownOutlined className={arrowClassName} />
        </h3>
        <div className={addIssuesContainerClassName}>
          <IssueSelector
            defaultFilter={defaultFilter}
            onSelect={addNewIssue}
            excludedIssues={massProblemIssues.map((issue) => issue.id)}
          />
        </div>
      </>
    );
  }

  return (
    <section className="card__section card__section_mass-problem">
      <h3 className="section__header">Обращения массовой проблемы</h3>
      {drawIssues()}
      {drawAddIssues(massProblemIssues)}
    </section>
  );
}

export default MassProblemSection;
