import React from "react";
import { Checkbox } from "antd";

import normalizeDate, { DATE_TIME } from "../../utils/normalizeDate";

import "./style.scss";

const IssueListItem = (props) => {
  const { issue, checked, onClick } = props;
  const { id, human_readable_id, created_at, operator, description } = issue;

  function onCheckboxClick(event) {
    event.stopPropagation();
    props.onSelect(issue);
  }

  return (
    <div
      className="component_issue-list-item"
      onClick={(e) => onClick(e, { id })}
    >
      <p className="issue-list-item__header">
        <Checkbox
          className="issue-list-item__checkbox"
          checked={checked}
          onClick={onCheckboxClick}
        />
        <span className="issue-list-item__human-readable-id">
          Обращение #{human_readable_id}
        </span>
        <span className="issue-list-item__created-at">
          {normalizeDate(created_at, DATE_TIME)}
        </span>
      </p>
      <p className="issue-list-item__issue-performer">
        <span className="list-item__field-title">Исполнитель:</span>{" "}
        {operator.last_name} {operator.first_name}
      </p>
      <p title={description}>
        <span className="issue-list-item__field-title">Описание:</span>{" "}
        {description}
      </p>
    </div>
  );
};

export default IssueListItem;
