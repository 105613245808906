import React, { memo } from "react";
import { Select } from "antd";

const ComparisonsSelect = memo(
  ({ value = undefined, options = [], onChange = () => {}, ...params }) => {
    function getDefaultValue(options) {
      return Array.isArray(options) && options[0]
        ? options[0].value
        : undefined;
    }

    return (
      <Select
        className="component_comparisons-select"
        defaultValue={getDefaultValue(options)}
        value={value}
        bordered={false}
        showArrow={false}
        size="small"
        options={options}
        onSelect={onChange}
        {...params}
      />
    );
  }
);

export default ComparisonsSelect;
