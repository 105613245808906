import React from "react";
import { connect } from "react-redux";

import "./App.css";

import AppLayout from "./components/AppLayout";
import ErrorMessage, { ErrorService } from "./services/errors";
import GlobalSpinner from "./services/spinner";

import errorHandling from "./services/errors/errorHandling";
import { CONNECTION_LOST } from "./actions/errors/actionTypes";
import AUTH_ACTIONS from "./actions/auth/auth";
import * as ls from "./localStorage";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.saveOpenTabs = this.saveOpenTabs.bind(this);
    this.netDisconnectErrorHandling =
      this.netDisconnectErrorHandling.bind(this);
    this.getLayout = this.getLayout.bind(this);
  }

  getLayout(isAuth) {
    switch (isAuth) {
      case true:
        return AppLayout.Protected;
      case false:
        return AppLayout.Unprotected;
      case undefined:
        return AppLayout.Suspense;
      default:
        return null;
    }
  }

  saveOpenTabs() {
    const { active, list } = this.props.tabs;
    const tabs = {};

    if (Array.isArray(list) && list.length > 0) {
      tabs.list = list.map((t) => t.url);
    }
    if (typeof active.index === "number" && active.uuid) {
      tabs.active = {
        url: list[active.index].url,
        index: active.index,
      };
    }

    if (Object.keys(tabs).length > 0) {
      localStorage.setItem("tabs", JSON.stringify(tabs));
    } else {
      localStorage.removeItem("tabs");
    }
  }

  netDisconnectErrorHandling() {
    this.props.disconnectHandling();
  }

  componentDidMount() {
    const id = JSON.parse(ls.Get("id"));
    const token = JSON.parse(ls.Get("token"));
    const refreshToken = JSON.parse(ls.Get("refresh_token"));
    const startPath = window.location.pathname;

    window.addEventListener("offline", this.netDisconnectErrorHandling);
    window.addEventListener("beforeunload", this.saveOpenTabs);

    this.props.checkAuthorization({
      id,
      token,
      refreshToken,
      startPath,
    });
  }

  componentWillUnmount() {
    window.removeEventListener("offline", this.netDisconnectErrorHandling);
  }

  render() {
    const { isAuth, isLoading, errors } = this.props;

    const Layout = this.getLayout(isAuth);

    return (
      <React.Fragment>
        <Layout />
        <ErrorMessage />
        <ErrorService errors={errors} />
        <GlobalSpinner loading={isLoading} />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    isAuth: store.user.isAuth,
    isLoading: store.app.loading,
    tabs: store.tabs,
    errors: store.error.errors,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    disconnectHandling: () => dispatch(errorHandling("", CONNECTION_LOST)),
    checkAuthorization: (params) =>
      dispatch(AUTH_ACTIONS.checkAuthorization(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
