import React from "react";
import { Spin } from "antd";

/**
 * @enum Размеры спиннера
 */
const SIZES = {
  small: "small",
  default: "default",
  large: "large",
};

/**
 * @param {Object} props
 * @param {SIZES} props.size Размер спиннера
 */
const Spinner = (props) => {
  const { size = SIZES.default, text = "Загрузка..." } = props;

  return <Spin className="spinner" tip={text} size={size} />;
};

export default Spinner;
