import React, { useState, useEffect } from "react";
import { Form, Input, Button, Tree, message } from "antd";

import MainContainer from "../../components/MainContainer";

import { getPermissionsList, getRolesList, createRole } from "../../api/rbac";

import "./style.scss";

const success = () => {
  return message.success("Роль успешно создана");
};

const error = () => {
  return message.error("При создании роли произошла ошибка");
};

const Header = () => {
  return (
    <>
      <h2 className="header__title">Создание новой роли</h2>
    </>
  );
};

const RoleCreationForm = (props) => {
  const [form] = Form.useForm();

  const [roles, setRoles] = useState([]); // Массив всех ролей
  const [permissions, setPermissions] = useState([]); // Массив всех разрешений
  const [parentRoles, setParentRoles] = useState([]); // Выбранные родительские роли
  const [rolePermissions, setRolePermissions] = useState([]); // Выбранные разрешения
  const [required, setRequired] = useState(true); // Обязательное поле

  function treePermissionFormatting(data = []) {
    return data.map((permission) => ({
      title: permission.title,
      key: permission.title,
      children: permission.permissions.map((permission) => ({
        title: (
          <p style={{ margin: "unset" }}>
            <span>{permission.description}</span>
            <span
              style={{
                color: "#8E8E8E",
                display: "inline-block",
                marginLeft: "8px",
              }}
            >
              {permission.id}
            </span>
          </p>
        ),
        key: permission.id,
      })),
    }));
  }

  function treeRoleFormatting(data = []) {
    return data.map((role) => ({
      title: role.id,
      key: role.id,
      children: role.permissions.map((permission) => ({
        title: permission,
        key: permission,
        checkable: false,
      })),
    }));
  }

  function getPermissions() {
    getPermissionsList()
      .then((resp) => setPermissions(treePermissionFormatting(resp.data)))
      .catch();
  }

  function getRoles() {
    getRolesList().then((resp) => setRoles(treeRoleFormatting(resp.data)));
  }

  function onPermissionCheck(checked = []) {
    const filtered = checked.filter((permission) => {
      const index = permissions.findIndex((item) => item.key === permission);

      return index < 0;
    });

    setRolePermissions(filtered);
  }

  function roleNameValidation(name = "") {
    const regex = /^[a-z_\d]*$/gim;

    if (name.length === 0) return Promise.reject("Обязательное поле");
    if (!regex.test(name))
      return Promise.reject(
        "Название может содержать только символы латинского алфавита, цифры и нижнее подчеркивание. Например: first_line_001"
      );

    return Promise.resolve();
  }

  function validation() {
    if (required)
      return Promise.reject(
        "Необходимо выбрать хотя бы одно разрешение или родительскую роль"
      );

    return Promise.resolve();
  }

  function chechRequired() {
    const required = parentRoles.length === 0 && rolePermissions.length === 0;

    setRequired(required);
  }

  function createNewRole(formData) {
    createRole(formData)
      .then((resp) => {
        if (resp.status === 201) {
          form.resetFields();
          getRoles();
          getPermissions();
          return success();
        }
        return Promise.reject();
      })
      .catch((err) => {
        error();
        console.error(err);
      });
  }

  // componentDidMount
  useEffect(() => {
    getRoles();
    getPermissions();
  }, []);

  // componentDidUpdate
  useEffect(() => chechRequired(), [parentRoles, rolePermissions]);

  const fields = [
    { name: "parents", value: parentRoles },
    { name: "permissions", value: rolePermissions },
  ];

  return (
    <MainContainer
      className="card_role-creation"
      headerClassName="role-creation__header"
      header={<Header />}
    >
      <Form
        className="role-creation__form"
        layout="vertical"
        fields={fields}
        form={form}
        onFinish={createNewRole}
      >
        <Form.Item
          label="Название роли:"
          name="id"
          rules={[
            {
              required: true,
              validator: (_, value) => roleNameValidation(value),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Родительские роли:"
          name="parents"
          rules={[
            {
              required: required,
              validator: validation,
            },
          ]}
        >
          <Tree checkable treeData={roles} onCheck={setParentRoles} />
        </Form.Item>

        <Form.Item
          label="Разрешения:"
          name="permissions"
          rules={[
            {
              required: required,
              validator: validation,
            },
          ]}
        >
          <Tree checkable treeData={permissions} onCheck={onPermissionCheck} />
        </Form.Item>

        <Button type="primary" htmlType="submit" children="Создать" />
      </Form>
    </MainContainer>
  );
};

export default RoleCreationForm;
