import moment from "moment";

export const TIME_DATE = "HH:mm DD.MM.YY";
export const DATE_TIME = "DD.MM.YY HH:mm";
export const ONLY_TIME = "HH:mm";
export const FULL_DATE = "DD.MM.YY";
export const FULL_TIME = "HH:mm:ss";

/**
 * Преобразует дату в соответствии с шаблоном
 * @param {string} date Строковое представление даты
 * @param {string} format Шаблон форматирования даты
 */
export default (date, format = TIME_DATE) => {
  if (!date) return "";

  return moment(date).format(format);
};
