import { message } from "antd";

import { GET_OPERATOR_PERMISSIONS } from "./actionTypes";
import * as ls from "../../localStorage";
import api from "../../api/operators";
import {
  // OPERATOR_GETTING_ERROR,
  OPERATOR_NAME_CHANGING_ERROR,
  OPERATOR_ADMIN_SIGN_CHANGING_ERROR,
  OPERATOR_FIRSTLINE_SIGN_CHANGING_ERROR,
  OPERATOR_GROUP_CHANGING_ERROR,
  GROUP_NAME_EDITING_ERROR,
} from "../errors/actionTypes";
import errorHandling from "../../services/errors/errorHandling";

export function getOperatorPermissions(operatorId) {
  return (dispatch) =>
    api
      .getOperatorPermissions(operatorId)
      .then((resp) => {
        ls.Set({ permissions: JSON.stringify(resp.data) });

        return dispatch({
          type: GET_OPERATOR_PERMISSIONS,
          payload: resp.data,
        });
      })
      .catch((err) => dispatch(errorHandling(err, "")));
}

export function changeOperatorNames(opID, names) {
  return async (dispatch) =>
    await api
      .changeOperatorNames(opID, names)
      .catch((err) =>
        dispatch(errorHandling(err, OPERATOR_NAME_CHANGING_ERROR))
      );
}

export function changeOperatorAdminSign(opID, adminSign) {
  return async (dispatch) =>
    await api
      .changeOperatorAdminSign(opID, adminSign)
      .catch((err) =>
        dispatch(errorHandling(err, OPERATOR_ADMIN_SIGN_CHANGING_ERROR))
      );
}

export function changeOperatorFirstLineSign(opID, firstLineSign) {
  return async (dispatch) =>
    await api
      .changeOperatorFirstLineSign(opID, firstLineSign)
      .catch((err) =>
        dispatch(errorHandling(err, OPERATOR_FIRSTLINE_SIGN_CHANGING_ERROR))
      );
}

export function changeOperatorGroup(opID, opGroupID) {
  return async (dispatch) =>
    await api
      .changeOperatorGroup(opID, opGroupID)
      .catch((err) =>
        dispatch(errorHandling(err, OPERATOR_GROUP_CHANGING_ERROR))
      );
}

export function changeOperatorsGroupTitle(groupId, title) {
  return async (dispatch) =>
    await api
      .putOperatorsGroupTitle(groupId, title)
      .then(() => {
        message.success("Название группы изменено");
      })
      .catch((err) => {
        dispatch(errorHandling(err, GROUP_NAME_EDITING_ERROR));
      });
}
