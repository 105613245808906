import React from "react";
import { Select } from "antd";

import SelectedFilter from "./selectedFilter";
import ExistenceSelect from "./existenceSelect";

const SearchFilter = ({
  label = "",
  onSearch = () => {},
  onSelect = () => {},
  onRemove = () => {},
  onChangeComparison = () => {},
  onChangeExistence = () => {},
  options = [],
  selected = [],
  comparisons = [],
  existence = undefined,
  params = {},
}) => {
  function filterOptions(options, selected) {
    const selectedValues = selected.map((selected) => selected.value);

    return options.filter((option) => !selectedValues.includes(option.value));
  }

  return (
    <div className="search-filter__filter search-filter__filter_search">
      <div className="filter__label">
        {label}
        {existence !== undefined && (
          <ExistenceSelect
            value={existence.selected}
            options={existence.options}
            onChange={onChangeExistence}
          />
        )}
      </div>
      {!(existence && existence.selected) && (
        <>
          <Select
            showSearch
            allowClear
            options={filterOptions(options, selected)}
            value={[]}
            className="filter__input"
            placeholder="Поиск"
            size="small"
            onSearch={onSearch}
            onSelect={(value, option) => onSelect(option)}
            filterOption={false}
            showArrow={false}
            defaultActiveFirstOption={false}
            {...params}
          />
          <div>
            {selected.map((filter, idx) => (
              <SelectedFilter
                key={`${filter.value}_${idx}`}
                comparisons={{
                  selected: selected.comparison,
                  options: comparisons,
                }}
                filterLabel={filter.label}
                onChange={(value) => onChangeComparison(idx, value)}
                onRemove={() => onRemove(idx)}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default SearchFilter;
