import React from "react";
import { Row, Col, Input, Typography } from "antd";

import normalizeDate, { ONLY_TIME } from "../../../utils/normalizeDate";

const { Title, Text } = Typography;

const EditingUserMessage = ({ message, onChange, onBlur, onPressEnter }) => {
  const { messageFrom, created_at } = message;
  const author = `${messageFrom.last_name} ${messageFrom.first_name}`.trim();

  return (
    <Row className="add-user-message__editing-user-message message message_from-user">
      <Col span={24}>
        <Title level={4} className="message__author" style={{ fontSize: 14 }}>
          {author}
        </Title>
      </Col>
      <Col span={24}>
        <Input.TextArea
          autoFocus
          className="editing-user-message__textarea"
          autoSize={{ minRows: 1, maxRows: 10 }}
          value={message.message}
          onChange={(event) => onChange(event.target.value)}
          onBlur={onBlur}
          onPressEnter={onPressEnter}
        />
      </Col>
      <Text className="message__created-at">{normalizeDate(created_at, ONLY_TIME)}</Text>
    </Row>
  );
};

export default EditingUserMessage;
