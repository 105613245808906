import FETCH_SERVICE from "./api";
import { MASS_PROBLEM } from "../consts";

/**
 * Создание массовой проблемы
 * @param {object} massProblem
 * @param {string} massProblem.operator_id
 * @param {string} massProblem.description Описание проблемы
 * @param {string[]} massProblem.issues_ids Список обращений, объединенных в массовую проблему
 * @param {string[]} massProblem.file_ids Список id файлов, прикрепленных к массовой проблеме
 * @returns {Promise}
 */
export async function createMassProblem(massProblem) {
  return await FETCH_SERVICE.protectedPost(MASS_PROBLEM.create, massProblem);
}

/**
 * Получение массовой проблемы по id
 * @param {string} massProblemId
 * @returns {Promise}
 */
export async function getMassProblemById(massProblemId) {
  return await FETCH_SERVICE.protectedGet(MASS_PROBLEM.getById(massProblemId));
}

/**
 * Получение списка открытых массовых проблем
 * @returns {Promise}
 */
export async function getMassProblemsList(expand) {
  return await FETCH_SERVICE.protectedGet(
    MASS_PROBLEM.getOpenProblemsList(expand)
  );
}

/**
 * Добавляет обращения к массовой проблеме
 * @param {string} massProblemId Идентификатор массовой проблемы
 * @param {string[]} issues_ids Список идентификаторов обращений
 * @returns {Promise}
 */
export async function addIssuesToMassProblem(massProblemId, issue_ids) {
  return await FETCH_SERVICE.protectedPost(
    MASS_PROBLEM.addIssues(massProblemId),
    { issue_ids }
  );
}

/**
 * Получение списка открытых обращений, объединеных в массовую задачу
 * @param {string} massProblemId Идентификатор массовой проблемы
 * @returns {Promise}
 */
export async function getOpenIssuesOfMassProblem(massProblemId) {
  return await FETCH_SERVICE.protectedGet(
    MASS_PROBLEM.getOpenIssuesList(massProblemId)
  );
}

/**
 * Изменение описания массовой проблемы
 * @param {string} massProblemId Идентификатор массовой проблемы
 * @param {string} description Описание массовой проблемы
 * @returns {Promise}
 */
export async function putMassProblemDescription(massProblemId, description) {
  return await FETCH_SERVICE.protectedPut(
    MASS_PROBLEM.putDescription(massProblemId),
    { description }
  );
}

/**
 * Закрывает массовую проблему
 * @param {string} massProblemId Идентификатор массовой проблемы
 * @returns {Promise}
 */
export async function closeMassProblem(massProblemId) {
  return await FETCH_SERVICE.protectedPost(
    MASS_PROBLEM.closeProblem(massProblemId)
  );
}

/**
 * Отпарвка комментария к массовой проблеме
 * @param {string} massProblemId Идентификатор массовой проблемы
 * @param {string} comment Строка комментария
 * @returns {Promise}
 */
export async function postMassProblemComment(massProblemId, comment) {
  return await FETCH_SERVICE.protectedPost(
    MASS_PROBLEM.postComment(massProblemId),
    { comment }
  );
}

/**
 * Получение списка комментариев к массовой проблеме
 * @param {string} massProblemId Идентификатор массовой проблемы
 * @returns {Promise}
 */
export async function getMassProblemComments(massProblemId) {
  return await FETCH_SERVICE.protectedGet(
    MASS_PROBLEM.commentsList(massProblemId)
  );
}

/**
 * Редактирование комментария к массовой проблеме
 * @param {string} commentId Идентификатор комментария
 * @param {string} comment Измененный текст комментария
 * @returns {Promise}
 */
export async function editMassProblemComment(commentId, comment) {
  return await FETCH_SERVICE.protectedPut(MASS_PROBLEM.editComment(commentId), {
    comment,
  });
}

/**
 * Добавление файлов к массовой проблеме
 * @param {string} massProblemId Идентификатор массовой проблемы
 * @param {string[]} file_ids Список идентификаторов добавляемых файлов
 * @returns {Promise}
 */
export async function postMassProblemFiles(massProblemId, file_ids) {
  return await FETCH_SERVICE.protectedPost(
    MASS_PROBLEM.addFiles(massProblemId),
    { file_ids }
  );
}

/**
 * Удаление файла массовой проблемы
 * @param {string} massProblemId Идентификатор массовой проблемы
 * @param {string} fileId Идентификатор файла
 * @returns {Promise}
 */
export async function deleteMassProblemFile(massProblemId, fileId) {
  return await FETCH_SERVICE.protectedDelete(
    MASS_PROBLEM.deleteFile(massProblemId, fileId)
  );
}

/**
 * Удаляет обращение из списка связанных с массовой проблемы
 * @param {string} massProblemId Идентификатор массовой проблемы
 * @param {string} issueId Идентификатор обращения
 * @returns {Promise}
 */
export async function deleteIssueFromMassProblem(massProblemId, issueId) {
  return await FETCH_SERVICE.protectedDelete(
    MASS_PROBLEM.deleteIssue(massProblemId, issueId)
  );
}

export async function getMassProblemChanges(massProblemId) {
  return await FETCH_SERVICE.protectedGet(
    MASS_PROBLEM.getChanges(massProblemId)
  );
}

export async function getMassProblems(ids) {
  return await FETCH_SERVICE.protectedGet(
    MASS_PROBLEM.getMassProblemsById(ids)
  );
}

const MASS_PROBLEM_API = {
  createMassProblem,
  getMassProblemById,
  getMassProblemsList,
  addIssuesToMassProblem,
  getOpenIssuesOfMassProblem,
  putMassProblemDescription,
  closeMassProblem,
  postMassProblemComment,
  getMassProblemComments,
  editMassProblemComment,
  postMassProblemFiles,
  deleteMassProblemFile,
  deleteIssueFromMassProblem,
  getMassProblemChanges,
  getMassProblems,
};

export default MASS_PROBLEM_API;
