import APP_ACTION_TYPES from "../actions/app/actionTypes";

const initialState = {
  loading: false,
  startPath: null,
  refreshTimeoutId: null,
  faviconBlickIntervalId: null,
};

function appReducer(state = initialState, action) {
  switch (action.type) {
    case APP_ACTION_TYPES.SET_LOADING_STATUS:
      return { ...state, loading: action.payload };
    case APP_ACTION_TYPES.SET_START_PATH:
      return { ...state, startPath: action.payload };
    case APP_ACTION_TYPES.SET_REFRESH_TIMEOUT_ID:
      return { ...state, refreshTimeoutId: action.payload };
    case APP_ACTION_TYPES.SET_FAVICON_BLICK_INTERVAL_ID:
      return { ...state, faviconBlickIntervalId: action.payload };
    default:
      return { ...state };
  }
}

export default appReducer;
